import PropTypes from 'prop-types';
import { bool, func, string, node } from 'prop-types';
import * as styles from './styles/styles.module.scss';
import { useIntl } from '@alltrails/shared/react-intl';

const ShareModalDrawer = ({ showSuccessIcon, onClick, imgLink, icon, title }) => {
  const intl = useIntl();
  const Icon = icon ? icon : <img className={styles.icon} src={imgLink} alt={title} />;

  return (
    <div className={styles.container} onClick={onClick} role="button">
      <div className={styles.shareDrawer}>
        <div className={styles.shareImageContainer}>{Icon}</div>
        <div className={styles.title}>{title}</div>
        {showSuccessIcon && (
          <img
            src="https://cdn-assets.alltrails.com/assets/icons/share/filled@2x.png"
            alt={intl.formatMessage({ defaultMessage: 'Success' })}
            className={styles.successImage}
          />
        )}
      </div>
    </div>
  );
};

ShareModalDrawer.propTypes = {
  showSuccessIcon: bool,
  onClick: func,
  imgLink: string,
  icon: node,
  title: PropTypes.oneOfType([string, node])
};

ShareModalDrawer.defaultProps = {
  showSuccessIcon: false,
  onClick: () => {},
  imgLink: '',
  icon: null,
  title: ''
};

export default ShareModalDrawer;
