import { Map } from 'mapbox-gl';
import { getRedrawSegments } from 'api/TrailRedraw';
import { Feature } from '@turf/helpers';
import logError from 'utils/logError';
import { addLayer, removeLayer } from '../layers/layer_helpers';
import { removeSource } from '../sources/source_helpers';

const redrawSegmentScoreThreshold = 0.2;

const addLowTrafficVR = async (map: Map, { trailId }: { trailId: number }) => {
  try {
    const data = await getRedrawSegments(trailId);
    if (!data.features) {
      return;
    }
    const features = data.features.filter((feature: Feature) => feature.properties.score <= redrawSegmentScoreThreshold);
    const sourceGeoJson = { ...data, features };

    addLayer(
      map,
      {
        id: 'low-traffic-vr',
        type: 'line',
        source: { type: 'geojson', data: sourceGeoJson },
        layout: {
          'line-join': 'round',
          'line-cap': 'round'
        },
        paint: {
          'line-color': '#ff0',
          'line-width': ['step', ['zoom'], 40, 6, 20, 12, 10]
        }
      },
      null,
      'atMap-polylines'
    );
  } catch (error) {
    logError(error);
  }
};

const removeLowTrafficVR = (map: Map) => {
  removeLayer(map, 'low-traffic-vr');
  removeSource(map, 'low-traffic-vr');
};

export { addLowTrafficVR, removeLowTrafficVR };
