import { useEffect } from 'react';
import { defineMessage, useIntl } from '@alltrails/shared/react-intl';
import logNullStateViewed from '@alltrails/analytics/events/logNullStateViewed';
import NullStateLocation from '@alltrails/analytics/enums/NullStateLocation';
import NullStateAction from '@alltrails/analytics/enums/NullStateAction';
import logNullStateCTAClicked from '@alltrails/analytics/events/logNullStateCTAClicked';
import NullState from '@alltrails/analytics/enums/NullState';
import NullStateDenali from '@alltrails/shared/denali/components/NullState';
import Backpack from '@alltrails/shared/illustrations/Backpack';
import Bear from '@alltrails/shared/illustrations/Bear';

import * as styles from '../../application/javascripts/react_components/search/fullscreen/styles/search_app.module.scss';

type ListNullStateType = {
  isCurrentUser: boolean;
  handlePrimaryCtaClick: (option: string) => void;
  isCollaborator: boolean;
};

const exploreTrailsMessage = defineMessage({ defaultMessage: 'Explore trails' });

const ListNullState = ({ isCurrentUser, handlePrimaryCtaClick, isCollaborator }: ListNullStateType): JSX.Element => {
  const intl = useIntl();

  useEffect(() => {
    logNullStateViewed({ null_location: NullStateLocation.List, null_state: NullState.NoContent });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onExploreClick = () => {
    logNullStateCTAClicked({ null_action: NullStateAction.ExploreTrails, null_location: NullStateLocation.List, null_state: NullState.NoContent });
    handlePrimaryCtaClick('custom');
  };

  return (
    <NullStateDenali
      className={styles.nullState}
      illustration={{ Component: isCurrentUser ? Backpack : Bear }}
      title={
        isCurrentUser || isCollaborator
          ? intl.formatMessage({ defaultMessage: 'Add trails to your list' })
          : intl.formatMessage({ defaultMessage: 'No list items' })
      }
      description={
        isCurrentUser || isCollaborator
          ? intl.formatMessage({
              defaultMessage: 'Tap the save icon on any trail, then select this list name to start adding to it.'
            })
          : intl.formatMessage({
              defaultMessage: 'When this member saves items to this list, you’ll see them here.'
            })
      }
      buttons={
        isCurrentUser || isCollaborator
          ? {
              primary: {
                text: intl.formatMessage(exploreTrailsMessage),
                testId: 'explore_trails_button',
                onClick: onExploreClick
              }
            }
          : undefined
      }
    />
  );
};

export default ListNullState;
