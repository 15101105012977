import { IntlShape } from '@alltrails/shared/react-intl';
import { FilterInput, FilterType, FilterViewModel } from 'types/Search';
import { SearchFiltersUtil } from 'utils/search_filters_util';

function getActiveFilters(intl: IntlShape, filter: FilterViewModel): string[] {
  let displayFunction;

  switch (filter.type) {
    case FilterInput.CHECKBOX:
    case FilterInput.RADIO:
      // The active state for Sort is only set when not using the application default choice.
      if (filter.key === FilterType.SORT && 'best-match' in filter.config && filter.config['best-match'].selected) {
        return [];
      }

      return Object.values(filter.config)
        .filter(config => config.selected)
        .map(config => config.name) as string[];
    case FilterInput.SLIDER:
      // The slider is using the default value.
      if (filter.config.displayValue === filter.config.upperBound) {
        return [];
      }

      displayFunction = SearchFiltersUtil.getFilterDisplayFunction(filter.config.displayFunction);

      return [intl.formatMessage({ defaultMessage: 'Within {distance}' }, { distance: displayFunction(filter.config.formattedRange) })];
    case FilterInput.RANGE:
      // The range is using the default values.
      if (filter.config.displayValue[0] === 0 && filter.config.displayValue[1] === filter.config.upperBound) {
        return [];
      }

      displayFunction = SearchFiltersUtil.getFilterDisplayFunction(filter.config.displayFunction);

      return [`${displayFunction(filter.config.formattedRange[0])} - ${displayFunction(filter.config.formattedRange[1])}`];
    case FilterInput.STAR_RATING:
      return filter.config !== null ? [filter.display] : [];
    default:
      // eslint-disable-next-line no-console
      console.warn(`getActiveFilters unexpected filter type: ${JSON.stringify(filter)}`);
      return [];
  }
}

function getAllAppliedFilterLabels(intl: IntlShape, appliedByKey: Record<string, FilterViewModel>): Record<string, string[]> {
  return Object.values(appliedByKey).reduce(
    (memo, nextFilter) => ({
      ...memo,
      [nextFilter.key]: getActiveFilters(intl, nextFilter)
    }),
    {}
  );
}

export default getAllAppliedFilterLabels;
