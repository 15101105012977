import { pointItemsToGeojson } from '@alltrails/maps/utils/legacyGeoJSONConversions';
import { createClusterLayer, addClusterImageToMap } from '../layers/clusters';
import { mapPinImg, mapPinImageId, pinPopupOffset, createPinLayer, addPinImageToMap, addHoverPinImageToMap } from '../layers/pins';
import { addOrUpdateGeojsonSource } from '../sources/geojson_helpers';
import { addLayer, removeLayer, findBeforeIdFromLayerId } from '../layers/layer_helpers';
import { addHoverPopupToMap, addHoverPopupToSpiderLeg } from '../event_handling/popup_helpers';
import { inspectClusterOnClick, spiderfyClusterOnLayerHover } from '../event_handling/cluster_helpers';
import { updatePreviewLines, resetPreviewLines } from './preview_lines';
import { removeSource } from '../sources/source_helpers';
import { removeRemotePinMarker } from './remote_pin_markers';

const addClusteredPins = async (map, id, items, color, renderPopup, opacity) => {
  const sourceId = id;
  const layerId = id;
  const existingSource = map.getSource(sourceId);

  // Init/update source
  const itemsGeojson = pointItemsToGeojson(items);
  addOrUpdateGeojsonSource(map, sourceId, itemsGeojson, { cluster: true });

  // Return if source existed before initialization
  if (existingSource) {
    return;
  }

  // Init ids for various layers to be created
  const pinId = `${id}-pins`;
  const clusterId = `${layerId}-clusters`;

  const pinMouseEnter = (e, object) => {
    removeRemotePinMarker(map);
    map.getCanvas().style.cursor = 'pointer';
    map.setLayoutProperty(pinId, 'icon-image', [
      'image',
      ['match', ['get', 'ID'], object.ID, mapPinImageId(`${color}-hover`), mapPinImageId(`${color}`)]
    ]);
    updatePreviewLines(map, object);
  };

  const pinMouseLeave = () => {
    map.getCanvas().style.cursor = map.customStyle.cursor;
    map.setLayoutProperty(pinId, 'icon-image', ['image', mapPinImageId(`${color}`)]);

    resetPreviewLines(map);
  };

  await Promise.all([addPinImageToMap(map, color), addHoverPinImageToMap(map, color)]);
  await addClusterImageToMap(map, color);

  // Add layers to map
  const pinLayer = createPinLayer(pinId, sourceId, color, opacity);
  const clusterLayer = createClusterLayer(clusterId, sourceId, color, opacity);
  // Add all pin layers below results-trails (main trail pins) if it exists, otherwise below nearbyTrails if it does
  let addUnderLayerId = null;
  if (layerId !== 'results-trails') {
    addUnderLayerId = findBeforeIdFromLayerId(map, 'results-trails-pins');
    if (!addUnderLayerId && layerId !== 'nearbyTrails') {
      addUnderLayerId = 'nearbyTrails-pins';
    }
  }
  [pinLayer, clusterLayer].forEach(layer => addLayer(map, layer, null, addUnderLayerId));

  // Add event handling to layers
  const { popup, closePopup } = addHoverPopupToMap(map, pinId, renderPopup, pinMouseEnter, pinMouseLeave, { offset: pinPopupOffset });
  inspectClusterOnClick(map, clusterId, sourceId);
  spiderfyClusterOnLayerHover(map, clusterId, sourceId, spiderLeg => {
    spiderLeg.elements.pin.append(mapPinImg(color, opacity));
    addHoverPopupToSpiderLeg(map, popup, spiderLeg, renderPopup, closePopup, pinMouseEnter, pinMouseLeave);
  });
};

const removeClusteredPins = (map, id) => {
  removeLayer(map, `${id}-clusters`);
  removeLayer(map, `${id}-pins`);
  removeSource(map, id);
};

export { addClusteredPins, removeClusteredPins };
