import { LanguageSupportUtil } from '../../../../../utils/language_support_util';

const FullscreenBreadcrumbBar = props => {
  const bullet = <span>&nbsp; &#8226; &nbsp;</span>;
  const breadcrumbItems = props.items.map((item, i) => {
    if (item.link && item.link != null) {
      const link = LanguageSupportUtil.wrapUrlSafe(item.link, props.context.languageRegionCode);
      return (
        <li className={item.class} key={`breadcrumb-${item.label}`}>
          {i > 0 && bullet}
          <a href={link}>{item.label}</a>
        </li>
      );
    }
    return (
      <li className={item.class} key={`breadcrumb-${item.label}`}>
        {i > 0 && bullet}
        {item.label}
      </li>
    );
  });

  return (
    <div>
      <div id="breadcrumb-bar">
        <ul id="breadcrumb-list">{breadcrumbItems}</ul>
      </div>
    </div>
  );
};

// eslint-disable-next-line import/prefer-default-export
export { FullscreenBreadcrumbBar };
