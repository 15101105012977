import turfNearestPointOnLine from '@turf/nearest-point-on-line';
import { lineString } from '@turf/helpers';

const closestInterpPt = (pt, coords) => {
  const line = lineString(coords);
  const closestPt = turfNearestPointOnLine(line, pt);
  return {
    distance: closestPt.properties.dist,
    cropIndex: closestPt.properties.index,
    interpolatedPoint: closestPt.geometry.coordinates
  };
};

const closestInterpPtSegments = (pt, multiCoords) => {
  let closest = {};
  multiCoords.forEach((coords, i) => {
    if (coords.length < 2) {
      return;
    }
    const closestOnSeg = closestInterpPt(pt, coords);
    if (!closest.distance || closestOnSeg.distance < closest.distance) {
      closest = closestOnSeg;
      closest.cropSegmentsIdx = i;
    }
  });
  return closest;
};

export { closestInterpPt, closestInterpPtSegments };
