import CustomProvider from 'components/CustomProvider';
import ExpirationDate from './ExpirationDate';
import RenewalDate from './RenewalDate';
import ProSubscriberSince from './ProSubscriberSince';

type Props = {
  isAutorenewing: boolean;
  isAutorenewable: boolean;
  isAppleSubscription: boolean; // used to display a link to Apple
  inTrial: boolean;
  expirationOrRenewalDate?: string;
  isLifetimeSubscriber: boolean;
};

function RenewalAndExpirationDate({
  isAutorenewing,
  isAutorenewable,
  isAppleSubscription,
  inTrial,
  expirationOrRenewalDate,
  isLifetimeSubscriber
}: Props) {
  return (
    <CustomProvider>
      {isAutorenewing ? (
        <RenewalDate renewalDateString={expirationOrRenewalDate} inTrial={inTrial} />
      ) : (
        <ExpirationDate
          isAppleSubscription={isAppleSubscription}
          isAutorenewable={isAutorenewable}
          isLifetimeSubscriber={isLifetimeSubscriber}
          expirationDateString={expirationOrRenewalDate}
          inTrial={inTrial}
        />
      )}
      <ProSubscriberSince />
    </CustomProvider>
  );
}

export default RenewalAndExpirationDate;
