import { useCallback } from 'react';
import { string, number, func, object } from 'prop-types';
import Button from '@alltrails/shared/denali/components/Button';
import { defineMessages, FormattedMessage } from '@alltrails/shared/react-intl';
import useFormatMessage from '@alltrails/shared/hooks/useFormatMessage';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import logCustomMapFeatureSubmitted from '@alltrails/analytics/events/logCustomMapFeatureSubmitted';
import CustomMapFeatureAdded from '@alltrails/analytics/enums/CustomMapFeatureAdded';
import CustomMapRouteOption from '@alltrails/analytics/enums/CustomMapRouteOption';
import MapboxSearchBox from '../../../Mapbox/MapboxSearchBox';
import { UPDATE_ROUTE_COLOR, CANCEL_ROUTE_FORM, UPDATE_ROUTE_OPTIONS } from '../../../../hooks/useRoutes';
import FormButtons from '../../FormButtons';
import RouteDetails from '../RouteDetails';
import RouteColorRadioButtons from '../RouteColorRadioButtons';
import * as styles from './styles/styles.module.scss';

const FORM_STRINGS = defineMessages({
  ROUTE_COLOR: { defaultMessage: 'Route color' },
  ROUTE_OPTIONS: { defaultMessage: 'Route options' },
  OUT_AND_BACK: { defaultMessage: 'Double-back' },
  RETURN: { defaultMessage: 'Close loop' },
  REVERSE: { defaultMessage: 'Reverse' }
});

const RouteForm = ({
  distance,
  elevation,
  color,
  idx,
  dispatchRoutes,
  messagingChannel,
  handleFindLocationSelect,
  mapboxAccessToken,
  handleRouteSave,
  isSubmitting
}) => {
  const languageRegionCode = useLanguageRegionCode();
  const {
    formattedDefaultMessages: { ROUTE_COLOR, ROUTE_OPTIONS, OUT_AND_BACK, RETURN, REVERSE }
  } = useFormatMessage(FORM_STRINGS);

  const handleRouteAlterClick = useCallback(
    (topic, routeOption) => () => {
      messagingChannel.publish({ topic });
      dispatchRoutes({ type: UPDATE_ROUTE_OPTIONS, payload: routeOption });
    },
    [messagingChannel, dispatchRoutes]
  );

  const handleFormSubmit = e => {
    logCustomMapFeatureSubmitted({ feature_added: CustomMapFeatureAdded.Route });
    // true signifies that this save is coming from an inner form
    handleRouteSave(e, true);
  };

  const handleCancel = () => {
    dispatchRoutes({ type: CANCEL_ROUTE_FORM });
  };

  const handleColorChange = e => {
    const newColor = e.currentTarget.value;

    dispatchRoutes({ type: UPDATE_ROUTE_COLOR, payload: newColor });
    // fires handleRouteChangeColor in trail_planner_mixin to update the color on the map
    messagingChannel.publish({
      topic: 'route.edit.changeColor',
      data: newColor
    });
  };

  return (
    <div className={styles.container}>
      <form onSubmit={handleFormSubmit}>
        <h3 className={styles.routeFormHeader}>
          <FormattedMessage defaultMessage="Route {number}" values={{ number: idx + 1 }} />
        </h3>
        <MapboxSearchBox
          mapboxAccessToken={mapboxAccessToken}
          languageRegionCode={languageRegionCode}
          handleFindLocationSelect={handleFindLocationSelect}
        />
        <div className={styles.colorDistanceSpacer}>
          <RouteDetails distance={distance} elevation={elevation} color={color} />
        </div>
        <div className={styles.sectionTitle}>{ROUTE_COLOR}</div>
        <RouteColorRadioButtons activeColor={color} onChange={handleColorChange} />
        <div className={styles.sectionTitle}>{ROUTE_OPTIONS}</div>
        <div className={styles.buttonsContainer}>
          <Button
            text={OUT_AND_BACK}
            onClick={handleRouteAlterClick('button.click.out_and_back', CustomMapRouteOption.DoubleBack)}
            testId="map-creator-route-out-and-back"
            size="sm"
          />
          <Button
            text={RETURN}
            onClick={handleRouteAlterClick('button.click.return', CustomMapRouteOption.Loop)}
            testId="map-creator-route-return"
            size="sm"
          />
          <Button
            text={REVERSE}
            onClick={handleRouteAlterClick('button.click.reverse', CustomMapRouteOption.Reverse)}
            testId="map-creator-route-reverse"
            size="sm"
          />
        </div>
        <FormButtons onCancel={handleCancel} disabled={isSubmitting} />
      </form>
    </div>
  );
};

RouteForm.propTypes = {
  distance: string.isRequired,
  elevation: string.isRequired,
  color: string.isRequired,
  idx: number.isRequired,
  dispatchRoutes: func,
  messagingChannel: object,
  handleFindLocationSelect: func,
  mapboxAccessToken: string.isRequired
};

RouteForm.defaultProps = {
  dispatchRoutes: () => {},
  messagingChannel: {},
  handleFindLocationSelect: () => {}
};

export default RouteForm;
