import CustomProvider from 'components/CustomProvider';

import FiveHundredHeader from 'components/errors/FiveHundredHeader';

const FourOFour = () => (
  <CustomProvider>
    <FiveHundredHeader />
  </CustomProvider>
);

export default FourOFour;
