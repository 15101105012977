// extracted by mini-css-extract-plugin
export var conditionsContainer = "styles-module__conditionsContainer___PJeR2";
export var conditionsRiver = "styles-module__conditionsRiver___fX2Ic";
export var conditionsRiverTrack = "styles-module__conditionsRiverTrack___HoBPT";
export var conditionsSideView = "styles-module__conditionsSideView___Lwkj_";
export var container = "styles-module__container___s4wpo";
export var fullCardWidth = "styles-module__fullCardWidth___PFPpv";
export var shimmer = "styles-module__shimmer___aqZXh";
export var shimmerCondition = "styles-module__shimmerCondition___jje1K";
export var shimmerConditionHidden = "styles-module__shimmerConditionHidden___WXBXC";
export var shimmerContainer = "styles-module__shimmerContainer___xwOoT";
export var tabBar = "styles-module__tabBar___VNLm3";