import { get } from '@alltrails/shared/api';
import type { TrailId } from '@alltrails/shared/types/trail';
import type Trail from 'types/Trails/Trail';

export type NearbyTrailsParams = {
  page: number;
  per_page: number;
};

export type NearbyTrailsResponse = {
  trails: Trail[];
};

export default function getNearbyTrails(trailId: TrailId, params?: NearbyTrailsParams) {
  return get<NearbyTrailsResponse>(`/api/alltrails/v2/trails/${trailId}/nearby_trails`, { params: { ...params, algolia_formatted: true } });
}
