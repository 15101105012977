/**
 * Gets the width of a rendered string (in px)
 * source: https://stackoverflow.com/questions/58704990/calculate-pixel-width-of-text-without-knowing-font-in-react-javascript
 * @param text string to measure
 * @param font font to use when measuring the texts. Defaults to the documents font if not provided.
 * @returns width of the given text in px
 */
export default function getTextWidth(text: string, font?: string) {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  context.font = font || getComputedStyle(document.body).font;

  return context.measureText(text).width;
}
