import { useEffect, useState } from 'react';
import { IsochroneData, MapboxMapShim } from 'types/Map';
import { isochroneDataToInvertedIsochrone } from 'utils/at_map_helpers';
import useMapLoadedEvents from './useMapLoadedEvents';

const ISO_LAYER_ID = 'iso-layer';
const ISO_LAYER_OUTLINE_ID = 'iso-layer-outline';
const ISO_SOURCE_ID = 'iso-source';

type Args = {
  isochroneData: IsochroneData;
  mapInstance: MapboxMapShim;
  isNewMapsPage: boolean;
};

function addIsochrone(mapInstance: MapboxMapShim, isochroneData: IsochroneData, hasResultsTrailsPinsLayer: boolean) {
  if (!mapInstance?.getSource(ISO_SOURCE_ID)) {
    mapInstance?.addSource?.(ISO_SOURCE_ID, {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [isochroneDataToInvertedIsochrone(isochroneData)]
      }
    });
  }

  if (!mapInstance?.getLayer(ISO_LAYER_ID)) {
    mapInstance?.addLayer(
      {
        id: ISO_LAYER_ID,
        type: 'fill',
        source: ISO_SOURCE_ID,
        layout: {},
        paint: {
          'fill-color': '#121212',
          'fill-opacity': 0.3
        }
      },
      // Search for other instances of results-trails-pins to see how layer
      // and marker manipulation works in our mixin/older JS code.
      hasResultsTrailsPinsLayer ? 'results-trails-pins' : undefined
    );
  }

  if (!mapInstance?.getLayer(ISO_LAYER_OUTLINE_ID)) {
    mapInstance?.addLayer(
      {
        id: ISO_LAYER_OUTLINE_ID,
        type: 'line',
        source: ISO_SOURCE_ID,
        layout: {},
        paint: {
          'line-color': '#fff',
          'line-width': 2
        }
      },
      // Search for other instances of results-trails-pins to see how layer
      // and marker manipulation works in our mixin/older JS code.
      hasResultsTrailsPinsLayer ? 'results-trails-pins' : undefined
    );
  }
}

function updateIsochrone(mapInstance: MapboxMapShim, isochroneData: IsochroneData) {
  mapInstance?.getSource(ISO_SOURCE_ID).setData(isochroneDataToInvertedIsochrone(isochroneData));
}

function removeIsochrone(mapInstance: MapboxMapShim) {
  if (mapInstance?.getLayer(ISO_LAYER_OUTLINE_ID)) {
    mapInstance?.removeLayer(ISO_LAYER_OUTLINE_ID);
  }
  if (mapInstance?.getLayer(ISO_LAYER_ID)) {
    mapInstance?.removeLayer(ISO_LAYER_ID);
  }
  if (mapInstance?.getSource(ISO_SOURCE_ID)) {
    mapInstance?.removeSource(ISO_SOURCE_ID);
  }
}

export default function useIsochroneMapLayer({ mapInstance, isochroneData, isNewMapsPage }: Args) {
  const { didMapLoadEventsFire } = useMapLoadedEvents(mapInstance, isNewMapsPage);
  const [currentStyle, setCurrentStyle] = useState();

  useEffect(() => {
    if (!didMapLoadEventsFire || !mapInstance || isNewMapsPage) {
      return;
    }

    // Isochrone data is present.
    if (isochroneData) {
      // This is our hint that the isochrone layer is currently dead/empty and
      // we have to initialize it with the data.
      if (!mapInstance?.getSource(ISO_SOURCE_ID)) {
        addIsochrone(mapInstance, isochroneData, Boolean(mapInstance?.getLayer('results-trails-pins')));
      } else {
        // An isochrone already is drawn and we can update its data.
        updateIsochrone(mapInstance, isochroneData);
      }
    } else {
      // Clear the layers and the source. This would be needed if the
      // isochrone data is zeroed out/removed by the user.
      removeIsochrone(mapInstance);
    }
  }, [currentStyle, didMapLoadEventsFire, isochroneData, mapInstance, isNewMapsPage]);

  useEffect(() => {
    function onLoad() {
      // We must track the current style. This is changed when users change the
      // layer explicitly, and we must re-draw the isochrone.
      setCurrentStyle(mapInstance?.getStyle());
    }

    mapInstance?.on('style.load', onLoad);

    return () => {
      mapInstance?.off('style.load', onLoad);
    };
  }, [mapInstance]);
}
