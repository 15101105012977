/* eslint-disable no-undef */
import { FilterConfigToggleEntry } from '@alltrails/shared/types/filters';
import type { VisitorUsage } from '@alltrails/shared/types/VisitorUsage';
import type { AreaId } from '@alltrails/shared/types/area';
import { AlertType } from '@alltrails/shared/types/alert';
import type { CityId } from '@alltrails/shared/types/Location/City';
import type { StateId } from '@alltrails/shared/types/Location/State';
import type { RouteTypeUid } from '@alltrails/shared/types/trail';
import { Access, Activities, ClosedStatus, CompletedStatus, Difficulty, Features } from '../Trails';
import { Sort } from './Sort';

export type FilterFeatures = Extract<
  Features,
  | Features.BEACH
  | Features.CAVE
  | Features.CITY_WALK
  | Features.EVENT
  | Features.FOREST
  | Features.HISTORIC_SITE
  | Features.HOT_SPRINGS
  | Features.LAKE
  | Features.PUB_CRAWL
  | Features.RAILS_TRAILS
  | Features.RIVER
  | Features.VIEWS
  | Features.WATERFALL
  | Features.WILD_FLOWERS
  | Features.WILDLIFE
>;
type LinkedStatus = 'unlinked' | 'linked';

export type FilterConfigArea = {
  name: string;
  popularity: number;
  selected: boolean;
  value: number;
};

type FilterConfigStarRating = string | null;

type FilterConfigToggleKey =
  | Access
  | Activities
  | ClosedStatus
  | CompletedStatus
  | Difficulty
  | FilterFeatures
  | LinkedStatus
  | RouteTypeUid
  | Sort
  | VisitorUsage
  | AlertType;

type FilterConfigToggle = Record<FilterConfigToggleKey, FilterConfigToggleEntry>;

type FilterConfigRange = {
  stepIncreaseAt: number[];
  stepIncreaseBy: number[];
  upperBound: number;
  displayFunction: 'm' | 'km' | 'ft' | 'mi' | 'duration';
  multiplier: number;
  // The calculated data represented by the current indexes.
  value: number[];
  // The current selected indexes.
  displayValue: number[];
  // The human-presentable adjusted values based on the current index and step.
  formattedRange: string[];
};

type FilterConfigSlider = {
  stepIncreaseAt: number[];
  stepIncreaseBy: number[];
  upperBound: number;
  displayFunction: 'm' | 'km' | 'ft' | 'mi' | 'duration';
  multiplier: number;
  // The calculated data represented by the current index.
  value: number;
  // The current selected index.
  displayValue: number;
  // The human-presentable adjusted value based on the current index and step.
  formattedRange: string;
};

type Filters = {
  states: Record<StateId, FilterConfigArea>;
  cities: Record<CityId, FilterConfigArea>;
  areas: Record<AreaId, FilterConfigArea>;

  closed_status: Record<ClosedStatus, FilterConfigToggleEntry>;
  completed: Record<CompletedStatus, FilterConfigToggleEntry>;
  linked: Record<LinkedStatus, FilterConfigToggleEntry>;
  activities: Record<Activities, FilterConfigToggleEntry>;
  features: Record<FilterFeatures, FilterConfigToggleEntry>;
  access: Record<Access, FilterConfigToggleEntry>;
  difficulty: Record<Difficulty, FilterConfigToggleEntry>;
  route_type: Record<RouteTypeUid, FilterConfigToggleEntry>;
  visitor_usage: Record<VisitorUsage, FilterConfigToggleEntry>;
  lengths: FilterConfigRange;
  elevation_gain: FilterConfigRange;
  highest_point: FilterConfigRange;
  sort: Record<Sort, FilterConfigToggleEntry>;
  minRating: FilterConfigStarRating;
  distance_away: FilterConfigSlider;
  alert_type: Record<AlertType, FilterConfigToggleEntry>;

  // These are not always set intentionally by the user, and are not necessarily
  // filters in the traditional sense. "Filters" is an overloaded term that means
  // both the well-structured UI-driven object we define and anything that impacts
  // search results, even if it cannot and is not handled by our filtering UI.
  //
  // We may want to consider separating out:
  //   * user-defined knobs-and-dials
  //   * system-defined knobs-and-dials
  //   * user state that impacts search
  //
  // The ways we handle changes and use these fields vs user-defined fields are
  // not always the same.
  boundingBox: any | null;
  countries: any;
  has_profile_photo: boolean | null;
  isMinimumViableContent: boolean | null;
  initLocationObject: any | null;
  initialUserSlug: string | null;
  locationObject: any | null;
  mapIds: any[];
  mobileMap: boolean;
  queryTerm: string;
  trailIds: any[];
  trackIds: any[];
  alertIds: any[];
  zoomLevel: number | null;
};

type FiltersKey = keyof Filters;

export enum FilterType {
  ACCESS = 'access',
  ACTIVITIES = 'activities',
  ALERT_TYPE = 'alert_type',
  AREAS = 'areas',
  DIFFICULTY = 'difficulty',
  ELEVATION_GAIN = 'elevation_gain',
  HIGHEST_POINT = 'highest_point',
  FEATURES = 'features',
  LENGTHS = 'lengths',
  MIN_RATING = 'minRating',
  ROUTE_TYPE = 'route_type',
  SORT = 'sort',
  SUITABILITY = 'access',
  TRAIL_TRAFFIC = 'visitor_usage',
  DISTANCE_AWAY = 'distance_away'
}

export enum FilterInput {
  CHECKBOX = 'checkbox',
  RADIO = 'radio',
  RANGE = 'range',
  SLIDER = 'slider',
  STAR_RATING = 'rating'
}

type FilterGenericViewModel = {
  admin?: boolean;
  display: string;
  // Note: Had to add 'enabledKey' because the enabledFilters keys don't necessarily match the filter keys
  enabledKey?: string;
  extraProps?: {
    useColumns?: boolean;
  };
  key: string;
  nested?: boolean;
  truncate?: boolean;
  type: FilterInput;
  isProFilter?: boolean;
};

type FilterCheckboxViewModel = FilterGenericViewModel & {
  type: FilterInput.CHECKBOX;
  config: FilterConfigToggle;
  sort?: (a: FilterConfigToggleEntry, b: FilterConfigToggleEntry) => number;
};

type FilterRadioViewModel = FilterGenericViewModel & {
  type: FilterInput.RADIO;
  config: FilterConfigToggle;
};

type FilterRangeUpdates = {
  convertedRange: number[];
  displayRange: number[];
  formattedRange: string[];
};

type FilterSliderUpdates = {
  convertedRange: number;
  displayRange: number;
  formattedRange: string;
};

type FilterSliderViewModel = FilterGenericViewModel & {
  type: FilterInput.SLIDER;
  config: FilterConfigSlider;
};

type FilterRangeViewModel = FilterGenericViewModel & {
  type: FilterInput.RANGE;
  config: FilterConfigRange;
};

type FilterStarRatingViewModel = FilterGenericViewModel & {
  type: FilterInput.STAR_RATING;
  config: FilterConfigStarRating;
};

type FilterViewModel = FilterCheckboxViewModel | FilterRadioViewModel | FilterSliderViewModel | FilterRangeViewModel | FilterStarRatingViewModel;

type ValueOf<T> = T[keyof T];

// The configuration (not including things like 'key', 'display', etc.) for a given type of filter logic that we support.
type FilterConfig = ValueOf<Filters>;

export {
  FilterViewModel,
  FilterCheckboxViewModel,
  FilterConfig,
  FilterConfigStarRating,
  FilterConfigRange,
  FilterConfigSlider,
  FilterRangeViewModel,
  FilterConfigToggle,
  FilterConfigToggleKey,
  FilterRadioViewModel,
  FilterRangeUpdates,
  FilterSliderUpdates,
  Filters,
  FiltersKey,
  FilterSliderViewModel,
  FilterStarRatingViewModel,
  LinkedStatus
};
