import { MouseEvent, useEffect, useMemo, useState } from 'react';
import { Anchorme } from 'react-anchorme';
import classNames from 'classnames';
import logTrailDetailsShowMoreClicked from '@alltrails/analytics/events/logTrailDetailsShowMoreClicked';
import { FormattedMessage, useIntl } from '@alltrails/shared/react-intl';
import LanguageRegionCode from '@alltrails/shared/types/LanguageRegionCode';
import { adjustToSameNominalTimeForUser, monthDayYear } from '@alltrails/shared/utils/timeHelpers';
import ShowMoreLocation from '@alltrails/analytics/enums/ShowMoreLocation';
import { updateTrailAlertsRequest } from 'api/Alerts';
import { createEditGroup, createEditGroupItem, CreateEditGroupResponse, CreateEditGroupItemResponse, applyEdits } from 'api/Edits';
import useHasOverflow from 'hooks/useHasOverflow';
import useUser from 'hooks/useUser';
import Alert from '@alltrails/shared/types/alert';
import type { TrailId } from '@alltrails/shared/types/trail';
import TrailTabType from 'types/TrailTabType';
import TrailDetailsTabKey from 'types/TrailDetailsTabKey';
import OriginalLanguageButton from '../../shared/OriginalLanguageButton';
import TrailDetailItemForm from '../TrailDetailItemForm';
import TrailDetailAlertsEditor from '../TrailDetailAlertsEditor';
import * as styles from './styles/styles.module.scss';

function getAnalyticsShowMoreLocation(tabKey: TrailDetailsTabKey | string) {
  switch (tabKey) {
    case TrailDetailsTabKey.Contact:
      return ShowMoreLocation.Contact;
    case TrailDetailsTabKey.Description:
      return ShowMoreLocation.TrailDetailsDescription;
    case TrailDetailsTabKey.Facilities:
      return ShowMoreLocation.Facilities;
    case TrailDetailsTabKey.GettingThere:
      return ShowMoreLocation.GettingThere;
    case TrailDetailsTabKey.Tips:
      return ShowMoreLocation.Tips;
    case TrailDetailsTabKey.Waypoints:
      return ShowMoreLocation.Waypoints;
    default:
      return null;
  }
}

export type TrailDetailItemProps = {
  activeTab: TrailDetailsTabKey | string;
  adminOrPendingOwner: boolean;
  descriptionInitiallyExpanded?: boolean;
  editItem: TrailTabType;
  trailId: TrailId;
  visible: boolean;
};

const TrailDetailItem = ({ activeTab, adminOrPendingOwner, descriptionInitiallyExpanded, editItem, trailId, visible }: TrailDetailItemProps) => {
  const [isEditShown, setIsEditShown] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [submittedPendingEditItem, setSubmittedPendingEditItem] = useState(false);
  const [showInTranslatedLang, setShowInTranslatedLang] = useState(false);
  const [showMoreButton, setShowMoreButton] = useState(false);
  const [submitErrorMessage, setSubmitErrorMessage] = useState('');
  const [item, setItem] = useState<TrailTabType>(editItem);
  const [truncated, setTruncated] = useState(!descriptionInitiallyExpanded);

  const displayText = useMemo(() => {
    if (showInTranslatedLang && editItem.sourceText) {
      return item.sourceText;
    }

    if (item.value) {
      return item.value;
    }

    return '';
  }, [editItem.sourceText, item.sourceText, item.value, showInTranslatedLang]);
  const { ref, hasOverflow } = useHasOverflow([displayText]);

  const intl = useIntl();
  const user = useUser();

  useEffect(() => {
    if (!descriptionInitiallyExpanded) {
      setShowMoreButton(hasOverflow);
    }
  }, [hasOverflow, descriptionInitiallyExpanded]);

  useEffect(() => {
    setTruncated(!descriptionInitiallyExpanded);
    setIsEditShown(false);
    setItem(editItem);
  }, [editItem, descriptionInitiallyExpanded]);

  const clearSubmissionData = () => {
    setIsEditShown(false);
    setSubmitting(false);
    setSubmitErrorMessage('');
  };

  const submitError = () => {
    setSubmitErrorMessage(intl.formatMessage({ defaultMessage: 'Cannot save your update. Please try again.' }));
    setSubmitting(false);
  };

  const submitSuccess = (_: any, value: string) => {
    const updatedItem = {
      ...item,
      sourceText: value,
      value
    };

    clearSubmissionData();
    setItem(updatedItem);
  };

  const alertSubmitSuccess = (items: any) => {
    const editedItem = {
      ...item,
      items
    };

    clearSubmissionData();
    setItem(editedItem);
  };

  const submitPendingEditItemComplete = () => {
    setIsEditShown(false);
    setSubmitting(false);
    setSubmittedPendingEditItem(true);
  };

  const adminOrPendingOwnerCallback = (_: CreateEditGroupItemResponse, itemText: string) => {
    applyEdits({ type: 'trail', target_object_id: trailId })
      .then(data => submitSuccess(data, itemText))
      .catch(submitError);
  };

  const postItemCallback = (responseData: CreateEditGroupResponse, itemText: string) => {
    const editGroupId = responseData.editGroups[0].id;

    // if user is not admin or pending owner, we do not want to immediately apply this edit, instead leave it as pending
    // eslint-disable-next-line max-len
    if (adminOrPendingOwner) {
      createEditGroupItem(editGroupId, { type: 'trail_detail', data: { name: item.key, value: itemText } })
        .then(data => adminOrPendingOwnerCallback(data, itemText))
        .catch(submitError);
    } else {
      createEditGroupItem(editGroupId, { type: 'trail_detail', data: { name: item.key, value: itemText } })
        .then(submitPendingEditItemComplete)
        .catch(submitError);
    }
  };

  const submit = (itemText: string) => {
    setSubmitting(true);
    setSubmitErrorMessage('');
    createEditGroup({ type: 'trail', target_object_id: trailId })
      .then(response => postItemCallback(response, itemText))
      .catch(submitError);
  };

  const submitAlerts = (alerts: Alert[]) => {
    setSubmitting(true);
    setSubmitErrorMessage('');

    updateTrailAlertsRequest({ objectId: trailId, alerts })
      .then(data => alertSubmitSuccess(data))
      .catch(submitError);
  };

  const getEditCta = () => {
    if (adminOrPendingOwner) {
      return editItem.key === 'alerts' ? (
        <FormattedMessage defaultMessage="Edit trail alerts" />
      ) : (
        <FormattedMessage defaultMessage="Edit description" />
      );
    }
    return <FormattedMessage defaultMessage="Suggest edit" />;
  };

  const noEditCta = () => {
    if (!user) return true;

    if (adminOrPendingOwner) {
      // Cannot edit in-place if not one of our supported languages
      return !!item.unsupportedLanguage;
    }

    return item.key !== TrailDetailsTabKey.Description;
  };

  const renderAlertTitle = ({ title, content }: Alert) => (title ? `${title}: ${content}` : content);

  const renderAlertStartDate = (alert: Alert) => {
    if (alert.status === 'S' && alert.startDate) {
      const date = adjustToSameNominalTimeForUser(alert.startDate);
      return `[STARTS ${monthDayYear(date, LanguageRegionCode.English, false)}]`;
    }
    return '';
  };

  const renderAlertExpireDate = (alert: Alert) => {
    if (alert.endDate) {
      const recurs = alert.startDate && alert.recursAnnually ? ', recurs' : '';
      const date = adjustToSameNominalTimeForUser(alert.endDate);
      return `[EXPIRES ${monthDayYear(date, LanguageRegionCode.English, false)}]${recurs}`;
    }
    return '';
  };

  const renderAlertList = () => (
    <ul className={styles.alertList}>
      {item.items.map((alert, i) => (
        <li key={i}>
          {' '}
          <Anchorme target="_blank" rel="noopener">
            {renderAlertTitle(alert)}
          </Anchorme>
          {adminOrPendingOwner && (
            <>
              {renderAlertStartDate(alert)}
              {renderAlertExpireDate(alert)}
            </>
          )}
        </li>
      ))}
    </ul>
  );

  const renderButton = (label: React.ReactNode, callback: (e: MouseEvent<HTMLButtonElement>) => void) => (
    <button className={styles.editButton} type="button" onClick={callback}>
      {label}
    </button>
  );

  const renderEditCTA = (item: any) => {
    if (submittedPendingEditItem) {
      return (
        <span>
          <FormattedMessage defaultMessage="Thank you" />
        </span>
      );
    }

    const cta = getEditCta();
    const isAlertsForArea = item.key === 'alerts' && !!item.areaSlug;
    const handleEditItemClick = isAlertsForArea
      ? () => window.open(`/admin/bulk_trail_edit/${item.areaSlug}`, '_blank', 'noopener')
      : () => setIsEditShown(true);
    return renderButton(cta, handleEditItemClick);
  };

  const renderShowOrigLangBtn = () => {
    const shouldShowOrigLangBtn = item.sourceText && item.sourceText !== item.value;
    if (!shouldShowOrigLangBtn) return null;

    return (
      <div className={styles.languageContainer}>
        <OriginalLanguageButton
          active={showInTranslatedLang}
          handleClick={() => setShowInTranslatedLang(!showInTranslatedLang)}
          eventSource={`TrailDetail ${item.key}`}
        />
      </div>
    );
  };

  const renderShowMoreButton = () => {
    if (!showMoreButton || !truncated) return null;

    return (
      // eslint-disable-next-line jsx-a11y/control-has-associated-label
      <button
        className={styles.showMoreBtn}
        type="button"
        onClick={() => {
          setTruncated(false);
          logTrailDetailsShowMoreClicked({ trail_id: trailId, show_more_location: getAnalyticsShowMoreLocation(activeTab) });
        }}
      >
        <FormattedMessage defaultMessage="Show more" />
      </button>
    );
  };

  const renderItemDiv = () => {
    if (item.key === 'alerts' && item.items.length) {
      return renderAlertList();
    }
    if (item.key === 'accessibility') {
      // eslint-disable-next-line react/no-danger
      return <div dangerouslySetInnerHTML={{ __html: displayText }} />;
    }
    return (
      <Anchorme target="_blank" rel="noopener">
        {displayText}
      </Anchorme>
    );
  };

  const renderContentOrForm = () => {
    if (isEditShown) {
      if (item.key === 'alerts') {
        const items = item.items.filter(alert => alert.type === 'TrailAlert');
        const errorMessage = submitErrorMessage || 'Trail-level alerts edited here. Use Bulk Trail Edit for park-level alerts.';
        return (
          <TrailDetailAlertsEditor
            items={items}
            handleCancelPress={() => setIsEditShown(false)}
            handleSubmit={submitAlerts}
            errorMessage={errorMessage}
            submitting={submitting}
          />
        );
      }
      return (
        <TrailDetailItemForm
          text={item.sourceText ? item.sourceText : item.value}
          handleCancelPress={() => setIsEditShown(false)}
          handleSubmit={submit}
          errorMessage={submitErrorMessage}
          submitting={submitting}
          label={getEditCta()}
        />
      );
    }
    const canEditCTA = !noEditCta();
    return (
      <>
        <div className={styles.textAndBtnContainer}>
          <div id={`text-container-${editItem.key}`} ref={ref} className={classNames(styles.displayText, { [styles.lineClamp]: truncated })}>
            {renderItemDiv()}
          </div>
          {renderShowMoreButton()}
        </div>
        <div className={classNames({ [styles.rowReverse]: !canEditCTA && showMoreButton })}>{canEditCTA && renderEditCTA(item)}</div>
        {renderShowOrigLangBtn()}
      </>
    );
  };

  return <div className={`${styles.trailDetailItemContainer} ${visible ? '' : `${styles.hidden}`}`}>{renderContentOrForm()}</div>;
};

export default TrailDetailItem;
