import { extendBoundsByPointItem } from '../sources/point_items';
import { addPreviewLines, removePreviewLines } from './preview_lines';
import { addClusteredPins, removeClusteredPins } from './clustered_pins';
import { GRAY, TEAL, GREEN } from '../../constants/PinColor';

const addExploreItems = (map, id, items, renderPopup = null, bounds = null, isAdmin = false) => {
  if (!items) {
    return;
  }

  // Note: We may be able to combine trail and activities clusters together using an html-based pin
  // e.g. https://docs.mapbox.com/mapbox-gl-js/example/cluster-html/
  // Currently these are kept separate because it's hard to show a cluster pin that's divided into multiple colors (e.g. blue/gray)
  // This could improve performance by reducing overall number of layers and sources, but may also worsen performance
  // if HTML is too slow to render compared to GL
  const trails = [];
  const closedTrails = [];
  const activities = [];
  items.forEach(item => {
    if (bounds) {
      extendBoundsByPointItem(bounds, item);
    }
    if (item.type === 'trail') {
      if (item.is_closed && isAdmin) {
        closedTrails.push(item);
      } else {
        trails.push(item);
      }
    } else {
      activities.push(item);
    }
  });

  // Initialize hoverlines source / layer
  addPreviewLines(map);

  // Initialize trail source / layer
  addClusteredPins(map, `${id}-trails`, trails, GREEN, renderPopup);

  // Initialize closed trail source / layer
  addClusteredPins(map, `${id}-trails-closed`, closedTrails, GRAY, renderPopup);

  // Initialize activity source / layer
  addClusteredPins(map, `${id}-activities`, activities, TEAL, renderPopup);

  // Uncomment these to see a tiny (2px) red square for individual pins and a
  // larger (100px) red square for clusters to help align map images with the
  // lat/lng coordinates of the source data. If our images have a shadow or
  // spacing they will not line up with the lat/lng without some adjustment.
  // Please note that the tiny red squre is so tiny that a screen shot and
  // zooming is necessary to see it.
  // addClusteredPins(map, `${id}-trails-debugger`, trails, DEBUGGER, renderPopup);
  // addClusteredPins(map, `${id}-trails-debugger`, activities, DEBUGGER, renderPopup);
};

const removeExploreItems = (map, id) => {
  removePreviewLines(map);
  removeClusteredPins(map, `${id}-trails`);
  removeClusteredPins(map, `${id}-trails-closed`);
  removeClusteredPins(map, `${id}-activities`);
};

export { addExploreItems, removeExploreItems };
