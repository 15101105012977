import { defineMessages } from '@alltrails/shared/react-intl';
import type { User } from 'types/User';
import LanguageRegionCode from '@alltrails/shared/types/LanguageRegionCode';
import useFormatMessage from '@alltrails/shared/hooks/useFormatMessage';
import { Activity, Stats } from 'types/Activity';
import { PageStrings } from '@alltrails/shared/utils/constants/pageStringHelpers';
import StatSection from '../StatsSection/StatsSection';
import StaticMapImage from '../../../MapCreator/StaticMapImage';
import { MapStatsUtil } from '../../../../utils/map_stats_util';
import { ServerCommunicationUtil } from '../../../../utils/server_communication_util';
import * as styles from './styles/styles.module.scss';

const STATS_STRINGS = defineMessages({
  AVG_SPEED: { defaultMessage: 'Avg speed' },
  AVG_PACE: { defaultMessage: 'Avg pace' },
  LENGTH: { defaultMessage: 'Length' },
  ELEV_GAIN: { defaultMessage: 'Elev. gain' },
  MOVING_TIME: { defaultMessage: 'Moving time' },
  CALORIES: { defaultMessage: 'Calories' },
  TOTAL_TIME: { defaultMessage: 'Total time' }
});

export type StatsProps = {
  data: Activity;
  currentPage?: string;
  displayMetric: boolean;
  currentUser?: User;
  languageRegionCode: LanguageRegionCode;
  isMobileWidth?: boolean;
  showMapMobile?: () => void;
};

const StatsTab = ({ data, currentPage, displayMetric, currentUser, languageRegionCode, isMobileWidth, showMapMobile }: StatsProps) => {
  const {
    formattedDefaultMessages: { AVG_SPEED, AVG_PACE, LENGTH, ELEV_GAIN, MOVING_TIME, CALORIES, TOTAL_TIME }
  } = useFormatMessage(STATS_STRINGS);

  const getPaceOrSpeed = (stats: Stats, metric: boolean) => {
    if (currentUser && currentUser.displaySpeed) {
      const mapAvgSpeedVal = MapStatsUtil.avgSpeed(stats, metric);
      const mapAvgSpeed = MapStatsUtil.speedFormatted(mapAvgSpeedVal, metric, languageRegionCode);
      return {
        label: AVG_SPEED,
        value: mapAvgSpeed
      };
    }
    const mapAvgPaceVal = MapStatsUtil.avgPace(stats, metric);
    const mapAvgPace = MapStatsUtil.timeFormatted(mapAvgPaceVal);
    return {
      label: AVG_PACE,
      value: mapAvgPace
    };
  };

  const nA = '--';
  let mapTotalDistance = nA;
  let mapElevationGain = nA;
  let mapMovingTime = nA;
  let mapTotalTime = nA;
  let mapCalories = nA;
  let mapPaceOrSpeed = {
    label: nA,
    value: nA
  };

  const stats = data.summaryStats;
  if (stats) {
    const metric = displayMetric;
    mapTotalDistance = MapStatsUtil.metersToFormattedUserUnits(stats.distanceTotal, 2, metric, languageRegionCode);
    mapElevationGain = MapStatsUtil.elevationFormatted(stats.elevationGain, metric, languageRegionCode);
    mapMovingTime = MapStatsUtil.timeFormatted(stats.timeMoving);
    mapTotalTime = MapStatsUtil.timeFormatted(stats.timeTotal);
    mapCalories = MapStatsUtil.caloriesFormatted(stats.calories, languageRegionCode);
    mapPaceOrSpeed = getPaceOrSpeed(stats, metric);
  }

  return (
    <div className={styles.statsContainer} data-testid="stats-tab">
      <div className={styles.statsSection}>
        <StatSection labelName={LENGTH} data={mapTotalDistance} />
        <StatSection labelName={ELEV_GAIN} data={mapElevationGain} />
        {currentPage !== PageStrings.EXPLORE_USERS_MAPS_MAP_PAGE && <StatSection labelName={MOVING_TIME} data={mapMovingTime} />}
      </div>
      {currentPage !== PageStrings.EXPLORE_USERS_MAPS_MAP_PAGE && (
        <div className={styles.statsSection}>
          <StatSection labelName={mapPaceOrSpeed.label} data={mapPaceOrSpeed.value} />
          <StatSection labelName={CALORIES} data={mapCalories} />
          <StatSection labelName={TOTAL_TIME} data={mapTotalTime} />
        </div>
      )}
      {isMobileWidth && (
        <div className={styles.staticMapImage}>
          <StaticMapImage
            url={`/api/alltrails/v3/maps/${data.id}/static_map?size=327x203&key=${ServerCommunicationUtil.apiKey}`}
            onClick={showMapMobile}
          />
        </div>
      )}
    </div>
  );
};

export default StatsTab;
