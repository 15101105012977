import { useEffect, useRef } from 'react';
import { object, bool, string, func, number } from 'prop-types';
import { FONT_FAMILY_PRIMARY } from '@alltrails/shared/denali/tokens';
import { useIntl } from '@alltrails/shared/react-intl';
import { D3Utils } from './d3Utils';

const clearDistanceScaleMetric = distanceScaleMetricRef => {
  const distanceScaleMetricContainer = distanceScaleMetricRef?.current;
  if (distanceScaleMetricContainer) {
    distanceScaleMetricContainer.innerHTML = '';
  }
};

const clearDistanceScale = distanceScaleRef => {
  const distanceScaleContainer = distanceScaleRef?.current;
  if (distanceScaleContainer) {
    distanceScaleContainer.innerHTML = '';
  }
};

const renderDistanceScale = (bounds, center, isMetric, reactMap, distanceScaleMetricRef, distanceScaleRef, distanceScaleContainerRef, intl) => {
  if (isMetric) {
    clearDistanceScaleMetric(distanceScaleMetricRef);
  } else {
    clearDistanceScale(distanceScaleRef);
  }

  const mapContainer = document.querySelector('.mapDetailsContainer');
  const pageWidth = mapContainer.offsetWidth;
  const lngLatEast = [bounds.ne.lng, center.lat];
  const lngLatWest = [bounds.sw.lng, center.lat];
  const pageWidthMeters = reactMap.distanceBetween(lngLatEast, lngLatWest);

  const scaleContainer = distanceScaleContainerRef?.current;
  const containerWidth = scaleContainer.offsetWidth;
  const padding = 20;
  const ratio = (containerWidth - padding * 2) / pageWidth;
  const height = 30;
  const scaleHeight = 4;

  let distance;
  let units;
  if (isMetric) {
    if (ratio * pageWidthMeters < 2000) {
      units = containerWidth < 500 ? intl.formatMessage({ defaultMessage: 'm' }) : intl.formatMessage({ defaultMessage: 'meters' });
      distance = D3Utils.scaleLinear([0, ratio * pageWidthMeters], [0, ratio * pageWidth]);
    } else {
      units = intl.formatMessage({ defaultMessage: 'km' });
      distance = D3Utils.scaleLinear([0, (ratio * pageWidthMeters) / 1000.0], [0, ratio * pageWidth]);
    }
  } else {
    units = containerWidth < 500 ? intl.formatMessage({ defaultMessage: 'mi' }) : intl.formatMessage({ defaultMessage: 'miles' });
    distance = D3Utils.scaleLinear([0, (ratio * pageWidthMeters) / 1609.344], [0, ratio * pageWidth]);
  }

  const ticks = distance.ticks(5);
  const tickDiff = Math.round((ticks[ticks.length - 1] - ticks[ticks.length - 2]) * 1000) / 1000;
  let divisions;
  if (tickDiff < 1) {
    divisions = 10 * tickDiff;
  }
  if (tickDiff % 2 === 0) {
    divisions = 2;
  } else {
    divisions = 5;
  }
  const data = [];
  const offsetData = [];
  for (let i = 0; i <= ticks.length - 1; i++) {
    data.push(ticks[i]);
    offsetData.push(Math.round(Math.abs(ticks[i] - tickDiff) * 1000) / 1000);
    if (i == 0) {
      for (let j = 1; j <= divisions - 1; j++) {
        data.push((j * ticks[1]) / divisions);
        offsetData.push(Math.round(Math.abs((j * ticks[1]) / divisions - tickDiff) * 1000) / 1000);
      }
    }
  }

  const container = isMetric ? distanceScaleMetricRef?.current : distanceScaleRef?.current;

  const chart = D3Utils.appendSvg(container, containerWidth, height);
  const axisGroup = chart.append('svg:g').attr('transform', `translate(${padding},${padding})`);
  axisGroup
    .append('svg:rect')
    .attr('x', 0.5)
    .attr('y', 0.5)
    .attr('width', Math.round(distance(data[data.length - 1])))
    .attr('height', scaleHeight)
    .attr('stroke', 'black')
    .attr('fill', 'none');
  axisGroup
    .selectAll('.xTicks')
    .data(data)
    .enter()
    .append('svg:line')
    .attr('x1', d => {
      return Math.round(distance(d)) + 0.5;
    })
    .attr('y1', 0)
    .attr('x2', d => {
      return Math.round(distance(d)) + 0.5;
    })
    .attr('y2', scaleHeight)
    .attr('stroke', 'black')
    .attr('class', 'xTicks');
  axisGroup
    .append('svg:text')
    .text(units.toUpperCase())
    .attr('text-anchor', 'middle')
    .attr('font-family', `${FONT_FAMILY_PRIMARY}, Arial, sans-serif`)
    .attr('font-size', '8pt')
    .attr('x', Math.round(distance(data[data.length - 1] - (data[data.length - 1] - data[data.length - 2]) / 2)))
    .attr('y', -3);
  axisGroup
    .selectAll('text.xAxisBottom')
    .data(data)
    .enter()
    .append('svg:text')
    .text(function getValue(d, i) {
      const value = offsetData[i];
      if ((value * 10000) % (tickDiff * 10000) === 0) {
        return value;
      }
      return 0;
    })
    .attr('x', function distanceAttr(d) {
      return distance(d);
    })
    .attr('y', -3)
    .attr('text-anchor', 'middle')
    .attr('class', 'xAxisBottom')
    .attr('font-family', `${FONT_FAMILY_PRIMARY}, Arial, sans-serif`)
    .attr('font-size', '8pt');
  return axisGroup
    .selectAll('.shading')
    .data(data)
    .enter()
    .append('svg:line')
    .attr('x1', d => {
      return Math.round(distance(d)) + 0.5;
    })
    .attr('y1', scaleHeight / 2 + 0.5)
    .attr('x2', (d, i) => {
      if (i < data.length - 1) {
        return Math.round(distance(data[i + 1])) + 0.5;
      }
      return Math.round(distance(d)) + 0.5;
    })
    .attr('y2', scaleHeight / 2 + 0.5)
    .attr('stroke', (d, i) => {
      if (i % 2 === 0) {
        return 'black';
      }
      return 'none';
    })
    .attr('class', 'shading');
};

const DistanceScale = ({ bounds, mapCenter, reactMap, isMobileWidth, paperSize, orientation, scale, grid, getCustomPaperDimensions }) => {
  const distanceScaleContainerRef = useRef();
  const distanceScaleRef = useRef();
  const distanceScaleMetricRef = useRef();
  const intl = useIntl();

  useEffect(() => {
    renderDistanceScale(bounds, mapCenter, false, reactMap, distanceScaleMetricRef, distanceScaleRef, distanceScaleContainerRef, intl);
  }, [bounds, mapCenter, reactMap, isMobileWidth, paperSize, orientation, scale, grid, getCustomPaperDimensions]);

  useEffect(() => {
    renderDistanceScale(bounds, mapCenter, true, reactMap, distanceScaleMetricRef, distanceScaleRef, distanceScaleContainerRef, intl);
  }, [bounds, mapCenter, reactMap, isMobileWidth, paperSize, orientation, scale, grid, getCustomPaperDimensions]);

  return (
    <div
      className="distanceScaleContainer"
      ref={distanceScaleContainerRef}
      style={{
        width: paperSize === 'custom' ? `${getCustomPaperDimensions()?.width - 4.5}in` : ''
      }}
    >
      <div data-testid="distance-scale" className="distanceScale" ref={distanceScaleRef} />
      <div data-testid="distance-scale-metric" className="distanceScaleMetric" ref={distanceScaleMetricRef} />
      <div className="mapUtmZone" />
    </div>
  );
};

DistanceScale.propTypes = {
  bounds: object,
  mapCenter: object,
  reactMap: object,
  isMobileWidth: bool,
  paperSize: string,
  orientation: string,
  grid: string,
  scale: number,
  getCustomPaperDimensions: func
};

DistanceScale.defaultProps = {
  bounds: {},
  mapCenter: {},
  reactMap: {},
  isMobileWidth: false,
  paperSize: '',
  orientation: '',
  grid: '',
  scale: 0,
  getCustomPaperDimensions: () => {}
};

export { DistanceScale };
