import { useState, useEffect, useContext } from 'react';
import classNames from 'classnames';
import Button from '@alltrails/shared/denali/components/Button';
import IconButton from '@alltrails/shared/denali/components/IconButton';
import useFormatMessage from '@alltrails/shared/hooks/useFormatMessage';
import Close from '@alltrails/shared/icons/Close';
import Info from '@alltrails/shared/icons/Info';
import { defineMessages, useIntl } from '@alltrails/shared/react-intl';

import LegacyModal from 'components/LegacyModal';
import { MIN_DIMENSION, MAX_DIMENSION } from '../../constants';
import { formatDimension, fromInches, getUnit } from '../../utils';
import { PrintContext } from '../../printContext';
import GridRow from '../settings/GridRow';

const LEARN_MORE_STRINGS = defineMessages({
  LEARN_MORE: { defaultMessage: 'Learn more' },
  PAPER_SIZE: { defaultMessage: 'Paper size' },
  WIDTH_HEIGHT: { defaultMessage: 'Width x height' },
  LETTER: { defaultMessage: 'Letter' },
  A4: { defaultMessage: 'A4' },
  LETTER_DIMENSIONS: { defaultMessage: '8.5” x 11”' },
  A4_DIMENSIONS: { defaultMessage: '21.0 x 29.7 cm' },
  LEGAL: { defaultMessage: 'Legal' },
  A3: { defaultMessage: 'A3' },
  LEGAL_DIMENSIONS: { defaultMessage: '8.5” x 14”' },
  A3_DIMENSIONS: { defaultMessage: '42.0 x 29.7 cm' },
  TABLOID: { defaultMessage: 'Tabloid' },
  A2: { defaultMessage: 'A2' },
  TABLOID_DIMENSIONS: { defaultMessage: '11” x 17”' },
  A2_DIMENSIONS: { defaultMessage: '59.4 x 42.0 cm' },
  CUSTOM: { defaultMessage: 'Custom' },
  MIN_IMPERIAL: { defaultMessage: 'Min {value} x {value}' },
  MAX_IMPERIAL: { defaultMessage: 'Max {value} x {value}' },
  MIN_METRIC: { defaultMessage: 'Min {value} x {value} {unit}' },
  MAX_METRIC: { defaultMessage: 'Max {value} x {value} {unit}' },
  ORIENTANTION: { defaultMessage: 'Orientation' },
  LAYOUT: { defaultMessage: 'Layout' },
  PORTRAIT: { defaultMessage: 'Portrait' },
  VERTICAL: { defaultMessage: 'Vertical' },
  LANDSCAPE: { defaultMessage: 'Landscape' },
  HORIZONTAL: { defaultMessage: 'Horizontal' },
  GRID: { defaultMessage: 'Grid' },
  DESCRIPTION: { defaultMessage: 'Description' },
  DECIMAL: { defaultMessage: 'Decimal' },
  DECIMAL_DEGREES: { defaultMessage: 'Decimal degrees' },
  DMS: { defaultMessage: 'Degrees, minutes, seconds' },
  UTM: { defaultMessage: 'Universal transverse mercator' },
  CLOSE: { defaultMessage: 'Close' }
});

type Props = {
  title?: string;
  content?: string;
  paperSize?: boolean;
  orientation?: boolean;
  grid?: boolean;
  isMobileWidth?: boolean;
};

const LearnMore = ({ title = '', paperSize = false, content = '', orientation = false, grid = false, isMobileWidth = false }: Props) => {
  const intl = useIntl();
  const {
    formattedDefaultMessages: {
      LEARN_MORE,
      PAPER_SIZE,
      WIDTH_HEIGHT,
      LETTER,
      A4,
      LETTER_DIMENSIONS,
      A4_DIMENSIONS,
      LEGAL,
      A3,
      LEGAL_DIMENSIONS,
      A3_DIMENSIONS,
      TABLOID,
      A2,
      TABLOID_DIMENSIONS,
      A2_DIMENSIONS,
      CUSTOM,
      ORIENTANTION,
      LAYOUT,
      PORTRAIT,
      VERTICAL,
      LANDSCAPE,
      HORIZONTAL,
      GRID,
      DESCRIPTION,
      DECIMAL,
      DECIMAL_DEGREES,
      DMS,
      UTM,
      CLOSE
    },
    formatMessage
  } = useFormatMessage(LEARN_MORE_STRINGS);
  const { metric } = useContext(PrintContext);
  const [visible, setVisible] = useState(false);

  const handleKeyDown = (e: KeyboardEvent) => {
    if (visible && e.keyCode === 27) {
      setVisible(false); // Esc
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  });

  const handleCloseModal = () => {
    setVisible(false);
  };

  const rederGridView = () => {
    if (paperSize) {
      return (
        <div className="detailTable">
          <GridRow columnTitle={PAPER_SIZE} secondColumnTitle={WIDTH_HEIGHT} titleRow />
          <GridRow propTitle={metric ? A4 : LETTER} propsDesc={metric ? A4_DIMENSIONS : LETTER_DIMENSIONS} />
          <GridRow propTitle={metric ? A3 : LEGAL} propsDesc={metric ? A3_DIMENSIONS : LEGAL_DIMENSIONS} />
          <GridRow propTitle={metric ? A2 : TABLOID} propsDesc={metric ? A2_DIMENSIONS : TABLOID_DIMENSIONS} />
          <GridRow
            propTitle={CUSTOM}
            propsDesc={
              metric
                ? formatMessage('MIN_METRIC', {
                    value: intl.formatNumber(fromInches(MIN_DIMENSION, metric), { minimumFractionDigits: 1, maximumFractionDigits: 1 }),
                    unit: getUnit(metric, intl)
                  })
                : formatMessage('MIN_IMPERIAL', { value: formatDimension(MIN_DIMENSION, metric, intl) })
            }
          />
          <GridRow
            propsDesc={
              metric
                ? formatMessage('MAX_METRIC', {
                    value: intl.formatNumber(fromInches(MAX_DIMENSION, metric), { minimumFractionDigits: 1, maximumFractionDigits: 1 }),
                    unit: getUnit(metric, intl)
                  })
                : formatMessage('MAX_IMPERIAL', { value: formatDimension(MAX_DIMENSION, metric, intl) })
            }
            skip
          />
        </div>
      );
    }
    if (orientation) {
      return (
        <div className="detailTable">
          <GridRow columnTitle={ORIENTANTION} secondColumnTitle={LAYOUT} titleRow />
          <GridRow propTitle={PORTRAIT} propsDesc={VERTICAL} />
          <GridRow propTitle={LANDSCAPE} propsDesc={HORIZONTAL} />
        </div>
      );
    }
    if (grid) {
      return (
        <div className="detailTable">
          <GridRow columnTitle={GRID} secondColumnTitle={DESCRIPTION} titleRow />
          <GridRow propTitle={DECIMAL} propsDesc={DECIMAL_DEGREES} />
          <GridRow propTitle="D°M’S”" propsDesc={DMS} />
          <GridRow propTitle="UTM" propsDesc={UTM} />
        </div>
      );
    }
    return null;
  };

  const renderModal = () => {
    if (!visible) {
      return null;
    }

    return (
      <LegacyModal handleBackgroundClick={() => setVisible(false)}>
        <div className={classNames('learnMoreModal', { scale: !paperSize && !grid && !orientation })}>
          <IconButton icon={{ Component: Close }} onClick={handleCloseModal} testId="closeIcon" title={CLOSE} variant="flat" />
          <span className="modalTitle"> {title} </span>
          <div className={classNames('contentContainer', { isMobile: isMobileWidth })}>
            <div className={classNames('content', { scale: !paperSize && !grid && !orientation })}> {content} </div>
            {rederGridView()}
          </div>
        </div>
      </LegacyModal>
    );
  };

  return (
    <>
      <Button text={LEARN_MORE} icon={{ Component: Info }} onClick={() => setVisible(true)} size="sm" testId="print-page-learn-more" variant="flat" />
      {renderModal()}
    </>
  );
};

export default LearnMore;
