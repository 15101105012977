import { featureCollection, point } from '@turf/helpers';
import { CURRENT } from 'utils/constants/MarkerIds';
import { addMarkers, removeMarkers } from './markers';

const sourceLayerId = 'current-location';

const addCurrLocMarker = map => {
  addMarkers(map, sourceLayerId, [], CURRENT, null);
};

const removeCurrLocMarker = map => {
  removeMarkers(map, sourceLayerId);
};

const updateCurrLocMarker = (map, lngLat) => {
  let geojson;
  if (lngLat) {
    geojson = point(lngLat);
  } else {
    geojson = featureCollection([]);
  }
  const source = map.getSource(sourceLayerId);
  if (source) {
    source.setData(geojson);
  }
};

export { addCurrLocMarker, removeCurrLocMarker, updateCurrLocMarker };
