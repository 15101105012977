import { useState, useEffect } from 'react';
import getTrailHistory from 'api/TrailHistory';
import type { TrailId } from '@alltrails/shared/types/trail';
import logError from 'utils/logError';
import * as styles from './styles/styles.module.scss';

type TrailEditHistoryProps = {
  trailId: TrailId;
  visible: boolean;
};

const TrailEditHistory = ({ trailId, visible }: TrailEditHistoryProps) => {
  const [history, setHistory] = useState([]);
  const [loading, setLoading] = useState(null);

  useEffect(() => {
    if (visible && loading === null) {
      setLoading(true);
      getTrailHistory(trailId)
        .then(setHistory)
        .catch(err => logError(err))
        .finally(() => setLoading(false));
    }
  }, [loading, trailId, visible]);

  if (loading) {
    return (
      <div className={styles.container}>
        <p>Loading...</p>
      </div>
    );
  }

  const header = (
    <tr>
      <th>Field</th>
      <th>Date</th>
      <th>Admin</th>
      <th>User</th>
      <th>Approver</th>
      <th>Approved</th>
      <th>New Value</th>
    </tr>
  );
  const rows = history.map(change => (
    <tr key={`${change.id}`}>
      <td>{change.name}</td>
      <td>{change.date}</td>
      <td>{change.userType}</td>
      <td>{change.user}</td>
      <td>
        {change.approver} {change.approvalInferred && '**'}
      </td>
      <td>{change.approvedDate}</td>
      <td>{change.value}</td>
    </tr>
  ));
  return (
    <div className={styles.container}>
      <table>
        <thead>{header}</thead>
        <tbody>{rows}</tbody>
      </table>
    </div>
  );
};

export default TrailEditHistory;
