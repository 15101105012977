import { useFlyoverSettings } from 'components/MapProvider';
import useUser from 'hooks/useUser';
import { useEffect, useState } from 'react';
import FlyoverPreviewUpsellModal from './FlyoverUpsell/FlyoverPreviewUpsellModal';

type Props = {
  disable3D: () => void;
  initFlyoverOnLoad?: boolean;
  exactExplorePath: string;
  onFlyoverDisabled: () => void;
  setLayer: (layer: string) => void;
};

export default function FlyoverUpsellWrapper({ disable3D, exactExplorePath, initFlyoverOnLoad, onFlyoverDisabled, setLayer }: Props) {
  const { flyoverLoadingState, togglePauseFlyover } = useFlyoverSettings();
  const user = useUser();
  const [isUpsellOpen, setIsUpsellOpen] = useState(false);

  useEffect(() => {
    if (flyoverLoadingState === 'done' && !user?.pro) {
      setTimeout(
        () => {
          setIsUpsellOpen(true);
        },
        window.localStorage.getItem('flyoverUpsell') ? 800 : 3000
      );

      window.localStorage.setItem('flyoverUpsell', 'true');
    }
  }, [flyoverLoadingState, togglePauseFlyover, user?.pro]);

  if (isUpsellOpen) {
    return (
      <FlyoverPreviewUpsellModal
        disable3D={disable3D}
        exactExplorePath={exactExplorePath}
        initFlyoverOnLoad={initFlyoverOnLoad}
        onFlyoverDisabled={onFlyoverDisabled}
        onSuccess={() => {
          setIsUpsellOpen(false);
          togglePauseFlyover();
        }}
        setLayer={setLayer}
      />
    );
  }

  return null;
}
