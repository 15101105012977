import { destroy, post, put } from '@alltrails/shared/api';
import type { TrailId } from '@alltrails/shared/types/trail';
import Waypoint, { WaypointId } from 'types/Waypoint';

type WaypointsMeta = {
  items: number;
  status: string;
  timestamp: string;
};

export type WaypointChangesResponse = {
  meta: WaypointsMeta;
  waypoints: Waypoint[];
};

export type AddWaypointToTrailPayload = {
  description: string;
  description_source?: string;
  location: Waypoint['location'];
  name: string;
  name_source?: string;
};

export function addWaypointToTrail(trailId: TrailId, waypoint: AddWaypointToTrailPayload) {
  const waypointPayload = {
    description: waypoint.description_source ? waypoint.description_source : waypoint.description,
    latitude: waypoint.location.latitude,
    longitude: waypoint.location.longitude,
    name: waypoint.name_source ? waypoint.name_source : waypoint.name
  };
  return post<WaypointChangesResponse>(`/api/alltrails/trails/${trailId}/waypoints`, waypointPayload);
}

export type AddWaypointToMapPayload = {
  description: string;
  location: Waypoint['location'];
  name: string;
  showTitle: boolean;
};

export function addWaypointToMap(mapId: number, waypoint: AddWaypointToMapPayload) {
  const waypointPayload = {
    description: waypoint.description,
    latitude: waypoint.location.latitude,
    longitude: waypoint.location.longitude,
    name: waypoint.name,
    showTitle: waypoint.showTitle
  };
  return post<WaypointChangesResponse>(`/api/alltrails/maps/${mapId}/waypoints`, waypointPayload);
}

export type UpdateWaypointPayload = {
  description: string;
  latitude: number;
  longitude: number;
  name: string;
  showTitle: boolean;
};

export function updateWaypoint(atMapId: number, waypointId: WaypointId, waypointChanges: UpdateWaypointPayload) {
  return put<WaypointChangesResponse>(`/api/alltrails/maps/${atMapId}/waypoints/${waypointId}`, waypointChanges);
}

export function deleteWaypoint(atMapId: number, waypointId: WaypointId) {
  return destroy<{ meta: { status: string } }>(`/api/alltrails/maps/${atMapId}/waypoints/${waypointId}`);
}

export function reorderWaypoints(atMapId: number, orderedWaypointIds: WaypointId[]) {
  return post<WaypointChangesResponse>(`/api/alltrails/maps/${atMapId}/waypoints/reorder`, { ordered_waypoint_ids: orderedWaypointIds });
}

export function updateAllWaypoints(atMapId: number, toUpdate: Partial<Waypoint> & { showTitle?: boolean }) {
  return put<WaypointChangesResponse>(`/api/alltrails/maps/${atMapId}/waypoints/all`, toUpdate);
}

export function deleteAllWaypoints(atMapId: number) {
  return destroy<{ meta: { status: string } }>(`/api/alltrails/maps/${atMapId}/waypoints/all`);
}
