import { useState } from 'react';
import NoSsr from '@material-ui/core/NoSsr';
import { DragDropContext, Droppable, DroppableProvided, DropResult, Draggable, DraggableProvided } from 'react-beautiful-dnd';
import reorder from '@alltrails/shared/utils/reorder';
import type { Context } from 'types/Context';
import Track from '@alltrails/shared/types/track';
import { saveListItems } from 'api/List';
import TrailsList from 'components/shared/TrailsList';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import type Trail from 'types/Trails/Trail';
import logError from 'utils/logError';
import { ServerCommunicationUtil } from 'utils/server_communication_util';
import ListMethods from 'types/ListMethods';

type SortableTrailsListProps = {
  allowReordering: boolean;
  context: Context;
  initialTrails: Trail[];
  listId: number;
  listMethods: ListMethods;
  locationServerData: any;
  serverTotalItems: number;
  userListItems: unknown;
};

const SortableTrailsList = ({
  allowReordering,
  context,
  initialTrails,
  listId,
  listMethods,
  locationServerData,
  serverTotalItems,
  userListItems
}: SortableTrailsListProps): JSX.Element => {
  const languageRegionCode = useLanguageRegionCode();
  const [trails, setTrails] = useState(initialTrails);

  const saveListOrder = (newOrder: (Trail | Track)[]) => {
    const orderedListItemIds = newOrder.map(
      trail =>
        listMethods.getListItemInList(
          listId,
          trail.type,
          trail.type === 'trail' ? trail.trail_id : (trail as Track).id ?? (trail as Track).ID,
          userListItems
        )?.id
    );
    saveListItems(listId, orderedListItemIds)
      .then(response => {
        if (response.success) {
          listMethods.updateListOrder(response.listItems, listId);

          // dynamically set hero image if it changes in reordering
          // TODO: avoid using jquery - use react-based approach when refactoring list page. Remove from lists-page-css.js as well.
          if (response.profile_photo_url) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            $('#list-profile-photo').css({
              'background-image': `linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.6)), url("${response.profile_photo_url}")`,
              'background-repeat': 'no-repeat',
              'background-position': 'center center'
            });
          }
        }
      })
      .catch(logError);
  };

  const onDragEnd = ({ destination, source }: DropResult) => {
    if (!destination) {
      return;
    }

    const results = reorder(trails, source.index, destination.index);
    setTrails(results);
    saveListOrder(results);
  };

  const loadMoreListItems = (pageNum: number, perPage: number, onSuccess: (newTrails: Trail[]) => void) => {
    ServerCommunicationUtil.loadMoreContent(
      'items',
      locationServerData.country_id,
      locationServerData.type,
      listId,
      pageNum,
      perPage,
      false,
      locationServerData.attribute,
      (data: any) => {
        const results = data.listItems.map((listItem: any) => listItem.object);
        setTrails(trails.concat(results));
        onSuccess(results);
      },
      false,
      null,
      null,
      languageRegionCode
    );
  };

  const trailRenderer = (
    trail: Trail | Track,
    idx: number,
    trailCard: (
      innerRef?: (element: HTMLElement) => any,
      draggableProps?: { [key: string]: any },
      dragHandleProps?: { [key: string]: any }
    ) => JSX.Element
  ) => {
    const uniqueId = trail.type === 'trail' ? trail.trail_id : (trail as Track).id ?? (trail as Track).ID;
    return (
      <Draggable key={uniqueId} draggableId={uniqueId.toString()} index={idx}>
        {({ innerRef, draggableProps, dragHandleProps }: DraggableProvided) => trailCard(innerRef, draggableProps, dragHandleProps)}
      </Draggable>
    );
  };

  return (
    <NoSsr>
      {allowReordering ? (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {({ innerRef, droppableProps, placeholder }: DroppableProvided) => (
              <div ref={innerRef} {...droppableProps}>
                <TrailsList
                  context={context}
                  listMethods={listMethods}
                  loadMoreTrails={loadMoreListItems}
                  serverTotalItems={serverTotalItems}
                  trailRenderer={trailRenderer}
                  trails={trails}
                />
                {placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      ) : (
        <TrailsList
          context={context}
          listMethods={listMethods}
          loadMoreTrails={loadMoreListItems}
          serverTotalItems={serverTotalItems}
          trails={trails}
        />
      )}
    </NoSsr>
  );
};

export default SortableTrailsList;
