import mapboxgl, { LngLat, LngLatLike, MapboxGeoJSONFeature, Popup, MapLayerTouchEvent, Map } from 'mapbox-gl';
import ReactDOM from 'react-dom';
import { addLayerEvent } from '../layers/layer_helpers';
import IconButtonGroup from '@alltrails/shared/denali/components/IconButtonGroup';
import { ReactElement } from 'react';

const mountReactComponent = (component: ReactElement) => {
  const domContent = document.createElement('div');
  ReactDOM.render(component, domContent);
  return domContent;
};

const unmountReactComponent = (domContent: Element) => {
  ReactDOM.unmountComponentAtNode(domContent);
  domContent.remove();
};

// Ensure that if the map is zoomed out such that multiple
// copies of the feature are visible, the popup appears
// over the copy being pointed to.
const getAdjustedCoordinates = (lngLat: LngLat, feature: MapboxGeoJSONFeature): LngLatLike => {
  const geometry = feature.geometry;
  if (geometry.type !== 'Point') {
    return lngLat;
  }
  const coordinates = geometry.coordinates.slice() as [number, number];
  while (Math.abs(lngLat.lng - coordinates[0]) > 180) {
    coordinates[0] += lngLat.lng > coordinates[0] ? 360 : -360;
  }
  return coordinates;
};

const getToolbarDOMContent = (toolbar: Popup) => {
  const element = toolbar.getElement();
  if (!element) {
    return null;
  }
  const popupContent = element.querySelector('.mapboxgl-popup-content');
  if (!popupContent) {
    return null;
  }
  return popupContent.children[0];
};

const addMarkerToolbarToMap = (map: Map, layerId: string, getToolbarConfig: () => any[]) => {
  const toolbar = new mapboxgl.Popup({
    closeButton: false,
    closeOnClick: true,
    maxWidth: 'none',
    focusAfterOpen: false,
    anchor: 'top-left',
    offset: 0,
  });

  const closeToolbar = () => {
    if (!toolbar.isOpen()) {
      return;
    }
    toolbar.remove();
    const domContent = getToolbarDOMContent(toolbar);
    if (domContent) {
      unmountReactComponent(domContent);
    }
  };

  const onRightClick = (e: MapLayerTouchEvent) => {
    const lngLat = e.lngLat;
    const feature = e.features[0];
    const coordinates = getAdjustedCoordinates(lngLat, feature);
    const toolbarButtons = getToolbarConfig();
    const toolbarComponent = mountReactComponent(
      <IconButtonGroup 
        buttons={toolbarButtons} 
        orientation="vertical"
        testId="in-map-toolbar"
      />
    );
    toolbarComponent.onmouseleave = () => {
      closeToolbar();
    };
    toolbarComponent.onclick = () => {
      closeToolbar();
    };
    toolbar.setLngLat(coordinates).setDOMContent(toolbarComponent).addTo(map);
  };

  addLayerEvent(map, layerId, true, 'contextmenu', onRightClick);

  return toolbar
};



export { addMarkerToolbarToMap };
