import { createContext, useContext } from 'react';
import { AllTrailsBounds } from '@alltrails/maps/types/Geo';
import { MapWeatherInfo } from '@alltrails/redux/slices/weather';
import Trail from 'types/Trails/Trail';
import { AppDispatch, RootState, useSelector } from './redux';

type ContextType = {
  getWeatherInfo: (state: RootState) => MapWeatherInfo;
  getMapWeather: () => Promise<ReturnType<AppDispatch>>;
  getConditions: () => Promise<ReturnType<AppDispatch>>;
  getOverview: () => Promise<ReturnType<AppDispatch>>;
  updateDate: (newDate: Date) => ReturnType<AppDispatch>;
  updateTime: (newTime: Date) => ReturnType<AppDispatch>;
  mapboxAccessToken: string;
  trail: Trail;
  bounds: AllTrailsBounds;
};

export const TrailWeatherContext = createContext<ContextType>({
  getWeatherInfo: null,
  getMapWeather: null,
  getConditions: null,
  getOverview: null,
  updateDate: null,
  updateTime: null,
  mapboxAccessToken: null,
  trail: null,
  bounds: null
});

export const useTrailWeatherContext = () => useContext(TrailWeatherContext);

export const useTrailWeather = () => {
  const { getWeatherInfo } = useTrailWeatherContext();
  return useSelector(state => getWeatherInfo(state));
};
