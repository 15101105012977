import logTrailDetailsDirectionsButtonTapped from '@alltrails/analytics/events/logTrailDetailsDirectionsButtonTapped';
import CarouselDisplayTrigger from '@alltrails/analytics/enums/CarouselDisplayTrigger';
import Car from '@alltrails/shared/icons/Car';
import { COLOR_INTERACTIVE_DEFAULT } from '@alltrails/shared/denali/tokens';
import DirectionsButtonType from '@alltrails/analytics/enums/DirectionsButtonType';
import useUser from 'hooks/useUser';
import type { TrailId } from '@alltrails/shared/types/trail';
import SignupModal from 'components/SignupModal';
import useToggle from '@alltrails/shared/hooks/useToggle';
import { useIntl } from '@alltrails/shared/react-intl';
import ActionBarItem from '../ActionBarItem';

type Props = {
  disabled: boolean;
  geoloc: { lat: number; lng: number };
  trailId: TrailId;
};

const DirectionsButton = ({ disabled = false, geoloc = { lat: null, lng: null }, trailId }: Props) => {
  const intl = useIntl();
  const [isOpen, toggle] = useToggle();
  const user = useUser();
  const url = `https://www.google.com/maps/dir/Current+Location/${geoloc.lat},${geoloc.lng}`;

  return (
    <>
      <SignupModal
        trigger={CarouselDisplayTrigger.TrailDetailDirections}
        isOpen={isOpen}
        onRequestClose={() => {
          toggle();
        }}
        onSuccess={() => {
          toggle();
          window.open(url, '_blank', 'noopener noreferrer');
        }}
      />
      <ActionBarItem
        disabled={disabled || !geoloc.lat || !geoloc.lng}
        icon={<Car color={COLOR_INTERACTIVE_DEFAULT} />}
        testId="directions-action"
        onClick={() => {
          logTrailDetailsDirectionsButtonTapped({ directions_button_type: DirectionsButtonType.ActionBarIcon, trail_id: trailId });

          if (!user) {
            toggle();
            return;
          }

          window.open(url, '_blank', 'noopener noreferrer');
        }}
        title={intl.formatMessage({ defaultMessage: 'Directions' })}
      />
    </>
  );
};

export default DirectionsButton;
