import { useCallback } from 'react';
import classNames from 'classnames';
import { defineMessages } from '@alltrails/shared/react-intl';
import Plus from '@alltrails/shared/icons/Plus';
import { COLOR_INTERACTIVE_PRIMARY } from '@alltrails/shared/denali/tokens';
import useFormatMessage from '@alltrails/shared/hooks/useFormatMessage';
import logCustomMapFeatureSubmitted from '@alltrails/analytics/events/logCustomMapFeatureSubmitted';
import CustomMapFeatureAdded from '@alltrails/analytics/enums/CustomMapFeatureAdded';
import type { Context } from 'types/Context';
import { ServerCommunicationUtil } from '../../../utils/server_communication_util';
import PhotoGrid from './PhotoGrid';
import * as styles from './styles/styles.module.scss';

const messages = defineMessages({
  ADD_PHOTOS: { defaultMessage: 'Add new photos' }
});

type PhotoSectionProps = {
  canEdit?: boolean;
  isEditing: boolean;
  mapId: number;
  mapPhotos: unknown[];
  openTrailFormModal: (type: string, data: unknown) => void;
  updateMapPhotos: (detail: unknown) => void;
  context: Context;
};

const PhotoSection = ({ isEditing, openTrailFormModal, mapId, updateMapPhotos, canEdit = false, mapPhotos, context }: PhotoSectionProps) => {
  const {
    formattedDefaultMessages: { ADD_PHOTOS }
  } = useFormatMessage(messages);

  const addMarkersAndUpload = useCallback(
    ({ detail }: any) => {
      // PhotoUploadModal calls this function multiple times per upload. Only log the event when the photo is actually added.
      if (detail && detail.length > 0) {
        logCustomMapFeatureSubmitted({ feature_added: CustomMapFeatureAdded.Photo });
      }
      updateMapPhotos(detail);
      // TODO add markers
    },
    [updateMapPhotos]
  );

  const openAddPhotoModal = () => {
    const url = `/api/alltrails/maps/${mapId}/photos?key=${ServerCommunicationUtil.apiKey}`;
    openTrailFormModal('photos', { url, onPhotosAdd: addMarkersAndUpload });
  };

  const hasMapPhotos = mapPhotos.length > 0;

  const showAddButton = canEdit || isEditing;

  return (
    <div>
      <ul className={classNames(styles.container, { [styles.withPhotos]: hasMapPhotos })}>
        {showAddButton && (
          <li key="add-photos-btn">
            <button
              aria-label={ADD_PHOTOS}
              className={styles.addPhotosBtn}
              onClick={openAddPhotoModal}
              data-testid="map-creator-add-new-photos"
              type="button"
            >
              <Plus color={COLOR_INTERACTIVE_PRIMARY} size="md" />
            </button>
          </li>
        )}
        {hasMapPhotos ? (
          <PhotoGrid mapPhotos={mapPhotos} mapId={mapId} context={context} />
        ) : (
          [1, 2, 3].map(i => <li key={i} className={styles.placeholder} />)
        )}
        <div className={styles.spacer} />
      </ul>
    </div>
  );
};

export default PhotoSection;
