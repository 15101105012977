import { useCallback } from 'react';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import reorder from '@alltrails/shared/utils/reorder';
import { defineMessages, useIntl } from '@alltrails/shared/react-intl';
import { reorderWaypoints, deleteWaypoint } from 'api/Waypoints';
import { WaypointsAction } from 'hooks/useWaypoints';
import type { TrailId } from '@alltrails/shared/types/trail';
import Waypoint, { WaypointId } from 'types/Waypoint';
import WaypointCard from '../WaypointCard';
import WaypointForm from '../WaypointForm';

const message = defineMessages({ delete: { defaultMessage: 'Delete this waypoint?' } });

type WaypointsProps = {
  canEdit: boolean;
  createMapWithWaypoint: (waypoint: Waypoint) => void;
  dispatchFormState?: boolean;
  dispatchWaypoints: React.Dispatch<WaypointsAction>;
  editIndex?: number;
  mapId: number;
  messagingChannel: any;
  sortMode: boolean;
  trailId: TrailId;
  waypoints: Waypoint[];
  refetchTrailProps?: () => void;
  onWaypointFormSubmit?: () => void;
};

const Waypoints = ({
  waypoints,
  editIndex,
  dispatchWaypoints,
  messagingChannel,
  mapId,
  sortMode,
  canEdit,
  trailId,
  createMapWithWaypoint,
  dispatchFormState,
  refetchTrailProps,
  onWaypointFormSubmit
}: WaypointsProps): JSX.Element => {
  let editWaypoint: (payload: unknown) => void;
  let handleDeleteWaypoint: (id: WaypointId) => void;
  let persistWaypointOrder: () => void;
  let toggleSortMode: () => void;
  let onDragEnd;

  const intl = useIntl();

  if (canEdit) {
    editWaypoint = (payload: number) => {
      if (!canEdit) {
        return;
      }

      dispatchWaypoints({ type: 'UPDATE_EDIT_INDEX', payload });
    };

    handleDeleteWaypoint = (id: WaypointId) => {
      if (!canEdit || !confirm(intl.formatMessage(message.delete))) {
        return;
      }

      deleteWaypoint(mapId, id).then(() => {
        dispatchWaypoints({ type: 'REMOVE_WAYPOINT', payload: id });
        refetchTrailProps?.();
      });
    };

    persistWaypointOrder = () => {
      const orderedWaypointIds = waypoints.map(wp => wp.id);
      reorderWaypoints(mapId, orderedWaypointIds).then(() => {
        dispatchWaypoints({ type: 'TOGGLE_SORT_MODE', payload: false });
      });
    };

    // eslint-disable-next-line react-hooks/rules-of-hooks
    toggleSortMode = useCallback(() => {
      dispatchWaypoints({ type: 'TOGGLE_SORT_MODE', payload: !sortMode });
    }, [sortMode]);

    onDragEnd = (result: DropResult) => {
      const payload = reorder(waypoints, result.source.index, result.destination.index).map((wp, idx) => ({ ...wp, order: idx + 1 }));

      dispatchWaypoints({ type: 'REORDER_WAYPOINTS', payload });
    };
  }

  const handleMouseOver = (id: number, location: unknown) => {
    if (!messagingChannel) {
      return;
    }

    messagingChannel.publish({
      topic: 'waypoint.hover',
      data: { id, location }
    });
  };

  if (sortMode) {
    const renderDraggableWaypoint = (waypoint: Waypoint, idx: number) => (
      <Draggable key={`${waypoint.id}`} draggableId={`${waypoint.id}`} index={idx}>
        {provided => (
          <li ref={provided.innerRef} {...provided.draggableProps}>
            <WaypointCard
              {...waypoint}
              provided={provided}
              sortMode={sortMode}
              canEdit={canEdit}
              handleMouseOver={handleMouseOver}
              key={waypoint.id}
              idx={idx}
              toggleSortMode={toggleSortMode}
              persistWaypointOrder={persistWaypointOrder}
            />
          </li>
        )}
      </Draggable>
    );

    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {provided => (
            <ul {...provided.droppableProps} ref={provided.innerRef}>
              {waypoints.map((waypoint, idx) => renderDraggableWaypoint(waypoint, idx))}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
    );
  }

  const toggleLanguageOrigin = (idx: number, showInTranslatedLang: boolean) => {
    dispatchWaypoints({ type: 'TOGGLE_SHOW_TRANSLATED_LANG', payload: { idx, showInTranslatedLang: !showInTranslatedLang } });
  };

  return (
    <>
      {waypoints.map((waypoint, idx) =>
        idx === editIndex && canEdit ? (
          <WaypointForm
            key="waypoint-form"
            {...waypoint}
            dispatchWaypoints={dispatchWaypoints}
            messagingChannel={messagingChannel}
            mapId={mapId}
            trailId={trailId}
            createMapWithWaypoint={createMapWithWaypoint}
            dispatchFormState={dispatchFormState}
            refetchTrailProps={refetchTrailProps}
            onSubmit={onWaypointFormSubmit}
          />
        ) : (
          <WaypointCard
            key={waypoint.id}
            {...waypoint}
            idx={idx}
            editWaypoint={editWaypoint}
            deleteWaypoint={handleDeleteWaypoint}
            totalWaypointsLength={waypoints.length}
            toggleSortMode={toggleSortMode}
            sortMode={sortMode}
            canEdit={canEdit}
            handleMouseOver={handleMouseOver}
            toggleLanguageOrigin={toggleLanguageOrigin}
          />
        )
      )}
    </>
  );
};

export default Waypoints;
