import React, { useState, useCallback } from 'react';
import { useIntl, FormattedMessage, IntlShape } from '@alltrails/shared/react-intl';
import hasPermission from 'utils/hasPermission';
import Link from '@alltrails/shared/denali/components/Link';
import Checkbox from '@alltrails/shared/denali/components/Checkbox';
import ModalBackground from '@alltrails/shared/components/modals/ModalBackground';
import ModalInterior from '@alltrails/shared/components/modals/ModalInterior';
import OutlinedTextField from '@alltrails/shared/components/OutlinedTextField';
import DatePicker from '@alltrails/shared/components/DatePicker';
import { adjustFromSameNominalTimeForUser, adjustToSameNominalTimeForUser } from '@alltrails/shared/utils/timeHelpers';
import CustomProvider from 'components/CustomProvider';
import Alert from '@alltrails/shared/types/alert';
import * as styles from './styles/styles.module.scss';
import * as formStyles from '../forms/styles/styles.module.scss';
import SelectDropdown from '../forms/SelectDropdown';
import { alertTypes, defaultAlertType } from '../../utils/alerts_helpers';

type TrailAlertModalProps = {
  alert?: Alert;
  closeModal: () => void;
  handleSubmit: (formParams: Alert) => void;
  showAreaFields: boolean;
};

const BaseTrailAlertModal = ({ alert, showAreaFields, closeModal, handleSubmit, intl }: TrailAlertModalProps & { intl: IntlShape }): JSX.Element => {
  // We're considering the date to be "timezone-less" so adjust it (originally UTC) to be same nominal time current admin's timezone
  const [formParams, updateFormParams] = useState({
    applyToNewTrails: false,
    applyToAreaPage: false,
    showStartDate: alert && !!alert.startDate,
    showEndDate: alert && !!alert.endDate,
    ...alert,
    startDate: alert && alert.startDate ? adjustToSameNominalTimeForUser(alert.startDate) : null,
    endDate: alert && alert.endDate ? adjustToSameNominalTimeForUser(alert.endDate) : null,
    recursAnnually: alert && !!alert.recursAnnually
  });
  const [errorMessage, updateErrorMessage] = useState(null);

  const updateForm = (payload: any) => {
    updateFormParams(prevState => ({ ...prevState, ...payload }));
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    const payload = { [name]: value };

    updateForm(payload);
  };

  const handleDateChange = (attribute: any, date: unknown) => {
    updateErrorMessage(null);
    updateForm({ [attribute]: date });
  };

  const handleCtaClick = () => {
    // We're considering the date to be "timezone-less" so adjust it to be the same nominal value in UTC as in current admin's timezone
    const adjustedStartDate = formParams.showStartDate && formParams.startDate ? adjustFromSameNominalTimeForUser(formParams.startDate) : null;
    const adjustedEndDate = formParams.showEndDate && formParams.endDate ? adjustFromSameNominalTimeForUser(formParams.endDate) : null;
    if (adjustedStartDate && adjustedEndDate && adjustedStartDate > adjustedEndDate) {
      updateErrorMessage('Expiration must be on or after start date');
      return;
    }
    if (formParams.showStartDate && formParams.showEndDate && formParams.recursAnnually && (!adjustedStartDate || !adjustedStartDate)) {
      updateErrorMessage('Recurrent alert needs start and end dates');
      return;
    }

    const data = {
      ...formParams,
      startDate: adjustedStartDate ? adjustedStartDate.toUTCString() : null,
      endDate: adjustedEndDate ? adjustedEndDate.toUTCString() : null,
      recursAnnually: adjustedStartDate && adjustedEndDate ? formParams.recursAnnually : null
    };
    delete data.showStartDate;
    delete data.showEndDate;
    handleSubmit(data);
  };

  const onAlertTypeSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const alertType = e.currentTarget.dataset.value;
    updateForm({ alertType });
  };

  const toggleApplyToAreaPage = () => {
    const applyToAreaPage = !formParams.applyToAreaPage;
    updateForm({ applyToAreaPage });
  };

  const toggleApplyToNewTrails = () => {
    const applyToNewTrails = !formParams.applyToNewTrails;
    updateForm({ applyToNewTrails });
  };

  const toggleRecursAnnually = () => {
    const recursAnnually = !formParams.recursAnnually;
    updateForm({ recursAnnually });
  };

  const toggleShowDate = (attribute: keyof typeof formParams) => {
    updateForm({ [attribute]: !formParams[attribute] });
  };

  const renderDate = (params: {
    dateAttribute: 'endDate' | 'startDate';
    showAttribute: 'showEndDate' | 'showStartDate';
    disablePast: boolean;
    label: string;
    hint: string;
  }) => {
    const datePicker = formParams[params.showAttribute] ? (
      <>
        <DatePicker
          date={formParams[params.dateAttribute]}
          onChange={date => handleDateChange(params.dateAttribute, date)}
          abbreviateMonth
          disableFuture={false}
          disablePast={params.disablePast}
        />
        <div>{params.hint}</div>
      </>
    ) : null;
    return (
      <div className={styles.formSection}>
        <Checkbox
          className={styles.checkbox}
          id={params.showAttribute}
          testId={`checkbox-${params.showAttribute}`}
          labelElement={params.label}
          size="sm"
          selected={formParams[params.showAttribute]}
          onChange={() => toggleShowDate(params.showAttribute)}
        />
        {datePicker}
      </div>
    );
  };

  const renderRecursAnnually = () => {
    if (formParams.showStartDate && formParams.showEndDate) {
      return (
        <Checkbox
          className={styles.formSection}
          labelElement="Recurs annually"
          id="recursAnnually"
          size="sm"
          testId="checkbox-recursAnnually"
          selected={formParams.recursAnnually}
          onChange={toggleRecursAnnually}
        />
      );
    }
    return null;
  };

  const renderShortName = () => (
    <div className={styles.formSection}>
      <OutlinedTextField label="Internal Short Name" value={formParams.shortName} changeHandler={handleTextChange} name="shortName" />
    </div>
  );

  const renderTranslationLink = useCallback(
    (columnName: string) => {
      if (!hasPermission({ permission: 'trails:manage' }) || !alert?.id) return null;

      return (
        <Link
          href={`/edit-translations?table=alerts&column_name=${columnName}&id=${alert?.id}`}
          size="sm"
          testId={`edit-${columnName}-translation-link`}
          target="_blank"
          noUnderline
          variant="secondary"
          className={styles.linkToTranslations}
        >
          <FormattedMessage defaultMessage="Edit translations" />
        </Link>
      );
    },
    [alert]
  );

  const renderTitle = () => (
    <div className={styles.formSection}>
      <OutlinedTextField label="Title" value={formParams.title} changeHandler={handleTextChange} name="title" />
      {renderTranslationLink('title')}
    </div>
  );

  const renderContent = () => (
    <div className={styles.formSection}>
      <OutlinedTextField
        label="Alert Text"
        value={formParams.content}
        changeHandler={handleTextChange}
        name="content"
        multiline
        verticalResize
        rows={3}
      />
      {renderTranslationLink('content')}
    </div>
  );

  return (
    <ModalBackground closeModal={closeModal}>
      <ModalInterior
        baseCtas={{
          primaryCta: {
            onClick: handleCtaClick,
            testId: 'submit-alert',
            text: <FormattedMessage defaultMessage="Submit" />
          },
          secondaryCta: {
            onClick: closeModal,
            testId: 'cancel-alert',
            text: <FormattedMessage defaultMessage="Cancel" />
          }
        }}
        closeModal={closeModal}
        isMounted
        title="Trail Alert"
      >
        <div className={formStyles.form}>
          {renderShortName()}
          {renderTitle()}
          {renderContent()}
          <div className={styles.formSection}>
            <div>
              <FormattedMessage defaultMessage="Alert type" />
            </div>
            <SelectDropdown
              ariaLabel="trail-alert-dropdown"
              options={alertTypes(intl)}
              handleChange={onAlertTypeSelect}
              defaultItem={alertTypes(intl).find(type => type.value === formParams.alertType) || defaultAlertType(intl)}
            />
          </div>
          {showAreaFields && (
            <Checkbox
              className={styles.formSection}
              id="applyToNewTrails"
              labelElement="Apply to new trails"
              testId="checkbox-applyToNewTrails"
              selected={formParams.applyToNewTrails}
              onChange={toggleApplyToNewTrails}
              size="sm"
            />
          )}
          {showAreaFields && (
            <Checkbox
              className={styles.formSection}
              id="applyToAreaPage"
              labelElement="Apply to area page"
              testId="checkbox-applyToAreaPage"
              selected={formParams.applyToAreaPage}
              onChange={toggleApplyToAreaPage}
              size="sm"
            />
          )}
          {renderDate({
            dateAttribute: 'startDate',
            showAttribute: 'showStartDate',
            disablePast: false,
            label: 'Start automatically',
            hint: 'Starts at the beginning of this day, PST (UTC-8)'
          })}
          {renderDate({
            dateAttribute: 'endDate',
            showAttribute: 'showEndDate',
            disablePast: true,
            label: 'Expire automatically',
            hint: 'Expires at the end of this day, PST (UTC-8)'
          })}
          {renderRecursAnnually()}
          {errorMessage && (
            <div className={styles.formSection}>
              <b>{errorMessage}</b>
            </div>
          )}
        </div>
      </ModalInterior>
    </ModalBackground>
  );
};

const TrailAlertModalIntlProvider = (props: TrailAlertModalProps) => {
  const intl = useIntl();
  return <BaseTrailAlertModal {...props} intl={intl} />;
};

const TrailAlertModal = (props: TrailAlertModalProps) => (
  <CustomProvider>
    <TrailAlertModalIntlProvider {...props} />
  </CustomProvider>
);

export default TrailAlertModal;
