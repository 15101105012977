import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useState } from 'react';

import * as styles from './styles/styles.module.scss';

const backgroundPlaceholderColors = [
  'rgba(123,164,216,1)',
  'rgba(101,96,92,1)',
  'rgba(146,146,108,1)',
  'rgba(95,132,184,1)',
  'rgba(202,139,91,1)',
  'rgba(83,98,191,1)',
  'rgba(108,139,133,1)',
  'rgba(25,168,59,1)'
];

function getBgColor(string) {
  let i = 0;
  if (string) {
    i = string.length;
  }
  let charSum = 0;
  while (i--) {
    charSum += string.charCodeAt(i);
  }
  const backgroundColorIdx = charSum % backgroundPlaceholderColors.length;
  return backgroundPlaceholderColors[backgroundColorIdx];
}

export function getImgDefaultStyle(string, backgroundImage) {
  return {
    backgroundColor: getBgColor(string),
    backgroundImage,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: 'cover'
  };
}

export default function CardImage({ className, url, title, darker, lazyload, noBackground }) {
  const [showImage, setShowImage] = useState(true);
  const classes = ['xlate-none'];
  const imageClasses = [styles.image];
  const containerStyle = {};
  const imageProps = {};

  const handleOnLoad = () => {
    if (showImage) return;

    setShowImage(true);
  };

  const handleOnError = () => {
    setShowImage(false);
  };

  if (!noBackground) {
    classes.push(styles.background);
    containerStyle.backgroundColor = getBgColor(title);
  }
  if (lazyload) {
    imageClasses.push('lazyload');
    imageProps['data-src'] = url;
  } else {
    imageProps.src = url;
  }
  if (className) {
    classes.push(className);
  }
  if (darker) {
    classes.push(styles.darker);
  }

  return (
    <div itemProp="image" className={classNames(classes)} style={containerStyle} title={title}>
      {url && showImage && (
        <img alt={title} onLoad={handleOnLoad} onError={handleOnError} className={classNames(imageClasses)} title={title} {...imageProps} />
      )}
    </div>
  );
}

CardImage.defaultProps = {
  darker: false,
  noBackground: false,
  lazyload: true,
  title: null,
  url: null
};

CardImage.propTypes = {
  className: PropTypes.string.isRequired,
  darker: PropTypes.bool,
  lazyload: PropTypes.bool,
  noBackground: PropTypes.bool,
  title: PropTypes.string,
  url: PropTypes.string
};
