import type { TrailId } from '@alltrails/shared/types/trail';
import { ServerCommunicationUtil } from 'utils/server_communication_util';

// API under app/controllers/api/alltrails/default/trails/trail_conditions_controller

export default async function getTrailConditions(trailId: TrailId) {
  return fetch(`/api/alltrails/trails/${trailId}/trail_conditions`, {
    headers: { 'Content-Type': 'application/json', ...ServerCommunicationUtil.defaultAuthHeaders() }
  }).then(response => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response.json();
  });
}
