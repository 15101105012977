import mapboxgl from 'mapbox-gl';

import { addClusteredPins, removeClusteredPins } from './clustered_pins';
import { addPreviewLines } from './preview_lines';
import { lngLatBoundsToAlgoliaBounds } from '../../at_map_helpers';
import { getMapsIndex } from '../../search';
import { heatmapOverlayId, addResultsHeatmapOverlay, filterAtMapResults } from './heatmap';

const createAlgoliaMapsObject = (map, layerId, selectedFilterActivity) => {
  let algoliaQueryObject = null;
  let filters = 'trail_id=0 AND hidden=0 AND missing_polyline=0';
  if (selectedFilterActivity && selectedFilterActivity != '') {
    filters = `${filters} AND activities:${selectedFilterActivity}`;
  }

  if (layerId.indexOf('All') > -1) {
    // 500 tracks closest to center of map
    // Set a radius to prevent super zoomed in maps from reutrning lots of results
    const center = map.getCenter();
    const bounds = map.getBounds();
    const westBound = new mapboxgl.LngLat(bounds.getWest(), center.lat);
    const radius = center.distanceTo(westBound);
    algoliaQueryObject = {
      hitsPerPage: 500,
      facetFilters: [layerId.indexOf('recordings') > -1 ? 'type:track' : 'type:map'],
      aroundLatLng: `${center.lat},${center.lng}`,
      aroundRadius: parseInt(radius),
      filters
    };
  } else {
    // 500 tracks in bounds with highest popularity, but must have a rating or a photo
    algoliaQueryObject = {
      hitsPerPage: 500,
      facetFilters: [layerId.indexOf('recordings') > -1 ? 'type:track' : 'type:map'],
      insideBoundingBox: lngLatBoundsToAlgoliaBounds(map),
      filters: `${filters} AND (rating>0 OR photo_count>0)`
    };
  }
  return algoliaQueryObject;
};

const applyResultsDataOverrides = (results, resultsDataOverrides) => {
  return results.map(result => {
    if (resultsDataOverrides[result.ID]) {
      return {
        ...result,
        ...resultsDataOverrides[result.ID]
      };
    }
    return result;
  });
};

const addMapsOrRecordings = (map, renderHoverCard, layerId, enabledOverlays, selectedFilterActivity, resultsDataOverrides, lineOpts = {}) => {
  return getMapsIndex()
    .search('', createAlgoliaMapsObject(map, layerId, selectedFilterActivity))
    .then(content => {
      addPreviewLines(map);
      let results = filterAtMapResults(content.hits, enabledOverlays, resultsDataOverrides);
      // Update results based on a recent action, since Algolia might not yet reflect a change just submitted
      if (resultsDataOverrides) {
        results = applyResultsDataOverrides(results, resultsDataOverrides);
      }
      const color = layerId.indexOf('recordings') > -1 ? 'blue' : 'brown';
      addClusteredPins(map, layerId, results, color, renderHoverCard);
      if (enabledOverlays.includes(heatmapOverlayId(layerId))) {
        addResultsHeatmapOverlay({ map, id: layerId, heatMapResults: results, renderHoverCard, lineOpts });
      }
    });
};

const removeMapsOrRecordings = (map, layerId) => {
  removeClusteredPins(map, layerId);
};

export { addMapsOrRecordings, removeMapsOrRecordings };
