const shallowNotEqual = (obj1, obj2, keysFilter = null) => {
  let keys1 = Object.keys(obj1);
  let keys2 = Object.keys(obj2);

  if (keysFilter) {
    keys1 = keys1.filter(keysFilter);
    keys2 = keys2.filter(keysFilter);
  }

  if (keys1.length !== keys2.length) {
    return true;
  }

  return keys1.some(key => obj1[key] !== obj2[key]);
};

const shallowCompare = (component, nextProps, nextState, propsKeyFilter = null, stateKeyFilter = null) => {
  return shallowNotEqual(component.props, nextProps, propsKeyFilter) || shallowNotEqual(component.state, nextState, stateKeyFilter);
};

export { shallowCompare };
