import MapboxglSpiderifier from './mapboxgl_spiderifier';
import { addLayerEvent } from '../layers/layer_helpers';
import { removePreviewLines } from '../overlays/preview_lines';

const minZoomLevelToCluster = 12;

const inspectClusterOnClick = (map, clusterMarkerId, sourceId) => {
  // inspect a cluster on click
  addLayerEvent(map, clusterMarkerId, true, 'click', e => {
    // Don't inspect if zoomed-in (opposite logic of spiderfy on hover)
    if (map.getZoom() >= minZoomLevelToCluster) {
      return;
    }

    const feature = e.features[0];
    const clusterId = feature.properties.cluster_id;
    map.getSource(sourceId).getClusterExpansionZoom(clusterId, (err, zoom) => {
      if (err) {
        console.error(err);
        return;
      }
      map.easeTo({
        center: feature.geometry.coordinates,
        zoom
      });
    });
  });
};

const spiderfyClusterOnLayerHover = (map, clusterLayerId, sourceId, initializeLeg) => {
  // init spiderfier
  let spiderfiedCluster = null;
  const spiderifier = new MapboxglSpiderifier(map, {
    animate: true,
    customPin: true,
    initializeLeg
  });

  const unspiderfy = () => {
    spiderifier.unspiderfy();
    spiderfiedCluster = null;
    removePreviewLines(map);
  };

  const onMouseEnter = e => {
    // Change the cursor style as a UI indicator.
    map.getCanvas().style.cursor = 'pointer';

    // Spiderfy
    const feature = e.features[0];
    const { cluster_id: clusterId, point_count: pointCount } = feature.properties;
    if (clusterId === spiderfiedCluster) {
      return;
    }

    // Don't spiderfy if zoomed-out far and more than 20 elements in the cluster
    // Note: the 20 elements clause was proposed by Data Integrity
    if (map.getZoom() < minZoomLevelToCluster && pointCount > 20) {
      return;
    }

    spiderfiedCluster = clusterId;
    map.getSource(sourceId).getClusterLeaves(clusterId, 1000, 0, (err, leafFeatures) => {
      if (err) {
        console.error(err);
        return;
      }
      const markers = leafFeatures.map(leafFeature => leafFeature.properties);
      spiderifier.spiderfy(feature.geometry.coordinates, markers);
    });
  };

  const onMouseLeave = () => {
    // Change the cursor style as a UI indicator.
    map.getCanvas().style.cursor = map.customStyle.cursor;
  };

  if (map.context && map.context.mobileBrowser) {
    addLayerEvent(map, clusterLayerId, true, 'click', onMouseEnter);
    addLayerEvent(map, clusterLayerId, true, 'unclick', unspiderfy);
  } else {
    addLayerEvent(map, clusterLayerId, true, 'mouseenter', onMouseEnter);
    addLayerEvent(map, clusterLayerId, true, 'mouseleave', onMouseLeave);
    addLayerEvent(map, clusterLayerId, false, 'click', unspiderfy);
  }
  addLayerEvent(map, clusterLayerId, true, 'removed', unspiderfy);
  addLayerEvent(map, clusterLayerId, false, 'moveend', unspiderfy);
  addLayerEvent(map, clusterLayerId, false, 'zoom', unspiderfy);
};

export { inspectClusterOnClick, spiderfyClusterOnLayerHover };
