import { FormattedMessage } from '@alltrails/shared/react-intl';
import Button from '@alltrails/shared/denali/components/Button';
import getHelpCenterUrl from '@alltrails/shared/utils/constants/helpCenterUrl';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import * as styles from './styles/styles.module.scss';

const HelpButton = () => {
  const languageRegionCode = useLanguageRegionCode();

  return (
    <Button
      className={styles.helpButton}
      linkInfo={{ href: getHelpCenterUrl(languageRegionCode, 360019245911), target: '_blank' }}
      text={<FormattedMessage defaultMessage="Need help?" />}
      testId="map-creator-help"
      variant="elevated"
    />
  );
};

export default HelpButton;
