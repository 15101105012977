import { serverAjaxPromise } from './request_helpers';

const regenTrailSlug = trailId => {
  return serverAjaxPromise({
    type: 'GET',
    url: `/api/alltrails/trails/${trailId}/regen_slug`
  });
};

const mergeTrailEnds = trailId => {
  return serverAjaxPromise({
    type: 'GET',
    url: `/api/alltrails/trails/${trailId}/merge_trail_ends`
  });
};

const refreshTrailCache = trailId => {
  return serverAjaxPromise({
    type: 'GET',
    url: `/api/alltrails/trails/${trailId}/refresh_trail_cache`
  });
};

export { regenTrailSlug, mergeTrailEnds, refreshTrailCache };
