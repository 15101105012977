import IconButton from '@alltrails/shared/denali/components/IconButton';
import useToggle from '@alltrails/shared/hooks/useToggle';
import Close from '@alltrails/shared/icons/Close';
import Ad from 'components/Ad/Ad';
import useScreenLock from 'hooks/useScreenLock';
import { ComponentProps } from 'react';
import { createPortal } from 'react-dom';
import { useIntl } from '@alltrails/shared/react-intl';
import type Trail from 'types/Trails/Trail';
import Experiments from '@alltrails/shared/types/Experiments';
import * as styles from './AdInterstitial.module.scss';

type Props = {
  adId: string;
  adUnitPath: ComponentProps<typeof Ad>['adUnitPath'];
  experiment: Experiments['web-growth-native-ads-v2']['value'];
  trail?: Trail;
};

type AdInterstitialPortalProps = {
  adId: string;
  adUnitPath: ComponentProps<typeof Ad>['adUnitPath'];
  experiment: Experiments['web-growth-native-ads-v2']['value'];
  toggle: () => void;
  trail?: Trail;
};

function AdInterstitialPortal({ adId, adUnitPath, experiment, trail, toggle }: AdInterstitialPortalProps) {
  const intl = useIntl();
  const [displayPortal, toggleDisplayPortal] = useToggle();
  useScreenLock();

  const adInterstitial = global.document.getElementById('ad-interstitial');

  if (!adInterstitial) {
    const div = global.document.createElement('div');
    div.setAttribute('id', 'ad-interstitial');
    const { body } = global.document;
    body.appendChild(div);
  }

  return createPortal(
    <div className={styles.container} style={{ display: !displayPortal && 'none' }}>
      <IconButton
        icon={{ Component: Close }}
        testId="ad-interstitial-close"
        title={intl.formatMessage({ defaultMessage: 'Close' })}
        className={styles.close}
        onClick={toggle}
        size="md"
        variant="flat"
      />
      <Ad
        adUnitPath={adUnitPath}
        buttonClassName={styles.button}
        buttonVariant="default"
        experiment={experiment}
        id={adId}
        trail={trail}
        onDisplay={toggleDisplayPortal}
      />
    </div>,
    document.getElementById('ad-interstitial')
  );
}

// https://www.figma.com/file/3dCQyKmNlOdfjaZ8vsDpzs/Native-Ads?node-id=471%3A191932&t=UklpU4oVdCjBcrsQ-4
export default function AdInterstitial({ adId, adUnitPath, experiment, trail }: Props) {
  const [isOpen, toggle] = useToggle(true);

  if (!isOpen) {
    return null;
  }

  return <AdInterstitialPortal adId={adId} adUnitPath={adUnitPath} experiment={experiment} toggle={toggle} trail={trail} />;
}
