import { get } from '@alltrails/shared/api';
import { Feature, FeatureCollection, LineString } from '@turf/helpers';
import { H3IndexInput } from 'h3-js';

export type ScoredCell = {
  h3_id: H3IndexInput;
  score: number;
};

export function getRedrawSegments(trailId: number) {
  return get<FeatureCollection>(`/api/alltrails/admin/trails/${trailId}/redraw?featureType=trail_segments`);
}

export function getRedrawH3Heatmap(trailId: number) {
  return get<ScoredCell[]>(`/api/alltrails/admin/trails/${trailId}/redraw?featureType=trail_heatmap`);
}

export function getSlidRoute(trailId: number) {
  return get<Feature<LineString>>(`/api/alltrails/admin/trails/${trailId}/redraw?featureType=slide_result`);
}
