import classNames from 'classnames';
import { defineMessages } from '@alltrails/shared/react-intl';
import useFormatMessage from '@alltrails/shared/hooks/useFormatMessage';
import NullState from '@alltrails/shared/denali/components/NullState';
import Binoculars from '@alltrails/shared/illustrations/Binoculars';
import { PageStrings } from '@alltrails/shared/utils/constants/pageStringHelpers';
import CustomProvider from 'components/CustomProvider';
import * as styles from './styles/styles.module.scss';

// https://www.figma.com/file/gZaqAej8HzoFkyrjlQ4cNs/Integrating-Brand-Changes?node-id=2869%3A81826

export type Props = {
  atMaps: any[];
  cta: any;
  hasActiveFilters: boolean;
  initialNoResultsTitle: string;
  onClick: (pageKey: string) => void;
  page: string;
  resultCount: number;
  subText: string;
  trailIds: string[];
};

enum Icon {
  FAVORITE,
  RECORDINGS,
  TRAILPOST
}

const iconToStyles = {
  [Icon.FAVORITE]: styles.favorite,
  [Icon.RECORDINGS]: styles.recordings,
  [Icon.TRAILPOST]: styles.trailpost
};

const getNoResultsDiv = (header: string, iconText: string, iconClass = Icon.TRAILPOST) => (
  <CustomProvider>
    <div className={styles.searching}>
      <div className={styles.header}>{header}</div>
      <div className={classNames(styles.icon, iconToStyles[iconClass])}>
        <span className={styles.iconText}>{iconText}</span>
      </div>
    </div>
  </CustomProvider>
);

const messages = defineMessages({
  VIEW_COMMUNITY_CONTENT: {
    defaultMessage: 'View community content'
  },
  VIEW_CURATED_TRAILS: {
    defaultMessage: 'View curated trails'
  },
  NO_RESULTS_FOUND: {
    defaultMessage: 'No results found'
  },
  LOOKING_FOR_MORE: {
    defaultMessage: 'Looking for more?'
  },
  CURATED_TRAILS_BODY_LONG: {
    defaultMessage: `Let's get you back on track! Zoom out or try the "Community content" tab to see if others have explored this area.`
  },
  CURATED_TRAILS_BODY_SHORT: {
    defaultMessage: 'Adjust your filters or zoom out. You can also try the "Community content" tab to see if others have explored this area.'
  },
  COMMUNITY_CONTENT_BODY_LONG: {
    defaultMessage: `Let's get you back on track! Zoom out or try the "Curated trails" tab to see if others have explored this area.`
  },
  CLEAR_FILTERS: {
    defaultMessage: 'Clear filters'
  },
  CURATED_TRAILS_OVER_FILTERED_BODY_LONG: {
    defaultMessage: `Let’s get you back on track! Adjust your filters or zoom out. Or try the “Community content” tab to see if others have explored this area.`
  },
  CURATED_TRAILS_OVER_FILTERED_BODY_SHORT: {
    defaultMessage: `Let’s get you back on track! Try adjusting your filters or searching another location.`
  },
  COMMUNITY_CONTENT_OVER_FILTERED_BODY_LONG: {
    defaultMessage: `Let's get you back on track! Adjust your filters or zoom out. You can also try the "Curated trails" tab to see if others have explored this area.`
  },
  COMMUNITY_CONTENT_OVER_FILTERED_BODY_SHORT: {
    defaultMessage: `Adjust your filters or zoom out. You can also try the "Curated trails" tab to see trails in this area.`
  },
  COMPLETION_PROMPT: {
    defaultMessage: 'Add to this list from the Completed tab of any trail. Or download the AllTrails app to save new travels with Navigator.'
  },
  NO_RESULTS: { defaultMessage: 'No results' },
  FAVORITE_PROMPT: { defaultMessage: 'Tap the save icon on any trail to add it to this list.' },
  CLEAR_FILTERS_MORE_RESULTS: { defaultMessage: 'Clear your current search filters to see more results.' },
  NO_TRAILS_IN_LIST: { defaultMessage: 'No trails in list.' },
  LIST_PROMPT: { defaultMessage: 'Search for trails to add to this list.' },
  RECORDING_LOAD_YOUR: { defaultMessage: 'Loading your activities...' },
  RECORDING_LOAD: { defaultMessage: 'Hang in there while we load your activities.' },
  RECORDER_PROMPT: {
    defaultMessage: 'Save activities by navigating your route in the AllTrails app. On desktop, upload activities from Garmin, Google, and more.'
  },
  NO_RECORDINGS: { defaultMessage: 'No Activities.' },
  CLEAR_FILTERS_ALL_RECORDINGS: { defaultMessage: 'Clear your current search filters to see all activities.' },
  MAP_LOAD_YOUR: { defaultMessage: 'Loading your maps...' },
  MAP_LOAD: { defaultMessage: 'Hang in there while we load your maps.' },
  PLAN_PROMPT: { defaultMessage: 'Create a custom map using the AllTrails map creation tool' },
  NO_MAPS: { defaultMessage: 'No maps.' },
  CLEAR_FILTERS_ALL_MAPS: { defaultMessage: 'Clear your current search filters to see all maps.' },
  SEARCH_FOR_TRAILS: { defaultMessage: 'Searching for trails...' },
  TRAIL_LOAD: { defaultMessage: 'Hang in there while we search through 200,000+ trails to find the ones for you.' }
});

const NoResults = ({ atMaps, hasActiveFilters, onClick, initialNoResultsTitle, page, resultCount, trailIds }: Props) => {
  const {
    formattedDefaultMessages: {
      NO_RESULTS_FOUND,
      CLEAR_FILTERS,
      VIEW_COMMUNITY_CONTENT,
      VIEW_CURATED_TRAILS,
      CURATED_TRAILS_BODY_LONG,
      CURATED_TRAILS_BODY_SHORT,
      LOOKING_FOR_MORE,
      COMMUNITY_CONTENT_BODY_LONG,
      CURATED_TRAILS_OVER_FILTERED_BODY_LONG,
      COMMUNITY_CONTENT_OVER_FILTERED_BODY_LONG,
      CURATED_TRAILS_OVER_FILTERED_BODY_SHORT,
      COMMUNITY_CONTENT_OVER_FILTERED_BODY_SHORT,
      COMPLETION_PROMPT,
      NO_RESULTS,
      FAVORITE_PROMPT,
      CLEAR_FILTERS_MORE_RESULTS,
      NO_TRAILS_IN_LIST,
      LIST_PROMPT,
      RECORDING_LOAD_YOUR,
      RECORDING_LOAD,
      RECORDER_PROMPT,
      NO_RECORDINGS,
      CLEAR_FILTERS_ALL_RECORDINGS,
      MAP_LOAD_YOUR,
      MAP_LOAD,
      PLAN_PROMPT,
      NO_MAPS,
      CLEAR_FILTERS_ALL_MAPS,
      SEARCH_FOR_TRAILS,
      TRAIL_LOAD
    }
  } = useFormatMessage(messages);

  let noResultsDiv = getNoResultsDiv(SEARCH_FOR_TRAILS, TRAIL_LOAD);
  let tabToPage: string;
  let text;

  const getBodyContent = (isLong: boolean) => {
    if (hasActiveFilters) {
      if (page === PageStrings.EXPLORE_ALL_PAGE) {
        return isLong ? CURATED_TRAILS_OVER_FILTERED_BODY_LONG : CURATED_TRAILS_OVER_FILTERED_BODY_SHORT;
      }
      return isLong ? COMMUNITY_CONTENT_OVER_FILTERED_BODY_LONG : COMMUNITY_CONTENT_OVER_FILTERED_BODY_SHORT;
    }
    if (page === PageStrings.EXPLORE_ALL_PAGE) {
      return isLong ? CURATED_TRAILS_BODY_LONG : CURATED_TRAILS_BODY_SHORT;
    }
    return isLong ? COMMUNITY_CONTENT_BODY_LONG : COMMUNITY_CONTENT_OVER_FILTERED_BODY_SHORT;
  };

  const getButtonContent = () => {
    if (hasActiveFilters) {
      return CLEAR_FILTERS;
    }
    if (page === PageStrings.EXPLORE_ALL_PAGE) {
      return VIEW_COMMUNITY_CONTENT;
    }
    return VIEW_CURATED_TRAILS;
  };

  switch (page) {
    case PageStrings.EXPLORE_ALL_PAGE:
    case PageStrings.EXPLORE_COMMUNITY_CONTENT_PAGE:
      tabToPage = page === PageStrings.EXPLORE_ALL_PAGE ? PageStrings.EXPLORE_COMMUNITY_CONTENT_PAGE : PageStrings.EXPLORE_ALL_PAGE;

      text = {
        title: {
          long: NO_RESULTS_FOUND,
          short: LOOKING_FOR_MORE
        },
        body: {
          long: getBodyContent(true),
          short: getBodyContent(false)
        }
      };
      noResultsDiv = (
        <CustomProvider>
          <div className={styles.noResults}>
            <NullState
              description={text.body[resultCount < 1 ? 'long' : 'short']}
              title={text.title[resultCount < 1 ? 'long' : 'short']}
              illustration={{ Component: Binoculars }}
              buttons={{
                primary: {
                  text: getButtonContent(),
                  onClick: () => onClick(tabToPage),
                  testId: getButtonContent()
                }
              }}
            />
          </div>
        </CustomProvider>
      );
      break;
    case PageStrings.EXPLORE_FAVORITE_PAGE:
      if (!trailIds || trailIds.length === 0) {
        noResultsDiv = getNoResultsDiv(initialNoResultsTitle, FAVORITE_PROMPT, Icon.FAVORITE);
      } else if (resultCount === 0) {
        noResultsDiv = getNoResultsDiv(NO_RESULTS, CLEAR_FILTERS_MORE_RESULTS, Icon.FAVORITE);
      }
      break;
    case PageStrings.EXPLORE_COMPLETED_PAGE:
      if (!trailIds || trailIds.length === 0) {
        noResultsDiv = getNoResultsDiv(initialNoResultsTitle, COMPLETION_PROMPT);
      } else if (resultCount === 0) {
        noResultsDiv = getNoResultsDiv(NO_RESULTS, CLEAR_FILTERS_MORE_RESULTS);
      }
      break;
    case PageStrings.EXPLORE_CUSTOM_PAGE:
      if (!trailIds || trailIds.length === 0) {
        noResultsDiv = getNoResultsDiv(NO_TRAILS_IN_LIST, LIST_PROMPT);
      } else if (resultCount === 0) {
        noResultsDiv = getNoResultsDiv(NO_RESULTS, CLEAR_FILTERS_MORE_RESULTS);
      }
      break;
    case PageStrings.EXPLORE_USERS_TRACKS_PAGE:
      if (!atMaps) {
        noResultsDiv = getNoResultsDiv(RECORDING_LOAD_YOUR, RECORDING_LOAD, Icon.RECORDINGS);
      } else if (atMaps && atMaps.length === 0) {
        noResultsDiv = getNoResultsDiv(initialNoResultsTitle, RECORDER_PROMPT, Icon.RECORDINGS);
      } else if (resultCount === 0) {
        noResultsDiv = getNoResultsDiv(NO_RECORDINGS, CLEAR_FILTERS_ALL_RECORDINGS, Icon.RECORDINGS);
      }
      break;
    case PageStrings.EXPLORE_USERS_MAPS_PAGE:
      if (!atMaps) {
        noResultsDiv = getNoResultsDiv(MAP_LOAD_YOUR, MAP_LOAD);
      } else if (atMaps && atMaps.length === 0) {
        noResultsDiv = getNoResultsDiv(initialNoResultsTitle, PLAN_PROMPT);
      } else if (resultCount === 0) {
        noResultsDiv = getNoResultsDiv(NO_MAPS, CLEAR_FILTERS_ALL_MAPS);
      }
      break;
    default:
    // do nothing
  }

  return noResultsDiv;
};

NoResults.defaultProps = {
  handler: () => {
    /* do nothing */
  },
  title: '',
  text: '',
  buttonText: '',
  className: ''
};

export default NoResults;
