import { useState } from 'react';
import { defineMessages } from '@alltrails/shared/react-intl';
import ReportLocation from '@alltrails/analytics/enums/ReportLocation';
import { PhotoSize } from '@alltrails/shared/types/photos';
import { Activity, ActivityUser, MapPhoto, Photo } from 'types/Activity';
import { getPhotoUrl } from '@alltrails/shared/utils/requests/photoRequests';

import useFormatMessage from '@alltrails/shared/hooks/useFormatMessage';
import type { Context } from 'types/Context';
import { ServerCommunicationUtil } from '../../../../utils/server_communication_util';
import { urlReformatPhotos } from '../../../../utils/lightbox_helpers';
import AddGreenIcon from '../../../../../assets/images/icons/add-green.svg';

import Lightbox from '../../../shared/Lightbox';
import PhotosTab from '../PhotosTab/PhotosTab';

import * as styles from './styles/styles.module.scss';

const PHOTOS_SECTION_STRINGS = defineMessages({
  ALLTRAILS: { defaultMessage: 'AllTrails' },
  UPLOAD_PHOTOS: {
    defaultMessage: 'Upload photos'
  }
});

type Props = {
  currentUser: ActivityUser;
  track: Activity;
  data: MapPhoto[];
  handlePhotosUpload?: (details: string) => void;
  openTrailFormModal?: (type: string, data: any) => void;
  messagingChannel?: {
    publish: (data: any) => void;
    subscribe: () => void;
  };
  linkedTrail?: any;
  context: Context;
};

const PhotosSection = ({
  currentUser,
  track,
  data,
  handlePhotosUpload,
  openTrailFormModal,
  messagingChannel,
  linkedTrail,
  context
}: Props): JSX.Element => {
  const {
    formattedDefaultMessages: { ALLTRAILS, UPLOAD_PHOTOS }
  } = useFormatMessage(PHOTOS_SECTION_STRINGS);

  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [lightboxStartingIndex, setLightBoxStartingIndex] = useState(null);
  const [lightboxImages, setLightBoxImages] = useState([]);

  const addMapPhotoMarkers = (photoDetails: string) => {
    messagingChannel.publish({
      topic: 'mapPhoto.add.toModel',
      data: photoDetails
    });
  };
  const handlePhotoHover = (mapPhotoId: number) => {
    messagingChannel.publish({
      topic: 'photo.hover',
      data: mapPhotoId
    });
  };
  const handleUpload = (photo: Photo) => {
    const photos = photo.detail;
    if (handlePhotosUpload) handlePhotosUpload(photos);
    addMapPhotoMarkers(photos);
  };
  const handlePlaceholderClick = () => {
    if (currentUser && currentUser.id === track.user.id) {
      const url = `/api/alltrails/maps/${track.id}/photos?key=${ServerCommunicationUtil.apiKey}`;
      openTrailFormModal('photos', { url, onPhotosAdd: handleUpload });
    }
  };
  const handleMapPhotoClick = (mapPhotoId: number) => {
    let newIndex;
    const newLightBoxImages = data.map((item, index) => {
      if (mapPhotoId === item.photo.id) {
        newIndex = index;
      }

      return { ...item.photo, map_id: item.mapId };
    });

    setLightboxOpen(true);
    setLightBoxImages(newLightBoxImages);
    setLightBoxStartingIndex(newIndex);
  };
  const handleLightboxClose = () => {
    setLightboxOpen(false);
    setLightBoxStartingIndex(null);
    setLightBoxImages([]);
  };
  const getLightboxProps = () => ({
    isOpen: lightboxOpen,
    images: urlReformatPhotos(lightboxImages),
    handleCloseRequest: handleLightboxClose,
    startingIndex: lightboxStartingIndex,
    mapId: track.id,
    linkedTrail,
    context,
    analyticsReportLocation: ReportLocation.ActivityDetails
  });

  const sortedPhotos = data?.sort((a, b) => new Date(a?.photo?.metadata?.created).valueOf() - new Date(b?.photo?.metadata?.created).valueOf());

  const renderPhotos = () => {
    let photos: any = [];
    if (sortedPhotos && sortedPhotos.length > 0) {
      photos = sortedPhotos.map((mapPhoto: MapPhoto, i: number) => {
        const { id, photoHash } = mapPhoto.photo;
        const fallbackPhotoUrl = `/api/alltrails/photos/${id}/image?size=medium_square&key=${ServerCommunicationUtil.apiKey}`;
        let photoUrl;
        if (id && photoHash) {
          photoUrl = getPhotoUrl({ id, photoHash }, PhotoSize.MediumSquare);
        } else {
          photoUrl = fallbackPhotoUrl;
        }
        let shouldFallback = true;
        const addFallbackSrc = (ev: React.ChangeEvent<HTMLImageElement>) => {
          if (shouldFallback) {
            ev.target.src = fallbackPhotoUrl;
            ev.target.onerror = null;
          }
          shouldFallback = false;
        };
        let hoverHandler: any = null;
        if (mapPhoto.location && mapPhoto.location.latitude != null && mapPhoto.location.longitude != null) {
          hoverHandler = handlePhotoHover(mapPhoto.id);
        }
        return (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
          <li
            className={styles.uploadedPhoto}
            key={`photo-${mapPhoto.id}-${i}`}
            onClick={() => handleMapPhotoClick(id)}
            onMouseOver={hoverHandler}
            onFocus={hoverHandler}
          >
            <img className={styles.uploadedImg} alt={ALLTRAILS} src={photoUrl} onError={addFallbackSrc} />
          </li>
        );
      });
    }
    if (currentUser && currentUser.id === track?.user?.id) {
      const addPhotosSquare = (
        <PhotosTab
          key="photos-add"
          srcIcon={AddGreenIcon}
          containerClass={styles.addPhotoSquare}
          innerClass={styles.addIcon}
          action={handlePlaceholderClick}
          altName={UPLOAD_PHOTOS}
        />
      );
      photos.unshift(addPhotosSquare);
    }
    if (!photos.length) {
      return null;
    }
    if (photos.length < 3) {
      const numToAdd = 3 - photos.length;
      for (let i = 0; i < numToAdd; i++) {
        photos.push(<PhotosTab key={`photos-add-gray-${i}`} containerClass={styles.emptyPhotoContainer} action={handlePlaceholderClick} />);
      }
    }

    return photos;
  };

  return (
    <>
      <ul key="photos" className={styles.photosTab}>
        {renderPhotos()}
      </ul>
      {lightboxOpen && <Lightbox key="lightbox" {...getLightboxProps()} />}
    </>
  );
};

export default PhotosSection;
