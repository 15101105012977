import { deleteSavedFilterSet, getSavedFilterSets, saveFilterSet } from 'api/SavedFilters';
import useHypotheticalSearchResultsCount, { SearchResultsCountFunction } from 'hooks/useHypotheticalSearchResultsCount';
import useSearchFilters from 'hooks/useSearchFilters';
import useUser from 'hooks/useUser';
import { createContext, useCallback, useEffect, useMemo, useState, ReactNode } from 'react';
import { FilterConfig, FilterRangeUpdates, FilterSliderUpdates, FilterViewModel } from 'types/Search';
import { SavedFilterSet, serializeFilters } from 'utils/filters';
import logError from 'utils/logError';

type FiltersStateValue = {
  appliedFilterLabelsByKey: Record<string, string[]>;
  activeFilterSetName: string | null;
  applySelectedFilterSet: (index: number) => void;
  blankFiltersFactory?: () => FilterViewModel[];
  clearActiveFilterSet: () => void;
  clearAllFilters: () => void;
  clearDraftChanges: () => void;
  clearAppliedFilters: (keys: string[]) => void;
  computedResultsCount: number;
  resetHypotheticalResultsCount: () => void;
  deleteSavedFilterSet: (id: number) => void;
  filtersByKey: Record<string, FilterConfig>;
  hasSaveableFilters: boolean;
  isLoadingHypotheticalResultsCount: boolean;
  page: string;
  saveChanges: () => void;
  savedFilterSets: SavedFilterSet[];
  saveFilters: (name: string) => void;
  sortedFilters: FilterViewModel[];
  updateFilter: (key: string, changes: FilterConfig) => void;
  updateFilters: (payload: FilterViewModel[]) => void;
};

const FiltersContext = createContext<FiltersStateValue>(null);

type Props = {
  // Only needed for filter bar uses cases that use Saved Filters.
  blankFiltersFactory?: () => FilterViewModel[];
  children: ReactNode;
  clearAllFilters?: () => void;
  clearRadioFilter?: (filterKey: string, pageName: string) => void;
  handleFilterRadioToggle?: (filterKey: string, filterConfigKey: string) => void;
  handleFilterSet?: (filterKey: string, value: string) => void;
  handleFilterRange?: (filterKey: string, value: FilterRangeUpdates | FilterSliderUpdates) => void;
  handleFilterToggle?: (filterKey: string, filterConfigKey: string) => void;
  page?: string;
  resultsCount: number;
  searchResultsCountFunction: SearchResultsCountFunction;
  sortedFilters: FilterViewModel[];
};

function FiltersProvider({
  blankFiltersFactory,
  children,
  clearAllFilters,
  clearRadioFilter,
  handleFilterRadioToggle,
  handleFilterSet,
  handleFilterRange,
  handleFilterToggle,
  page,
  resultsCount,
  searchResultsCountFunction,
  sortedFilters
}: Props) {
  const [activeFilterSetId, setActiveFilterSetId] = useState<number>(null);
  const [savedFilterSets, setSavedFilterSets] = useState<SavedFilterSet[]>([]);

  const serializedFilters = useMemo(() => serializeFilters(sortedFilters), [sortedFilters]);

  const user = useUser();

  const {
    appliedFilterLabelsByKey,
    clearAppliedFilters,
    clearDraftChanges,
    filtersByKey,
    hasAnyDraftChanges,
    saveChanges,
    updateFilter,
    updateFilters
  } = useSearchFilters({
    clearRadioFilter,
    filters: sortedFilters,
    handleFilterRadioToggle,
    handleFilterSet,
    handleFilterRange,
    handleFilterToggle,
    page
  });

  const {
    computedResultsCount,
    resetHypotheticalResultsCount,
    isLoading: isLoadingHypotheticalResultsCount
  } = useHypotheticalSearchResultsCount({
    filtersByKey,
    hasAnyDraftChanges,
    resultsCount,
    searchResultsCountFunction
  });

  useEffect(() => {
    (async () => {
      if (!user) {
        return;
      }

      try {
        const newSavedFilterSets = await getSavedFilterSets();

        setSavedFilterSets(newSavedFilterSets);
      } catch (error) {
        logError(error);
      }
    })();
  }, [user]);

  const clearActiveFilterSet = useCallback(() => setActiveFilterSetId(null), []);

  const valueMemo = useMemo(() => {
    const saveFilters = async (name: string) => {
      const results = await saveFilterSet(name, serializedFilters);

      setSavedFilterSets([...savedFilterSets, ...results]);
      setActiveFilterSetId(results[0].id);
    };

    const deleteFilterSet = async (id: number) => {
      await deleteSavedFilterSet(id);

      setSavedFilterSets([...savedFilterSets.filter(savedFilterSet => savedFilterSet.id !== id)]);

      if (activeFilterSetId === id) {
        setActiveFilterSetId(null);
      }
    };

    return {
      appliedFilterLabelsByKey,
      activeFilterSetName: savedFilterSets.find(savedFilterSet => savedFilterSet.id === activeFilterSetId)?.name,
      applySelectedFilterSet: (index: number) => setActiveFilterSetId(savedFilterSets[index].id),
      blankFiltersFactory,
      clearActiveFilterSet,
      clearAllFilters,
      clearAppliedFilters,
      clearDraftChanges,
      computedResultsCount,
      deleteSavedFilterSet: deleteFilterSet,
      filtersByKey,
      hasAnyDraftChanges,
      hasSaveableFilters: !!serializedFilters,
      isLoadingHypotheticalResultsCount,
      page,
      resetHypotheticalResultsCount,
      saveChanges,
      savedFilterSets,
      saveFilters,
      sortedFilters,
      updateFilter,
      updateFilters
    };
  }, [
    appliedFilterLabelsByKey,
    activeFilterSetId,
    blankFiltersFactory,
    clearActiveFilterSet,
    clearAllFilters,
    clearAppliedFilters,
    clearDraftChanges,
    computedResultsCount,
    filtersByKey,
    hasAnyDraftChanges,
    isLoadingHypotheticalResultsCount,
    page,
    resetHypotheticalResultsCount,
    saveChanges,
    savedFilterSets,
    serializedFilters,
    sortedFilters,
    updateFilter,
    updateFilters
  ]);

  return <FiltersContext.Provider value={valueMemo}>{children}</FiltersContext.Provider>;
}

export default FiltersProvider;
export { FiltersContext };
