/* eslint-disable @typescript-eslint/no-explicit-any */
import { createDispatchHook, createSelectorHook, shallowEqual, TypedUseSelectorHook } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import type { Context } from 'types/Context';
import weather from '@alltrails/redux/slices/weather';
// eslint-disable-next-line import/no-extraneous-dependencies, @typescript-eslint/no-unused-vars
import { ThunkMiddleware } from 'redux-thunk'; // Avoid a TS error where the consuming package is unable to type the result of the function https://github.com/microsoft/TypeScript/issues/29808
import { createContext } from 'react';
import context from '../../../slices/contextSlice';

export type PreloadedState = {
  context: Context;
};

const reducer = {
  context,
  weather
};

// see https://react-redux.js.org/api/hooks#custom-context
export const ReduxContext = createContext(null);

export const buildStore = (preloadedState?: Partial<PreloadedState>) => configureStore({ reducer, preloadedState });

export type AppStore = ReturnType<typeof buildStore>;
export type AppDispatch = AppStore['dispatch'];
export type RootState = ReturnType<AppStore['getState']>;

export const useDispatch = (): AppDispatch => createDispatchHook<AppDispatch>(ReduxContext)();

const useSelectorBase = createSelectorHook<RootState, any>(ReduxContext);
export const useSelector: TypedUseSelectorHook<RootState> = (selector: (state: RootState) => any, equalityFn = shallowEqual) =>
  useSelectorBase(selector, equalityFn);
