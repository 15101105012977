import { get } from '@alltrails/shared/api';
import type { TrailId } from '@alltrails/shared/types/trail';
import TrailTabType from 'types/TrailTabType';

type PropsResponse = {
  initialMyListItems: any;
  initialMyLists: any;
  overviewProps?: {
    tabs: TrailTabType[];
  };
};

// API under app/controllers/api/alltrails/default/trails/props_controller

export default async function getTrailProps(trailId: TrailId, explore?: boolean) {
  return get<PropsResponse>(`/api/alltrails/trails/${trailId}/props`, {
    params: {
      explore
    }
  });
}
