import { useIntl } from '@alltrails/shared/react-intl';
import { bool, func, string } from 'prop-types';
import IconButton from '@alltrails/shared/denali/components/IconButton';
import Translation from '@alltrails/shared/icons/Translation';

const OriginalLanguageButton = ({ active, handleClick, eventSource }) => {
  const intl = useIntl();

  const reportEventAndHandleClick = e => {
    handleClick(e);
  };

  const title = active ? intl.formatMessage({ defaultMessage: 'Show original' }) : intl.formatMessage({ defaultMessage: 'Show translation' });

  return (
    <IconButton
      icon={{ Component: Translation }}
      onClick={reportEventAndHandleClick}
      testId={title}
      title={title}
      variant={active ? 'primary' : 'flat'}
    />
  );
};

OriginalLanguageButton.propTypes = {
  active: bool,
  handleClick: func.isRequired,
  eventSource: string
};

OriginalLanguageButton.defaultProps = {
  active: false,
  eventSource: ''
};

export default OriginalLanguageButton;
