import { Component } from 'react';
import { FormattedMessage, defineMessages, useIntl } from '@alltrails/shared/react-intl';
import classNames from 'classnames';
import Button from '@alltrails/shared/denali/components/Button';
import RadioGroup from '@alltrails/shared/denali/components/RadioGroup';
import CheckboxGroup from '@alltrails/shared/denali/components/CheckboxGroup';
import CustomProvider from 'components/CustomProvider';
import { LanguageSupportUtil } from '../../../../utils/language_support_util';
import SliderGroup from '../form/SliderGroup/SliderGroup';
import FileUpload from '../form/FileUpload/FileUpload';
import { DragAndDrop } from '../form/drag_and_drop';
import TextInput from '@alltrails/shared/denali/components/TextInput';
import Select from '@alltrails/shared/denali/components/Select';
import Switch from '@alltrails/shared/denali/components/Switch';
import Expandable from '@alltrails/shared/components/Expandable';
import Link from '@alltrails/shared/denali/components/Link';
import Typography from '@alltrails/shared/denali/components/Typography';
import * as styles from './Converter.module.scss';
import getHelpCenterUrl from '@alltrails/shared/utils/constants/helpCenterUrl';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';

const routeOptionMessages = defineMessages({
  REVERSE_ROUTE: { defaultMessage: 'Reverse route' },
  OUT_AND_BACK: { defaultMessage: 'Change to Out & Back route' },
  ADD_ELEVATION: { defaultMessage: 'Add / Replace elevation' },
  COMBINE_ROUTES: { defaultMessage: 'Combine multiple routes into one route' }
});

const routeSimplificationMessages = defineMessages({
  NONE: { defaultMessage: 'None' },
  LOW: { defaultMessage: 'Low (Reduce by 25%)' },
  MEDIUM: { defaultMessage: 'Medium (Reduce by 50%)' },
  HIGH: { defaultMessage: 'High (Reduce by 75%)' },
  CUSTOM: { defaultMessage: 'Customize:' }
});

class BaseConverter extends Component {
  constructor(props) {
    super(props);

    this.props.routeOptions.forEach(routeOption => {
      if (typeof routeOption.enabled === 'undefined') {
        routeOption.enabled = false;
      }
    });

    this.initialState = {
      convertTo: 'route',
      downloadAs: 'csv',
      submitting: false,
      uploadProgress: 0,
      uploadComplete: false,
      fileSizeLimit: 8e6,
      errorMessage: '',
      fileTypes: [
        {
          display: this.props.intl.formatMessage({ defaultMessage: 'Track / Route' }),
          value: 'route',
          options: this.props.fileTypes.route
        },
        {
          display: this.props.intl.formatMessage({ defaultMessage: 'Waypoints' }),
          value: 'waypoint',
          options: this.props.fileTypes.waypoint
        }
      ],
      files: {
        uploadedPath: this.props.filePath,
        key: 0,
        fileName: '',
        fileSize: 0,
        fileUrl: '',
        isAttached: false,
        attachedFile: null,
        redirectSlug: this.props.convertedSlug,
        mapType: this.props.mapType
      },
      routeOptions: {
        options: this.props.routeOptions
      },
      routeSimplification: {
        selectedOption: 0,
        value: this.props.routeSimplificationMax,
        maxTrackPoints: false
      },
      cueSheets: {
        on: false,
        number: this.props.cueSheetOptions.number.value,
        distance: this.props.cueSheetOptions.distance.range
      }
    };
    this.state = this.initialState;
  }

  componentDidMount() {
    const options = this.props.routeOptions.map(r => ({
      id: r.key,
      testId: `checkbox-${r.key}-route-option`,
      selected: r.enabled,
      text: this.props.intl.formatMessage(routeOptionMessages[r.stringKey])
    }));
    this.setState({ routeOptions: { options } });
  }

  // This updates the nested state of a state var, and runs 'formReset' to clear validation messages on update.
  stateHandler(section, key) {
    return e => {
      this.formReset();
      const { value } = e.target;
      this.setState(state => {
        state[section][key] = value;
      });
    };
  }

  setValue = (value, section, key) => {
    const newSection = { ...this.state[section] };
    newSection[key] = value;
    this.setState({ [section]: newSection });
  };

  resetField(section, key) {
    return () => {
      this.formReset();
      this.setState(state => {
        state[section][key] = '';
      });
    };
  }

  resetPage() {
    return () => {
      this.setState(this.initialState);
    };
  }

  handleRemove() {
    return e => {
      this.setState({
        uploadProgress: 0,
        files: {
          key: this.state.files.key++,
          isAttached: false,
          attachedFile: '',
          fileName: '',
          fileSize: ''
        }
      });
    };
  }

  addFile(newFile) {
    this.setState({
      uploadProgress: 0,
      files: {
        key: this.state.files.key + 1,
        isAttached: true,
        attachedFile: newFile,
        fileName: newFile.name,
        fileSize: newFile.size,
        fileUrl: ''
      }
    });
  }

  // These two functions are pretty similar. There's probably a better way to do this.
  handleFileFieldChange() {
    return e => {
      this.formReset();
      const newFile = e.target.files[0];
      this.addFile(newFile);
    };
  }

  handleDrop() {
    return e => {
      this.formReset();
      const newFile = e[0];
      this.addFile(newFile);
    };
  }

  sendData(data) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.upload.onprogress = e => {
        const done = e.position || e.loaded;
        const total = e.totalsize || e.total;
        const perc = Math.floor((done / total) * 1000) / 10;
        if (perc >= 100) {
          this.setState({
            uploadProgress: 100
          });
        } else {
          this.setState({
            uploadProgress: perc
          });
        }
      };
      // todo: parameterize this path
      xhr.open('POST', '/api/alltrails/convert', true);
      xhr.setRequestHeader('X-AT-KEY', this.props.apiKey);
      xhr.setRequestHeader('X-AT-AUTH-TOKEN', this.props.authToken);
      xhr.setRequestHeader('X-Language-Locale', this.props.languageRegionCode);
      xhr.setRequestHeader('X-CSRF-Token', document.querySelector('meta[name="csrf-token"]').content);
      xhr.onload = function () {
        if (this.status >= 200 && this.status < 300) {
          resolve(xhr.response);
        } else if (this.status == 400) {
          const parsed = JSON.parse(xhr.response);
          reject({
            status: this.status,
            localizedErrorMsg: parsed.localizedErrorMsg
          });
        } else {
          reject({
            status: this.status,
            statusText: xhr.statusText
          });
        }
      };
      xhr.onerror = function () {
        reject({
          status: this.status,
          statusText: xhr.statusText
        });
      };
      xhr.send(data);
    });
  }

  handleUploadComplete(filePath, map) {
    // check if the user is logged in. If not, send them to the login page.
    if (!this.props.loggedInUser && !this.props.mobileBrowser) {
      return (window.location = LanguageSupportUtil.wrapUrlSafe('/signup?returnTo=/converter/success', this.props.languageRegionCode));
    }
    // If the user is logged in, set uploadComplete to true to send them to the success page.
    if (map) {
      window.location = LanguageSupportUtil.wrapUrlSafe('/converter/success', this.props.languageRegionCode);
    } else {
      this.setState(state => {
        state.submitting = false;
        state.uploadComplete = true;
        state.files.uploadedPath = filePath;
      });
    }
  }

  formReset() {
    // This runs on form field change events (for clearing validation/error messages)
    this.handleError('');
  }

  handleError(message) {
    this.setState({
      submitting: false,
      errorMessage: message
    });
  }

  serializeFormData(formData) {
    const response = {};
    for (let i = 0; i < formData.length; i++) {
      response[formData[i].name] = formData[i].value;
    }
    return response;
  }

  handleSubmit(event) {
    event.preventDefault();
    this.formReset();
    this.setState({
      submitting: true
    });

    if (!this.state.files.isAttached && !this.state.files.fileUrl) {
      this.handleError(this.props.intl.formatMessage({ defaultMessage: 'Please attach a file or file url to convert.' }));
      return;
    }

    if (this.state.files.fileSize > this.state.fileSizeLimit) {
      // todo: use displayFileSize function to format this dynamically
      // todo: perform file size validation when attaching, not when submitting
      this.handleError(this.props.intl.formatMessage({ defaultMessage: 'Your file is too large, please choose a file below 8mb.' }));
      return;
    }

    const formData = new FormData(event.target);
    formData.append('key', this.props.apiKey);
    formData.append('formFileUrl', this.state.files.fileUrl);
    formData.append('filetype', this.state.downloadAs);

    if (this.state.files.isAttached) {
      formData.append('formFile', this.state.files.attachedFile);
    }

    if (!!this.state.routeSimplification.selectedOption) {
      formData.append('trackSimplification', this.state.routeSimplification.selectedOption);
      if (this.state.routeSimplification.selectedOption === 'custom') {
        formData.append('maxTrackpoints', this.state.routeSimplification.value);
      }
    }

    if (this.state.cueSheets.on) {
      formData.append(this.props.cueSheetOptions.number.name, this.state.cueSheets.number);
      formData.append(this.props.cueSheetOptions.distance.name, this.state.cueSheets.distance);
    }

    const context = this;

    const selectedRouteOptions = this.state.routeOptions?.options?.filter(option => option.selected);
    selectedRouteOptions.forEach(option => {
      formData.append(`${option.id}`, option.selected);
    });

    this.sendData(formData)
      .then(response => {
        const parsed = JSON.parse(response);
        this.handleUploadComplete(parsed.s3_path, parsed.map);
      })
      .catch(function (err) {
        // Some kind of error happened on upload
        context.handleError(
          err.localizedErrorMsg
            ? err.localizedErrorMsg
            : this.props.intl.formatMessage({ defaultMessage: 'There was an issue uploading your file. Please check your file and try again.' })
        );
      });
  }

  toggleRouteOption(optionKey) {
    const clonedOptions = [...this.state.routeOptions.options];
    this.setState({
      routeOptions: {
        ...this.state.routeOptions,
        options: clonedOptions.map(o => (o.id === optionKey ? { ...o, selected: !o.selected } : o))
      }
    });
  }

  toggleCueSheetOptions() {
    const context = this;
    const cueSheetState = this.state.cueSheets;
    cueSheetState.on = !cueSheetState.on;
    context.setState({ cueSheets: cueSheetState });
  }

  selectTrackType(selected) {
    this.setState({
      convertTo: selected,
      downloadAs: this.props.fileTypes[selected][0].value
    });
  }

  selectSimplificationOption(value) {
    const currentSimplification = this.state.routeSimplification;
    currentSimplification.selectedOption = value;
    this.setState({ routeSimplification: currentSimplification });
  }

  getFileSize() {
    // Limit FileSize
    let { fileSize } = this.state.files;
    if (fileSize === 0) {
      return 0;
    }
    // todo: make much more robust file size component
    fileSize = (fileSize / 1000).toFixed(1);
    return `${fileSize} kb`;
  }

  getRouteSimplificationOptions() {
    const options = this.props.routeSimplification;
    return options.map(option => ({
      text: this.props.intl.formatMessage(routeSimplificationMessages[option.stringKey]),
      selected: option.value === this.state.routeSimplification.selectedOption,
      value: option.value
    }));
  }

  getFileTypeOptions() {
    const options = this.state.fileTypes;
    return options.map(fileType => ({
      text: fileType.display,
      selected: fileType.value === this.state.convertTo,
      value: fileType.value
    }));
  }

  setFileUrl(value) {
    const currentFiles = this.state.files;
    currentFiles['fileUrl'] = value;
    this.setState({ files: currentFiles });
  }

  updateDownloadAs(downloadAs) {
    this.setState({ downloadAs });
  }

  render() {
    const fileUrlPlaceholder = this.props.intl.formatMessage({ defaultMessage: 'Or link supported file here' });
    const fileUploadSection = (
      <div>
        <DragAndDrop disabled={this.state.files.fileUrl} handleDrop={this.handleDrop().bind(this)}>
          <FileUpload
            key={this.state.files.key}
            handleChange={this.handleFileFieldChange().bind(this)}
            handleRemove={this.handleRemove().bind(this)}
            fileName={this.state.files.fileName}
            fileSize={this.getFileSize()}
            fileAttached={this.state.files.isAttached}
          />
        </DragAndDrop>
        <TextInput
          disabled={this.state.files.isAttached}
          label={fileUrlPlaceholder}
          value={this.state.files.fileUrl}
          labelText={fileUrlPlaceholder}
          onChange={this.setFileUrl.bind(this)}
        />
      </div>
    );

    const bodyContent = (
      <form onSubmit={this.handleSubmit.bind(this)}>
        <input type="hidden" name="metric" value={this.props.metric} />
        <div className="container">
          <div className={classNames(styles.section, styles.firstSection)}>
            <Typography variant="heading400" component="h2" mb="12">
              <FormattedMessage defaultMessage="Upload file" />
            </Typography>
            <div className="panel-body">
              <Typography variant="text200">
                <p>
                  <FormattedMessage defaultMessage="Use the AllTrails Route Converter to upload a route and convert to any of our supported file formats." />
                </p>
                <FormattedMessage
                  defaultMessage="View <a>supported file formats</a>"
                  values={{
                    a: chunks => (
                      <Link href={this.props.supportUrl} target="_blank" size="md" testId="file-formats">
                        {chunks}
                      </Link>
                    )
                  }}
                />
              </Typography>
              <div className="panel-body">{fileUploadSection}</div>
            </div>
          </div>
          <div className={styles.section}>
            <Typography variant="heading400" component="h2" mb="16">
              <FormattedMessage defaultMessage="Convert to" />
            </Typography>
            <div className="panel-body">
              <RadioGroup
                options={this.getFileTypeOptions()}
                onChange={this.selectTrackType.bind(this)}
                testId="converter-radio-group"
                className={styles.downloadRadioGroup}
              />
              <Select
                labelText={this.props.intl.formatMessage({ defaultMessage: 'Download as:' })}
                placeholder={this.props.intl.formatMessage({ defaultMessage: 'Download as:' })}
                onChange={this.updateDownloadAs.bind(this)}
                options={this.props.fileTypes[this.state.convertTo]}
                testId="converter-filetype-select"
                value={this.state.downloadAs}
              />
            </div>
          </div>
          <div className={styles.section}>
            <Typography variant="heading400" component="h2" mb="12">
              <FormattedMessage defaultMessage="Customize route" />
            </Typography>
            <Expandable title={<FormattedMessage defaultMessage="Route options" />} titleSize="md">
              <div className="panel-body">
                <CheckboxGroup
                  options={this.state.routeOptions.options}
                  onChange={this.toggleRouteOption.bind(this)}
                  testId="checkbox-route-options-converter"
                />
              </div>
            </Expandable>
            <Expandable title={<FormattedMessage defaultMessage="Route simplification" />} titleSize="md">
              <div id="rt-simplification">
                <RadioGroup
                  options={this.getRouteSimplificationOptions()}
                  selected={this.state.routeSimplification.selectedOption}
                  onChange={this.selectSimplificationOption.bind(this)}
                />
              </div>
              <div className={styles.sliderContainer}>
                <SliderGroup
                  label={
                    <span>
                      <b>
                        <FormattedMessage defaultMessage="{value} pts" values={{ value: this.state.routeSimplification.value }} />
                      </b>
                    </span>
                  }
                  min={1}
                  disabled={this.state.routeSimplification.selectedOption != 'custom'}
                  max={this.props.routeSimplification[this.props.routeSimplification.length - 1].max}
                  name="maxTrackpoints"
                  onChange={val => this.setValue(val, 'routeSimplification', 'value')}
                  value={this.state.routeSimplification.value}
                  accessibleLabel={this.props.intl.formatMessage({ defaultMessage: 'Route simplification' })}
                />
              </div>
            </Expandable>
            <Expandable title={<FormattedMessage defaultMessage="Cue sheets" />} titleSize="md" hideBorder>
              <div className="panel-body">
                <div>
                  <div className="form-prompt">
                    <p className="body3">
                      <FormattedMessage defaultMessage="Cue sheets can be used with third party vendors. You can customize cues to meet the specifications of your watch or device" />
                    </p>
                  </div>
                  <div>
                    <Switch
                      className="form-group toggle-group"
                      labelText={
                        this.state.cueSheets.on
                          ? this.props.intl.formatMessage({ defaultMessage: 'On' })
                          : this.props.intl.formatMessage({ defaultMessage: 'Off' })
                      }
                      selected={this.state.cueSheets.on}
                      testId="toggleCueSheetSwitch"
                      onChange={this.toggleCueSheetOptions.bind(this)}
                    />
                    <input name="toggleCueSheets" value={this.state.cueSheets.on ? 'on' : 'off'} type="hidden" />
                  </div>
                </div>
                <SliderGroup
                  label={
                    <span>
                      <FormattedMessage defaultMessage="Number of cues:" /> <b>{this.state.cueSheets.number}</b>
                    </span>
                  }
                  min={1}
                  disabled={!this.state.cueSheets.on}
                  max={this.props.cueSheetOptions.number.range}
                  name={this.props.cueSheetOptions.number.name}
                  onChange={val => this.setValue(val, 'cueSheets', 'number')}
                  value={this.state.cueSheets.number}
                  accessibleLabel={this.props.intl.formatMessage({ defaultMessage: 'Number of cues' })}
                />
                <SliderGroup
                  label={
                    <span>
                      <FormattedMessage
                        defaultMessage="Move cue: <b></b> before turn"
                        values={{
                          b: () => (
                            <b>
                              {this.state.cueSheets.distance} {this.props.cueSheetOptions.distance.units}
                            </b>
                          )
                        }}
                      />
                    </span>
                  }
                  min={1}
                  disabled={!this.state.cueSheets.on}
                  max={this.props.cueSheetOptions.distance.range}
                  name={this.props.cueSheetOptions.distance.name}
                  onChange={val => this.setValue(val, 'cueSheets', 'distance')}
                  value={this.state.cueSheets.distance}
                  accessibleLabel={this.props.intl.formatMessage({ defaultMessage: 'Cue sheet distance' })}
                />
              </div>
            </Expandable>
          </div>
          <div className={styles.foot}>
            <Button
              loading={this.state.submitting}
              text={<FormattedMessage defaultMessage="Convert file" />}
              testId="converter-submit"
              type="submit"
              variant="primary"
            />
            <div className="errorMessage">{this.state.errorMessage}</div>
            <div className="body3">
              <p>
                <FormattedMessage defaultMessage="The following formats are supported (maximum 8MB, automatically detected):" />
              </p>
              <p>
                Google Earth (KML, KMZ), Google Maps directions (XML, JSON), PCX5 (tracks, waypoints), GPX (tracks, routes, waypoints), GPX Garmin
                Streetpilot, Garmin Course (CRS, TCX), FIT (ANT+), MS Excel, CSV (Comma-Separated-Values), Falk IBEX Tour, CompeGPS, VDO GP7 (TRC),
                GeoRSS, Logbook, NMEA, OVL (ASCII), Fugawi, KOMPASS Verlag (Alpenverein), TrainingPeaks (PWX), Navigon Route, OziExplorer, qpeGps
                Track, MagicMaps IKT, TomTom BIN (ttbin), TomTom ITN, Suunto SDF, Magellan Track, PathAway
              </p>
            </div>
          </div>
        </div>
      </form>
    );

    return (
      <div className="wrapper">
        <div className="head">
          <div className={styles.hero}>
            <h1 className="text-center">
              <FormattedMessage defaultMessage="Route converter" />
            </h1>
          </div>
        </div>
        {bodyContent}
      </div>
    );
  }
}

const ConverterIntlProvider = props => {
  const languageRegionCode = useLanguageRegionCode();
  const intl = useIntl();
  return <BaseConverter {...props} intl={intl} supportUrl={getHelpCenterUrl(languageRegionCode, 360032749611)} />;
};

const Converter = props => {
  return (
    <CustomProvider>
      <ConverterIntlProvider {...props} />
    </CustomProvider>
  );
};

export { Converter };
