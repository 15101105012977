import { useState, useEffect } from 'react';
import type GeoLocation from '@alltrails/shared/types/Location/GeoLocation';

type LatLng = {
  latitude: number;
  longitude: number;
};

type Position = {
  coords: LatLng;
};

const useCurrentGeoLocation = (options = {}, isEnabled = false) => {
  const [geoLocation, setGeoLocation] = useState<GeoLocation>({ lat: null, lng: null, pending: false, isLocationBlocked: false });
  const [geoLocationError, setGeoLocationError] = useState<any>(null);

  const handleSuccess = (position: Position) => {
    const { latitude, longitude } = position.coords;

    setGeoLocation({
      lat: latitude,
      lng: longitude,
      pending: false,
      isLocationBlocked: false
    });
  };

  const handleError = (err: any) => {
    setGeoLocation({
      lat: null,
      lng: null,
      pending: false,
      isLocationBlocked: true
    });
    setGeoLocationError(err.message);
  };

  useEffect(() => {
    // A caller may conditionally enable this hook. Wait until it is enabled.
    if (!isEnabled) {
      return;
    }

    // When enabled set pending to true and clear out data.
    setGeoLocation({ lat: null, lng: null, pending: true, isLocationBlocked: false });

    const { geolocation } = navigator;

    if (!geolocation) {
      setGeoLocationError('Geolocation is not supported.');
      return;
    }

    geolocation.getCurrentPosition(handleSuccess, handleError, options);
  }, [options, isEnabled]);

  return { geoLocation, geoLocationError };
};

export default useCurrentGeoLocation;
