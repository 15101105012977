import { useState } from 'react';
import { FormattedMessage } from '@alltrails/shared/react-intl';
import Button from '@alltrails/shared/denali/components/Button';
import OutlinedTextField from '@alltrails/shared/components/OutlinedTextField';
import * as styles from './styles/styles.module.scss';

type TrailDetailItemFormProps = {
  errorMessage: React.ReactNode;
  handleCancelPress: () => void;
  handleSubmit: (text: string) => void;
  label: React.ReactNode;
  submitting: boolean;
  text: string;
};

const TrailDetailItemForm = ({ errorMessage, handleCancelPress, handleSubmit, label, submitting, text }: TrailDetailItemFormProps): JSX.Element => {
  const [textValue, setTextValue] = useState(text);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (submitting) {
      return;
    }

    setTextValue(e.currentTarget.value);
  };

  const handleSubmitInternal = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (submitting) {
      return;
    }

    handleSubmit(textValue);
  };

  return (
    <form onSubmit={handleSubmitInternal}>
      <OutlinedTextField
        name="description"
        errorMessage={errorMessage}
        value={textValue}
        label={label}
        changeHandler={handleChange}
        multiline
        verticalResize
        rows={8}
      />
      <div className={styles.buttonContainer}>
        <Button
          disabled={submitting}
          testId="trail-detail-item-submit"
          text={<FormattedMessage defaultMessage="Submit" />}
          type="submit"
          variant="primary"
          width="full"
        />
        <Button onClick={handleCancelPress} text={<FormattedMessage defaultMessage="Cancel" />} testId="trail-item-form-cancel" variant="flat" />
      </div>
    </form>
  );
};

export default TrailDetailItemForm;
