import { atMapsToGeojson } from '@alltrails/maps/utils/legacyGeoJSONConversions';
import { loadVerifiedRoute, loadAtMap } from 'utils/requests/at_map_requests';

type TrailType = 'trail' | 'map';

export default async function fetchAndDecodeCoordinates(id: string, type: TrailType) {
  if (!id) {
    return null;
  }
  try {
    const map = type === 'trail' ? await loadVerifiedRoute(id) : await loadAtMap(id, 'deep');
    return map ? atMapsToGeojson([map]) : null;
  } catch (e) {
    console.log('getGeojsonAndBounds error= ', e);
    return null;
  }
}
