import useFormatMessage from '@alltrails/shared/hooks/useFormatMessage';
import { LanguageSupportUtil } from './language_support_util';
import { PhotoSize } from '@alltrails/shared/types/photos';
import { getPhotoUrl, getTrailProfilePhoto } from '@alltrails/shared/utils/requests/photoRequests';

import {
  FILTER_ACCESS_STRINGS,
  FILTER_ACTIVITIES_STRINGS,
  FILTER_DIFFICULTIES_STRINGS,
  FILTER_FEATURE_TYPES_STRINGS,
  FILTER_LENGTHS_STRINGS,
  FILTER_OBSTACLES_STRINGS,
  FILTER_ROUTE_TYPES_FILTER
} from './filters/filters.strings';

const getTrailProfilePhotoUrl = (trail, size = PhotoSize.Large) => {
  if (trail.profile_photo_url !== undefined) {
    // Note: in case that profile_photo_url is null, we simply want to assign a null value
    // rather than send requests to the API that would knowingly return as 404s
    return trail.profile_photo_url;
  }
  if (trail.profile_photo_data) {
    const [photoId, photoHash] = trail.profile_photo_data.split('-');
    return getPhotoUrl({ id: photoId, photoHash }, size);
  }
  return getTrailProfilePhoto(trail.ID, size, '320x320');
};

const getFormattedTrailUrls = (trail, languageRegionCode, prefix = null, suffix = null) => {
  // trail.slug has /trail already because its from algolia
  // If we dont have a slug for some reason go to explore
  let trailUrl;
  if (trail.slug) {
    trailUrl = trail.slug.includes('trail/') ? trail.slug : `/trail/${trail.slug}`;
    if (!trailUrl.startsWith('/')) {
      trailUrl = `/${trailUrl}`;
    }
    if (prefix) {
      if (!prefix.startsWith('/')) {
        prefix = `/${prefix}`;
      }
      trailUrl = `${prefix}${trailUrl}`;
    }
    if (suffix) {
      if (!suffix.startsWith('/')) {
        suffix = `/${suffix}`;
      }
      trailUrl = `${trailUrl}${suffix}`;
    }
  } else {
    trailUrl = `/explore`;
  }

  return {
    url: trailUrl,
    localizedUrl: LanguageSupportUtil.wrapUrlSafe(trailUrl, languageRegionCode)
  };
};

const inIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

const getAbbrevDescription = (trail, displayMetric) => {
  if (!displayMetric && trail.abbrev_desc_i) {
    return trail.abbrev_desc_i;
  }
  if (displayMetric && trail.abbrev_desc_m) {
    return trail.abbrev_desc_m;
  }

  return trail.description;
};

const getAllTags = () => {
  const { formattedDefaultMessages } = useFormatMessage({
    ...FILTER_ACCESS_STRINGS,
    ...FILTER_ACTIVITIES_STRINGS,
    ...FILTER_DIFFICULTIES_STRINGS,
    ...FILTER_FEATURE_TYPES_STRINGS,
    ...FILTER_LENGTHS_STRINGS,
    ...FILTER_OBSTACLES_STRINGS,
    ...FILTER_ROUTE_TYPES_FILTER
  });

  return formattedDefaultMessages;
};

const constantsKey = string => {
  return string.toUpperCase().replace(/(\s)|[-]/gi, '_');
};

const translateTag = (tag, localizedTags = getAllTags()) => {
  const tagKey = constantsKey(tag);
  return localizedTags[tagKey] || tag;
};

/*
  This logic was taken from TagCloud.tsx
  trailTags Data structure from the server:
  trailTags: {
    whoToBring: String[];
    whatToDo: String[];
    whatToSeeAndObstacles: String[];
  };
*/
const getTagGroupArray = trailTags => {
  if (!trailTags) return [];

  return Object.values(trailTags)
    .reduce((allTags, tagSubset) => (tagSubset ? [...allTags, ...tagSubset] : allTags), [])
    .map(tag => ({ text: translateTag(tag) }));
};

const translateRouteType = routeType => {
  const routeKey = constantsKey(routeType).replace('&', 'AND'); // for Out & Back
  return getAllTags()[routeKey] || routeType;
};

export {
  getTrailProfilePhotoUrl,
  getFormattedTrailUrls,
  getAbbrevDescription,
  inIframe,
  translateTag,
  getTagGroupArray,
  getAllTags,
  translateRouteType
};
