import { useIntl } from '@alltrails/shared/react-intl';
import type { Context } from 'types/Context';
import { Trail } from '@alltrails/shared/types/trail';
import Waypoint from 'types/Waypoint';
import useAttributes from './useAttributes';
import useCollections, { CollectionMappings } from './useCollections';
import { EditTrailBox as EditTrailBoxBase } from './edit_trail_box';

type ContributeTrail = Trail & {
  canEdit?: boolean;
  trailSource?: string;
  numViews?: number;
  isCountryTopTrail?: string;
  lastEditedTimestamp?: string;
  collectionMappings?: CollectionMappings[];
};

type Props = {
  context: Context;
  trail: ContributeTrail;
  routeInfo: any;
  insertRouteInfo: any;
  defaultMap: any;
  mapCenter: number[];
  currentCity: any;
  hasAdminFeatures: boolean;
  displayMetric: boolean;
  allowNameEditing: boolean;
  rapidRoute: boolean;
  handleMapChange: (defaultMap: any) => void;
  handleAddTrailClick: () => void;
  nextTrailCallback: () => void;
  messagingChannel: any;
  waypointMapId: number;
  waypoints: Waypoint[];
  refetchTrailProps?: () => void;
  areWaypointsLoading?: boolean;
};

const EditTrailBox = (props: Props) => {
  const { trail, defaultMap } = props;
  const intl = useIntl();
  // Manage state here so that we can eventually move away from EditTrailBoxBase accessing refs of its children when computing edits
  const attributeState = useAttributes({ trail, defaultMap });
  const collectionsState = useCollections({ trail });

  return <EditTrailBoxBase {...props} {...attributeState} {...collectionsState} intl={intl} />;
};

export default EditTrailBox;
