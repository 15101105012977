import { createRoute, updateRoute, createWaypoint, updateWaypoint } from './requests/at_map_requests';

const routeRequest = (mapId, route) => {
  if (!route) {
    return null;
  }

  const ajaxRequest = route.id ? updateRoute : createRoute;
  return ajaxRequest(mapId, route);
};

const waypointRequest = (mapId, waypoint) => {
  if (!waypoint) {
    return null;
  }

  const ajaxRequest = waypoint.id ? updateWaypoint : createWaypoint;
  return ajaxRequest(mapId, waypoint);
};

const formatWaypointForMap = waypoint => {
  const { name, description, location, showTitle } = waypoint;

  return {
    name,
    description,
    location,
    waypointDisplayProperty: {
      showTitle
    }
  };
};

export { routeRequest, waypointRequest, formatWaypointForMap };
