import classNames from 'classnames';
import IconButton from '@alltrails/shared/denali/components/IconButton';
import Location from '@alltrails/shared/icons/Location';
import ZoomIn from '@alltrails/shared/icons/ZoomIn';
import ZoomOut from '@alltrails/shared/icons/ZoomOut';
import RotateLeft from '@alltrails/shared/icons/RotateLeft';
import RotateRight from '@alltrails/shared/icons/RotateRight';
import { useIntl } from '@alltrails/shared/react-intl';
import useIsMobile from '@alltrails/shared/hooks/useIsMobile';
import useIsMinimumMapHeight from '@alltrails/shared/hooks/useIsMinimumMapHeight';
import { wrapEventHandler } from '../../../utils/handlers';
import * as styles from './styles/styles.module.scss';

type ZoomControlsProps = {
  variant?: 'with-elevation-pane' | '';
  shouldConditionallyRender?: boolean;
  handleZoomInClick: () => void;
  handleZoomOutClick: () => void;
  handleCurrLocClick: () => void;
  handleUndoClick?: () => void;
  handleRedoClick?: () => void;
};

const ZoomControls = ({
  variant,
  shouldConditionallyRender,
  handleZoomInClick,
  handleZoomOutClick,
  handleCurrLocClick,
  handleUndoClick,
  handleRedoClick
}: ZoomControlsProps) => {
  const intl = useIntl();
  const isMobile = useIsMobile();
  const isMinimumMapHeight = useIsMinimumMapHeight();
  const shouldRenderZoomControls = !shouldConditionallyRender || !(isMobile || !isMinimumMapHeight);
  const shouldRenderLocationButton = !shouldConditionallyRender || isMinimumMapHeight;

  return (
    <div
      className={classNames(styles.container, styles.withNewElevationChart, {
        [styles.withElevationPane]: variant === 'with-elevation-pane'
      })}
    >
      {handleUndoClick ? (
        <IconButton
          icon={{ Component: RotateLeft }}
          onClick={wrapEventHandler(handleUndoClick)}
          testId="undo"
          title={intl.formatMessage({ defaultMessage: 'Undo' })}
          variant="elevated"
          size="sm"
        />
      ) : null}
      {handleRedoClick ? (
        <IconButton
          icon={{ Component: RotateRight }}
          onClick={wrapEventHandler(handleRedoClick)}
          testId="redo"
          title={intl.formatMessage({ defaultMessage: 'Redo' })}
          variant="elevated"
          size="sm"
        />
      ) : null}
      {shouldRenderZoomControls && (
        <>
          <IconButton
            icon={{ Component: ZoomIn }}
            onClick={wrapEventHandler(handleZoomInClick)}
            testId="zoom-map-in"
            title={intl.formatMessage({ defaultMessage: 'Zoom map in' })}
            variant="elevated"
            size="sm"
          />
          <IconButton
            icon={{ Component: ZoomOut }}
            onClick={wrapEventHandler(handleZoomOutClick)}
            testId="zoom-map-out"
            title={intl.formatMessage({ defaultMessage: 'Zoom map out' })}
            variant="elevated"
            size="sm"
          />
        </>
      )}
      {shouldRenderLocationButton && (
        <IconButton
          icon={{ Component: Location }}
          onClick={wrapEventHandler(handleCurrLocClick)}
          testId="current-location"
          title={intl.formatMessage({ defaultMessage: 'Current location' })}
          variant="elevated"
          size="sm"
        />
      )}
    </div>
  );
};

export default ZoomControls;
