import { lazy, Suspense, useState } from 'react';
import logConditionsEmptyStateIconTapped from '@alltrails/analytics/events/logConditionsEmptyStateIconTapped';
import IconButton from '@alltrails/shared/denali/components/IconButton';
import Info from '@alltrails/shared/icons/Info';
import type Review from '@alltrails/shared/types/review';
import { useTrailUgcContext } from 'components/trailUgc/TrailUgcContext';
import Trail from 'types/Trails/Trail';
import { modalRoadblock } from '@alltrails/shared/utils/modalFunnelUtils';
import useUser from 'hooks/useUser';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import * as styles from './styles/styles.module.scss';

const ReviewFormModal = lazy(() => import('@alltrails/modules/TrailReviews/ReviewFormModal'));
const TrailConditionsEmptyModal = lazy(() => import('./TrailConditionsEmptyModal'));

type ConditionsModalKey = 'reviews' | 'emptyConditions' | null;

export type TrailConditionsEmptyProps = {
  trail: Trail;
};

const TrailConditionsEmpty = ({ trail }: TrailConditionsEmptyProps): JSX.Element => {
  const user = useUser();
  const languageRegionCode = useLanguageRegionCode();
  const { dispatch } = useTrailUgcContext();
  const [activeModal, setActiveModal] = useState<ConditionsModalKey>(null);

  const onReviewAdd = (data: { detail: Review }) => dispatch({ type: 'ADD_REVIEW', review: data.detail });

  const reviewFormProps = {
    trailId: trail.trail_id,
    trailName: trail.name,
    rating: null as number,
    multistep: true,
    user,
    onReviewAdd
  };

  const onWriteReviewClicked = () => {
    if (!user) {
      const returnToUrl = window.location.pathname + window.location.search;
      modalRoadblock('signup', 'trail-contribute-reviews', returnToUrl, languageRegionCode);
      return;
    }

    setActiveModal('reviews');
  };

  /* NOTE: no translations needed for now, EN/US only feature */
  return (
    <div>
      <div className={styles.emptyHeader}>
        No conditions reported in the past 7 days
        <div className={styles.infoButton}>
          <IconButton
            icon={{ Component: Info }}
            onClick={() => {
              logConditionsEmptyStateIconTapped();
              setActiveModal('emptyConditions');
            }}
            size="sm"
            testId="trail-conditions-no-conditions-info"
            title="More info about how conditions are reported"
            variant="flat"
          />
        </div>
      </div>
      <div className={styles.emptyText}>
        Want to report conditions for this trail?{' '}
        <button className={styles.reviewLink} onClick={onWriteReviewClicked} type="button">
          Write a review
        </button>{' '}
        to inform other visitors!
      </div>
      {activeModal === 'reviews' && (
        <Suspense fallback={null}>
          <ReviewFormModal {...reviewFormProps} closeReviewModal={() => setActiveModal(null)} />
        </Suspense>
      )}
      {activeModal === 'emptyConditions' && (
        <Suspense fallback={null}>
          <TrailConditionsEmptyModal closeModal={() => setActiveModal(null)} />
        </Suspense>
      )}
    </div>
  );
};

export default TrailConditionsEmpty;
