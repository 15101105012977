import { useReducer, useEffect, useRef, useCallback } from 'react';
import { lineOrSegSort } from '@alltrails/maps/utils/legacyGeoJSONConversions';

const defaultRouteColor = '#FF0000';
const defaultEditParams = {
  id: null,
  distanceTotal: 0,
  elevationGain: 0,
  lineDisplayProperty: { color: defaultRouteColor },
  initialColor: defaultRouteColor,
  lineSegments: []
};
// actions
const ADD_NEW_ROUTE = 'ADD_NEW_ROUTE';
const CANCEL_ROUTE_FORM = 'CANCEL_ROUTE_FORM';
const REMOVE_ROUTE = 'REMOVE_ROUTE';
const REORDER_ROUTES = 'REORDER_ROUTES';
const UPDATE_EDIT_INDEX = 'UPDATE_EDIT_INDEX';
const UPDATE_GEO_STATS = 'UPDATE_GEO_STATS';
const UPDATE_ROUTE_COLOR = 'UPDATE_ROUTE_COLOR';
const UPDATE_SORT_MODE = 'UPDATE_SORT_MODE';
const UPDATE_FOR_INCOMING_ROUTES = 'UPDATE_FOR_INCOMING_ROUTES';
const UPDATE_ROUTE_FORM = 'UPDATE_ROUTE_FORM';
const UPDATE_ROUTE_OPTIONS = 'UPDATE_ROUTE_OPTIONS';

const organizeInitialRoutes = (initialRoutes = []) =>
  initialRoutes
    .map(route => {
      // newly uploaded routes don't have a color display property so we want to give it a default
      if (typeof route.lineDisplayProperty === 'undefined') {
        return { ...route, lineDisplayProperty: { color: defaultRouteColor } };
      }

      return route;
    })
    .sort(lineOrSegSort);

// reducer
const routingReducer = (state, action) => {
  switch (action.type) {
    case ADD_NEW_ROUTE: {
      const routes = [...state.routes, action.payload.route];
      // Map source is only available when there is a single route
      const mapSource = routes.length === 1 ? action.payload.mapSource : null;

      return {
        ...state,
        routes,
        editParams: defaultEditParams,
        editIndex: action.payload.editIndex,
        mapSource,
        routeOptions: null
      };
    }
    case UPDATE_GEO_STATS:
      return {
        ...state,
        editParams: { ...state.editParams, [action.payload.key]: action.payload.stat }
      };
    case UPDATE_ROUTE_FORM: {
      return {
        ...state,
        editParams: { ...state.editParams, ...action.payload }
      };
    }
    case UPDATE_ROUTE_COLOR:
      return {
        ...state,
        editParams: { ...state.editParams, lineDisplayProperty: { color: action.payload } },
        routes: state.routes.map((route, idx) => {
          if (idx === state.editIndex) {
            return { ...route, lineDisplayProperty: { color: action.payload } };
          }

          return route;
        })
      };
    case UPDATE_ROUTE_OPTIONS: {
      // Route options are only available when there is a single route
      if (state.routes.length > 1) {
        return state;
      }

      const routeOptions = state.routeOptions ? [...state.routeOptions, action.payload] : [action.payload];

      return {
        ...state,
        routeOptions
      };
    }
    case REMOVE_ROUTE: {
      const routes = state.routes.filter(route => route.id !== action.payload);

      return {
        ...state,
        routes
      };
    }
    case CANCEL_ROUTE_FORM:
      return {
        ...state,
        editIndex: null,
        editParams: null,
        routes: state.routes
          .map((route, idx) => {
            if (state.editIndex === idx) {
              return { ...route, lineDisplayProperty: { color: state.editParams.initialColor } };
            }

            return route;
          })
          .filter(r => r.id !== null),
        mapSource: null,
        routeOptions: null
      };
    case UPDATE_EDIT_INDEX:
      return {
        ...state,
        editIndex: action.payload.editIndex,
        editParams: action.payload.editParams
      };
    case REORDER_ROUTES: {
      return {
        ...state,
        routes: action.payload
      };
    }
    case UPDATE_SORT_MODE:
      return {
        ...state,
        editIndex: null,
        sortMode: action.payload
      };
    case UPDATE_FOR_INCOMING_ROUTES:
      return {
        ...state,
        editIndex: null,
        routes: [...organizeInitialRoutes(action.payload)]
      };
    default:
      return state;
  }
};

const useRoutes = ({ initialRoutes = [], messagingChannel, routeInfo = {}, canEdit = false }) => {
  const initialState = {
    routes: organizeInitialRoutes(initialRoutes),
    editIndex: null,
    editParams: null,
    sortMode: false,
    mapSource: null,
    routeOptions: null
  };

  const [state, dispatch] = useReducer(routingReducer, initialState);
  const prevStateRef = useRef(state);

  useEffect(() => {
    if (!routeInfo || !state.editParams) {
      return;
    }

    let { lineDisplayProperty, lineSegments } = state.editParams;
    const distanceTotal = routeInfo.distance;
    const { elevationGain } = routeInfo;

    if (routeInfo.plannerMap && routeInfo.plannerMap.routes && routeInfo.plannerMap.routes.length > 0) {
      lineDisplayProperty = routeInfo.plannerMap.routes[0].lineDisplayProperty;
      lineSegments = routeInfo.plannerMap.routes[0].lineSegments;
    }

    const payload = { distanceTotal, lineDisplayProperty, lineSegments, elevationGain };
    dispatch({ type: UPDATE_ROUTE_FORM, payload });
  }, [routeInfo]);

  useEffect(() => {
    const { editIndex, routes } = state;

    // if route editor has been cancelled
    if (editIndex === null && prevStateRef.current.editIndex !== null) {
      // fires handleQuickEditCancel in FullScreenSearchMap to disable trail planner
      messagingChannel.publish({
        topic: 'edit.disable_trail_planner'
      });
      return;
    }

    if (editIndex === null) {
      return;
    }

    // triggers removeRoutePreviewLines in FullScreenSearchMap
    messagingChannel.publish({
      topic: 'result.remove_route_preview_lines'
    });
    // triggers handleEditClick in FullScreenSearchMap
    messagingChannel.publish({
      topic: 'edit.enable_trail_planner',
      data: routes[editIndex]
    });
  }, [state.editIndex]);

  useEffect(() => {
    // if done sorting
    if (prevStateRef.current.sortMode && !state.sortMode) {
      // updates routes in exploreMap state with routes that are in order
      messagingChannel.publish({
        topic: 'routes.updated',
        data: state.routes
      });
    }
  }, [state.sortMode]);

  const dispatchRoutes = useCallback(({ type, payload }) => {
    dispatch({ type, payload });
  }, []);

  useEffect(() => {
    prevStateRef.current = state;
  }, [state]);

  if (!canEdit) {
    return [initialState, null];
  }

  return [state, dispatchRoutes];
};

export {
  useRoutes,
  ADD_NEW_ROUTE,
  UPDATE_ROUTE_COLOR,
  CANCEL_ROUTE_FORM,
  UPDATE_EDIT_INDEX,
  UPDATE_SORT_MODE,
  REORDER_ROUTES,
  REMOVE_ROUTE,
  UPDATE_FOR_INCOMING_ROUTES,
  UPDATE_ROUTE_OPTIONS,
  defaultRouteColor
};
