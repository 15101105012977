const encodeObjectsToPolyline = (values, cols, append, resolutions) => {
  const rows = values.length;
  values = values.reduce((a, b) => {
    return a.concat(b);
  }, []);

  let value = 0;
  let output = '';
  for (let i = 0; i < rows * cols; i++) {
    // in 0..rows * cols-1
    value = 0;
    // Calculate the delta between the last value and the current one
    // Need to round both to the correct resolution first otherwise a rounding error drift occurs
    value =
      Math.round(Math.pow(10, resolutions[i % cols]) * values[i]) -
      (i < cols ? 0 : Math.round(Math.pow(10, resolutions[i % cols]) * values[i - cols]));

    // If value is negative take its two's complement ~value, and then left shift by 1 bit
    value = value < 0 ? ~(value << 1) : value << 1;

    // The number is split into 5bit chunks starting from the right
    // Iterate through each chunk in reverse order
    while (value >= 0x20) {
      // OR each value with 0x20 (ie 32)
      // (value & 0x1F) makes sure value is 0-31.
      // we then add 0x3F (ie 63 or '?') to place our chars into the right domain space ie ?@ABC...etc
      output += String.fromCharCode((0x20 | (value & 0x1f)) + 0x3f);
      // move to next 5bit chunk
      value >>= 5;
    }
    output += String.fromCharCode(value + 0x3f);
  }
  return output;
};

export { encodeObjectsToPolyline };
