import { ReactNode, createContext, useContext, useMemo, useReducer } from 'react';
import type Review from '@alltrails/shared/types/review';
import type { User } from 'types/User';
import type Track from '@alltrails/shared/types/track';
import { Photo } from 'types/Photo';
import trailUgcReducer, { TrailUgcState, TrailUgcAction } from '../../../ducks/trailUgc';
import ItemTotals from '../types/ItemTotals';

type TrailUgcInfo = {
  ugcState: TrailUgcState;
  dispatch: React.Dispatch<TrailUgcAction>;
};

type TrailUgcProviderProps = {
  ugcInfo: {
    reviews?: Review[];
    photos?: Photo[];
    tracks?: Track[];
    completed?: { users?: User[]; connectionId?: null | number };
    itemTotals: ItemTotals;
  };
  children: ReactNode;
};

/**
 * A context used to store general UGC information.
 */
const TrailUgcContext = createContext<TrailUgcInfo>({
  ugcState: {
    reviews: {
      items: null,
      sortOption: 'atSort',
      pageCount: 1,
      total: 0
    },
    photos: {
      items: null,
      sortOption: 'atSort',
      pageCount: 1,
      total: 0
    },
    tracks: { items: null, sortOption: 'desc', pageCount: 1, total: 0 },
    completed: { items: null, sortOption: 'atSort', pageCount: 1, total: 0, connectionId: null },
    selectedUgcTab: 'reviews',
    allUgcShown: false
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  dispatch: () => {}
});
TrailUgcContext.displayName = 'TrailUgcContext';

/**
 * A provider to share UGC information across components.
 */
const TrailUgcProvider = ({ ugcInfo, children }: TrailUgcProviderProps): JSX.Element => {
  const [state, dispatch] = useReducer(trailUgcReducer, {
    reviews: {
      items: ugcInfo?.reviews,
      sortOption: 'atSort',
      pageCount: 1,
      total: ugcInfo?.itemTotals?.reviews
    },
    photos: {
      items: ugcInfo?.photos,
      sortOption: 'atSort',
      pageCount: 1,
      total: ugcInfo?.itemTotals?.photos
    },
    tracks: { items: ugcInfo?.tracks, sortOption: 'desc', pageCount: 1, total: ugcInfo?.itemTotals?.maps },
    completed: {
      items: ugcInfo?.completed?.users,
      sortOption: 'atSort',
      pageCount: 1,
      total: ugcInfo?.itemTotals?.completed,
      connectionId: ugcInfo?.completed?.connectionId
    },
    selectedUgcTab: 'reviews',
    allUgcShown: false
  });

  const value = useMemo(
    () => ({
      ugcState: state,
      dispatch
    }),
    [state]
  );

  return <TrailUgcContext.Provider value={value}>{children}</TrailUgcContext.Provider>;
};

function useTrailUgcContext() {
  const context = useContext(TrailUgcContext);

  if (context === undefined) {
    throw new Error('The UGC context is undefined. Are you using useTrailUgcContext within TrailUgcProvider?');
  }

  return context;
}

export { useTrailUgcContext, TrailUgcContext, TrailUgcProvider };
