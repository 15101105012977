import PropTypes from 'prop-types';
import classNames from 'classnames';

import * as styles from './styles.module.scss';

function Circle({ isActive, step, isComplete }) {
  return (
    <svg className={classNames(styles.circle, { [styles.active]: isActive, [styles.complete]: isComplete })}>
      <circle cx="12" cy="12" r="12" />
      <text x="12" y="16" textAnchor="middle">
        {step}
      </text>
    </svg>
  );
}

function Stepper({ activeStep, classes, steps }) {
  // we can create a <Step component and refactor if we need additional functionality on the step
  return (
    <ul className={classes}>
      {[...Array(steps).keys()].map((step, index) => {
        return (
          <li className={styles.step} key={step} {...(step === activeStep ? { 'aria-current': 'step' } : {})}>
            {index !== 0 && <span className={classNames(styles.connector, { [styles.completedConnector]: step <= activeStep })} />}
            <Circle isActive={activeStep === step} step={step + 1} isComplete={step < activeStep} />
            {index !== steps - 1 && <span className={classNames(styles.connector, { [styles.completedConnector]: step < activeStep })} />}
          </li>
        );
      })}
    </ul>
  );
}

Stepper.propTypes = {
  activeStep: PropTypes.number.isRequired,
  steps: PropTypes.number.isRequired
};

export default Stepper;
