import { FormattedMessage } from '@alltrails/shared/react-intl';
import * as styles from './ProStatus.module.scss';

type Props = {
  inTrial: boolean;
  expirationDateString?: string;
  isAppleSubscription: boolean;
  isLifetimeSubscriber: boolean;
  isAutorenewable: boolean;
};

type ExpirationDateLabelProps = {
  expirationDateString?: string;
  inTrial: boolean;
};

function ExpirationDateLabel({ expirationDateString, inTrial }: ExpirationDateLabelProps) {
  if (!expirationDateString) {
    return <FormattedMessage defaultMessage="Unknown" />;
  }

  const expirationDate = new Date(expirationDateString);

  if (inTrial) {
    return <FormattedMessage defaultMessage="Your trial will expire on {expirationDate, date, long}" values={{ expirationDate }} />;
  }

  return <FormattedMessage defaultMessage="Your subscription will expire on {expirationDate, date, long}" values={{ expirationDate }} />;
}

function ExpirationDate({ isAppleSubscription, isAutorenewable, isLifetimeSubscriber, expirationDateString, inTrial }: Props) {
  if (isAppleSubscription) {
    return (
      <>
        <div className={styles.settingsPro}>
          <span className={styles.subtitle}>
            <FormattedMessage defaultMessage="Expiration date:" />{' '}
          </span>
          <span>
            <ExpirationDateLabel expirationDateString={expirationDateString} inTrial={inTrial} />
          </span>
        </div>
        {isAutorenewable && (
          <div className={styles.settingsPro}>
            <span>
              <a href="https://apps.apple.com/account/subscriptions" target="_blank" rel="noreferrer">
                <FormattedMessage defaultMessage="Check autorenewal status" />
              </a>
            </span>
          </div>
        )}
      </>
    );
  }

  return (
    <div className={styles.settingsPro}>
      {isLifetimeSubscriber ? (
        <span className={styles.subtitle}>
          <FormattedMessage defaultMessage="Lifetime member" />
        </span>
      ) : (
        <>
          <span className={styles.subtitle}>
            <FormattedMessage defaultMessage="Expiration date:" />{' '}
          </span>
          <ExpirationDateLabel expirationDateString={expirationDateString} inTrial={inTrial} />
        </>
      )}
    </div>
  );
}

export default ExpirationDate;
