// A route intersection node (clickable/draggable)
class RouteNode {
  constructor(idxs, lngLat, position = null) {
    this.id = idxs[0];
    this.idxs = idxs;
    this.lngLat = lngLat;
    this.position = position;
  }
}

export { RouteNode };
