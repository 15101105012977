import { PropsWithChildren } from 'react';
import { CSSTransition } from 'react-transition-group';
import { FLYOVER_ANIM_DURATION_MS } from 'utils/flyoverHelpers';
import * as flyoverStyles from 'styles/flyoverStyles.module.scss';

type FlyoverTransitionProps = {
  className?: string;
  showOn: boolean;
  unmountOnExit?: boolean;
  useShortEntry?: boolean;
};

const FlyoverTransition = ({
  children,
  className,
  showOn,
  unmountOnExit = false,
  useShortEntry = false
}: PropsWithChildren<FlyoverTransitionProps>): JSX.Element => (
  <CSSTransition
    classNames={{
      enter: flyoverStyles.controlsEnter,
      enterActive: useShortEntry ? flyoverStyles.controlsEnterActiveShort : flyoverStyles.controlsEnterActive,
      exit: flyoverStyles.controlsExit,
      exitActive: flyoverStyles.controlsExitActive
    }}
    in={showOn}
    timeout={FLYOVER_ANIM_DURATION_MS}
    unmountOnExit={unmountOnExit}
  >
    <div className={className}>{children}</div>
  </CSSTransition>
);

export default FlyoverTransition;
