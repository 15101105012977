import ShimmerWrapper from '@alltrails/shared/components/ShimmerWrapper';
import * as styles from './styles/styles.module.scss';

const TrailRealTimeWidgetsShimmer = (): JSX.Element => (
  <ShimmerWrapper className={styles.shimmerContainer} testId="trail-real-time-widgets-shimmer">
    <div className={styles.conditionsRow}>
      {[1, 2, 3, 4, 5].map(num => (
        <div className={styles.shimmerCondition} key={num} />
      ))}
    </div>
    <div className={styles.attributionShimmer} />
  </ShimmerWrapper>
);

export default TrailRealTimeWidgetsShimmer;
