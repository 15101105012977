import turfAlong from '@turf/along';
import turfDistance from '@turf/distance';
import { featureCollection, lineString } from '@turf/helpers';

const KM_TO_MILES = 0.621371;

// Offset and units based on displayMetric flag
const getUnitDetails = displayMetric => {
  let unitInKm;
  let unitsLabel;
  if (displayMetric) {
    unitInKm = 1; // 1 km in meters
    unitsLabel = 'km';
  } else {
    unitInKm = 1 / KM_TO_MILES; // 1 mile in meters
    unitsLabel = 'mi';
  }
  return {
    unitInKm,
    unitsLabel
  };
};

// Get distance markers as an array of geojsonPoints
const distanceMarkersAsGeojsonPoints = (coords, unitInKm, unitsLabel) => {
  const distanceMarkers = [];
  let prevDist = 0;
  coords.forEach((p2, i) => {
    if (i == 0) {
      return;
    }
    const p1 = coords[i - 1];
    const prevUnits = Math.floor(prevDist / unitInKm);

    const currDist = prevDist + turfDistance(p1, p2);
    const currUnits = Math.floor(currDist / unitInKm);

    if (currUnits >= prevUnits + 1) {
      const incUnits = currUnits - prevUnits;
      for (let j = 1; j <= incUnits; j++) {
        const distRemainder = (prevUnits + j) * unitInKm - prevDist;
        const geojsonPoint = turfAlong(lineString([p1, p2]), distRemainder);
        geojsonPoint.properties = {
          label: `${currUnits} ${unitsLabel}`
        };
        distanceMarkers.push(geojsonPoint);
      }
    }
    prevDist = currDist;
  });
  return distanceMarkers;
};

const distanceMarkersGeojson = (fromFeatureCollection, displayMetric) => {
  const { unitInKm, unitsLabel } = getUnitDetails(displayMetric);
  const geojsonPoints = fromFeatureCollection.features
    .map(feature => {
      let coordinates = feature.geometry.coordinates;
      coordinates = feature.geometry.type === 'MultiLineString' ? coordinates.flat() : coordinates;
      return distanceMarkersAsGeojsonPoints(coordinates, unitInKm, unitsLabel);
    })
    .flat();
  return featureCollection(geojsonPoints);
};

export { distanceMarkersGeojson };
