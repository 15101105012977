import { defineMessages } from '@alltrails/shared/react-intl';
import InputField from '@alltrails/shared/denali/components/InputField';
import Select from '@alltrails/shared/denali/components/Select';
import TextInput from '@alltrails/shared/denali/components/TextInput';
import useFormatMessage from '@alltrails/shared/hooks/useFormatMessage';
import LanguageRegionCode from '@alltrails/shared/types/LanguageRegionCode';

import * as styles from './styles/styles.module.scss';

type BirthdayType = 'month' | 'date' | 'year';

type BirthdayInputProps = {
  birthdayMonth?: number;
  birthdayDate?: number;
  birthdayYear?: number;
  handleBirthdayChange: (value: number, type: BirthdayType) => void;
  error?: string;
  languageRegionCode: LanguageRegionCode;
};

const messages = defineMessages({
  Year: { defaultMessage: 'Year' },
  Day: { defaultMessage: 'Day' },
  Month: { defaultMessage: 'Month' },
  DD: { defaultMessage: 'DD' },
  YYYY: { defaultMessage: 'YYYY' }
});

const BirthdayInput = ({ birthdayMonth, birthdayDate, birthdayYear, handleBirthdayChange, error, languageRegionCode }: BirthdayInputProps) => {
  const {
    formattedDefaultMessages: { Year, Day, Month, DD, YYYY }
  } = useFormatMessage(messages);

  const months = Array.from(Array(12), (elem, i) => ({
    label: new Date(0, i).toLocaleString(languageRegionCode, { month: 'long' }),
    value: (i + 1).toString()
  }));

  return (
    <InputField className={styles.container} errorText={error}>
      <Select
        labelText={Month}
        hideLabel
        className={styles.selectInput}
        placeholder={Month}
        options={months}
        onChange={value => handleBirthdayChange(parseInt(value, 10), 'month')}
        value={birthdayMonth ? birthdayMonth.toString() : ''}
        testId="birthday-select-month"
        errorText={error && ' '}
      />
      <TextInput
        autoComplete="bday-day"
        className={styles.dateInput}
        type="text"
        inputMode="numeric"
        placeholder={DD}
        labelText={Day}
        hideLabel
        value={birthdayDate ? birthdayDate.toString() : ''}
        testId="birthday-date-field"
        onChange={value => handleBirthdayChange(parseInt(value, 10), 'date')}
        errorText={error && ' '}
      />
      <TextInput
        autoComplete="bday-year"
        className={styles.yearInput}
        type="text"
        inputMode="numeric"
        placeholder={YYYY}
        labelText={Year}
        hideLabel
        value={birthdayYear ? birthdayYear.toString() : ''}
        testId="birthday-year-field"
        onChange={value => handleBirthdayChange(parseInt(value, 10), 'year')}
        errorText={error && ' '}
      />
    </InputField>
  );
};

export default BirthdayInput;
