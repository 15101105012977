import { pointItemsToGeojson } from '@alltrails/maps/utils/legacyGeoJSONConversions';
import { addOrUpdateGeojsonSource } from '../sources/geojson_helpers';
import { addMarkerImagesToMap, createMarkerLayer } from '../layers/markers';
import { addLayer, addLayerEvent, removeLayer } from '../layers/layer_helpers';
import { addHoverPopupToMap } from '../event_handling/popup_helpers';
import { addMarkerTooltipsToMap } from '../event_handling/tooltip_helpers';
import { addMarkerToolbarToMap } from '../event_handling/toolbar_helpers';
import { removeSource } from '../sources/source_helpers';
import { makeLayerDraggable, undoMakeLayerDraggable } from './draggable';

const addMarkers = (
  map,
  id,
  items,
  color,
  renderPopup = null,
  bounds = null,
  draggable = false,
  hasAdminFeatures = false,
  getToolbarConfig = null
) => {
  const sourceId = id;
  const layerId = id;

  // Init/update source
  const geojson = pointItemsToGeojson(items);
  addOrUpdateGeojsonSource(map, sourceId, geojson);

  if (map.getLayer(layerId)) {
    // Update draggable source
    if (draggable) {
      makeLayerDraggable(map, { id });
    }
    return;
  }

  // Extend bounds to fit markers
  if (bounds) {
    geojson.features.forEach(geojsonPoint => {
      bounds.extend(geojsonPoint.geometry.coordinates);
    });
  }

  // Add "colors" (i.e. images) to map
  // Color passed-in could be a single string or an array of strings if multiple images can be used
  // First color is used as the "fallback" color
  let colors = [];
  if (Array.isArray(color)) {
    colors = color;
    // eslint-disable-next-line prefer-destructuring
    color = colors[0];
  } else {
    colors.push(color);
  }
  colors.map(c => addMarkerImagesToMap(map, c));

  // Add layer to map
  const layer = createMarkerLayer(layerId, sourceId, color);
  addLayer(map, layer);

  // Init cursor changes
  addLayerEvent(map, layerId, true, 'mouseenter', () => {
    map.getCanvas().style.cursor = 'pointer';
  });
  addLayerEvent(map, layerId, true, 'mouseleave', () => {
    map.getCanvas().style.cursor = map.customStyle.cursor;
  });

  // Init popups
  if (renderPopup) {
    addHoverPopupToMap(map, layerId, renderPopup);
  }

  // tooltips
  if (hasAdminFeatures) {
    addMarkerTooltipsToMap(map, layerId);
  }

  if (hasAdminFeatures && getToolbarConfig) {
    addMarkerToolbarToMap(map, layerId, getToolbarConfig);
  }

  // Init draggable
  if (draggable) {
    makeLayerDraggable(map, layer);
  }
};

const removeMarkers = (map, id, draggable = false) => {
  if (draggable) {
    undoMakeLayerDraggable(map, id);
  }
  removeLayer(map, id);
  removeSource(map, id);
};

export { addMarkers, removeMarkers };
