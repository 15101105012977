import { lazy, Suspense } from 'react';
import postal from 'postal';
import classNames from 'classnames';
import isEqual from 'underscore/modules/isEqual';
import { useIntl, defineMessage, FormattedMessage } from '@alltrails/shared/react-intl';
import { fetchListAlerts } from '@alltrails/shared/utils/requests/listRequests';
import { CollabListAlerts } from '@alltrails/shared/types/lists';
import CollabListAlertModal from '@alltrails/modules/Lists/CollabListAlertModal';
import ArrowSq from '@alltrails/shared/icons/ArrowSq';
import Link from '@alltrails/shared/denali/components/Link';
import Backpack from '@alltrails/shared/illustrations/Backpack';
import Deer from '@alltrails/shared/illustrations/Deer';
import Bear from '@alltrails/shared/illustrations/Bear';
import Flower from '@alltrails/shared/illustrations/Flower';
import LoadingSpinner from '@alltrails/shared/components/LoadingSpinner';
import compareUserIds from '@alltrails/shared/utils/compareUserIds';
import { get } from '@alltrails/shared/api';
import { CSSTransition } from 'react-transition-group';
import isEmpty from 'underscore/modules/isEmpty';
import { PageStrings } from '@alltrails/shared/utils/constants/pageStringHelpers';
import logExploreCommunityContentItemClicked from '@alltrails/analytics/events/logExploreCommunityContentItemClicked';
import getTrailProps from 'api/Trail';
import FlyoverTransition from 'components/flyover/FlyoverTransition';
import SearchStateProvider from 'components/SearchStateProvider';
import TrailExplore from 'components/TrailExplore';
import { newFiltersReusingSearchState } from 'utils/filters';
import { applyRangeUpdates, applySliderUpdates } from 'utils/filters/filterConfigs';
import { FLYOVER_ANIM_DURATION_MS } from 'utils/flyoverHelpers';
import getUTMAttribution from 'utils/getUTMAttribution';
import { modalRoadblock } from '@alltrails/shared/utils/modalFunnelUtils';
import ErrorFallback from 'components/shared/ErrorFallback';
import NullState from '@alltrails/shared/denali/components/NullState';
import FullscreenSearchResults from 'components/FullscreenSearchResults';
import PortalModal from 'components/shared/PortalModal';
import CustomProvider from 'components/CustomProvider';
import Toast from '@alltrails/shared/denali/components/Toast';
import CheckOutline from '@alltrails/shared/icons/CheckOutline';
import Error from '@alltrails/shared/icons/Error';
import Check from '@alltrails/shared/icons/Check';
import ListNullState from 'components/ListNullState/ListNullState';
import ExploreListHeaderPage from '@alltrails/modules/Lists/ExploreListHeaderPage';
import hasPermission from 'utils/hasPermission';
import Button from '@alltrails/shared/denali/components/Button';
import MapFilled from '@alltrails/shared/icons/MapFilled';
import List from '@alltrails/shared/icons/List';
import ExploreTabBar from '../../../../../components/shared/ExploreTabBar';
import { FullscreenBreadcrumbBar } from './breadcrumb_bar';
import { FullscreenLifelineDetails } from './lifeline_details';
import SearchFilterBar from '../../../../../components/explore/SearchFilterBar';
import { FullscreenSearchMap } from './search_map';
import LegacyFullscreenSearchResultList from './search_result_list';
import TrackPanel from '../../../../../components/Tracks/TrackPanel';
import { LanguageSupportUtil } from '../../../../../utils/language_support_util';
import { ResultCardFunctionsMixin } from '../../mixins/result_card_functions_mixin';
import { ResultCountMixin } from '../../mixins/search_app/result_count_mixin';
import SearchAppAlgoliaMixin from '../../mixins/search_app/search_app_algolia_mixin';
import { SearchFiltersUtil } from '../../../../../utils/search_filters_util';
import { ServerCommunicationUtil } from '../../../../../utils/server_communication_util';
import ShareModal from '../../main_action_bar/share_modal';
import MapCreatorPanel from '../../../../../components/MapCreator/Panel';
import ProgressDialog from '../../../../../components/shared/ProgressDialog';
import { FILTER, MAP_PAN } from '../../../../../utils/constants/SearchAnalyticsConstants';
import { loadVerifiedRoute, saveAtMap, saveNewEndpoint, updateAtMap, copyRoute } from '../../../../../utils/requests/at_map_requests';
import { isMapFaulty, getBoundingBoxFromLatLng } from '../../../../../utils/at_map_helpers';
import { getFormattedTrailUrls } from '../../../../../utils/trail_helpers';
import { copyList, reorderList } from '../../../../../utils/requests/list_requests';
import logError from '../../../../../utils/logError';
import { Page } from '../../../../../components/shared/Page/Page';
import * as styles from './styles/search_app.module.scss';
import { AnonymousMapUsername } from '../../../../../Constants';

const ActivityUploadModal = lazy(() => import('@alltrails/modules/ActivityUpload/ActivityUploadModal'));
const PhotoUploadModal = lazy(() => import('@alltrails/modules/PhotoUpload/PhotoUploadModal'));

const exploreTrailsMessage = defineMessage({ defaultMessage: 'Explore trails' });

// eslint-disable-next-line @typescript-eslint/no-var-requires
const createReactClass = require('create-react-class');

const ET_PHOTO_USER_SLUG = 'alltrails-user';
// Lifeline constants
const LIFELINE_STATUS_SAFE = 'S';

export function isValidLatLng({ lat, lng }) {
  return lng >= -180 && lng <= 180 && lat >= -90 && lat <= 90;
}

// !!!!!!!!!!
// NOTE: We work with algolia results as trails for easiness
// This means that the trail.trail_id is in the algolia result as ID
// access it with result.ID, aka result.ID == trail.trail_id
const BaseFullscreenSearchApp = createReactClass({
  mixins: [ResultCardFunctionsMixin, ResultCountMixin, SearchAppAlgoliaMixin],
  getInitialFilters() {
    let allFilters = this.newSearchFilters();
    // Return early
    if (!this.props.initialFilters) {
      return allFilters;
    }

    if (this.props.initialFilters.query) {
      allFilters = SearchFiltersUtil.setOrToggleFilter(allFilters, 'queryTerm', this.props.initialFilters.query);
    }
    if (this.props.initialFilters.activities) {
      this.props.initialFilters.activities.forEach(filter => {
        allFilters = SearchFiltersUtil.toggleFilter(allFilters, 'activities', filter);
      });
    }
    if (this.props.initialFilters.features) {
      this.props.initialFilters.features.forEach(filter => {
        allFilters = SearchFiltersUtil.toggleFilter(allFilters, 'features', filter);
      });
    }
    if (this.props.initialFilters.access) {
      this.props.initialFilters.access.forEach(filter => {
        allFilters = SearchFiltersUtil.toggleFilter(allFilters, 'access', filter);
      });
    }
    if (this.props.initialFilters.areas) {
      this.props.initialFilters.areas.forEach(areaId => {
        // add "fake" area entry without name (because Algolia data has not been retrieved yet)
        areaId = parseInt(areaId);
        allFilters.areas[areaId] = {
          value: areaId,
          selected: true
        };
      });
    }
    if (this.props.initialFilters.difficulty) {
      this.props.initialFilters.difficulty.forEach(filter => {
        allFilters = SearchFiltersUtil.toggleFilter(allFilters, 'difficulty', filter);
      });
    }
    if (this.props.initialFilters.route_type) {
      this.props.initialFilters.route_type.forEach(filter => {
        allFilters = SearchFiltersUtil.toggleFilter(allFilters, 'route_type', filter);
      });
    }
    if (this.props.initialFilters.visitor_usage) {
      this.props.initialFilters.visitor_usage.forEach(filter => {
        allFilters = SearchFiltersUtil.toggleFilter(allFilters, 'visitor_usage', filter);
      });
    }
    // these values will be supplied in meters, -1 also signifies maximum value
    if (this.props.initialFilters.lengths && this.props.initialFilters.lengths.length === 2) {
      applyRangeUpdates(allFilters.lengths, this.props.initialFilters.lengths);
    }
    // these values will be supplied in meters, -1 also signifies maximum value
    if (this.props.initialFilters.elevation_gain && this.props.initialFilters.elevation_gain.length === 2) {
      applyRangeUpdates(allFilters.elevation_gain, this.props.initialFilters.elevation_gain);
    }
    if (this.props.initialFilters.highest_point && this.props.initialFilters.highest_point.length === 2) {
      applyRangeUpdates(allFilters.highest_point, this.props.initialFilters.highest_point);
    }
    // this value will be supplied in meters
    if ('distance_away' in this.props.initialFilters) {
      applySliderUpdates(allFilters.distance_away, this.props.initialFilters.distance_away);

      // TODO DISCO-1245 Distance Away has special handling we want to unwind.
      this.props.onUpdateDistanceAway(allFilters.distance_away.value);
    }
    if (this.props.initialFilters.trailIds) {
      allFilters = SearchFiltersUtil.setOrToggleFilter(allFilters, 'trailIds', this.props.initialFilters.trailIds);
    }
    if (this.props.initialFilters.trackIds) {
      allFilters = SearchFiltersUtil.setOrToggleFilter(allFilters, 'trackIds', this.props.initialFilters.trackIds);
    }
    if (this.props.initialFilters.mapIds) {
      allFilters = SearchFiltersUtil.setOrToggleFilter(allFilters, 'mapIds', this.props.initialFilters.mapIds);
    }
    if (this.props.initialFilters.minRating) {
      allFilters = SearchFiltersUtil.setOrToggleFilter(allFilters, 'minRating', this.props.initialFilters.minRating);
    }
    if (this.props.initialFilters.boundingBox) {
      const newBounds = this.props.initialFilters.boundingBox;
      allFilters = SearchFiltersUtil.setBoundsFilter(
        allFilters,
        newBounds.latitudeTopLeft,
        newBounds.longitudeTopLeft,
        newBounds.latitudeBottomRight,
        newBounds.longitudeBottomRight
      );
    }
    if (this.props.initialFilters.mobileMap) {
      allFilters = SearchFiltersUtil.setOrToggleFilter(allFilters, 'mobileMap', this.props.initialFilters.mobileMap);
    }
    if (this.props.initialFilters.locationObject) {
      allFilters = SearchFiltersUtil.setOrToggleFilter(allFilters, 'locationObject', this.props.initialFilters.locationObject);
      allFilters = SearchFiltersUtil.setOrToggleFilter(allFilters, 'initLocationObject', this.props.initialFilters.locationObject);
      if (this.props.initialCenter) {
        allFilters.initLocationObject._geoloc = {
          lat: this.props.initialCenter[0],
          lng: this.props.initialCenter[1]
        };
      }
    }
    return allFilters;
  },
  getInitialBackReturnsToExplore() {
    return (
      (this.props.initialPage === PageStrings.EXPLORE_USERS_TRACKS_PAGE || this.props.initialPage === PageStrings.EXPLORE_USERS_MAPS_PAGE) &&
      (!this.props.initialAtMaps || (this.props.initialAtMaps && this.props.initialAtMaps.length === 0))
    );
  },
  getInitialState() {
    this.resultCardFunctions = this.getResultCardFunctions();

    return {
      page: this.props.initialPage || PageStrings.EXPLORE_ALL_PAGE,
      results: this.props.initialPage === PageStrings.EXPLORE_CUSTOM_PAGE ? this.props.listItemsData : this.props.initialResults,
      loading: this.props.initialPage !== PageStrings.EXPLORE_TRAIL_MAP_PAGE,
      trackTrail: this.props.initialTrackTrailResult,
      // Used for client-side sorting through list of atMaps, as opposed to using Algolia
      atMaps: this.props.initialPage === PageStrings.EXPLORE_CUSTOM_PAGE ? this.props.listItemsData : this.props.initialAtMaps,
      selectedObject: this.props.initialSelectedObject,
      lifeline: this.props.initialLifeline,
      exploreMap: this.props.initialExploreMap,
      exploreMapPromise: null,
      routeInfo: null, // in-progress trail planner routeInfo
      filters: this.getInitialFilters(),
      backReturnsToExplore: this.getInitialBackReturnsToExplore(),
      customList: this.props.initialPage === PageStrings.EXPLORE_CUSTOM_PAGE ? this.props.listDetailsData : this.props.initialCustomList,
      titleString: this.props.initialTitleString,
      listCopyString: this.props.intl.formatMessage({ defaultMessage: 'Copy to my lists' }),
      numberViewedTrails: this.props.numberViewedTrails || 0,
      isEditing: false,
      progressDialogState: null,
      displayMetric: this.props.context.displayMetric,
      nearbyTrailsShown: false,
      saveToList: null,
      showShareModal: false,
      shareObject: null,
      activeTrailFormModal: this.props.pendingReview !== null ? 'reviews' : null,
      trailFormProps: this.props.pendingReview,
      downloadObject: null,
      showDownloadRouteModal: false,
      searchTrigger: null,
      trailProps: this.props.trailPageProps,
      hasTrailError: false,
      flyoverViewEnabled: this.props.initFlyoverOnLoad,
      shouldInitializeFlyover: this.props.initFlyoverOnLoad,
      customMaps: [],
      showCollabListAlertModal: false,
      collabListAlertProps: {
        title: '',
        description: '',
        type: ''
      },
      listDetailsData: this.props.listDetailsData
    };
  },
  componentWillMount() {
    this.resultSelected = false;
    const messagingChannelName = 'explore_messagingChannel';
    this.messagingChannel = postal.channel(messagingChannelName);
    // Initialize messagingTunnel to be used between iFrame and explore
    if (typeof window !== 'undefined') {
      window.messagingTunnel = channelName => postal.channel(channelName);
    }

    this.listeners = [
      this.messagingChannel.subscribe('routes.updated', this.updateRouteOrder),
      this.messagingChannel.subscribe('waypointsChanged', this.waypointsChanged),
      this.messagingChannel.subscribe('waypoint.updated', this.updateWaypoint),
      this.messagingChannel.subscribe('map.show', data => {
        this.showMapMobile(data ? data.replace : false);
      }),
      this.messagingChannel.subscribe('map.hide', data => {
        this.hideMapMobile(data ? data.replace : false);
      })
    ];
    this.revGeocodeRequest = null;
  },
  initializeSearchApp() {
    let newFilterState = this.state.filters;

    // For "default" ip address of Pretty Prairie, Kansas, zoom-out of map and show the rest of the United States
    const initLocation = newFilterState.initLocationObject;
    if (initLocation && initLocation.slug === 'us/kansas/pretty-prairie') {
      this.refs.searchMap.setMapZoom(4);
    }

    // If we dont have an initial bounding box query
    // it means we are doing a map center + zoom level set
    // Grab the current map bounds to initialize the query
    // Only do this for expore all page since we want to fit markers for list pages
    if (this.state.filters.boundingBox == null && this.state.filters.queryTerm == null && this.state.page === PageStrings.EXPLORE_ALL_PAGE) {
      if (this.isHomePageTrailRiver()) {
        const boundingBox = getBoundingBoxFromLatLng(
          this.props.locationData?.latLng[0],
          this.props.locationData?.latLng[1],
          this.state.filters?.zoomLevel ? this.state.filters?.zoomLevel : this.props.initialCenter[2]
        );
        newFilterState = SearchFiltersUtil.setBoundsFilter(
          newFilterState,
          boundingBox.latitudeTopLeft,
          boundingBox.longitudeTopLeft,
          boundingBox.latitudeBottomRight,
          boundingBox.longitudeBottomRight
        );
      } else {
        const newBounds = this.refs.searchMap.getMapBounds();
        if (newBounds) {
          newFilterState = SearchFiltersUtil.setBoundsFilter(newFilterState, newBounds[0], newBounds[2], newBounds[1], newBounds[3]);
        }
      }
    }

    // Update filters and isMobile state based on page type
    // Perform search after filtering if necessary
    if (this.state.page === PageStrings.EXPLORE_TRAIL_MAP_PAGE) {
      if (this.state.selectedObject && !this.state.exploreMap && !this.state.exploreMapPromise) {
        this.getSelectedTrailVerifiedMap(this.state.selectedObject.ID);
      }
    } else if (
      [PageStrings.EXPLORE_USERS_TRACKS_MAP_PAGE, PageStrings.EXPLORE_USERS_MAPS_MAP_PAGE, PageStrings.EXPLORE_LIFELINE_PAGE].includes(
        this.state.page
      )
    ) {
      // load lifeline data on component mount instead of before page render
      if (this.state.lifeline && this.state.lifeline.metadata.status === 'A') {
        this.checkLifelineStatusInterval = window.setInterval(() => {
          this.updateLifelineData();
        }, 60000);
      }
      this.setState({ filters: newFilterState });
    } else if ([PageStrings.EXPLORE_USERS_TRACKS_PAGE, PageStrings.EXPLORE_USERS_MAPS_PAGE].includes(this.state.page)) {
      if (!this.state.atMaps) {
        // load these recordings and maps on component mount instead of before page render
        const type = this.state.page === PageStrings.EXPLORE_USERS_MAPS_PAGE ? 'map' : 'track';
        this.getUserMaps(this.props.userInfo.id, type, true, true);
      }
      this.setState({ filters: newFilterState });
    } else if ([PageStrings.EXPLORE_CUSTOM_PAGE, PageStrings.EXPLORE_FAVORITE_PAGE].includes(this.state.page)) {
      if (this.props.listId && this.props.userListItems) {
        if (this.state.page === PageStrings.EXPLORE_FAVORITE_PAGE) {
          // get custom maps so they can be combined with algolia trail results for "My favorites" list
          const hasMapsInTheList = Object.keys(this.props.userListItems[this.props.listId])?.includes('map');
          const hasTracksInTheList = Object.keys(this.props.userListItems[this.props.listId])?.includes('track');
          if (hasMapsInTheList || hasTracksInTheList) {
            // eslint-disable-next-line no-unsafe-optional-chaining
            const ids = [...this.state.filters?.mapIds, ...this.state.filters?.trackIds];
            this.customUserMaps(ids, newFilterState);
          } else {
            this.setState({ filters: newFilterState }, () => {
              this.replaceHistoryState();
              this.performAppSearch();
            });
          }
        } else {
          this.setState({ filters: newFilterState }, () => {
            this.replaceHistoryState();
            this.performAppSearch();
          });
        }
      }
    } else {
      this.setState(
        {
          filters: newFilterState,
          // if we are in one of the explore list views, and there are no items in the current list, turn on Nearby Trails overlay
          nearbyTrailsShown: !!this.props.listId && !this.props.listMethods.hasItemsInList(this.props.listId, this.props.userListItems)
        },
        () => {
          this.replaceHistoryState();
          this.performAppSearch();
        }
      );
    }
  },
  getListAlerts(listId) {
    fetchListAlerts(listId)
      .then(result => {
        if (
          [
            CollabListAlerts.Transferred,
            CollabListAlerts.Joined,
            CollabListAlerts.FullList,
            CollabListAlerts.Removed,
            CollabListAlerts.ExpiredInvitation
          ].includes(result.type)
        ) {
          this.setState({
            collabListAlertProps: {
              ...this.state.collabListAlertProps,
              title: result.title,
              description: result.description,
              type: result.type
            }
          });
          this.setState({ showCollabListAlertModal: true });
        }
      })
      .catch(e => {
        logError(e);
      });
  },
  isListPage() {
    return this.state.page === PageStrings.EXPLORE_CUSTOM_PAGE;
  },
  componentDidMount() {
    // If user is not logged in and is editing a recently created map, take them to Signup
    if (
      window &&
      !this.props.isAnonUsersNewMap &&
      this.props.initialPage === PageStrings.EXPLORE_USERS_MAPS_MAP_PAGE &&
      !this.props.user &&
      this.props.initialSelectedObject &&
      this.props.initialSelectedObject.slug &&
      this.props.userInfo &&
      this.props.userInfo.slug === AnonymousMapUsername
    ) {
      const url = LanguageSupportUtil.wrapUrlSafe(`/explore/map/${this.props.initialSelectedObject.slug}`, this.props.context.languageRegionCode);
      window.history.pushState(null, '', url);
      modalRoadblock('signup', 'explore-map-new', url, this.props.context.languageRegionCode);
      return;
    }

    this.initAlgolia();

    window.addEventListener('popstate', this.handlePopState);

    this.setupReviewModalListeners();

    if (this.props.isMapReady) {
      this.initializeSearchApp();
    }

    // fetch a list alerts only for the authenticated users
    if (this.props.listId && this.state.page === PageStrings.EXPLORE_CUSTOM_PAGE && !!this.props.context.currentUser) {
      this.getListAlerts(this.props.listId);
    }
  },
  componentDidUpdate(prevProps, prevState) {
    const listPagePropsChanged = !isEqual(this.props.listItemsData, prevProps.listItemsData) && this.isListPage();
    if ((!prevProps.isMapReady && this.props.isMapReady) || listPagePropsChanged) {
      if (listPagePropsChanged) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ results: this.props.listItemsData, atMaps: this.props.listItemsData }, this.initializeSearchApp());
      } else {
        this.initializeSearchApp();
      }
      // Return. The initialization sets a lot of different state vars. Let
      // componentDidUpdate naturally get invoked following any initialization.
      return;
    }

    if (prevState.page !== this.state.page) {
      this.props.updatePage(this.state.page);

      const newDistanceAwayValue = this.state.page === PageStrings.EXPLORE_ALL_PAGE ? this.state.filters.distance_away.value : -1;
      this.props.onUpdateDistanceAway(newDistanceAwayValue);

      this.updateAlgoliaIndex();
    }

    if ((prevProps.isLoadingIsochrone && !this.props.isLoadingIsochrone) || prevProps.insidePolygon !== this.props.insidePolygon) {
      // Kick off a new search if the polygon changed. This covers cases where
      // the isochrone may have been cleared or the distance away radius
      // explicitly set to 0 by a user.
      this.performAppSearch();

      if (this.props.isochroneBoundingBox) {
        this.refs.searchMap.setMapBounds({
          latitudeTopLeft: this.props.isochroneBoundingBox[3],
          longitudeTopLeft: this.props.isochroneBoundingBox[2],
          latitudeBottomRight: this.props.isochroneBoundingBox[1],
          longitudeBottomRight: this.props.isochroneBoundingBox[0]
        });
      }
    }

    /**
     *
     * This works with the result_card_mixin to update the filters when
     * a item is added or removed.
     *
     */
    if (this.props.listId && prevProps.latestListAction !== this.props.latestListAction && this.props.belongsToCurrentUser) {
      this.updateResultsFromListHash(this.props.latestListAction, this.props.userListItems, this.state.customMaps, maps =>
        this.setState({ customMaps: maps })
      );
    }

    /**
     *
     * Since the trail page is rendered out of an iframe we need to communicate with the TrailPage
     * when a list or listItems have changed.
     *
     */
    if (
      this.state.page === PageStrings.EXPLORE_TRAIL_MAP_PAGE &&
      (prevProps.listItems !== this.props.listItems || prevProps.lists !== this.props.lists)
    ) {
      this.messagingChannel.publish('lists.changed', { lists: this.props.lists, listItems: this.props.listItems });
    }

    if (this.props.toastProps !== prevProps.toastProps && this.props.toastProps) {
      setTimeout(() => {
        this.props.setToastProps(null);
      }, 3000);
    }

    if (this.props.reportingSuccessToast !== prevProps.reportingSuccessToast && this.props.reportingSuccessToast) {
      setTimeout(() => {
        this.props.setReportingSuccessToast(null);
      }, 3000);
    }
  },
  componentWillUnmount() {
    window.removeEventListener('popstate', this.handlePopState);

    this.listeners.forEach(sub => {
      postal.unsubscribe(sub);
    });
  },
  setupReviewModalListeners() {
    window.document.addEventListener('openTrailFormModal', data =>
      this.openTrailFormModal(data.detail.activeTrailFormModal, data.detail.trailFormProps)
    );
  },
  blockNonUserCreation(type) {
    const returnToUrl = window.location.pathname + window.location.search;
    modalRoadblock('signup', type, returnToUrl, this.props.context.languageRegionCode);
  },
  handleMapSaveSuccess(map) {
    this.props.setMapId?.(map.id);
    this.updateExploreMap(map);
    // eslint-disable-next-line no-undef
    $('#flash-msg').slideUp();
    this.updateProgressDialog('saved');
    // Bring user to signup page after saving
    if (!this.props.user) {
      this.blockNonUserCreation('explore-map-new');
    }

    return map;
  },
  handleMapSaveError(err) {
    this.updateProgressDialog('error-saving');
    logError(err);
    return err;
  },
  renderProgressDialog() {
    if (!this.state.progressDialogState) {
      return null;
    }

    return (
      <PortalModal isOpen={this.state.progressDialogState}>
        <ProgressDialog variant={this.state.progressDialogState} updateProgressDialog={this.updateProgressDialog} />
      </PortalModal>
    );
  },
  updateProgressDialog(progressDialogState) {
    this.setState({ progressDialogState });
  },
  saveMapChanges({ descriptionChangeset, waypoints = null }) {
    this.updateProgressDialog('saving');

    const panelData = {
      description: descriptionChangeset.description,
      name: descriptionChangeset.title,
      contentPrivacy: descriptionChangeset.contentPrivacy,
      createdAt: descriptionChangeset.date,
      activity: { uid: descriptionChangeset.activity.value }
    };

    if (waypoints) {
      Object.assign(panelData, { waypoints });
    }

    let mapData;
    // planner map only exists if a route has been drawn
    const plannerMap = this.state.routeInfo ? this.state.routeInfo.plannerMap : null;
    if (plannerMap && plannerMap.routes) {
      mapData = { ...plannerMap, ...panelData };
    } else {
      // if plannerMap is not there, we still need to save the bounds
      // TODO: using onMapBoundsChanged, save `bounds`, `center`, `zoom`, etc. to SearchApp state
      // then use this for everything else (filters, etc.)
      // and reduce usage of this.refs.searchMap
      const [latitudeTopLeft, latitudeBottomRight, longitudeTopLeft, longitudeBottomRight] = this.refs.searchMap.getMapBounds();
      const bounds = { latitudeTopLeft, longitudeTopLeft, latitudeBottomRight, longitudeBottomRight };
      mapData = { bounds, presentationType: 'M', ...panelData };
    }

    if (isMapFaulty(mapData)) {
      alert(this.props.intl.formatMessage({ defaultMessage: 'Please finish drawing route (or cancel) before attempting to save.' }));
      this.updateProgressDialog('error-saving');
      return Promise.reject(new Error(`Map is faulty - ${JSON.toString(mapData)}`));
    }

    // Update Existing
    if (this.state.exploreMap.id) {
      mapData = { ...mapData, id: this.state.exploreMap.id };
      return updateAtMap(mapData).then(this.handleMapSaveSuccess).catch(this.handleMapSaveError);
    }

    // Save New
    return saveAtMap(mapData).then(this.handleMapSaveSuccess).catch(this.handleMapSaveError);
  },
  saveEditEndpointChanges({ segmentId, cropIndex, newEndPoint }) {
    this.updateProgressDialog('saving');
    return saveNewEndpoint(this.state.exploreMap.id, segmentId, cropIndex, newEndPoint)
      .then(map => {
        this.setState({ exploreMap: map });
      })
      .catch(e => {
        this.updateProgressDialog('error-saving');
        return e;
      })
      .finally(() => {
        this.updateProgressDialog(null);
      });
  },
  handleRouteInfoChanged(routeInfoChanges) {
    if (!routeInfoChanges) {
      this.setState({ routeInfo: null });
      return;
    }
    let { routeInfo } = this.state;
    routeInfo = routeInfo ? { ...routeInfo, ...routeInfoChanges } : routeInfoChanges;
    this.setState({ routeInfo });
  },
  updateRouteOrder(data) {
    this.setState({ exploreMap: { ...this.state.exploreMap, routes: data } });
  },
  updateExploreMap(map) {
    const previousId = this.state.exploreMap.id || null;
    this.setState({ exploreMap: { ...this.state.exploreMap, ...map, routes: map.routes, waypoints: map.waypoints }, selectedObject: map }, () => {
      if (!previousId && this.state.exploreMap.id) {
        const url = LanguageSupportUtil.wrapUrlSafe(`/explore/map/${this.state.exploreMap.slug}`, this.props.context.languageRegionCode);
        window.history.pushState(
          { page: PageStrings.EXPLORE_USERS_MAPS_MAP_PAGE, selectedObjectId: this.state.exploreMap.ID, filters: null },
          `Explore ${this.state.exploreMap.name} | AllTrails`,
          url
        );
      }
    });
  },
  waypointsChanged(data) {
    let { waypoints } = data;
    const { editWaypointId, activeWaypoint } = data;

    // if a waypoint is new and doesn't have a lat / lng, we want to coordinate it with the map
    if (typeof activeWaypoint !== 'undefined' && activeWaypoint.location.longitude === null && activeWaypoint.location.latitude === null) {
      const [latitude, longitude] = this.refs.searchMap.getMapCenter();
      waypoints = waypoints.map(wp => {
        if (wp.id === null) {
          return { ...wp, location: { longitude, latitude } };
        }

        return wp;
      });
      this.messagingChannel.publish('waypoint.dragged.with_location', { waypointId: activeWaypoint.id, location: { latitude, longitude } });
    }

    this.setState({ exploreMap: { ...this.state.exploreMap, waypoints, editWaypointId } });
  },
  newSearchFilters() {
    const page = this.state && this.state.page ? this.state.page : this.props.initialPage;
    return SearchFiltersUtil.getSearchFilters(this.props.context, page, this.props.intl, this.props.initialUserSlug);
  },
  showMapMobile(replace) {
    // scroll to top of page
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    const { filters } = this.state;
    if (!filters.mobileMap) {
      filters.mobileMap = true;
      this.setState({ filters }, replace ? this.replaceHistoryState : this.pushHistoryStateAndSearch);
    }
  },
  hideMapMobile() {
    // scroll to top of page
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    const { filters } = this.state;
    if (filters.mobileMap) {
      filters.mobileMap = false;
      this.setState({ filters }, this.pushHistoryStateAndSearch);
    }
  },
  publishShowHideMap(mobileMap, replace) {
    if (this.props.isMobileWidth && mobileMap) {
      this.messagingChannel.publish('map.show', { replace });
    } else {
      this.messagingChannel.publish('map.hide', { replace });
    }
  },
  logWebPageViewedEvent(page) {
    if (
      [
        PageStrings.EXPLORE_ALL_PAGE,
        PageStrings.EXPLORE_FAVORITE_PAGE,
        PageStrings.EXPLORE_CUSTOM_PAGE,
        PageStrings.EXPLORE_COMPLETED_PAGE,
        PageStrings.EXPLORE_TRAIL_MAP_PAGE
      ].includes(page)
    ) {
      if (window.amplitude) {
        window.amplitude.track('web page viewed', JSON.parse(JSON.stringify(window.location)));
      }
    }
  },
  handlePopState(event) {
    // If the user remained on the trail page, popstate was called when exiting the flyover
    if (!event.state || (this.state.page === PageStrings.EXPLORE_TRAIL_MAP_PAGE && event.state.page === PageStrings.EXPLORE_TRAIL_MAP_PAGE)) {
      return;
    }

    let newFilters = event.state.filters;
    const newPage = event.state.page;

    if (!newPage) {
      return;
    }

    // send an Amplitude event 'web page viewed' when the browser's back button is clicked
    this.logWebPageViewedEvent(newPage);

    if (newPage === PageStrings.EXPLORE_ALL_PAGE && this.refs.searchMap) {
      this.messagingChannel.publish({
        topic: 'edit.disable_trail_planner'
      });
    }

    if (newFilters && this.state.filters && newFilters.mobileMap !== this.state.filters.mobileMap) {
      this.publishShowHideMap(newFilters.mobileMap, false);
    }

    if (newFilters && newFilters?.boundingBox?.latitudeTopLeft && !this.props.isMobileWidth && this.refs.searchMap) {
      this.refs.searchMap.setMapBounds(newFilters.boundingBox);
    }

    if (!newFilters) {
      newFilters = this.newSearchFilters();
    }

    if ([PageStrings.EXPLORE_CUSTOM_PAGE, PageStrings.EXPLORE_COMPLETED_PAGE, PageStrings.EXPLORE_FAVORITE_PAGE].includes(newPage)) {
      newFilters = SearchFiltersUtil.setOrToggleFilter(newFilters, 'trailIds', this.state.filters.trailIds);
      newFilters = SearchFiltersUtil.setOrToggleFilter(newFilters, 'trackIds', this.state.filters.trackIds);
      newFilters = SearchFiltersUtil.setOrToggleFilter(newFilters, 'mapIds', this.state.filters.mapIds);
      this.setState(
        { page: newPage, filters: newFilters, trackTrail: null, exploreMap: null, results: [], selectedObject: null },
        this.performAppSearch
      );
    } else if (newPage === PageStrings.EXPLORE_TRAIL_MAP_PAGE) {
      // assure fetches happens before page is updated so we render the loading state instead of an unitialized page
      this.fetchTrailProps({ ID: event.state.selectedObjectId });
      this.getAlgoliaTrail(event.state.selectedObjectId);
      this.getSelectedTrailVerifiedMap(event.state.selectedObjectId);
      this.setState({ page: newPage, results: [] });
    } else if ([PageStrings.EXPLORE_USERS_TRACKS_PAGE, PageStrings.EXPLORE_USERS_MAPS_PAGE].includes(newPage)) {
      const type = newPage === PageStrings.EXPLORE_USERS_MAPS_PAGE ? 'map' : 'track';
      this.getUserMaps(this.state.exploreMap.user.id, type, true, false);
      this.setState(
        { page: newPage, filters: newFilters, trackTrail: null, selectedObject: null, results: [], exploreMap: null },
        this.performAppSearch
      );
    } else if (
      [PageStrings.EXPLORE_USERS_TRACKS_MAP_PAGE, PageStrings.EXPLORE_USERS_MAPS_MAP_PAGE, PageStrings.EXPLORE_LIFELINE_PAGE].includes(newPage)
    ) {
      const { atMaps } = this.state;
      if (atMaps) {
        // eslint-disable-next-line eqeqeq
        const selectedObject = atMaps.filter(atMap => atMap.ID == event.state.selectedObjectId)[0];
        this.setState({ page: newPage, selectedObject, results: [], filters: newFilters });
        this.getSelectedTrackDetails(event.state.selectedObjectId);
      } else if (event.state.selectedObjectId === this.props.initialSelectedObject?.ID) {
        this.setState({ page: newPage, selectedObject: this.props.initialSelectedObject, results: [], filters: newFilters });
        this.getSelectedTrackDetails(event.state.selectedObjectId);
      } else {
        window.location.reload();
      }
    } else {
      this.setState({ filters: newFilters, page: newPage, exploreMap: null, selectedObject: null }, this.performAppSearch);
    }
  },
  updateLifelineData() {
    ServerCommunicationUtil.getApiEndpoint(
      `/api/alltrails/lifeline/sessions/${this.state.lifeline.id}`,
      { detail: 'web' },
      data => {
        if (data && data.sessions) {
          const session = data.sessions[0];
          if (session.metadata.status === LIFELINE_STATUS_SAFE) {
            window.clearInterval(this.checkLifelineStatusInterval);
          }
          // maintain the zoom and bounds that the user is currently using
          let allFilters = this.newSearchFilters();
          const currentBounds = this.refs.searchMap.getMapBounds();
          allFilters = SearchFiltersUtil.setBoundsFilter(allFilters, currentBounds[0], currentBounds[2], currentBounds[1], currentBounds[3]);
          allFilters = SearchFiltersUtil.setOrToggleFilter(allFilters, 'zoomLevel', this.refs.searchMap.getMapZoom());
          this.setState({ lifeline: session, filters: allFilters });
        }
      },
      err => {
        console.log(err);
      },
      () => {}
    );
  },
  handleFilterToggle(filterType, filterToToggle) {
    this.handleFilterChange(SearchFiltersUtil.toggleFilter, filterType, filterToToggle);
  },
  handleFilterRadioToggle(filterType, filterToToggle) {
    const newFilterState = SearchFiltersUtil.toggleRadioFilter(this.state.filters, filterType, filterToToggle);
    this.setState({ filters: newFilterState }, this.replaceHistoryStateAndSearch);
  },
  clearRadioFilter(filterType, page) {
    const newFilterState = SearchFiltersUtil.clearRadioFilter(this.state.filters, filterType, page, this.props.intl);
    this.setState({ filters: newFilterState }, this.replaceHistoryStateAndSearch);
  },
  handleFilterRange(filterType, sliderValues) {
    if (filterType === 'distance_away') {
      // TODO DISCO-1245 Distance Away has special handling we want to unwind.
      this.props.onUpdateDistanceAway(sliderValues.convertedRange);
    }

    this.handleFilterChange(SearchFiltersUtil.sliderFilter, filterType, sliderValues);
  },
  handleFilterSet(filterType, value) {
    this.handleFilterChange(SearchFiltersUtil.setFilter, filterType, value);
  },
  handleFilterChange(updateFunc, filterType, newValue) {
    const newFilterState = updateFunc(this.state.filters, filterType, newValue);
    this.setState({ filters: newFilterState, searchTrigger: FILTER }, this.replaceHistoryStateAndSearch);
  },
  handleQueryTermSet(value) {
    // When we query on a term we dont want a bounding box, so set it to null
    let newFilterState = SearchFiltersUtil.setOrToggleFilter(this.state.filters, 'queryTerm', value);
    newFilterState = SearchFiltersUtil.setOrToggleFilter(newFilterState, 'locationObject', null);
    newFilterState = SearchFiltersUtil.setOrToggleFilter(newFilterState, 'boundingBox', null);
    this.setState({ filters: newFilterState }, this.replaceHistoryStateAndSearch);
  },
  handleReverseGeocode(lat, lng) {
    const revGeocodeUrl = '/api/alltrails/locations/reverse_geocode';
    const successFunc = data => {
      this.revGeocodeRequest = null;
      let { titleString } = this.state;
      if (data && data.name) {
        titleString = this.props.intl.formatMessage({ defaultMessage: 'Map of trails near {place}' }, { place: data.name });
      }
      const newFilterState = SearchFiltersUtil.setOrToggleFilter(this.state.filters, 'locationObject', data === 'null' ? null : data);
      this.setState({ filters: newFilterState, titleString }, this.replaceHistoryState);
    };
    const errorFunc = () => {
      let newFilterState = this.state.filters;
      newFilterState = SearchFiltersUtil.setOrToggleFilter(newFilterState, 'locationObject', null);
      this.setState({ filters: newFilterState });
    };
    const completeFunc = () => {};
    if (this.revGeocodeRequest) {
      this.revGeocodeRequest.abort();
      this.revGeocodeRequest = null;
    }
    this.revGeocodeRequest = ServerCommunicationUtil.postApiEndpoint(
      revGeocodeUrl,
      { latitude: lat, longitude: lng },
      successFunc,
      errorFunc,
      completeFunc,
      { 'X-Language-Locale': this.props.context.languageRegionCode }
    );
  },
  clearAllFilters() {
    const { page, results, filters } = newFiltersReusingSearchState(this.state.atMaps, this.state.filters, this.state.results, {
      context: this.props.context,
      initialUserSlug: this.props.initialUserSlug,
      page: this.state.page,
      intl: this.props.intl
    });

    const state = {
      filters,
      searchTrigger: FILTER
    };

    if (page !== undefined) {
      state.page = page;
    }

    if (results !== undefined) {
      state.results = results;
    }

    // TODO DISCO-1245 Distance Away has special handling we want to unwind.
    this.props.onUpdateDistanceAway(-1);

    this.setState(state, this.replaceHistoryStateAndSearch);
  },
  onMapBoundsChanged(newCenter, newBounds, newZoomLevel) {
    // If the map moved as a result of a search result being selected, do nothing.
    // Otherwise, clear the value shown in the nav bar search box
    if (this.resultSelected) {
      this.resultSelected = false;
    }

    // Clear the algolia cache to help with memory limits
    if (this.algoliaIndex) {
      this.algoliaIndex.clearCache();
    }
    // If we're in trail details, dont do anything for mapmoves
    if (
      this.state.page === PageStrings.EXPLORE_TRAIL_MAP_PAGE ||
      this.state.page === PageStrings.EXPLORE_USERS_TRACKS_MAP_PAGE ||
      this.state.page === PageStrings.EXPLORE_USERS_MAPS_MAP_PAGE ||
      this.state.page === PageStrings.EXPLORE_LIFELINE_PAGE ||
      this.state.isEditing
    ) {
      return;
    }
    if (this.state.page === PageStrings.EXPLORE_ALL_PAGE) {
      if (this.geocodeTimeout) {
        clearInterval(this.geocodeTimeout);
      }
      this.geocodeTimeout = setTimeout(() => {
        this.handleReverseGeocode(newCenter[0], newCenter[1]);
      }, 1000);
    }
    let newFilterState = SearchFiltersUtil.setBoundsFilter(this.state.filters, newBounds[0], newBounds[2], newBounds[1], newBounds[3]);
    newFilterState = SearchFiltersUtil.setOrToggleFilter(newFilterState, 'locationObject', null);
    newFilterState = SearchFiltersUtil.setZoomLevelFilter(newFilterState, newZoomLevel);
    // On initial load, call pushHistoryState to ensure that "back buttons" will give expected behavior after clicking into a trail
    // Update filters without calling setState to prevent unnecessary render before search results are retrieved
    this.state.filters = newFilterState;
    this.state.searchTrigger = MAP_PAN;
    this.replaceHistoryStateAndSearch();
  },
  replaceHistoryState() {
    const titleString =
      this.state.titleString === '' && this.props.initialHTMLTitleString ? this.props.initialHTMLTitleString : this.state.titleString;
    const params = new URLSearchParams(window.location.search);
    const utmAttribution = getUTMAttribution(params);
    if (this.state.selectedObject) {
      window.history.replaceState(
        { filters: this.state.filters, page: this.state.page, selectedObjectId: this.state.selectedObject.ID },
        `${titleString} | AllTrails`,
        SearchFiltersUtil.createUrlFromPageAndFilters(
          this.state.page,
          this.state.filters,
          this.props.context.languageRegionCode,
          this.state.selectedObject,
          utmAttribution
        )
      );
    } else {
      window.history.replaceState(
        { filters: this.state.filters, page: this.state.page },
        `${titleString} | AllTrails`,
        SearchFiltersUtil.createUrlFromPageAndFilters(
          this.state.page,
          this.state.filters,
          this.props.context.languageRegionCode,
          this.state.selectedObject,
          utmAttribution
        )
      );
    }
  },
  replaceHistoryStateAndSearch() {
    this.replaceHistoryState();
    this.performAppSearch();
  },
  pushHistoryStateAndSearch() {
    const titleString =
      this.state.titleString === '' && this.props.initialHTMLTitleString ? this.props.initialHTMLTitleString : this.state.titleString;
    if (this.state.selectedObject) {
      window.history.pushState(
        { filters: this.state.filters, page: this.state.page, selectedObjectId: this.state.selectedObject.ID },
        `${titleString} | AllTrails`,
        SearchFiltersUtil.createUrlFromPageAndFilters(
          this.state.page,
          this.state.filters,
          this.props.context.languageRegionCode,
          this.state.selectedObject
        )
      );
    } else {
      window.history.pushState(
        { filters: this.state.filters, page: this.state.page },
        `${titleString} | AllTrails`,
        SearchFiltersUtil.createUrlFromPageAndFilters(
          this.state.page,
          this.state.filters,
          this.props.context.languageRegionCode,
          this.state.selectedObject
        )
      );
    }
    this.performAppSearch();
  },
  toggleNearbyTrailsOverlay() {
    this.setState({ nearbyTrailsShown: !this.state.nearbyTrailsShown });

    if (!this.props.isNewMapsPage) {
      this.refs.searchMap.toggleOverlay('nearbyTrails');
    }
  },
  getSelectedTrailVerifiedMap(trailId) {
    this.setState({
      exploreMapPromise: loadVerifiedRoute(trailId)
        .then(exploreMap => {
          this.setState({ exploreMap, exploreMapPromise: null });
        })
        .catch(e => {
          this.setState({ exploreMapPromise: null });
          logError(e);
          // If it is discovered that the trail has been replaced by another (such as through a merge),
          // then completely refresh the page
          if (e.responseText?.includes(`${trailId} replaced by`)) {
            window.location.reload();
          }
        })
    });
  },
  goToTrailPage(trail) {
    const { localizedUrl } = getFormattedTrailUrls(trail, this.props.context.languageRegionCode);
    window.location.href = localizedUrl;
  },
  goToTrailPageInNewTab(trail) {
    const { localizedUrl } = getFormattedTrailUrls(trail, this.props.context.languageRegionCode);
    window.open(localizedUrl, '_blank');
  },
  fetchTrailProps(trail) {
    this.setState({ hasTrailError: false, loading: true });
    getTrailProps(trail.ID, true /* exploreFlag */)
      .then(data => {
        this.setState({ trailProps: data, loading: false });
        // send an Amplitude event 'web page viewed' when the trail page is loaded
        this.logWebPageViewedEvent(this.state.page);
      })
      .catch(e => {
        logError(e);
        this.setState({ hasTrailError: true, loading: false });
      });
  },
  handleTrailClick(trail) {
    // eslint-disable-next-line eqeqeq
    if (this.state.selectedObject && this.state.page === PageStrings.EXPLORE_TRAIL_MAP_PAGE && trail.ID == this.state.selectedObject.ID) {
      return;
    }

    if (this.props.context.mobileBrowser || this.props.isMobileWidth) {
      this.goToTrailPage(trail);
      return;
    }
    if (this.props.user) {
      this.goToTrailPageInNewTab(trail);
    } else {
      window.open(this.formatTrailUrl(trail), '_blank');
      return;
    }
    this.messagingChannel.publish('edit.disable_trail_planner');
  },
  // formats trail url for trail result cards and hover cards
  formatTrailUrl(trail) {
    return getFormattedTrailUrls(trail, this.props.context.languageRegionCode).localizedUrl;
  },
  // eslint-disable-next-line react/no-unused-class-component-methods
  setBackReturnsToExplore(backReturnsToExplore) {
    this.setState({ backReturnsToExplore });
  },
  getSelectedTrackDetails(trackId) {
    // eslint-disable-next-line no-undef
    $.ajax({
      url: `/api/alltrails/maps/${trackId}?detail=deep&combine_segments=false`,
      headers: {
        'X-AT-KEY': ServerCommunicationUtil.apiKey,
        'X-Language-Locale': this.props.context.languageRegionCode
      },
      success: data => {
        const exploreMap = data.maps[0];
        if (exploreMap.trailId) {
          this.getAlgoliaTrail(exploreMap.trailId);
        }
        if (exploreMap) {
          this.setState({ exploreMap: { ID: trackId, ...exploreMap } });
        }
      }
    });
  },
  setMaps(maps, revertBounds, performSearchAfterLoad) {
    // remove any maps that came back malformed
    const formattedMaps = maps.filter(map => !!map._geoloc && isValidLatLng({ lat: map._geoloc.lat, lng: map._geoloc.lng }));
    const newState = { atMaps: formattedMaps, results: formattedMaps, loading: false };
    let afterLoad;
    if (performSearchAfterLoad) {
      afterLoad = this.replaceHistoryStateAndSearch;
      // we may want to reset the bounds here to include all the pins
      if (revertBounds) {
        const { filters } = this.state;
        filters.boundingBox = null;
        newState.filters = filters;
      }
    }
    this.setState(newState, afterLoad);
  },
  setCustomUserMaps(maps, filtersState) {
    const newState = { atMaps: maps, results: maps, loading: false };
    newState.customMaps = maps;
    newState.filters = filtersState;
    newState.nearbyTrailsShown = !!this.props.listId && !this.props.listMethods.hasItemsInList(this.props.listId, this.props.userListItems);
    this.setState(newState, () => {
      this.replaceHistoryState();
      this.performAppSearch();
    });
  },
  formatCustomUserMaps(maps, filtersState) {
    // removing malformed maps
    const formattedMaps = maps?.filter(map => !!map._geoloc);

    if (hasPermission({ permission: 'trails:manage' }) || this.props.context?.currentUser?.id === this.props.userInfo?.id) {
      this.setCustomUserMaps(formattedMaps, filtersState);
    } else {
      // filter out private maps
      const nonPrivateMaps = formattedMaps.filter(map => !map.private);
      this.setCustomUserMaps(nonPrivateMaps, filtersState);
    }
  },
  customUserMaps(ids, filtersState) {
    const params = {
      ids,
      detail: 'algolia'
    };
    ServerCommunicationUtil.postApiEndpoint(
      `/api/alltrails/maps/download`,
      params,
      data => {
        this.formatCustomUserMaps(data?.maps, filtersState);
      },
      () => {
        alert('an error occurred, please contact support@alltrails.com if it persists.');
      },
      () => {}
    );
  },
  // this method gets all of a users maps recursively using cursor pagination
  fetchAllMaps(url, params, performSearchAfterLoad, revertBounds, allMaps = []) {
    get(url, { params })
      .then(data => {
        if (data.pageInfo && data.pageInfo.hasNextPage) {
          const newParams = { ...params, after: data.pageInfo.nextCursor };
          this.fetchAllMaps(url, newParams, performSearchAfterLoad, revertBounds, allMaps.concat(data.maps));
          return;
        }

        this.setMaps(allMaps.concat(data.maps), revertBounds, performSearchAfterLoad);
      })
      .catch(e => {
        logError(e);
        alert('an error occurred, please contact support@alltrails.com if it persists.');
      });
  },
  getUserMaps(userId, presentationType, performSearchAfterLoad, revertBounds) {
    if (presentationType !== 'map' && presentationType !== 'track') return;

    const params = { detail: 'algolia', presentation_type: presentationType, limit: 100 };
    // eslint-disable-next-line eqeqeq
    if ((this.props.user && userId == this.props.user.id) || hasPermission({ permission: 'trails:manage' })) {
      params.allow_private = true;
    }

    this.fetchAllMaps(`/api/alltrails/users/${userId}/maps`, params, performSearchAfterLoad, revertBounds);
  },
  handleTrackClick(track, idx) {
    // eslint-disable-next-line eqeqeq
    if (this.state.selectedObject && this.state.exploreMap?.id == track.ID) {
      return;
    }
    if (this.state.page === PageStrings.EXPLORE_COMMUNITY_CONTENT_PAGE) {
      logExploreCommunityContentItemClicked({ item_type: track.type, item_id: track.ID, item_position: idx });
    }
    this.getSelectedTrackDetails(track.ID);

    let url = `/explore/recording/${track.slug}`;
    // Append the user's units if they differ from the object's own native units
    if (this.props.user && track.units) {
      if (this.props.user.metric && track.units !== 'm') url += '?u=m';
      else if (!this.props.user.metric && track.units !== 'i') url += '?u=i';
    }
    url = LanguageSupportUtil.wrapUrlSafe(url, this.props.context.languageRegionCode);
    window.history.pushState(
      { page: PageStrings.EXPLORE_USERS_TRACKS_MAP_PAGE, selectedObjectId: track.ID, filters: null },
      `Explore ${track.name} | AllTrails`,
      url
    );
    this.setState({ page: PageStrings.EXPLORE_USERS_TRACKS_MAP_PAGE, selectedObject: track });
  },
  handleMapClick(map) {
    // eslint-disable-next-line eqeqeq
    if (this.state.selectedObject && this.state.exploreMap.id == map.ID) {
      return;
    }
    this.getSelectedTrackDetails(map.ID);
    const url = LanguageSupportUtil.wrapUrlSafe(`/explore/map/${map.slug}`, this.props.context.languageRegionCode);
    window.history.pushState(
      { page: PageStrings.EXPLORE_USERS_MAPS_MAP_PAGE, selectedObjectId: map.ID, filters: null },
      `Explore ${map.name} | AllTrails`,
      url
    );
    this.setState({ page: PageStrings.EXPLORE_USERS_MAPS_MAP_PAGE, selectedObject: map });
  },
  addWaypoint(waypoint) {
    const { exploreMap } = this.state;
    const waypoints = exploreMap.waypoints.slice();
    waypoints.push(waypoint);
    this.setState({ exploreMap: { ...exploreMap, waypoints, editWaypointId: null } });
  },
  removeWaypoint(waypoint) {
    const { exploreMap } = this.state;
    const waypoints = exploreMap.waypoints.filter(wp => wp.id !== waypoint.id);
    this.setState({ exploreMap: { ...exploreMap, waypoints, editWaypointId: null } });
  },
  updateWaypoint(waypoint) {
    const { exploreMap } = this.state;
    const waypoints = exploreMap.waypoints.map(wp => (waypoint.id === wp.id ? waypoint : wp));
    this.setState({ exploreMap: { ...exploreMap, waypoints, editWaypointId: null } });
  },
  handleMapUpdate(map) {
    this.getSelectedTrackDetails(map.id);
  },
  handleMapCreate(map) {
    if (
      (this.props.user &&
        (this.state.page === PageStrings.EXPLORE_USERS_MAPS_PAGE || this.state.page === PageStrings.EXPLORE_USERS_TRACKS_PAGE) &&
        map.user &&
        map.user.slug !== this.props.user.slug) ||
      // eslint-disable-next-line eqeqeq
      (this.state.exploreMap && this.state.exploreMap.user && this.props.user && this.state.exploreMap.user.id != this.props.user.id)
    ) {
      window.location = LanguageSupportUtil.wrapUrlSafe(`/explore/map/${map.slug}`, this.props.context.languageRegionCode);
      return;
    }
    map.ID = map.id;
    map.type = 'map';
    // this.getSelectedTrackDetails(map.ID);
    if (map.trailId) this.getAlgoliaTrail(map.trailId);
    // Get all the maps for the user so that Explore More maps works;
    this.getUserMaps(map.user.id, 'map', false, false);
    const url = LanguageSupportUtil.wrapUrlSafe(`/explore/map/${map.slug}`, this.props.context.languageRegionCode);
    window.history.pushState(
      { page: PageStrings.EXPLORE_USERS_MAPS_MAP_PAGE, selectedObjectId: map.ID, filters: null },
      `Explore ${map.name} | AllTrails`,
      url
    );
    this.setState({
      page: PageStrings.EXPLORE_USERS_MAPS_MAP_PAGE,
      selectedObject: map,
      exploreMap: map,
      titleString: this.props.intl.formatMessage({ defaultMessage: 'My maps' })
    });
  },
  handleNewMapClick() {
    window.location = LanguageSupportUtil.wrapUrlSafe('/explore/map/new', this.props.context.languageRegionCode);
  },
  handleRecordingRequest({ detail }) {
    const recording = detail;
    recording.ID = recording.id;
    // this.getSelectedTrackDetails(map.ID);
    if (recording.trailId) this.getAlgoliaTrail(recording.trailId);
    // Get all the recording for the user so that Explore More recording works;
    this.getUserMaps(recording.user.id, 'track', false, false);
    const url = LanguageSupportUtil.wrapUrlSafe(`/explore/recording/${recording.slug}`, this.props.context.languageRegionCode);
    this.props.setMapId?.(recording.ID);
    window.history.pushState(
      { page: PageStrings.EXPLORE_USERS_TRACKS_MAP_PAGE, selectedObjectId: recording.ID, filters: null },
      `Explore ${recording.name} | AllTrails`,
      url
    );
    this.setState({
      page: PageStrings.EXPLORE_USERS_TRACKS_MAP_PAGE,
      selectedObject: recording,
      exploreMap: recording,
      titleString: this.props.intl.formatMessage({ defaultMessage: 'My activities' })
    });
  },
  updateEditingStatus(editing) {
    const isListOwner = this.props.belongsToCurrentUser;

    if (!isListOwner) {
      return;
    }

    if (editing) {
      // if toggling edit on, zoom out map to see all results
      const newFilterState = this.newSearchFilters();

      const updatedFilterState = SearchFiltersUtil.setOrToggleFilter(newFilterState, 'trailIds', this.state.filters.trailIds);
      this.setState({ isEditing: editing, filters: updatedFilterState }, this.replaceHistoryStateAndSearch);
    } else {
      this.setState({ isEditing: editing });
      // if toggling edit off, save list order
      if (this.props.listId) {
        this.handleListReordering();
      }
    }
  },
  backToExplore() {
    this.messagingChannel.publish('map.edit-endpoint-state-changed', { editingEndpoint: false, canceled: true });
    this.messagingChannel.publish('edit.disable_trail_planner');
    let allFilters = this.newSearchFilters();

    const params = new URLSearchParams(document.referrer);
    const previousPageWasCommunityContent = params.get('cc') === 'true';

    // If we're on a track show page, we should always go back to explore recordings with explore more recordings button
    if (this.state.page === PageStrings.EXPLORE_USERS_TRACKS_MAP_PAGE) {
      if (previousPageWasCommunityContent) {
        window.history.back();
      }
      const currentSearchBounds = this.state.filters.boundingBox;
      // If we have search bounds set, go back to these bounds
      if (currentSearchBounds && currentSearchBounds.latitudeTopLeft) {
        allFilters = SearchFiltersUtil.setBoundsFilter(
          allFilters,
          currentSearchBounds.latitudeTopLeft,
          currentSearchBounds.longitudeTopLeft,
          currentSearchBounds.latitudeBottomRight,
          currentSearchBounds.longitudeBottomRight
        );
        this.refs.searchMap.setMapBounds(currentSearchBounds);
        allFilters = SearchFiltersUtil.setOrToggleFilter(allFilters, 'zoomLevel', this.refs.searchMap.getMapZoom());
      }
      // Otherwise just go back with NULL bounds so we can fit all the markers on the map
      const { atMaps } = this.state;
      // eslint-disable-next-line eqeqeq
      if (!atMaps && this.props.initialUserSlug != ET_PHOTO_USER_SLUG) {
        // Allow for the getUserMaps call to run before trying to pushHistoryStateAndSearch
        this.getUserMaps(this.state.exploreMap.user.id, 'track', true, false);
        this.setState({
          filters: allFilters,
          results: null,
          trackTrail: null,
          atMaps,
          selectedObject: null,
          exploreMap: null,
          page: PageStrings.EXPLORE_USERS_TRACKS_PAGE,
          titleString: this.props.initialTitleString
        });
      } else {
        this.setState(
          {
            filters: allFilters,
            results: null,
            trackTrail: null,
            atMaps,
            selectedObject: null,
            exploreMap: null,
            page: PageStrings.EXPLORE_USERS_TRACKS_PAGE,
            titleString: this.props.initialTitleString
          },
          this.pushHistoryStateAndSearch
        );
      }
    }
    if (this.state.page === PageStrings.EXPLORE_USERS_MAPS_MAP_PAGE) {
      if (previousPageWasCommunityContent) {
        window.history.back();
      }
      const currentSearchBounds = this.state.filters.boundingBox;
      // If we have search bounds set, go back to these bounds
      if (currentSearchBounds && currentSearchBounds.latitudeTopLeft) {
        allFilters = SearchFiltersUtil.setBoundsFilter(
          allFilters,
          currentSearchBounds.latitudeTopLeft,
          currentSearchBounds.longitudeTopLeft,
          currentSearchBounds.latitudeBottomRight,
          currentSearchBounds.longitudeBottomRight
        );
        this.refs.searchMap.setMapBounds(currentSearchBounds);
        const zoomLevel = this.refs.searchMap.getMapZoom();
        allFilters = SearchFiltersUtil.setZoomLevelFilter(allFilters, zoomLevel);
      }
      const { atMaps } = this.state;
      if (!atMaps) {
        this.getUserMaps(this.state.exploreMap.user.id, 'map', true, false);
        this.setState({
          filters: allFilters,
          results: [],
          selectedObject: null,
          exploreMap: null,
          trackTrail: null,
          atMaps,
          page: PageStrings.EXPLORE_USERS_MAPS_PAGE,
          titleString: this.props.initialTitleString
        });
      } else {
        // Othwerise just go back with NULL bounds so we can fit all the markers on the map
        this.setState(
          {
            filters: allFilters,
            results: [],
            selectedObject: null,
            exploreMap: null,
            trackTrail: null,
            atMaps,
            page: PageStrings.EXPLORE_USERS_MAPS_PAGE,
            titleString: this.props.initialTitleString
          },
          this.pushHistoryStateAndSearch
        );
      }
    }
    // If on a trail show page
    else if (this.state.page === PageStrings.EXPLORE_TRAIL_MAP_PAGE) {
      // If we have trailIds we had to have come from a list, so just go back
      if (this.state.filters.trailIds && this.state.filters.trailIds.length > 0) {
        window.history.back();
      } else {
        const currentSearchBounds = this.state.filters.boundingBox;
        // If no search bounds, zoom out a bit and then go to Explore All
        if (currentSearchBounds == null || this.state.backReturnsToExplore) {
          const mapCenter = this.refs.searchMap.getMapCenter();
          if (mapCenter) {
            this.refs.searchMap.setMapCenterAndZoom(mapCenter[0], mapCenter[1], 14, { suppressBoundsCallback: true });
          }

          const newBounds = this.refs.searchMap.getMapBounds();
          if (newBounds) {
            allFilters = SearchFiltersUtil.setBoundsFilter(allFilters, newBounds[0], newBounds[2], newBounds[1], newBounds[3]);
            allFilters = SearchFiltersUtil.setOrToggleFilter(allFilters, 'zoomLevel', this.refs.searchMap.getMapZoom());
            this.setState(
              {
                filters: allFilters,
                results: [],
                selectedObject: null,
                exploreMap: null,
                page: PageStrings.EXPLORE_ALL_PAGE,
                backReturnsToExplore: false,
                titleString: 'Explore',
                loading: true
              },
              this.pushHistoryStateAndSearch
            );
          }

          // Otherwise, just use back
        } else {
          this.props.onMapReady(false);
          window.history.back();
        }
      }
    }
  },
  handleTrackPhotosUpload(photos) {
    const currentMap = this.state.exploreMap;
    if (currentMap.mapPhotos && currentMap.mapPhotos.length === 0) {
      const forceTrackPhotoIds = this.state.forceTrackPhotoIds || {};
      forceTrackPhotoIds[this.state.exploreMap.id] = photos[0].photo.id;
      this.setState({ forceTrackPhotoIds });
    }
    currentMap.mapPhotos = currentMap.mapPhotos.concat(photos);
    this.setState({ exploreMap: currentMap });
  },
  changeParentResultOrder(newResults, newTrailFilterIds) {
    let newFilters = this.newSearchFilters();
    newFilters = SearchFiltersUtil.setOrToggleFilter(newFilters, 'trailIds', newTrailFilterIds);
    this.setState({ results: newResults, filters: newFilters });
  },
  handleTrackDefaultPhotoChanged(photoId) {
    const forceTrackPhotoIds = this.state.forceTrackPhotoIds || {};
    forceTrackPhotoIds[this.state.exploreMap.id] = photoId;
    this.setState({ forceTrackPhotoIds });
  },
  handleMapInfoChanged(newMap) {
    if (!newMap) {
      return;
    }
    if (newMap?.maps) {
      // eslint-disable-next-line prefer-destructuring
      newMap = newMap.maps[0];
    }
    if (newMap?.map) {
      newMap = newMap.map;
    }
    this.setState({ exploreMap: newMap });
  },
  handleOverlayToggled(overlay) {
    if (overlay === 'nearbyTrails') {
      this.setState({ nearbyTrailsShown: !this.state.nearbyTrailsShown });
    }
  },
  getMapViewCenter() {
    const center = this.refs.searchMap.getMapCenter();
    return { latitude: center[0].toFixed(5), longitude: center[1].toFixed(5) };
  },
  enablePlaceFilters(enabledFilters) {
    const index = enabledFilters.indexOf('access') + 1;
    enabledFilters.splice(index, 0, 'closed_status', 'city', 'state', 'country');
  },
  // eslint-disable-next-line react/no-unstable-nested-components
  getTopBar() {
    let enabledFilters = [];
    let searchBoxTypeSubsets = [];
    let placeholderText;
    let breadcrumbItems = [];
    switch (this.state.page) {
      case PageStrings.EXPLORE_FAVORITE_PAGE:
        placeholderText = this.props.intl.formatMessage({ defaultMessage: 'Search favorite trails...' });
        enabledFilters = [
          'sort',
          'difficulty',
          'length',
          'rating',
          'activity',
          'feature',
          'access',
          'area',
          'route_type',
          'visitor_usage',
          'elevation_gain',
          'highest_point'
        ];
        if (this.props.user) {
          enabledFilters.push('completed');
        }
        if (hasPermission({ permission: 'trails:manage' })) {
          this.enablePlaceFilters(enabledFilters);
        }
        break;
      case PageStrings.EXPLORE_COMPLETED_PAGE:
        placeholderText = this.props.intl.formatMessage({ defaultMessage: 'Search completed trails...' });
        enabledFilters = ['difficulty', 'length', 'rating', 'activity', 'feature', 'access', 'area', 'route_type', 'visitor_usage', 'elevation_gain'];
        if (this.props.user) {
          enabledFilters.push('completed');
        }
        if (hasPermission({ permission: 'trails:manage' })) {
          this.enablePlaceFilters(enabledFilters);
        }
        break;
      case PageStrings.EXPLORE_ALL_PAGE:
        placeholderText = this.state.filters.locationObject ? this.state.filters.locationObject.name : '';
        enabledFilters = [
          'sort',
          'distance_away',
          'difficulty',
          'length',
          'rating',
          'activity',
          'feature',
          'access',
          'area',
          'route_type',
          'visitor_usage',
          'elevation_gain',
          'highest_point'
        ];
        if (this.props.user) {
          enabledFilters.push('completed');
        }
        if (hasPermission({ permission: 'trails:manage' })) {
          this.enablePlaceFilters(enabledFilters);
        }
        break;
      case PageStrings.EXPLORE_COMMUNITY_CONTENT_PAGE:
        placeholderText = this.state.filters.locationObject ? this.state.filters.locationObject.name : '';
        enabledFilters = ['sort', 'distance_away', 'activity', 'length', 'rating', 'elevation_gain'];
        if (hasPermission({ permission: 'trails:manage' })) {
          enabledFilters.push('place');
        }
        break;
      case PageStrings.EXPLORE_CUSTOM_PAGE:
        enabledFilters = [
          'sort',
          'difficulty',
          'length',
          'rating',
          'activity',
          'feature',
          'access',
          'area',
          'route_type',
          'visitor_usage',
          'elevation_gain'
        ];
        if (this.props.user) {
          enabledFilters.push('completed');
        }
        if (hasPermission({ permission: 'trails:manage' })) {
          this.enablePlaceFilters(enabledFilters);
        }
        placeholderText = this.props.intl.formatMessage({ defaultMessage: 'Search list...' });
        break;
      case PageStrings.EXPLORE_USERS_TRACKS_PAGE:
        enabledFilters = ['sort', 'length', 'rating', 'activity', 'elevation_gain'];
        if (hasPermission({ permission: 'trails:manage' })) {
          enabledFilters.push('linked');
        }
        searchBoxTypeSubsets = ['area', 'place'];
        placeholderText = this.props.intl.formatMessage({ defaultMessage: 'Search activities...' });
        break;
      case PageStrings.EXPLORE_USERS_MAPS_PAGE:
        enabledFilters = ['sort', 'length', 'rating', 'activity', 'elevation_gain'];
        if (hasPermission({ permission: 'trails:manage' })) {
          enabledFilters.push('linked');
        }
        searchBoxTypeSubsets = ['area', 'place'];
        placeholderText = this.props.intl.formatMessage({ defaultMessage: 'Search maps...' });
        break;
      case PageStrings.EXPLORE_USERS_TRACKS_MAP_PAGE:
      case PageStrings.EXPLORE_USERS_MAPS_MAP_PAGE:
      case PageStrings.EXPLORE_LIFELINE_PAGE:
        breadcrumbItems = this.createMapBreadcrumbItems();
        break;
      case PageStrings.EXPLORE_TRAIL_MAP_PAGE:
        breadcrumbItems = this.createTrailBreadcrumbItems();
        break;
      default:
        enabledFilters = [];
    }
    switch (this.state.page) {
      case PageStrings.EXPLORE_ALL_PAGE:
      case PageStrings.EXPLORE_COMMUNITY_CONTENT_PAGE:
      case PageStrings.EXPLORE_FAVORITE_PAGE:
      case PageStrings.EXPLORE_COMPLETED_PAGE:
      case PageStrings.EXPLORE_CUSTOM_PAGE:
      case PageStrings.EXPLORE_USERS_TRACKS_PAGE:
      case PageStrings.EXPLORE_PENDING_PAGE:
      case PageStrings.EXPLORE_USERS_MAPS_PAGE:
        return {
          component: (
            <SearchFilterBar
              algoliaIndex={this.algoliaIndex}
              atMaps={this.state.atMaps}
              clearAllFilters={this.clearAllFilters}
              context={this.props.context}
              enableSavedFilters={this.state.page === PageStrings.EXPLORE_ALL_PAGE}
              initialUserSlug={this.props.initialUserSlug}
              listMethods={this.props.listMethods}
              clearRadioFilter={this.clearRadioFilter}
              enabledFilters={enabledFilters}
              filters={this.state.filters}
              handleFilterSet={this.handleFilterSet}
              handleFilterRange={this.handleFilterRange}
              handleFilterToggle={this.handleFilterToggle}
              handleFilterRadioToggle={this.handleFilterRadioToggle}
              handleQueryTermSet={this.handleQueryTermSet}
              isMobileWidth={this.props.isMobileWidth}
              liveStringSearch={[
                PageStrings.EXPLORE_FAVORITE_PAGE,
                PageStrings.EXPLORE_COMPLETED_PAGE,
                PageStrings.EXPLORE_CUSTOM_PAGE,
                PageStrings.EXPLORE_USERS_TRACKS_PAGE,
                PageStrings.EXPLORE_USERS_MAPS_PAGE
              ].includes(this.state.page)}
              page={this.state.page}
              placeholderText={placeholderText}
              results={this.state.results}
              resultsCount={this.state.results ? this.state.results.length : null}
            />
          ),
          type: 'filter'
        };
      case PageStrings.EXPLORE_TRAIL_MAP_PAGE:
      case PageStrings.EXPLORE_USERS_TRACKS_MAP_PAGE:
      case PageStrings.EXPLORE_USERS_MAPS_MAP_PAGE:
      case PageStrings.EXPLORE_LIFELINE_PAGE:
        if ((breadcrumbItems && breadcrumbItems.length) || this.state.page === PageStrings.EXPLORE_USERS_MAPS_MAP_PAGE) {
          return {
            component: (
              <FullscreenBreadcrumbBar
                items={breadcrumbItems}
                onMapPage
                context={this.props.context}
                typeSubsets={searchBoxTypeSubsets}
                intl={this.props.intl}
              />
            ),
            type: 'breadcrumb'
          };
        }
        return null;
      default:
        return null;
    }
  },
  openUploadRecordingModal() {
    this.openTrailFormModal('tracks', { onTrackAdd: this.handleRecordingRequest, exploreFlag: false });
  },
  getInitialLocationType() {
    let initialLocationType = null;
    if (this.props.initialFilters.locationObject && this.props.initialFilters.locationObject.objectID) {
      // eslint-disable-next-line prefer-destructuring
      initialLocationType = this.props.initialFilters.locationObject.objectID.split('-')[0];
    }
    return initialLocationType;
  },
  tabHandler(pageKey) {
    this.setState({ page: pageKey, results: [], loading: true }, this.replaceHistoryStateAndSearch);
  },
  handlePrimaryCtaClick(option) {
    if (option === 'favorites' || option === 'custom' || option === 'completed')
      window.location = LanguageSupportUtil.wrapUrlSafe('/explore', this.props.context.languageRegionCode);
    if (option === 'maps') window.location = LanguageSupportUtil.wrapUrlSafe('/explore/map/new', this.props.context.languageRegionCode);
    if (option === 'activities') return this.openUploadRecordingModal();
    return null;
  },
  redirectToMapPage(data) {
    window.location = LanguageSupportUtil.wrapUrlSafe(`/explore/map/${data.slug}?save`, this.props.context.languageRegionCode);
  },
  saveMapAsClone(data) {
    // Block user with signup if not signed-in
    if (!this.props.user) {
      this.blockNonUserCreation('explore-map-new');
      return;
    }

    const { id, name } = data;
    this.updateProgressDialog('saving');
    copyRoute(id, name)
      .then(resp => {
        if (resp.errors) {
          this.handleMapSaveError(resp.errors);
          return;
        }
        this.redirectToMapPage(resp);
      })
      .catch(this.handleMapSaveError);
  },
  initializeFlyover() {
    this.setState({ shouldInitializeFlyover: true });
  },
  onFlyoverViewEnabled() {
    this.setState({ flyoverViewEnabled: !!this.state.exploreMap, shouldInitializeFlyover: false });
  },
  onFlyoverViewDisabled() {
    this.setState({ flyoverViewEnabled: false, shouldInitializeFlyover: false });
  },
  // eslint-disable-next-line react/no-unstable-nested-components
  createEmptyStates() {
    const isCurrentUser = compareUserIds(this.props.context?.currentUser?.id, this.props.userInfo?.id);
    switch (this.state.page) {
      case PageStrings.EXPLORE_FAVORITE_PAGE:
        return isCurrentUser ? (
          <NullState
            className={styles.nullState}
            illustration={{ Component: Backpack }}
            title={this.props.intl.formatMessage({ defaultMessage: 'Start saving trails' })}
            description={this.props.intl.formatMessage({
              defaultMessage: 'Tap the bookmark icon on any trail to turn this into your adventure wishlist.'
            })}
            buttons={{
              primary: {
                text: this.props.intl.formatMessage(exploreTrailsMessage),
                testId: exploreTrailsMessage.defaultMessage,
                onClick: () => this.handlePrimaryCtaClick('favorites')
              }
            }}
          />
        ) : (
          <NullState
            className={styles.nullState}
            illustration={{ Component: Bear }}
            title={this.props.intl.formatMessage({ defaultMessage: 'No list items' })}
            description={this.props.intl.formatMessage({
              defaultMessage: 'When this member saves items to this list, you’ll see them here.'
            })}
          />
        );
      case PageStrings.EXPLORE_USERS_MAPS_PAGE:
        return (
          <NullState
            className={styles.nullState}
            illustration={{ Component: Deer }}
            title={this.props.intl.formatMessage({ defaultMessage: 'Create your own map' })}
            description={this.props.intl.formatMessage({ defaultMessage: 'Use the AllTrails map creator tool to build custom routes.' })}
            buttons={{
              primary: {
                text: <FormattedMessage defaultMessage="Create map" />,
                testId: 'create-map',
                onClick: () => this.handlePrimaryCtaClick('maps')
              }
            }}
          />
        );
      case PageStrings.EXPLORE_USERS_TRACKS_PAGE:
        return (
          <NullState
            className={styles.nullState}
            illustration={{ Component: Bear }}
            title={this.props.intl.formatMessage({ defaultMessage: 'Keep track of your activities' })}
            description={this.props.intl.formatMessage({
              defaultMessage:
                'Save activities by navigating your route in the AllTrails app. On desktop, upload activities from Garmin, Google, and more.'
            })}
            buttons={{
              primary: {
                text: this.props.intl.formatMessage({ defaultMessage: 'Upload file' }),
                testId: 'Upload file',
                onClick: () => this.handlePrimaryCtaClick('activities')
              }
            }}
          />
        );
      case PageStrings.EXPLORE_COMPLETED_PAGE:
        return isCurrentUser ? (
          <NullState
            className={styles.nullState}
            illustration={{ Component: Flower }}
            title={this.props.intl.formatMessage({ defaultMessage: 'Start completing trails' })}
            description={this.props.intl.formatMessage({ defaultMessage: 'Log your completed trails by using the AllTrails app to Navigate.' })}
            buttons={{
              primary: {
                text: this.props.intl.formatMessage(exploreTrailsMessage),
                testId: exploreTrailsMessage.defaultMessage,
                onClick: () => this.handlePrimaryCtaClick('completed')
              }
            }}
          />
        ) : (
          <NullState
            className={styles.nullState}
            illustration={{ Component: Bear }}
            title={this.props.intl.formatMessage({ defaultMessage: 'No completed trails yet' })}
            description={this.props.intl.formatMessage({
              defaultMessage: 'When this member completes trails, you’ll see them here.'
            })}
          />
        );
      case PageStrings.EXPLORE_CUSTOM_PAGE:
        // eslint-disable-next-line no-case-declarations
        const isCollaborator = this.props.collaborators.find(collaborator => collaborator.user.id === this.props.context?.currentUser?.id);
        return <ListNullState isCurrentUser={isCurrentUser} handlePrimaryCtaClick={this.handlePrimaryCtaClick} isCollaborator={isCollaborator} />;
      default:
        return this.createNoResultsObj();
    }
  },
  leftPaneAndMapTopLeft() {
    // Render leftPane based on page type, filters, and other state
    let leftPane = null;
    let mapTop = 106;
    let fullscreenSearchResultsWidth = 400;

    if (this.state.page === PageStrings.EXPLORE_TRAIL_MAP_PAGE) {
      if (this.props.isMobileWidth && this.state.filters.mobileMap) {
        mapTop = 60;
        fullscreenSearchResultsWidth = 0;
        leftPane = (
          <FullscreenSearchResults
            className="hide-for-fullscreen-modal"
            isFlyoverViewEnabled={this.state.flyoverViewEnabled}
            isMobileMap={this.state.filters?.mobileMap}
            isMobileWidth={this.props.isMobileWidth}
            mapTop={mapTop}
          >
            <div className="result-info">
              <Link onClick={() => this.goToTrailPage(this.state.selectedObject)} size="sm" icon={{ Component: ArrowSq, orientation: 'left' }}>
                <FormattedMessage defaultMessage="Back" />
              </Link>
            </div>
          </FullscreenSearchResults>
        );
      } else {
        let selectedTrailSlug;
        if (this.state.selectedObject) {
          selectedTrailSlug = `/trail/${this.state.selectedObject.slug.replace(/^trail\//, '')}`;
        }
        leftPane = (
          <FullscreenSearchResults
            isFlyoverViewEnabled={this.state.flyoverViewEnabled}
            isMobileMap={this.state.filters?.mobileMap}
            isMobileWidth={this.props.isMobileWidth}
            overflowY="auto"
            paddingBottom={25}
            width={fullscreenSearchResultsWidth}
          >
            <div className="result-info">
              <Link onClick={this.backToExplore} size="sm" icon={{ Component: ArrowSq, orientation: 'left' }}>
                <FormattedMessage defaultMessage="More trails" />
              </Link>
              {!this.props.isMobileWidth && (
                <Link className="floatright" href={selectedTrailSlug} size="sm" testId="VIEW_TRAIL_DETAILS">
                  <FormattedMessage defaultMessage="View trail details" />
                </Link>
              )}
            </div>
            {this.state.hasTrailError ? (
              <ErrorFallback retryErrorPage={() => this.fetchTrailProps(this.state.selectedObject)} className={styles.trailErrorFallbackContainer} />
            ) : (
              <TrailExplore
                trailPageProps={{
                  ...this.state.trailProps,
                  context: this.props.context,
                  initializingFlyover: this.state.shouldInitializeFlyover,
                  onFlyoverClick: this.initializeFlyover,
                  mapboxAccessToken: this.props.mapboxAccessToken
                }}
                listMethods={this.props.listMethods}
                isLoading={this.state.loading}
              />
            )}
          </FullscreenSearchResults>
        );
      }
    } else if (this.state.page === PageStrings.EXPLORE_USERS_MAPS_MAP_PAGE && this.state.exploreMap) {
      leftPane = (
        <FullscreenSearchResults
          className="map-creator-panel"
          isFlyoverViewEnabled={this.state.flyoverViewEnabled}
          isMobileMap={this.state.filters?.mobileMap}
          isMobileWidth={this.props.isMobileWidth}
        >
          {this.props.isMobileWidth && this.state.filters.mobileMap && (
            <div className="backToMap">
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              <button onClick={this.hideMapMobile}>
                <FormattedMessage defaultMessage="Back" />
              </button>
            </div>
          )}
          <MapCreatorPanel
            context={this.props.context}
            handleMapUpdate={this.handleMapUpdate}
            initialIsEditing={typeof this.state.exploreMap.ID === 'undefined'}
            linkedTrail={this.state.trackTrail}
            map={this.state.exploreMap}
            mapboxAccessToken={this.props.mapboxAccessToken}
            messagingChannel={this.messagingChannel}
            openShareModal={this.openShareModal}
            openTrailFormModal={this.openTrailFormModal}
            resultCardFunctions={this.resultCardFunctions}
            saveMapChanges={this.saveMapChanges}
            updateExploreMap={this.updateExploreMap}
            updateMapPhotos={this.handleTrackPhotosUpload}
            routeInfo={this.state.routeInfo}
            updateProgressDialog={this.updateProgressDialog}
            showMapMobile={this.showMapMobile}
            hidePanel={this.props.isMobileWidth && this.state.filters.mobileMap}
            handleRouteInfoChanged={this.handleRouteInfoChanged}
            listMethods={this.props.listMethods}
            saveMapAsClone={this.saveMapAsClone}
            setReportingSuccessToast={this.props.setReportingSuccessToast}
          />
        </FullscreenSearchResults>
      );
    } else if (this.state.page === PageStrings.EXPLORE_USERS_TRACKS_MAP_PAGE || this.state.page === PageStrings.EXPLORE_LIFELINE_PAGE) {
      mapTop = this.props.isMobileWidth ? 60 : 101;
      if (this.state.exploreMap && this.state.selectedObject && this.state.page !== PageStrings.EXPLORE_LIFELINE_PAGE) {
        let resultInfoBody;
        if (this.props.isMobileWidth && this.state.filters.mobileMap) {
          resultInfoBody = (
            <Link onClick={this.hideMapMobile} size="sm" icon={{ Component: ArrowSq, orientation: 'left' }}>
              <FormattedMessage defaultMessage="Back" />
            </Link>
          );
        } else if (
          this.state.page === PageStrings.EXPLORE_USERS_MAPS_MAP_PAGE &&
          this.props.userInfo &&
          // eslint-disable-next-line eqeqeq
          this.props.userInfo.slug != AnonymousMapUsername
        ) {
          resultInfoBody = (
            <Link onClick={this.backToExplore} size="sm" icon={{ Component: ArrowSq, orientation: 'left' }}>
              <FormattedMessage defaultMessage="More maps" />
            </Link>
          );
        } else if (this.state.page === PageStrings.EXPLORE_USERS_MAPS_MAP_PAGE && this.state.selectedObject && !this.state.selectedObject.ID) {
          resultInfoBody = (
            <h1 className="result-action">
              <FormattedMessage defaultMessage="Create map" />
            </h1>
          );
        } else if (this.state.page === PageStrings.EXPLORE_USERS_TRACKS_MAP_PAGE) {
          resultInfoBody = (
            <Link onClick={this.backToExplore} size="sm" icon={{ Component: ArrowSq, orientation: 'left' }}>
              <FormattedMessage defaultMessage="More activities" />
            </Link>
          );
        }
        leftPane = (
          <FullscreenSearchResults
            className={classNames({ 'hide-for-fullscreen-modal': this.props.isMobileWidth })}
            isFlyoverViewEnabled={this.state.flyoverViewEnabled}
            isMobileMap={this.state.filters?.mobileMap}
            isMobileWidth={this.props.isMobileWidth}
            width={fullscreenSearchResultsWidth}
          >
            <div className="result-info">{resultInfoBody}</div>
            <TrackPanel
              openTrailFormModal={this.openTrailFormModal}
              context={this.props.context}
              currentPage={this.state.page}
              data={JSON.parse(JSON.stringify(this.state.exploreMap))}
              getMapViewCenter={this.getMapViewCenter}
              handleMapInfoChanged={this.handleMapInfoChanged}
              handleMapUpdate={this.handleMapUpdate}
              handlePhotosUpload={this.handleTrackPhotosUpload}
              handleTrackDefaultPhotoChanged={this.handleTrackDefaultPhotoChanged}
              handleShareClick={this.openShareModal}
              isMobileWidth={this.props.isMobileWidth}
              key={`track-details-${this.state.exploreMap.id}`}
              messagingChannel={this.messagingChannel}
              result={this.state.selectedObject}
              trackTrail={this.state.trackTrail}
              resultCardFunctions={this.resultCardFunctions}
              listMethods={this.props.listMethods}
              mapboxAccessToken={this.props.mapboxAccessToken}
              handleTrackUpdate={this.handleRecordingRequest}
              saveMapAsClone={this.saveMapAsClone}
              blockNonUserCreation={this.blockNonUserCreation}
              showMapMobile={this.showMapMobile}
              setReportingSuccessToast={this.props.setReportingSuccessToast}
            />
          </FullscreenSearchResults>
        );
      } else if (this.state.page === PageStrings.EXPLORE_LIFELINE_PAGE) {
        let resultInfoBody;
        if (this.props.isMobileWidth && this.state.filters.mobileMap) {
          resultInfoBody = (
            <div className="result-info">
              <Link onClick={this.hideMapMobile} size="sm" icon={{ Component: ArrowSq, orientation: 'left' }}>
                <FormattedMessage defaultMessage="Back" />
              </Link>
            </div>
          );
        }
        leftPane = (
          <FullscreenSearchResults
            isFlyoverViewEnabled={this.state.flyoverViewEnabled}
            isMobileMap={this.state.filters?.mobileMap}
            isMobileWidth={this.props.isMobileWidth}
            width={fullscreenSearchResultsWidth}
          >
            {resultInfoBody}
            <FullscreenLifelineDetails
              context={this.props.context}
              data={JSON.parse(JSON.stringify(this.state.exploreMap))}
              isMobileWidth={this.props.isMobileWidth}
              key={`track-details-${this.state.exploreMap.id}`}
              lifeline={this.state.lifeline}
              messagingChannel={this.messagingChannel}
              resultCardFunctions={this.resultCardFunctions}
              listMethods={this.props.listMethods}
              trackTrail={this.state.trackTrail}
              sampledElevationGain={this.state.routeInfo ? this.state.routeInfo.elevationGain : null}
              intl={this.props.intl}
            />
          </FullscreenSearchResults>
        );
      } else {
        leftPane = (
          <FullscreenSearchResults
            isFlyoverViewEnabled={this.state.flyoverViewEnabled}
            isMobileMap={this.state.filters?.mobileMap}
            isMobileWidth={this.props.isMobileWidth}
            width={fullscreenSearchResultsWidth}
          >
            <div className="result-info">
              {this.state.page === PageStrings.EXPLORE_USERS_MAPS_MAP_PAGE && (
                <Link onClick={this.backToExplore} size="sm" icon={{ Component: ArrowSq, orientation: 'left' }}>
                  <FormattedMessage defaultMessage="More maps" />
                </Link>
              )}
              {this.state.page === PageStrings.EXPLORE_USERS_TRACKS_MAP_PAGE && (
                <Link onClick={this.backToExplore} size="sm" icon={{ Component: ArrowSq, orientation: 'left' }}>
                  <FormattedMessage defaultMessage="More activities" />
                </Link>
              )}
            </div>
            <div id="iframe-loading" style={{ display: 'block' }}>
              {this.state.page === PageStrings.EXPLORE_USERS_TRACKS_MAP_PAGE && <LoadingSpinner className={styles.activitiesLoading} />}
              {this.state.page === PageStrings.EXPLORE_USERS_MAPS_MAP_PAGE && (
                <div className="header">
                  <FormattedMessage defaultMessage="Loading map info..." />
                </div>
              )}
              {this.state.page === PageStrings.EXPLORE_USERS_MAPS_MAP_PAGE && (
                <div className="icon trailpost">
                  <span className="icon-text">
                    <FormattedMessage defaultMessage="Hang in there while we load more details about the map" />
                  </span>
                </div>
              )}
            </div>
          </FullscreenSearchResults>
        );
      }
    } else {
      let showBottomButton;
      let bottomButtonAction;
      let bottomButtonText;
      let showToggleButton;
      if (
        this.state.page === PageStrings.EXPLORE_FAVORITE_PAGE ||
        this.state.page === PageStrings.EXPLORE_COMPLETED_PAGE ||
        this.state.page === PageStrings.EXPLORE_CUSTOM_PAGE
      ) {
        showToggleButton = true;
        bottomButtonAction = this.toggleNearbyTrailsOverlay;
        bottomButtonText = this.props.intl.formatMessage({ defaultMessage: 'Nearby trails' });
      } else if (this.state.page === PageStrings.EXPLORE_USERS_MAPS_PAGE) {
        // eslint-disable-next-line eqeqeq
        showBottomButton = this.props.user && this.props.user.slug == this.props.initialUserSlug && !isEmpty(this.state.results);
        bottomButtonAction = this.handleNewMapClick;
        bottomButtonText = this.props.intl.formatMessage({ defaultMessage: 'Create map' });
      } else if (this.state.page === PageStrings.EXPLORE_USERS_TRACKS_PAGE && !this.props.context.mobileBrowser) {
        // eslint-disable-next-line eqeqeq
        showBottomButton = this.props.user && this.props.user.slug == this.props.initialUserSlug && !isEmpty(this.state.results);
        bottomButtonAction = this.openUploadRecordingModal;
        bottomButtonText = this.props.intl.formatMessage({ defaultMessage: 'Upload file' });
      }

      let h1Obj = null;
      if (this.state.page === PageStrings.EXPLORE_CUSTOM_PAGE) {
        if (this.props.areListDetailsLoading || this.props.listDetailsData === null) {
          h1Obj = (
            <div className="loading-placeholder">
              <LoadingSpinner />
            </div>
          );
        } else if (this.props.listDetailsData !== null) {
          const canEdit =
            hasPermission({ permission: 'trails:manage' }) || compareUserIds(this.props.context.currentUser?.id, this.props.listDetailsData?.user.id);
          h1Obj = (
            <ExploreListHeaderPage
              context={this.props.context}
              list={this.props.listDetailsData}
              collaborators={this.props.collaborators}
              setCollaborators={this.props.setCollaborators}
              areCollaboratorsLoading={this.props.areCollaboratorsLoading}
              isCollaborative={this.props.listDetailsData?.isCollaborative}
              ownerId={String(this.props.listDetailsData?.ownerId)}
              user={this.props.context.currentUser}
              languageRegionCode={this.props.context.languageRegionCode}
              canEdit={canEdit}
              adminUser={hasPermission({ permission: 'trails:manage' })}
              hasPrivateContent={this.state.results?.find(result => result.private)}
              numberOfPrivateItems={this.state.results?.filter(result => result.private)?.length}
              collaborativeListCallback={this.collaborativeListCallback}
              setToastProps={this.props.setToastProps}
              openCollaboratorsModal={this.props.openCollaboratorsModal}
              setIsCollaboratorsModalOpen={this.props.setIsCollaboratorsModalOpen}
            />
          );
        }
      } else {
        h1Obj = this.createH1Obj();
      }
      const resultCountObj = this.createResultCountObj();
      let paddingBottom;
      if (h1Obj && resultCountObj && !this.props.context.mobileBrowser) {
        paddingBottom = '64px';
      }

      leftPane = (
        <FullscreenSearchResults
          className={classNames({ 'hide-for-fullscreen-modal': this.props.isMobileWidth }, styles.resultList)}
          isFlyoverViewEnabled={this.state.flyoverViewEnabled}
          isMobileMap={this.state.filters?.mobileMap}
          isMobileWidth={this.props.isMobileWidth}
          paddingBottom={paddingBottom}
        >
          <LegacyFullscreenSearchResultList
            allowReordering={this.state.isEditing && this.props.listId} // Only re-orderable items belong to a list with an ID
            bottomButtonAction={bottomButtonAction}
            bottomButtonText={bottomButtonText}
            changeParentResultOrder={this.changeParentResultOrder}
            clearAllFilters={this.clearAllFilters}
            context={this.props.context}
            currentPage={this.state.page}
            customList={this.isListPage() ? this.props.listDetailsData : this.props.initialCustomList}
            filtersSet={SearchFiltersUtil.filtersAreActive(this.state.filters)}
            handleCopyListClick={this.handleCopyListClick}
            handleNewMapClick={this.handleNewMapClick}
            handleResultsEditClick={() => this.updateEditingStatus(!this.state.isEditing)}
            h1Obj={h1Obj}
            isEditing={this.state.isEditing}
            isMobileMap={this.state.filters?.mobileMap}
            isMobileWidth={this.props.isMobileWidth}
            listCopyString={this.state.listCopyString}
            messagingChannel={this.messagingChannel}
            noResultsObj={this.createEmptyStates()}
            resultCardFunctions={this.resultCardFunctions}
            resultCountObj={resultCountObj}
            results={this.state.results}
            nearbyTrailsShown={this.state.nearbyTrailsShown}
            showToggleButton={showToggleButton}
            showBottomButton={showBottomButton}
            // eslint-disable-next-line eqeqeq
            showNewButton={!showBottomButton && this.props.user && this.props.user.slug == this.props.initialUserSlug}
            tabBar={
              <ExploreTabBar
                activeItemKey={this.state.page}
                loading={this.state.loading}
                onClick={this.tabHandler}
                resultCount={this.getResultCount()}
              />
            }
            userInfo={this.props.userInfo}
            openUploadRecordingModal={this.openUploadRecordingModal}
            loading={this.isListPage() ? this.props.areListItemsLoading : this.state.loading}
            listMethods={this.props.listMethods}
            intl={this.props.intl}
            updateCurrentlyHoveredResult={this.props.updateCurrentlyHoveredResult}
            isNewMapsPage={this.props.isNewMapsPage}
            setToastProps={this.props.setToastProps}
            belongsToCurrentUser={this.props.belongsToCurrentUser}
            setReportingSuccessToast={this.props.setReportingSuccessToast}
            isCollaborator={
              this.props.collaborators && this.props.collaborators.some(collaborator => collaborator.user.id === this.props.context?.currentUser?.id)
            }
            areListDetailsLoading={this.isListPage() ? this.props.areListDetailsLoading : false}
          />
        </FullscreenSearchResults>
      );
    }
    return {
      leftPane,
      mapTop,
      fullscreenSearchResultsWidth
    };
  },
  openShareModal(shareObject) {
    this.setState({ showShareModal: true, shareObject });
  },
  closeShareModal() {
    this.setState({ showShareModal: false });
  },
  openTrailFormModal(activeTrailFormModal, trailFormProps) {
    this.setState({ activeTrailFormModal, trailFormProps });
  },
  closeTrailFormModal() {
    this.setState({ activeTrailFormModal: null, trailFormProps: {} });
  },
  renderTrailFormModal() {
    if (!this.state.activeTrailFormModal) {
      return null;
    }

    switch (this.state.activeTrailFormModal) {
      case 'photos':
        return (
          <Suspense fallback={null}>
            <PhotoUploadModal {...this.state.trailFormProps} closePhotoModal={this.closeTrailFormModal} />
          </Suspense>
        );
      case 'tracks':
        return (
          <Suspense fallback={null}>
            <ActivityUploadModal
              exploreFlag
              {...this.state.trailFormProps}
              closeModal={this.closeTrailFormModal}
              user={this.props.context.currentUser}
            />
          </Suspense>
        );
      default:
        return null;
    }
  },

  // Code extracted from ListFunctionalityMixin
  handleListReordering() {
    const { isEditing, results } = this.state;
    const { listId } = this.props;
    if (!isEditing) {
      return;
    }

    const orderedListItemIds = results.map(
      result => this.props.listMethods.getListItemInList(this.props.listId, result.type, result.ID, this.props.userListItems)?.id
    );

    reorderList(listId, orderedListItemIds)
      .then(updatedListItems => {
        this.props.listMethods.updateListOrder(updatedListItems, listId);
        this.setState({ isEditing: false });
      })
      .catch(logError);
  },

  handleCopyListClick() {
    if (!this.props.user) {
      const returnToUrl = window.location.pathname + window.location.search;
      modalRoadblock('signup', 'explore-list', returnToUrl, this.props.context.languageRegionCode);
      return;
    }

    this.setState({ listCopyString: 'Copying...' });
    copyList(this.state.customList.id).then(list => {
      this.setState({ listCopyString: 'Successfully copied' });
      if (list) {
        window.location = LanguageSupportUtil.wrapUrlSafe(`/explore/lists/${list.slug}`, this.props.context.languageRegionCode);
      }
    });
  },

  isHomePageTrailRiver() {
    return typeof window !== 'undefined' && window.location.href.includes('homepage_explore_trailriver');
  },

  renderMapToggleButton() {
    const isMapView = this.state.filters.mobileMap;
    return (
      <Button
        className={styles.mapToggleButton}
        icon={{ Component: isMapView ? List : MapFilled }}
        onClick={() => {
          this.messagingChannel.publish(isMapView ? 'map.hide' : 'map.show');
        }}
        stopPropagation
        testId="explore-map-toggle-button"
        text={isMapView ? <FormattedMessage defaultMessage="List" /> : <FormattedMessage defaultMessage="Map" />}
        variant="accent"
      />
    );
  },

  render() {
    const { leftPane, fullscreenSearchResultsWidth } = this.leftPaneAndMapTopLeft();
    const boundingLocationBox =
      this.isHomePageTrailRiver() && this.props.locationData
        ? getBoundingBoxFromLatLng(
            this.props.locationData?.latLng[0],
            this.props.locationData?.latLng[1],
            this.state.filters?.zoomLevel ? this.state.filters?.zoomLevel : this.props.initialCenter[2]
          )
        : // For new maps, we need to set the boundingLocationBox so that the new map instance 'knows' its old bounds
          this.state.filters?.boundingBox;
    const locationDataLatLng = this.isHomePageTrailRiver() && this.props.locationData ? this.props.locationData.latLng : null;
    const topBar = this.getTopBar();
    return (
      <Page context={this.props.context}>
        <SearchStateProvider filters={this.state.filters}>
          <div>
            <div id="modalPortal" />
            <CSSTransition
              in={!this.state.flyoverViewEnabled}
              timeout={FLYOVER_ANIM_DURATION_MS}
              classNames={{
                enter: styles.breadcrumbEnter,
                enterActive: styles.breadcrumbEnterActive,
                exit: styles.breadcrumbExit,
                exitActive: styles.breadcrumbExitActive
              }}
            >
              <div>{this.state.flyoverViewEnabled ? null : topBar?.component || null}</div>
            </CSSTransition>
            {this.state.showShareModal && (
              <ShareModal handleClose={this.closeShareModal} shareObject={this.state.shareObject} currentUser={this.props.user} />
            )}
            {this.renderTrailFormModal()}
            {this.renderProgressDialog()}
            <div
              className={classNames('map-list-container', 'no-search-bar', {
                'under-filter-bar': topBar?.type === 'filter',
                withoutBreadcrumb: this.state.flyoverViewEnabled
              })}
            >
              {this.state.showCollabListAlertModal && (
                <CollabListAlertModal
                  listId={this.props.listId}
                  title={this.state.collabListAlertProps.title}
                  description={this.state.collabListAlertProps.description}
                  type={this.state.collabListAlertProps.type}
                  closeModal={() => this.setState({ showCollabListAlertModal: false })}
                />
              )}
              {this.props.toastProps && (
                <Toast
                  message={this.props.toastProps.message}
                  position="alternative"
                  type={this.props.toastProps.type}
                  testId={`invite_collaborators_${this.props.toastProps.type}_toast`}
                  icon={{ Component: this.props.toastProps.type === 'success' ? CheckOutline : Error }}
                />
              )}
              {this.props.reportingSuccessToast && (
                <Toast
                  message={this.props.reportingSuccessToast.text}
                  position="alternative"
                  type="success"
                  testId="report-success-toast"
                  icon={{ Component: Check }}
                />
              )}
              <FlyoverTransition showOn={!this.state.flyoverViewEnabled}>{!this.state.flyoverViewEnabled && leftPane}</FlyoverTransition>
              <FullscreenSearchMap
                context={this.props.context}
                currentPage={this.state.page}
                customList={this.isListPage() ? this.props.listDetailsData : this.props.initialCustomList}
                divLeft={fullscreenSearchResultsWidth}
                boundingLocationBox={boundingLocationBox}
                locationData={locationDataLatLng}
                exactExplorePath={this.state.trailProps?.exactExplorePath}
                exploreMap={this.state.exploreMap}
                flyoverViewEnabled={this.state.flyoverViewEnabled}
                handleMapCreate={this.handleMapCreate}
                handleMapUpdate={this.handleMapUpdate}
                handleOverlayToggled={this.handleOverlayToggled}
                handleRouteInfoChanged={this.handleRouteInfoChanged}
                handleTrailClick={this.handleTrailClick}
                handleTrackClick={this.handleTrackClick}
                handleMapClick={this.handleMapClick}
                handleWaypointAdded={this.addWaypoint}
                handleWaypointRemoved={this.removeWaypoint}
                initFlyoverOnLoad={this.props.initFlyoverOnLoad}
                initialBounds={this.props.initialBounds}
                initialCenter={this.props.initialCenter}
                initialLocationType={this.getInitialLocationType()}
                isDisplayingMobileMap={this.state.filters?.mobileMap}
                isMapExpanded={this.props.isMapExpanded}
                isMobileWidth={this.props.isMobileWidth}
                isochroneData={this.props.isochroneData}
                key="explore-search-map"
                lifeline={this.state.lifeline}
                listMethods={this.props.listMethods}
                mapboxAccessToken={this.props.mapboxAccessToken}
                mapDivId="map"
                mapProviderName="mapBox"
                messagingChannel={this.messagingChannel}
                newMapInstance={this.props.newMapInstance}
                onFlyoverViewEnabled={this.onFlyoverViewEnabled}
                onFlyoverViewDisabled={this.onFlyoverViewDisabled}
                onMapBoundsChanged={this.onMapBoundsChanged}
                onMapReady={this.props.onMapReady}
                page={this.state.page}
                plannerMap={this.state.routeInfo ? this.state.routeInfo.plannerMap : null}
                ref="searchMap"
                resultCardFunctions={this.getResultCardFunctions()}
                results={this.state.results}
                saveEditEndpointChanges={this.saveEditEndpointChanges}
                selectedObject={this.state.selectedObject}
                shouldInitializeFlyover={this.state.shouldInitializeFlyover}
                areActivitiesNewMapsEnabled={this.props.areActivitiesNewMapsEnabled}
                isNewMapsPage={this.props.isNewMapsPage}
                shouldHideMapLayers={this.props.shouldHideMapLayers}
                user={this.props.user}
                currentlyHoveredResult={this.props.currentlyHoveredResult}
                shouldClearClickedResult={this.props.shouldClearClickedResult}
                setShouldClearClickedResult={this.props.setShouldClearClickedResult}
                filters={this.state.filters}
                intl={this.props.intl}
                nearbyTrailsShown={this.state.nearbyTrailsShown}
                toggleNearbyTrailsOverlay={this.toggleNearbyTrailsOverlay}
                belongsToCurrentUser={this.props.belongsToCurrentUser}
                experiments={this.props.experiments}
              />
              {topBar?.type === 'filter' && this.renderMapToggleButton()}
            </div>
          </div>
        </SearchStateProvider>
      </Page>
    );
  },
  // performAppSearch is a wrapper around the mixin performSearch method.
  performAppSearch() {
    if (this.props.isLoadingIsochrone) {
      return;
    }

    this.performSearch(this.state.customMaps);
  }
});

const FullscreenSearchAppIntlProvider = props => {
  const intl = useIntl();
  return <BaseFullscreenSearchApp {...props} intl={intl} />;
};

const FullscreenSearchApp = props => (
  <CustomProvider>
    <FullscreenSearchAppIntlProvider {...props} />
  </CustomProvider>
);

// eslint-disable-next-line import/prefer-default-export
export { FullscreenSearchApp };
