import { string } from 'prop-types';
import { defineMessages } from '@alltrails/shared/react-intl';
import useFormatMessage from '@alltrails/shared/hooks/useFormatMessage';
import * as styles from './styles/styles.module.scss';

const STATS_STRINGS = defineMessages({
  LENGTH: { defaultMessage: 'Length' },
  ELEV_GAIN: { defaultMessage: 'Elev. gain' },
  TOTAL: { defaultMessage: 'Total' }
});

const RouteDetails = ({ distance, elevation, color }) => {
  const {
    formattedDefaultMessages: { LENGTH, ELEV_GAIN, TOTAL }
  } = useFormatMessage(STATS_STRINGS);
  return (
    <div className={styles.container} data-testid="routeDetails">
      {color ? (
        <div className={styles.routeColor} style={{ backgroundColor: color }} />
      ) : (
        <div className={styles.total}>{TOTAL}</div>
      )}
      <div className={styles.statInfo}>
        <div className={styles.statTitle}>{LENGTH}</div>
        <div className={styles.stat}>{distance}</div>
      </div>
      <div className={styles.statInfo}>
        <div className={styles.statTitle}>{ELEV_GAIN}</div>
        <div className={styles.stat}>{elevation}</div>
      </div>
    </div>
  );
};

RouteDetails.propTypes = {
  distance: string.isRequired,
  elevation: string.isRequired,
  color: string
};

RouteDetails.defaultProps = {
  color: null
};

export default RouteDetails;
