import { defineMessages } from '@alltrails/shared/react-intl';
import useFormatMessage from '@alltrails/shared/hooks/useFormatMessage';
import { panelHeader, panelTitle, panelSubtext } from './styles/styles.module.scss';

const EDIT_HEADER_STRINGS = defineMessages({
  CREATE_A_CUSTOM_MAP: { defaultMessage: 'Create a custom map' },
  EASILY_PLAN_THE_PERFECT_PATH: { defaultMessage: 'Easily plan the perfect path for your next hike, bike, or run with our Map Creator tool.' }
});

const EditHeader = () => {
  const {
    formattedDefaultMessages: { CREATE_A_CUSTOM_MAP, EASILY_PLAN_THE_PERFECT_PATH }
  } = useFormatMessage(EDIT_HEADER_STRINGS);
  return (
    <div className={panelHeader}>
      <h1 className={panelTitle}>{CREATE_A_CUSTOM_MAP}</h1>
      <p className={panelSubtext}>{EASILY_PLAN_THE_PERFECT_PATH}</p>
    </div>
  );
};

export default EditHeader;
