import ShimmerWrapper from '@alltrails/shared/components/ShimmerWrapper';
import * as styles from './styles/styles.module.scss';

const TrailUpsellRiverShimmer = (): JSX.Element => (
  <ShimmerWrapper className={styles.shimmerContainer} testId="trail-upsell-shimmer">
    <div className={styles.shimmerRiverContainer}>
      {[1, 2, 3].map(num => (
        <div className={styles.shimmerCondition} key={num} />
      ))}
    </div>
  </ShimmerWrapper>
);

export default TrailUpsellRiverShimmer;
