import { useEffect, useRef, useState } from 'react';

export default function useHasOverflow(deps: ReadonlyArray<unknown> = []) {
  const ref = useRef(null);
  const [hasOverflow, setHasOverflow] = useState<boolean>();

  useEffect(() => {
    const element = ref.current;
    if (!element) return;

    setHasOverflow(element.offsetHeight < element.scrollHeight || element.offsetWidth < element.scrollWidth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  return { ref, hasOverflow };
}
