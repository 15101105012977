import Button from '@alltrails/shared/denali/components/Button';
import useUser from 'hooks/useUser';
import { useEffect } from 'react';
import { FormattedMessage } from '@alltrails/shared/react-intl';
import Typography from '@alltrails/shared/denali/components/Typography';
import classNames from 'classnames';
import useToggle from '@alltrails/shared/hooks/useToggle';
import logAdServed from '@alltrails/analytics/events/logAdServed';
import AdSource from '@alltrails/analytics/enums/AdSource';
import { wrapUrlSafe } from 'utils/language_support_util';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import type { Trail } from '@alltrails/shared/types/Trails/Trail';
import Experiments from '@alltrails/shared/types/Experiments';
import * as styles from './Ad.module.scss';
import logError from 'utils/logError';

type Props = {
  adUnitPath: string;
  buttonClassName?: string;
  buttonVariant?: 'default' | 'primary';
  className?: string;
  experiment?: Experiments['web-growth-native-ads-v2']['value'];
  id: string;
  screenSize?: 'desktop' | 'mobile';
  trail?: Trail;
  onDisplay?: () => void;
};

// Desktop: https://www.figma.com/file/3dCQyKmNlOdfjaZ8vsDpzs/Native-Ads?node-id=464%3A140041&t=6m6YpR4rfbKIw3Ft-4
// Mobile: https://www.figma.com/file/3dCQyKmNlOdfjaZ8vsDpzs/Native-Ads?node-id=464%3A140300&t=6m6YpR4rfbKIw3Ft-4
export default function Ad({
  adUnitPath,
  buttonClassName,
  buttonVariant = 'primary',
  className,
  experiment,
  id,
  screenSize,
  trail,
  onDisplay
}: Props) {
  const user = useUser();
  const [displayAd, toggleDisplayAd] = useToggle(false);
  const languageRegionCode = useLanguageRegionCode();

  useEffect(() => {
    const existing = document.getElementById('gpt');

    if (!existing) {
      const script = document.createElement('script');
      script.id = 'gpt';
      script.src = 'https://securepubads.g.doubleclick.net/tag/js/gpt.js';
      script.async = true;
      document.body.appendChild(script);
    }

    // @ts-ignore
    window.googletag = window.googletag || { cmd: [] };

    if (!window.googletag.cmd) {
      logError(new Error('window.googletag.cmd is undefined'), event => {
        event.addMetadata('googletag', window.googletag);
      });
    }
  }, []);

  useEffect(() => {
    // could do a <FreeAd component that wraps <Ad to avoid loading scripts on plus pages
    if (!user?.pro) {
      const handleSlotRenderEnded = (event: googletag.events.SlotRenderEndedEvent) => {
        if (event.slot.getSlotElementId() === id && !event.isEmpty) {
          logAdServed({ adunitpath: adUnitPath, advertiser_id: event.advertiserId, location: AdSource.TrailDetails });
          toggleDisplayAd();
          onDisplay?.();
        }
      };

      googletag.cmd.push(() => {
        const slot = googletag.defineSlot(adUnitPath, [[300, 250]], id).addService(googletag.pubads());

        if (__AT_DATA__.RAILS_ENV !== 'production') {
          slot.setTargeting('test', '1');
        }

        // trail can be null if you view a lists page and scroll down to reviews i.e. <TrailUgcWrapper and it would also be confusing since each
        // review is a different trail
        if (trail) {
          slot.setTargeting('activity', trail.activities);
          slot.setTargeting('feature', trail.features);
          slot.setTargeting('is_nps', trail.country_id === 313 && trail.area_type === 'N' ? '1' : '0');
        }

        if (experiment === 'unpaid_only') {
          slot.setTargeting('2023adtest', 'unpaid');
        }

        if (experiment === 'unpaid-and-paid') {
          slot.setTargeting('2023adtest', ['paid', 'unpaid']);
        }

        if (screenSize === 'desktop') {
          const mapping = googletag.sizeMapping().addSize([768, 0], [300, 250]).addSize([0, 0], []).build();
          slot.defineSizeMapping(mapping);
        }

        if (screenSize === 'mobile') {
          const mapping = googletag.sizeMapping().addSize([768, 0], []).addSize([0, 0], [300, 250]).build();
          slot.defineSizeMapping(mapping);
        }

        googletag.enableServices();

        googletag.display(id);

        googletag.pubads().addEventListener('slotRenderEnded', handleSlotRenderEnded);
      });

      return () => {
        googletag.cmd.push(() => {
          googletag.pubads().removeEventListener('slotRenderEnded', handleSlotRenderEnded);

          const slots = googletag
            .pubads()
            .getSlots()
            .filter(slot => slot.getAdUnitPath() === adUnitPath);

          googletag.destroySlots(slots);
        });
      };
    }
  }, [adUnitPath, experiment, id, onDisplay, screenSize, toggleDisplayAd, trail, user?.pro]);

  return (
    <div className={classNames(styles.ad, className)} style={{ display: !displayAd && 'none' }}>
      <Typography className={styles.advertisement} color="secondary" variant="text100">
        <FormattedMessage defaultMessage="Advertisement" />
      </Typography>
      <div id={id} />
      <Button
        className={classNames(styles.button, buttonClassName)}
        linkInfo={{ href: wrapUrlSafe('/plus', languageRegionCode) }}
        size="sm"
        testId="go-ad-free"
        text={<FormattedMessage defaultMessage="Go ad-free with AllTrails+" />}
        variant={buttonVariant}
      />
    </div>
  );
}
