import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import type Trail from 'types/Trails/Trail';
import * as styles from './styles/styles.module.scss';
import { wrapEventHandler } from '../../../utils/handlers';
import { getTrailLocationUrl, getTrailLocationInfo, getLocationName } from '../../../utils/location_parsing';

/**
 * wrapLocationClick wraps the CardLocation link's onClick handler. At the time
 * the event is fired the trail's area information will be parsed and the
 * preferred trail location information will be sent to handleClick.
 *
 * @param {*} trail
 * @param {(string: locationString, string: locationType) => void} handleClick
 * @returns
 */
const wrapLocationClick = (trail: Trail, handleClick: (locationString: string, locationType: string) => void) => (e: any) => {
  e.preventDefault();
  e.stopPropagation();
  const [locationString, locationType] = getTrailLocationInfo(trail);
  handleClick(locationString, locationType);
  return false;
};

type Props = {
  className: string;
  handleClick?: () => void;
  trail: Trail;
};

export default function CardLocation({ trail, handleClick = null, className }: Props) {
  const languageRegionCode = useLanguageRegionCode();
  const displayName = getLocationName(trail);
  if (!displayName) {
    return null;
  }

  const locationUrl = getTrailLocationUrl(trail, languageRegionCode);

  // Overrides the default click behavior of the link
  let onClick;
  if (handleClick) {
    onClick = wrapLocationClick(trail, handleClick);
  } else if (locationUrl) {
    onClick = wrapEventHandler(() => {
      window.open(locationUrl, '_blank');
    });
  }

  return (
    <a className={`xlate-none ${styles.location} ${className}`} href={locationUrl} onClick={onClick} title={displayName}>
      {displayName}
    </a>
  );
}
