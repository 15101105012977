import { loadAndAddImage } from '../image_helpers';
import logError from 'utils/logError';
import * as styles from './pins.module.scss';
import green from '../../../../assets/images/icons/maps/pins/map-pin-green@2x.png';
import greenHover from '../../../../assets/images/icons/maps/pins/map-pin-green-hover@2x.png';
import gray from '../../../../assets/images/icons/maps/pins/map-pin-gray@2x.png';
import grayHover from '../../../../assets/images/icons/maps/pins/map-pin-gray-hover@2x.png';
import red from '../../../../assets/images/icons/maps/pins/map-pin-red@2x.png';
import redHover from '../../../../assets/images/icons/maps/pins/map-pin-red-hover@2x.png';
import blue from '../../../../assets/images/icons/maps/pins/map-pin-blue@2x.png';
import blueHover from '../../../../assets/images/icons/maps/pins/map-pin-blue-hover@2x.png';
import brown from '../../../../assets/images/icons/maps/pins/map-pin-brown@2x.png';
import brownHover from '../../../../assets/images/icons/maps/pins/map-pin-brown-hover@2x.png';
import teal from '../../../../assets/images/icons/maps/pins/map-pin-teal@2x.png';
import tealHover from '../../../../assets/images/icons/maps/pins/map-pin-teal-hover@2x.png';
import debuggerPinImage from '../../../../assets/images/icons/maps/pins/map-pin-debugger@2x.png';
import debuggerPinImageHover from '../../../../assets/images/icons/maps/pins/map-pin-debugger-hover@2x.png';
import { BLUE, BROWN, GRAY, GREEN, RED, DEBUGGER, TEAL } from '../../constants/PinColor';

function getPinSrcs(color) {
  switch (color) {
    case GREEN:
      return { notHover: green, hover: greenHover };
    case GRAY:
      return { notHover: gray, hover: grayHover };
    case RED:
      return { notHover: red, hover: redHover };
    case BLUE:
      return { notHover: blue, hover: blueHover };
    case BROWN:
      return { notHover: brown, hover: brownHover };
    case TEAL:
      return { notHover: teal, hover: tealHover };
    case DEBUGGER:
      return { notHover: debuggerPinImage, hover: debuggerPinImageHover };
    default:
      throw Error(`missing pin srcs for color '${color}'`);
  }
}

const mapPinImageId = colorAndHover => {
  return `map-pin-${colorAndHover}`;
};

const mapPinImg = (color, opacity) => {
  const img = document.createElement('img');
  img.alt = 'pin';
  img.crossOrigin = 'anonymous';
  img.className = styles.pin;

  if (opacity) {
    img.style.opacity = opacity;
  }

  const srcs = getPinSrcs(color);

  img.onmouseover = () => {
    img.src = srcs.hover;
  };

  img.onmouseout = () => {
    img.src = srcs.notHover;
  };

  img.src = srcs.notHover;

  return img;
};

// This governs the offset [x, y] of the popover (the trail hover card) from the pin.
const pinPopupOffset = [0, -20];

const addPinImageToMap = (map, color) => {
  const srcs = getPinSrcs(color);
  const imageId = mapPinImageId(color);
  return loadAndAddImage(map, imageId, srcs.notHover, {
    pixelRatio: 2
  }).catch(logError);
};

const addHoverPinImageToMap = (map, color) => {
  const srcs = getPinSrcs(color);
  const imageId = mapPinImageId(`${color}-hover`);
  return loadAndAddImage(map, imageId, srcs.hover, {
    pixelRatio: 2
  }).catch(logError);
};

const createPinLayer = (id, sourceId, colorAndHover, opacity) => {
  return {
    id,
    type: 'symbol',
    source: sourceId,
    filter: ['!', ['has', 'point_count']],
    layout: {
      'icon-allow-overlap': true,
      'icon-anchor': 'bottom',
      // The offset was found manually with the DEBUGGER pin icon to help align things ideally on the map.
      // The exact bottom of the tip of the pin image (not including the shadow) should rest at the lat/lng of the data.
      'icon-offset': [GREEN, TEAL].includes(colorAndHover) ? [0, 7] : [0, 0],
      'icon-ignore-placement': true,
      'icon-image': ['image', mapPinImageId(colorAndHover)]
    },
    paint: {
      'icon-opacity': opacity || 1
    }
  };
};

export { mapPinImg, mapPinImageId, addPinImageToMap, createPinLayer, pinPopupOffset, addHoverPinImageToMap };
