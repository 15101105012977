import Router, { Switch, Route, useHistory, useParams } from '@alltrails/shared/components/ReactRouter';
import MinimalLayout from 'components/MinimalLayout';
import CustomProvider from 'components/CustomProvider';
import ReferralDownloadApp from './ReferralDownload';
import ReferralWelcome from './ReferralWelcome';
import LoginSignupHero from 'components/LoginSignupLayout';
import SignUpForm from 'components/SignUpForm';

function SignupRoute() {
  const history = useHistory();
  const { referralLink } = useParams<{ referralLink: string }>();

  function handleRegistrationSuccess() {
    history.push('download');
  }

  return (
    <LoginSignupHero>
      <SignUpForm handleRegistrationSuccess={handleRegistrationSuccess} referralLink={referralLink} />
    </LoginSignupHero>
  );
}

interface ReferralRouterProps {
  firstName: string;
  fullpath: string;
}

function ReferralRouter({ firstName, fullpath }: ReferralRouterProps) {
  return (
    <CustomProvider>
      <MinimalLayout>
        <Router fullpath={fullpath}>
          <Switch>
            <Route path="/:locale?/invite/:referralLink" exact>
              <ReferralWelcome firstName={firstName} />
            </Route>
            <Route path="/:locale?/invite/:referralLink/signup" exact>
              <SignupRoute />
            </Route>
            <Route path="/:locale?/invite/:referralLink/download" exact>
              <ReferralDownloadApp />
            </Route>
          </Switch>
        </Router>
      </MinimalLayout>
    </CustomProvider>
  );
}

export default ReferralRouter;
