import { getItemsInListOfType, verifiedListId } from '@alltrails/modules/Lists/listUtils';
import { PageStrings } from '@alltrails/shared/utils/constants/pageStringHelpers';
import { defineMessages } from '@alltrails/shared/react-intl';
import hasPermission from 'utils/hasPermission';
import { ServerCommunicationUtil } from '../../../../utils/server_communication_util';
import { SearchFiltersUtil } from '../../../../utils/search_filters_util';

const messages = defineMessages({
  addToCompleted: { defaultMessage: 'Add to completed' },
  removeFromCompleted: { defaultMessage: 'Remove from completed' },
  addToFavorites: { defaultMessage: 'Add to favorites' },
  removeFromFavorites: { defaultMessage: 'Remove from favorites' },
  addToList: { defaultMessage: 'Add to list' },
  removeFromList: { defaultMessage: 'Remove from list' },
  delete: { defaultMessage: 'Delete' },
  deleteConfirmation: { defaultMessage: 'Are you sure you want to delete this from AllTrails?' }
});

// Used by SearchApp (SearchMap/SearchResultList) and by FullScreenSearchResultList
const ResultCardFunctionsMixin = {
  allowAddRemove() {
    // Only allow on completed page, when admin or current user
    return (
      [
        PageStrings.EXPLORE_COMPLETED_PAGE,
        PageStrings.EXPLORE_FAVORITE_PAGE,
        PageStrings.EXPLORE_CUSTOM_PAGE,
        PageStrings.EXPLORE_USERS_TRACKS_PAGE,
        PageStrings.EXPLORE_USERS_MAPS_PAGE
      ].includes(this.state.page) &&
      this.props.context.currentUser &&
      this.props.userInfo &&
      // eslint-disable-next-line eqeqeq
      (hasPermission({ permission: 'trails:manage' }) || this.props.context.currentUser.id == this.props.userInfo.id)
    );
  },
  allowClickingCompletedBadge() {
    // Only allow on completed page, when admin
    return (
      this.state.page === PageStrings.EXPLORE_COMPLETED_PAGE &&
      this.props.context &&
      this.props.context.currentUser &&
      hasPermission({ permission: 'trails:manage' })
    );
  },
  forcePhotoId(type, objectId) {
    if (['map', 'track'].indexOf(type) === -1) {
      return null;
    }
    // Currently only works for map/track photos
    return this.state.forceTrackPhotoIds ? this.state.forceTrackPhotoIds[objectId] : this.props.initialPhotoId;
  },
  getAddRemoveLabels() {
    switch (this.state.page) {
      case PageStrings.EXPLORE_COMPLETED_PAGE:
        return {
          add: this.props.intl.formatMessage(messages.addToCompleted),
          remove: this.props.intl.formatMessage(messages.removeFromCompleted)
        };
      case PageStrings.EXPLORE_FAVORITE_PAGE:
        return {
          add: this.props.intl.formatMessage(messages.addToFavorites),
          remove: this.props.intl.formatMessage(messages.removeFromFavorites)
        };
      case PageStrings.EXPLORE_CUSTOM_PAGE:
        return {
          add: this.props.intl.formatMessage(messages.addToList),
          remove: this.props.intl.formatMessage(messages.removeFromList)
        };
      case PageStrings.EXPLORE_USERS_TRACKS_PAGE:
      case PageStrings.EXPLORE_USERS_MAPS_PAGE:
        return {
          add: null,
          remove: this.props.intl.formatMessage(messages.delete)
        };
      default:
        return {};
    }
  },
  handleAddRemoveClick(type, objectId) {
    switch (this.state.page) {
      case PageStrings.EXPLORE_COMPLETED_PAGE:
      case PageStrings.EXPLORE_FAVORITE_PAGE:
      case PageStrings.EXPLORE_CUSTOM_PAGE:
        return this.props.listMethods.toggleListItem(this.props.listId, type, objectId);
      case PageStrings.EXPLORE_USERS_TRACKS_PAGE:
      case PageStrings.EXPLORE_USERS_MAPS_PAGE:
        return this.handleDeleteMapClick(type, objectId);
      default:
        return null;
    }
  },
  handleDeleteMapClick(type, objectId) {
    if (!confirm(this.props.intl.formatMessage(messages.deleteConfirmation))) {
      return;
    }
    const url = `/api/alltrails/maps/${objectId}`;
    ServerCommunicationUtil.deleteApiEndpoint(
      url,
      {},
      () => {
        this.setState(prevState => ({
          results: prevState.results.filter(result => result.ID !== objectId),
          atMaps: prevState.atMaps.filter(map => map.ID !== objectId)
        }));
      },
      () => {},
      () => {}
    );
  },
  handleCompletedBadgeClick(type, objectId) {
    if (hasPermission({ permission: 'trails:manage' })) {
      // Only allow admins to manually verify ANY user's trails
      let userId;
      if (
        this.state.page === PageStrings.EXPLORE_COMPLETED_PAGE &&
        this.props.context.currentUser &&
        hasPermission({ permission: 'trails:manage' }) &&
        this.props.userInfo &&
        // eslint-disable-next-line eqeqeq
        this.props.context.currentUser.id != this.props.userInfo.id
      ) {
        userId = this.props.userInfo.id;
      } else {
        userId = null;
      }
      this.props.listMethods.toggleListItem(verifiedListId, type, objectId, userId);
    }
  },
  isItemInPageList(type, objectId) {
    switch (this.state.page) {
      case PageStrings.EXPLORE_FAVORITE_PAGE:
        return this.props.listMethods.isFavorite(type, objectId);
      case PageStrings.EXPLORE_USERS_TRACKS_PAGE:
      case PageStrings.EXPLORE_USERS_MAPS_PAGE:
        return true;
      default:
        return this.props.listMethods.isItemInList(type, objectId, this.props.listId);
    }
  },
  // Updates filters/results in state based on list items added/removed ported from the old ListHashMixin
  updateResultsFromListHash({ action, listItem }, listItems, customMaps, setCustomMaps) {
    const { listId } = this.props;
    let results = [...this.state.results];
    let updatedCustomMaps = customMaps;
    if (results) {
      if (parseInt(listItem.listId) === parseInt(listId)) {
        // Add/Remove from results
        results = results.filter(result => result.objectID !== `${listItem.type}-${listItem.objectId}`);
        if (action === 'add' && listItem.object) {
          // Add object onto the front of results so it appears at the top of the search result list on explore pages
          // Note: an item (currently) can't be added to a list from the list SEO page
          results.unshift(listItem.object);
        }
      }
    }
    if (updatedCustomMaps) {
      if (parseInt(listItem.listId) === parseInt(listId)) {
        // add/remove
        if (listItem.type === 'track' || listItem.type === 'map') {
          updatedCustomMaps = updatedCustomMaps.filter(result => result.objectID !== `${listItem.type}-${listItem.objectId}`);
          if (action === 'add' && listItem.object) {
            updatedCustomMaps.unshift(listItem.object);
          }
          setCustomMaps(updatedCustomMaps);
        }
      }
    }

    const { filters } = this.state;
    if (filters) {
      const trailIds = Object.keys(getItemsInListOfType(listItems, listId, 'trail'));
      SearchFiltersUtil.setOrToggleFilter(filters, 'trailIds', trailIds);
    }

    if (results || filters) {
      const afterSetState = this.performSearch ? this.performSearch(updatedCustomMaps) : () => {};
      this.setState(
        {
          filters,
          results
        },
        afterSetState
      );
    }
  },
  // Catch-All for functions in this mixin
  // Keep this function at the bottom of this mixin
  getResultCardFunctions() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this; // prevents weird scoping of `this` when inside object in ES6
    return {
      // Displaying photos
      forcePhotoId: self.forcePhotoId,
      // Displaying buttons
      allowClickingCompletedBadge: self.allowClickingCompletedBadge,
      allowAddRemove: self.allowAddRemove,
      // Filling button states
      getAddRemoveLabels: self.getAddRemoveLabels,
      isItemInPageList: self.isItemInPageList,
      // Handle clicking on entire result card (to best passed as handleCardClick)
      // These should be defined outside of the ResultCardFunctionsMixin
      handleAddRemoveClick: self.handleAddRemoveClick,
      handleMapClick: self.handleMapClick,
      handleTrackClick: self.handleTrackClick,
      handleTrailClick: self.handleTrailClick,
      formatTrailUrl: self.formatTrailUrl,
      // Handle clicking on buttons on result card
      handleCompletedBadgeClick: self.handleCompletedBadgeClick,
      handleFavoriteClick: self.handleFavoriteClick
    };
  }
};

// eslint-disable-next-line import/prefer-default-export
export { ResultCardFunctionsMixin };
