import { useState, useEffect } from 'react';
import { defineMessages } from '@alltrails/shared/react-intl';
import classNames from 'classnames';
import useFormatMessage from '@alltrails/shared/hooks/useFormatMessage';
import Select from '@alltrails/shared/denali/components/Select';
import { useTrailUgcContext } from 'components/trailUgc/TrailUgcContext';
import type { TrailId } from '@alltrails/shared/types/trail';
import SortOption from 'components/trailUgc/types/SortOption';

import * as styles from './styles/styles.module.scss';

const messages = defineMessages({
  SORT_BY: { defaultMessage: 'Sort by' },
  AT_SORT: { defaultMessage: 'AllTrails sort' },
  DESC: { defaultMessage: 'Newest first' },
  ASC: { defaultMessage: 'Oldest first' },
  HIGHEST_RATED: { defaultMessage: 'Highest rated' },
  LOWEST_RATED: { defaultMessage: 'Lowest rated' }
});

export type UgcSortDropdownProps = {
  enabledSortLinks: SortOption[];
  hideSortLabel?: boolean;
  selectedSort: SortOption;
  onSortSelected: (newSort: SortOption) => void;
};

const UgcSortDropdown = ({ enabledSortLinks, hideSortLabel, selectedSort, onSortSelected }: UgcSortDropdownProps): JSX.Element => {
  const {
    formattedDefaultMessages: { SORT_BY, AT_SORT, DESC, ASC, HIGHEST_RATED, LOWEST_RATED }
  } = useFormatMessage(messages);
  const [selectedSortOption, updateSelectSort] = useState(selectedSort);

  useEffect(() => {
    if (selectedSort && selectedSortOption !== selectedSort) {
      updateSelectSort(selectedSort);
    }
  }, [selectedSort, selectedSortOption]);

  const handleSortClick = (newSort: SortOption) => {
    updateSelectSort(newSort);
    onSortSelected(newSort);
  };

  const getSortOptions = () => {
    const linksMap = {
      atSort: AT_SORT,
      desc: DESC,
      asc: ASC,
      highestRated: HIGHEST_RATED,
      lowestRated: LOWEST_RATED
    };

    return enabledSortLinks.map(link => ({
      label: String(linksMap[link]),
      value: link
    }));
  };

  const options = getSortOptions();
  return (
    <div className={classNames('xlate-none', styles.container)}>
      <div className={styles.labelAndSelect}>
        {!hideSortLabel && <div className={styles.label}>{SORT_BY}:</div>}
        <Select
          className={styles.select}
          options={options}
          placeholder={SORT_BY}
          value={selectedSortOption}
          onChange={value => handleSortClick(value as SortOption)}
          labelText={String(options[Number(Object.keys(options)[0])])}
          hideLabel
          testId="sort"
          size="sm"
        />
      </div>
    </div>
  );
};

export default UgcSortDropdown;
