import { string, func } from 'prop-types';
import { useIntl, defineMessages } from '@alltrails/shared/react-intl';
import * as styles from './styles/styles.module.scss';
import IconButton from '@alltrails/shared/denali/components/IconButton';
import Expand from '@alltrails/shared/icons/Expand';
import { wrapEventHandler } from '../../../utils/handlers';

const messages = defineMessages({ altExpand: { defaultMessage: 'Expand map' } });

const StaticMapImage = ({ url, onClick }) => {
  const intl = useIntl();
  const altExpand = intl.formatMessage(messages.altExpand);
  return (
    <a className={styles.container} href={url} onClick={wrapEventHandler(onClick)}>
      <img className={styles.staticMapImage} src={url} alt="mapping routes" />
      <IconButton
        icon={{ Component: Expand, orientation: 'right' }}
        title={altExpand}
        size="lg"
        variant="elevated"
        testId="viewFullMap"
        className={styles.viewFullMap}
      />
    </a>
  );
};

StaticMapImage.propTypes = {
  url: string.isRequired,
  onClick: func.isRequired
};

export default StaticMapImage;
