import { useRef } from 'react';
import { defineMessages, FormattedMessage } from '@alltrails/shared/react-intl';
import useFormatMessage from '@alltrails/shared/hooks/useFormatMessage';
import IconButton from '@alltrails/shared/denali/components/IconButton';
import Plus from '@alltrails/shared/icons/Plus';
import Check from '@alltrails/shared/icons/Check';
import Link from '@alltrails/shared/denali/components/Link';
import * as styles from './FileUpload.module.scss';

const FILE_UPLOAD_STRINGS = defineMessages({
  DRAG_SUPPORTED: {
    defaultMessage: 'Drag supported file here or'
  },
  BROWSE_FILES: {
    defaultMessage: 'browse files'
  }
});

type FileUploadProps = {
  fileName: string;
  fileSize: string;
  fileAttached: boolean;
  handleChange: () => void;
  handleRemove: () => void;
  fileKey: string;
};

// Desktop: https://www.figma.com/file/gZaqAej8HzoFkyrjlQ4cNs/Integrating-Brand-Changes?node-id=9429%3A96530
const FileUpload = ({ fileName, fileSize, fileAttached, handleChange, handleRemove, fileKey }: FileUploadProps) => {
  const {
    formattedDefaultMessages: { DRAG_SUPPORTED, BROWSE_FILES }
  } = useFormatMessage(FILE_UPLOAD_STRINGS);

  const fileInputRef = useRef(null);

  const handleFileClick = () => {
    if (fileAttached) {
      // File already attached
      return;
    }
    fileInputRef.current?.click();
  };

  let icon = (
    <IconButton
      icon={{ Component: Plus }}
      onClick={handleFileClick}
      testId="converter-upload-icon"
      title={BROWSE_FILES}
      size="sm"
      variant="primary"
    />
  );
  let body = (
    <div className={styles.body}>
      <span>{DRAG_SUPPORTED}</span>{' '}
      <Link onClick={handleFileClick} size="md">
        {BROWSE_FILES}
      </Link>
    </div>
  );

  if (fileAttached) {
    icon = <IconButton icon={{ Component: Check }} testId="converter-upload-success-icon" size="sm" variant="primary" title={fileName} />;
    body = (
      <div className={styles.body}>
        <div className={styles.fileName}>{fileName}</div>
        <div className={styles.fileDetails}>
          <FormattedMessage defaultMessage="File size:" /> {fileSize}
        </div>
        <Link onClick={handleRemove} size="sm">
          <FormattedMessage defaultMessage="Remove file" />
        </Link>
      </div>
    );
  }
  return (
    <div className={styles.fileUploadWrapper}>
      <div className={styles.content}>
        <div>{icon}</div>
        <input type="file" key={fileKey} className={styles.hidden} id="file" ref={fileInputRef} onChange={handleChange} />
        {body}
      </div>
    </div>
  );
};

export default FileUpload;
