import { useRef } from 'react';
import logTrailDetailsDistinctTrailPhotosViewed from '@alltrails/analytics/events/logTrailDetailsDistinctTrailPhotosViewed';
import TrailDetailsPhotoSource from '@alltrails/analytics/enums/TrailDetailsPhotoSource';
import type { TrailId } from '@alltrails/shared/types/trail';

type Args = {
  source: TrailDetailsPhotoSource;
  trailId: TrailId;
};

export default function useTrailAggregatePhotoViewed(args?: Args): [(photoId: number) => void, () => void] {
  const uniquePhotos = useRef(new Set<number>());
  const photosViewedCount = useRef(0);

  const onPhotoViewed = (photoId: number) => {
    if (!uniquePhotos.current.has(photoId)) {
      photosViewedCount.current += 1;
      uniquePhotos.current.add(photoId);
    }
  };

  const onViewingComplete = () => {
    if (args) {
      logTrailDetailsDistinctTrailPhotosViewed({ distinct_photos_viewed: photosViewedCount.current, source: args.source, trail_id: args.trailId });
    }

    uniquePhotos.current.clear();
    photosViewedCount.current = 0;
  };

  return [onPhotoViewed, onViewingComplete];
}
