import { ServerCommunicationUtil } from 'utils/server_communication_util';

// API under app/controllers/api/alltrails/default/maps/elevation_profile_controller

export type ElevationPoint = {
  elevation: number;
  location: {
    latitude: number;
    longitude: number;
  };
};

export type SmoothedElevationPoint = {
  elevation: number;
  index: number;
};

export type MapElevationProfileResponse = {
  elevations: ElevationPoint[];
  meta: {
    status: string;
    items: number;
    timestamp: string;
  };
  stats: {
    gain: number;
    distance: number;
  };
  smoothedElevations: SmoothedElevationPoint[];
};

export default function fetchMapElevationProfile(mapId: number): Promise<MapElevationProfileResponse> {
  return fetch(`/api/alltrails/v1/maps/${mapId}/elevation_profile`, {
    headers: { 'Content-Type': 'application/json', ...ServerCommunicationUtil.defaultAuthHeaders() }
  }).then(response => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response.json();
  });
}
