import { RED } from 'utils/constants/MarkerIds';
import PhotoPopup from '../../../components/shared/MapPopups/PhotoPopup';
import { addMarkers, removeMarkers } from './markers';
import { addPinImageToMap, mapPinImageId, pinPopupOffset } from '../layers/pins';
import { getTrailsPhotos } from '../../requests/trail_requests';

const addPhotos = (map, id, photos, handleClick, bounds = null) => {
  const renderPhotoPopup = photo => {
    const handlePhotoClick = handleClick ? () => handleClick(photo.id, photos) : undefined;
    return <PhotoPopup photo={photo} handleClick={handlePhotoClick} />;
  };
  addMarkers(map, id, photos, RED, renderPhotoPopup, bounds);
};

const updateProfilePhoto = (map, photos, profilePhoto) => {
  // eslint-disable-next-line eqeqeq
  const index = photos.findIndex(photo => photo.id == profilePhoto.id);
  // eslint-disable-next-line eqeqeq
  if (index != -1) {
    addPinImageToMap(map, RED);
    photos[index].iconAnchor = 'bottom';
    photos[index].iconImage = mapPinImageId(RED);
    photos[index].popupOffset = pinPopupOffset;
    // Move the profile photo to the end of the array so its marker is rendered above other photos
    photos.push(photos.splice(index, 1)[0]);
  }
};

const addCommunityPhotos = (map, id, exploreMap, profilePhoto, handleClick, isAdmin, bounds = null) => {
  getTrailsPhotos(exploreMap.trailId).then(photos => {
    // If viewing a trail map, do not show UGC that is from this map.
    const excludePhotoIds = exploreMap.mapPhotos.map(mapPhoto => mapPhoto.photo.id);
    photos = photos.filter(photo => !excludePhotoIds.includes(photo.id));
    if (profilePhoto && isAdmin) {
      updateProfilePhoto(map, photos, profilePhoto);
    }
    addPhotos(map, id, photos, handleClick, bounds);
  });
};

const removePhotos = (map, id) => {
  removeMarkers(map, id);
};

export { addPhotos, addCommunityPhotos, removePhotos };
