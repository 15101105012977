import mapboxgl from 'mapbox-gl';
import MapPopup from '../../../components/shared/MapPopups/MapPopup';
import { addMarkers } from './markers';
import { mountReactComponent } from '../event_handling/popup_helpers';
import { formattedDate } from '@alltrails/shared/utils/timeHelpers';
import { END, START } from 'utils/constants/MarkerIds';

const ACTIVE = 'A';
const getStartAndEndDate = (start, end, timezone) => {
  const startDate = formattedDate(new Date(start[3] * 1000), timezone);
  const endDate = formattedDate(new Date(end[3] * 1000), timezone);

  return [startDate, endDate];
};

const startEndPopup = ({ name, location, dateString }) => {
  if (typeof location === 'string') {
    location = JSON.parse(location);
  }

  return <MapPopup name={name} latitude={location.latitude} longitude={location.longitude} dateString={dateString} />;
};

// Added as a Mapbox marker instead of as a layer in order to more easily create the "pulse" effect
// Note: in the future it may be possible to do something like the example below, where the "pulse" is done in canvas
// https://docs.mapbox.com/mapbox-gl-js/example/add-image-animated/
const addLiveLocationMarker = (map, id, latitude, longitude, dateString) => {
  const markerData = {
    name: 'Last Location',
    location: {
      latitude,
      longitude
    },
    dateString
  };

  const popup = new mapboxgl.Popup({
    closeButton: false
  }).setDOMContent(mountReactComponent(startEndPopup(markerData)));

  const element = document.createElement('div');
  const div = document.createElement('div');
  div.id = 'live-location-icon';
  element.appendChild(div);

  const marker = new mapboxgl.Marker({
    id,
    element
  })
    .setLngLat([longitude, latitude])
    .addTo(map)
    .setPopup(popup);

  const togglePopup = () => {
    marker.togglePopup();
  };

  const markerElement = marker.getElement();
  markerElement.addEventListener('mouseenter', togglePopup);
  markerElement.addEventListener('mouseleave', togglePopup);
};

const addLifelineStartEnd = (map, id, multiCoordinates, lifeline) => {
  const startEndMarkers = [];
  multiCoordinates.forEach((coordinates, index) => {
    if (coordinates.length < 1) {
      return;
    }
    const isLastSetOfCoordinates = index === multiCoordinates.length - 1;
    const startCoord = coordinates[0];
    const [startLng, startLat] = startCoord;
    const endCoord = coordinates[coordinates.length - 1];
    const [endLng, endLat] = endCoord;
    const [startDate, endDate] = getStartAndEndDate(startCoord, endCoord, lifeline.metadata.timezoneIdentifier);

    startEndMarkers.push({
      name: 'Start',
      location: {
        latitude: startLat,
        longitude: startLng
      },
      iconImage: 'map-marker-start',
      dateString: startDate
    });

    // only show live location marker if lifeline is ongoing and this is the last segment
    if (isLastSetOfCoordinates && lifeline.metadata.status === ACTIVE) {
      addLiveLocationMarker(map, id, endLat, endLng, endDate);
    } else {
      startEndMarkers.push({
        name: 'End',
        location: {
          latitude: endLat,
          longitude: endLng
        },
        iconImage: 'map-marker-end',
        dateString: endDate
      });
    }
  });

  addMarkers(map, id, startEndMarkers, [START, END], startEndPopup);
};

export { addLifelineStartEnd };
