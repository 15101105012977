import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import ShareSource from '@alltrails/analytics/enums/ShareSource';
import { defineMessages } from '@alltrails/shared/react-intl';
import useActivityStrings from '@alltrails/shared/hooks/useActivityStrings';
import useFormatMessage from '@alltrails/shared/hooks/useFormatMessage';
import type { Context } from 'types/Context';
import { ExploreMap } from '@alltrails/shared/types/map';
import type Trail from 'types/Trails/Trail';
import PortalModal from 'components/shared/PortalModal';
import ActivityUploadModal from '@alltrails/modules/ActivityUpload/ActivityUploadModal';
import { modalRoadblock } from '@alltrails/shared/utils/modalFunnelUtils';
import { getEditTrackParams, EditTrackResponse } from '@alltrails/modules/ActivityUpload/requests';
import PrivacyMapUpdateModal from '@alltrails/modules/Privacy/PrivacyMapUpdateModal';
import ReportIssuePage from '@alltrails/modules/Reporting/ReportIssuePage';
import ReportLocation from '@alltrails/analytics/enums/ReportLocation';
import ReportContentType from '@alltrails/analytics/enums/ReportContentType';
import { EntryPoint } from '@alltrails/modules/Reporting/types';
import SuccessToast from '@alltrails/shared/types/successToast';
import '@alltrails/modules/Reporting/sharedCss.css';
import '@alltrails/modules/Privacy/sharedCss.css';
import { VISIBILITY_PRIVATE } from 'utils/privacy_policy_helpers';
import TagalongConfirmationModal from 'components/TagalongConfirmationModal/TagalongConfirmationModal';
import ListMapTaggedUsersResponse from 'types/ListMapTaggedUsersResponse';
import { get } from '@alltrails/shared/api';
import { AvatarProps } from '@alltrails/shared/denali/components/Avatar';
import { LanguageSupportUtil } from '../../../utils/language_support_util';
import logError from '../../../utils/logError';
import ActionHeader from '../../shared/ActionHeader';
import DeleteModal from '../../DeleteModal';
import DownloadRouteModal from '../../DownloadRouteModal';

type Props = {
  forcePhotoId?: string;
  track?: {
    type: string;
    slug: string;
  };
  context?: Context;
  data?: any;
  blockNonUserCreation?: (ref: string) => void;
  handleShareClick?: (something: unknown) => void;
  messagingChannel?: {
    publish: (
      event: string,
      track: {
        editingEndpoint: boolean;
        canceled?: boolean;
      }
    ) => void;
  };
  isFavorite?: boolean;
  handleFavoriteClick?: () => void;
  saveMapAsClone?: (data: any) => void;
  handleTrackUpdate?: (payload: any) => void;
  height?: number;
  isMobileWidth?: boolean;
  linkable?: boolean;
  showStaticMap?: boolean;
  tabletBrowser?: boolean;
  width?: number;
  handleRatingChange?: (e: React.ChangeEvent<HTMLInputElement>, rating: number) => void;
  rating?: number;
  trackTrail?: Trail;
  canEdit?: boolean;
  setReportingSuccessToast: Dispatch<SetStateAction<SuccessToast | null>>;
};

const TRACK_DETAILS_STRINGS = defineMessages({
  ENDPOINT_CONFIRMATION: {
    defaultMessage: 'Are you sure you want to save changes to this activity? Please note, once saved, these changes cannot be undone.'
  }
});

const TrackDetailsCard = ({
  forcePhotoId,
  track,
  context,
  data,
  blockNonUserCreation,
  handleShareClick,
  messagingChannel,
  isFavorite,
  handleFavoriteClick,
  saveMapAsClone,
  handleTrackUpdate,
  handleRatingChange,
  rating,
  trackTrail,
  canEdit,
  setReportingSuccessToast
}: Props): JSX.Element => {
  const {
    formattedDefaultMessages: { ENDPOINT_CONFIRMATION }
  } = useFormatMessage(TRACK_DETAILS_STRINGS);

  const { findByValue } = useActivityStrings();

  const [updatedData, setUpdatedData] = useState(data);
  const [showEditModal, setShowEditModal] = useState(false);
  const [activityModalEditProps, setActivityModalEditProps] = useState(null);
  const [editingEndpoint, setEditingEndpoint] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);
  const [showActivityPrivacyModal, setShowActivityPrivacyModal] = useState(false);
  const [showTagalongConfirmationModal, setShowTagalongConfirmationModal] = useState(false);
  const [taggedAvatars, setTaggedAvatars] = useState<Omit<AvatarProps, 'testId'>[]>([]);

  // Trigger the tagalong modal when the `i=[invite_id]` param exists in the URL.
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const tagalongParam = searchParams.get('i');
    if (tagalongParam && context.currentUser) {
      setShowTagalongConfirmationModal(true);
    }
  }, [context.currentUser]);

  useEffect(() => {
    const fetchTaggedUsers = async () => {
      try {
        const taggedUsers: ListMapTaggedUsersResponse = await get(`/api/alltrails/maps/${updatedData.id}/tagged_users`);
        const avatars = taggedUsers?.map_users?.map(taggedUser => ({
          userId: taggedUser.user.id,
          userName: `${taggedUser.user.firstName} ${taggedUser.user.lastName}`,
          linkInfo: {
            href: `/members/${taggedUser.user.slug}`
          },
          hasPlus: taggedUser.user.pro
        }));

        if (avatars?.length > 0) {
          setTaggedAvatars(avatars);
        }
      } catch (error) {
        logError(error);
      }
    };

    if (updatedData.id) {
      fetchTaggedUsers();
    }
  }, [updatedData.id]);

  const toggleDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal);
  };

  const openEditModal = (activityModalProps: any) => {
    setActivityModalEditProps(activityModalProps);
    setShowEditModal(true);
  };

  const getTrackUrl = () =>
    LanguageSupportUtil.wrapUrlSafe(`/explore${track.type === 'map' ? '/map/' : '/recording/'}${track.slug}`, context.languageRegionCode);

  const handleEditClick = () => {
    getEditTrackParams(`${getTrackUrl()}/edit`)
      .then((result: EditTrackResponse) => {
        result.trackParams.activity = findByValue(result.activityUid);

        openEditModal(result);
      })
      .catch(logError);
  };

  const handleDirectionsClick = () => {
    if (!context.currentUser) {
      blockNonUserCreation('explore-atmap-directions');
    }

    const { location } = updatedData;
    window.open(`https://www.google.com/maps/dir/Current+Location/${location?.latitude},${location?.longitude}`);
  };

  const handleShare = () => {
    if (updatedData.contentPrivacy === VISIBILITY_PRIVATE) {
      setShowActivityPrivacyModal(true);
      return;
    }
    handleShareClick({
      type: updatedData.presentationType,
      ugc: true,
      id: updatedData.id,
      slug: updatedData.slug,
      name: updatedData.name,
      private: updatedData.private,
      primaryKey: 'id',
      source: ShareSource.Activity
    });
  };

  // admin option
  const handleEditEndpointClick = () => {
    if (editingEndpoint && !confirm(ENDPOINT_CONFIRMATION)) {
      return;
    }
    const newState = { editingEndpoint: !editingEndpoint };
    messagingChannel.publish('map.edit-endpoint-state-changed', newState);
    setEditingEndpoint(!editingEndpoint);
  };
  // admin option
  const handleCancelEditEndpointClick = () => {
    if (!editingEndpoint) {
      return;
    }
    messagingChannel.publish('map.edit-endpoint-state-changed', { editingEndpoint: false, canceled: true });
    setEditingEndpoint(false);
  };

  const toggleDownloadModal = () => {
    setShowDownloadModal(!showDownloadModal);
  };

  const openDownloadModal = () => {
    if (!context.currentUser) {
      const returnToUrl = window.location.pathname + window.location.search;
      modalRoadblock('signup', 'explore-gpx-download', returnToUrl, context.languageRegionCode);
    }

    setShowDownloadModal(true);
  };

  const openReportModal = () => {
    if (!context.currentUser) {
      const returnToUrl = window.location.pathname + window.location.search;
      modalRoadblock('signup', 'report-issue', returnToUrl, context.languageRegionCode);
    }

    setShowReportModal(true);
  };

  return (
    <div>
      <ActionHeader
        taggedAvatars={taggedAvatars}
        context={context}
        data={updatedData}
        bannerPhotoId={forcePhotoId}
        isFavorite={isFavorite}
        handleFavoriteClick={handleFavoriteClick}
        handleDirectionsClick={handleDirectionsClick}
        handleShareClick={handleShare}
        saveMapAsClone={() => saveMapAsClone(updatedData)}
        editingEndpoint={editingEndpoint}
        handleEditEndpointClick={handleEditEndpointClick}
        handleCancelEditEndpointClick={handleCancelEditEndpointClick}
        handleEditClick={handleEditClick}
        handleDeleteClick={toggleDeleteModal}
        openDownloadModal={openDownloadModal}
        type="track"
        excludeRatingStructuredData
        handleRatingChange={handleRatingChange}
        rating={rating}
        trackTrail={trackTrail}
        canEdit={canEdit}
        openReportModal={openReportModal}
        setReportingSuccessToast={setReportingSuccessToast}
        onActivityUpdate={(payload: any) => {
          setUpdatedData(payload);
        }}
      />
      {context.currentUser && showDownloadModal && (
        <PortalModal isOpen={context.currentUser && showDownloadModal}>
          <DownloadRouteModal toggleDownloadModal={toggleDownloadModal} mapId={updatedData.id} />
        </PortalModal>
      )}
      {showEditModal && (
        <PortalModal isOpen={showEditModal}>
          <ActivityUploadModal
            {...activityModalEditProps}
            closeModal={() => setShowEditModal(false)}
            user={context.currentUser}
            onTrackAdd={(payload: any) => {
              handleTrackUpdate(payload);
            }}
            disableDropzone
          />
        </PortalModal>
      )}
      {showReportModal && (
        <PortalModal isOpen={showReportModal}>
          <ReportIssuePage
            context={context}
            currentUserId={context.currentUser.id}
            entryPoint={EntryPoint.Maps}
            reportId={updatedData.id}
            reportType="issue"
            reportUser={updatedData.user}
            closeModal={() => setShowReportModal(false)}
            setSuccessToast={setReportingSuccessToast}
            analyticsData={{ location: ReportLocation.ActivityDetails, contentType: ReportContentType.Activity }}
          />
        </PortalModal>
      )}
      <DeleteModal isOpen={showDeleteModal} toggle={toggleDeleteModal} trackId={updatedData.id} userSlug={updatedData?.user?.slug} />
      {showActivityPrivacyModal && updatedData && (
        <PortalModal isOpen={showActivityPrivacyModal}>
          <PrivacyMapUpdateModal
            data={updatedData}
            type="activity"
            closeModal={() => setShowActivityPrivacyModal(false)}
            user={updatedData?.user}
            onUpdate={(data: ExploreMap) => {
              setUpdatedData(data);
            }}
          />
        </PortalModal>
      )}
      {showTagalongConfirmationModal && (
        <TagalongConfirmationModal
          activityOwner={updatedData.user}
          currentUser={context.currentUser}
          isOpen={showTagalongConfirmationModal}
          closeModal={() => setShowTagalongConfirmationModal(false)}
        />
      )}
    </div>
  );
};

export default TrackDetailsCard;
