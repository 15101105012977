import { LanguageSupportUtil } from '../../../../../utils/language_support_util';
import { MapStatsUtil } from '../../../../../utils/map_stats_util';
import { ServerCommunicationUtil } from '../../../../../utils/server_communication_util';
import TrailCard from 'components/cards/TrailCard';
import { decodeNDim } from '../../../../../utils/at_map_helpers';
import { getFormattedTrailUrls } from '../../../../../utils/trail_helpers';
import { FormattedMessage } from '@alltrails/shared/react-intl';
import getHelpCenterUrl from '@alltrails/shared/utils/constants/helpCenterUrl';

const createReactClass = require('create-react-class');

const SECONDS_IN_A_DAY = 86400;
const SECONDS_IN_AN_HOUR = 3600;
const FullscreenLifelineDetails = createReactClass({
  getInitialState() {
    return {
      hideStatusText: null,
      sampledElevationGain: null
    };
  },
  handleTrailClick(trail) {
    const { localizedUrl } = getFormattedTrailUrls(trail, this.props.context.languageRegionCode);
    window.open(localizedUrl, '_blank');
    return false;
  },
  formatDateLocale(date) {
    const tz = this.props.lifeline.metadata.timezoneIdentifier;
    const locale = this.props.context.languageRegionCode || 'en-Us';
    if (tz) {
      return date.toLocaleDateString(locale, {
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        timeZone: tz,
        timeZoneName: 'short'
      });
    }
    return date.toLocaleDateString(locale, { month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' });
  },
  renderLifelineExplanationDiv() {
    return (
      <div id="lifeline-sell-points">
        <div className="logo-title">
          <span className="lifeline-logo" />
          <h3>AllTrails Lifeline</h3>
        </div>
        <div className="sell-pt start-time">
          <p>
            <FormattedMessage defaultMessage="Get the details before they hit the trail. See planned start and end times for their upcoming adventure." />
          </p>
        </div>
        <div className="sell-pt real-time-location">
          <p>
            <FormattedMessage defaultMessage="Get the details before they hit the trail. See planned start and end times for their upcoming adventure." />
          </p>
        </div>
        <div className="sell-pt elevation-gain">
          <p>
            <FormattedMessage defaultMessage="Stay informed and track progress. Follow where they are on an easy-to-read map." />
          </p>
        </div>
        <div className="sell-pt route-complete">
          <p>
            <FormattedMessage defaultMessage="Stay in contact. We'll notify you once they're back safely or if they're running late." />
          </p>
        </div>
      </div>
    );
  },
  renderLifelineNotStartedDiv() {
    const userFirstName = this.props.lifeline.user.firstName;
    return (
      <div className="late-instructions">
        <div className="up-arrow" />
        <div className="green-background">
          <h4>
            <FormattedMessage defaultMessage="Not started yet or no data signal" />
          </h4>
          <p className="last-child">
            <FormattedMessage
              defaultMessage="{name} planned to start this activity by now, but either doesn't have a data connection, or isn't navigating in the AllTrails app yet. Please check back in a bit."
              values={{ name: userFirstName }}
            />
          </p>
        </div>
      </div>
    );
  },
  renderPanicTextDiv() {
    const userFirstName = this.props.lifeline.user.firstName;
    return (
      <div className="late-instructions">
        <div className="up-arrow" />
        <div className="green-background">
          <h4>
            <FormattedMessage defaultMessage="Do not panic" />
          </h4>
          <p>
            <FormattedMessage
              defaultMessage="It is likely {name} is alright. {name}'s phone may have lost connection, battery power, or {name} is running behind and still enjoying the trail."
              values={{ name: userFirstName }}
            />
          </p>
          <h4>
            <FormattedMessage defaultMessage="Emergency services" />
          </h4>
          <p className="last-child">
            <FormattedMessage
              defaultMessage="If you have reason to believe {name} is in danger, contact local authorities associated with the trail.  Please provide the trail information below to local emergency services."
              values={{ name: userFirstName }}
            />
          </p>
        </div>
      </div>
    );
  },
  renderTimeStatusDiv() {
    const timer = new Date();
    const endTime = new Date(this.props.lifeline.endTime);
    const startTime = new Date(this.props.lifeline.startTime);
    let diff = endTime - timer;
    const diffStart = startTime - timer;
    const isBeforeStart = diffStart > 0;

    let timeRemainingContent;
    const clockClasses = 'clock';
    if (isBeforeStart) {
      diff = diffStart;
    } else {
      const overdue = diff < 0;
      if (overdue) {
        diff *= -1;
      }
    }

    const diffInseconds = diff / 1000;
    const minutes = Math.floor((diffInseconds % SECONDS_IN_AN_HOUR) / 60);
    let hours = Math.floor(diffInseconds / SECONDS_IN_AN_HOUR);
    let diffTime;
    // display days and hours when appropriate
    if (diffInseconds >= SECONDS_IN_A_DAY) {
      const days = Math.floor(diffInseconds / SECONDS_IN_A_DAY);
      hours = Math.floor((diffInseconds % SECONDS_IN_A_DAY) / SECONDS_IN_AN_HOUR);
      diffTime = `${days}d ${hours}h ${minutes}m`;
    } else if (diffInseconds >= SECONDS_IN_AN_HOUR) {
      diffTime = `${hours}h ${minutes}m`;
    } else {
      diffTime = `${minutes}m`;
    }

    const clockLabelClasses = 'clock-label';
    let timeRemainingTitle;
    let scheduleStatus;
    let showPanicInfo = false;
    let showNoDataDiv = false;
    if (this.props.lifeline.timeMarkedSafe) {
      const safeTime = new Date(this.props.lifeline.timeMarkedSafe);
      timeRemainingContent = this.formatDateLocale(safeTime);
      scheduleStatus = <FormattedMessage defaultMessage="Finished and reported safe" />;
    } else if (typeof overdue !== 'undefined') {
      timeRemainingTitle = <FormattedMessage defaultMessage="Scheduled end time:" />;
      timeRemainingContent = <FormattedMessage defaultMessage="{diffTime} ago" values={{ diffTime }} />;
      scheduleStatus = <FormattedMessage defaultMessage="Overdue" />;
      showPanicInfo = true;
    } else if (isBeforeStart) {
      timeRemainingTitle = <FormattedMessage defaultMessage="Scheduled start time:" />;
      timeRemainingContent = diffTime;
      if (!(this.props.lifeline.currentPolylines && this.props.lifeline.currentPolylines.length > 0)) {
        scheduleStatus = <FormattedMessage defaultMessage="Not started" />;
      } else {
        scheduleStatus = <FormattedMessage defaultMessage="Activity in progress" />;
      }
    } else {
      timeRemainingTitle = <FormattedMessage defaultMessage="Scheduled end time:" />;
      timeRemainingContent = diffTime;
      if (this.props.lifeline.currentPolylines && this.props.lifeline.currentPolylines.length > 0) {
        scheduleStatus = <FormattedMessage defaultMessage="Activity in progress" />;
      } else {
        scheduleStatus = <FormattedMessage defaultMessage="No data received" />;
        showNoDataDiv = true;
      }
    }

    const inlineStyle = !showPanicInfo && !showNoDataDiv ? { marginBottom: '22px' } : {};

    return (
      <div className="stats-section first header-sec">
        <span className="align-center">
          <div className="stats-label spaced-out">
            <FormattedMessage defaultMessage="STATUS" />
          </div>
          <div className={clockClasses}>{scheduleStatus}</div>
          <div className={clockLabelClasses} style={inlineStyle}>
            {timeRemainingTitle}
            <b>{timeRemainingContent}</b>
          </div>
        </span>
        {showPanicInfo && this.renderPanicTextDiv()}
        {showNoDataDiv && this.renderLifelineNotStartedDiv()}
      </div>
    );
  },
  render() {
    let plannedDistanceElevDiv;
    let plannedSectionClasses = 'stats-section first';
    const { languageRegionCode, displayMetric } = this.props.context;
    if (this.props.data.summaryStats) {
      const stats = this.props.data.summaryStats;

      const mapTotalDistance = MapStatsUtil.metersToFormattedUserUnits(stats.distanceTotal, 2, displayMetric, languageRegionCode);
      const mapElevationGain = MapStatsUtil.elevationFormatted(stats.elevationGain, displayMetric, languageRegionCode);

      plannedDistanceElevDiv = (
        <div className="stats-section last">
          <span className="total-distance stat">
            <div className="stats-label">
              <FormattedMessage defaultMessage="PLANNED DISTANCE" />
            </div>
            <div className="data">{mapTotalDistance}</div>
          </span>
          <span className="elevation-gain stat">
            <div className="stats-label">
              <FormattedMessage defaultMessage="PLANNED ELEVATION GAIN" />
            </div>
            <div className="data">{mapElevationGain}</div>
          </span>
        </div>
      );
    } else {
      plannedSectionClasses += ' extra-padding';
    }

    const style = { height: '100%', bottom: 0, left: 0 };
    let trailCard;
    let inlineTrailCard = null;
    const staticMapUrl = `/api/alltrails/lifeline/sessions/${this.props.lifeline.id}/static_map?size=480x200&key=${ServerCommunicationUtil.apiKey}`;
    const directionsLink = `https://www.google.com/maps/dir/Current+Location/${this.props.data.location.latitude},${this.props.data.location.longitude}`;
    let staticMapInlineDiv = null;
    if (this.props.trackTrail) {
      const itemID = `www.alltrails.com/${this.props.trackTrail.slug}`;
      const inline = true; // Inline by default now - TODO: Remove this crazy logic when moving this page to Cuttlefish
      const className = inline ? 'inline-card' : '';
      const result = this.props.trackTrail;
      trailCard = (
        <div id="trail-card" className={className} itemProp="containedInPlace" itemScope itemType="http://schema.org/LocalBusiness" itemID={itemID}>
          <span className="associated-with-trail">
            <FormattedMessage defaultMessage="Linked trail:" />
          </span>
          <span className="directions">
            <a className="green-link" href={directionsLink} target="_blank">
              <FormattedMessage defaultMessage="Directions to trailhead" />
            </a>
          </span>
          <TrailCard
            handleCardClick={() => this.props.resultCardFunctions.handleTrailClick(result)}
            handleFavoriteClick={() => this.props.listMethods.handleFavoriteClick({ type: result.type, id: result.ID, objectId: result.objectID })}
            isCompleted={this.props.listMethods.isComplete(result.type, result.ID)}
            isFavorite={this.props.listMethods.isFavorite(result.type, result.ID)}
            isVerified={this.props.listMethods.isVerified(result.type, result.ID)}
            key={`trail-${result.ID}`}
            messagingChannel={this.props.messagingChannel}
            trail={result}
            context={this.props.context}
          />
        </div>
      );
      if (inline) {
        inlineTrailCard = trailCard;
        trailCard = null;
      } else {
        style.paddingBottom = this.props.isMobileWidth ? 250 : 120;
      }
    }

    if (this.props.isMobileWidth) {
      const onClick = () => {
        this.props.messagingChannel.publish('map.show');
      };
      let mapExpandText = <FormattedMessage defaultMessage="View planned route" />;
      if (this.props.lifeline && this.props.lifeline.metadata.status === 'S') {
        mapExpandText = <FormattedMessage defaultMessage="See completed activity" />;
      } else if (this.props.lifeline && this.props.lifeline.currentPolylines.length > 0) {
        mapExpandText = <FormattedMessage defaultMessage="See live updates" />;
      }

      staticMapInlineDiv = (
        <div className="static-map link">
          <a onClick={onClick}>
            <img className="track-map-image" src={staticMapUrl} />
          </a>
          <a onClick={onClick} className="full-map-expand">
            <div>{mapExpandText}</div>
          </a>
        </div>
      );
    }

    let userProfilePhoto = null;
    const { user } = this.props.lifeline;
    let userName;
    if (user) {
      userName = `${user.firstName} ${user.lastName}`;
      const profilePhotoUrl = `/api/alltrails/profile_photos/${user.id}/image?size=medium_square&key=${ServerCommunicationUtil.apiKey}`;
      userProfilePhoto = (
        <a
          className="circle-item-profile-link clickable"
          href={LanguageSupportUtil.wrapUrlSafe(`/members/${user.slug}`, languageRegionCode)}
          target="_blank"
          title={userName}
        >
          <img width="75" height="75" className="user-photo" src={profilePhotoUrl} />
        </a>
      );
    }

    const formatStart = this.formatDateLocale(new Date(this.props.lifeline.startTime));
    const formatEnd = this.formatDateLocale(new Date(this.props.lifeline.endTime));

    let lastLocationDiv;
    let liveTitleDiv;
    let liveTotalsDiv;
    let firstTimeDiv;
    if (this.props.lifeline && this.props.lifeline.currentPolylines.length > 0) {
      let pointsObjects = [];
      for (let i = 0; i < this.props.lifeline.currentPolylines.length; i++) {
        const enc_points = this.props.lifeline.currentPolylines[i];
        if (enc_points.length == 0) {
          return;
        }

        const ndimensionalPts = decodeNDim(enc_points);

        pointsObjects = pointsObjects.concat(
          ndimensionalPts.map(function (latLng) {
            return [latLng[0], latLng[1], latLng[2], latLng[3]];
          })
        );
      }
      const lastLoc = pointsObjects[pointsObjects.length - 1];
      const progressTitle =
        this.props.lifeline.metadata.status === 'S' ? (
          <FormattedMessage defaultMessage="Completed activity details" />
        ) : (
          <FormattedMessage defaultMessage="Track progress" />
        );

      liveTitleDiv = (
        <div className="action-bar">
          <menu className="title clearfix overview-menu tabs inline-menu">
            <li className="active">
              <h3>
                <a>{progressTitle}</a>
              </h3>
            </li>
          </menu>
        </div>
      );

      let currTotalDistance = MapStatsUtil.totalDistanceFromPoints(pointsObjects);
      if (displayMetric) {
        currTotalDistance = MapStatsUtil.formatData(currTotalDistance / 1000.0, 1, this.props.intl.formatMessage({ defaultMessage: 'km' }));
      } else {
        currTotalDistance = MapStatsUtil.formatData(
          MapStatsUtil.metersToMiles(currTotalDistance, 5),
          1,
          this.props.intl.formatMessage({ defaultMessage: 'miles' })
        );
      }

      let currElevationGain = this.props.sampledElevationGain
        ? this.props.sampledElevationGain
        : MapStatsUtil.totalElevationFromPoints(pointsObjects);
      if (displayMetric) {
        currElevationGain = MapStatsUtil.formatData(currElevationGain, 0, this.props.intl.formatMessage({ defaultMessage: 'meters' }));
      } else {
        currElevationGain = MapStatsUtil.formatData(
          MapStatsUtil.metersToFeet(currElevationGain, 5),
          0,
          this.props.intl.formatMessage({ defaultMessage: 'feet' })
        );
      }

      const firstStartDate = pointsObjects[0] ? this.formatDateLocale(new Date(pointsObjects[0][3] * 1000)) : '';
      const lastRecordedDate = pointsObjects[pointsObjects.length - 1]
        ? this.formatDateLocale(new Date(pointsObjects[pointsObjects.length - 1][3] * 1000))
        : '';

      firstTimeDiv = (
        <div className="stats-section first">
          <span className="start-time stat">
            <div className="stats-label">
              <FormattedMessage defaultMessage="ACTUAL START TIME" />
            </div>
            <div className="data">{firstStartDate}</div>
          </span>
          <span className="stop-time stat">
            <div className="stats-label">
              <FormattedMessage defaultMessage="LAST RECORDED TIME" />
            </div>
            <div className="data">{lastRecordedDate}</div>
          </span>
        </div>
      );

      liveTotalsDiv = (
        <div className="stats-section">
          <span className="total-distance stat">
            <div className="stats-label">
              <FormattedMessage defaultMessage="ACTUAL DISTANCE" />
            </div>
            <div className="data">{currTotalDistance}</div>
          </span>
          <span className="elevation-gain stat">
            <div className="stats-label">
              <FormattedMessage defaultMessage="ACTUAL ELEVATION GAIN" />
            </div>
            <div className="data">{currElevationGain}</div>
          </span>
        </div>
      );

      if (typeof lastLoc != 'undefined') {
        lastLocationDiv = (
          <div className="stats-section last">
            <span className="real-time-location stat">
              <div className="stats-label">
                <FormattedMessage defaultMessage="LAST RECORDED LOCATION" />
              </div>
              <div className="data">{`${lastLoc[0]}, ${lastLoc[1]}`}</div>
            </span>
          </div>
        );
      }
    }

    return (
      <div id="fullscreen-track-details" className="xlate-none" style={style}>
        <meta itemProp="image" content={staticMapUrl} />
        <div className="inner-track-details">
          <div id="total-stats">
            <div className="lifeline-sub-header">
              <b>AllTrails Lifeline</b> - <FormattedMessage defaultMessage="Keeping adventurers safe on the trail" />
            </div>
            <div className="stats-section first header-sec">
              <div className="align-center">
                {userProfilePhoto}
                <h3>{userName}</h3>
                <div className="tracking-disclaimer">
                  <FormattedMessage
                    defaultMessage="Has shared a Lifeline with you. You can follow {name}'s real-time location during the activity. If you or {name} encounter limited GPS or data coverage, Lifeline information could be delayed."
                    values={{ name: user.firstName }}
                  />
                </div>
              </div>
            </div>
            {this.renderTimeStatusDiv()}
            {staticMapInlineDiv}
            {liveTitleDiv}
            {firstTimeDiv}
            {liveTotalsDiv}
            {lastLocationDiv}
            <div className="action-bar">
              <menu className="title clearfix overview-menu tabs inline-menu">
                <li className="active">
                  <h3>
                    <a>
                      <FormattedMessage defaultMessage="Planned activity details" />
                    </a>
                  </h3>
                </li>
              </menu>
            </div>
            <div className={plannedSectionClasses}>
              <span className="start-time stat">
                <div className="stats-label">
                  <FormattedMessage defaultMessage="PLANNED START TIME" />
                </div>
                <div className="data">{formatStart}</div>
              </span>
              <span className="stop-time stat">
                <div className="stats-label">
                  <FormattedMessage defaultMessage="PLANNED END TIME" />
                </div>
                <div className="data">{formatEnd}</div>
              </span>
            </div>
            {plannedDistanceElevDiv}
          </div>
          {inlineTrailCard}
          {this.renderLifelineExplanationDiv()}
          <div id="cell-charge-disclaimer">
            <FormattedMessage defaultMessage="Standard SMS rates will apply to messages you receive.  Live tracking can only update when the user's phone has a data signal." />
            <div className="more-info">
              <a href={getHelpCenterUrl(languageRegionCode, 360019246351)} target="_blank" style={{ position: 'relative', top: '4px' }}>
                <FormattedMessage defaultMessage="More info" />
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
});

export { FullscreenLifelineDetails };
