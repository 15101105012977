import { LngLatLike, LngLatBounds, Marker } from 'mapbox-gl';
import { Feature, FeatureCollection, Geometry, LineString, Position } from '@turf/helpers';
import turfAlong from '@turf/along';
import turfBearing from '@turf/bearing';
import turfDistance from '@turf/distance';
import { SIZE_HEADER_HEIGHT_DESKTOP, SPACE_40 } from '@alltrails/shared/denali/tokens';
import { MapboxMapShim } from 'types/Map';
import { hideAtMap, showAtMap, setBoundsFromAtMapGeojson, DEFAULT_AT_MAP_PADDING } from 'utils/mapbox/overlays/at_map';
import { fitMapToBounds } from 'utils/mapbox/map_helpers';
import { mapMarkerDiv } from 'utils/mapbox/layers/markers';
import { addPolylines, removePolylines } from 'utils/mapbox/overlays/polylines';
import FlyoverMapEventHandlers from 'types/FlyoverMapEventHandlers';
import FlyoverCameraPosition from 'types/FlyoverCameraPosition';

/**
 * Calculated from animating a marker over [Snow Lake Trail](https://www.alltrails.com/trail/us/washington/snow-lake-trail) in 40 seconds
 */
export const DEFAULT_SPEED = 252.38996618; // m/s
const SPEEDS: { [distRangeKm: string]: number } = {
  '0-0.804': 50, // 0 mi - 0.5 mi
  '0.804-4.828': 150, // 0.5 mi - 3 mi
  '4.828': DEFAULT_SPEED // 3+ mi
};
const DEFAULT_CONTROLS_PADDING = SPACE_40;

const MAX_BEARING_VEL = 25;

export const MAX_ZOOM = 17;
export const MIN_ZOOM = 13;
const AVG_TRAIL_LENGTH_KM = 15.77;
const STD_DEV_TRAIL_LENGTH_KM = 46.08;

export const FLYOVER_LINE = 'flyover-line';
export const FLYOVER_ANIM_DURATION_MS = 800;

/**
 * Hides part of the original polyline.
 * Initializes the mapbox map to draw the animated line for flyover by adding a new polyline layer & marker to animate.
 * @param map [Mapbox map object](https://docs.mapbox.com/mapbox-gl-js/api/map/#map)
 * @param atMapGeojson a FeatureCollection of the map to animate - typically should match the original polyline
 * @returns a [Mapbox Marker](https://docs.mapbox.com/mapbox-gl-js/api/markers/#marker) to animate
 */
export function initializeFlyoverMap(
  map: MapboxMapShim,
  atMapGeojson: FeatureCollection<
    Geometry,
    {
      [name: string]: any;
    }
  >
): { marker: Marker } {
  hideAtMap(map);

  // Add a line feature and layer. This feature will get updated as we progress the animation
  addPolylines(
    map,
    FLYOVER_LINE,
    atMapGeojson,
    { lineMetrics: true } /* sourceOpts */,
    { 'line-color': 'rgba(0,0,0,0)' } /* opts */,
    null /* addUnderLayerTypes */,
    false /* isMapCreatorPage */
  );

  const marker = new Marker(mapMarkerDiv('current'));

  return { marker };
}

/**
 * Removes flyover lines & markers from the map & shows the original polyline.
 * Resets map bounds to normal.
 * @param map [Mapbox map object](https://docs.mapbox.com/mapbox-gl-js/api/map/#map)
 * @param atMapGeojson a FeatureCollection of the original atMap - typically should match the original polyline
 */
export function exitFlyoverMap(
  map: MapboxMapShim,
  atMapGeojson: FeatureCollection<
    Geometry,
    {
      [name: string]: any;
    }
  >
): void {
  removePolylines(map, FLYOVER_LINE);
  const bounds = new LngLatBounds();
  setBoundsFromAtMapGeojson(atMapGeojson, bounds);
  fitMapToBounds(map, bounds, DEFAULT_AT_MAP_PADDING, 1000 /* duration */, false /* maintainZoomLevel */, true /* zoomOutByDefault */);
  showAtMap(map);
}

/**
 * Given pathing information, calculates the position along the path along with the bearing of it relative to another point.
 * @param path line string representing a path in which to calculate position values from
 * @param pathDistance the total distance of the path
 * @param percentageComplete a value from 0 to 1 indicating a percentage along the path
 * @param previousPosition a separate position used to calculate bearing
 * @returns {object} an object with a `bearing`, `lngLat` of the position, and a position
 */
export function computePositionValues(
  path: Feature<
    LineString,
    {
      [name: string]: any;
    }
  >,
  pathDistance: number,
  percentageComplete: number,
  previousPosition: Position | null
): {
  bearing: number | null;
  lngLat: LngLatLike;
  position: Position;
} {
  // Get the new latitude and longitude by sampling along the path
  const position = turfAlong(path, pathDistance * percentageComplete).geometry.coordinates;
  const lngLat = {
    lng: position[0],
    lat: position[1]
  };

  const bearing = previousPosition ? turfBearing(previousPosition, position) : null;

  return {
    bearing,
    lngLat,
    position
  };
}

/**
 * Adds event listeners on the map/document to handle pause/play interactions.
 * @param map [Mapbox map object](https://docs.mapbox.com/mapbox-gl-js/api/map/#map)
 * @param pause a function to pause the flyover
 * @param {FlyoverMapEventHandlers} eventHandlers object of event handlers
 */
export function addFlyoverEventListeners(map: MapboxMapShim, pause: () => void, eventHandlers?: FlyoverMapEventHandlers) {
  if (!eventHandlers) {
    return;
  }

  map.on('dblclick', pause);

  const { onKeyDown, onMouseDown, onMouseMove, onMouseUp, onTouchStart, onTouchEnd, onWheel } = eventHandlers;
  map.on('mousedown', onMouseDown);
  map.on('mousemove', onMouseMove);
  map.on('mouseup', onMouseUp);
  map.on('wheel', onWheel);

  map.on('touchstart', onTouchStart);
  map.on('touchend', onTouchEnd);

  document.addEventListener('keydown', onKeyDown);
}

/**
 * Removes event listeners on the map/document added from `addFlyoverEventListeners`.
 * @param map [Mapbox map object](https://docs.mapbox.com/mapbox-gl-js/api/map/#map)
 * @param pause a function to pause the flyover
 * @param {FlyoverMapEventHandlers} eventHandlers object of event handlers
 */
export function removeFlyoverEventListeners(map: MapboxMapShim, pause: () => void, eventHandlers?: FlyoverMapEventHandlers) {
  if (!eventHandlers) {
    return;
  }

  // pause events
  map.off('dblclick', pause);

  const { onKeyDown, onMouseDown, onMouseMove, onMouseUp, onTouchStart, onTouchEnd, onWheel } = eventHandlers;
  map.off('mousedown', onMouseDown);
  map.off('mousemove', onMouseMove);
  map.off('mouseup', onMouseUp);
  map.off('wheel', onWheel);

  map.off('touchstart', onTouchStart);
  map.off('touchend', onTouchEnd);

  document.removeEventListener('keydown', onKeyDown);
}

/**
 * Gets a padding value for the bottom controls based on the map size vs. the bottom of the screen, used to emulate a `sticky` anchored to the bottom.
 * Used to assure the bottom-achored flyover controls are never overlapped by a mobile browser's url bar.
 * @param map [Mapbox map object](https://docs.mapbox.com/mapbox-gl-js/api/map/#map)
 * @returns a bottom padding value from the bottom of the map.
 */
export function getControlsPadding(map: MapboxMapShim): number {
  if (typeof window === undefined || !map) {
    return DEFAULT_CONTROLS_PADDING;
  }

  const bottomDepth = window.scrollY + window.innerHeight;
  const mapBottom = parseInt(map.getCanvas().style.height) + SIZE_HEADER_HEIGHT_DESKTOP;
  return mapBottom > bottomDepth ? DEFAULT_CONTROLS_PADDING + mapBottom - bottomDepth : DEFAULT_CONTROLS_PADDING;
}

/**
 * Gets the speed in which the marker should travel.
 * @param distKm total distance of the line that the flyover is following
 * @returns speed (in m/s)
 */
export function getAnimationSpeed(distKm: number): number {
  let speed: number = DEFAULT_SPEED;
  Object.keys(SPEEDS).forEach((distRangeKm: string) => {
    const [lower, upper] = distRangeKm.split('-');
    if (distKm >= parseFloat(lower) && ((upper && distKm < parseFloat(upper)) || !upper)) {
      speed = SPEEDS[distRangeKm];
    }
  });
  return speed;
}

/**
 * Gets a mapbox zoom level for the flyover animation based on the distance of the trail.
 * Applies z-scale normalization to get a relative value based on trail length, then cube root transforms the value as
 * our trails skews towards shorter trails.
 *
 * More info: https://medium.com/@TheDataGyan/day-8-data-transformation-skewness-normalization-and-much-more-4c144d370e55
 *
 * @param distKm distance of the trail
 * @returns zoom level between [`MIN_ZOOM`,`MAX_ZOOM`]. If there's an  distance, returns the maximum zoom level.
 */
export function getInitialZoom(distKm: number): number {
  if (distKm <= 0) {
    return MAX_ZOOM;
  }

  const zScale = (distKm - AVG_TRAIL_LENGTH_KM) / STD_DEV_TRAIL_LENGTH_KM;
  const normalizedZoom = Math.cbrt(zScale) + (MAX_ZOOM - (MAX_ZOOM - MIN_ZOOM) / 2);
  const clampedZoom = Math.min(Math.max(normalizedZoom, MIN_ZOOM), MAX_ZOOM);
  const clampedZoomInversed = MAX_ZOOM - (clampedZoom - MIN_ZOOM);

  return clampedZoomInversed;
}

/**
 * Computes a series of positions based off of a given path.
 * @param cameraPath the path in which to calculate positions over
 * @param cameraPathDistKm total distance of the cameraPath in km
 * @returns an array of camera pathing information based on each coordinate inside the cameraPath
 * (coordinates, bearing to the next coordinate, percentage)
 */
export function getCameraPositions(cameraPath: Feature<LineString, { [name: string]: any }>, cameraPathDistKm: number): FlyoverCameraPosition[] {
  let prevPercentage = 0;
  const { coordinates } = cameraPath.geometry;
  const cameraPositions = [] as FlyoverCameraPosition[];

  if (!coordinates.length || !cameraPathDistKm) {
    return cameraPositions;
  }

  coordinates.forEach((coordinate: Position, i: number) => {
    if (i === coordinates.length - 1) {
      return;
    }

    const pointA = coordinate;
    const pointB = coordinates[i + 1];
    const bearing = turfBearing(pointA, pointB);
    const dist = turfDistance(pointA, pointB);
    const pathPercentage = dist / cameraPathDistKm;

    cameraPositions.push({ location: coordinate, bearing, pathPercentage: prevPercentage });
    prevPercentage = pathPercentage;
  });

  cameraPositions.push({
    location: coordinates[coordinates.length - 1],
    bearing: cameraPositions[cameraPositions.length - 1].bearing,
    pathPercentage: prevPercentage
  });

  return cameraPositions;
}

/**
 * Given two bearings, calculates the shortest degree difference.
 * @param fromBearing bearing value (in degrees) between (-180, 180]
 * @param toBearing bearing value (in degrees) between (-180, 180]
 * @returns shortest degree difference between from & to bearings
 */
export function shortestAngularDiff(fromBearing: number, toBearing: number): number {
  const bearingDiff = ((toBearing - fromBearing + 180) % 360) - 180;
  return bearingDiff < -180 ? bearingDiff + 360 : bearingDiff;
}

/**
 * An easing function to return a value along a cos wave between [0, 1]
 * @param val a numeric value
 * @returns a clamped value between [0, 1] to a cos value
 */
function easeInOut(val: number): number {
  return 0.5 * (1 - Math.cos(val * Math.PI));
}

/**
 * Interpolates between bearings given a normalized value between [0, 1].
 * @param fromBearing starting bearing
 * @param toBearing destination bearing
 * @param percentProgress value normalized between [0, 1], indicative of the percentage progress made over the whole animation
 * @param percentageSegment percentage of the total distance the current pathing segment is
 * @param totalDuration duration of the whole animation (in seconds)
 * @returns an interpolated bearing value between the fromBearing & toBearing
 */
export function interpolateBearing(
  fromBearing: number,
  toBearing: number,
  percentProgress: number,
  percentageSegment: number,
  totalDuration: number
): number {
  const angularDiff = shortestAngularDiff(fromBearing, toBearing);
  const adjustedToBearing = fromBearing + angularDiff;

  const valueToStartMove = -(Math.abs(angularDiff) / (MAX_BEARING_VEL * totalDuration * percentageSegment) - 1);

  if (percentProgress < valueToStartMove) return fromBearing;

  const maxStartVal = Math.max(0, valueToStartMove);
  const adjustedVal = (percentProgress - maxStartVal) / (1 - maxStartVal);
  const easedVal = easeInOut(adjustedVal);
  const easedValClamped = Math.min(1, Math.max(0, easedVal));

  return fromBearing * (1 - easedValClamped) + adjustedToBearing * easedValClamped;
}

/**
 * Linearly interpolates between positions given a normalized value between [0, 1].
 * @param fromPosition starting bearing
 * @param toPosition destination bearing
 * @param normalizedVal value normalized between [0, 1]
 * @returns an interpolated position value between the fromPosition & toPosition
 */
export function interpolatePosition(fromPosition: Position, toPosition: Position, normalizedVal: number): Position {
  const clampedVal = Math.min(1, Math.max(0, normalizedVal));
  const remainderVal = 1 - clampedVal;
  const interpolatedLon = fromPosition[0] * remainderVal + toPosition[0] * clampedVal;
  const interpolatedLat = fromPosition[1] * remainderVal + toPosition[1] * clampedVal;
  return [interpolatedLon, interpolatedLat];
}

/**
 * Given a pre-calculated set of positions & a percentage complete, determines the next destination position.
 * Example: if we have 3 camera positions at progresses [0, 0.4, 1.0], a progressPercentage of 0.2 would return the position at progress of 0.4.
 * @param cameraPositions array of all positions to animate over
 * @param progressPercentage percentage complete of the animation
 * @returns the proceeding camera position based off the percentage
 */
export function getNextCameraPosition(cameraPositions: FlyoverCameraPosition[], progressPercentage: number): FlyoverCameraPosition | undefined {
  if (cameraPositions.length < 1) {
    return undefined;
  }

  let targetPosition = cameraPositions[cameraPositions.length - 1];
  let accumulatedPercentage = 0;

  cameraPositions.every(position => {
    accumulatedPercentage += position.pathPercentage;
    targetPosition = position;
    return accumulatedPercentage <= progressPercentage;
  });

  return targetPosition;
}

/**
 * Interpolates the camera position & bearing.
 * @param prevTarget starting target
 * @param nextTarget destination target
 * @param prevTargetProgress progress percentage when the next target was changed
 * @param progressPercentage current progress percentage over the whole animation
 * @param totalDuration total duration of the animation (in seconds)
 * @returns interpolated location / bearing
 */
export function getInterpolatedCameraPosition(
  prevTarget: FlyoverCameraPosition,
  nextTarget: FlyoverCameraPosition,
  prevTargetProgress: number,
  progressPercentage: number,
  totalDuration: number
): FlyoverCameraPosition {
  const interpolatedVal = (progressPercentage - prevTargetProgress) / nextTarget.pathPercentage;
  const clampedInterpolationVal = Math.min(1, Math.max(0, interpolatedVal));
  // const interpolationRemainder = 1 - clampedInterpolationVal;
  const location = interpolatePosition(prevTarget.location, nextTarget.location, clampedInterpolationVal);
  const bearing = interpolateBearing(prevTarget.bearing, nextTarget.bearing, clampedInterpolationVal, nextTarget.pathPercentage, totalDuration);

  return {
    location,
    bearing,
    pathPercentage: progressPercentage
  };
}
