import { useMemo, useState } from 'react';
import { Trail } from '@alltrails/shared/types/trail';

export type Collection = {
  uid: string;
  name: string;
  id: number;
  tagAssociation: string;
};

export type CollectionMappings = {
  collection: Collection;
};

type Props = {
  trail: Trail & {
    collectionMappings?: CollectionMappings[];
  };
};

const useCollections = ({ trail }: Props) => {
  const originalCollectionMappings = useMemo(() => trail.collectionMappings, [trail.collectionMappings]);
  const [collectionMappings, setCollectionMappings] = useState(trail.collectionMappings);

  return {
    originalCollectionMappings,
    collectionMappings,
    setCollectionMappings
  };
};

export default useCollections;
