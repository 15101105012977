// Starting from top layer, find first layer that isn't a symbol, circle, or heatmap layer
const findBeforeIdFromTypes = (map, addUnderLayerTypes) => {
  const layers = map.getStyle().layers;
  let beforeId;
  for (let i = layers.length - 1; i >= 0; i--) {
    const { type } = layers[i];
    if (!addUnderLayerTypes.includes(type)) {
      break;
    }
    beforeId = layers[i].id;
  }
  return beforeId;
};

// this just confirms that a layer exists before asking MBGL to render beneath it
const findBeforeIdFromLayerId = (map, addUnderLayerid) => {
  let beforeId;
  const layer = map.getLayer(addUnderLayerid);
  if (layer) {
    beforeId = addUnderLayerid;
  }
  return beforeId;
};

const addLayer = (map, layer, addUnderLayerTypes = null, addUnderLayerId = null) => {
  let beforeId;
  if (addUnderLayerTypes) {
    beforeId = findBeforeIdFromTypes(map, addUnderLayerTypes);
  } else if (addUnderLayerId) {
    beforeId = findBeforeIdFromLayerId(map, addUnderLayerId);
  } else if (layer.type === 'heatmap' || layer.id.toLowerCase().includes('heatmap')) {
    beforeId = findBeforeIdFromTypes(map, ['symbol', 'circle', 'line']);
  } else if (layer.type === 'line' || layer.id.includes('chevron')) {
    beforeId = findBeforeIdFromTypes(map, ['symbol', 'circle']);
  }
  if (!map.getLayer(layer.id)) {
    map.addLayer(layer, beforeId);
  }
  const customLayers = map.customStyle.layers;
  const customLayerEvents = map.customStyle.layerEvents;
  const idx = customLayers.findIndex(l => l.id === layer.id);
  if (idx < 0) {
    customLayers.push(layer);
    customLayerEvents[layer.id] = [];
  }
};

// Listen for event on layer, remove with layer
const addLayerEvent = (map, layerId, onLayer, type, listener, useMapLevel = true) => {
  if (onLayer) {
    // Event types in this array are handled by initLayerEvents instead and should only fire at the map-level
    if (!useMapLevel || !['click', 'mouseenter', 'mouseleave'].includes(type)) {
      // trigger event on interaction with layer-specific features
      map.on(type, layerId, listener);
    }
  } else {
    // trigger event on interaction with map, but still associate with layer for easy removal
    map.on(type, listener);
  }

  map.customStyle.layerEvents?.[layerId]?.push({
    type,
    listener,
    onLayer
  });
};

const removeLayer = (map, layerId) => {
  // Custom removal
  const customLayers = map.customStyle.layers;
  const customLayerEvents = map.customStyle.layerEvents;
  const idx = customLayers.findIndex(l => l.id === layerId);
  if (idx >= 0) {
    customLayers.splice(idx, 1);
    customLayerEvents[layerId].forEach(({ type, listener, onLayer }) => {
      if (type === 'removed') {
        listener();
      }
      if (onLayer) {
        map.off(type, layerId, listener);
      } else {
        map.off(type, listener);
      }
    });
    delete customLayerEvents[layerId];
  }
  // Basic removal
  if (map.getLayer(layerId)) {
    map.removeLayer(layerId);
  }
};

const fireLayerEvent = (map, layerId, eventType, e = {}) => {
  let layerEvents = map.customStyle.layerEvents[layerId];
  if (!layerEvents) {
    return;
  }
  layerEvents = layerEvents.filter(event => event.type === eventType);
  layerEvents.forEach(config => {
    config.listener(e);
  });
};

// Init map layer events
const initLayerEvents = map => {
  const getEventFeature = e => {
    return map.queryRenderedFeatures(e.point)?.[0];
  };
  const sameFeature = (feature1, feature2) => {
    return feature1 && feature2 && feature1.layer.id === feature2.layer.id && feature1.id === feature2.id;
  };
  const fireEvent = (feature, eventType, e) => {
    if (feature) {
      fireLayerEvent(map, feature.layer.id, eventType, { ...e, features: [feature] });
    }
  };
  // Handle these events at the map-level (from a Mapbox GL API perspective), rather than at the layer-level, to prevent
  // multiple events from firing across multiple layers on a single hover event.
  let lastClickedFeature = null;
  map.on('click', e => {
    // Ignore clicks not fired directly onto the canvas (e.g. onto an HTML marker)
    if (e.originalEvent.target.nodeName !== 'CANVAS') {
      return;
    }
    const feature = getEventFeature(e);
    if (!sameFeature(lastClickedFeature, feature)) {
      fireEvent(lastClickedFeature, 'unclick', e);
    }
    // Unlike mouseenter/mouseleave, click can be called multiple times on same feature
    fireEvent(feature, 'click', e);
    lastClickedFeature = feature;
  });
  // Don't use normal mousemove/moveout/mouseenter/mouseleave events on mobile as it causes some headaches
  if (!(map.context && map.context.mobileBrowser)) {
    let lastMouseOveredFeature;
    map.on('mousemove', e => {
      // Ignore movemoves not fired directly onto the canvas (e.g. onto an HTML marker)
      if (e.originalEvent.target.nodeName !== 'CANVAS') {
        return;
      }
      const feature = getEventFeature(e);
      if (!sameFeature(lastMouseOveredFeature, feature)) {
        fireEvent(lastMouseOveredFeature, 'mouseleave', e);
        fireEvent(feature, 'mouseenter', e);
        lastMouseOveredFeature = feature;
      }
    });
    map.on('mouseout', () => {
      lastMouseOveredFeature = null;
    });
  }
};

const updateLayerProperty = (map, layerId, type, key, value) => {
  const customLayers = map.customStyle.layers;
  const layer = customLayers.find(l => l.id === layerId);
  if (!layer) {
    return;
  }
  if (type === 'layout') {
    map.setLayoutProperty(layerId, key, value);
    layer.layout[key] = value;
  } else {
    map.setPaintProperty(layerId, key, value);
    layer.paint[key] = value;
  }
};

export { addLayer, addLayerEvent, removeLayer, fireLayerEvent, updateLayerProperty, initLayerEvents, findBeforeIdFromLayerId };
