import { ComponentProps, useCallback, useEffect, useState } from 'react';
import { defineMessages } from '@alltrails/shared/react-intl';
import TextInput from '@alltrails/shared/denali/components/TextInput';
import Button from '@alltrails/shared/denali/components/Button';
import Check from '@alltrails/shared/icons/Check';
import PhoneInput, { isPossiblePhoneNumber } from '@alltrails/shared/denali/components/PhoneInput';
import TextArea from '@alltrails/shared/denali/components/TextArea';
import Radio from '@alltrails/shared/denali/components/Radio';
import Select from '@alltrails/shared/denali/components/Select';
import useFormatMessage from '@alltrails/shared/hooks/useFormatMessage';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import { AlgoliaSearchBox, PlaceSearchResult } from '@alltrails/modules/AlgoliaSearch';
import '@alltrails/modules/AlgoliaSearch/index.css';
import ProfileUser from 'types/ProfileUser';
import CustomProvider from 'components/CustomProvider';
import { CM_IN_INCH, KG_IN_LB } from 'utils/constants/conversion';
import { translateTag } from 'utils/trail_helpers';
import { execute } from 'utils/recaptcha_helpers';
import isValidEmail from 'utils/isValidEmail';
import UserAvatar from '../../profile/UserAvatar';
import BirthdayInput from '../BirthdayInput/BirthdayInput';
import { LanguageSupportUtil } from '../../../utils/language_support_util';
import { ServerCommunicationUtil } from '../../../utils/server_communication_util';
import * as styles from './styles/styles.module.scss';

const placeSearchTypes: ComponentProps<typeof AlgoliaSearchBox>['searchTypes'] = ['place'];

type Activity = {
  name: string;
  uid: string;
};

type ProfileFormUser = {
  aboutMe?: string;
  activities?: string[];
  birthday?: Date;
  city?: string;
  country?: {
    id?: number;
    isoCode?: string;
    latitude?: string;
    longitude?: string;
    name?: string;
    slug?: string;
  };
  displaySpeed?: boolean;
  email?: string;
  firstName?: string;
  height?: string | number;
  language?: string;
  lastName?: string;
  metric?: boolean;
  private?: boolean;
  sex?: string;
  state?: string;
  weight?: string | number;
  algoliaObjectId?: any;
  password?: string;
  recaptcha?: string;
  phone?: string;
};

type EnabledLanguages = {
  language_region_code: string;
  name: string;
};

type Errors = {
  birthday?: string;
  email?: string[];
  firstName?: string[];
  formError?: string[];
  lastName?: string[];
  password?: string[];
  phone?: string;
  aboutMe?: string;
};

type Props = {
  currentUser: ProfileFormUser;
  user: ProfileUser;
  activities: Activity[];
  enabledLanguages: EnabledLanguages[];
  mobileBrowser: boolean;
};

const messages = defineMessages({
  REQUIRED: { defaultMessage: 'Required' },
  AT_LEAST_SIX_TO_128_CHARS: { defaultMessage: 'Must be 6 to 128 characters in length' },
  LESS_THAN_2000_CHARS: { defaultMessage: 'Must be no more than 2000 characters in length' },
  NO_LOCATION: { defaultMessage: 'No current location info' },
  MEMBER_LOCATION: { defaultMessage: 'Member location: ' },
  FIRST_NAME: { defaultMessage: 'First name' },
  LAST_NAME: { defaultMessage: 'Last name' },
  SET_NEW_PASSWORD: { defaultMessage: 'Set a new password' },
  EMAIL: { defaultMessage: 'Email' },
  ABOUT_ME: { defaultMessage: 'About me' },
  FAVORITE_ACTIVITIES: { defaultMessage: 'Favorite activities' },
  UNITS: { defaultMessage: 'Units' },
  IMPERIAL: { defaultMessage: 'Imperial' },
  METRIC: { defaultMessage: 'Metric' },
  CANCEL: { defaultMessage: 'Cancel' },
  SAVE: { defaultMessage: 'Save' },
  CHANGE_LOCATION: { defaultMessage: 'Change member location' },
  RECORDING_PREFERENCE: { defaultMessage: 'Activity time preference' },
  SPEED: { defaultMessage: 'Speed' },
  PACE: { defaultMessage: 'Pace' },
  UNSPECIFIED: { defaultMessage: 'unspecified' },
  HEIGHT: { defaultMessage: 'Height' },
  WEIGHT: { defaultMessage: 'Weight' },
  BIRTHDAY: { defaultMessage: 'Birthday ' },
  CALORIE_INFO: { defaultMessage: 'Calorie counter info' },
  LANGUAGE: { defaultMessage: 'Marketing language ' },
  NOT_ALLOWED: { defaultMessage: '@ is not allowed' },
  NOT_VALID_EMAIL: { defaultMessage: 'Email is not valid' },
  BDAY_ERROR: { defaultMessage: 'Enter a valid birthday ' },
  BDAY_NON_VALID_YEAR: { defaultMessage: 'Please enter a 4-digit year' },
  PHONE_NUMBER: { defaultMessage: 'Phone number' },
  INVALID_PHONE_NUMBER: { defaultMessage: 'Enter a valid phone number' },
  PHONE_HELPER_TEXT: { defaultMessage: "We'll only use your phone number if you opt into SMS updates from a live share contact." }
});

const ProfileEditForm = ({ currentUser, user, activities, enabledLanguages, mobileBrowser }: Props) => {
  const {
    formattedDefaultMessages: {
      REQUIRED,
      AT_LEAST_SIX_TO_128_CHARS,
      LESS_THAN_2000_CHARS,
      NO_LOCATION,
      MEMBER_LOCATION,
      FIRST_NAME,
      LAST_NAME,
      SET_NEW_PASSWORD,
      EMAIL,
      ABOUT_ME,
      FAVORITE_ACTIVITIES,
      UNITS,
      IMPERIAL,
      METRIC,
      CANCEL,
      SAVE,
      CHANGE_LOCATION,
      RECORDING_PREFERENCE,
      SPEED,
      PACE,
      UNSPECIFIED,
      HEIGHT,
      WEIGHT,
      BIRTHDAY,
      CALORIE_INFO,
      LANGUAGE,
      NOT_ALLOWED,
      NOT_VALID_EMAIL,
      BDAY_ERROR,
      BDAY_NON_VALID_YEAR,
      PHONE_NUMBER,
      INVALID_PHONE_NUMBER,
      PHONE_HELPER_TEXT
    }
  } = useFormatMessage(messages);
  const [profileFormUser, setProfileFormUser] = useState<ProfileFormUser>(currentUser);
  const [errors, setErrors] = useState<Errors>(null);
  const [newPassword, setNewPassword] = useState<string>('');
  const [birthdayMonth, setBirthdayMonth] = useState<number>(null);
  const [birthdayDate, setBirthdayDate] = useState<number>(null);
  const [birthdayYear, setBirthdayYear] = useState<number>(null);
  const languageRegionCode = useLanguageRegionCode();

  useEffect(() => {
    if (profileFormUser?.birthday) {
      const [year, month, day] = profileFormUser.birthday.toString().split('-');

      setBirthdayYear(parseInt(year, 10));
      setBirthdayMonth(parseInt(month, 10));
      setBirthdayDate(parseInt(day, 10));
    }
  }, [profileFormUser?.birthday]);

  const getDate = (year: number, month: number, day: number) =>
    new Date(
      year,
      month - 1, // months are zero based
      day
    );

  const birthdayValidation = (year: number, month: number, day: number): string => {
    if (Number.isNaN(year) || Number.isNaN(month) || Number.isNaN(day)) {
      return BDAY_ERROR;
    }

    if (year < 1000) {
      return BDAY_NON_VALID_YEAR;
    }

    const date = getDate(year, month, day);

    if (date.getFullYear() !== year || date.getMonth() !== month - 1 || date.getDate() !== day) {
      return BDAY_ERROR;
    }

    return null;
  };

  const presubmitErrors = () => {
    const newErrors = {} as Errors;
    if (!(profileFormUser?.firstName.length > 0)) {
      newErrors.firstName = [REQUIRED];
    }
    if (profileFormUser?.firstName && profileFormUser?.firstName.indexOf('@') >= 0) {
      newErrors.firstName = [NOT_ALLOWED];
    }
    if (profileFormUser.lastName && profileFormUser.lastName.indexOf('@') >= 0) {
      newErrors.lastName = [NOT_ALLOWED];
    }

    if (profileFormUser?.email && !isValidEmail(profileFormUser.email)) {
      newErrors.email = [NOT_VALID_EMAIL];
    }
    if (newPassword && newPassword !== '' && (newPassword.length < 6 || newPassword.length > 128)) {
      newErrors.password = [AT_LEAST_SIX_TO_128_CHARS];
    }
    if (profileFormUser?.aboutMe && profileFormUser?.aboutMe.length > 2000) {
      newErrors.aboutMe = LESS_THAN_2000_CHARS;
    }
    if (profileFormUser?.birthday) {
      const hasErrors = birthdayValidation(birthdayYear, birthdayMonth, birthdayDate);
      if (hasErrors) {
        newErrors.birthday = hasErrors;
      }
    }
    if (profileFormUser.phone && !isPossiblePhoneNumber(profileFormUser.phone)) {
      newErrors.phone = INVALID_PHONE_NUMBER;
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return true;
    }
    setErrors(null);
    return false;
  };

  const renderEditWithFailure = () => {
    window.location.href = LanguageSupportUtil.wrapUrlSafe('/my/profile/edit?edit_fail=true', languageRegionCode);
  };

  // The server responds with either an HTTP 400 with an empty body, or an HTTP 500 with a text body (not JSON).
  // This function handles both cases and also allows the server to respond with specific field errors, even
  // though it currently doesn't make use of that.
  const handleServerErrors = (err: any) => {
    // If we have parsable JSON with a newErrors key, we'll use that to update the errors state.
    if (err.status === 400 && err.responseText !== '') {
      const { newErrors } = JSON.parse(err.responseText);
      if (newErrors) {
        setErrors(Object.assign(newErrors, errors));
        return;
      }
    }

    // If we're here then the server returned a 500 error or a 400 error without newErrors. Either way,
    // we'll show the edit page with a param that tells the server to render a flash message.
    renderEditWithFailure();
  };

  const handleSubmit = async () => {
    if (presubmitErrors()) {
      return;
    }

    const recaptcha = await execute('editProfile');

    const userForSave = profileFormUser;

    if (newPassword && newPassword !== '') userForSave.password = newPassword;

    if (birthdayYear && birthdayMonth && birthdayDate) {
      const date = getDate(birthdayYear, birthdayMonth, birthdayDate);
      userForSave.birthday = date;
    }

    userForSave.recaptcha = recaptcha;

    ServerCommunicationUtil.putApiEndpoint(
      '/my/profile',
      { user: userForSave },
      () => {
        window.location.href = LanguageSupportUtil.wrapUrlSafe('/my/profile?edit_success=true', languageRegionCode);
      },
      (err: any) => {
        handleServerErrors(err);
      },
      () => {
        // complete
      }
    );
  };

  const handlePlaceSelection = useCallback(
    (result: PlaceSearchResult) => {
      let city;
      let state;
      let country;
      if (result.objectID.indexOf('country') > -1) {
        country = {
          id: result.ID,
          name: result.name
        };
      } else if (result.objectID.indexOf('state') > -1) {
        state = result.name;
        country = {
          id: result.country_id,
          name: result.country_name
        };
      } else if (result.objectID.indexOf('cityo') > -1) {
        city = result.name;
        state = result.state_name;
        country = {
          id: result.country_id,
          name: result.country_name
        };
      }
      profileFormUser.city = city;
      profileFormUser.state = state;
      profileFormUser.country = country;
      profileFormUser.algoliaObjectId = result.objectID;

      setProfileFormUser({ ...profileFormUser });
    },
    [profileFormUser]
  );

  const handleUserValues = (value: any, key: string) => {
    let height = null;
    let weight = null;
    switch (key) {
      case 'imperial':
        profileFormUser.metric = !value;
        break;
      case 'metric':
      case 'displaySpeed':
        profileFormUser[key] = !!value;
        break;
      case 'pace':
        profileFormUser.displaySpeed = !value;
        break;
      case 'height':
        if (value === '') {
          height = null;
        } else if (profileFormUser.metric) {
          height = value;
        } else {
          height = value * CM_IN_INCH;
        }
        profileFormUser.height = height;
        break;
      case 'weight':
        if (value === '') {
          weight = null;
        } else if (profileFormUser.metric) {
          weight = value;
        } else {
          weight = value * KG_IN_LB;
        }
        profileFormUser.weight = weight;
        break;
      default:
        (profileFormUser as any)[key] = value;
    }

    setProfileFormUser({ ...profileFormUser });
  };

  const handleNewPasswordChange = (password: string) => {
    setNewPassword(password);
  };

  const handleAttributeSelection = (activity: Activity) => {
    const index = profileFormUser.activities.indexOf(activity.uid);
    if (index > -1) {
      profileFormUser.activities.splice(index, 1);
    } else {
      profileFormUser.activities.push(activity.uid);
    }

    setProfileFormUser({ ...profileFormUser });
  };

  const createActivityInput = (activity: Activity, checkedAttributes: any) => {
    const isSelected = checkedAttributes?.indexOf(activity.uid) >= 0;
    return (
      <div className="activities-select" key={activity.name}>
        <Button
          className="activity-tag"
          text={translateTag(activity.uid)}
          onClick={() => handleAttributeSelection(activity)}
          testId={activity.name}
          variant={isSelected ? 'primary' : 'default'}
          size="sm"
          icon={isSelected ? { Component: Check } : null}
        />
      </div>
    );
  };

  const createLocationLabelText = () => {
    let text = NO_LOCATION;
    let includePrefix = false;
    const { country } = profileFormUser;
    if (country) {
      includePrefix = true;
      if (country.id === 313) {
        text = '';
      } else {
        text = country.name;
      }
      if (profileFormUser.state) {
        text = country.id === 313 ? profileFormUser.state : `${profileFormUser.state}, ${text}`;
      }
      if (profileFormUser.city) {
        text = `${profileFormUser.city}, ${text}`;
      }
    }

    if (includePrefix) text = `${MEMBER_LOCATION} ${text}`;

    return text;
  };

  const textInputComponent = (field: string, isHalfWidth?: boolean) => {
    let errorMessage = '';
    let divClasses = 'input string required name-section';

    if (isHalfWidth) {
      divClasses += ' half-width';
    }
    if ((errors as any)?.[field]) {
      divClasses += ' field_with_errors';
      errorMessage = (errors as any)?.[field][0];
    }
    let text;
    switch (field) {
      case 'firstName':
        text = FIRST_NAME;
        break;
      case 'lastName':
        text = LAST_NAME;
        break;
      default:
        text = EMAIL;
    }
    return (
      <div className={divClasses}>
        <TextInput
          type="text"
          placeholder={text}
          labelText={text}
          value={(profileFormUser as any)[field]}
          testId={`user_${field}`}
          errorText={errorMessage || ''}
          onChange={value => handleUserValues(value, field)}
          required
        />
      </div>
    );
  };

  const passwordDiv = () => {
    let errorMessage = '';
    let divClasses = 'input password optional bottom-section';
    if (errors?.password) {
      divClasses += ' field_with_errors';
      errorMessage = errors?.password[0];
    }

    return (
      <div className="bottom-section">
        <div className={divClasses}>
          <TextInput
            autoComplete="new-password"
            type="password"
            placeholder={SET_NEW_PASSWORD}
            labelText={SET_NEW_PASSWORD}
            value={newPassword}
            testId="user_password"
            errorText={errorMessage || ''}
            onChange={value => handleNewPasswordChange(value)}
          />
        </div>
      </div>
    );
  };

  const handleBirthdayChange = (value: number, type: 'month' | 'date' | 'year') => {
    setErrors({ ...errors, birthday: null });
    if (type === 'month') {
      setBirthdayMonth(value);
    } else if (type === 'date') {
      setBirthdayDate(value);
    } else if (type === 'year') {
      setBirthdayYear(value);
    }
  };

  const inchesToString = (inches: number) => {
    const ft = Math.floor(inches / 12);
    const inch = Math.round(inches - ft * 12);
    return `${ft}' ${inch}\"`;
  };

  const calorieDiv = () => {
    const { metric } = profileFormUser;
    let defaultHeight = Number(profileFormUser.height);
    defaultHeight = Math.round(metric ? defaultHeight : defaultHeight / CM_IN_INCH);
    let defaultWeight = Number(profileFormUser.weight);
    defaultWeight = Math.round(metric ? defaultWeight : defaultWeight / KG_IN_LB);
    const heightOptions = [{ label: `${UNSPECIFIED}`, value: '' }];
    const weightOptions = [{ label: `${UNSPECIFIED}`, value: '' }];
    let height;
    let initHeight;
    let endHeight;
    let weight;
    let initWeight;
    let endWeight;
    if (metric) {
      initHeight = 100;
      endHeight = 300;
      initWeight = 23;
      endWeight = 408;
      for (height = initHeight; height < endHeight; height++) {
        heightOptions.push({ label: `${height} cm`, value: String(height) });
      }
      for (weight = initWeight; weight < endWeight; weight++) {
        weightOptions.push({ label: `${weight} kg`, value: String(weight) });
      }
    } else {
      initHeight = 36;
      endHeight = 120;
      initWeight = 50;
      endWeight = 900;
      for (height = initHeight; height < endHeight; height++) {
        heightOptions.push({ label: inchesToString(height), value: String(height) });
      }
      for (weight = initWeight; weight < endWeight; weight++) {
        weightOptions.push({ label: `${weight} lbs`, value: String(weight) });
      }
    }

    return (
      <div className="calorie-counter-info clear clearfix bottom-section">
        <label className="bold-label string optional">{CALORIE_INFO}</label>
        <div id="calorie-counter-info">
          <div className={styles.calorieContainer}>
            <div className={styles.flexRow}>
              <div className={styles.calorieSection}>
                <Select
                  labelText={HEIGHT}
                  onChange={value => handleUserValues(value, 'height')}
                  options={heightOptions}
                  testId="user-height-select"
                  placeholder={HEIGHT}
                  value={String(defaultHeight)}
                />
              </div>
              <div className={styles.calorieSection}>
                <Select
                  labelText={WEIGHT}
                  onChange={value => handleUserValues(value, 'weight')}
                  options={weightOptions}
                  testId="user-weight-select"
                  placeholder={WEIGHT}
                  value={String(defaultWeight)}
                />
              </div>
            </div>
            <div className={styles.birthdayContainer}>
              <div className={styles.birthdayLabel}>{BIRTHDAY}</div>
              <BirthdayInput
                birthdayMonth={birthdayMonth}
                birthdayDate={birthdayDate}
                birthdayYear={birthdayYear}
                handleBirthdayChange={handleBirthdayChange}
                error={errors?.birthday}
                languageRegionCode={languageRegionCode}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderLanguageBlock = () => {
    const selectLanguageOptions = enabledLanguages.map(language => ({
      label: language.name,
      value: language.language_region_code
    }));

    const defaultVal = currentUser.language || 'en-US';

    return (
      <div className="clear clearfix bottom-section">
        <Select
          labelText={LANGUAGE}
          onChange={value => handleUserValues(value, 'language')}
          options={selectLanguageOptions}
          testId="user-language-select"
          placeholder={LANGUAGE}
          value={profileFormUser.language || defaultVal}
        />
      </div>
    );
  };

  const activitiesOptions = [] as JSX.Element[];
  activities.forEach((item, i) => {
    activitiesOptions.push(createActivityInput(item, profileFormUser.activities));
  });

  const formErrorMessage = errors?.formError && (
    <div className="submit-buttons">
      <span className="error">{errors.formError[0]}</span>
    </div>
  );

  return (
    <div className="edit-profile-container-form">
      <div className="small-content">
        <UserAvatar user={user} publicProfile={false} />
      </div>
      <div className="large-content">
        <div className="firstLastName">
          {textInputComponent('firstName', true)}
          {textInputComponent('lastName', true)}
        </div>
        {textInputComponent('email')}
        <PhoneInput
          className={styles.phoneInput}
          errorText={errors?.phone}
          helperText={PHONE_HELPER_TEXT}
          labelText={PHONE_NUMBER}
          languageRegionCode={languageRegionCode}
          onChange={phone => {
            // phone can come back undefined but we always want to send an empty string back to the server in order to "delete" a user's number
            setProfileFormUser({ ...profileFormUser, phone: phone || '' });
          }}
          testId="phone-input"
          value={profileFormUser.phone}
        />
        <div className="bottom-section">
          <TextArea
            labelText={ABOUT_ME}
            errorText={errors?.aboutMe}
            testId="user_about_me"
            placeholder={ABOUT_ME}
            rows={3}
            value={profileFormUser.aboutMe || ''}
            onChange={value => handleUserValues(value, 'aboutMe')}
          />
        </div>
        <div className="clear clearfix bottom-section">
          <div id="activities" className="filter">
            <dl className="activities light">
              <label className="bold-label">{FAVORITE_ACTIVITIES}</label>
              <div className="activities-options">{activitiesOptions}</div>
            </dl>
          </div>
        </div>
        <div className="clear bottom-section">
          <label className="bold-label string optional xlate-google">{createLocationLabelText()}</label>
          <AlgoliaSearchBox
            className={styles.algoliaSearchBox}
            clearOnSelect
            configs={global.__AT_DATA__.algoliaConfig}
            isMobileBrowser={mobileBrowser}
            languageRegionCode={languageRegionCode}
            onResultSelect={handlePlaceSelection}
            placeholder={CHANGE_LOCATION}
            searchTypes={placeSearchTypes}
            testId="change-member-location"
          />
        </div>
        <div className="clear clearfix bottom-section">
          <label className="bold-label string optional">{UNITS}</label>
          <div className="radio-container">
            <div className="radio-group">
              <Radio
                testId="user_imperial"
                labelElement={<span>{IMPERIAL}</span>}
                value="1"
                selected={!profileFormUser.metric}
                onChange={value => handleUserValues(value, 'imperial')}
                verticalAlignment="center"
              />
            </div>
            <div className="radio-group">
              <Radio
                testId="user_metric"
                labelElement={<span>{METRIC}</span>}
                value="1"
                selected={profileFormUser.metric}
                onChange={value => handleUserValues(value, 'metric')}
                verticalAlignment="center"
              />
            </div>
          </div>
        </div>
        <div className="clear clearfix bottom-section">
          <label className="bold-label string optional">{RECORDING_PREFERENCE}</label>
          <div className="radio-container">
            <div className="radio-group">
              <Radio
                testId="user_speed"
                labelElement={<span>{SPEED}</span>}
                value="1"
                selected={profileFormUser.displaySpeed}
                onChange={value => handleUserValues(value, 'displaySpeed')}
                verticalAlignment="center"
              />
            </div>
            <div className="radio-group">
              <Radio
                testId="user_pace"
                labelElement={<span>{PACE}</span>}
                value="1"
                selected={!profileFormUser.displaySpeed}
                onChange={value => handleUserValues(value, 'pace')}
                verticalAlignment="center"
              />
            </div>
          </div>
        </div>
        {calorieDiv()}
        {renderLanguageBlock()}
        {passwordDiv()}
        <div className="submit-buttons">
          <Button
            text={CANCEL}
            linkInfo={{
              href: LanguageSupportUtil.wrapUrlSafe('/my/profile', languageRegionCode)
            }}
            testId="cancel-edit-profile"
            variant="flat"
          />
          <Button text={SAVE} onClick={handleSubmit} type="submit" testId="submit-edit-profile" variant="primary" />
        </div>
        {formErrorMessage}
      </div>
    </div>
  );
};

// needed to bypass error related to translations
// Could not find required `intl` object. <IntlProvider> needs to exist in the component ancestry
export default function ProfileEditFormWrapper({ currentUser, user, activities, enabledLanguages, mobileBrowser }: Props) {
  return (
    <CustomProvider>
      <ProfileEditForm
        currentUser={currentUser}
        user={user}
        activities={activities}
        enabledLanguages={enabledLanguages}
        mobileBrowser={mobileBrowser}
      />
    </CustomProvider>
  );
}
