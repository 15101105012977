import PropTypes from 'prop-types';

export default PropTypes.shape({
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  id: PropTypes.number.isRequired,
  slug: PropTypes.string,
  location: PropTypes.shape({
    city_id: PropTypes.number,
    city: PropTypes.string,
    country: PropTypes.umber,
    country_name: PropTypes.string,
    latitude: PropTypes.number,
    longitude: PropTypes.number,
    regionName: PropTypes.string
  })
});
