import { Suspense, lazy } from 'react';
import postal from 'postal';
import mapboxgl from 'mapbox-gl';
import turfDistance from '@turf/distance';
import isEqual from 'underscore/modules/isEqual';
import isEmpty from 'underscore/modules/isEmpty';
import { useIntl, FormattedMessage } from '@alltrails/shared/react-intl';
import { getLines } from '@alltrails/maps/utils/legacyGeoJSONConversions';
import PageTitle from '@alltrails/shared/types/PageTitle';
import getPageTitle from '@alltrails/shared/utils/getPageTitle';
import { MapStatsUtil } from 'utils/map_stats_util';
import CustomProvider from 'components/CustomProvider';
import Logo from '@alltrails/shared/denali/components/Logo';
import { additionalStyleLoaders } from 'utils/mapbox/additionalStyleLoaders';
import hasPermission from 'utils/hasPermission';
import { MapMixin } from '../mixins/map/map_mixin';
import ZoomControls from '../../../../components/map_controls/ZoomControls';
import { fitMapToBounds } from '../../../../utils/mapbox/map_helpers';
import { addAtMap } from '../../../../utils/mapbox/overlays/at_map';
import { getLocationFromLongLat } from '../../../../utils/requests/print_map_requests';
import { GridAxes } from './GridAxes';
import { GridLines } from './GridLines';
import { DeclinationScale } from './DeclinationScale';
import { DistanceScale } from './DistanceScale';
import { LIST, MAP, TRACK, TRAIL } from '../../../../utils/constants/MapTypes';
import { LanguageSupportUtil } from '../../../../utils/language_support_util';
import MapSelection from '../../../../components/MapSelection';
import { Page } from '../../../../components/shared/Page/Page';
import { GridUtils } from './GridUtils';

const PrintElevationChart = lazy(() => import('@alltrails/maps/components/PrintElevationChart'));

// eslint-disable-next-line @typescript-eslint/no-var-requires
const createReactClass = require('create-react-class');

const BasePrintMap = createReactClass({
  mixins: [MapMixin],
  getInitialState() {
    return {
      currentMapLayer: this.props.mapLayer,
      enabledOverlays: [],
      mapZoom: this.props.scale,
      declination: null,
      mapCenter: null,
      bounds: {},
      reactMap: null,
      isZoomActive: false,
      city: '',
      region: '',
      isMapChanged: false
    };
  },
  componentDidUpdate(prevProps, prevState) {
    if (!this.state.isMapReady) {
      return;
    }

    const map = this.mbMap;
    const bounds = map.getBounds();
    const mapCenter = map.getCenter();

    if (!prevState.isMapReady) {
      const { exploreMap } = this.props;
      if (exploreMap) {
        // Draw atMap
        const newBounds = new mapboxgl.LngLatBounds();
        const pageTitle = getPageTitle(window?.location?.pathname);
        const isMapCreatorPage = pageTitle === PageTitle.ExploreMapDetails && !this.props.exploreMap?.originalAtMapId;
        addAtMap({ map, atMap: exploreMap, renderWaypointPopup: this.renderWaypointPopup, newBounds, isMapCreatorPage });
        // Only fit map to bounds if requested
        if (this.props.fitBounds) {
          fitMapToBounds(map, newBounds);
        }
      }
      if (this.props.enabledOverlays) {
        const enabledOverlays = [...this.state.enabledOverlays];
        const overlayConfigParams = this.getOverlayConfigParams();
        this.props.enabledOverlays.forEach(overlay => {
          if (!enabledOverlays.includes(overlay)) {
            const styleLoader = additionalStyleLoaders[overlay];
            styleLoader.add(this.mbMap, overlayConfigParams);
            enabledOverlays.push(overlay);
          }
        });
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ enabledOverlays }, this.getOverlaysChangedCb());
      }
      if (bounds) {
        this.updateMapBounds(bounds);
      }
    }

    if (this.state.enabledOverlays !== prevState.enabledOverlays) {
      this.props.setOverlays(this.state.enabledOverlays);
    }
    if (this.state.isMapReady !== prevState.isMapReady || !isEqual(this.props.exploreMap, prevProps.exploreMap)) {
      if (this.state.mapZoom < 0) {
        const newMapCenter = { lng: this.props.mapCenterLng, lat: this.props.mapCenterLat };
        const scaledZoom = GridUtils.getScaledZoom(this.state.mapZoom, newMapCenter);
        this.updateMapCenterProps(map, newMapCenter, scaledZoom, bounds, true, true);
      } else {
        this.updateMapProps(mapCenter, { sw: bounds.getSouthWest(), ne: bounds.getNorthEast() }, this.state.mapZoom);
      }
    }
    if (
      (this.props.paperSize !== prevProps.paperSize && this.props.paperSize) ||
      (this.props.orientation !== prevProps.orientation && this.props.orientation) ||
      (this.props.paperDimensionWidth !== prevProps.paperDimensionWidth && this.props.paperDimensionWidth) ||
      (this.props.paperDimensionHeight !== prevProps.paperDimensionHeight && this.props.paperDimensionHeight)
    ) {
      map.resize();
    }
    if (this.props.scale !== prevProps.scale && this.props.scale) {
      const scaledZoom = GridUtils.getScaledZoom(this.props.scale, mapCenter);
      this.updateMapCenterProps(map, mapCenter, scaledZoom, bounds, true);
    }
    if (this.state.currentMapLayer !== prevState.currentMapLayer && this.state.currentMapLayer) {
      this.props.setMapLayer(this.state.currentMapLayer);
    }
    if (this.state.isZoomActive !== prevState.isZoomActive && this.state.isZoomActive) {
      const mapZoom = map.getZoom();
      const scaledZoom = GridUtils.getScaledZoom(mapZoom, mapCenter);
      this.updateMapCenterProps(map, mapCenter, scaledZoom, bounds);
    }
    if (this.state.isMapChanged !== prevState.isMapChanged) {
      const mapZoom = map.getZoom();
      const scaledZoom = GridUtils.getScaledZoom(mapZoom, mapCenter);
      this.updateMapCenterProps(map, mapCenter, scaledZoom, bounds);
    }
  },
  updateMapCenterProps(map, mapCenter, scaledZoom, bounds, isScale, isMount) {
    // update map center longitude/latitude and zoom
    if (!isMount) {
      this.props.setMapCenterLat(mapCenter?.lat);
      this.props.setMapCenterLng(mapCenter?.lng);
    }
    if (isScale) {
      map.jumpTo({
        center: mapCenter,
        zoom: scaledZoom.fractionalZoom
      });
    }
    this.props.setScale(scaledZoom.atMapZoom);
    this.updateMapProps(mapCenter, { sw: bounds.getSouthWest(), ne: bounds.getNorthEast() }, scaledZoom.fractionalZoom);
  },
  updateMapBounds(bounds) {
    this.setState({ bounds });
  },
  onMouseUp() {
    this.setState({ isMapChanged: true });
  },
  updateMapProps(mapCenter, bounds, mapZoom) {
    this.setState({
      // eslint-disable-next-line no-undef
      declination: AT.Shared.MapUtils.Declination().compute(mapCenter.lat, mapCenter.lng),
      reactMap: this,
      mapZoom,
      bounds,
      mapCenter,
      isZoomActive: false,
      isMapChanged: false
    });
  },
  zoomInMap() {
    this.setState({ isZoomActive: true }, this.zoomInClicked);
  },
  zoomOutMap() {
    this.setState({ isZoomActive: true }, this.zoomOutClicked);
  },
  componentDidMount() {
    if (!this.listeners) {
      this.listeners = [];
    }
    // use initialCenter lat&lng from url params for drawing map
    this.initLegacyMap(true);

    const { exploreMap, initialCenter } = this.props;
    const location = exploreMap?.location;
    if (initialCenter && !location?.city && !location?.region) {
      getLocationFromLongLat(initialCenter[1], initialCenter[0], this.props.mapboxAccessToken)
        .then(response => {
          this.setState({
            city: response?.features[0]?.text,
            region: response?.features[1]?.properties?.short_code.split('-')[1].toString()
          });
        })
        .catch(() => console.log('Error getting location'));
    }
  },
  // eslint-disable-next-line react/no-unused-class-component-methods
  distanceBetween(lngLat1, lngLat2) {
    return 1000.0 * turfDistance(lngLat1, lngLat2);
  },
  renderGainLoss() {
    const { exploreMap, context } = this.props;

    const elevationGain = context.displayMetric
      ? exploreMap.summaryStats.elevationGain
      : MapStatsUtil.metersToFeet(exploreMap.summaryStats.elevationGain);
    const elevationLoss = context.displayMetric
      ? exploreMap.summaryStats.elevationLoss
      : MapStatsUtil.metersToFeet(exploreMap.summaryStats.elevationLoss);
    return (
      <div className="elevationGainLossContainer">
        <FormattedMessage defaultMessage="Gain" />:
        <span className="secondPart">
          {elevationGain} {context.displayMetric ? 'm' : 'ft'}
        </span>
        <span className="verticalDivider" />
        <FormattedMessage defaultMessage="Loss" />:
        <span className="secondPart">
          {elevationLoss} {context.displayMetric ? 'm' : 'ft'}
        </span>
      </div>
    );
  },
  renderElevationPane() {
    const { exploreMap } = this.props;

    const lines = getLines(exploreMap);

    if (lines.length < 1) {
      return null;
    }

    return (
      <>
        {this.renderGainLoss()}
        <Suspense fallback={<div>Loading...</div>}>
          <PrintElevationChart
            map={exploreMap}
            displayMetric={this.props.context.displayMetric}
            handleMouseOver={this.handleElevationPaneMouseOver}
          />
        </Suspense>
      </>
    );
  },
  formatViewPath() {
    const typeMap = {
      [MAP]: '/explore/map',
      [LIST]: '/lists',
      [TRACK]: '/explore/recording',
      [TRAIL]: '/trail'
    };

    const prefix = typeMap[this.props.exploreMap?.type];
    let pathStart = '';

    if (prefix) {
      pathStart = LanguageSupportUtil.wrapUrlSafe(prefix, this.props.context.languageRegionCode);
    }

    return `${pathStart}/${this.props.exploreMap?.slug}`;
  },
  formatQRCodeSrc(window) {
    const urlBase = `${window.location.protocol}//${window.location.host}`;
    return `${urlBase}/qrcode${this.formatViewPath()}?disposition=attachment`;
  },
  onMapWheel() {
    this.setState({ isZoomActive: !this.state.isZoomActive });
  },
  getDisplayLocation(exploreMap) {
    const location = exploreMap?.location;
    if (location?.city && location?.region) {
      return `${location?.city}, ${location?.region}`;
    }
    if (this.state.city !== '' && this.state.region !== '') {
      return `${this.state.city}, ${this.state.region}`;
    }
    return '';
  },
  getCustomPaperDimensions() {
    let width;
    let height;
    if (this.props.orientation === 'portrait') {
      width = this.props.paperDimensionWidth;
      height = this.props.paperDimensionHeight;
    } else {
      width = this.props.paperDimensionHeight;
      height = this.props.paperDimensionWidth;
    }

    return {
      width,
      height
    };
  },
  render() {
    const permissions = this.getPermissions();
    const qrCodeSrc = typeof window !== 'undefined' ? this.formatQRCodeSrc(window) : '';

    return (
      <Page context={this.props}>
        <div className="mapContainer">
          <div
            className={`mapDetailsContainer ${[this.props.paperSize]} ${[this.props.orientation]}`}
            style={{
              width: this.props.paperSize === 'custom' ? `${this.getCustomPaperDimensions()?.width}in` : ''
            }}
          >
            <div className="fullMap">
              {this.state.isMapReady && !isEmpty(this.state.bounds) && (
                <GridAxes
                  {...this.props}
                  bounds={this.state.bounds}
                  mapZoom={this.state.mapZoom}
                  grid={this.props.grid}
                  paperSize={this.props.paperSize}
                  orientation={this.props.orientation}
                  isMobileWidth={this.props.isMobileWidth}
                  paperDimensionWidth={this.props.paperDimensionWidth}
                  paperDimensionHeight={this.props.paperDimensionHeight}
                  intl={this.props.intl}
                />
              )}
              {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
              <div
                id="print-map"
                className="printMap"
                onWheel={e => this.onMapWheel(e)}
                onMouseUp={this.onMouseUp}
                // adjust width and height for custom maps
                style={{
                  // eslint-disable-next-line no-unsafe-optional-chaining
                  width: this.props.paperSize === 'custom' ? `${this.getCustomPaperDimensions()?.width - 0.5}in` : '',
                  // eslint-disable-next-line no-unsafe-optional-chaining
                  height: this.props.paperSize === 'custom' ? `${this.getCustomPaperDimensions()?.height - 3}in` : ''
                }}
              >
                <div id={this.props.mapDivId} className="full-height" />
              </div>
              <div id="print-map-controls" className="atNoprint">
                <ZoomControls handleZoomInClick={this.zoomInMap} handleZoomOutClick={this.zoomOutMap} handleCurrLocClick={this.compassClick} />
              </div>
              <MapSelection
                enabledOverlays={this.state.enabledOverlays}
                onActivityFilterChange={this.handleActivityFilterChange}
                selectedFilterActivity={this.state.selectedFilterActivity}
                currentMapLayer={this.state.currentMapLayer}
                onCardClick={this.handleCardClick}
                onHeatmapClick={this.handleHeatmapOverlayClick}
                permissions={permissions}
                terrainActive={this.state.terrainActive}
                handleResetClick={this.disableAllOverlays}
                adminLayout={hasPermission({ permission: 'trails:manage' })}
                trailId={this.props.exploreMap?.type === 'trail' ? this.props.exploreMap.ID : undefined}
                terrainDisabled
              />
              {this.state.isMapReady && !isEmpty(this.state.bounds) && (
                <GridLines
                  {...this.props}
                  bounds={this.state.bounds}
                  mapZoom={this.state.mapZoom}
                  grid={this.props.grid}
                  paperSize={this.props.paperSize}
                  scale={this.props.scale}
                  orientation={this.props.orientation}
                  isMobileWidth={this.props.isMobileWidth}
                  paperDimensionWidth={this.props.paperDimensionWidth}
                  paperDimensionHeight={this.props.paperDimensionHeight}
                  intl={this.props.intl}
                />
              )}
            </div>
            <div className="detailsContainer">
              <span className="mapDivider" />
              <div
                className="scalesContainer"
                style={{
                  width: this.props.paperSize === 'custom' ? `${this.getCustomPaperDimensions()?.width}in` : ''
                }}
              >
                <div className="leftScalesContainer">{typeof window !== 'undefined' && <img className="qrCode" alt="QRCode" src={qrCodeSrc} />}</div>
                <div className="centeredScaleContainer">
                  <DeclinationScale
                    {...this.props}
                    map={this.mbMap}
                    declination={this.state.declination}
                    isMobileWidth={this.props.isMobileWidth}
                    grid={this.props.grid}
                    paperSize={this.props.paperSize}
                    scale={this.props.scale}
                    orientation={this.props.orientation}
                  />
                  {this.state.isMapReady && !isEmpty(this.state.bounds) && (
                    <DistanceScale
                      {...this.props}
                      map={this.mbMap}
                      bounds={this.state.bounds}
                      mapCenter={this.state.mapCenter}
                      reactMap={this.state.reactMap}
                      renderElevationPane={this.renderElevationPane}
                      paperDimensionWidth={this.props.paperDimensionWidth}
                      paperDimensionHeight={this.props.paperDimensionHeight}
                      paperSize={this.props.paperSize}
                      getCustomPaperDimensions={this.getCustomPaperDimensions}
                    />
                  )}
                </div>
                <div className="rightScalesContainer">
                  <div id="pdf-elevation" className="pdfElevation">
                    {this.renderElevationPane()}
                  </div>
                </div>
              </div>
              <span className="mapDivider" />
              <div className="details">
                <div className="leftContainer">
                  <span className="trailTitle">{this.props.exploreMap?.name}</span>
                  <span className="trailLocation">{this.getDisplayLocation(this.props.exploreMap)}</span>
                </div>
                <div className="rightContainer">
                  <Logo size="sm" variant="logomark" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Page>
    );
  }
});

const PrintMapIntlProvider = props => {
  const intl = useIntl();
  return <BasePrintMap {...props} intl={intl} />;
};

const PrintMap = props => (
  <CustomProvider>
    <PrintMapIntlProvider {...props} />
  </CustomProvider>
);

PrintMap.defaultProps = {
  mapDivId: 'fullscreen-search-map',
  mapProviderName: 'mapBox',
  messagingChannel: postal.channel('printMap_messagingChannel'),
  containerSelector: '#pdf-map-page',
  fitBounds: true,
  setPrintFlags: true,
  currentPage: 'PRINT',
  mapLayer: 'alltrailsOutdoorsV2'
};

// eslint-disable-next-line import/prefer-default-export
export { PrintMap };
