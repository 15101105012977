import { string, func } from 'prop-types';
import * as styles from './styles/styles.module.scss';

// ATR-162 Do not use COLOR_MAP_CUSTOM_ROUTE_1 etc at this time, as they do not work (they blend-in too much, making route lines nearly invisible)
const colors = ['#FF0000', '#428A13', '#219AD8', '#F5D24B', '#BE50C8'];
export const DEFAULT_ROUTE_COLOR = colors[0];

const RouteColorRadioButtons = ({ onChange, activeColor }) => {
  const radioButtons = colors.map(color => {
    const id = `${color}-color`.replace('#', '');

    return (
      <div className={styles.container} key={id}>
        <input
          aria-label={id}
          className={styles.radiobutton}
          value={color}
          onChange={onChange}
          id={id}
          type="radio"
          checked={activeColor === color}
        />
        <label htmlFor={id} style={{ backgroundColor: color }} />
      </div>
    );
  });

  return <div className={styles.formContainer}>{radioButtons}</div>;
};

RouteColorRadioButtons.propTypes = {
  onChange: func.isRequired,
  activeColor: string
};

RouteColorRadioButtons.defaultProps = {
  activeColor: colors[0]
};

export default RouteColorRadioButtons;
