// Mapbox API when it takes latitude and longitude it expects that the coordinate pair will
// be in the format of longitude,latitude but we instruct users to put in their latitude,longitude
// query match code taken from: https://docs.mapbox.com/mapbox-gl-js/example/mapbox-gl-geocoder-accept-coordinates/

const coordinatesGeocoder = query => {
  // match anything which looks like a decimal degrees coordinate pair
  const matches = query.match(/^[ ]*(?:Lat: )?(-?\d+\.?\d*)[, ]+(?:Lng: )?(-?\d+\.?\d*)[ ]*$/i);
  if (!matches) {
    return query;
  }

  const coord1 = Number(matches[1]);
  const coord2 = Number(matches[2]);

  // If the coordinate doesn't have a possible latitude,longitude coordinate then use to longitude,latitude
  return coord1 < -90 || coord1 > 90 ? `${coord1},${coord2}` : `${coord2},${coord1}`;
};

export { coordinatesGeocoder };
