import { COLOR_UI_HIGHLIGHT, COLOR_GREEN_700 } from '@alltrails/shared/denali/tokens';
import hasPermission from 'utils/hasPermission';
import { addLayer, removeLayer, addLayerEvent } from '../layers/layer_helpers';
import { addOrUpdateGeojsonSource } from '../sources/geojson_helpers';
import { addHoverPopupToMap } from '../event_handling/popup_helpers';
import { removeSource } from '../sources/source_helpers';

const NEW_POLYLINE_COORDINATES = [-1, 0, 1, 0];

const getNewPolylineLayerId = (id, i) => id + i;

// eslint-disable-next-line default-param-last
const addPolylines = (map, id, geojson, sourceOpts = {}, opts = {}, addUnderLayerTypes = null, isMapCreatorPage, clickBuffer = 0) => {
  // Even though we have a default parameter value some callers may pass in null or undefined.
  opts = opts || {};

  addOrUpdateGeojsonSource(map, id, geojson, sourceOpts);
  if (map.getLayer(id)) {
    return;
  }

  const isNewPolylineColor = !hasPermission({ permission: 'trails:manage' });

  // when drawing a map isMapCreatorPage is undefined
  if (isMapCreatorPage !== undefined && isNewPolylineColor && !isMapCreatorPage) {
    // loop through the coordinates - for each coordinate add layer with polyline
    NEW_POLYLINE_COORDINATES.forEach((coord, i) => {
      addLayer(
        map,
        {
          id: getNewPolylineLayerId(id, i),
          type: 'line',
          source: id,
          layout: {
            'line-join': 'round',
            'line-cap': 'round'
          },
          paint: {
            // eslint-disable-next-line no-nested-ternary
            'line-color': opts['line-color'] ? opts['line-color'] : i === 3 ? COLOR_UI_HIGHLIGHT : COLOR_GREEN_700,
            'line-width': i === 3 ? 4 : 2,
            'line-offset': coord * 3
          }
        },
        addUnderLayerTypes,
        'osm-alltrails-lines-public'
      );
    });
  } else {
    addLayer(
      map,
      {
        id,
        type: 'line',
        source: id,
        paint: {
          'line-color': opts['line-color'] ? opts['line-color'] : ['coalesce', ['get', 'color'], 'red'],
          'line-width': opts['line-width'] || 5
        }
      },
      addUnderLayerTypes
    );
    if (clickBuffer > 0) {
      // this layer will be bound to the polylines mouseover and mouseout events if a click buffer is set
      addLayer(
        map,
        {
          id: `${id}-clickBuffer`,
          type: 'line',
          source: id,
          paint: {
            'line-width': (opts['line-width'] || 5) + clickBuffer * 2,
            'line-opacity': 0
          }
        },
        addUnderLayerTypes
      );
    }
  }
  if (opts && opts.renderPopup) {
    addLayerEvent(map, id, true, 'mouseenter', () => {
      map.getCanvas().style.cursor = 'pointer';
    });
    addLayerEvent(map, id, true, 'mouseleave', () => {
      map.getCanvas().style.cursor = map.customStyle.cursor;
    });

    addHoverPopupToMap(map, id, opts.renderPopup);
  }
};

const updatePolylines = (map, id, key, primaryValue, secondaryValue) => {
  // We don't want to check permissions in this method because a user can log in via the sign up modal and can cause a mismatch in layer names between
  // this method and addPolylines. Instead, we want to keep using the layers that are already on the map and just update them.
  if (map.getLayer(id)) {
    map.setPaintProperty(id, key, primaryValue);
  } else {
    NEW_POLYLINE_COORDINATES.forEach((_, i) => {
      if (!map.getLayer(getNewPolylineLayerId(id, i))) return;

      if (i === 3) {
        map.setPaintProperty(getNewPolylineLayerId(id, i), key, primaryValue);
      } else {
        map.setPaintProperty(getNewPolylineLayerId(id, i), key, secondaryValue ?? primaryValue);
      }
    });
  }
};

const removePolylines = (map, id) => {
  if (!hasPermission({ permission: 'trails:manage' })) {
    NEW_POLYLINE_COORDINATES.forEach((_, i) => {
      removeLayer(map, getNewPolylineLayerId(id, i));
    });
  } else {
    removeLayer(map, id);
    removeLayer(map, `${id}-clickBuffer`);
  }
  removeSource(map, id);
};

export { addPolylines, removePolylines, updatePolylines };
