import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import useUser from 'hooks/useUser';
import { FormattedMessage } from '@alltrails/shared/react-intl';
import * as styles from './ProStatus.module.scss';

export default function ProSubscriberSince() {
  const languageRegionCode = useLanguageRegionCode();
  const user = useUser();
  const formatter = new Intl.DateTimeFormat(languageRegionCode, { dateStyle: 'long' });

  return (
    <div className={styles.settingsPro}>
      <span className={styles.subtitle}>
        <FormattedMessage defaultMessage="Subscriber since:" />{' '}
      </span>
      <span>{user.account.whenPaid ? formatter.format(new Date(user.account.whenPaid)) : <FormattedMessage defaultMessage="Unknown" />}</span>
    </div>
  );
}
