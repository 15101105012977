import { destroy, get, post } from '@alltrails/shared/api';
import { SavedFilters, SavedFilterSet } from 'utils/filters';

export async function getSavedFilterSets() {
  return get<SavedFilterSet[]>('/api/alltrails/v2/saved_filters');
}

export async function saveFilterSet(name: string, filters: SavedFilters) {
  return post<SavedFilterSet[]>('/api/alltrails/v2/saved_filters', {
    name,
    filters
  });
}

export async function deleteSavedFilterSet(id: number) {
  return destroy(`/api/alltrails/v2/saved_filters/${id}`);
}
