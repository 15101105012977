import { lazy, Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import sortUgc from 'api/UgcSorting';
import ShowMoreItems from 'components/location/ShowMoreItems';
import TrailUgcBar from 'components/trailUgc/TrailUgcBar';
import type { TrailUgcBarProps } from 'components/trailUgc/TrailUgcBar';
import UgcSortDropdown from 'components/shared/UgcSortDropdown';
import { useTrailUgcContext } from 'components/trailUgc/TrailUgcContext';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import type { TrailId } from '@alltrails/shared/types/trail';
import { ServerCommunicationUtil } from 'utils/server_communication_util';
import logError from 'utils/logError';
import logTrailDetailsSeeAllClicked from '@alltrails/analytics/events/logTrailDetailsSeeAllClicked';
import logTrailDetailsSortChanged from '@alltrails/analytics/events/logTrailDetailsSortChanged';
import CarouselDisplayTrigger from '@alltrails/analytics/enums/CarouselDisplayTrigger';
import { FormattedMessage } from '@alltrails/shared/react-intl';
import useToggle from '@alltrails/shared/hooks/useToggle';
import { getAnalyticsSeeAllLocation, getAnalyticsSort, getAnalyticsSortLocation } from '../utils/trailUgcAnalyticsHelpers';
import RatingsBreakdown from '../types/RatingsBreakdown';
import SortOption, { PresentationType } from '../types/SortOption';
import TrailUgcTab from '../types/TrailUgcTab';

const SignupModal = lazy(() => import('components/SignupModal'));

const ACTIVE_TAB_MAP: Readonly<Record<TrailUgcTab, React.ReactNode>> = {
  reviews: <FormattedMessage defaultMessage="Show more reviews" />,
  photos: <FormattedMessage defaultMessage="Show more photos" />,
  completed: <FormattedMessage defaultMessage="Show more completed" />,
  tracks: <FormattedMessage defaultMessage="Show more activities" />
};

const MODAL_TRIGGER_MAP: Readonly<Record<TrailUgcTab, CarouselDisplayTrigger>> = {
  reviews: CarouselDisplayTrigger.SeeMoreReviews,
  photos: CarouselDisplayTrigger.SeeMorePhotos,
  completed: CarouselDisplayTrigger.SeeMoreCompleted,
  tracks: CarouselDisplayTrigger.SeeMoreActivities
};

export type UgcContentProps = {
  locationServerData: unknown;
  perPage?: number;
  trailId?: TrailId;
  userId?: number;
};

export type SortConfig = {
  availableSorts: SortOption[];
  hideSortLabel?: boolean;
  onSortSuccess: (data: any, newSort: SortOption) => void;
  sortUrl: string;
  presentationType?: PresentationType;
};

type UgcContentStateType<T> = {
  items: T[] | null;
  activeTab: TrailUgcTab;
  pageCount: number;
  maxItems: number;
  locationServerData: any;
  userId: number;
  perPage: number;
  handleMoreLoaded: (data: any) => void;
  trailId?: TrailId;
  sortConfig?: SortConfig;
  ratingsBreakdown?: RatingsBreakdown;
  showContributeButton?: boolean;
  showInvitation?: boolean;
  avgRating?: string;
};
export default function useUgcContentState<T>({
  items,
  activeTab,
  pageCount,
  maxItems,
  locationServerData,
  userId,
  perPage,
  handleMoreLoaded,
  trailId,
  sortConfig,
  ratingsBreakdown,
  showContributeButton,
  showInvitation,
  avgRating
}: UgcContentStateType<T>) {
  const languageRegionCode = useLanguageRegionCode();
  const { dispatch } = useTrailUgcContext();
  const [sortOption, setSortOption] = useState<SortOption | null>(sortConfig?.availableSorts[0] ?? null);
  const [loadingMore, setLoadingMore] = useState(false);
  const [formOpen, setFormOpen] = useState(false);
  const [signupModalIsOpen, toggleSignupModal] = useToggle(false);

  const onMoreLoaded = useCallback(
    (data: any) => {
      handleMoreLoaded(data);
      setLoadingMore(false);
    },
    [handleMoreLoaded]
  );

  const handleShowMore = useCallback(() => {
    if (trailId) {
      logTrailDetailsSeeAllClicked({ trail_id: trailId, location: getAnalyticsSeeAllLocation(activeTab) });
    }

    if (!userId) {
      toggleSignupModal();
    } else {
      setLoadingMore(true);
      dispatch({ type: 'SET_ALL_UGC_SHOWN', allShown: items?.length > maxItems });
      ServerCommunicationUtil.loadMoreContent(
        activeTab,
        locationServerData.country_id,
        locationServerData.type,
        locationServerData.loc_id,
        pageCount + 1 /* nextPage */,
        perPage,
        true /* includeTrail */,
        locationServerData.attribute,
        onMoreLoaded,
        false /* onlyWithParks */,
        sortOption,
        null /* parkType */,
        languageRegionCode
      );
    }
  }, [
    activeTab,
    dispatch,
    items?.length,
    languageRegionCode,
    locationServerData.attribute,
    locationServerData.country_id,
    locationServerData.loc_id,
    locationServerData.type,
    maxItems,
    onMoreLoaded,
    pageCount,
    perPage,
    sortOption,
    trailId,
    toggleSignupModal,
    userId
  ]);

  const openForm = useCallback(() => {
    setFormOpen(true);
  }, []);

  const closeForm = () => setFormOpen(false);

  const SortDropdown = useMemo(() => {
    const hidePhotoSort = activeTab === 'photos' && locationServerData.type !== 'trails';
    if (!sortConfig || hidePhotoSort) {
      return null;
    }

    const onSortChanged = (newSort: SortOption) => {
      logTrailDetailsSortChanged({
        trail_id: trailId,
        previous_sort_method: getAnalyticsSort(sortOption),
        sort_method_selected: getAnalyticsSort(newSort),
        sort_location: getAnalyticsSortLocation(activeTab)
      });
      setSortOption(newSort);
      sortUgc(sortConfig.sortUrl, newSort, perPage, locationServerData, sortConfig.presentationType)
        .then(response => sortConfig.onSortSuccess(response, newSort))
        .catch(e => logError(`Error sorting UGC: ${e}`));
    };

    return (
      <UgcSortDropdown
        onSortSelected={onSortChanged}
        enabledSortLinks={sortConfig.availableSorts}
        hideSortLabel={sortConfig.hideSortLabel}
        selectedSort={sortOption}
      />
    );
  }, [activeTab, locationServerData, perPage, sortConfig, sortOption, trailId]);

  const renderUgcBar = useCallback(
    (partialProps?: Partial<TrailUgcBarProps>) => (
      <TrailUgcBar
        activeTab={activeTab}
        itemCount={maxItems}
        loggedInUserId={!!userId}
        ratingsBreakdown={ratingsBreakdown}
        avgRating={avgRating}
        showContributeButton={showContributeButton}
        showInvitation={showInvitation}
        trailId={trailId}
        ugcSortDropDown={SortDropdown}
        openTrailFormModal={openForm}
        {...partialProps}
      />
    ),
    [activeTab, maxItems, userId, ratingsBreakdown, avgRating, showContributeButton, showInvitation, trailId, SortDropdown, openForm]
  );

  const renderUgcShowMore = useCallback(
    () => (
      <>
        {signupModalIsOpen && (
          <Suspense fallback={null}>
            <SignupModal
              trigger={MODAL_TRIGGER_MAP[activeTab]}
              isOpen={signupModalIsOpen}
              onRequestClose={toggleSignupModal}
              onSuccess={toggleSignupModal}
            />
          </Suspense>
        )}
        <ShowMoreItems
          config={{ whiteBackground: true, min: 1, max: items?.length ?? maxItems, total: maxItems }}
          title={loadingMore ? <FormattedMessage defaultMessage="Loading..." /> : ACTIVE_TAB_MAP[activeTab]}
          handler={handleShowMore}
        />
      </>
    ),
    [activeTab, handleShowMore, items?.length, loadingMore, maxItems, signupModalIsOpen, toggleSignupModal]
  );

  useEffect(() => {
    dispatch({ type: 'SET_ALL_UGC_SHOWN', allShown: items?.length > maxItems });
  }, []);

  return {
    formOpen,
    openForm,
    closeForm,
    renderUgcBar,
    renderUgcShowMore
  };
}
