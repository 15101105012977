import { useContext } from 'react';
import { useIntl, defineMessages } from '@alltrails/shared/react-intl';
import Button from '@alltrails/shared/denali/components/Button';

import LearnMore from '../LearnMore';
import { PrintContext } from '../../printContext';
import { Grids, GRID_LABELS } from '../../constants';
import useFormatMessage from '@alltrails/shared/hooks/useFormatMessage';

type Props = {
  isMobileWidth?: boolean;
};

const GRID_STRINGS = defineMessages({
  GRID: { defaultMessage: 'Grid' },
  CONTENT: {
    defaultMessage:
      'Decimal degrees (DD) express latitude and longitude geographic coordinates as decimal fractions of a degree. DD are used in many geographic information systems (GIS), web mapping applications such as OpenStreetMap, and GPS devices.\n\nGPS mapping programs and units default to DMS. It treats the earth as a sphere and covers the entire earth with a single coordinate system.\n\nUTM is a simplified system for land use. It breaks the surface of the earth into a large number of small sections and treats each one as a plane using meters as the coordinate units.'
  }
});

const Grid = ({ isMobileWidth = false }: Props) => {
  const {
    formattedDefaultMessages: { GRID, CONTENT }
  } = useFormatMessage(GRID_STRINGS);
  const { grid, setGrid } = useContext(PrintContext);
  const intl = useIntl();

  return (
    <div className="buttonRow">
      {Object.values(Grids).map(g => (
        <Button
          text={intl.formatMessage(GRID_LABELS[g])}
          className="settingsButton"
          key={`grid-${g}`}
          onClick={() => setGrid(g)}
          testId={intl.formatMessage(GRID_LABELS[g])}
          variant={grid === g ? 'primary' : 'default'}
        />
      ))}
      <LearnMore title={GRID} content={CONTENT} grid isMobileWidth={isMobileWidth} />
    </div>
  );
};

export default Grid;
