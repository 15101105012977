import { FormattedMessage, defineMessages } from '@alltrails/shared/react-intl';
import Modal from 'components/Modal';
import { useState } from 'react';
import useFormatMessage from '@alltrails/shared/hooks/useFormatMessage';
import { serverAjaxPromise } from 'utils/requests/request_helpers';
import { LanguageSupportUtil } from 'utils/language_support_util';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import * as styles from './styles/styles.module.scss';

type Props = {
  isOpen: boolean;
  toggle: () => void;
  trackId: number;
  userSlug: string;
};

const DELETE_MODAL_STRINGS = defineMessages({
  THIS_MAP_IS_ASSOCIATED: {
    defaultMessage: 'This map is associated with an active Live Share, so it cannot be deleted until you end your Live Share.'
  },
  OK: { defaultMessage: 'OK' },
  CANCEL: { defaultMessage: 'Cancel' }
});

function DeleteModal({ isOpen, toggle, trackId, userSlug }: Props) {
  const {
    formattedDefaultMessages: { THIS_MAP_IS_ASSOCIATED, OK, CANCEL }
  } = useFormatMessage(DELETE_MODAL_STRINGS);

  const [isSubmitting, setIsSubmitting] = useState<boolean>();
  const languageRegionCode = useLanguageRegionCode();

  async function handleSubmit() {
    setIsSubmitting(true);

    try {
      const result = await serverAjaxPromise({
        type: 'DELETE',
        url: `/api/alltrails/maps/${trackId}`
      });

      // this error case returns a 200 for the apps' sync sake, so look for whether to prompt the user in the JSON returned
      if (result && result.displayLifelinePrompt) {
        alert(THIS_MAP_IS_ASSOCIATED);
        setIsSubmitting(false);
      } else {
        const location = LanguageSupportUtil.wrapUrlSafe(`/explore/members/${userSlug}/recordings`, languageRegionCode);
        window.location.assign(location);
      }
    } catch {
      const location = LanguageSupportUtil.wrapUrlSafe(`/explore/members/${userSlug}/recordings`, languageRegionCode);
      window.location.assign(location);
    }
  }

  return (
    <Modal
      baseCtas={{
        primaryCta: {
          onClick: handleSubmit,
          text: OK,
          disabled: isSubmitting,
          testId: OK
        },
        secondaryCta: {
          onClick: toggle,
          text: CANCEL,
          testId: CANCEL
        }
      }}
      isOpen={isOpen}
      onRequestClose={toggle}
      title={<FormattedMessage defaultMessage="Are you sure?" />}
    >
      <div className={styles.deleteModalBody}>
        <FormattedMessage defaultMessage="Once you delete this map it will be gone permanently and cannot be undone." />
      </div>
    </Modal>
  );
}

export default DeleteModal;
