import { memo } from 'react';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import { FormattedMessage } from '@alltrails/shared/react-intl';
import Trail from 'types/Trails/Trail';
import useDisplayMetric from 'hooks/useDisplayMetric';
import * as styles from './styles/styles.module.scss';
import { MapStatsUtil } from '../../utils/map_stats_util';
import { translateRouteType } from '../../utils/trail_helpers';

type TrailStatsProps = {
  routeType?: string;
  trail: Trail;
};

const TrailStats = ({ trail, routeType }: TrailStatsProps) => {
  const displayMetric = useDisplayMetric();
  const languageRegionCode = useLanguageRegionCode();
  return (
    <section className={styles.trailStatSection}>
      <div>
        <span className={styles.trailStatIcons}>
          <span className={styles.statsLabel}>
            <FormattedMessage defaultMessage="Length" />
          </span>
          <span className={styles.detailData}>{MapStatsUtil.metersToFormattedUserUnits(trail.length, 1, displayMetric, languageRegionCode)}</span>
        </span>
        <span className={styles.trailStatIcons}>
          <span className={styles.statsLabel}>
            <FormattedMessage defaultMessage="Elevation gain" />
          </span>
          <span className={styles.detailData}>{MapStatsUtil.elevationFormatted(trail.elevation_gain, displayMetric, languageRegionCode)}</span>
        </span>
        <span className={styles.trailStatIcons}>
          <span className={styles.statsLabel}>
            <FormattedMessage defaultMessage="Route type" />
          </span>
          <span className={styles.detailData}>{routeType && translateRouteType(routeType)}</span>
        </span>
      </div>
    </section>
  );
};

export default memo(TrailStats);
