import { useCallback, useEffect, useMemo, useReducer } from 'react';
import getIsochrone from 'api/DistanceAway';
import type GeoLocation from '@alltrails/shared/types/Location/GeoLocation';
import { IsochroneAPIResponse } from 'types/Map';
import logError from 'utils/logError';
import isochroneReducer, { getCachedData } from '../ducks/isochrone';
import useDebounce from './useDebounce';

type Args = {
  distanceAwayValue: number;
  geoLocation: GeoLocation;
};

export default function useIsochroneSearch({ distanceAwayValue, geoLocation }: Args) {
  const [state, dispatch] = useReducer(isochroneReducer, { cache: {} });

  const { isLoadingGeoLocation, isLoadingIsochrone, lat, lng, meters } = state;
  const hasValidParameters = state.hasValidDistanceParameters && state.hasValidGeoParameters;
  // Typically the same exact object is returned, but there are some special
  // cases where a new instance is sent back for the same parameters so wrap
  // this in a useMemo.
  const cachedData = useMemo(() => getCachedData(state.lat, state.lng, state.meters, state.cache), [state]);
  const { insidePolygon, isochroneBoundingBox, isochroneData } = cachedData || {};

  // Only re-render the function if the distance away value changes.
  const onDistanceUpdate = useCallback(() => {
    dispatch({ type: 'DISTANCE_UPDATE', meters: distanceAwayValue });
  }, [distanceAwayValue]);

  // Only re-render the function if the geo location changes.
  const onGeoUpdate = useCallback(() => {
    // Make the lat and lng fuzzy to avoid being overly precise.
    const geoLocationWithFuzzyValues = {
      ...geoLocation,
      lat: Number((geoLocation?.lat || 0).toFixed(4)),
      lng: Number((geoLocation?.lng || 0).toFixed(4))
    };

    dispatch({ type: 'GEO_UPDATE', geoLocation: geoLocationWithFuzzyValues });
  }, [geoLocation]);

  // Both the Distance Away value and the geo location could be updated at any
  // time. Either could spam the system. A user action or the browser's Geo API
  // could both send a lot of updates at once. Debounce state updates for those
  // data points. If either of these functions updates (because the values
  // update) then any debounced action will be canceled and the new function
  // with the new value will be run.
  useDebounce(onDistanceUpdate, 100);
  useDebounce(onGeoUpdate, 100);

  useEffect(() => {
    if (!hasValidParameters) {
      return;
    }

    if (cachedData) {
      return;
    }

    dispatch({ type: 'ISOCHRONE_PENDING' });

    getIsochrone({ lat, lng, meters })
      .then((res: IsochroneAPIResponse) => {
        dispatch({ type: 'ISOCHRONE_LOADED', lat, lng, meters, isochroneData: res.message });
      })
      .catch(error => {
        logError(error);

        dispatch({ type: 'ISOCHRONE_REJECTED' });
      });
  }, [cachedData, hasValidParameters, lat, lng, meters]);

  return { isochroneBoundingBox, isochroneData, insidePolygon, isLoading: !!(isLoadingIsochrone || isLoadingGeoLocation) };
}
