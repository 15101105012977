import { ReactNode } from 'react';
import { FormattedMessage, FormattedDate } from '@alltrails/shared/react-intl';
import FrequentlyAskedQuestion from 'components/FrequentlyAskedQuestion';
import { celsiusToFahrenheit } from 'utils/weather_condition_helper';
import useDisplayMetric from 'hooks/useDisplayMetric';
import { FaqWeatherForecast } from 'types/Trails/Trail';
import * as styles from './styles.module.scss';

const DAYS_OF_FORECAST = 5;

const resetTime = (date: Date) => {
  date.setHours(0, 0, 0, 0);
  return date;
};

type TrailFaqProps = {
  trailName: string;
  weatherForecast: FaqWeatherForecast[];
};

const TrailWeatherFaq = ({ trailName, weatherForecast }: TrailFaqProps) => {
  const displayMetric = useDisplayMetric();

  const formattedForecast = [] as { day: ReactNode; temp: number; condition: string; key: string }[];
  const today = resetTime(new Date());

  weatherForecast.forEach(forecast => {
    // These forecasts are cached for a day, so we want to make sure that we don't display a forecast for a previous day
    const forcastBeforeToday = resetTime(new Date(forecast.dateTime)) < today;

    const includeInAnswer = !forcastBeforeToday && formattedForecast.length < DAYS_OF_FORECAST;
    if (includeInAnswer) {
      formattedForecast.push({
        key: new Date(forecast.dateTime).toString(),
        day: <FormattedDate value={new Date(forecast.dateTime)} day="numeric" weekday="short" month="long" />,
        temp: displayMetric ? forecast.maxTemperature : celsiusToFahrenheit(forecast.maxTemperature, true),
        condition: forecast.weatherText.toLowerCase()
      });
    }
  });

  const weatherQuestion = <FormattedMessage defaultMessage="What is the expected weather this week for {trailName}?" values={{ trailName }} />;

  const weatherAnswer = (
    <div itemProp="text">
      <FormattedMessage defaultMessage="Expected weather for {trailName} for the next 5 days is: " values={{ trailName }} />
      <ul className={styles.forecastList}>
        {formattedForecast.map(forecast => (
          <li key={forecast.key}>
            <FormattedMessage defaultMessage="{day} - {temp} degrees/{condition}" values={forecast} />
          </li>
        ))}
      </ul>
    </div>
  );

  return <FrequentlyAskedQuestion question={weatherQuestion} answer={weatherAnswer} key="trail-faq-weather" />;
};

export default TrailWeatherFaq;
