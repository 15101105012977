import { useMemo, ReactNode } from 'react';
import Slider from '@alltrails/shared/components/sliders/Slider';
import * as styles from './SliderGroup.module.scss';

type SliderGroupProps = {
  name: string;
  label: ReactNode;
  disabled?: boolean;
  min: number;
  max: number;
  onChange: (index: number) => void;
  value: number;
  accessibleLabel: string;
};

const SliderGroup = ({ name, label, disabled, min, max, onChange, value, accessibleLabel }: SliderGroupProps) => {
  const options = useMemo(
    () =>
      [...Array(max + 1 - min)].map(() => ({
        label: null // No label displayed above this slider
      })),
    [min, max]
  );

  return (
    <div className={styles.container}>
      <label htmlFor={name} className={styles.label}>
        {label}
      </label>
      <span className={styles.slider}>
        <Slider options={options} selectedIndex={value} onChange={onChange} disabled={disabled} min={min} accessibleLabel={accessibleLabel} />
      </span>
    </div>
  );
};

export default SliderGroup;
