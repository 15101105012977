import { useCallback } from 'react';
import useUser from 'hooks/useUser';
import { useTrailUgcContext } from 'components/trailUgc/TrailUgcContext';
import { addToList, removeFromList } from '@alltrails/shared/utils/requests/listRequests';

const MAGIC_ID_COMPLETED = 1001;

const useTrailCompleted = (trailId: number) => {
  const user = useUser();
  const {
    ugcState: {
      completed: { connectionId }
    },
    dispatch
  } = useTrailUgcContext();

  const handleCompletedConnectionChanged = useCallback(
    (id?: number) => {
      dispatch({ type: 'TOGGLE_COMPLETED', payload: { connectionId: id, currentUser: user } });
    },
    [user, dispatch]
  );

  const toggleTrailCompleted = useCallback(async () => {
    const handleCompletedAdded = (listItem: any) => {
      handleCompletedConnectionChanged?.(listItem.id);
    };

    const handleCompletedRemoved = () => {
      handleCompletedConnectionChanged?.(null);
    };

    if (connectionId) {
      try {
        const { errors } = await removeFromList(MAGIC_ID_COMPLETED, connectionId, null /* userId - aka me */);
        if (errors && errors.length > 0) {
          throw Error(`There was an error removing the trail from your completed list ${errors[0].message}`);
        }
        handleCompletedRemoved();
      } catch (err) {
        throw Error(`There was an error removing the trail from your completed List ${err}`);
      }
    } else {
      try {
        const { listItems, errors } = await addToList(MAGIC_ID_COMPLETED, 'trail', trailId, null /* userId - aka me */);
        if (listItems && listItems.length > 0) {
          handleCompletedAdded(listItems[0]);
        }
        if (errors && errors.length > 0) {
          throw Error(`There was an error adding the trail to your completed list ${errors[0].message}`);
        }
      } catch (err) {
        throw Error(`There was an error adding the trail to your completed list ${err}`);
      }
    }
  }, [connectionId, handleCompletedConnectionChanged, trailId]);

  return { connectionId, toggleTrailCompleted };
};

export default useTrailCompleted;
