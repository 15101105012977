import { featureCollection } from '@turf/helpers';
import { atMapsToGeojson } from '@alltrails/maps/utils/legacyGeoJSONConversions';
import { addChevrons, removeChevrons } from './chevrons';
import { loadAtMapForObj } from '../../requests/at_map_requests';
import { addPolylines, removePolylines } from './polylines';
import { addOrUpdateGeojsonSource } from '../sources/geojson_helpers';

const previewLineId = 'preview-lines';
const previewChevronId = 'preview-chevrons';

const addPreviewLines = map => {
  // Do nothing if already added to map
  if (map.getSource(previewLineId)) {
    return;
  }

  const geojson = featureCollection([]);
  addPolylines(map, previewLineId, geojson, {} /* sourceOpts */, {} /* opts */, null, false);
  addChevrons(map, previewChevronId, previewLineId);
};

const removePreviewLines = map => {
  removeChevrons(map, previewChevronId);
  removePolylines(map, previewLineId);
};

const updatePreviewLines = (map, object) => {
  if (!map.getLayer(previewLineId)) {
    addPreviewLines(map);
  }
  if (!object) {
    return Promise.resolve(null);
  }

  return loadAtMapForObj(object)
    .then(atMap => {
      const atMapGeojson = atMapsToGeojson([atMap]);
      addOrUpdateGeojsonSource(map, previewLineId, atMapGeojson);
      addOrUpdateGeojsonSource(map, previewChevronId, atMapGeojson);
    })
    .catch(e => {
      console.error(e);
    });
};

const resetPreviewLines = map => {
  const lineSource = map.getSource(previewLineId);
  if (lineSource) {
    lineSource.setData(featureCollection([]));
  }
};

export { addPreviewLines, removePreviewLines, previewLineId, previewChevronId, updatePreviewLines, resetPreviewLines };
