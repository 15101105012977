import { Component } from 'react';
import { FormattedMessage, useIntl } from '@alltrails/shared/react-intl';
import Button from '@alltrails/shared/denali/components/Button';
import Link from '@alltrails/shared/denali/components/Link';
import CustomProvider from 'components/CustomProvider';
import * as styles from './Converter.module.scss';

class BaseConverterSuccess extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.fileDownload();
  }

  fileDownload() {
    this.downloadConvertedFile(`${this.props.cdnPath}/${this.props.cdnDownloadPath}`);
  }

  getFileDownload() {
    return () => {
      this.fileDownload();
    };
  }

  handleSaveAsMap() {
    return () => {
      const slug = this.props.convertedSlug;
      const slugPart = slug.split('/').pop();
      const context = this;
      const formData = new FormData();
      formData.append('slug', slugPart);
      this.claimMap(formData)
        .then(response => {
          window.location = context.props.convertedSlug;
        })
        .catch(function (err) {
          context.handleError(this.props.intl.formatMessage({ defaultMessage: 'Upload failed' }));
        });
    };
  }

  handleError(message) {
    this.setState({
      submitting: false,
      errorMessage: message
    });
  }

  claimMap(data) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      // todo: parameterize this path
      xhr.open('POST', '/api/alltrails/convert/claim', true);
      xhr.setRequestHeader('X-AT-KEY', this.props.apiKey);
      xhr.setRequestHeader('X-AT-AUTH-TOKEN', this.props.authToken);
      xhr.setRequestHeader('X-CSRF-Token', document.querySelector('meta[name="csrf-token"]').content);
      xhr.onload = function () {
        if (this.status >= 200 && this.status < 300) {
          resolve(xhr.response);
        } else {
          reject({
            status: this.status,
            statusText: xhr.statusText
          });
        }
      };
      xhr.onerror = function () {
        reject({
          status: this.status,
          statusText: xhr.statusText
        });
      };
      xhr.send(data);
    });
  }

  getMapTypeDisplay(type) {
    if (!type) {
      return;
    }
    return this.isTypeRecording(type)
      ? this.props.intl.formatMessage({ defaultMessage: 'activities' })
      : this.props.intl.formatMessage({ defaultMessage: 'maps' });
  }

  downloadConvertedFile(filePath) {
    fetch(filePath).then(response => {
      const downloadName = response.url.split('/').slice(-1)[0];
      response.blob().then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = downloadName;
        // some browsers (at least Firefox) require that this element is added to the DOM before triggering a click event on it
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      });
    });
  }

  isTypeRecording(type) {
    const trackTypesArray = ['t', 'track'];
    return trackTypesArray.indexOf(type.toLowerCase()) > -1;
  }

  renderFoot() {
    return (
      <div className="success-foot">
        {this.props.mapType && this.renderSaveMapButton()}
        <div className="foot-group xlate-none">
          <div className="subtitle2">
            <FormattedMessage defaultMessage="Create custom maps to personalize your trip" />
          </div>
          <div className="button-wrapper">
            <Button
              linkInfo={{ href: this.props.createMapPath }}
              text={<FormattedMessage defaultMessage="Create new map" />}
              testId="converter-create-map"
            />
          </div>
        </div>
      </div>
    );
  }

  renderSaveMapButton() {
    return (
      <div className="foot-group">
        <div className="subtitle2">
          <FormattedMessage defaultMessage="Save this route to your account and never lose the file" />
        </div>
        <div className="button-wrapper">
          <Button
            loading={this.props.submitting}
            onClick={this.handleSaveAsMap().bind(this)}
            text={`${this.props.intl.formatMessage({ defaultMessage: 'Save to' })} ${this.getMapTypeDisplay(this.props.mapType)}`}
            testId="converter-success-save"
          />
          <div className="errorMessage">{this.props.errorMessage}</div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="wrapper xlate-none">
        <div className="head">
          <div className={styles.hero}>
            <h1>
              <FormattedMessage defaultMessage="Route converter" />
            </h1>
          </div>
        </div>
        <div className="container">
          <div className="success-wrapper">
            <div className="icon-holder">
              <img className="success-img" src="https://cdn-assets.alltrails.com/assets/icons/green-check-outline.png" />
            </div>
            <h5>
              <FormattedMessage defaultMessage="All finished!" />
            </h5>
            <div className="body1">
              <FormattedMessage
                defaultMessage="Your new file was added to your “downloads” folder. If you do not see the file, click <a>here</a> to retry your download. Have fun out on the trail!"
                values={{
                  a: chunks => (
                    <Link size="md" onClick={this.getFileDownload().bind(this)}>
                      {chunks}
                    </Link>
                  )
                }}
              />
            </div>
            <div className="body1">
              <FormattedMessage
                defaultMessage="Convert another file <a>here</a>"
                values={{
                  a: chunks => (
                    <Link href="/converter" size="md" testId="converter-sucess-convert">
                      {chunks}
                    </Link>
                  )
                }}
              />
            </div>
            {!this.props.mobileBrowser ? this.renderFoot() : ''}
          </div>
        </div>
      </div>
    );
  }
}

const ConverterSuccessIntlProvider = props => {
  const intl = useIntl();
  return <BaseConverterSuccess {...props} intl={intl} />;
};

const ConverterSuccess = props => {
  return (
    <CustomProvider>
      <ConverterSuccessIntlProvider {...props} />
    </CustomProvider>
  );
};

export { ConverterSuccess };
