import { lazy, Suspense, useState } from 'react';
import CustomProvider from 'components/CustomProvider';
import { FormattedMessage } from '@alltrails/shared/react-intl';
import Avatar from '@alltrails/shared/denali/components/Avatar';
import ProfileUser from 'types/ProfileUser';
import * as styles from './styles/styles.module.scss';

const PhotoUploadModal = lazy(() => import('@alltrails/modules/PhotoUpload/PhotoUploadModal'));

type Props = {
  user: ProfileUser;
  publicProfile?: boolean;
};

const UserAvatar = ({ user, publicProfile }: Props): JSX.Element => {
  const [renderModal, setRenderModal] = useState(false);

  const openModal = () => {
    setRenderModal(true);
  };

  const handleSuccess = () => {
    // With profile being separated amongst the erb, reloading the page after success has been the only way to
    // consistently get the profile photo everyway to propagate
    window.location.reload();
  };

  return (
    <CustomProvider>
      {renderModal && (
        <Suspense fallback={null}>
          <PhotoUploadModal
            closePhotoModal={() => setRenderModal(false)}
            url="/api/alltrails/profile_photos"
            uploadLimit={1}
            onSuccess={handleSuccess}
            hideControls
          />
        </Suspense>
      )}
      <div className={styles.imageContainer}>
        <Avatar userName={user?.username} userId={user?.id as number} testId={`show-profile-${user?.username}-image`} hasPlus={user?.pro} size="xl" />
        {!publicProfile && (
          <button className={styles.changeImage} onClick={openModal}>
            <FormattedMessage defaultMessage="Change image" />
          </button>
        )}
      </div>
    </CustomProvider>
  );
};

export default UserAvatar;
