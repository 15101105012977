import { MapboxMapShim } from 'types/Map';

export function getMapCenter(mapInstance: MapboxMapShim): [number, number] {
  const rawCenter = mapInstance.getCenter();
  // The LngLat is explicitly reversed to a [lat, lng] for our standard use cases.
  return [rawCenter.lat, rawCenter.lng];
}

export function getMapBounds(mapInstance: MapboxMapShim): [number, number, number, number] {
  const rawBounds = mapInstance.getBounds();
  return [rawBounds.getNorth(), rawBounds.getSouth(), rawBounds.getWest(), rawBounds.getEast()];
}

export function getMapZoom(mapInstance: MapboxMapShim): number {
  return mapInstance.getZoom();
}
