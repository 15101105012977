export const START = 'start';
export const EDIT_START = 'edit-start';
export const END = 'end';
export const EDIT_END = 'edit-end';
export const CURRENT = 'current';
export const START_END = 'start-end';
export const EDIT_START_END = 'edit-start-end';
export const NODE = 'node';
export const PLUS = 'plus';
export const YELLOW = 'yellow';
export const RED = 'red';
export const PURPLE = 'purple';
export const MSG_GREEN = 'msg-green';
export const MSG_RED = 'msg-red';
export const MSG_YELLOW = 'msg-yellow';
