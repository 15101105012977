const downloadOptions = [
  { value: 'gpxTrk', label: 'GPX Track' },
  { value: 'kml', label: 'Google Earth KML' },
  { value: 'tcx', label: 'Garmin Course TCX' },
  { value: 'crs', label: 'Garmin Course CRS' },
  { value: 'csv', label: 'CSV' },
  { value: 'fit', label: 'Garmin FIT (ANT+)' },
  { value: 'gpxRte', label: 'GPX Route' },
  { value: 'kmz', label: 'Google Earth KMZ' },
  { value: 'kmlGx', label: 'Google Earth Timeline' },
  { value: 'kmlTour', label: 'Google Earth Tour' },
  { value: 'ovl', label: 'OVL (ASCII)' },
  { value: 'fugawi', label: 'Fugawi' },
  { value: 'kompass', label: 'KOMPASS Verlag' },
  { value: 'pcx5Trk', label: 'PCX5 Track' },
  { value: 'geoRssTrk', label: 'GeoRSS Track' },
  { value: 'excel', label: 'MS Excel' },
  { value: 'oziExplorer', label: 'OziExplorer' },
  { value: 'ikt', label: 'MagicMaps IKT' },
  { value: 'falkTourRte', label: 'Falk IBEX Tour' },
  { value: 'geoJsonTrk', label: 'GeoJSON Track' },
  { value: 'jsonTrk', label: 'JSON Track' },
  { value: 'pwtrk', label: 'PathAway' },
  { value: 'navigon5Rte', label: 'Navigon RTE 5.x' },
  { value: 'navigon6Rte', label: 'Navigon RTE 6.x' },
  { value: 'freshroute', label: 'Navigon Freshroute' },
  { value: 'magellanTrk', label: 'Magellan Track' },
  { value: 'osynceTrk', label: 'o-synce Track' },
  { value: 'compeGpsTrk', label: 'CompeGPS Track' },
  { value: 'qpeGpsTrk', label: 'qpeGPS Track' },
  { value: 'tomTomRte', label: 'TomTom ITN' },
  { value: 'logbook', label: 'Garmin Logbook' },
  { value: 'sqlTrk', label: 'SQL Inserts Track' },
  { value: 'bddTdTrk', label: 'BDD (Bait dropping)' }
];

export default downloadOptions;
