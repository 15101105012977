import { PageStrings } from '@alltrails/shared/utils/constants/pageStringHelpers';
import { defineMessages, FormattedMessage } from '@alltrails/shared/react-intl';
import compareUserIds from '@alltrails/shared/utils/compareUserIds';
import LoadingSpinner from '../../../../../components/shared/LoadingSpinner/index';
import NoResults from '../../../../../components/shared/NoResults';
import { FullscreenCustomListTitle } from '../../search/fullscreen/custom_list_title';
import { LanguageSupportUtil } from '../../../../../utils/language_support_util';
import { SearchFiltersUtil } from '../../../../../utils/search_filters_util';
import CustomProvider from 'components/CustomProvider';
import { AnonymousMapUsername } from '../../../../../Constants';

const messages = defineMessages({
  trails: { defaultMessage: 'Trails' },
  myFavorites: { defaultMessage: 'My favorites' },
  myMaps: { defaultMessage: 'My maps' },
  thatMatch: { defaultMessage: 'that match' },
  near: { defaultMessage: 'near' },
  clearFilters: { defaultMessage: 'Clear filters' },
  members: { defaultMessage: 'Members' },
  maps: { defaultMessage: 'Maps' },
  activities: { defaultMessage: 'Activities' },
  lifeline: { defaultMessage: 'Lifeline' }
});

const ResultCountMixin = {
  handleListTitleUpdate(title, slug) {
    const {
      customList: { id, userId }
    } = this.state;
    this.setState({ customList: { id, userId, title, slug } }, this.replaceHistoryStateAndSearch);
  },
  collaborativeListCallback() {
    if (this.props.context.currentUser) {
      window.location.href = LanguageSupportUtil.wrapUrlSafe(
        `/members/${this.props.context.currentUser?.slug}/lists`,
        this.props.context.languageRegionCode
      );
    } else {
      window.location.href = LanguageSupportUtil.wrapUrlSafe('/signup', this.props.context.languageRegionCode);
    }
  },
  createH1Obj() {
    let h1Title = this.props.initialTitleString;
    if (!h1Title || h1Title.length < 1) {
      h1Title = this.props.intl.formatMessage(messages.trails);
    }

    // Special handling for Favorites and Maps
    const isMe = this.props.context.currentUser && this.props.userInfo && this.props.context.currentUser.id === this.props.userInfo.id;
    if (this.state.page === PageStrings.EXPLORE_FAVORITE_PAGE) {
      h1Title = this.props.intl.formatMessage(messages.myFavorites);
    }
    if (this.state.page === PageStrings.EXPLORE_USERS_MAPS_PAGE && isMe) {
      h1Title = this.props.intl.formatMessage(messages.myMaps);
    }

    let h1Content = h1Title;
    // User's Recordings/Maps pages
    if (this.state.page === PageStrings.EXPLORE_USERS_TRACKS_PAGE || (this.state.page === PageStrings.EXPLORE_USERS_MAPS_PAGE && !isMe)) {
      // Set h1Content to have link around user's name in cases like "Andrew's Activities"
      const splitTitleString = h1Title.split(' ');
      if (splitTitleString.length === 2 && this.props.userInfo) {
        h1Content = (
          <span>
            <a
              className="link"
              title={`${this.props.userInfo.firstName} ${this.props.userInfo.lastName}`}
              href={LanguageSupportUtil.wrapUrlSafe(`/members/${this.props.initialUserSlug}`, this.props.context.languageRegionCode)}
            >
              {splitTitleString[0]}
            </a>
            {` ${splitTitleString[1]}`}
          </span>
        );
      } else if (splitTitleString.length === 3 && this.props.userInfo && !isMe) {
        // "Mapas de Andrew"
        h1Content = (
          <span>
            {`${splitTitleString[0]} ${splitTitleString[1]} `}
            <a
              className="link"
              title={`${this.props.userInfo.firstName} ${this.props.userInfo.lastName}`}
              href={LanguageSupportUtil.wrapUrlSafe(`/members/${this.props.initialUserSlug}`, this.props.context.languageRegionCode)}
            >
              {splitTitleString[2]}
            </a>
          </span>
        );
      }
    }
    // Matching query term
    if (this.state.filters.queryTerm && this.state.filters.queryTerm.length > 0) {
      h1Title = `${h1Title} ${this.props.intl.formatMessage(messages.thatMatch)} "${this.state.filters.queryTerm}"`;
      h1Content = h1Title;
    }
    // Selected Location
    else if (this.state.filters.locationObject) {
      // Sometimes h1Title can contain a "near <location>" suffix
      // We need to remove this before adding this again dynamically
      const splitNear = h1Title.split(` ${this.props.intl.formatMessage(messages.near)} `);
      if (splitNear.length > 1) {
        h1Title = splitNear[0];
      }
      // Get name/slug from location object
      const obj = this.state.filters.locationObject;
      let { name } = obj;
      if (obj.objectID && obj.objectID.indexOf('city') >= 0) {
        if (obj.state_name) name = `${name}, ${obj.state_name}`;
        if (obj.country_name && obj.country_id !== 313 && obj.country_id !== 139) name = `${name}, ${obj.country_name}`;
      } else if (obj.objectID && obj.objectID.indexOf('state') >= 0) {
        if (obj.country_name && obj.country_id !== 313 && obj.country_id !== 139) name = `${name}, ${obj.country_name}`;
      }
      // Set h1Content
      const newH1Title = `${h1Title} ${this.props.intl.formatMessage(messages.near)} ${name}`;
      if (obj.slug) {
        h1Content = (
          <span>
            {`${h1Title} ${this.props.intl.formatMessage(messages.near)} `}
            <a className="link" href={LanguageSupportUtil.wrapUrlSafe(`/${obj.slug}`, this.props.context.languageRegionCode)}>
              {name}
            </a>
          </span>
        );
      } else {
        h1Content = newH1Title;
      }
      // Set h1Title
      h1Title = newH1Title;
    }
    // Return if valid content
    if (h1Title && h1Title.length > 0 && h1Content && h1Title !== this.props.intl.formatMessage(messages.trails)) {
      return (
        <h1 className={`result-title xlate-none`} title={h1Title}>
          {h1Content}
        </h1>
      );
    }
    // Always default to what the original
    return null;
  },
  getResultCount() {
    return this.state.results ? this.state.results.length : 0;
  },
  createResultCountObj() {
    const { filters, atMaps, page, isMobileWidth } = this.state;

    if (isMobileWidth && filters.mobileMap) {
      return null;
    }

    // try to intelligently cap the width here if there will be a clear filters button
    const capSpaceForCountObj = SearchFiltersUtil.filtersAreActive(filters);
    const inlineStyle = { display: 'inline-block' };
    if (capSpaceForCountObj) {
      const maxWidth = 298 - (28 + this.props.intl.formatMessage(messages.clearFilters).length * 5);
      inlineStyle.maxWidth = `${maxWidth}px`;
    }

    const count = this.getResultCount();
    let title;

    if (page === PageStrings.EXPLORE_COMPLETED_PAGE) {
      title = (
        <FormattedMessage
          defaultMessage="Showing {count} of {total, plural, one {# trail} other {# trails}}"
          values={{
            count,
            total: filters.trailIds.length
          }}
        />
      );
    } else if ([PageStrings.EXPLORE_CUSTOM_PAGE, PageStrings.EXPLORE_FAVORITE_PAGE].includes(page)) {
      title = (
        <FormattedMessage
          defaultMessage="Showing {count} of {total, plural, one {# item} other {# items}}"
          values={{
            count,
            total: filters.trailIds.length + filters.mapIds.length + filters.trackIds.length
          }}
        />
      );
    } else if (page === PageStrings.EXPLORE_USERS_TRACKS_PAGE) {
      title = (
        <FormattedMessage
          defaultMessage="Showing {count} of {total, plural, one {# activity} other {# activities}}"
          values={{
            count,
            total: atMaps ? atMaps.length : 0
          }}
        />
      );
    } else if (page === PageStrings.EXPLORE_USERS_MAPS_PAGE) {
      title = (
        <FormattedMessage
          defaultMessage="Showing {count} of {total, plural, one {# map} other {# maps}}"
          values={{
            count,
            total: atMaps ? atMaps.length : 0
          }}
        />
      );
    } else {
      return null;
    }

    return (
      <span className="result-count" style={inlineStyle}>
        {title}
      </span>
    );
  },
  createLoadingPlaceholder() {
    return (
      <div className="loading-placeholder">
        <LoadingSpinner />
      </div>
    );
  },
  createNoResultsObj() {
    const resultCount = this.getResultCount();

    if ([PageStrings.EXPLORE_ALL_PAGE, PageStrings.EXPLORE_COMMUNITY_CONTENT_PAGE].includes(this.state.page)) {
      if (resultCount >= 3) {
        return null;
      }
      if (this.state.loading) {
        return this.createLoadingPlaceholder();
      }
    } else if (resultCount > 0) {
      return null;
    }

    return (
      <CustomProvider>
        <NoResults
          atMaps={this.state.atMaps}
          hasActiveFilters={SearchFiltersUtil.filtersAreActive(this.state.filters)}
          initialNoResultsTitle={this.props.initialNoResultsTitle}
          onClick={pageKey => {
            if (SearchFiltersUtil.filtersAreActive(this.state.filters)) {
              this.clearAllFilters();
            } else {
              this.tabHandler(pageKey);
            }
          }}
          page={this.state.page}
          resultCount={resultCount}
          trailIds={this.state.filters.trailIds}
        />
      </CustomProvider>
    );
  },
  createTrailBreadcrumbItems() {
    if (!this.state.selectedObject || this.state.selectedObject.slug == null || this.state.selectedObject.slug == '') return [];
    const breadcrumbItems = [];
    if (this.state.selectedObject.country_id && this.state.selectedObject.country_id != null) {
      const countrySlug = this.state.selectedObject.slug.split('/')[1];
      breadcrumbItems.push({
        link: `/explore/${countrySlug}`,
        label: this.state.selectedObject.country_name,
        class: 'xlate-none'
      });
    } else {
      return [];
    }
    if (this.state.selectedObject.state_id && this.state.selectedObject.state_id != null) {
      const countrySlug = this.state.selectedObject.slug.split('/')[1];
      const stateSlug = this.state.selectedObject.slug.split('/')[2];
      breadcrumbItems.push({
        link: `/explore/${countrySlug}/${stateSlug}`,
        label: this.state.selectedObject.state_name,
        class: 'xlate-none'
      });
    }
    if (
      this.state.selectedObject.area_id &&
      this.state.selectedObject.area_id != null &&
      this.state.selectedObject.area_slug &&
      this.state.selectedObject.area_slug != null &&
      this.state.selectedObject.area_slug != ''
    ) {
      const areaSlug = this.state.selectedObject.area_slug;
      const label = this.state.selectedObject.area_name;
      breadcrumbItems.push({
        link: `/explore/${areaSlug}`,
        label,
        class: 'xlate-none'
      });
    } else if (
      this.state.selectedObject.city_id &&
      this.state.selectedObject.city_id != null &&
      this.state.selectedObject.city_name &&
      this.state.selectedObject.city_name != ''
    ) {
      const countrySlug = this.state.selectedObject.slug.split('/')[1];
      const stateSlug = this.state.selectedObject.slug.split('/')[2];
      const citySlug = this.state.selectedObject.city_name
        .replace(/\s+/g, '-')
        .replace(/[^a-zA-Z -]/g, '')
        .toLowerCase();
      breadcrumbItems.push({
        link: `/explore/${countrySlug}/${stateSlug}/${citySlug}`,
        label: this.state.selectedObject.city_name,
        class: 'xlate-none'
      });
    }
    const label = this.state.selectedObject.name;
    breadcrumbItems.push({ link: null, label, class: 'xlate-none' });
    return breadcrumbItems;
  },
  createMapBreadcrumbItems() {
    if (!this.state.selectedObject) return [];
    // Handle case where the owner of the map is holding-account for maps created by non-logged-in users.
    // The map might not yet have a name or ID if this is the "new map" case.
    if (
      !this.props.context.currentUser &&
      this.state.page === PageStrings.EXPLORE_USERS_MAPS_MAP_PAGE &&
      (!this.props.userInfo || this.props.userInfo.slug == AnonymousMapUsername)
    ) {
      if (!this.state.selectedObject.ID) {
        return [];
      }
      return [{ link: null, label: this.state.selectedObject.name, class: 'xlate-none' }];
    }
    let user;

    if (this.props.userInfo && this.props.userInfo != null) {
      user = this.props.userInfo;
    } else if (this.state.selectedObject && this.state.selectedObject.user && this.state.selectedObject.user != null) {
      user = this.state.selectedObject.user;
    } else if (this.props.context && this.props.context.currentUser) {
      user = this.props.context.currentUser;
    }
    let userSlug;
    let userFirstName;
    let userLastName;
    if (user) {
      userSlug = user.slug;
      userFirstName = user.firstName || user.first_name || 'User';
      userLastName = user.lastName || user.last_name || 'User';
    }
    const breadcrumbItems = [];
    breadcrumbItems.push({ link: null, label: this.props.intl.formatMessage(messages.members) });
    breadcrumbItems.push({
      link: `/members/${userSlug}`,
      label: `${userFirstName} ${userLastName}`,
      class: 'xlate-none'
    });

    let addSelectedObjectBreadcrumb = true;

    switch (this.state.page) {
      case PageStrings.EXPLORE_USERS_MAPS_MAP_PAGE:
        breadcrumbItems.push({ link: `/members/${userSlug}/maps`, label: this.props.intl.formatMessage(messages.maps) });

        // when map creator map is saved, update breadcrumbs with map name
        if (this.state.exploreMap && this.state.exploreMap.name) {
          addSelectedObjectBreadcrumb = false;
          breadcrumbItems.push({ link: null, label: this.state.exploreMap.name });
        }
        break;
      case PageStrings.EXPLORE_USERS_TRACKS_MAP_PAGE:
        breadcrumbItems.push({
          link: `/explore/members/${userSlug}/recordings`,
          label: this.props.intl.formatMessage(messages.activities)
        });
        break;
      case PageStrings.EXPLORE_LIFELINE_PAGE:
        addSelectedObjectBreadcrumb = false;
        breadcrumbItems.push({ link: null, label: this.props.intl.formatMessage(messages.lifeline) });
        break;
      default:
        break;
    }

    if (addSelectedObjectBreadcrumb) {
      breadcrumbItems.push({ link: null, label: this.state.selectedObject.name, class: 'xlate-none' });
    }

    return breadcrumbItems;
  }
};

export { ResultCountMixin };
