import { useState, useEffect } from 'react';
import { defineMessages } from '@alltrails/shared/react-intl';
import Settings from '@alltrails/shared/icons/Settings';
import IconButton from '@alltrails/shared/denali/components/IconButton';
import useFormatMessage from '@alltrails/shared/hooks/useFormatMessage';
import CustomProvider from 'components/CustomProvider';
import ModalBackground from '@alltrails/shared/components/modals/ModalBackground';
import ModalInterior from '@alltrails/shared/components/modals/ModalInterior';
import Accordion from '../../../shared/Accordion';
import PaperSize from './PaperSize';
import Orientation from './Orientation';
import Grid from './Grid';
import Scale from './Scale';

const PRINT_SETTINGS_STRINGS = defineMessages({
  PAPER_SIZE: { defaultMessage: 'Paper size' },
  ORIENTATION: { defaultMessage: 'Orientation' },
  GRID: { defaultMessage: 'Grid' },
  RESET: { defaultMessage: 'Reset' },
  DONE: { defaultMessage: 'Done' },
  SCALE: { defaultMessage: 'Scale' },
  PRINT_SETTINGS: { defaultMessage: 'Print settings' },
  CLOSE: { defaultMessage: 'Close' }
});

type Props = {
  accordionsOpened: {
    grid: unknown;
    orientation: unknown;
    paperSize: unknown;
    scale: unknown;
  };
  isMobileWidth: boolean;
  resetToInitialSettings?: () => void;
  title: string;
  toggleAccordionState: (state: string) => void;
};

const PrintSettings = ({ accordionsOpened, isMobileWidth, resetToInitialSettings = () => undefined, title, toggleAccordionState }: Props) => {
  const {
    formattedDefaultMessages: { PAPER_SIZE, ORIENTATION, GRID, RESET, DONE, SCALE, PRINT_SETTINGS }
  } = useFormatMessage(PRINT_SETTINGS_STRINGS);
  const [visible, setVisible] = useState(false);

  const handleKeyDown = (e: KeyboardEvent) => {
    if (visible && e.keyCode === 27) {
      setVisible(false); // Esc
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  });

  const renderPrintSettings = () => (
    <CustomProvider>
      <div className="settingsContainer">
        <Accordion
          onClick={() => toggleAccordionState('paperSize')}
          isOpen={!!accordionsOpened.paperSize}
          title={PAPER_SIZE}
          className="printComponent"
        >
          <PaperSize isMobileWidth={isMobileWidth} />
        </Accordion>
        <Accordion
          onClick={() => toggleAccordionState('orientation')}
          isOpen={!!accordionsOpened.orientation}
          title={ORIENTATION}
          className="printComponent"
        >
          <Orientation isMobileWidth={isMobileWidth} />
        </Accordion>
        <Accordion onClick={() => toggleAccordionState('grid')} isOpen={!!accordionsOpened.grid} title={GRID} className="printComponent">
          <Grid isMobileWidth={isMobileWidth} />
        </Accordion>
        <Accordion onClick={() => toggleAccordionState('scale')} isOpen={!!accordionsOpened.scale} title={SCALE} className="printComponent">
          <Scale isMobileWidth={isMobileWidth} />
        </Accordion>
      </div>
    </CustomProvider>
  );

  const renderModal = () => {
    if (!isMobileWidth) {
      return renderPrintSettings();
    }
    if (!visible) {
      return null;
    }

    return (
      <ModalBackground closeModal={() => setVisible(false)}>
        <ModalInterior
          closeModal={() => setVisible(false)}
          isMounted
          title={title}
          baseCtas={{
            primaryCta: {
              onClick: () => setVisible(false),
              testId: 'print-page-save',
              text: DONE
            },
            secondaryCta: {
              onClick: resetToInitialSettings,
              testId: RESET,
              text: RESET
            }
          }}
        >
          {renderPrintSettings()}
        </ModalInterior>
      </ModalBackground>
    );
  };

  return (
    <div className={!isMobileWidth ? 'printSettings' : 'printSettingsIconContainer'}>
      {isMobileWidth && (
        <IconButton
          icon={{ Component: Settings }}
          onClick={() => {
            setVisible(true);
          }}
          testId={PRINT_SETTINGS}
          title={PRINT_SETTINGS}
        />
      )}
      {renderModal()}
    </div>
  );
};

export default PrintSettings;
