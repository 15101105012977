import { completedListId, verifiedListId } from '@alltrails/modules/Lists/listUtils';
import { PageStrings } from '@alltrails/shared/utils/constants/pageStringHelpers';
import bboxPolygon from '@turf/bbox-polygon';
import pointInPolygon from '@turf/boolean-point-in-polygon';
import type { Context } from 'types/Context';
import { Filters } from 'types/Search';
import type GeoLocation from '@alltrails/shared/types/Location/GeoLocation';
import { AlgoliaResults } from 'types/Search/Algolia';
import { Sort } from 'types/Search/Sort';
import { EXPLORE } from 'utils/constants/SearchAnalyticsConstants';
import logError from 'utils/logError';
import { getAnalyticsTags } from 'utils/requests/algolia_requests';
import { SearchFiltersUtil, CARD_ATTRIBUTES } from 'utils/search_filters_util';
import ListMethods from 'types/ListMethods';
import hasPermission from './hasPermission';

const ET_PHOTO_USER_SLUG = 'alltrails-user';

export const DEFAULT_RADIUS_METERS = 48270; // 30 miles in m

const algoliaAnalyticsTags = (context: Context, searchText: string, origin: string) =>
  getAnalyticsTags({
    user: context.currentUser,
    locale: context.languageRegionCode,
    origin,
    mobile: context.mobileBrowser,
    searchText,
    system: false
  });

export type SearchAppArgs = {
  algoliaIndex: any;
  atMaps: any;
  context: Context;
  filters: Filters;
  handlePlaceSearchResults?: any;
  handleSearchResults: (results: AlgoliaResults) => void;
  handleUserResults?: any;
  initialUserSlug: string;
  insidePolygon?: number[][];
  isMobileWidth: boolean;
  isochroneBoundingBox?: [number, number, number, number];
  listMethods: ListMethods;
  page: string;
  geoLocation?: GeoLocation;
};

/**
 * legacyAlgoliaSearchAppQuery is our wrapper for Algolia search API calls in
 * /explore and its related code. This is prefixed as "legacy" because it has
 * been migrated from TS and is one of several disjoint methods for invoking
 * Algolia. A new strategy will emerge at some point and (ideally) we do not
 * enhance this method if possible.
 *
 * Conceptually, think of this method as if it were our own search API service
 * like /api/alltrails/search. This encapsulates all the functionality around
 * "here is my search state, give me back what I need to use the results"."
 *
 * @param {SearchAppArgs} args
 * @returns
 */
const legacyAlgoliaSearchAppQuery = async ({
  algoliaIndex,
  atMaps,
  context,
  filters,
  handlePlaceSearchResults,
  handleSearchResults,
  handleUserResults,
  initialUserSlug,
  insidePolygon,
  isMobileWidth,
  isochroneBoundingBox,
  listMethods,
  page
}: SearchAppArgs) => {
  // Sanity check that our index is ready to go!
  // Dont search on trail map page, or if quick-draw is enabled
  const isCertainPage = [
    PageStrings.EXPLORE_TRAIL_MAP_PAGE,
    PageStrings.EXPLORE_USERS_TRACKS_MAP_PAGE,
    PageStrings.EXPLORE_USERS_MAPS_MAP_PAGE,
    PageStrings.EXPLORE_PENDING_PAGE
  ].includes(page);
  if (!algoliaIndex || isCertainPage) {
    return;
  }

  // search for recordings or trails
  const completedIds = Object.keys(listMethods.itemsInListOfType(completedListId, 'trail'));
  const verifiedIds = Object.keys(listMethods.itemsInListOfType(verifiedListId, 'trail'));

  const isListPage = [PageStrings.EXPLORE_CUSTOM_PAGE].includes(page);

  if ([PageStrings.EXPLORE_USERS_TRACKS_PAGE, PageStrings.EXPLORE_USERS_MAPS_PAGE].includes(page) || isListPage) {
    if (initialUserSlug === ET_PHOTO_USER_SLUG || !atMaps) {
      return;
    }
    handleUserResults(SearchFiltersUtil.customSearch(atMaps, filters, context, isMobileWidth, isListPage, completedIds, verifiedIds));
    return;
  }

  // TODO This approach may not be sustainable long term. See https://alltrails.atlassian.net/browse/DISCO-554
  if (filters.sort[Sort.NEWLY_ADDED]?.selected) {
    filters.has_profile_photo = true;
    filters.isMinimumViableContent = true;
  } else {
    filters.has_profile_photo = null;
    filters.isMinimumViableContent = null;
  }

  const algoliaQueryObject = SearchFiltersUtil.createAlgoliaQueryObject({
    insidePolygon,
    filters,
    page,
    completedIds,
    verifiedIds,
    analyticsTags: algoliaAnalyticsTags(context, filters.queryTerm, EXPLORE),
    attributesToRetrieve: CARD_ATTRIBUTES,
    attributesToHighlight: filters.queryTerm && filters.queryTerm.length > 0 ? ['name'] : []
  });

  algoliaIndex
    .search(filters.queryTerm || '', algoliaQueryObject)
    .then((results: AlgoliaResults) => {
      let polygon: any;

      if (isochroneBoundingBox) {
        polygon = bboxPolygon(isochroneBoundingBox);
      } else if (filters.boundingBox) {
        polygon = bboxPolygon([
          filters.boundingBox.longitudeTopLeft,
          filters.boundingBox.latitudeBottomRight,
          filters.boundingBox.longitudeBottomRight,
          filters.boundingBox.latitudeTopLeft
        ]);
      }

      if (insidePolygon && polygon) {
        results.hits = results.hits?.filter(hit => {
          const point = [hit._geoloc.lng, hit._geoloc.lat];

          return pointInPolygon(point, polygon);
        });
      }

      handleSearchResults(results);
    })
    .catch(logError);

  // search for places (for admin filter)
  if (hasPermission({ permission: 'trails:manage' })) {
    const algoliaAreaQueryObject = SearchFiltersUtil.createAlgoliaLocQueryObject(['area', 'place'], filters, isMobileWidth, page);
    algoliaIndex.search('', algoliaAreaQueryObject).then(handlePlaceSearchResults).catch(logError);
  }
};

export { algoliaAnalyticsTags, legacyAlgoliaSearchAppQuery };
