import { useState, lazy, Suspense } from 'react';
import useTrailConditionsState from 'hooks/useTrailConditions';
import Trail from 'types/Trails/Trail';
import { TrailCondition, TrailConditionUid } from 'types/Trails/TrailCondition';
import TrailConditionsInfo from './TrailConditionsInfo';
import TrailConditionsEmpty from '../TrailConditionsEmpty';

const TrailConditionsReviewsModal = lazy(() => import('../TrailConditionsReviewsModal'));

export type TrailConditionsBaseProps = {
  trail: Trail;
  trailConditions: TrailCondition[];
  exploreFlag?: boolean;
};

const TrailConditionsBase = ({ trail, trailConditions, exploreFlag }: TrailConditionsBaseProps): JSX.Element => {
  const [showReviewDetails, setShowReviewDetails] = useState<boolean>(false);
  const { sortedConditions, toggleConditionFilter, resetConditionFilters, filteredConditions } = useTrailConditionsState(trailConditions);

  const openReviewDetails = () => {
    setShowReviewDetails(true);
  };

  return (
    <>
      {!sortedConditions.length ? (
        <TrailConditionsEmpty trail={trail} />
      ) : (
        <TrailConditionsInfo
          conditions={sortedConditions}
          onSeeConditionDetailsClick={openReviewDetails}
          onConditionCardClick={(conditionUid: TrailConditionUid) => {
            toggleConditionFilter(conditionUid);
            openReviewDetails();
          }}
          exploreFlag={exploreFlag}
        />
      )}
      {showReviewDetails && (
        <Suspense fallback={null}>
          <TrailConditionsReviewsModal
            trail={trail}
            conditions={sortedConditions}
            closeModal={() => {
              resetConditionFilters();
              setShowReviewDetails(false);
            }}
            filters={filteredConditions}
          />
        </Suspense>
      )}
    </>
  );
};

export default TrailConditionsBase;
