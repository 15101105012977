import LanguageRegionCode from '@alltrails/shared/types/LanguageRegionCode';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import { IntlProvider } from '@alltrails/shared/react-intl';
import Terms from './Terms';

export default function TermsSSR() {
  const languageRegionCode = useLanguageRegionCode();
  const messages = require(`./compiled-lang/${languageRegionCode}.json`);

  return (
    <IntlProvider defaultLocale={LanguageRegionCode.English} locale={languageRegionCode} messages={messages}>
      <Terms />
    </IntlProvider>
  );
}
