import { string, func, bool } from 'prop-types';
import { defineMessages } from '@alltrails/shared/react-intl';
import useFormatMessage from '@alltrails/shared/hooks/useFormatMessage';
import Button from '@alltrails/shared/denali/components/Button';
import { popupContainer, popupLocation, closeButton, contentContainer } from './styles/styles.module.scss';

const SEARCH_POPUP_POINT_STRINGS = defineMessages({
  CLOSE_POPUP: { defaultMessage: 'Close popup' },
  START_HERE: { defaultMessage: 'Start here' },
  ADD_TO_ROUTE: { defaultMessage: 'Add to route' }
});

const SearchPointPopup = ({ locationName = null, isStartingPoint, addToRoute, onClose }) => {
  const {
    formattedDefaultMessages: { CLOSE_POPUP, START_HERE, ADD_TO_ROUTE }
  } = useFormatMessage(SEARCH_POPUP_POINT_STRINGS);
  const buttonText = isStartingPoint ? START_HERE : ADD_TO_ROUTE;

  return (
    <div className={popupContainer}>
      <div className={contentContainer}>
        {locationName && <div className={popupLocation}>{locationName}</div>}
        <button className={closeButton} type="button" label={CLOSE_POPUP} onClick={onClose} />
      </div>
      <Button text={buttonText} onClick={addToRoute} testId={buttonText} variant="primary" />
    </div>
  );
};

export default SearchPointPopup;

SearchPointPopup.propTypes = {
  onClose: func.isRequired,
  addToRoute: func.isRequired,
  isStartingPoint: bool.isRequired,
  locationName: string
};

SearchPointPopup.defaultProps = {
  locationName: null
};
