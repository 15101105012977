import { addClusteredPins, removeClusteredPins } from './clustered_pins';
import { addPreviewLines } from './preview_lines';
import { getPendingIndex } from '../../search';
import { SearchFiltersUtil } from '../../search_filters_util';
import { addResultsHeatmapOverlay, heatmapOverlayId } from './heatmap';
const pendingLayerId = 'pendingTrails';
const clusterLayerId = 'clusterTrails';

const addPendingTrails = (map, trailId, renderHoverCard, layerId, color, enabledOverlays) => {
  return getPendingIndex()
    .search('', SearchFiltersUtil.createAlgoliaTrailsObject(map))
    .then(content => {
      const results = content.hits.filter(trail => {
        // Do not show pin of current trail if we are looking at one
        if (trailId && trail.ID == trailId) {
          return false;
        }
        const machineSource = trail.source === 1046;
        return layerId === clusterLayerId ? machineSource : !machineSource;
      });
      results.forEach(trail => {
        trail.name += ' (Pending)';
      });
      addPreviewLines(map);
      addClusteredPins(map, layerId, results, color, renderHoverCard, 0.6);
      if (layerId === pendingLayerId && enabledOverlays.includes(heatmapOverlayId(layerId))) {
        addResultsHeatmapOverlay({ map, id: layerId, heatMapResults: results, renderHoverCard });
      }
    });
};

const removePendingTrails = (map, layerId) => {
  removeClusteredPins(map, layerId);
};

export { addPendingTrails, removePendingTrails };
