import turfDistance from '@turf/distance';
import { FormattedMessage } from '@alltrails/shared/react-intl';
import LoadingDots from '@alltrails/shared/denali/components/LoadingDots';
import { X_AT_KEY, get, post, destroy } from '@alltrails/shared/api';
import { humanizeTimeSpan } from '@alltrails/shared/utils/timeHelpers';
import getHelpCenterUrl from '@alltrails/shared/utils/constants/helpCenterUrl';
import Button from '@alltrails/shared/denali/components/Button';
import Link from '@alltrails/shared/denali/components/Link';
import hasPermission from 'utils/hasPermission';
import { TrailDetailsBox } from './trail_details_box';
import logError from '../../../../utils/logError';
import { LanguageSupportUtil } from '../../../../utils/language_support_util';
import { setWindowExitGuard } from '../../../../utils/window_helpers';
import { getMapDistance } from '../../../../utils/at_map_helpers';
import { getFormattedTrailUrls } from '../../../../utils/trail_helpers';
import { MapStatsUtil } from '../../../../utils/map_stats_util';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const createReactClass = require('create-react-class');

// Keep this in sync with Trail::LENGTH_MIN
const MIN_TRAIL_LENGTH_METERS = 160.9344;

const EditTrailBox = createReactClass({
  getInitialState() {
    return {
      currentState: '',
      // eslint-disable-next-line eqeqeq
      isPendingTrail: this.props.trail.metadata.status == 'P'
    };
  },
  renderLocation(location) {
    const locations = [];
    if (location && location.country) locations.push(<li key="country">{location.country}</li>);
    if (location && location.region) locations.push(<li key="region">{location.region}</li>);
    if (location && location.city) locations.push(<li key="city">{location.city}</li>);
    return locations;
  },
  componentDidMount() {
    this.apiTrailId = null;
    this.subscriptions = [];
  },
  componentWillUnmount() {
    while (this.subscriptions.length) this.subscriptions.pop().unsubscribe();
  },
  // eslint-disable-next-line consistent-return
  handleItemPostComplete() {
    const { context, trail } = this.props;
    const { languageRegionCode } = context;
    const { id } = trail;
    const { isPendingTrail, setToPending, setToActive } = this.state;

    // eslint-disable-next-line eqeqeq
    if (--this.unsavedItems == 0) {
      setWindowExitGuard(false);
      // this.setState({currentState: ""});

      // Immediately apply edits (rather than waiting for hourly job run) in certain cases:
      if (!isPendingTrail && setToPending) {
        get('/api/alltrails/edits/apply', {
          params: {
            type: 'trail',
            target_object_id: id,
            set_to_pending: setToPending
          }
        })
          .then(this.handleApplyPostSuccess)
          .catch(error => {
            logError(`handleApplyPostError: ${error}`);
          });
      } else if ((isPendingTrail || hasPermission({ permission: 'trails:manage' })) && this.apiTrailId) {
        get('/api/alltrails/edits/apply', {
          params: {
            type: 'trail',
            target_object_id: this.apiTrailId,
            set_to_active: setToActive
          }
        })
          .then(this.handleApplyPostSuccess)
          .catch(error => {
            logError(`handleApplyPostError: ${error}`);
          });
      } else if (hasPermission({ permission: 'trails:manage' })) {
        const { localizedUrl } = getFormattedTrailUrls(trail, languageRegionCode);
        window.location = localizedUrl;
        return false;
      }
    }
  },
  checkForTrail(trailId) {
    const {
      context: { languageRegionCode }
    } = this.props;

    get(`/api/alltrails/trails/${trailId}?include_pending=true`).then(data => {
      if (data && data.trails[0].slug) {
        clearInterval(this.checkForTrailInterval);
        window.location = LanguageSupportUtil.wrapUrlSafe(`/trail/${data.trails[0].slug}`, languageRegionCode);
      }
    });
  },
  handleApplyPostSuccess(data) {
    const {
      trail: { id },
      context: { languageRegionCode }
    } = this.props;

    // If this is a new trail we need to wait for async processing, check every 2.5 secs
    if (id == null && this.apiTrailId != null) {
      this.checkForTrailInterval = setInterval(this.checkForTrail.bind(null, this.apiTrailId), 2500);
    } else {
      setTimeout(() => {
        window.location = LanguageSupportUtil.wrapUrlSafe(`/trail/${data.trails[0].slug}`, languageRegionCode);
      }, 2500);
    }
  },
  postEditItem(itemUrl, newEdit) {
    post(itemUrl, newEdit)
      .catch(error => {
        logError(`handleItemPostError: ${error}`);
      })
      .finally(this.handleItemPostComplete);
  },
  handleTrailView(successCallback) {
    const {
      trail: { id }
    } = this.props;

    if (!id) {
      successCallback();
      return;
    }

    post('/api/alltrails/edits/contribute/views', { type: 'trail', target_object_id: id })
      .then(successCallback)
      .catch(error => {
        logError(`handleTrailViewError: ${error}`);
      });
  },
  saveEdits(newEdits) {
    const { context, trail } = this.props;
    const { languageRegionCode } = context;
    const { id } = trail;

    this.newGroupUrl = '/api/alltrails/edits/groups';
    if (newEdits.length > 0) {
      this.unsavedItems = newEdits.length;
      setWindowExitGuard(true);
      this.setState({ currentState: 'saving' });
      const postItemsCallback = function (data) {
        const editGroupId = data.editGroups[0].id;
        this.apiTrailId = data.editGroups[0].targetObjectId;
        const itemUrl = `${this.newGroupUrl}/${editGroupId}/items`;
        for (let i = 0; i < newEdits.length; i++) {
          const { itemPostHandler, itemPostHandlerData } = newEdits[i];
          if (itemPostHandler) {
            itemPostHandler(itemUrl, newEdits[i], this.postEditItem, itemPostHandlerData);
          } else {
            this.postEditItem(itemUrl, newEdits[i]);
          }
        }
      }.bind(this);
      post(this.newGroupUrl, {
        type: 'trail',
        target_object_id: id,
        origin_object_id: !id && trail.defaultMap ? trail.defaultMap.id : undefined
      })
        .then(postItemsCallback)
        .catch(error => {
          logError(`handleGroupPostError: ${error}`);
        });
    } else if (hasPermission({ permission: 'trails:manage' })) {
      // No edits made, but return to trail page as would do if edits had been saved
      const { localizedUrl } = getFormattedTrailUrls(trail, languageRegionCode);
      window.location = localizedUrl;
    }
  },
  handleSaveClick(e, targetId) {
    const {
      intl: { formatMessage },
      context,
      displayMetric,
      trail,
      routeInfo,
      defaultMap,
      rapidRoute,
      nextTrailCallback,
      selectedActivities
    } = this.props;
    const { languageRegionCode } = context;
    const { id } = trail;
    const { detailsEdits } = this.refs;
    const { isPendingTrail } = this.state;

    e.preventDefault();
    e.stopPropagation();

    // eslint-disable-next-line eqeqeq
    if (detailsEdits.getNameValue().trim() == '') {
      alert(formatMessage({ defaultMessage: 'Please enter a trail name.' }));
      return false;
    }

    if (id) {
      if (isNaN(detailsEdits.state.cityId)) {
        alert(formatMessage({ defaultMessage: 'Please choose or enter a city.' }));
        return false;
      }
      // eslint-disable-next-line eqeqeq
      if (detailsEdits.state.cityId == -1) {
        const { freeFormCity } = detailsEdits.state;
        const regionAndCountry = detailsEdits.refs.regionAndCountry.getSelectedValues();
        // eslint-disable-next-line eqeqeq
        if (freeFormCity == '') {
          alert(formatMessage({ defaultMessage: 'Please enter a city name.' }));
          return false;
        }
        if (regionAndCountry.countryId <= 0) {
          alert(formatMessage({ defaultMessage: 'Please choose a country.' }));
          return false;
        }
      }
    }
    let vrouteInfo = routeInfo;
    if (!vrouteInfo) {
      vrouteInfo = {
        distance: getMapDistance(defaultMap)
      };
    }
    if (!(vrouteInfo.distance > 0 || trail.defaultMap)) {
      alert(formatMessage({ defaultMessage: 'Please draw the route of the trail.' }));
      return false;
    }

    if (!hasPermission({ permission: 'trails:manage' }) && vrouteInfo.distance < MIN_TRAIL_LENGTH_METERS) {
      const minimumLength = MapStatsUtil.metersToFormattedUserUnits(MIN_TRAIL_LENGTH_METERS, 1, displayMetric, languageRegionCode);
      const currentLength = MapStatsUtil.metersToFormattedUserUnits(vrouteInfo.distance, 1, displayMetric, languageRegionCode);
      alert(
        formatMessage(
          {
            defaultMessage: 'The trail must be at least {minimumLength} long and is currently only {currentLength}.'
          },
          { minimumLength, currentLength }
        )
      );
      return false;
    }

    if (!(Object.keys(selectedActivities).length > 0)) {
      alert(formatMessage({ defaultMessage: 'Please select at least one activity for the trail.' }));
      return false;
    }

    if (!detailsEdits.getDifficultyEdit()) {
      alert(formatMessage({ defaultMessage: 'Please select a difficulty rating for the trail.' }));
      return false;
    }

    // eslint-disable-next-line eqeqeq
    const pressedApprove = hasPermission({ permission: 'trails:manage' }) && targetId != 'save-no-approval-btn';
    // eslint-disable-next-line eqeqeq
    const pressedMakePending = hasPermission({ permission: 'trails:manage' }) && targetId == 'make-pending-btn';
    if (!pressedMakePending) {
      const routeType = detailsEdits.getRouteTypeValue();
      // eslint-disable-next-line eqeqeq
      if (!(!routeType || routeType == '')) {
        if (vrouteInfo.distance > 0) {
          // eslint-disable-next-line eqeqeq
          if (vrouteInfo.endsCoincide && routeType == 'P') {
            if (
              !confirm(
                formatMessage({
                  defaultMessage:
                    "The start & end of the route are at the same location even though the route type is set to Point-to-Point. Please fix the route or correct the route type before proceeding. If you're sure this is correct press OK."
                })
              )
            ) {
              return false;
            }
            // eslint-disable-next-line eqeqeq
          } else if (!vrouteInfo.endsCoincide && routeType != 'P' && vrouteInfo.routeStartPt && vrouteInfo.routeEndPt) {
            const startToEnd = turfDistance(vrouteInfo.routeStartPt, vrouteInfo.routeEndPt) * 1000;
            if (startToEnd > 100) {
              alert(
                formatMessage({
                  defaultMessage:
                    "The start & end of the trail aren't at the same location. Please finish drawing the route back to the starting point."
                })
              );
              return false;
            }
          }
        }
      } else {
        alert(formatMessage({ defaultMessage: 'Please select a route type for the trail.' }));
        return false;
      }
    }

    const allTrailEdits = detailsEdits.getAllTrailEdits();
    if (rapidRoute && isPendingTrail && pressedApprove) {
      this.setState({ setToActive: true });
      // If the admin pressed Approve, but did not change anything, generate at least one
      // edit item (even though it is not an actual change) so that trail is updated to approved in the next batch run.
      // eslint-disable-next-line eqeqeq
      if (allTrailEdits.length == 0) {
        allTrailEdits.push(this.refs.detailsEdits.getNameEdit());
      }
    } else if (rapidRoute && !isPendingTrail && pressedMakePending) {
      this.setState({ setToPending: true });
      // If the admin pressed Make Pending, but did not change anything, generate at least one
      // edit item (even though it is not an actual change) so that trail is updated to Pending (as soon as posted).
      // eslint-disable-next-line eqeqeq
      if (allTrailEdits.length == 0) {
        allTrailEdits.push(this.refs.detailsEdits.getNameEdit());
      }
    }

    this.saveEdits(allTrailEdits);
    if (!hasPermission({ permission: 'trails:manage' }) && !isPendingTrail) {
      this.handleTrailView(nextTrailCallback);
    }
    return false;
  },

  handleRejectTrailClick(e) {
    const {
      trail: { id }
    } = this.props;

    e.preventDefault();
    e.stopPropagation();

    if (!confirm('Reject this new trail?')) {
      return false;
    }

    destroy(`/api/alltrails/trails/${id}`)
      .then(() => {
        // should take user to a blank contribute view to indicate that the trail was deleted
        window.location.reload();
      })
      .catch(error => {
        logError(`Failure: ${error}`);
      });

    return false;
  },
  render() {
    const {
      intl,
      context,
      displayMetric,
      hasAdminFeatures,
      hasObjectPermissions,
      trail,
      routeInfo,
      insertRouteInfo,
      defaultMap,
      currentCity,
      mapCenter,
      zoom,
      rapidRoute,
      allowNameEditing,
      handleAddTrailClick,
      handleMapChange,
      messagingChannel,
      allActivities,
      allFeatures,
      allObstacles,
      allDogs,
      allAccessibility,
      allSurface,
      allKids,
      originalActivities,
      originalFeatures,
      originalObstacles,
      selectedActivities,
      selectedFeatures,
      selectedObstacles,
      setSelectedActivities,
      setSelectedFeatures,
      setSelectedObstacles,
      originalCollectionMappings,
      collectionMappings,
      setCollectionMappings
    } = this.props;
    const { formatMessage, formatNumber } = intl;
    const { languageRegionCode, adminUser } = context;
    const { id, slug, name, area, location, trailCounts } = trail;
    const { isPendingTrail, currentState } = this.state;
    const photoUrl = id ? `/api/alltrails/trails/${id}/profile_photo?api_key=${X_AT_KEY}&size=small_square` : '';
    const { url: trailUrl } = getFormattedTrailUrls(trail, languageRegionCode);
    let trailInfoLinks = null;
    const isLoading = currentState === 'saving' || currentState === 'loading';
    let cancelButton;
    let saveButtonLabel = <FormattedMessage defaultMessage="Save" />;
    let saveNoApprovalButton = null;
    let makePendingButton = null;
    let addButton = null;
    let geocontributeLink = null;
    const adminAndCenterAvailable = adminUser && mapCenter;
    if (adminAndCenterAvailable) {
      geocontributeLink = (
        <li>
          <Link
            size="sm"
            href={`/manage/geo-contribute?latitude=${mapCenter[0]}&longitude=${mapCenter[1]}&zoom=${zoom}`}
            target="_blank"
            variant="primary"
            noUnderline
          >
            Geospatial
          </Link>
        </li>
      );
    }

    if (isPendingTrail) {
      if (rapidRoute && id) {
        cancelButton = (
          <Button
            text={this.props.intl.formatMessage({ defaultMessage: 'Reject' })}
            onClick={this.handleRejectTrailClick}
            testId="reject-btn"
            type="button"
            variant="default"
            size="sm"
          />
        );
        saveButtonLabel = <FormattedMessage defaultMessage="Approve" />;
        saveNoApprovalButton = (
          <Button
            text={this.props.intl.formatMessage({ defaultMessage: 'Save only' })}
            onClick={e => this.handleSaveClick(e, 'save-no-approval-btn')}
            testId="save-no-approval-btn"
            type="button"
            variant="primary"
            size="sm"
          />
        );
      } else
        cancelButton = (
          <Button
            text={this.props.intl.formatMessage({ defaultMessage: 'Cancel' })}
            linkInfo={{ href: LanguageSupportUtil.wrapUrlSafe('/explore', languageRegionCode) }}
            testId="cancel-btn"
            type="button"
            variant="flat"
            size="sm"
          />
        );
      if (rapidRoute && hasPermission({ permission: 'trails:manage' }))
        addButton = (
          <Button
            text={this.props.intl.formatMessage({ defaultMessage: 'Add trail' })}
            onClick={handleAddTrailClick}
            testId="add-trail-btn"
            type="button"
            variant="flat"
            size="sm"
          />
        );
      // Admin users can edit, normal users cannot. It _not_ being a pending trail implies we're an admin
    } else if (rapidRoute) {
      addButton = (
        <Button
          text={this.props.intl.formatMessage({ defaultMessage: 'Add trail' })}
          onClick={handleAddTrailClick}
          testId="add-trail-btn"
          type="button"
          variant="flat"
          size="sm"
        />
      );
      cancelButton = (
        <Button
          text={this.props.intl.formatMessage({ defaultMessage: 'Cancel' })}
          linkInfo={{ href: trailUrl }}
          testId="cancel-btn"
          type="button"
          variant="default"
          size="sm"
        />
      );
      makePendingButton = (
        <Button
          text={this.props.intl.formatMessage({ defaultMessage: 'Make pending' })}
          onClick={e => this.handleSaveClick(e, 'make-pending-btn')}
          testId="make-pending-btn"
          type="button"
          variant="flat"
          size="sm"
        />
      );
    } else {
      cancelButton = (
        <Button
          text={this.props.intl.formatMessage({ defaultMessage: 'Cancel' })}
          linkInfo={{ href: trailUrl }}
          testId="cancel-btn"
          type="button"
          variant="default"
          size="sm"
        />
      );
    }

    if (rapidRoute) {
      const googleLink = (
        <li>
          <Link size="sm" href={`https://www.google.com/search?q=${encodeURIComponent(name)}`} target="_blank" variant="primary" noUnderline>
            Google
          </Link>
        </li>
      );
      let parkLink = null;
      let parkMapLink = null;
      let trailSource = null;
      let numViews = null;

      let isCountryTopTrail = null;
      if (area) {
        parkLink = (
          <li>
            <Link size="sm" href={`/parks/${area.slug}`} target="_blank" variant="primary" noUnderline>
              <FormattedMessage defaultMessage="Park" />
            </Link>
          </li>
        );
        if (area.map_url) {
          parkMapLink = (
            <li>
              <Link size="sm" href={area.map_url} target="_blank" variant="primary" noUnderline>
                <FormattedMessage defaultMessage="Map" />
              </Link>
            </li>
          );
        }
      }

      if (trail.trailSource) {
        trailSource = <li>{trail.trailSource}</li>;
      }

      if (trail.numViews) {
        const formatted = formatNumber(trail.numViews);
        numViews = (
          <li>
            <FormattedMessage defaultMessage="Num views: {count}" values={{ count: formatted }} />
          </li>
        );
      }

      if (trail.isCountryTopTrail) {
        const yes = formatMessage({ defaultMessage: 'Yes' });
        const no = formatMessage({ defaultMessage: 'No' });
        const value = trail.isCountryTopTrail === 'true' ? yes : no;
        isCountryTopTrail = (
          <li>
            <FormattedMessage defaultMessage="Top trail: {value}" values={{ value }} />
          </li>
        );
      }

      trailInfoLinks = (
        <span className="contribute-trail-links">
          <ul>
            {googleLink}
            {parkLink}
            {parkMapLink}
            {trailSource}
            {geocontributeLink}
          </ul>
          <ul>
            {numViews}
            {isCountryTopTrail}
          </ul>
        </span>
      );
    }

    let lastEditedTime = null;
    if (trail.lastEditedTimestamp) {
      // only humanize if diff is less than a day
      const parsedTime = new Date(trail.lastEditedTimestamp);
      const now = new Date();
      let time = null;
      if (now - parsedTime > 86400000) {
        time = parsedTime.toDateString();
      } else {
        time = humanizeTimeSpan(trail.lastEditedTimestamp);
      }
      lastEditedTime = (
        <div>
          <FormattedMessage defaultMessage="Last edited: {time}" values={{ time }} />
        </div>
      );
    }

    let photosLink = null;
    if (trailCounts) {
      const photosUrl = hasPermission({ permission: 'trails:manage' }) ? `/contribute/admin/photos?trail_id=${id}` : `/trail/${slug}/photos`;
      photosLink = (
        <Link size="sm" href={photosUrl} target="_blank" variant="primary" noUnderline className="photos-link">
          <FormattedMessage defaultMessage="Photos ({count})" values={{ count: trailCounts.photoCount }} />
        </Link>
      );
    }

    let editItemsContainerClasses = 'edit-items-container';
    let titleDiv;
    // eslint-disable-next-line jsx-a11y/alt-text
    const trailPhoto = <img src={photoUrl} height="80" width="80" />;

    if (isPendingTrail) {
      if (id) {
        if (slug) {
          titleDiv = (
            <div id="contribute-title" className="xlate-none">
              {trailPhoto}
              <div id="trail-contribute-title-text">
                <span title={name} className="contribute-trail-name">
                  <FormattedMessage defaultMessage="Pending:" />{' '}
                  <Link size="lg" href={trailUrl} target="_blank" variant="primary" noUnderline className="contribute-trail-name">
                    {name}
                  </Link>
                </span>
                <br />
                <span className="contribute-trail-location">
                  <ul>{this.renderLocation(location)}</ul>
                  {lastEditedTime}
                  {trailInfoLinks}
                </span>
              </div>
            </div>
          );
        } else {
          editItemsContainerClasses += ' with-small-title';
          titleDiv = (
            <div id="contribute-title" className="small-title xlate-none">
              <div id="trail-contribute-title-text">
                <span className="contribute-trail-name">
                  <FormattedMessage defaultMessage="Pending:" /> {name}
                </span>
                <br />
              </div>
            </div>
          );
        }
      } else {
        editItemsContainerClasses += ' with-no-title';
        titleDiv = null;
      }
    } else {
      titleDiv = (
        <div id="contribute-title" className="xlate-none">
          {trailPhoto}
          <div id="trail-contribute-title-text">
            <span title={name} className="contribute-trail-name">
              <Link size="lg" href={trailUrl} target="_blank" variant="primary" noUnderline className="contribute-trail-name">
                {name}
              </Link>
            </span>
            <br />
            <span className="contribute-trail-location">
              <ul>{this.renderLocation(location)}</ul>
              {lastEditedTime}
              {photosLink}
              {trailInfoLinks}
            </span>
          </div>
        </div>
      );
    }

    return (
      <div id="edit-trail-contribute-box">
        {titleDiv}
        <div className={editItemsContainerClasses}>
          <TrailDetailsBox
            geocontributeLink={geocontributeLink}
            allowNameEditing={allowNameEditing}
            currentCity={currentCity}
            displayMetric={displayMetric}
            hasAdminFeatures={hasAdminFeatures}
            hasObjectPermissions={hasObjectPermissions}
            isPendingTrail={isPendingTrail}
            languageRegionCode={languageRegionCode}
            mapCenter={mapCenter}
            messagingChannel={messagingChannel}
            rapidRoute={rapidRoute}
            ref="detailsEdits"
            routeInfo={routeInfo}
            insertRouteInfo={insertRouteInfo}
            trail={trail}
            handleMapChange={handleMapChange}
            defaultMap={defaultMap}
            context={context}
            allActivities={allActivities}
            allFeatures={allFeatures}
            allObstacles={allObstacles}
            allDogs={allDogs}
            allAccessibility={allAccessibility}
            allSurface={allSurface}
            allKids={allKids}
            originalActivities={originalActivities}
            originalFeatures={originalFeatures}
            originalObstacles={originalObstacles}
            selectedActivities={selectedActivities}
            selectedFeatures={selectedFeatures}
            selectedObstacles={selectedObstacles}
            setSelectedActivities={setSelectedActivities}
            setSelectedFeatures={setSelectedFeatures}
            setSelectedObstacles={setSelectedObstacles}
            originalCollectionMappings={originalCollectionMappings}
            collectionMappings={collectionMappings}
            setCollectionMappings={setCollectionMappings}
            mapboxAccessToken={this.props.mapboxAccessToken}
            waypointMapId={this.props.waypointMapId}
            waypoints={this.props.waypoints}
            refetchTrailProps={this.props.refetchTrailProps}
            areWaypointsLoading={this.props.areWaypointsLoading}
          />
        </div>
        <div id="contribute-action-bar" className="xlate-none">
          <Link size="sm" href={getHelpCenterUrl(languageRegionCode, 360018930672)} target="_blank" variant="primary">
            <FormattedMessage defaultMessage="Help" />
          </Link>
          <span className="buttons">
            {isLoading && <LoadingDots />}
            {!isLoading && (
              <>
                {addButton}
                {saveNoApprovalButton}
                {makePendingButton}
                {cancelButton}
                <Button text={saveButtonLabel} onClick={this.handleSaveClick} testId="save-btn" type="button" variant="primary" size="sm" />
              </>
            )}
          </span>
        </div>
      </div>
    );
  }
});

// eslint-disable-next-line import/prefer-default-export
export { EditTrailBox };
