import isEqual from 'underscore/modules/isEqual';
import { featureCollection, point, lineString } from '@turf/helpers';
import { END, NODE, PLUS, START, EDIT_START, EDIT_END, START_END, EDIT_START_END } from 'utils/constants/MarkerIds';
import { addPolylines, removePolylines } from './polylines';
import { addChevrons, removeChevrons } from './chevrons';
import { addMarkers, removeMarkers } from './markers';
import { addLayer, addLayerEvent } from '../layers/layer_helpers';
import { createLabelLayer } from '../layers/labels';
import { closestInterpPt } from '../../trail_planner/closest_on_line';
import { addOrUpdateGeojsonSource } from '../sources/geojson_helpers';
import { getDraggableId } from './draggable';
import { isCloseToEnds } from '../point_helpers';

const trailPlannerId = 'trailPlannerMap';
const routeNodesId = `${trailPlannerId}-nodes`;
const routeNodesDragId = getDraggableId(routeNodesId);
const routeInsertModeNodesId = `${trailPlannerId}-insert-mode-nodes`;
const routeInsertModeNodesDragId = getDraggableId(routeInsertModeNodesId);
const routePolylinesId = `${trailPlannerId}-polylines`;
const routeInsertModePolylinesId = `${trailPlannerId}-insert-mode-polylines`;
const routeChevronsId = `${trailPlannerId}-chevrons`;
const routeInsertModeChevronsId = `${trailPlannerId}-insert-route-chevrons`;
const newRouteNodeId = 999999999;

const styleRouteNodes = (trailPlanner, startNode, messages, hasAdminFeatures = false) => {
  const nodes = trailPlanner.routeNodes;

  return nodes.map((routeNode, i) => {
    // eslint-disable-next-line no-use-before-define
    const iconImage = getRouteNodeMarkerType(trailPlanner, nodes, startNode, routeNode, i);
    const draggable = isRouteNodeDraggable(trailPlanner, nodes, i);
    const labelMessage = iconImage === 'map-marker-node' && messages.dragToChange;
    // eslint-disable-next-line no-use-before-define
    const positionMessage = getRouteNodePositionMessage(routeNode, messages, hasAdminFeatures);

    return {
      ...routeNode,
      iconImage,
      draggable,
      labelMessage,
      positionMessage
    };
  });
};

const getRouteNodePositionMessage = (routeNode, messages, hasAdminFeatures) => {
  if (!hasAdminFeatures || !routeNode?.position) {
    return undefined;
  }

  return messages[`${routeNode.position}Selected`];
};

const getRouteNodeMarkerType = (trailPlanner, nodes, startNode, node, i) => {
  const { isInsertTrailPlanner } = trailPlanner;
  // first node
  if (i === 0) {
    if (isInsertTrailPlanner) {
      // if the first node of the insert planner is also the routes start point
      if (isEqual(node.lngLat, startNode.lngLat)) {
        return 'map-marker-edit-start-end';
      }
      return 'map-marker-edit-start';
    }

    return 'map-marker-start';
  }
  // last node
  if (i === nodes.length - 1) {
    if (isEqual(node.lngLat, nodes[0].lngLat)) {
      return isInsertTrailPlanner ? 'map-marker-edit-start-end' : 'map-marker-start-end';
    }
    return isInsertTrailPlanner ? 'map-marker-edit-end' : 'map-marker-end';
  }
  return 'map-marker-node';
};

const isRouteNodeDraggable = (trailPlanner, nodes, i) => {
  const { isInsertTrailPlanner } = trailPlanner;
  if (!isInsertTrailPlanner) {
    return true;
  }
  // start node of insert planner is not draggable
  if (i === 0) {
    return false;
  }
  // end node of insert planner is not draggable
  if (i === nodes.length - 1) {
    return false;
  }
  return true;
};

const addPolylineEvents = (map, trailPlanner, layerId, pointText, hasBuffer = false) => {
  // Remove new route node on mouse-out
  const onMouseLeave = () => {
    map.getCanvas().style.cursor = map.customStyle.cursor;
    addOrUpdateGeojsonSource(map, routeNodesDragId, featureCollection([]));
  };

  // Show new route node when mousing-over polylineGeojson
  // Note: Here we are reusing the routeNodesDragId source/layer for this node to reduce the need to add yet another layer
  const onMouseMove = e => {
    if (map.shiftKeyDown) {
      onMouseLeave();
      return;
    }
    map.getCanvas().style.cursor = 'pointer';
    const polylineIdx = e.features[0].properties.idx;
    const polyline = trailPlanner.routePolylines[polylineIdx];
    const { points } = polyline;
    if (isCloseToEnds(map, e.lngLat, points, 16)) {
      addOrUpdateGeojsonSource(map, routeNodesDragId, featureCollection([]));
      return;
    }
    const closest = closestInterpPt(e.lngLat.toArray(), points);
    const closestLngLat = closest.interpolatedPoint ? closest.interpolatedPoint : points[closest.cropIndex];
    const newRouteNode = {
      id: newRouteNodeId,
      cropIdx: closest.cropIndex,
      polylineIdx,
      iconImage: 'map-marker-plus',
      addPointText: pointText
    };
    const newRouteNodeGeojson = point(closestLngLat, newRouteNode, { id: newRouteNode.id });
    addOrUpdateGeojsonSource(map, routeNodesDragId, newRouteNodeGeojson);
    if (!map.dragging) {
      addLayer(map, createLabelLayer(`${routeNodesDragId}-labels`, routeNodesDragId, 'addPointText', true));
    }
  };

  const hoverLayer = hasBuffer ? `${layerId}-clickBuffer` : layerId;

  addLayerEvent(map, hoverLayer, true, 'mousemove', onMouseMove, false);
  addLayerEvent(map, hoverLayer, true, 'mouseleave', onMouseLeave, false);
};

const addNodeEvents = (map, nodesId, trailPlanner) => {
  // Handle route node dragged to new location
  addLayerEvent(map, nodesId, true, 'draglayerend', e => {
    const routeNode = e.draggingFeature.properties;
    trailPlanner.routeNodeMoved(routeNode, e.lngLat.toArray());
  });
};

// eslint-disable-next-line default-param-last
const addTrailPlannerOverlay = (map, trailPlanner, lineColor, messages, hasAdminFeatures = false, startNode, getToolbarConfig = null) => {
  // Draw route polylines
  const layerId = trailPlanner.isInsertTrailPlanner ? routeInsertModePolylinesId : routePolylinesId;
  const chevronsId = trailPlanner.isInsertTrailPlanner ? routeInsertModeChevronsId : routeChevronsId;
  const nodesId = trailPlanner.isInsertTrailPlanner ? routeInsertModeNodesId : routeNodesId;
  const { color } = trailPlanner;
  const lineStrings = trailPlanner.routePolylines.map((routePolyline, idx) => lineString(routePolyline.points, { idx, color }));
  const polylineGeojson = featureCollection(lineStrings);
  const polylineLayerExists = map.getLayer(layerId);
  const clickBuffer = hasAdminFeatures ? 4 : 0;
  const polylineOpts = lineColor ? { 'line-color': lineColor } : null;

  addPolylines(map, layerId, polylineGeojson, {} /* sourceOpts */, polylineOpts, null, undefined, clickBuffer);
  addChevrons(map, chevronsId, layerId);
  if (!polylineLayerExists) {
    const hasBuffer = clickBuffer > 0;
    addPolylineEvents(map, trailPlanner, layerId, messages.clickToAddPoint, hasBuffer);
  }

  // Draw route nodes
  const routeNodes = styleRouteNodes(trailPlanner, startNode, messages, hasAdminFeatures);
  const nodeLayerExists = map.getLayer(nodesId);
  addMarkers(
    map,
    nodesId,
    routeNodes,
    [START, END, NODE, PLUS, START_END, EDIT_START, EDIT_END, EDIT_START_END],
    null,
    null,
    true,
    hasAdminFeatures,
    getToolbarConfig
  );
  if (!nodeLayerExists) {
    addNodeEvents(map, nodesId, trailPlanner);
  }
};

const removeTrailPlannerMap = map => {
  removeMarkers(map, routeNodesId, true);
  removeChevrons(map, routeChevronsId);
  removePolylines(map, routePolylinesId);
};

const removeInsertModeMap = map => {
  removeMarkers(map, routeInsertModeNodesId, true);
  removeChevrons(map, routeInsertModeChevronsId);
  removePolylines(map, routeInsertModePolylinesId);
};

export {
  addTrailPlannerOverlay,
  removeTrailPlannerMap,
  removeInsertModeMap,
  routeNodesId,
  routeInsertModeNodesId,
  routeNodesDragId,
  routeInsertModeNodesDragId,
  newRouteNodeId,
  routePolylinesId,
  routeChevronsId
};
