import { useEffect, useState, useRef } from 'react';
import type { Context } from 'types/Context';
import type { User } from 'types/User';
import TrailDetailsCard from 'components/cards/TrailDetailsCard';
import NearbyTrailsList from 'components/NearbyTrailsList';
import ViewFullMap from 'components/shared/ViewFullMap';
import SignupModal from 'components/SignupModal';
import Link from '@alltrails/shared/denali/components/Link';
import Toast from '@alltrails/shared/denali/components/Toast';
import { AllTrailsBounds } from '@alltrails/maps/types/Geo';
import TrailActionBar from 'components/TrailActionBar';
import AdminTrailDropdown from 'components/TrailDetails/AdminTrailDropdown';
import FlyoverEntry from 'components/TrailDetails/FlyoverEntry';
import ProgrammaticOverview from 'components/TrailDetails/ProgrammaticOverview';
import TagGroup from '@alltrails/shared/denali/components/TagGroup';
import TrailConditions from 'components/TrailDetails/TrailConditions';
import TrailOverview from 'components/TrailDetails/TrailOverview';
import TrailWeatherWrapper from 'components/TrailDetails/TrailWeather/TrailWeatherWrapper';
import TrailFaq from 'components/TrailFaq';
import TrailStats from 'components/TrailStats';
import TrailUgc from 'components/trailUgc/TrailUgc';
import RatingsBreakdown from 'components/trailUgc/types/RatingsBreakdown';
import { HAWAII_ID, UNITED_STATES_ID } from 'constants/CountryIDs';
import logTrailPageViewLimitReached from '@alltrails/analytics/events/logTrailPageViewLimitReached';
import useToggle from '@alltrails/shared/hooks/useToggle';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import useUser from 'hooks/useUser';
import { FormattedMessage } from '@alltrails/shared/react-intl';
import ListMethods from 'types/ListMethods';
import { Photo } from 'types/Photo';
import type { GarminCourse } from '@alltrails/shared/types/trail';
import TrailType, { FaqWeatherForecast } from 'types/Trails/Trail';
import { getTagGroupArray } from 'utils/trail_helpers';
import CarouselDisplayTrigger from '@alltrails/analytics/enums/CarouselDisplayTrigger';
import Ad from 'components/Ad';
import LocationServerData from 'types/LocationServerData';
import ShareObject from 'types/ShareObject';
import TrailTabType from 'types/TrailTabType';
import useDeeplinkComponent from 'components/DeepLink/hooks';
import InstallBanner from 'components/InstallBanner';
import InstallPromptLocation from '@alltrails/analytics/enums/InstallPromptLocation';
import { LocalBusinessAddress, localBusinessDataAttributes } from 'components/shared/LocalBusinessStructuredData';
import hasPermission from 'utils/hasPermission';
import getTrailProps from 'api/Trail';
import logError from 'utils/logError';
import * as styles from './Trail.module.scss';

type Props = {
  adminOrPendingOwner: boolean;
  avgRating?: string;
  context: Context;
  descriptionInitiallyExpanded: boolean;
  exactExplorePath?: string;
  exploreFlag?: boolean;
  explorePath?: string;
  initializingFlyover?: boolean;
  listMethods?: ListMethods;
  localizedTrailSlug?: string;
  locationServerData: LocationServerData;
  maxAllowedTrailCount: number;
  overview?: string;
  offerMergeEnds?: unknown;
  trailTags?: {
    whoToBring: any;
    whatToDo: any;
    whatToSeeAndObstacles: any;
  };
  nearbyTrails: TrailType[];
  trail: TrailType;
  numPhotos?: number;
  pending?: boolean;
  photoUrl?: string;
  webpStaticMapUrl?: string;
  popTrailReview?: boolean;
  webpPhotoUrl?: string;
  staticMapUrl?: string;
  overviewProps: {
    tabs: Array<TrailTabType>;
  };
  routeType?: string;
  trailPageLimitReached: boolean;
  trailConditions?: any[];
  ratingsBreakdown?: RatingsBreakdown;
  onFlyoverClick: () => void;
  shareObject?: ShareObject;
  photos?: Photo[];
  botDetected?: boolean;
  mapboxAccessToken: string;
  bounds: AllTrailsBounds;
  garminCourse?: GarminCourse;
  reviewSummary?: string;
  seoH1TestContent?: string;
  faqWeatherForecast?: FaqWeatherForecast[];
};

export default function Trail({
  context,
  exploreFlag,
  ratingsBreakdown,
  avgRating,
  trail,
  localizedTrailSlug,
  locationServerData,
  nearbyTrails,
  adminOrPendingOwner,
  photos,
  popTrailReview,
  routeType,
  overviewProps,
  overview,
  offerMergeEnds,
  trailTags,
  explorePath,
  exactExplorePath,
  pending,
  numPhotos,
  staticMapUrl,
  webpStaticMapUrl,
  photoUrl,
  webpPhotoUrl,
  listMethods,
  shareObject,
  trailConditions,
  descriptionInitiallyExpanded,
  trailPageLimitReached,
  maxAllowedTrailCount,
  initializingFlyover,
  onFlyoverClick,
  botDetected,
  mapboxAccessToken,
  bounds,
  garminCourse,
  reviewSummary,
  seoH1TestContent,
  faqWeatherForecast
}: Props) {
  const user = useUser();
  const languageRegionCode = useLanguageRegionCode();

  const [isOpen, toggle] = useToggle(trailPageLimitReached);
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [tabs, setTabs] = useState(overviewProps.tabs);
  const [isLoadingTabs, setIsLoadingTabs] = useState(false);
  const [isBannerOpen, setIsBannerOpen] = useDeeplinkComponent('banner', true);
  const previousUser = useRef<User>(user);

  useEffect(() => {
    if (!previousUser.current && user) {
      setIsToastOpen(true);
    }

    previousUser.current = user;
  }, [setIsToastOpen, user]);

  useEffect(() => {
    if (isToastOpen) {
      setTimeout(() => {
        setIsToastOpen(false);
      }, 3000);
    }
  }, [isToastOpen]);

  useEffect(() => {
    if (trailPageLimitReached) {
      logTrailPageViewLimitReached({ limit: maxAllowedTrailCount, trail_id: trail.trail_id });
    }
  }, [trailPageLimitReached, maxAllowedTrailCount, trail]);

  const refetchTrailProps = () => {
    if (trail.ID) {
      setIsLoadingTabs(true);
      getTrailProps(trail.ID, true)
        .then(data => {
          if (data.overviewProps) {
            setTabs?.(data.overviewProps.tabs);
            setIsLoadingTabs(false);
          }
        })
        .catch(e => {
          logError(`Error fetching trail props: ${e}`);
          setIsLoadingTabs(false);
        });
    }
  };

  function renderRobotsTag() {
    if (pending) {
      return <meta content="noindex" name="robots" />;
    }
    return null;
  }

  function renderNearbyTrails() {
    if (!nearbyTrails || nearbyTrails.length === 0) {
      return null;
    }

    return (
      <NearbyTrailsList context={context} explorePath={explorePath} listMethods={listMethods} nearbyTrails={nearbyTrails} trailId={trail.trail_id} />
    );
  }

  function renderTrailSidebar() {
    if (exploreFlag) {
      return null;
    }

    let adminDropdown = null;

    if (hasPermission({ permission: 'trails:manage' })) {
      adminDropdown = <AdminTrailDropdown offerMergeEnds={offerMergeEnds} statusPending={pending} trailId={trail.trail_id} />;
    }

    return (
      <div className={styles.trailSidebar}>
        <aside className="trail-aside">
          <ViewFullMap
            className={styles.hideOnMobile}
            href={exactExplorePath}
            lat={trail._geoloc.lat}
            lng={trail._geoloc.lng}
            staticMapUrl={staticMapUrl}
            trail={trail}
            webpStaticMapUrl={webpStaticMapUrl}
          />
          <div className={styles.suggestEditAndDropdown}>
            <Link href={`/${trail.slug}/edit?ref=trail-suggest-edit`} rel={['nofollow']} target="_blank" testId="trail-suggest-edit" size="sm">
              <FormattedMessage defaultMessage="Suggest edit" />
            </Link>
            {adminDropdown}
          </div>
          <Ad adUnitPath="/4689141/alltrails_trail_300x250" className={styles.ad1} id="gpt-trail-ad-desktop" screenSize="desktop" trail={trail} />
          {renderNearbyTrails()}
        </aside>
      </div>
    );
  }

  function renderMobileMapSection() {
    if (exploreFlag) {
      return null;
    }

    return (
      <div className={styles.mobileStaticMap}>
        <ViewFullMap
          href={exactExplorePath}
          lat={trail._geoloc.lat}
          lng={trail._geoloc.lng}
          staticMapUrl={staticMapUrl}
          trail={trail}
          webpStaticMapUrl={webpStaticMapUrl}
        />
        <Ad adUnitPath="/4689141/alltrails_trail_300x250" className={styles.ad1Mobile} id="gpt-trail-ad-mobile" screenSize="mobile" trail={trail} />
      </div>
    );
  }

  const userId = user ? user.id : null;

  const tags = getTagGroupArray(trailTags);

  return (
    <>
      <InstallBanner
        isOpen={isBannerOpen}
        onRequestClose={() => setIsBannerOpen(false)}
        pageId={trail.trail_id}
        pageType="trail"
        promptLocation={InstallPromptLocation.TrailPage}
      />
      <SignupModal
        trigger={CarouselDisplayTrigger.TrailDetailsWall3rdUniqueViewed}
        isOpen={isOpen}
        onRequestClose={() => {
          global.history.back();
        }}
        onSuccess={toggle}
        trailId={trail.trail_id}
      />
      <div className={styles.container} {...(trail.num_reviews ? localBusinessDataAttributes(localizedTrailSlug ?? trail.slug) : {})}>
        <LocalBusinessAddress city={trail.city_name} state={trail.state_name} country={trail.country_name} />
        {renderRobotsTag()}
        <TrailDetailsCard
          exploreFlag={exploreFlag}
          isPending={pending}
          listMethods={listMethods}
          numPhotos={numPhotos}
          photos={photos}
          photoUrl={photoUrl}
          trail={trail}
          webpPhotoUrl={webpPhotoUrl}
          seoH1TestContent={seoH1TestContent}
          context={context}
        />
        <TrailActionBar
          context={context}
          exploreFlag={exploreFlag}
          numPhotos={numPhotos}
          pending={pending}
          photos={photos}
          shareObject={shareObject}
          trail={trail}
          garminCourse={garminCourse}
        />
        {isToastOpen && (
          <Toast
            message={<FormattedMessage defaultMessage="Welcome, {name}!" values={{ name: user?.firstName }} />}
            position="alternative"
            type="success"
            testId="welcome_toast"
          />
        )}
        <div className={styles.trailInfoContainer}>
          <article className={styles.trailInfo}>
            <TrailStats routeType={routeType} trail={trail} />
            <ProgrammaticOverview overview={overview} trailId={trail.trail_id} />
            <FlyoverEntry initializingFlyover={initializingFlyover} onEntryClick={onFlyoverClick} trail={trail} />
            <div className={styles.divider} />
            <div className={styles.tagGroupContainer}>
              <TagGroup tags={tags} testId="tag-group" />
            </div>
            {renderMobileMapSection()}
            <TrailOverview
              adminOrPendingOwner={adminOrPendingOwner}
              descriptionInitiallyExpanded={descriptionInitiallyExpanded}
              tabs={tabs}
              trailId={trail.trail_id}
              refetchTrailProps={refetchTrailProps}
              isLoadingTabs={isLoadingTabs}
            />
            {!botDetected ? (
              <TrailWeatherWrapper context={context} trail={trail} bounds={bounds} mapboxAccessToken={mapboxAccessToken} />
            ) : (
              trailConditions &&
              languageRegionCode === 'en-US' &&
              (trail.country_id === UNITED_STATES_ID || trail.country_id === HAWAII_ID) && (
                <>
                  <TrailConditions exploreFlag={exploreFlag} trail={trail} trailConditions={trailConditions} /> {/* RTTC is a US-only feature */}
                </>
              )
            )}

            {!exploreFlag && (
              <Ad adUnitPath="/4689141/alltrails_trail2_300x250" className={styles.ad2} id="gpt-trail2-ad" screenSize="mobile" trail={trail} />
            )}

            <section id="activity-feeds">
              <TrailUgc
                adminOrPendingOwner={adminOrPendingOwner}
                avgRating={avgRating}
                context={context}
                exploreFlag={exploreFlag}
                locationServerData={locationServerData}
                popTrailReview={popTrailReview}
                ratingsBreakdown={ratingsBreakdown}
                section="trail-show"
                trail={trail}
                trailId={trail.trail_id}
                trailName={trail.name}
                userId={userId}
                reviewSummary={reviewSummary}
              />
            </section>
            <TrailFaq trailName={trail.name} trailOverviewTabs={tabs} faqWeatherForecast={faqWeatherForecast} />
          </article>
          {renderTrailSidebar()}
        </div>
      </div>
    </>
  );
}
