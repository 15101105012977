import { useMemo, useState } from 'react';
import { useIntl } from '@alltrails/shared/react-intl';
import { Trail } from '@alltrails/shared/types/trail';
import { obstacles as getObstacles, activities as getActivities, features as getFeatures } from '../../../../utils/activity_util';
import { Uid, Attribute, reduceAttributes, filterAttributes } from '../../../../utils/attributeUtils';

type Props = {
  trail: Trail;
  defaultMap: any;
};

const useAttributes = ({ trail: { attributes }, defaultMap }: Props) => {
  const intl = useIntl();
  const activity = defaultMap?.tracks?.[0]?.activity;

  // Create maps of the possible attributes for each section
  const { allActivities, allFeatures, allObstacles, allDogs, allAccessibility, allSurface, allKids } = useMemo(() => {
    // Attributes we no longer wish to expose
    const removedActivities = ['default', 'geocaching', 'mountaineering', 'nature-trips'];
    const removedFeatures: Uid[] = [];
    const removedObstacles = ['flooded', 'great', 'icy', 'old-growth', 'slippery'];

    // Categories of features
    const dogsFeatures = ['dogs', 'dogs-leash', 'dogs-no'];
    const accessibleFeatures = ['ada', 'strollers'];
    const surfaceFeatures = ['paved', 'partially-paved'];
    const kidsFeatures = ['kids'];

    const dogs: Attribute[] = [];
    const accesibility: Attribute[] = [];
    const surface: Attribute[] = [];
    const kids: Attribute[] = [];
    const otherFeatures: Attribute[] = [];
    const features = filterAttributes(getFeatures(intl), removedFeatures);
    features.forEach(feature => {
      if (dogsFeatures.includes(feature.uid)) {
        dogs.push(feature);
      } else if (accessibleFeatures.includes(feature.uid)) {
        accesibility.push(feature);
      } else if (surfaceFeatures.includes(feature.uid)) {
        surface.push(feature);
      } else if (kidsFeatures.includes(feature.uid)) {
        kids.push(feature);
      } else {
        otherFeatures.push(feature);
      }
    });

    return {
      allActivities: filterAttributes(getActivities(intl), removedActivities).reduce(reduceAttributes, {}),
      allFeatures: otherFeatures.reduce(reduceAttributes, {}),
      allObstacles: filterAttributes(getObstacles(intl), removedObstacles).reduce(reduceAttributes, {}),
      allDogs: dogs.reduce(reduceAttributes, {}),
      allAccessibility: accesibility.reduce(reduceAttributes, {}),
      allSurface: surface.reduce(reduceAttributes, {}),
      allKids: kids.reduce(reduceAttributes, {})
    };
  }, [intl]);

  // Memoize the originally assigned attributes
  const originalActivities = useMemo(() => {
    const activities = attributes?.activities?.reduce(reduceAttributes, {}) ?? {};
    if (activity) {
      activities[activity.uid] = activity;
    }
    return activities;
  }, [attributes?.activities, activity]);
  const originalObstacles = useMemo(() => attributes?.obstacles?.reduce(reduceAttributes, {}) ?? {}, [attributes?.obstacles]);
  const originalFeatures = useMemo(() => attributes?.features?.reduce(reduceAttributes, {}) ?? {}, [attributes?.features]);

  // Attribute selection state
  const [selectedActivities, setSelectedActivities] = useState(originalActivities);
  const [selectedFeatures, setSelectedFeatures] = useState(originalFeatures);
  const [selectedObstacles, setSelectedObstacles] = useState(originalObstacles);

  return {
    allActivities,
    allFeatures,
    allObstacles,
    allDogs,
    allAccessibility,
    allSurface,
    allKids,
    originalActivities,
    originalFeatures,
    originalObstacles,
    selectedActivities,
    selectedFeatures,
    selectedObstacles,
    setSelectedActivities,
    setSelectedFeatures,
    setSelectedObstacles
  };
};

export default useAttributes;
