import { FILTER_OBSTACLES_STRINGS, FILTER_ACTIVITIES_STRINGS, FILTER_ACCESS_STRINGS, FILTER_FEATURE_TYPES_STRINGS } from './filters/filters.strings';
import { IntlShape } from '@alltrails/shared/react-intl';
import { Activities, Features } from 'types/Trails';

const obstacles = (intl: IntlShape) => [
  { uid: 'great', name: intl.formatMessage(FILTER_OBSTACLES_STRINGS.GREAT) },
  { uid: 'blowdown', name: intl.formatMessage(FILTER_OBSTACLES_STRINGS.BLOWDOWN) },
  { uid: 'bridge-out', name: intl.formatMessage(FILTER_OBSTACLES_STRINGS.BRIDGE_OUT) },
  { uid: 'bugs', name: intl.formatMessage(FILTER_OBSTACLES_STRINGS.BUGS) },
  { uid: 'fee', name: intl.formatMessage(FILTER_OBSTACLES_STRINGS.FEE) },
  { uid: 'flooded', name: intl.formatMessage(FILTER_OBSTACLES_STRINGS.FLOODED) },
  { uid: 'icy', name: intl.formatMessage(FILTER_OBSTACLES_STRINGS.ICY) },
  { uid: 'muddy', name: intl.formatMessage(FILTER_OBSTACLES_STRINGS.MUDDY) },
  { uid: 'no-shade', name: intl.formatMessage(FILTER_OBSTACLES_STRINGS.NO_SHADE) },
  { uid: 'off-trail', name: intl.formatMessage(FILTER_OBSTACLES_STRINGS.OFF_TRAIL) },
  { uid: 'over-grown', name: intl.formatMessage(FILTER_OBSTACLES_STRINGS.OVER_GROWN) },
  { uid: 'private-property', name: intl.formatMessage(FILTER_OBSTACLES_STRINGS.PRIVATE_PROPERTY) },
  { uid: 'rocky', name: intl.formatMessage(FILTER_OBSTACLES_STRINGS.ROCKY) },
  { uid: 'scramble', name: intl.formatMessage(FILTER_OBSTACLES_STRINGS.SCRAMBLE) },
  { uid: 'snow', name: intl.formatMessage(FILTER_OBSTACLES_STRINGS.SNOW) },
  { uid: 'washed-out', name: intl.formatMessage(FILTER_OBSTACLES_STRINGS.WASHED_OUT) }
];

const activities = (intl: IntlShape) => [
  { uid: Activities.BACKPACKING, name: intl.formatMessage(FILTER_ACTIVITIES_STRINGS.BACKPACKING) },
  { uid: Activities.BIKE_TOURING, name: intl.formatMessage(FILTER_ACTIVITIES_STRINGS.BIKE_TOURING) },
  { uid: Activities.BIRDING, name: intl.formatMessage(FILTER_ACTIVITIES_STRINGS.BIRDING) },
  { uid: Activities.CAMPING, name: intl.formatMessage(FILTER_ACTIVITIES_STRINGS.CAMPING) },
  { uid: Activities.CROSS_COUNTRY_SKIING, name: intl.formatMessage(FILTER_ACTIVITIES_STRINGS.CROSS_COUNTRY_SKIING) },
  { uid: Activities.FISHING, name: intl.formatMessage(FILTER_ACTIVITIES_STRINGS.FISHING) },
  { uid: Activities.HIKING, name: intl.formatMessage(FILTER_ACTIVITIES_STRINGS.HIKING) },
  { uid: Activities.HORSEBACK_RIDING, name: intl.formatMessage(FILTER_ACTIVITIES_STRINGS.HORSEBACK_RIDING) },
  { uid: Activities.MOUNTAIN_BIKING, name: intl.formatMessage(FILTER_ACTIVITIES_STRINGS.MOUNTAIN_BIKING) },
  { uid: Activities.OFF_ROAD_DRIVING, name: intl.formatMessage(FILTER_ACTIVITIES_STRINGS.OFF_ROAD_DRIVING) },
  { uid: Activities.PADDLE_SPORTS, name: intl.formatMessage(FILTER_ACTIVITIES_STRINGS.PADDLE_SPORTS) },
  { uid: Activities.ROAD_BIKING, name: intl.formatMessage(FILTER_ACTIVITIES_STRINGS.ROAD_BIKING) },
  { uid: Activities.ROCK_CLIMBING, name: intl.formatMessage(FILTER_ACTIVITIES_STRINGS.ROCK_CLIMBING) },
  { uid: Activities.SCENIC_DRIVING, name: intl.formatMessage(FILTER_ACTIVITIES_STRINGS.SCENIC_DRIVING) },
  { uid: Activities.SKIING, name: intl.formatMessage(FILTER_ACTIVITIES_STRINGS.SKIING) },
  { uid: Activities.SNOWSHOEING, name: intl.formatMessage(FILTER_ACTIVITIES_STRINGS.SNOWSHOEING) },
  { uid: Activities.TRAIL_RUNNING, name: intl.formatMessage(FILTER_ACTIVITIES_STRINGS.TRAIL_RUNNING) },
  { uid: Activities.VIA_FERRATA, name: intl.formatMessage(FILTER_ACTIVITIES_STRINGS.VIA_FERRATA) },
  { uid: Activities.WALKING, name: intl.formatMessage(FILTER_ACTIVITIES_STRINGS.WALKING) },
  { uid: Activities.NATURE_TRIPS, name: intl.formatMessage(FILTER_ACTIVITIES_STRINGS.NATURE_TRIPS) }
];

const features = (intl: IntlShape) => [
  { uid: Features.ADA, name: intl.formatMessage(FILTER_ACCESS_STRINGS.ADA) },
  { uid: Features.BEACH, name: intl.formatMessage(FILTER_FEATURE_TYPES_STRINGS.BEACH) },
  { uid: Features.CAVE, name: intl.formatMessage(FILTER_FEATURE_TYPES_STRINGS.CAVE) },
  { uid: Features.CITY_WALK, name: intl.formatMessage(FILTER_FEATURE_TYPES_STRINGS.CITY_WALK) },
  { uid: Features.DOGS, name: intl.formatMessage(FILTER_ACCESS_STRINGS.DOGS) },
  { uid: Features.DOGS_NO, name: intl.formatMessage(FILTER_ACCESS_STRINGS.DOGS_NO) },
  { uid: Features.DOGS_LEASH, name: intl.formatMessage(FILTER_ACCESS_STRINGS.DOGS_LEASH) },
  { uid: Features.EVENT, name: intl.formatMessage(FILTER_FEATURE_TYPES_STRINGS.EVENT) },
  { uid: Features.FOREST, name: intl.formatMessage(FILTER_FEATURE_TYPES_STRINGS.FOREST) },
  { uid: Features.HISTORIC_SITE, name: intl.formatMessage(FILTER_FEATURE_TYPES_STRINGS.HISTORIC_SITE) },
  { uid: Features.HOT_SPRINGS, name: intl.formatMessage(FILTER_FEATURE_TYPES_STRINGS.HOT_SPRINGS) },
  { uid: Features.KIDS, name: intl.formatMessage(FILTER_ACCESS_STRINGS.KIDS) },
  { uid: Features.LAKE, name: intl.formatMessage(FILTER_FEATURE_TYPES_STRINGS.LAKE) },
  { uid: Features.PAVED, name: intl.formatMessage(FILTER_ACCESS_STRINGS.PAVED) },
  { uid: Features.PARTIALLY_PAVED, name: intl.formatMessage(FILTER_ACCESS_STRINGS.PARTIALLY_PAVED) },
  { uid: 'pub-crawl', name: intl.formatMessage(FILTER_FEATURE_TYPES_STRINGS.PUB_WALK) }, // not associated with other filters
  { uid: Features.RAILS_TRAILS, name: intl.formatMessage(FILTER_FEATURE_TYPES_STRINGS.RAILS_TRAILS) },
  { uid: Features.RIVER, name: intl.formatMessage(FILTER_FEATURE_TYPES_STRINGS.RIVER) },
  { uid: Features.STROLLERS, name: intl.formatMessage(FILTER_ACCESS_STRINGS.STROLLERS) },
  { uid: Features.VIEWS, name: intl.formatMessage(FILTER_FEATURE_TYPES_STRINGS.VIEWS) },
  { uid: Features.WATERFALL, name: intl.formatMessage(FILTER_FEATURE_TYPES_STRINGS.WATERFALL) },
  { uid: Features.WILD_FLOWERS, name: intl.formatMessage(FILTER_FEATURE_TYPES_STRINGS.WILD_FLOWERS) },
  { uid: Features.WILDLIFE, name: intl.formatMessage(FILTER_FEATURE_TYPES_STRINGS.WILDLIFE) }
];

export { obstacles, activities, features };
