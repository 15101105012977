import { useEffect, useState } from 'react';
import { defineMessages, useIntl } from '@alltrails/shared/react-intl';
import OutlinedTextField from '@alltrails/shared/components/OutlinedTextField';
import useErrorParsing from 'hooks/useErrorParsing';
import useFiltersContext from 'hooks/useFiltersContext';
import useUser from 'hooks/useUser';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import { modalRoadblock } from '@alltrails/shared/utils/modalFunnelUtils';
import Modal from '../../../Modal';
import * as styles from './styles/styles.module.scss';

const messages = defineMessages({
  cancel: {
    defaultMessage: 'Cancel'
  },
  errorMessage: {
    defaultMessage: 'Error saving filters'
  },
  label: {
    defaultMessage: 'Filter set name'
  },
  save: {
    defaultMessage: 'Save'
  },
  title: {
    defaultMessage: 'Save filter set'
  }
});

export type Props = {
  isOpen: boolean;
  onRequestClose: () => void;
  onSave: () => void;
};

export default function SaveFiltersModal({ isOpen, onRequestClose, onSave }: Props) {
  const intl = useIntl();
  const user = useUser();
  const languageRegionCode = useLanguageRegionCode();
  const { errorsToMessage } = useErrorParsing();

  const [value, setValue] = useState('');
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const { saveFilters } = useFiltersContext();

  const isSaveDisabled = !value?.trim();

  const onSubmit = async () => {
    if (!user) {
      const returnToUrl = window.location.pathname + window.location.search;
      modalRoadblock('signup', 'explore-saved-filters', returnToUrl, languageRegionCode);

      return;
    }

    try {
      await saveFilters(value.trim());
      onSave();
    } catch (errors) {
      setErrorMessage(errorsToMessage(errors));
    }
  };

  useEffect(() => {
    setErrorMessage(undefined);
    setValue('');
  }, [isOpen]);

  return (
    <Modal
      baseCtas={{
        primaryCta: {
          disabled: isSaveDisabled,
          text: intl.formatMessage(messages.save),
          testId: intl.formatMessage(messages.save),
          onClick: onSubmit
        },
        secondaryCta: {
          children: intl.formatMessage(messages.cancel),
          text: intl.formatMessage(messages.cancel),
          testId: intl.formatMessage(messages.cancel),
          onClick: onRequestClose
        }
      }}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      title={intl.formatMessage(messages.title)}
    >
      <div className={styles.container}>
        <OutlinedTextField
          changeHandler={(event: React.ChangeEvent<HTMLInputElement>) => {
            setErrorMessage(null);
            setValue(event.target.value);
          }}
          errorMessage={errorMessage}
          label={intl.formatMessage(messages.label)}
          name="savedFiltersName"
          value={value}
        />
      </div>
    </Modal>
  );
}
