import { Map } from 'mapbox-gl';
import { ScoredCell, getRedrawH3Heatmap } from 'api/TrailRedraw';
import { cellToBoundary } from 'h3-js';
import { Feature } from '@turf/helpers';
import logError from 'utils/logError';
import { addLayer, removeLayer } from '../layers/layer_helpers';
import { removeSource } from '../sources/source_helpers';

const getGradientExpression = (rgbRange: number[][], scoreRange: number[]) => {
  const minScore = scoreRange[0];
  const maxScore = scoreRange[1];
  const [r1, g1, b1] = rgbRange[0];
  const [r2, g2, b2] = rgbRange[1];
  const score = ['get', 'score'];
  // position of score in range
  const numerator = ['-', score, minScore];
  const denominator = maxScore - minScore;
  const position = ['/', numerator, denominator];
  // deltas for rgb values
  const dr = ['-', r2, r1];
  const dg = ['-', g2, g1];
  const db = ['-', b2, b1];
  // new rgb values
  const r = ['+', ['*', dr, position], r1];
  const g = ['+', ['*', dg, position], g1];
  const b = ['+', ['*', db, position], b1];
  return ['rgb', r, g, b];
};

const beforeLayer = (map: Map) => {
  if (map.getLayer('slid-route')) {
    return 'slid-route';
  }
  if (map.getLayer('low-traffic-vr')) {
    return 'low-traffic-vr';
  }
  return 'atMap-polylines';
};

const renderFeatureLayer = (map: Map, cells: ScoredCell[]) => {
  const features: Feature[] = cells
    .filter(cell => cell.score >= 0.2)
    .map(cell => ({
      type: 'Feature',
      properties: { score: cell.score },
      geometry: { type: 'Polygon', coordinates: [cellToBoundary(cell.h3_id, true)] }
    }));
  addLayer(
    map,
    {
      id: 'h3-heatmap',
      type: 'fill',
      source: { type: 'geojson', data: { type: 'FeatureCollection', features } },
      paint: {
        'fill-color': [
          'case',
          ['<', ['get', 'score'], 0.4],
          getGradientExpression(
            [
              [29, 154, 66],
              [101, 188, 118]
            ],
            [0.2, 0.4]
          ),
          ['<', ['get', 'score'], 0.6],
          getGradientExpression(
            [
              [138, 188, 87],
              [249, 220, 124]
            ],
            [0.4, 0.6]
          ),
          ['<', ['get', 'score'], 0.8],
          getGradientExpression(
            [
              [236, 179, 70],
              [235, 143, 79]
            ],
            [0.6, 0.8]
          ),
          getGradientExpression(
            [
              [222, 92, 68],
              [184, 65, 45]
            ],
            [0.8, 1]
          )
        ],
        'fill-opacity': 0.8
      }
    },
    null,
    beforeLayer(map)
  );
};

const addH3Heatmap = async (map: Map, { trailId }: { trailId: number }) => {
  try {
    const cells = await getRedrawH3Heatmap(trailId);
    if (cells.length) {
      renderFeatureLayer(map, cells);
    }
  } catch (error) {
    logError(error);
  }
};

const removeH3Heatmap = (map: Map) => {
  removeLayer(map, 'h3-heatmap');
  removeSource(map, 'h3-heatmap');
};

export { addH3Heatmap, removeH3Heatmap };
