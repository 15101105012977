import { get, post } from '@alltrails/shared/api';
import Alert, { AlertId, AlertModel, AlgoliaAlert, ObjectType } from '@alltrails/shared/types/alert';

// TODO: branded types
type AreaId = number;
type TrailId = number;

type TrailsAlertCreateRequestPayload = {
  area_id?: AreaId;
  trail_ids?: TrailId[];
  alert: Alert;
};

type UpdateRequestPayload = {
  objectId: TrailId | AreaId;
  trailIds?: TrailId[];
};
type TrailsAlertUpdateRequestPayload = UpdateRequestPayload & {
  alert: Alert;
};
type TrailsAlertsUpdateRequestPayload = UpdateRequestPayload & {
  alerts: Alert[];
};

type TrailsAlertDeleteRequestPayload = {
  objectId: TrailId | AreaId;
  alert: AlgoliaAlert;
  trailIds: TrailId[];
};

type TrailsAlertResponse = Alert & {
  trailIds: TrailId[];
};

const createAlertRequest = async (alertPayload: TrailsAlertCreateRequestPayload) => {
  const response = await post<TrailsAlertResponse[]>('/api/alltrails/trails/alerts', alertPayload);
  return response;
};

const updateAlertRequest = async (alertPayload: TrailsAlertUpdateRequestPayload) => {
  const isAreaAlert = alertPayload.alert.type === AlertModel.AreaAlert;
  const payload = isAreaAlert
    ? {
        id: alertPayload.objectId,
        type: ObjectType.Area,
        alert: alertPayload.alert,
        trail_ids: alertPayload.trailIds
      }
    : {
        id: alertPayload.objectId,
        type: ObjectType.Trail,
        alerts: [alertPayload.alert],
        only_changed: true
      };

  if (isAreaAlert) {
    const response = await post<TrailsAlertResponse>('/api/alltrails/admin/alerts', payload);
    return response;
  }

  const response = await post<Alert[]>('/api/alltrails/admin/alerts', payload);
  const alert = response?.[0];
  return { ...alert, trailIds: [alert?.objectId] };
};

const updateTrailAlertsRequest = async (alertsPayload: TrailsAlertsUpdateRequestPayload) => {
  const payload = {
    id: alertsPayload.objectId,
    type: ObjectType.Trail,
    alerts: alertsPayload.alerts
  };

  return post<Alert[]>('/api/alltrails/admin/alerts', payload);
};

const deleteAlertRequest = async (alertPayload: TrailsAlertDeleteRequestPayload) => {
  const isAreaAlert = alertPayload.alert.object_type === ObjectType.Area;
  const payload = isAreaAlert
    ? {
        id: alertPayload.objectId,
        type: alertPayload.alert.object_type,
        alert: {
          id: alertPayload.alert.ID,
          deleted: true
        }
      }
    : {
        id: alertPayload.objectId,
        type: alertPayload.alert.object_type,
        alerts: [
          {
            id: alertPayload.alert.ID,
            deleted: true
          }
        ],
        only_changed: true
      };

  if (isAreaAlert) {
    const response = await post<TrailsAlertResponse>('/api/alltrails/admin/alerts', payload);
    return response;
  }

  const response = await post<Alert[]>('/api/alltrails/admin/alerts', payload);
  const alert = response?.[0];
  return { ...alert, trailIds: [alert?.objectId] };
};

const getAlertRequest = async (id: AlertId) => {
  const response = await get<Alert[]>(`/api/alltrails/alerts/${id}`);

  return response;
};

export {
  TrailsAlertCreateRequestPayload,
  TrailsAlertUpdateRequestPayload,
  TrailsAlertDeleteRequestPayload,
  TrailsAlertResponse,
  createAlertRequest,
  updateAlertRequest,
  updateTrailAlertsRequest,
  deleteAlertRequest,
  getAlertRequest
};
