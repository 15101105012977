import Button from '@alltrails/shared/denali/components/Button';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import LanguageRegionCode from '@alltrails/shared/types/LanguageRegionCode';
import logInvitedUserDownloadAppCtaClicked from '@alltrails/analytics/events/logInvitedUserDownloadAppCtaClicked';
import { useIntl } from '@alltrails/shared/react-intl';

export default function DownloadAppButton() {
  const languageRegionCode = useLanguageRegionCode();
  const intl = useIntl();

  function getHref() {
    // this seems easier and less complicated than trying to send back something from the browser gem
    if (/Android/i.test(navigator.userAgent)) {
      switch (languageRegionCode) {
        case LanguageRegionCode.German:
          return 'https://alltrails.io/oEnYF1cMmgb';
        case LanguageRegionCode.French:
          return 'https://alltrails.io/iZe9jjaMmgb';
        case LanguageRegionCode.English:
          return 'https://alltrails.io/pW7o6i6Lmgb';
        case LanguageRegionCode.Spanish:
          return 'https://alltrails.io/HIX8CIfMmgb';
        default:
          throw new Error(`No download url for ${languageRegionCode}`);
      }
    }

    if (/(iPhone|iPad|iPod)/i.test(navigator.userAgent)) {
      switch (languageRegionCode) {
        case LanguageRegionCode.German:
          return 'https://alltrails.io/qytSKQVLmgb';
        case LanguageRegionCode.French:
          return 'https://alltrails.io/DKsdICPLmgb';
        case LanguageRegionCode.English:
          return 'https://alltrails.io/ujRkr9MGmgb';
        case LanguageRegionCode.Spanish:
          return 'https://alltrails.io/ig3zPxYLmgb';
        default:
          throw new Error(`No download url for ${languageRegionCode}`);
      }
    }

    // fallback to iphone english?
    return 'https://alltrails.io/ujRkr9MGmgb';
  }

  return (
    <Button
      text={intl.formatMessage({ defaultMessage: 'Download the free app' })}
      linkInfo={{ href: getHref() }}
      onClick={logInvitedUserDownloadAppCtaClicked}
      testId="download-free-app"
      variant="primary"
    />
  );
}
