import React, { useReducer, useEffect, useRef } from 'react';
import Waypoint from 'types/Waypoint';

export type WaypointsState = {
  waypoints: Waypoint[];
  editIndex?: number;
  editParams?: unknown;
};

const newWaypointParams: Waypoint = {
  id: null,
  at_map_id: null,
  name: '',
  description: '',
  location: {
    longitude: null,
    latitude: null
  },
  waypointDisplayProperty: {
    showTitle: true
  },
  order: null,
  enable_translations: false
};

const organizeInitialWaypoints = (waypoints: Waypoint[]) => waypoints.filter(w => w.location !== null).sort((a, b) => a.order - b.order);

// actions
export type WaypointsAction =
  | { type: 'ADD_NEW_WAYPOINT_FORM' }
  | { type: 'UPDATE_EDIT_INDEX'; payload: number }
  | { type: 'CANCEL_WAYPOINT_FORM' }
  | { type: 'REMOVE_WAYPOINT'; payload: number /* waypoint ID */ }
  | { type: 'TOGGLE_SORT_MODE'; payload: boolean /* sortMode */ }
  | { type: 'ADD_PERSISTED_WAYPOINT'; payload: Waypoint }
  | { type: 'REORDER_WAYPOINTS'; payload: Waypoint[] }
  | { type: 'DELETE_ALL_WAYPOINTS' }
  | { type: 'TOGGLE_SHOW_ALL_TITLES'; payload: boolean /* showAllTitles */ }
  | { type: 'TOGGLE_SHOW_TRANSLATED_LANG'; payload: { idx: number; showInTranslatedLang: boolean } }
  | { type: 'UPDATE_FOR_INCOMING_WAYPOINTS'; payload: Waypoint[] }
  | { type: 'UPDATE_WAYPOINT_EDIT_PARAMS'; payload: unknown };

// reducer
const waypointsReducer = (state: WaypointsState, action: WaypointsAction) => {
  switch (action.type) {
    case 'ADD_NEW_WAYPOINT_FORM':
      return {
        ...state,
        waypoints: [...state.waypoints, newWaypointParams],
        editIndex: state.waypoints.length
      };
    case 'UPDATE_EDIT_INDEX':
      return {
        ...state,
        editIndex: action.payload
      };
    case 'CANCEL_WAYPOINT_FORM':
      return {
        ...state,
        editIndex: null,
        editParams: null,
        waypoints: state.waypoints.filter(w => w.id !== null)
      };
    case 'REMOVE_WAYPOINT':
      return {
        ...state,
        waypoints: state.waypoints.filter(wp => wp.id !== action.payload)
      };
    case 'TOGGLE_SORT_MODE':
      return {
        ...state,
        sortMode: action.payload,
        editIndex: null as number
      };
    case 'ADD_PERSISTED_WAYPOINT':
      return {
        ...state,
        editIndex: null,
        editParams: null,
        waypoints: state.waypoints.map((wp, idx) => {
          if (idx === state.editIndex) {
            return { ...action.payload };
          }

          return wp;
        })
      };
    case 'REORDER_WAYPOINTS':
      return { ...state, waypoints: action.payload };
    case 'DELETE_ALL_WAYPOINTS':
      return { ...state, waypoints: [] };
    case 'TOGGLE_SHOW_ALL_TITLES':
      return {
        ...state,
        waypoints: state.waypoints.map(wp => ({ ...wp, waypointDisplayProperty: { showTitle: action.payload } }))
      };
    case 'TOGGLE_SHOW_TRANSLATED_LANG':
      return {
        ...state,
        waypoints: state.waypoints.map((wp, idx) => {
          if (idx === action.payload.idx) {
            return { ...wp, showInTranslatedLang: action.payload.showInTranslatedLang };
          }

          return wp;
        })
      };
    case 'UPDATE_FOR_INCOMING_WAYPOINTS':
      return {
        ...state,
        editIndex: null,
        waypoints: [...organizeInitialWaypoints(action.payload)]
      };
    case 'UPDATE_WAYPOINT_EDIT_PARAMS': {
      return {
        ...state,
        editParams: action.payload
      };
    }

    default:
      return state;
  }
};

export const useWaypoints = ({
  initialWaypoints = [],
  messagingChannel = null,
  canEdit = false
}): [WaypointsState, React.Dispatch<WaypointsAction>] => {
  const initialState = {
    waypoints: organizeInitialWaypoints(initialWaypoints),
    newWaypointParams,
    editIndex: null as number,
    sortMode: false,
    editParams: null as {
      id: number;
      description: string;
      location: {
        longitude: number;
        latitude: number;
      };
      name: string;
      showTitle: boolean;
    }
  };

  const [state, dispatch] = useReducer(waypointsReducer, { ...initialState });
  const prevStateRef = useRef(state);

  useEffect(() => {
    if (!messagingChannel || !canEdit) {
      return;
    }

    const { editIndex, waypoints } = state;
    const activeWaypoint = waypoints[editIndex];
    const editWaypointId = typeof activeWaypoint === 'undefined' ? 0 : activeWaypoint.id;

    // calls waypointsChanged in search_app to keep waypoints consistent everywhere
    messagingChannel.publish({
      topic: 'waypointsChanged',
      data: { waypoints, editWaypointId, activeWaypoint }
    });
  }, [state.editIndex, state.waypoints]);

  useEffect(() => {
    prevStateRef.current = state;
  }, [state]);

  return [state, dispatch];
};
