import type { TrailId } from '@alltrails/shared/types/trail';
import Waypoint from 'types/Waypoint';
import WaypointsSection from '../WaypointsSection';
import { useWaypoints } from '../../../hooks/useWaypoints';

type WaypointsWrapperProps = {
  canEdit: boolean;
  className?: string;
  messagingChannel?: unknown;
  mapId: number;
  trailId?: TrailId;
  waypoints: Waypoint[];
  refetchTrailProps?: () => void;
  isLoading?: boolean;
};

const WaypointsWrapper = ({
  waypoints = [],
  canEdit,
  messagingChannel,
  mapId,
  trailId,
  className,
  refetchTrailProps,
  isLoading
}: WaypointsWrapperProps) => {
  const [waypointsState, dispatchWaypoints] = useWaypoints({ initialWaypoints: waypoints, messagingChannel, canEdit });

  return (
    <div className={className}>
      <WaypointsSection
        {...waypointsState}
        dispatchWaypoints={dispatchWaypoints}
        messagingChannel={messagingChannel}
        mapId={mapId}
        canEdit={canEdit}
        trailId={trailId}
        refetchTrailProps={refetchTrailProps}
        isLoading={isLoading}
      />
    </div>
  );
};

export default WaypointsWrapper;
