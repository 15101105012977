import { defineMessages } from '@alltrails/shared/react-intl';
import Print from '@alltrails/shared/icons/Print';
import { COLOR_INTERACTIVE_DEFAULT } from '@alltrails/shared/denali/tokens';
// eslint-disable-next-line import/no-unresolved
import { TrailMoreAlternateMenuItem } from 'react_components/main_action_bar/trail_more_alternate_menu_item';
import useUser from 'hooks/useUser';
import LanguageRegionCode from '@alltrails/shared/types/LanguageRegionCode';
import type { Context } from 'types/Context';
import type { User } from 'types/User';
import Trail from 'types/Trails/Trail';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import useFormatMessage from '@alltrails/shared/hooks/useFormatMessage';
import ShareObject from 'types/ShareObject';
import { NoSsr } from '@material-ui/core';
import logTrailDetailsPrintClicked from '@alltrails/analytics/events/logTrailDetailsPrintClicked';
import { GarminProvider } from 'components/GarminSettings/GarminProvider';
import type { GarminCourse } from '@alltrails/shared/types/trail';
import * as styles from './styles/styles.module.scss';
import ActionBarItem from '../ActionBarItem';
import PhotosMenuItem from '../TrailDetails/PhotosMenuItem';
import DirectionsButton from '../DirectionsButton';
import SharePopupButton from '../SharePopupButton/SharePopupButton';
import { wrapUrlSafe } from '../../utils/language_support_util';

const TRAIL_ACTION_STRINGS = defineMessages({
  PRINT_PDF_MAP: { defaultMessage: 'Print/PDF map' }
});

function getPrintUrl(languageRegionCode: LanguageRegionCode, user: User, trail: Trail) {
  const url = wrapUrlSafe(`/${trail.slug}/print`, languageRegionCode);

  if (user?.pro) {
    return url;
  }

  const params = new URLSearchParams();

  params.set('returnTo', url);

  return wrapUrlSafe(`/plus?${params.toString()}`, languageRegionCode);
}

type Props = {
  context: Context;
  exploreFlag?: boolean;
  pending?: boolean;
  photos?: unknown;
  numPhotos?: number;
  shareObject?: ShareObject;
  trail?: Trail;
  garminCourse?: GarminCourse;
};

const TrailActionBar = ({ context, photos, numPhotos, trail, pending, exploreFlag, shareObject, garminCourse }: Props) => {
  const {
    formattedDefaultMessages: { PRINT_PDF_MAP },
    intl
  } = useFormatMessage(TRAIL_ACTION_STRINGS);

  const languageRegionCode = useLanguageRegionCode();
  const user = useUser();

  const printUrl = getPrintUrl(languageRegionCode, user, trail);
  return (
    <div className={styles.mainActionBar}>
      <ul className={styles.list}>
        <PhotosMenuItem photos={photos} count={numPhotos} trailId={trail.trail_id} slug={trail.slug} context={context} />
        <DirectionsButton disabled={!trail._geoloc || !trail._geoloc.lat || !trail._geoloc.lng} geoloc={trail._geoloc} trailId={trail.trail_id} />
        {(!context.mobileBrowser || !exploreFlag) && (
          <ActionBarItem
            icon={<Print color={COLOR_INTERACTIVE_DEFAULT} />}
            disabled={pending}
            title={PRINT_PDF_MAP}
            className={styles.mobileHide}
            testId="print-action"
            onClick={() => {
              logTrailDetailsPrintClicked({ trail_id: trail.trail_id });
              window.location.assign(printUrl);
            }}
            showPlus
          />
        )}
        <SharePopupButton className={styles.mobileHide} shareObject={shareObject} trail={trail} />
        <NoSsr>
          <GarminProvider user={user} mapId={trail.verified_map_id} garminCourse={garminCourse} trailId={trail.trail_id}>
            <TrailMoreAlternateMenuItem
              mobileBrowser={context.mobileBrowser}
              trail={trail}
              pending={pending}
              printUrl={printUrl}
              exploreFlag={exploreFlag}
              intl={intl}
            />
          </GarminProvider>
        </NoSsr>
      </ul>
    </div>
  );
};

TrailActionBar.defaultProps = {
  exploreFlag: false,
  pending: false,
  shareObject: {},
  photos: [],
  numPhotos: null
};

export default TrailActionBar;
