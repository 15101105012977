import { serverAjaxPromise } from './request_helpers';

const reorderList = (listId, orderedListItemIds) => {
  if (!orderedListItemIds || orderedListItemIds.length < 1) {
    return Promise.resolve([]);
  }
  return serverAjaxPromise({
    type: 'PUT',
    url: `/api/alltrails/v2/lists/${listId}/reorder`,
    data: {
      ordered_list_item_ids: orderedListItemIds
    },
    resReducer: data => data.listItems
  });
};

const copyList = listId =>
  serverAjaxPromise({
    type: 'POST',
    url: `/api/alltrails/lists/${listId}/copy`,
    resReducer: data => data.lists[0]
  });

export { reorderList, copyList };
