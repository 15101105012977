import { destroy, post, put } from '@alltrails/shared/api';
import List from 'types/List';

export async function saveList(ids: (string | number)[]) {
  return post('/api/alltrails/lists/reorder', { ordered_list_ids: ids });
}

type DeleteListsResponse = {
  lists: List[];
};

export async function deleteList(id: string | number) {
  return destroy<DeleteListsResponse>(`/api/alltrails/lists/${id}`);
}

type ReorderResponse = {
  listItems: any;
  profile_photo_url: string;
  success: boolean;
};

export async function saveListItems(listId: string | number, itemIds: string | number[], range?: number[]) {
  return put<ReorderResponse>(`/api/alltrails/v2/lists/${listId}/reorder`, { ordered_list_item_ids: itemIds, item_range: range });
}
