import { FormattedMessage } from '@alltrails/shared/react-intl';
import * as styles from './ProStatus.module.scss';

type Props = {
  renewalDateString?: string;
  inTrial: boolean;
};

function RenewalDateLabel({ renewalDateString, inTrial }: Props) {
  if (!renewalDateString) {
    return <FormattedMessage defaultMessage="Unknown" />;
  }

  const renewalDate = new Date(renewalDateString);

  if (inTrial) {
    return <FormattedMessage defaultMessage="Your trial will convert to an annual plan on {renewalDate, date, long}" values={{ renewalDate }} />;
  }

  return <FormattedMessage defaultMessage="Your subscription will renew on {renewalDate, date, long}" values={{ renewalDate }} />;
}

function RenewalDate({ renewalDateString, inTrial }: Props) {
  return (
    <div className={styles.settingsPro}>
      <span className={styles.subtitle}>
        <FormattedMessage defaultMessage="Renewal date:" />{' '}
      </span>
      <RenewalDateLabel renewalDateString={renewalDateString} inTrial={inTrial} />
    </div>
  );
}

export default RenewalDate;
