import { additionalStyleCardConfigs } from '@alltrails/maps/utils/cardConfigs';
import { atMapsToGeojson } from '@alltrails/maps/utils/legacyGeoJSONConversions';
import getStyle from '@alltrails/maps/utils/getStyle';
import createRasterStyle from '@alltrails/maps/utils/raster';
import { addDistanceMarkers, removeDistanceMarkers } from './overlays/distance_markers';
import { addCommunityPhotos, removePhotos } from './overlays/photos';
import { addCommunityWaypoints, removeWaypoints } from './overlays/waypoints';
import { addPendingTrails, removePendingTrails } from './overlays/pending_trails';
import { addNearbyTrails, removeNearbyTrails } from './overlays/nearby_trails';
import { addMapsOrRecordings, removeMapsOrRecordings } from './overlays/maps_or_recordings';
import { removeHeatmapOverlay, addHeatmapOverlay } from './overlays/heatmap';
import { addLowTrafficVR, removeLowTrafficVR } from './overlays/low_traffic_vr';
import { addH3Heatmap, removeH3Heatmap } from './overlays/h3_heatmap';
import { addContribs, removeContribs } from './overlays/contribs';
import { GREEN, RED } from '../constants/PinColor';
import { sanityCheckSourceUrl } from './admin_customization_helpers';
import { addSource, removeSource } from './sources/source_helpers';
import { addLayer, removeLayer } from './layers/layer_helpers';
import * as colors from '../../stylesheets/colors.scss';

const mapboxAccessToken = 'pk.eyJ1IjoiYWxsdHJhaWxzIiwiYSI6ImNqM293emo1YjAwZWQyd3FnaXh0eWsxeHkifQ.LeDD0X-JiWsJmDKeB0AS5w'; // gitleaks:allow

const addRasterOverlay = (map, id, style) => {
  const source = style.sources[id];
  const layer = style.layers[0];

  addSource(map, id, source);
  addLayer(map, layer);
};

const removeRasterOverlay = (map, id) => {
  removeLayer(map, id);
  removeSource(map, id);
};

// Refreshes all enabled heatmap overlays that can be filtered by 'after' or 'limit' params
const refreshFilterableOverlays = (map, configParams) => {
  if (configParams) {
    // eslint-disable-next-line no-use-before-define
    refreshOverlays(map, configParams, 'filterable');
  }
};

const additionalStyleLoaders = {
  lowTrafficVR: {
    add: addLowTrafficVR,
    remove: removeLowTrafficVR
  },
  h3HeatmapCells: {
    add: addH3Heatmap,
    remove: removeH3Heatmap
  },
  after: {
    add: refreshFilterableOverlays,
    remove: refreshFilterableOverlays
  },
  airQuality: {
    add: map => addRasterOverlay(map, 'airQuality', getStyle({ styleId: 'airQuality', mapboxAccessToken })),
    remove: map => removeRasterOverlay(map, 'airQuality')
  },
  clusterTrails: {
    add: (map, { trailId, renderHoverCard, enabledOverlays }) =>
      addPendingTrails(map, trailId, renderHoverCard, 'clusterTrails', RED, enabledOverlays),
    remove: map => removePendingTrails(map, 'clusterTrails')
  },
  contribs: {
    add: (map, { trailId }) => addContribs(map, trailId),
    remove: map => removeContribs(map)
  },
  distanceMarkers: {
    add: (map, { exploreMap, plannerMap }) => addDistanceMarkers(map, atMapsToGeojson([plannerMap || exploreMap])),
    remove: map => removeDistanceMarkers(map)
  },
  heatmap: {
    add: addHeatmapOverlay,
    remove: map => removeHeatmapOverlay(map, 'default')
  },
  lightPollution: {
    add: map => addRasterOverlay(map, 'lightPollution', getStyle({ styleId: 'lightPollution', mapboxAccessToken })),
    remove: map => removeRasterOverlay(map, 'lightPollution')
  },
  limit: {
    add: refreshFilterableOverlays,
    remove: refreshFilterableOverlays
  },
  mapsAll: {
    add: (map, { renderHoverCard, enabledOverlays }) => addMapsOrRecordings(map, renderHoverCard, 'mapsAll', enabledOverlays),
    remove: map => removeMapsOrRecordings(map, 'mapsAll')
  },
  nearbyTrails: {
    add: (map, { trailId, renderHoverCard, enabledOverlays, adminUser }) =>
      addNearbyTrails(map, trailId, renderHoverCard, enabledOverlays, adminUser),
    remove: map => removeNearbyTrails(map)
  },
  pendingTrails: {
    add: (map, { trailId, renderHoverCard, enabledOverlays }) =>
      addPendingTrails(map, trailId, renderHoverCard, 'pendingTrails', GREEN, enabledOverlays),
    remove: map => removePendingTrails(map, 'pendingTrails')
  },
  photos: {
    add: (map, { exploreMap, profilePhoto, handlePhotoClick, adminUser }) =>
      addCommunityPhotos(map, 'community-photos', exploreMap, profilePhoto, handlePhotoClick, adminUser),
    remove: map => removePhotos(map, 'community-photos')
  },
  pollen: {
    add: map => addRasterOverlay(map, 'pollen', getStyle({ styleId: 'pollen', mapboxAccessToken })),
    remove: map => removeRasterOverlay(map, 'pollen')
  },
  recordingsAll: {
    add: (map, { renderHoverCard, enabledOverlays, selectedFilterActivity, resultsDataOverrides }) =>
      addMapsOrRecordings(map, renderHoverCard, 'recordingsAll', enabledOverlays, selectedFilterActivity, resultsDataOverrides, {
        'line-color': colors.blue
      }),
    remove: map => removeMapsOrRecordings(map, 'recordingsAll')
  },
  recordingsPop: {
    add: (map, { renderHoverCard, enabledOverlays, selectedFilterActivity, resultsDataOverrides }) =>
      addMapsOrRecordings(map, renderHoverCard, 'recordingsPop', enabledOverlays, selectedFilterActivity, resultsDataOverrides, {
        'line-color': colors.blue
      }),
    remove: map => removeMapsOrRecordings(map, 'recordingsPop')
  },
  adminOverlay: {
    add: (map, { adminCustomizationSettings }) => {
      if (!sanityCheckSourceUrl(adminCustomizationSettings.overlaySourceUrl)) {
        return;
      }
      addRasterOverlay(
        map,
        'admin-overlay',
        createRasterStyle(
          'admin-overlay',
          adminCustomizationSettings.overlaySourceUrl,
          { tileSize: 256 },
          {
            paint: {
              'raster-opacity': 0.5
            }
          }
        )
      );
    },
    remove: map => removeRasterOverlay(map, 'admin-overlay')
  },
  waymarkedCycling: {
    add: map => addRasterOverlay(map, 'waymarkedCycling', getStyle({ styleId: 'waymarkedCycling', mapboxAccessToken })),
    remove: map => removeRasterOverlay(map, 'waymarkedCycling')
  },
  waymarkedHiking: {
    add: map => addRasterOverlay(map, 'waymarkedHiking', getStyle({ styleId: 'waymarkedHiking', mapboxAccessToken })),
    remove: map => removeRasterOverlay(map, 'waymarkedHiking')
  },
  waymarkedMTB: {
    add: map => addRasterOverlay(map, 'waymarkedMTB', getStyle({ styleId: 'waymarkedMTB', mapboxAccessToken })),
    remove: map => removeRasterOverlay(map, 'waymarkedMTB')
  },
  waypoints: {
    add: (map, { exploreMap, renderWaypointPopup }) => addCommunityWaypoints(map, 'community-waypoints', exploreMap, renderWaypointPopup),
    remove: map => removeWaypoints(map, 'community-waypoints')
  },
  weather: {
    add: map => addRasterOverlay(map, 'weather', getStyle({ styleId: 'weather', mapboxAccessToken })),
    remove: map => removeRasterOverlay(map, 'weather')
  }
};

// Init all admin heatmap overlay configs
// These heatmaps (unlike the normal heatmap overlay) are all based off of non-heatmap overlays
Object.entries(additionalStyleCardConfigs).forEach(([key, { hasHeatmap }]) => {
  if (!hasHeatmap) {
    return;
  }

  // Create an artificial heatmap loader that mostly just proxies to the original
  additionalStyleLoaders[`${key}Heatmap`] = {
    // The same exact style loader handles both the normal state and when it
    // detects that it has a heatmap enabled
    add: additionalStyleLoaders[key].add,
    // Remove the heatmap only
    remove: map => removeHeatmapOverlay(map, key)
  };
});

// Refreshes all enabled overlays with a specific key
const refreshOverlays = (map, configParams, matchingKey) => {
  const { enabledOverlays } = configParams;
  enabledOverlays.forEach(key => {
    const styleConfig = additionalStyleCardConfigs[key];
    const styleLoader = additionalStyleLoaders[key];

    if (!styleConfig[matchingKey]) {
      return;
    }

    styleLoader.add(map, configParams);
  });
};

// Refreshes all enabled overlays that should be refreshed on panning the map
const refreshOverlaysOnPan = (map, configParams) => {
  refreshOverlays(map, configParams, 'refreshOnPan');
};

export { additionalStyleLoaders, refreshOverlaysOnPan };
