import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import logTrailDetailsConditionsViewed from '@alltrails/analytics/events/logTrailDetailsConditionsViewed';
import TabBar from '@alltrails/shared/denali/components/TabBar';
import useIsVisible from '@alltrails/shared/hooks/useIsVisible';
import getTrailConditions from 'api/TrailConditions';
import Trail from 'types/Trails/Trail';
import { TrailCondition } from 'types/Trails/TrailCondition';
import logError from 'utils/logError';
import * as styles from './styles/styles.module.scss';
import TrailConditionsBase from './TrailConditionsBase';
import TrailConditionsError from './TrailConditionsError';
import TrailConditionsShimmer from './TrailConditionsShimmer';

export type TrailConditionsProps = {
  trail: Trail;
  trailConditions: TrailCondition[];
  exploreFlag?: boolean;
};

/* NOTE: no translations needed for now, EN/US only feature */
const TrailConditions = ({ trail, trailConditions, exploreFlag }: TrailConditionsProps): JSX.Element => {
  const ref = useRef<HTMLDivElement>();
  const isVisible = useIsVisible(ref, { threshold: 1 });
  const [conditions, setConditions] = useState<TrailCondition[]>(trailConditions);
  const [isLoading, setIsLoading] = useState(false);
  const [displayError, setDisplayError] = useState(false);

  useEffect(() => {
    if (isVisible) {
      logTrailDetailsConditionsViewed({ trail_id: trail.trail_id });
    }
  }, [isVisible, trail.trail_id]);

  const onRetry = useCallback(async () => {
    setIsLoading(true);
    setDisplayError(false);

    try {
      const data = await getTrailConditions(trail.trail_id);
      setConditions(data.trailConditions);
    } catch (e) {
      logError(e);
      setDisplayError(true);
    } finally {
      setIsLoading(false);
    }
  }, [trail.trail_id]);

  return (
    <>
      <TabBar
        activeTab="Conditions"
        className={styles.tabBar}
        onChange={() => {
          // One tab just for display, no onChange needed
        }}
        tabs={[{ id: 'Conditions', label: 'Conditions', testId: 'trail-conditions-tab-conditions' }]}
        testId="trail-conditions-tab-bar"
      />
      <div className={styles.container} ref={ref}>
        {isLoading && <TrailConditionsShimmer />}
        {displayError && <TrailConditionsError onRetry={onRetry} />}
        {!isLoading && !displayError && (
          <ErrorBoundary fallback={<TrailConditionsError onRetry={onRetry} />}>
            <TrailConditionsBase trail={trail} trailConditions={conditions} exploreFlag={exploreFlag} />
          </ErrorBoundary>
        )}
      </div>
    </>
  );
};

export default memo(TrailConditions);
