import { serverAjaxPromise } from './request_helpers';

const getTrailsPhotos = trailId => {
  return serverAjaxPromise({
    url: `/api/alltrails/v2/trails/${trailId}/photos`,
    data: {
      with_location: 'Y'
    },
    resReducer: data => data.photos
  });
};

const getTrailsWaypoints = trailId => {
  return serverAjaxPromise({
    url: `/api/alltrails/v2/trails/${trailId}/waypoints`,
    resReducer: data => data.waypoints
  });
};

const getTrailContribVRs = trailId => {
  return serverAjaxPromise({
    url: `/api/alltrails/edits/trails/admin/contrib-vrs/`,
    data: { trail_id: trailId },
    resReducer: data => data.maps
  });
};

const getNearbyTrails = (trailId, page, perPage) => {
  return serverAjaxPromise({
    url: `/api/alltrails/trails/${trailId}/nearby_trails`,
    data: { page, per_page: perPage },
    resReducer: data => data.trails
  });
};

const mergeTrails = (mergeIntoTrail, mergeTrail) => {
  return serverAjaxPromise({
    url: `/api/alltrails/trails/${mergeIntoTrail.ID}/merge`,
    data: { merge_trail_id: mergeTrail.ID }
  });
};

export { getTrailsPhotos, getTrailsWaypoints, getTrailContribVRs, mergeTrails, getNearbyTrails };
