import {
  FilterViewModel,
  FilterInput,
  FilterConfigStarRating,
  FilterConfig,
  FilterConfigToggle,
  FilterConfigRange,
  FilterConfigSlider
} from 'types/Search';

export type State = {
  // Maintaining this array is an optimization for checking if updates have occurred.
  saved: FilterViewModel[];
  savedByKey: { [key: string]: FilterViewModel };
  changes: { [key: string]: FilterViewModel };
};

type Action =
  | {
      type: 'CHANGE_ONE';
      payload: { key: string; changes: FilterConfig };
    }
  | {
      type: 'CHANGE_MANY';
      payload: FilterViewModel[];
    }
  | {
      type: 'CLEAR_CHANGES';
    }
  | {
      type: 'UPDATE_ALL';
      payload: FilterViewModel[];
    };

const generateFilterChangeset = (filter: FilterViewModel, configUpdates: FilterConfig): FilterViewModel => {
  switch (filter.type) {
    case FilterInput.CHECKBOX:
    case FilterInput.RADIO:
      return {
        ...filter,
        config: {
          ...filter.config,
          ...(configUpdates as FilterConfigToggle)
        }
      };
    case FilterInput.RANGE:
      return {
        ...filter,
        config: {
          ...filter.config,
          ...(configUpdates as FilterConfigRange)
        }
      };
    case FilterInput.SLIDER:
      return {
        ...filter,
        config: {
          ...filter.config,
          ...(configUpdates as FilterConfigSlider)
        }
      };
    case FilterInput.STAR_RATING:
      return {
        ...filter,
        config: configUpdates as FilterConfigStarRating
      };
    default:
      return filter;
  }
};

export default function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'CLEAR_CHANGES':
      return {
        ...state,
        changes: {}
      };
    case 'UPDATE_ALL':
      // Filter objects are updated upstream on change and strict equality comparison
      // will always show these as a change. Add some logic here to prevent side-effects
      // and re-renders for anyone using the reducer.
      if (JSON.stringify(action.payload) === JSON.stringify(state.saved)) {
        return state;
      }

      return {
        saved: action.payload,
        savedByKey: action.payload.reduce(
          (memo, filter) => ({
            ...memo,
            [filter.key]: filter
          }),
          {}
        ),
        changes: {}
      };
    case 'CHANGE_MANY':
      return {
        ...state,
        changes: {
          ...state.changes,
          ...action.payload.reduce(
            (memo, filter) => ({
              ...memo,
              [filter.key]: generateFilterChangeset(state.savedByKey[filter.key], filter.config)
            }),
            {}
          )
        }
      };
    case 'CHANGE_ONE':
      return {
        ...state,
        changes: {
          ...state.changes,
          [action.payload.key]: generateFilterChangeset(state.savedByKey[action.payload.key], action.payload.changes)
        }
      };
    default:
      return state;
  }
}
