import { useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from '@alltrails/shared/react-intl';
import Remove from '@alltrails/shared/icons/Remove';
import SearchFilter from '@alltrails/shared/components/SearchFilter';
import useModalPortalElement from '@alltrails/shared/hooks/useModalPortalElement';
import useFiltersContext from 'hooks/useFiltersContext';
import type { Context } from 'types/Context';
import { deserializeSavedFilterSet } from 'utils/filters';
import RadioGroupWithIcon, { RadioGroupWithIconProps } from '../RadioGroupWithIcon';
import getSaveButtonChildrenProps from '../saveButtonChildrenProps';
import * as styles from './styles/styles.module.scss';

export type Props = {
  context: Context;
  popperClassName?: string;
};

export default function SavedFilters({ context, popperClassName }: Props) {
  const intl = useIntl();
  const modalElement = useModalPortalElement();

  const {
    activeFilterSetName,
    applySelectedFilterSet,
    blankFiltersFactory,
    clearActiveFilterSet,
    clearAllFilters,
    clearDraftChanges,
    computedResultsCount,
    deleteSavedFilterSet,
    isLoadingHypotheticalResultsCount,
    page,
    resetHypotheticalResultsCount,
    saveChanges,
    savedFilterSets,
    updateFilters
  } = useFiltersContext();

  const [selectedFilterSetIndex, setSelectedFilterSetIndex] = useState<number | null>(null);
  const [isEditing, setIsEditing] = useState(false);

  const filterOptions = useMemo<RadioGroupWithIconProps['options']>(
    () =>
      savedFilterSets.map((savedFilterSet, index) => ({
        iconButtonProps: isEditing
          ? {
              alignment: 'right',
              icon: { Component: Remove },
              onClick: (e: React.MouseEvent, id: string) => {
                deleteSavedFilterSet(parseInt(id));
              },
              testId: 'delete-button',
              title: `${savedFilterSet.name}-delete`
            }
          : undefined,
        value: savedFilterSet.id.toString(),
        selected: index === selectedFilterSetIndex,
        testId: savedFilterSet.id.toString(),
        text: savedFilterSet.name
      })),
    [deleteSavedFilterSet, isEditing, savedFilterSets, selectedFilterSetIndex]
  );

  return (
    <SearchFilter
      onClear={() => {
        clearAllFilters();
        resetHypotheticalResultsCount();
        clearActiveFilterSet();
        setSelectedFilterSetIndex(null);
        setIsEditing(false);
      }}
      selectedItems={activeFilterSetName ? [activeFilterSetName] : []}
      modalElement={modalElement}
      modalTitle={<FormattedMessage defaultMessage="Saved filters" />}
      unselectedText={intl.formatMessage({ defaultMessage: 'Saved filters' })}
      popperClassName={popperClassName}
      onDismiss={() => {
        // Only reset the currently selected radio if there are no actively applied filters.
        if (activeFilterSetName) {
          setSelectedFilterSetIndex(null);
        }
        resetHypotheticalResultsCount();
        setIsEditing(false);
      }}
      saveButtonChildren={getSaveButtonChildrenProps({ intl, isLoading: isLoadingHypotheticalResultsCount, resultsCount: computedResultsCount })}
      saveButtonHandler={() => {
        applySelectedFilterSet(selectedFilterSetIndex);
        saveChanges();
        setIsEditing(false);
      }}
      tertiaryElement={
        <button type="button" onClick={() => setIsEditing(!isEditing)} className={styles.editButton}>
          {isEditing ? <FormattedMessage defaultMessage="Done" /> : <FormattedMessage defaultMessage="Edit" />}
        </button>
      }
      testIdPrefix="saved-filters"
    >
      <RadioGroupWithIcon
        options={filterOptions}
        onChange={(value: string) => {
          clearDraftChanges();
          const selectedIndex = filterOptions.findIndex(option => option.value === value);

          if (selectedIndex !== -1) {
            const selectedFiltersSet = savedFilterSets[selectedIndex];

            const deserializedFilterSet = deserializeSavedFilterSet(selectedFiltersSet, blankFiltersFactory, context, page, intl);

            updateFilters(deserializedFilterSet);
          }

          setSelectedFilterSetIndex(selectedIndex === -1 ? null : selectedIndex);
        }}
        testId="saved-filters-radio-group"
      />
    </SearchFilter>
  );
}
