import { DraggableProvided } from 'react-beautiful-dnd';
import { FormattedMessage, defineMessages } from '@alltrails/shared/react-intl';
import useFormatMessage from '@alltrails/shared/hooks/useFormatMessage';
import Menu from '@alltrails/shared/icons/Menu';
import CustomProvider from 'components/CustomProvider';
import Waypoint from 'types/Waypoint';
import OriginalLanguageButton from '../../shared/OriginalLanguageButton';
import MenuPop from '../../shared/MenuPop';
import * as styles from './styles/styles.module.scss';

const WAYPOINT_CARD_STRINGS = defineMessages({
  DELETE: { defaultMessage: 'Delete' },
  EDIT: { defaultMessage: 'Edit' },
  SORT: { defaultMessage: 'Sort' }
});

type WaypointCardProps = Waypoint & {
  canEdit?: boolean;
  deleteWaypoint?: (id: number) => void;
  description_source?: string;
  editWaypoint?: (idx: number) => void;
  handleMouseOver?: (id: number, location: Waypoint['location']) => void;
  idx: number;
  name_source?: string;
  persistWaypointOrder?: () => void;
  provided?: DraggableProvided;
  showInTranslatedLang?: boolean;
  sortMode?: boolean;
  toggleLanguageOrigin?: (idx: number, showInTranslatedLang: boolean) => void;
  toggleSortMode?: () => void;
  totalWaypointsLength?: number;
  totalWaypointsNumber?: number;
};

const WaypointCard = ({
  id,
  idx,
  name,
  name_source,
  description,
  description_source,
  location,
  editWaypoint,
  deleteWaypoint,
  toggleSortMode,
  totalWaypointsLength,
  sortMode,
  provided,
  persistWaypointOrder,
  canEdit,
  handleMouseOver,
  toggleLanguageOrigin,
  showInTranslatedLang
}: WaypointCardProps): JSX.Element => {
  const {
    formattedDefaultMessages: { DELETE, EDIT, SORT }
  } = useFormatMessage(WAYPOINT_CARD_STRINGS);
  const { latitude, longitude } = location;
  let renderMenuPop;
  let renderToggleLanguageButton;
  let toggleShowInTranslatedLang;

  if (canEdit) {
    const menuOptions = [
      {
        label: DELETE,
        callback: () => deleteWaypoint(id)
      }
    ];

    menuOptions.unshift({
      label: EDIT,
      callback: () => editWaypoint(idx)
    });

    if (totalWaypointsLength > 1) {
      menuOptions.push({
        label: SORT,
        callback: toggleSortMode
      });
    }
    renderMenuPop = <MenuPop items={menuOptions} />;
  }

  if (name_source || description_source) {
    toggleShowInTranslatedLang = () => {
      toggleLanguageOrigin(idx, showInTranslatedLang);
    };

    renderToggleLanguageButton = <OriginalLanguageButton active={showInTranslatedLang} handleClick={toggleShowInTranslatedLang} />;
  }

  const nameText = !showInTranslatedLang && name_source ? name_source : name;
  const descriptionText = !showInTranslatedLang && description_source ? description_source : description;

  return (
    <CustomProvider>
      <div className={styles.container} onMouseEnter={() => handleMouseOver(id, location)}>
        <div className={styles.topSection}>
          <div className={styles.numberAndName}>
            {provided && canEdit && (
              <span className={styles.dragHandle} {...provided.dragHandleProps} title="Reorder">
                <Menu size="sm" />
              </span>
            )}
            <div className={styles.orderNumber}>{idx + 1}</div>
            <div className={styles.nameText}>{nameText}</div>
          </div>
          {sortMode ? (
            <button type="button" className={styles.doneButton} onClick={persistWaypointOrder}>
              <FormattedMessage defaultMessage="Done" />
            </button>
          ) : (
            renderMenuPop
          )}
        </div>
        <div className={styles.descriptionText}>{descriptionText}</div>
        <div className={styles.coordinatesAndLanguageBtn}>
          <div className={styles.latLng}>
            {latitude}, {longitude}
          </div>
          {renderToggleLanguageButton}
        </div>
      </div>
    </CustomProvider>
  );
};

export default WaypointCard;
