import { createContext } from 'react';

interface PrintScaleValueInterface {
  grid: string;
  metric: boolean;
  orientation: string;
  paperSize: string;
  paperDimensionWidth: number;
  paperDimensionHeight: number;
  scale: number | null;
  setGrid(value: string): void;
  setOrientation(value: string): void;
  setPaperSize(value: string): void;
  setPaperDimensionsWidth(value: number): void;
  setPaperDimensionsHeight(value: number): void;
  setPaperDimensionsValid(value: boolean): void;
  setScale(value: number): void;
}

const PrintContext = createContext<PrintScaleValueInterface | null>(null);

const PrintProvider = PrintContext.Provider;

export { PrintScaleValueInterface, PrintContext, PrintProvider };
