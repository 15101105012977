import { PropsWithChildren, useMemo, createContext, useState, useCallback, useEffect, Suspense, lazy } from 'react';
import type { User } from 'types/User';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import { defineMessages } from '@alltrails/shared/react-intl';
import useFormatMessage from '@alltrails/shared/hooks/useFormatMessage';
import Toast from '@alltrails/shared/denali/components/Toast';
import CarouselDisplayTrigger from '@alltrails/analytics/enums/CarouselDisplayTrigger';
import logGarminRouteSent from '@alltrails/analytics/events/logGarminRouteSent';
import logGarminConnectModalViewed from '@alltrails/analytics/events/logGarminConnectModalViewed';
import logGarminSendRouteTapped from '@alltrails/analytics/events/logGarminSendRouteTapped';
import logProUpsellScreenShown from '@alltrails/analytics/events/logProUpsellScreenShown';
import logGarminConnectSucceeded from '@alltrails/analytics/events/logGarminConnectSucceeded';
import { GarminPermissionTypes, postGarminCourse, courseImport, getGarminPermissions } from 'utils/requests/garminRequests';
import { garminNewConnectionKey, garminPermissionsReturnKey, removeQueryParam } from 'components/GarminSettings/utils/modalHelpers';
import logError from 'utils/logError';
import { wrapUrlSafe } from '@alltrails/shared/utils/languageSupport';
import { isTrial } from 'utils/PlanUtils';
import type { GarminCourse } from '@alltrails/shared/types/trail';
import GarminModalType from '@alltrails/analytics/enums/GarminModalType';
import getGarminConnectUrl from '../utils/getGarminConnectUrl';

const GarminInformationalModal = lazy(() => import('../GarminInformationalModal'));
const GarminEnableCoursesModal = lazy(() => import('../GarminEnableCoursesModal'));
const GarminRouteSentModal = lazy(() => import('../GarminRouteSentModal'));
const GarminUpdateCourseModal = lazy(() => import('components/GarminSettings/GarminUpdateCourseModal'));
const GarminRouteSentErrorModal = lazy(() => import('components/GarminSettings/GarminRouteSentErrorModal'));

const messages = defineMessages({
  SENDING_ROUTE: { defaultMessage: 'Sending to Garmin...' }
});

type GarminValue = {
  canUpdateCourse: boolean;
  handleSendRoute: () => void;
  handleUpdateRoute: () => void;
  // allows consumers to set the map id, as in cases when a new activity or map are created and we do not get this prop from rails:
  setCurrentMapId: (mapId: number) => void;
};

type Props = {
  user?: User;
  mapId?: number;
  garminCourse?: GarminCourse;
  trailId?: number;
};

const initialGarminContext: GarminValue = {
  canUpdateCourse: false,
  handleSendRoute: undefined,
  handleUpdateRoute: undefined,
  setCurrentMapId: undefined
};

const GarminContext = createContext<GarminValue>(initialGarminContext);

const GarminProvider = ({ user, mapId, garminCourse, children, trailId }: PropsWithChildren<Props>) => {
  const {
    formattedDefaultMessages: { SENDING_ROUTE }
  } = useFormatMessage(messages);
  const languageRegionCode = useLanguageRegionCode();
  const garminConnectUrl = getGarminConnectUrl(user?.pro);
  const [showInformationalModal, setShowInformationalModal] = useState(false);
  const [showEnableCoursesModal, setShowEnableCoursesModal] = useState(false);
  const [showRouteSentModal, setShowRouteSentModal] = useState(false);
  const [showUpdateCourseModal, setShowUpdateCourseModal] = useState(false);
  const [showRouteSentErrorModal, setShowRouteSentErrorModal] = useState(false);
  const [toastMessage, setToastMessage] = useState<string | null>(null);
  const [currentMapId, setCurrentMapId] = useState<number | undefined>(mapId);
  const [isLoading, setIsLoading] = useState(false);

  const permissionsIncludeCourseImport = useCallback((permissions: GarminPermissionTypes[]) => Boolean(permissions?.includes(courseImport)), []);

  const sendRoute = useCallback(async () => {
    let isMounted = true;
    const timer = setTimeout(() => {
      setToastMessage(SENDING_ROUTE);
    }, 1000);

    postGarminCourse(currentMapId)
      .then(() => {
        if (isMounted) {
          clearTimeout(timer);
          setToastMessage(null);
          setShowRouteSentModal(true);
        }
      })
      .catch(err => {
        logError(err);

        if (isMounted) {
          clearTimeout(timer);
          setToastMessage(null);
          setShowRouteSentErrorModal(true);
        }
      });

    return () => {
      isMounted = false;
      clearTimeout(timer);
    };
  }, [SENDING_ROUTE, currentMapId]);

  const fetchGarminPermissions = useCallback(async () => {
    await getGarminPermissions()
      .then(res => {
        if (permissionsIncludeCourseImport(res?.permissions)) {
          logGarminRouteSent({ trail_id: trailId || null });
          sendRoute();
        } else {
          logGarminConnectModalViewed({ modal_type: GarminModalType.GarminCourses });
          setShowEnableCoursesModal(true);
        }
      })
      .catch(err => logError(err));
  }, [permissionsIncludeCourseImport, sendRoute, trailId]);

  const handleSendRoute = useCallback(() => {
    logGarminSendRouteTapped({ is_update: Boolean(garminCourse) });
    setShowUpdateCourseModal(false);

    if (!user?.pro) {
      logProUpsellScreenShown({ is_free_7_day_trial: isTrial(__AT_DATA__?.plan), trigger: CarouselDisplayTrigger.SendRoutesToGarmin });
      window.location.assign(wrapUrlSafe('/plus', languageRegionCode));
      return;
    }

    if (!user?.garminConnected) {
      logGarminConnectModalViewed({ modal_type: GarminModalType.Connect });
      setShowInformationalModal(true);
      return;
    }

    fetchGarminPermissions();
  }, [garminCourse, user?.pro, user?.garminConnected, fetchGarminPermissions, languageRegionCode]);

  const handleUpdateRoute = useCallback(() => setShowUpdateCourseModal(true), []);

  const handleCloseRouteSentModal = () => {
    // indicate to the user that their action was successful
    setIsLoading(true);
    // we need to reload the page in order to refetch the garmin course
    window.location.reload();
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const userWasRedirectedAfterAuth = searchParams?.has(garminPermissionsReturnKey);
    const userJustConnected = searchParams?.has(garminNewConnectionKey);
    const shouldRefetchPermissions = user?.pro && user?.garminConnected;

    if (userJustConnected) {
      logGarminConnectSucceeded({ trail_id: trailId || null });
      removeQueryParam(garminNewConnectionKey);
    }

    if (userWasRedirectedAfterAuth) {
      removeQueryParam(garminPermissionsReturnKey);

      if (shouldRefetchPermissions) {
        fetchGarminPermissions();
      }
    }
    // we only want to run this on mount as we are checking to see if the user was redirected after auth
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = useMemo(
    () => ({
      canUpdateCourse: Boolean(garminCourse?.updated_at),
      handleSendRoute,
      handleUpdateRoute,
      setCurrentMapId
    }),
    [garminCourse?.updated_at, handleSendRoute, handleUpdateRoute]
  );

  return (
    <GarminContext.Provider value={value}>
      {toastMessage && <Toast message={toastMessage} type="success" testId="garmin-toast" />}
      <Suspense fallback={null}>
        <GarminInformationalModal
          isOpen={showInformationalModal}
          garminConnectUrl={garminConnectUrl}
          onRequestClose={() => setShowInformationalModal(false)}
        />
      </Suspense>
      {showEnableCoursesModal && (
        <Suspense fallback={null}>
          <GarminEnableCoursesModal
            garminConnectUrl={garminConnectUrl}
            handleCloseModal={() => {
              setShowEnableCoursesModal(false);
            }}
          />
        </Suspense>
      )}
      <Suspense fallback={null}>
        <GarminRouteSentModal isOpen={showRouteSentModal} onRequestClose={handleCloseRouteSentModal} isLoading={isLoading} />
      </Suspense>
      {showUpdateCourseModal && (
        <Suspense fallback={null}>
          <GarminUpdateCourseModal
            lastUpdatedTime={garminCourse?.updated_at}
            handleUpdate={handleSendRoute}
            onRequestClose={() => setShowUpdateCourseModal(false)}
          />
        </Suspense>
      )}
      {showRouteSentErrorModal && (
        <Suspense fallback={null}>
          <GarminRouteSentErrorModal
            onRequestClose={() => {
              setShowRouteSentErrorModal(false);
            }}
          />
        </Suspense>
      )}
      {children}
    </GarminContext.Provider>
  );
};

export { GarminValue, GarminContext, GarminProvider };
