import turfDistance from '@turf/distance';
import { pointItemToLngLat } from '@alltrails/maps/utils/legacyGeoJSONConversions';
import { PageStrings } from '@alltrails/shared/utils/constants/pageStringHelpers';
import { AlgoliaFilterKey, AlgoliaResultType } from 'types/Search/Algolia';
import { LanguageSupportUtil } from './language_support_util';

// TODO https://alltrails.atlassian.net/browse/TD-300
const AlgoliaToFiltersMapping = {
  [AlgoliaFilterKey.Access]: AlgoliaFilterKey.Access,
  [AlgoliaFilterKey.Activities]: AlgoliaFilterKey.Activities,
  [AlgoliaFilterKey.Activity]: AlgoliaFilterKey.Activities,
  [AlgoliaFilterKey.Feature]: AlgoliaFilterKey.Features,
  [AlgoliaFilterKey.Features]: AlgoliaFilterKey.Features,
  [AlgoliaFilterKey.Suitability]: AlgoliaFilterKey.Access
};

const SearchResultsUtil = {
  createCreatedAtDateDescResultList(results) {
    return results.sort((a, b) => (b.created_at ?? 0) - (a.created_at ?? 0));
  },
  createPopularResultList(results) {
    return results.sort((a, b) => b.popularity - a.popularity);
  },
  createClosestResultList(results, center) {
    const centerLngLat = [center[1], center[0]];
    return results
      .map(result => {
        result.distanceToCenter = turfDistance(centerLngLat, pointItemToLngLat(result));
        return result;
      })
      .sort((a, b) => a.distanceToCenter - b.distanceToCenter);
  },
  sortByCustomListOrder(results, filters, getListItem) {
    if (!getListItem) {
      return results;
    }
    // favorite, completed, and custom list pages
    return results.sort((a, b) => {
      a = getListItem(a.type, a.ID);
      b = getListItem(b.type, b.ID);
      // descending order
      if (filters.sort['list-order'].value === -1) {
        return (b ? b.order : null) - (a ? a.order : null);
      }
      // ascendening order
      return (a ? a.order : null) - (b ? b.order : null);
    });
  },
  sortByName(results, filters) {
    return results.sort((a, b) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();
      if (filters.sort.name.value === 1) {
        if (nameA > nameB) return 1;
        if (nameA < nameB) return -1;
        return 0;
      }

      if (nameA < nameB) return 1;
      if (nameA > nameB) return -1;
      return 0;
    });
  },
  sortByDate(results, filters) {
    return results.sort((a, b) => {
      const dateA = new Date(a.created_at);
      const dateB = new Date(b.created_at);
      if (filters.sort.date.value === -1) return dateA - dateB;
      return dateB - dateA;
    });
  },
  sortByRecentlyAdded(results, filters) {
    return results.sort((a, b) => {
      const dateA = new Date(a.list_item_created);
      const dateB = new Date(b.list_item_created);
      if (filters.sort['recently-added'].value === -1) return dateA - dateB;
      return dateB - dateA;
    });
  },
  applySortFunction(items, filters, center, weightDistance, getListItem = null) {
    const selectedFilterKey = Object.keys(filters.sort).filter(itemKey => filters.sort[itemKey].selected)[0];
    if (selectedFilterKey) {
      if (selectedFilterKey == 'most-popular') return this.createPopularResultList(items);
      if (selectedFilterKey == 'newly-added') return this.createCreatedAtDateDescResultList(items);
      if (selectedFilterKey == 'closest') return this.createClosestResultList(items, center);
      if (selectedFilterKey == 'list-order') return this.sortByCustomListOrder(items, filters, getListItem);
      if (selectedFilterKey == 'name') return this.sortByName(items, filters);
      if (selectedFilterKey == 'date') return this.sortByDate(items, filters);
      if (selectedFilterKey == 'recently-added') return this.sortByRecentlyAdded(items, filters);
    }
    return items;
  },
  formatTrailInfo(hit) {
    let resultString = '';

    if (hit.area_name && hit.area_name != '') {
      resultString += hit.area_name;
    }

    if (hit.city_name && hit.city_name != '') {
      resultString += (resultString == '' ? '' : ', ') + hit.city_name;
    }

    if (hit.state_name && hit.state_name != '') {
      resultString += (resultString == '' ? '' : ', ') + hit.state_name;
    }

    if (hit.country_name && hit.country_name != '') {
      resultString += (resultString == '' ? '' : ', ') + hit.country_name;
    }

    return resultString;
  },
  formatNonTrailInfo(hit) {
    let resultString = '';
    if (hit.type !== 'area' || (hit.area_type && hit.area_type === 'C')) {
      if (hit.city_name !== undefined && hit.city_name != '' && hit.city_name != null) {
        resultString += (resultString === '' ? '' : ', ') + hit.city_name;
      }
    }
    if (hit.state_name !== undefined && hit.state_name !== '' && hit.state_name != null) {
      resultString += (resultString == '' ? '' : ', ') + hit.state_name;
    }
    if (hit.country_name !== '' && hit.country_name != undefined) {
      resultString += (resultString == '' ? '' : ', ') + hit.country_name;
    }

    return resultString;
  },
  formatLocationInfo(hit) {
    return hit.type === 'trail' ? this.formatTrailInfo(hit) : this.formatNonTrailInfo(hit);
  },
  /**
   * createResultUrl returns a relative URL for a given hit. Downstream users
   * of this function should always assume the URL is a relative path.
   */
  createResultUrl(hit, languageRegionCode = 'en-US') {
    switch (hit.type) {
      case AlgoliaResultType.Track:
        return LanguageSupportUtil.wrapUrlSafe(`/explore/recording/${hit.slug}`, languageRegionCode);
      case AlgoliaResultType.Map:
        return LanguageSupportUtil.wrapUrlSafe(`/explore/map/${hit.slug}`, languageRegionCode);
      case AlgoliaResultType.List:
        return LanguageSupportUtil.wrapUrlSafe(`/lists/${hit.slug}`, languageRegionCode);
      case AlgoliaResultType.Filter:
        return LanguageSupportUtil.wrapUrlSafe(`/explore?${hit.slug}`, languageRegionCode);
      case AlgoliaResultType.POI:
        const link = hit.slug ? `/${hit.slug}` : `/explore?lat=${hit._geoloc.lat}&lng=${hit._geoloc.lng}`;
        return LanguageSupportUtil.wrapUrlSafe(link, languageRegionCode);
      default:
        return LanguageSupportUtil.wrapUrlSafe(`/${hit.slug}`, languageRegionCode);
    }
  },

  // TODO https://alltrails.atlassian.net/browse/TD-300
  normalizeAlgoliaHitFilters(hit) {
    if (hit.type !== AlgoliaResultType.Filter) {
      return undefined;
    }

    const filters = {};

    Object.keys(AlgoliaToFiltersMapping).forEach(oldKey => {
      if (hit.filters?.[oldKey]) {
        const newKey = AlgoliaToFiltersMapping[oldKey];
        filters[newKey] = hit.filters[oldKey];
      }
    });

    return filters;
  },
  getListData(currentPage, customList, intl) {
    if (currentPage === PageStrings.EXPLORE_CUSTOM_PAGE || currentPage === PageStrings.EXPLORE_FAVORITE_PAGE) {
      const isFavorite = currentPage === PageStrings.EXPLORE_FAVORITE_PAGE;
      return {
        id: isFavorite ? '1000' : customList?.id,
        title: isFavorite ? intl.formatMessage({ defaultMessage: 'My favorites' }) : customList?.name
      };
    }
  }
};

export { SearchResultsUtil };
