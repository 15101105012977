import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ShimmerWrapper from '@alltrails/shared/components/ShimmerWrapper';
import { PhotoSize } from '@alltrails/shared/types/photos';
import { getPhotoUrl } from '@alltrails/shared/utils/requests/photoRequests';
import { ServerCommunicationUtil } from '../../../../utils/server_communication_util';
import { wrapEventHandler } from '../../../../utils/handlers';
import * as styles from './styles/styles.module.scss';

const PhotoPopup = ({ handleClick, imageSize, photo }) => {
  const [src, setSrc] = useState('');

  const title = photo.title || '';
  const defaultSrc = `/api/alltrails/photos/${photo.id}/image?size=${imageSize}&api_key=${ServerCommunicationUtil.apiKey}`;

  useEffect(() => {
    const { id, photoHash } = photo;

    if (photoHash && id) {
      setSrc(getPhotoUrl({ id, photoHash }, imageSize));
    } else {
      setSrc(defaultSrc);
    }
  }, [photo, imageSize]);

  return (
    <div key={photo} className={styles.photoPopup} onClick={wrapEventHandler(handleClick)}>
      {src === '' ? (
        <ShimmerWrapper className={styles.shimmerWrapper}>
          <div className={styles.shimmer} />
        </ShimmerWrapper>
      ) : (
        <img alt={title} title={title} src={src} onError={() => {
          // We're already using the default. There's no additional fallback.
          if (src === defaultSrc) {
            return;
          }

          setSrc(defaultSrc);
        }} />
      )}
    </div>
  );
};

PhotoPopup.defaultProps = {
  handleClick: null,
  imageSize: PhotoSize.MediumSquare
};

PhotoPopup.propTypes = {
  handleClick: PropTypes.func,
  imageSize: PropTypes.string,
  photo: PropTypes.object.isRequired
};

export default PhotoPopup;
