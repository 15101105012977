import { decodedObjectsFromPolyline } from '@alltrails/maps/utils/legacyGeoJSONConversions';
import turfNearestPointOnLine from '@turf/nearest-point-on-line';
import booleanPointOnLine from '@turf/boolean-point-on-line';
import turfLength from '@turf/length';
import { lineString } from '@turf/helpers';
import isEqual from 'underscore/modules/isEqual';

const getOptsForCurrentRoutingMode = routingMode => {
  switch (routingMode) {
    case 'mountain-biking':
      return {
        costing: 'bicycle',
        costing_options: {
          bicycle: {
            bicycle_type: 'Mountain',
            use_roads: 0.25,
            use_hills: 1,
            avoid_bad_surfaces: 0.25,
            use_ferry: 0
          }
        }
      };
    case 'bike-touring':
      return {
        costing: 'bicycle',
        costing_options: {
          bicycle: {
            bicycle_type: 'Hybrid',
            use_roads: 0.5,
            use_hills: 0.5,
            avoid_bad_surfaces: 0.5,
            use_ferry: 0
          }
        }
      };
    case 'road-biking':
      return {
        costing: 'bicycle',
        costing_options: {
          bicycle: {
            bicycle_type: 'Road',
            use_roads: 0.75,
            use_hills: 1,
            avoid_bad_surfaces: 0.99,
            use_ferry: 0
          }
        }
      };
    case 'off-road-driving':
      return {
        costing: 'motorcycle',
        costing_options: {
          motorcycle: {
            use_trails: 1, // A riders's desire for adventure in their routes.
            use_ferry: 0
          }
        }
      };
    case 'scenic-driving':
      return {
        costing: 'auto',
        costing_options: {
          auto: {
            use_ferry: 0
          }
        }
      };
    default:
      // hiking
      return {
        costing: 'pedestrian',
        costing_options: {
          pedestrian: {
            max_hiking_difficulty: 6, // pedestrian setting for maximum trail difficulty when routing
            use_ferry: 0
          }
        }
      };
  }
};

// get rid of this once the DS pipeline is updated to truncate decimal places
const pointsEqual = (pt1, pt2) =>
  isEqual(
    pt1.map(n => n.toFixed(6)),
    pt2.map(n => n.toFixed(6))
  );

const getPath = (vertices, startIndex, endIndex) => {
  // start at next point after start index
  const startToLastVertice = vertices.reduce((acc, vertex, i) => {
    // If we cross over the end, route all the way to the end and capture the rest with the reduce below
    if (i > startIndex && (i <= endIndex || endIndex < startIndex)) {
      acc.push(vertex);
    }
    return acc;
  }, []);

  const firstVerticeToEnd = vertices.reduce((acc, vertex, i) => {
    if (endIndex < startIndex && i <= endIndex) {
      acc.push(vertex);
    }
    return acc;
  }, []);

  return startToLastVertice.concat(firstVerticeToEnd);
};

const removeDuplicateVertices = vertices => {
  const cleaned = vertices.reduce((acc, vertex, i) => {
    if (i === 0 || !isEqual(acc[acc.length - 1], vertex)) {
      acc.push(vertex);
    }
    return acc;
  }, []);
  return cleaned;
};

const oppositeIndex = (index, array) => array.length - 1 - index;

const pointHasManyMatches = (vertices, index) => {
  const point = vertices[index];
  return vertices.filter(vertice => pointsEqual(vertice, point)).length > 1;
};

const findTopLinePointIndex = (vertices, index) => {
  const point = vertices[index];
  const first = vertices.findIndex(vertice => pointsEqual(vertice, point));
  return first !== index ? first - 1 : index;
};

const findClosestPointIndex = (vertices, startIndex, targetIndex) => {
  const targetPoint = vertices[targetIndex];
  const allIndexesOfTarget = vertices.reduce((indexes, vertice, i) => (pointsEqual(vertice, targetPoint) ? indexes.concat(i) : indexes), []);

  if (allIndexesOfTarget.length === 1) {
    return targetIndex;
  }

  const shortestIndexWithLength = allIndexesOfTarget.reduce(
    (shortest, index) => {
      // get length from start to target
      const path = getPath(vertices, startIndex, index);
      const route = lineString(path);
      const length = turfLength(route);
      if (length < shortest[1]) {
        return [index, length];
      }
      return shortest;
    },
    [0, Infinity]
  );
  return shortestIndexWithLength[0];
};

const slidRoutePoints = async (pts, slidRoute, ignoreOrigStart, ignoreOrigEnd) => {
  const fromPoint = pts[0];
  const placedPoint = pts[pts.length - 1];
  const vertices = slidRoute.geometry.coordinates;
  const lineSuggestion = lineString(vertices);
  // this epsilon value was found through trial and error, but in testing will always return the correct boolean result
  const isStartPointOnLine = booleanPointOnLine(fromPoint, lineSuggestion, { epsilon: 5e-10 });

  const nearestToStartPointResult = turfNearestPointOnLine(lineSuggestion, fromPoint);
  const nearestToPlacedPointResult = turfNearestPointOnLine(lineSuggestion, placedPoint);

  // always use the first instance of this point
  const startIndex = nearestToStartPointResult.properties.index;
  const newStartIndex = findTopLinePointIndex(vertices, startIndex);

  const endIndex = nearestToPlacedPointResult.properties.index;

  // If coming from a non overlapping point and moving to an overlapping point,
  // use closest of 2 overlapping points, not necessarily the top line point
  const newEndIndex = pointHasManyMatches(vertices, startIndex)
    ? findTopLinePointIndex(vertices, endIndex)
    : findClosestPointIndex(vertices, newStartIndex, endIndex);

  // if the start point is already on the suggested route, use that point
  const firstPoint = isStartPointOnLine ? fromPoint : nearestToStartPointResult.geometry.coordinates;

  // use nearest point on the suggested route to the placed point, this will likely not be one of the vertices, but could be
  const endPoint = nearestToPlacedPointResult.geometry.coordinates;

  // set first and last point of the result segment
  const result = [firstPoint, endPoint];

  // no need for routing if its just point to point within one edge
  if (newStartIndex === newEndIndex) {
    return result;
  }

  // get all points in between
  // check both directions and use the shortest path
  const forwardPath = getPath(vertices, newStartIndex, newEndIndex);
  const reversed = vertices.toReversed();
  const backwardsPath = getPath(reversed, oppositeIndex(newStartIndex, reversed), oppositeIndex(newEndIndex, reversed) - 1);

  const forwardLine = lineString(forwardPath);
  const backwardsLine = lineString(backwardsPath);
  const forwardLength = turfLength(forwardLine);
  const backwardsLength = turfLength(backwardsLine);

  if (forwardLength < backwardsLength) {
    result.splice(1, 0, ...forwardPath);
  } else {
    result.splice(1, 0, ...backwardsPath);
  }

  if (!ignoreOrigStart && !isStartPointOnLine) {
    result.unshift(fromPoint);
  }

  if (!ignoreOrigEnd) {
    result.push(placedPoint);
  }
  // sometimes the route will have duplicate points, likely due to some faulty logic in this method or any that it calls
  // removeDuplicateVertices is a safeguard which ideally should not trigger
  return removeDuplicateVertices(result);
};

// Uses routing library (Valhalla) to get directions from point to point (to point) following OSM segments
// https://valhalla.readthedocs.io/en/latest/api/turn-by-turn/api-reference/
const routePoints = (routingMode, pts, ignoreOrigStart, ignoreOrigEnd, slidRoute) => {
  if (routingMode === 'slid-route') {
    return slidRoutePoints(pts, slidRoute, ignoreOrigStart, ignoreOrigEnd);
  }
  const firstOrigPt = pts[0];
  const lastOrigPt = pts[pts.length - 1];
  return new Promise((resolve, reject) => {
    const options = {
      directions_type: 'none', // not interested in directions - only need final shape
      locations: pts.map(pt => ({ lon: pt[0], lat: pt[1] })),
      ...getOptsForCurrentRoutingMode(routingMode)
    };
    $.ajax({
      type: 'GET',
      url: 'https://api.mapbox.com/valhalla/v1/route',
      contentType: 'application/json',
      data: {
        access_token: 'pk.eyJ1IjoiYWxsdHJhaWxzIiwiYSI6ImNqM293emo1YjAwZWQyd3FnaXh0eWsxeHkifQ.LeDD0X-JiWsJmDKeB0AS5w',
        json: JSON.stringify(options)
      },
      success: data => {
        // handle bug in valhalla where status code of request is 200, but it responds with error json block
        if (data.status_code && data.status_code !== 200) {
          return reject(data);
        }
        // push polyline from endPt to latLng onto stack
        const polyline = data.trip.legs[0].shape;
        const lngLats = decodedObjectsFromPolyline(polyline, [6, 6]).map(pt => [Math.round(pt[1] * 1e5) / 1e5, Math.round(pt[0] * 1e5) / 1e5]);
        // Ensure pointsData includes first point of original to reduce gap lines
        // This logic is needed because valhalla will route to nearest OSM point, rather than to exact point
        if (!ignoreOrigStart) {
          lngLats.unshift(firstOrigPt);
          // Check for weird valhalla bug where first point returned from routing engine is closing point on line
          // segment's polygon - see French Trail loop in Redwood Regional Park, between Fern trail and Mill trail
          // intersections.
          // Ensure that pointsData[1] is between pointsData[0] and pointsData[2].
          // Note: This logic may need to be adjusted as other cases are found.
          const closeThresh = 1e-4;
          if (
            lngLats.length > 2 &&
            Math.abs(lngLats[0][1] - lngLats[2][1]) <= closeThresh &&
            Math.abs(lngLats[0][0] - lngLats[2][0]) <= closeThresh &&
            Math.abs(lngLats[0][1] - lngLats[1][1]) > closeThresh &&
            Math.abs(lngLats[0][0] - lngLats[1][0]) > closeThresh
          ) {
            lngLats.splice(1, 1);
          }
        }
        if (!ignoreOrigEnd) {
          lngLats.push(lastOrigPt);
        }
        return resolve(lngLats);
      },
      error: error => reject(error)
    });
  });
};

export { routePoints };
