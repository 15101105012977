import { lazy, ComponentType } from 'react';

function retry(fn: () => Promise<{ default: ComponentType<any> }>, retriesLeft = 5, interval = 1000): Promise<{ default: ComponentType<any> }> {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch(error => {
        if (retriesLeft === 1) {
          reject(error);
          return;
        }
        setTimeout(() => {
          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
}

export default retry;
