import { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import Overflow from '@alltrails/shared/icons/Overflow';
import * as styles from './styles/styles.module.scss';
import { regenTrailSlug, mergeTrailEnds, refreshTrailCache } from '../../../utils/requests/admin_trail_requests';

class AdminTrailDropdown extends Component {
  constructor(props) {
    super(props);

    this.regenerateButton = createRef();
    this.refreshButton = createRef();
    this.offerMergeEndsButton = createRef();

    this.state = {
      dropdownExpanded: false
    };
  }

  // This feature is only applicable for admins and won't be used on mobile
  handleMouseEnter = () => {
    this.setState({ dropdownExpanded: true });
  };

  handleMouseLeave = () => {
    this.setState({ dropdownExpanded: false });
  };

  regenerateSlug = () => {
    if (!window.confirm("Are you sure you want to regenerate this trail's slug?")) {
      return;
    }
    const a = this.regenerateButton.current;
    a.innerHTML = 'Queuing...';
    regenTrailSlug(this.props.trailId).then(() => {
      a.innerHTML = 'Queued for regeneration';
      a.disabled = true;
    });
  };

  refreshTrail = () => {
    if (!window.confirm("Are you sure you want to redraw this trail's static map and clear it's cached data?")) {
      return;
    }
    const a = this.refreshButton.current;
    a.innerHTML = 'Refreshing...';
    refreshTrailCache(this.props.trailId).then(() => {
      a.innerHTML = 'Refreshed';
      a.disabled = true;
    });
  };

  mergeEnds = () => {
    if (!window.confirm("Are you sure you want to merge the ends of this trail's route?")) {
      return;
    }
    const a = this.offerMergeEndsButton.current;
    a.innerHTML = 'Merging...';
    mergeTrailEnds(this.props.trailId).then(() => {
      a.innerHTML = 'Ends merged';
      window.location.reload();
    });
  };

  renderDropdown() {
    // AT is a global variable that we're trying to work away from
    return (
      <div onMouseLeave={this.handleMouseLeave} className={styles.dropdownContainer}>
        <button
          ref={this.regenerateButton}
          className={styles.dropdownButton}
          type="button"
          key="regenerate-slug"
          onClick={this.regenerateSlug}
          data-testid="regenerateSlug"
        >
          Regenerate Slug
        </button>
        <button
          ref={this.refreshButton}
          className={styles.dropdownButton}
          type="button"
          key="refresh-trail"
          onClick={this.refreshTrail}
          data-testid="refreshTrail"
        >
          Refresh Trail
        </button>
        <a
          className={styles.dropdownButton}
          href={`/edit-translations?id=${this.props.trailId}&table=trails&column_name=name`}
          key="edit-translations"
          target="_blank"
          rel="noopener"
          data-testid="editTranslations"
        >
          Edit Translations
        </a>
        {this.props.offerMergeEnds && (
          <button ref={this.offerMergeEndsButton} className={styles.dropdownButton} type="button" onClick={this.mergeEnds}>
            Merge Ends
          </button>
        )}
      </div>
    );
  }

  render() {
    return (
      <>
        <button
          onMouseEnter={this.handleMouseEnter}
          className={styles.triggerContainer}
          type="button"
          data-testid="suggestEditTrigger"
          title="Edit dropdown"
        >
          <Overflow />
        </button>
        {this.state.dropdownExpanded && this.renderDropdown()}
      </>
    );
  }
}

AdminTrailDropdown.propTypes = {
  offerMergeEnds: PropTypes.bool,
  trailId: PropTypes.number,
  statusPending: PropTypes.bool
};

AdminTrailDropdown.defaultProps = {
  offerMergeEnds: null,
  trailId: null,
  statusPending: false
};

export default AdminTrailDropdown;
