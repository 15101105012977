import { useState } from 'react';
import { FormattedMessage } from '@alltrails/shared/react-intl';
import Button from '@alltrails/shared/denali/components/Button';
import PlusBadge from '@alltrails/shared/denali/components/PlusBadge';
import Play from '@alltrails/shared/icons/Play';
import logFlyoverStartButtonTapped from '@alltrails/analytics/events/logFlyoverStartButtonTapped';
import FlyoverSource from '@alltrails/analytics/enums/FlyoverSource';
import Trail from 'types/Trails/Trail';
import useUser from 'hooks/useUser';
import SignupModal from 'components/SignupModal';
import useToggle from '@alltrails/shared/hooks/useToggle';
import CarouselDisplayTrigger from '@alltrails/analytics/enums/CarouselDisplayTrigger';
import TrailPageCTAText from '@alltrails/analytics/enums/TrailPageCTAText';
import * as styles from './styles.module.scss';

type FlyoverEntryProps = {
  initializingFlyover?: boolean;
  onEntryClick: () => void;
  trail: Trail;
};

const FlyoverEntry = ({ initializingFlyover = false, onEntryClick, trail }: FlyoverEntryProps): JSX.Element => {
  const [showOfflineError, setShowOfflineError] = useState(false);
  const [isSignUpModalOpen, toggleSignUpModal] = useToggle(false);

  const user = useUser();

  const onFlyoverEntryClick = (ev: React.MouseEvent) => {
    logFlyoverStartButtonTapped({ source: FlyoverSource.MapDetails, trail_id: trail.trail_id, cta_text: TrailPageCTAText.PreviewRoute });
    setShowOfflineError(false);
    if (!window.navigator.onLine) {
      ev.preventDefault();
      setShowOfflineError(true);
      return;
    }

    if (!user) {
      toggleSignUpModal();
      return;
    }

    window.history.pushState(null, '');

    onEntryClick();
  };

  if (trail.length === 0 && trail.elevation_gain === 0) {
    return null;
  }

  return (
    <div className={styles.flyoverContainer}>
      <SignupModal
        trigger={CarouselDisplayTrigger.Flyover}
        heading={<FormattedMessage defaultMessage="Access Trail Previews and more with AllTrails+" />}
        isOpen={isSignUpModalOpen}
        onRequestClose={toggleSignUpModal}
        onSuccess={() => {
          toggleSignUpModal();
          onEntryClick();
        }}
      />
      <div className={styles.buttonEntry}>
        <Button
          icon={{ Component: Play }}
          loading={initializingFlyover}
          onClick={onFlyoverEntryClick}
          testId="flyover-entry"
          text={<FormattedMessage defaultMessage="Preview trail" />}
          size="md"
          width="full"
        />
        <PlusBadge className={styles.plusBadge} />
      </div>
      {showOfflineError && (
        <span className={styles.error}>
          <FormattedMessage defaultMessage="We’re unable to  submit your request because you’re offline. Please try again later." />
        </span>
      )}
    </div>
  );
};

export default FlyoverEntry;
