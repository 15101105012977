import classNames from 'classnames';
import { ComponentProps, useEffect, useMemo } from 'react';
import { defineMessages } from '@alltrails/shared/react-intl';
import useFormatMessage from '@alltrails/shared/hooks/useFormatMessage';
import SelectionChips from '@alltrails/shared/denali/components/SelectionChips';
import * as styles from './TagSection.module.scss';

const ID_NONE = 'none';
const messages = defineMessages({
  NONE: {
    defaultMessage: 'Unsure'
  }
});

type Chip = ComponentProps<typeof SelectionChips>['chips'][0];
type Props = {
  uniqueId: string;
  title: string;
  chips: Chip[];
  selectedChips: Chip[];
  onChange: (selectedChip: Chip) => void;
  isMultiSelect?: boolean;
  includeNone?: boolean;
};

const TagSection = ({ uniqueId, title, chips, selectedChips, onChange, isMultiSelect, includeNone }: Props) => {
  const {
    formattedDefaultMessages: { NONE }
  } = useFormatMessage(messages);
  const allChips = useMemo(
    () => (includeNone ? [...chips.filter(chip => chip.id !== ID_NONE), { id: ID_NONE, label: NONE }] : chips),
    [includeNone, chips, NONE]
  );
  const allSelectedChips = useMemo(
    () => (includeNone && !selectedChips.length ? [{ id: ID_NONE, label: NONE }] : selectedChips),
    [includeNone, selectedChips, NONE]
  );

  useEffect(() => {
    if (includeNone && chips.some(chip => chip.id === ID_NONE)) {
      console.warn(`TagSection: includeNone is true but chips contains a chip with id of ${ID_NONE}`);
    }
  }, [includeNone, chips]);

  return (
    <div className={classNames(styles.container)}>
      <span className={styles.labelText}>{title}</span>
      <SelectionChips
        className={styles.selectionChips}
        selectionChipClassName={styles.selectionChip}
        uniqueId={uniqueId}
        title={title}
        chips={allChips}
        selectedChips={allSelectedChips}
        onChange={onChange}
        isMultiSelect={isMultiSelect}
      />
    </div>
  );
};

export default TagSection;
export { ID_NONE };
export type { Chip };
