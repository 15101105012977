import { ReactNode } from 'react';
import Stepper from 'components/Stepper';
import { MEDIA_QUERY_MOBILE_DOWN } from 'utils/constants/breakpoints';
import { Images } from '../HeroLayout/index';
import * as styles from './styles.module.scss';

type Props = {
  activeStep: 0 | 1;
  paragraphText: string;
  children: ReactNode;
  heading: ReactNode;
  images: Images;
  heroAlt: string;
};

function ReferralLayout({ activeStep, paragraphText, images, heroAlt, heading, children }: Props) {
  return (
    <>
      <div className={styles.hero}>
        <picture className={styles.picture}>
          <source media={MEDIA_QUERY_MOBILE_DOWN} srcSet={images.mobile.avif} type="image/avif" />
          <source media={MEDIA_QUERY_MOBILE_DOWN} srcSet={images.mobile.webp} type="image/webp" />
          <source media={MEDIA_QUERY_MOBILE_DOWN} srcSet={images.mobile.jpg} />
          <source srcSet={images.desktop.avif} type="image/avif" />
          <source srcSet={images.desktop.webp} type="image/webp" />
          <img alt={heroAlt} src={images.desktop.jpg} className={styles.img} />
        </picture>
      </div>
      <div className={styles.content}>
        {/* these might be better in a grid with a gap of margin bottom */}
        <Stepper classes={styles.stepper} activeStep={activeStep} steps={2} />
        <h1 className={styles.heading}>{heading}</h1>
        <p className={styles.introduction}>{paragraphText}</p>
        <div className={styles.secondary}>{children}</div>
      </div>
    </>
  );
}

export default ReferralLayout;
