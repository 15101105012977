import { COLOR_NEUTRAL_800 } from '@alltrails/shared/denali/tokens';
import waypointImageSrc from '../../../../assets/images/icons/maps/icons/map-icon-waypoint@2x.png';

const waypointImageId = 'waypoint-icon';

const createWaypointMarkerLayer = (id, sourceId) => {
  return {
    id,
    type: 'symbol',
    source: sourceId,
    layout: {
      'icon-allow-overlap': true,
      'icon-ignore-placement': true,
      'icon-image': ['image', waypointImageId],
      'icon-offset':[0, 1],
      'icon-size': 1,
      'text-field': ['get', 'order'],
      'text-font': ['Manrope Bold'],
      'text-size': 13
    },
    paint: {
      'text-color': COLOR_NEUTRAL_800,
    }
  };
};

export { createWaypointMarkerLayer, waypointImageSrc, waypointImageId };
