import { useState, useEffect } from 'react';
import { debounce } from 'debounce';
import { defineMessages } from '@alltrails/shared/react-intl';
import useFormatMessage from '@alltrails/shared/hooks/useFormatMessage';
import Switch from '@alltrails/shared/denali/components/Switch';
import TextInput from '@alltrails/shared/denali/components/TextInput';
import TextArea from '@alltrails/shared/denali/components/TextArea';
import Button from '@alltrails/shared/denali/components/Button';
import Link from '@alltrails/shared/denali/components/Link';
import type { TrailId } from '@alltrails/shared/types/trail';
import { addWaypointToMap, AddWaypointToMapPayload, addWaypointToTrail, updateWaypoint } from 'api/Waypoints';
import { WaypointsAction } from 'hooks/useWaypoints';
import Waypoint, { WaypointId } from 'types/Waypoint';
import hasPermission from 'utils/hasPermission';
import { formatWaypointForMap } from '../../../utils/map_creator_util';
import * as styles from './styles/styles.module.scss';

const WAYPOINT_FORM_STRINGS = defineMessages({
  ERROR: { defaultMessage: 'There has been an error, please try again' },
  TITLE: { defaultMessage: 'Title' },
  DESCRIPTION: { defaultMessage: 'Description' },
  LATITUDE: { defaultMessage: 'Latitude' },
  LONGITUDE: { defaultMessage: 'Longitude' },
  SHOW_TITLE: { defaultMessage: 'Show title on map' },
  SUBMIT: { defaultMessage: 'Submit' },
  CLEAR: { defaultMessage: 'Clear' }
});

type Props = {
  id: WaypointId;
  dispatchWaypoints: React.Dispatch<WaypointsAction>;
  messagingChannel?: {
    subscribe: (name: string, data: any) => void;
  };
  mapId: number;
  trailId?: TrailId;
  createMapWithWaypoint?: (waypoint: any) => void;
  description: string;
  location: {
    longitude: number;
    latitude: number;
  };
  name: string;
  waypointDisplayProperty: {
    showTitle: boolean;
  };
  dispatchFormState?: boolean;
  name_unsupported_language?: boolean;
  description_unsupported_language?: boolean;
  refetchTrailProps?: () => void;
  onSubmit?: () => void;
};

export default function WaypointForm({
  id,
  dispatchWaypoints,
  messagingChannel,
  mapId,
  trailId,
  createMapWithWaypoint,
  dispatchFormState,
  description,
  location,
  name,
  waypointDisplayProperty,
  name_unsupported_language,
  description_unsupported_language,
  refetchTrailProps,
  onSubmit
}: Props) {
  const [formParams, updateFormParams] = useState({
    description,
    location: {
      longitude: location.longitude?.toString(),
      latitude: location.latitude?.toString()
    },
    name,
    showTitle: !trailId && waypointDisplayProperty.showTitle
  });
  const [submitting, toggleSubmitting] = useState(false);
  const [serverErrors, updateServerErrors] = useState([]);

  const {
    formattedDefaultMessages: { ERROR, TITLE, DESCRIPTION, LATITUDE, LONGITUDE, SHOW_TITLE, SUBMIT, CLEAR }
  } = useFormatMessage(WAYPOINT_FORM_STRINGS);

  const updateTopLevelForm = debounce(() => {
    // this updates the form data in the panel, but we debounce here to limit re-renders
    const payload = { ...formParams, id };
    dispatchWaypoints({ payload, type: 'UPDATE_WAYPOINT_EDIT_PARAMS' });
  }, 1000);

  useEffect(() => {
    if (!dispatchFormState) {
      return;
    }

    updateTopLevelForm();
  }, [formParams]);

  const handleError = (response: { data?: { errors?: Error[] }; meta?: unknown } = {}) => {
    const errors = response?.data?.errors?.map((e: any) => e.message) || [ERROR];
    toggleSubmitting(false);
    updateServerErrors(errors);
  };

  const handleSuccess = (waypoint: Waypoint) => {
    dispatchWaypoints({ payload: waypoint, type: 'ADD_PERSISTED_WAYPOINT' });
    refetchTrailProps?.();
  };

  const persistNewWaypoint = () => {
    const { location, ...params } = { ...(!mapId || trailId ? formatWaypointForMap(formParams) : formParams) };
    const waypoint = {
      ...params,
      location: {
        latitude: Number(location.latitude),
        longitude: Number(location.longitude)
      }
    };

    if (trailId) {
      addWaypointToTrail(trailId, waypoint)
        .then(response => handleSuccess(response.waypoints[0]))
        .catch(handleError);

      return;
    }

    if (!mapId && createMapWithWaypoint) {
      createMapWithWaypoint(waypoint);
      return;
    }

    addWaypointToMap(mapId, waypoint as AddWaypointToMapPayload)
      .then(response => handleSuccess(response.waypoints[0]))
      .catch(handleError);
  };

  const handleSubmit = () => {
    onSubmit?.();
    if (id) {
      updateWaypoint(mapId, id, {
        description: formParams.description,
        name: formParams.name,
        latitude: Number(formParams.location.latitude),
        longitude: Number(formParams.location.longitude),
        showTitle: formParams.showTitle
      })
        .then(response => handleSuccess(response.waypoints[0]))
        .catch(handleError);
    } else {
      persistNewWaypoint();
    }
  };

  const handleWaypointDragged = (data: Waypoint) => {
    const {
      location: { latitude, longitude }
    } = data;

    const location = {
      latitude: latitude.toFixed(5),
      longitude: longitude.toFixed(5)
    };

    updateFormParams(prevState => ({ ...prevState, location }));
  };

  useEffect(() => {
    if (!messagingChannel) {
      return;
    }

    messagingChannel.subscribe('waypoint.dragged.with_location', handleWaypointDragged);
  }, []);

  const handleChange = (value: string, formName: string) => {
    updateFormParams(prevState => ({ ...prevState, [formName]: value }));
  };

  const handleToggle = () => {
    const { showTitle } = formParams;
    updateFormParams(prevState => ({ ...prevState, showTitle: !showTitle }));
  };

  const handleLocationChange = (value: string, fieldName: string) => {
    const locationData = { ...formParams.location, [fieldName]: value };

    updateFormParams({ ...formParams, location: locationData });
  };

  const handleClearWaypointForm = () => {
    dispatchWaypoints({ type: 'CANCEL_WAYPOINT_FORM' });
  };

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <div className={styles.textFieldContainer}>
        <TextInput
          type="text"
          testId="waypoint_title"
          labelText={TITLE}
          value={formParams.name}
          onChange={value => handleChange(value, 'name')}
          disabled={name_unsupported_language}
        />
      </div>
      <div className={styles.textFieldContainer}>
        <TextArea
          testId="waypoint_description"
          labelText={DESCRIPTION}
          value={formParams.description}
          rows={3}
          resizable
          onChange={value => handleChange(value, 'description')}
          disabled={description_unsupported_language}
        />
      </div>
      <div className={styles.lngLat}>
        <TextInput
          type="text"
          testId="waypoint_location_latitude"
          labelText={LATITUDE}
          value={formParams.location.latitude?.toString()}
          onChange={value => handleLocationChange(value, 'latitude')}
        />
        <TextInput
          type="text"
          testId="waypoint_location_longitude"
          labelText={LONGITUDE}
          value={formParams.location.longitude?.toString()}
          onChange={value => handleLocationChange(value, 'longitude')}
        />
      </div>
      {!trailId && (
        <Switch
          className={styles.formToggle}
          labelText={SHOW_TITLE}
          selected={formParams.showTitle}
          testId="showTitle"
          width="full"
          onChange={handleToggle}
        />
      )}
      {serverErrors.map(error => (
        <div className={styles.errorMessage}>{error}</div>
      ))}
      <div className={styles.buttonAndLinks}>
        {hasPermission({ permission: 'trails:manage' }) && id && (
          <Link target="_blank" href={`/edit-translations?table=at_map_markers&column_name=name&id=${id}`}>
            {' '}
            Edit Translations{' '}
          </Link>
        )}
        <Button text={CLEAR} onClick={handleClearWaypointForm} disabled={submitting} testId="activity-waypoint-form-clear" variant="flat" />
        <Button text={SUBMIT} onClick={handleSubmit} disabled={submitting} testId="activity-waypoint-form-submit" variant="primary" />
      </div>
    </form>
  );
}
