import { PropTypes, bool, number } from 'prop-types';

import * as styles from './styles/styles.module.scss';

const SplitSection = ({ data, pace, splitBar }) => {
  return (
    <div className={styles.splitsData}>
      {pace ? (
        <div className={styles.splitsPace}>
          <span className={styles.dataHeadings}>{data}</span>
          <div className={styles.splitsBar}>
            <div style={{ width: `${splitBar * 100}%` }} />
          </div>
        </div>
      ) : (
        <span className={styles.dataHeadings}>{data}</span>
      )}
    </div>
  );
};

SplitSection.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  pace: bool,
  splitBar: number
};

SplitSection.defaultProps = {
  pace: false,
  splitBar: 0
};

export default SplitSection;
