import { useEffect, useRef } from 'react';
import { object, string, arrayOf, number, bool } from 'prop-types';

import { GridUtils } from './GridUtils';

const GridLines = ({ bounds, grid, mapZoom, paperSize, orientation, paperDimensionWidth, paperDimensionHeight, isMobileWidth, intl }) => {
  const mapGridLinesRef = useRef();

  useEffect(() => {
    GridUtils.createLngLatSvgs(bounds, grid, mapZoom, null, mapGridLinesRef, intl);
  }, [bounds, grid, mapZoom, paperSize, orientation, paperDimensionWidth, paperDimensionHeight, isMobileWidth]);

  return <div data-testid="grid-lines" className="mapGridLines" ref={mapGridLinesRef} />;
};

GridLines.propTypes = {
  bounds: object,
  grid: string,
  mapZoom: number,
  paperSize: string,
  orientation: string,
  paperDimensionWidth: number,
  paperDimensionHeight: number,
  isMobileWidth: bool
};

GridLines.defaultProps = {
  bounds: {},
  grid: '',
  mapZoom: 0,
  paperSize: '',
  orientation: '',
  paperDimensionWidth: 0,
  paperDimensionHeight: 0,
  isMobileWidth: false
};

export { GridLines };
