import { featureCollection } from '@turf/helpers';
import { addLayer, addLayerEvent, fireLayerEvent, removeLayer } from '../layers/layer_helpers';
import { createLabelLayer } from '../layers/labels';
import { addOrUpdateGeojsonSource } from '../sources/geojson_helpers';
import { removeSource } from '../sources/source_helpers';

const trailPlannerLabelId = 'trailPlannerMap-nodes-drag-labels';
const getDraggableId = id => `${id}-drag`;

const makeLayerDraggable = (map, origLayer) => {
  const id = getDraggableId(origLayer.id);
  const sourceId = id;
  const layerId = id;

  // Init source
  const geojson = featureCollection([]);
  addOrUpdateGeojsonSource(map, sourceId, geojson);

  if (map.getLayer(layerId)) {
    return;
  }

  let draggingFeature = null;
  let origCoords = null;
  addLayer(map, { ...origLayer, id, source: sourceId });

  const showLabel = e => {
    const feature = e.features[0];
    if (!feature.properties.labelMessage) {
      return;
    }

    const draggableId = getDraggableId(id);
    addOrUpdateGeojsonSource(map, draggableId, feature);
    addLayer(map, createLabelLayer(`${draggableId}-labels`, draggableId, 'labelMessage', true));
  };

  const removeLabel = e => {
    removeLayer(map, `${getDraggableId(id)}-labels`);
  };

  const onDrag = e => {
    map.getCanvas().style.cursor = 'grabbing';

    // Update dragging source
    map.dragging = true;
    draggingFeature.geometry.coordinates = e.lngLat.toArray();
    addOrUpdateGeojsonSource(map, sourceId, draggingFeature);

    // Fire event
    e.draggingFeature = draggingFeature;
    fireLayerEvent(map, origLayer.id, 'draglayer', e);
  };

  const onDragEnd = e => {
    map.getCanvas().style.cursor = 'pointer';
    map.off('mousemove', onDrag);
    map.dragging = false;

    // Remove from dragging source
    addOrUpdateGeojsonSource(map, sourceId, featureCollection([]));

    // Add back into non-dragging source
    const origSource = map.getSource(origLayer.source);
    origSource._data.features.push(draggingFeature);

    // Ensure coordinates have changed by a meaningful amount in pixels,
    // otherwise keep the way it is and don't fire an event
    const origP = map.project(origCoords);
    const newP = e.point;
    const d = Math.abs(Math.sqrt((newP.x - origP.x) ** 2 + (newP.y - origP.y) ** 2));
    if (d > 8) {
      origSource.setData(origSource._data);
      // Fire event
      e.draggingFeature = draggingFeature;
      fireLayerEvent(map, origLayer.id, 'draglayerend', e);
      map.dragLayerEnd = true;
    } else {
      // Restore original coordinate
      draggingFeature.geometry.coordinates = origCoords;
      origSource.setData(origSource._data);
    }

    draggingFeature = null;
  };

  const onDragStart = e => {
    // Prevent conflicts with "shift" dragging
    // do not fire on a right click
    if (map.shiftKeyDown || e.originalEvent.button === 2) {
      return;
    }

    draggingFeature = e.features[0];
    if (!draggingFeature.properties.draggable) {
      return;
    }

    map.dragging = true;
    removeLayer(map, trailPlannerLabelId); // removes edge-case label
    e.preventDefault(); // prevents dragPane event
    map.getCanvas().style.cursor = 'grabbing';
    map.on('mousemove', onDrag);
    map.once('mouseup', onDragEnd);

    // Show on dragging source
    origCoords = draggingFeature.geometry.coordinates;
    addOrUpdateGeojsonSource(map, sourceId, draggingFeature);

    // Remove from non-dragging source
    const origSource = map.getSource(origLayer.source);
    origSource._data.features = origSource._data.features.filter(feature => feature.id !== draggingFeature.id);
    origSource.setData(origSource._data);

    // Fire event
    e.draggingFeature = draggingFeature;
    fireLayerEvent(map, origLayer.id, 'draglayerstart', e);
  };

  addLayerEvent(map, origLayer.id, true, 'mousedown', onDragStart);

  addLayerEvent(map, origLayer.id, true, 'mouseenter', showLabel);
  addLayerEvent(map, origLayer.id, true, 'mouseleave', removeLabel);
};

const undoMakeLayerDraggable = (map, layerId) => {
  const draggableId = getDraggableId(layerId);
  removeLayer(map, trailPlannerLabelId);
  removeLayer(map, draggableId);
  removeSource(map, draggableId);
};

export { getDraggableId, makeLayerDraggable, undoMakeLayerDraggable };
