import React, { useMemo } from 'react';
import { Provider } from 'react-redux';
import { getConditions, getMapWeather, getOverview, getWeatherInfo, updateDate, updateTime } from '@alltrails/redux/slices/weather';
import type { Context } from 'types/Context';
import { AllTrailsBounds } from '@alltrails/maps/types/Geo';
import Trail from 'types/Trails/Trail';
import useUser from 'hooks/useUser';
import { RootState, buildStore, useDispatch, ReduxContext } from '../redux';
import TrailWeatherBase from '../TrailWeatherBase';
import { TrailWeatherContext } from '../trailWeatherContext';

type TrailWeatherContextProviderProps = {
  trail: Trail;
  bounds: AllTrailsBounds;
  mapboxAccessToken: string;
};

const TrailWeatherContextProvider = ({ trail, bounds, mapboxAccessToken }: TrailWeatherContextProviderProps): JSX.Element => {
  const dispatch = useDispatch();
  const mapId = trail.verified_map_id; // for testing use 53272919;

  const contextValue = useMemo(
    () => ({
      getWeatherInfo: (state: RootState) => getWeatherInfo(state, mapId),
      getMapWeather: () => dispatch(getMapWeather({ mapId })),
      getConditions: () => dispatch(getConditions({ mapId })),
      getOverview: () => dispatch(getOverview({ mapId })),
      updateDate: (newDate: Date) => dispatch(updateDate({ mapId, newDate })),
      updateTime: (newTime: Date) => dispatch(updateTime({ mapId, newTime })),
      mapboxAccessToken,
      trail,
      bounds
    }),
    [dispatch, mapId, mapboxAccessToken, trail, bounds]
  );

  return (
    <TrailWeatherContext.Provider value={contextValue}>
      <TrailWeatherBase />
    </TrailWeatherContext.Provider>
  );
};

type TrailWeatherWrapperProps = {
  context: Context;
  trail: Trail;
  bounds: AllTrailsBounds;
  mapboxAccessToken: string;
};

const TrailWeatherWrapper = ({ context, trail, bounds, mapboxAccessToken }: TrailWeatherWrapperProps): JSX.Element => {
  const user = useUser();
  const store = buildStore({ context: { ...context, currentUser: user, proUser: !!user?.pro } });
  return (
    <Provider context={ReduxContext} store={store}>
      <TrailWeatherContextProvider trail={trail} bounds={bounds} mapboxAccessToken={mapboxAccessToken} />
    </Provider>
  );
};

export default React.memo(TrailWeatherWrapper);
