// extracted by mini-css-extract-plugin
export var activityTag = "styles-module__activityTag___sHw4x";
export var content = "styles-module__content___u3Ojr";
export var dateTrailDetails = "styles-module__dateTrailDetails___bTYrX";
export var description = "styles-module__description___sVcdp";
export var descriptive = "styles-module__descriptive___ot32_";
export var info = "styles-module__info___jMR_5";
export var infoTrailDetails = "styles-module__infoTrailDetails___rd4UC";
export var inlineBlock = "styles-module__inlineBlock___uyWzl";
export var largerMargins = "styles-module__largerMargins___qa_Rm";
export var lines3 = "styles-module__lines3___pXE6K";
export var link = "styles-module__link___yhpft";
export var marginTop = "styles-module__marginTop___FM6lP";
export var metadataContent = "styles-module__metadataContent___X4Enc";
export var name = "styles-module__name___u0S_n";
export var nameTrailDetails = "styles-module__nameTrailDetails___rTHi3";
export var starsDateTrailDetails = "styles-module__starsDateTrailDetails___rsRNB";
export var tagGroupContainer = "styles-module__tagGroupContainer____LoXu";
export var thin = "styles-module__thin___SHeMO";
export var trackNameDistance = "styles-module__trackNameDistance___TQFqC";
export var trailLink = "styles-module__trailLink___TDluv";