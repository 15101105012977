import IconButton from '@alltrails/shared/denali/components/IconButton';
import Print from '@alltrails/shared/icons/Print';
import { useIntl } from '@alltrails/shared/react-intl';
import logPrintMapButtonClicked from '@alltrails/analytics/events/logPrintMapButtonClicked';
import { wrapUrlSafe } from 'utils/language_support_util';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import { modalRoadblock } from '@alltrails/shared/utils/modalFunnelUtils';
import { getFormattedTrailUrls } from 'utils/trail_helpers';
import useUser from 'hooks/useUser';
import { PageStrings } from '@alltrails/shared/utils/constants/pageStringHelpers';
import { getMapLocation } from '@alltrails/maps/utils/amplitude';
import type Trail from 'types/Trails/Trail';
import useMobileWidth from 'hooks/useMobileWidth';

type Props = {
  buildPrintMapUrlParams: () => string;
  currentPage: string; // could be fixed in the monorepo to enum or as const
  selectedObject: Trail; // or other objects
};

function PrintButton({ buildPrintMapUrlParams, currentPage, selectedObject }: Props) {
  const intl = useIntl();
  const languageRegionCode = useLanguageRegionCode();
  const user = useUser();
  const isMobileWidth = useMobileWidth();

  function getPrintUrl() {
    let printUrl = null;

    if (currentPage === PageStrings.EXPLORE_TRAIL_MAP_PAGE && selectedObject) {
      const { url } = getFormattedTrailUrls(selectedObject, languageRegionCode, null, `/print?${buildPrintMapUrlParams()}`);
      printUrl = wrapUrlSafe(url, languageRegionCode);
    } else if (currentPage === PageStrings.EXPLORE_USERS_TRACKS_MAP_PAGE) {
      printUrl = wrapUrlSafe(`/explore/recording/${selectedObject.slug}/print?${buildPrintMapUrlParams()}`, languageRegionCode);
    } else if (currentPage === PageStrings.EXPLORE_USERS_MAPS_MAP_PAGE || currentPage === PageStrings.EXPLORE_LIFELINE_PAGE) {
      printUrl = wrapUrlSafe(`/explore/map/${selectedObject.slug}/print?${buildPrintMapUrlParams()}`, languageRegionCode);
    }

    return printUrl;
  }

  function handlePrintClick() {
    logPrintMapButtonClicked({ map_location: getMapLocation(window.location.pathname), trail_id: selectedObject.ID });

    if (isMobileWidth) {
      window.location.href = wrapUrlSafe('/mobile', languageRegionCode);
    } else if (user?.pro) {
      window.location.assign(getPrintUrl());
    } else if (user) {
      const returnToUrl = window.location.pathname + window.location.search;
      modalRoadblock('plus', 'explore-trail-print', returnToUrl, languageRegionCode);
    } else {
      const returnToUrl = window.location.pathname + window.location.search;
      modalRoadblock('signup', 'explore-trail-print', returnToUrl, languageRegionCode);
    }
  }

  return (
    <IconButton
      icon={{ Component: Print }}
      onClick={handlePrintClick}
      title={intl.formatMessage({ defaultMessage: 'Print map' })}
      testId="map-controls-print"
      variant="elevated"
    />
  );
}

export default PrintButton;
