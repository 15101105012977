import { FormattedMessage } from '@alltrails/shared/react-intl';
import isEqual from 'underscore/modules/isEqual';
import omit from 'underscore/modules/omit';
import Button from '@alltrails/shared/denali/components/Button';
import { getLines } from '@alltrails/maps/utils/legacyGeoJSONConversions';
import hasPermission from 'utils/hasPermission';
import { MapStatsUtil } from '../../../../utils/map_stats_util';
import { getMapDistance } from '../../../../utils/at_map_helpers';
import { saveAtMap } from '../../../../utils/requests/at_map_requests';
import { INSERT_TYPE, CUT_TYPE } from '../mixins/map/trail_planner_mixin';
import * as styles from './styles/styles.module.scss';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const createReactClass = require('create-react-class');

const VerifiedRouteContributeItem = createReactClass({
  getInitialState() {
    return {
      trailPlannerActive: false,
      showInstructions: false
    };
  },
  componentDidUpdate(prevProps) {
    // keep edits when adding or editing a waypoint
    const prevMapModelWithoutWaypoints = omit(prevProps.mapModel, ['waypoints', 'editWaypointId']);
    const currentMapModelWithoutWaypoints = omit(this.props.mapModel, ['waypoints', 'editWaypointId']);

    // Has GPX been uploaded (/trail/new)?
    if (!isEqual(prevMapModelWithoutWaypoints, currentMapModelWithoutWaypoints)) {
      this.disableTrailPlanner();
    }
  },
  componentWillUnmount() {
    this.props.messagingChannel.publish('edit.disable_trail_planner');
    this.props.messagingChannel.publish('edit.disable_insert_mode');
  },
  getDistance() {
    const { routeInfo, mapModel } = this.props;
    if (routeInfo) {
      return routeInfo.distance;
    }
    if (mapModel) {
      return getMapDistance(mapModel);
    }
    return 0;
  },
  getElevationGain() {
    const { routeInfo } = this.props;
    return routeInfo?.elevationGain || 0;
  },
  isTrailNewWithGivenTrack() {
    return this.props.rapidRoute && !this.props.trail.id;
  },
  getVREditData(relationId) {
    return {
      type: 'verified_route',
      data: {
        display_text: this.formatDistance(),
        move_th_to_start_pt: 'Y',
        use_description: 'Y',
        ignore_map_waypoints: 'Y',
        preserve_trail_waypoints: 'Y'
      },
      relation_id: relationId
    };
  },
  // eslint-disable-next-line react/no-unused-class-component-methods
  getNewEditData() {
    if (!this.state.trailPlannerActive && this.props.mapModel) {
      const { defaultMap } = this.props.trail;
      if (!defaultMap || defaultMap.id !== this.props.mapModel.id || this.isTrailNewWithGivenTrack()) {
        // If user uploaded a GPX, no need to create map before creating edit item.
        return this.getVREditData(this.props.mapModel.id);
      }
    }

    if (this.state.trailPlannerActive && this.getDistance() > 0.0 && this.props.routeInfo.hasChanged) {
      // Use the itemPostHandler callback option rather than returning an edit item.
      // This allows us to save the map, then save the edit item with the new map id,
      // without creating a synchronous block on the main JS thread.
      // Need to capture the route JSON now, because the map object holding it may not
      // be valid when the callback is called (race condition, story 103039636)
      const map = this.retainOriginalMapData(this.props.routeInfo.plannerMap, this.props.trail.defaultMap);
      return {
        itemPostHandler: this.itemPostHandler,
        itemPostHandlerData: { map }
      };
    }

    return null;
  },
  retainOriginalMapData(newMap, originalMap) {
    // Carry over specific, non-route-line data from the original map into the new one
    if (!originalMap) {
      return newMap;
    }
    const { description, rating, waypoints } = originalMap;
    return { description, rating, waypoints, ...newMap };
  },
  itemPostHandler(itemUrl, editItem, postEditItem, itemPostHandlerData) {
    const { map } = itemPostHandlerData;
    map.status = 'P';
    map.trail_id = this.props.trail.id;

    saveAtMap(map, { system_owned: 'true', detail: 'lite' })
      .then(newMap => {
        postEditItem(itemUrl, this.getVREditData(newMap.id));
      })
      .catch(e => {
        console.error('Map save failed: ', e);
      });
  },
  disableTrailPlanner() {
    if (this.props.insertRouteInfo) {
      this.props.messagingChannel.publish('edit.disable_insert_mode');
    }
    if (this.state.trailPlannerActive) {
      this.props.messagingChannel.publish('edit.disable_trail_planner');
      this.setState({ trailPlannerActive: false, showInstructions: false });
    }
  },
  enableTrailPlanner(loadVerifiedRoute) {
    if (!this.state.trailPlannerActive) {
      // Get single route to load into trail planner on init (from GPX upload or editing existing verifie route)
      let route;
      if (loadVerifiedRoute && this.props.mapModel) {
        const routes = getLines(this.props.mapModel);
        if (routes.length > 0) {
          // eslint-disable-next-line prefer-destructuring
          route = routes[0];
        }
      }
      // Enable and show instruction block briefly
      this.props.messagingChannel.publish('edit.enable_trail_planner', route);
      this.setState({ trailPlannerActive: true, showInstructions: true });
      setTimeout(() => {
        this.setState({ showInstructions: false });
      }, 30000); // 30 seconds
    }
  },
  enableInsertMode(type = INSERT_TYPE) {
    if (!this.props.insertRouteInfo) {
      this.props.messagingChannel.publish('edit.enable_insert_mode', type);
    }
  },
  handleEditClick() {
    this.enableTrailPlanner(true);
  },
  handleDrawClick() {
    this.enableTrailPlanner(false);
  },
  handleInsertClick() {
    this.enableInsertMode(INSERT_TYPE);
  },
  handleCutClick() {
    this.enableInsertMode(CUT_TYPE);
  },
  handleOutAndBackClick() {
    this.props.messagingChannel.publish('button.click.out_and_back');
  },
  handleReturnClick() {
    this.props.messagingChannel.publish('button.click.return');
  },
  handleReverseRouteClick() {
    this.props.messagingChannel.publish('button.click.reverse');
  },
  formatDistance(distance) {
    if (!distance) {
      distance = this.getDistance();
    }
    if (this.props.displayMetric) {
      return `Len: ${(distance > 0.0 ? distance / 1000.0 : 0.0).toFixed(1)}km`;
    }
    return `Len: ${(distance > 0.0 ? distance / 1609.344 : 0.0).toFixed(1)}mi`;
  },
  formatElevationGain() {
    if (this.props.displayMetric) {
      return `Elev. Gain: ${this.getElevationGain()}m`;
    }
    return `Elev. Gain: ${MapStatsUtil.metersToFeet(this.getElevationGain())}ft`;
  },
  renderCancelButton() {
    if (this.props.trail.id || this.isTrailNewWithGivenTrack()) {
      return (
        <Button
          text={this.props.intl.formatMessage({ defaultMessage: 'Cancel' })}
          onClick={this.disableTrailPlanner}
          testId="cancel-btn"
          type="button"
          className={styles.button}
          variant="default"
          size="sm"
        />
      );
    }
    return null;
  },
  renderOutAndBackButton() {
    const routeInfo = this.props.insertRouteInfo ? this.props.insertRouteInfo : this.props.routeInfo;
    return (
      <Button
        text={this.props.intl.formatMessage({ defaultMessage: 'Double-back' })}
        onClick={this.handleOutAndBackClick}
        testId="double-back-btn"
        type="button"
        className={styles.button}
        variant="default"
        disabled={!routeInfo || !routeInfo.rtsAvailable}
        size="sm"
      />
    );
  },
  renderReturnButton() {
    const routeInfo = this.props.insertRouteInfo ? this.props.insertRouteInfo : this.props.routeInfo;
    return (
      <Button
        text={this.props.intl.formatMessage({ defaultMessage: 'Close loop' })}
        onClick={this.handleReturnClick}
        testId="close-loop-btn"
        type="button"
        className={styles.button}
        variant="default"
        disabled={!routeInfo || !routeInfo.rtsAvailable}
        size="sm"
      />
    );
  },
  renderReverseButton() {
    const disabled = this.props.insertRouteInfo?.rtsAvailable;
    return (
      <Button
        text={this.props.intl.formatMessage({ defaultMessage: 'Reverse' })}
        onClick={this.handleReverseRouteClick}
        testId="reverse-btn"
        type="button"
        className={styles.button}
        variant="default"
        disabled={disabled}
        size="sm"
      />
    );
  },
  renderEditRouteButton() {
    return (
      <Button
        onClick={this.handleEditClick}
        className={styles.button}
        text={this.props.intl.formatMessage({ defaultMessage: 'Edit route' })}
        testId="edit-route-btn"
        size="sm"
        variant="default"
      />
    );
  },
  renderDrawRouteButton() {
    return (
      <Button
        text={this.props.intl.formatMessage({ defaultMessage: 'Draw route' })}
        onClick={this.handleDrawClick}
        testId="draw-route"
        type="button"
        variant="default"
        size="sm"
      />
    );
  },
  renderInsertButton() {
    return (
      this.props.hasAdminFeatures && (
        <Button
          text={this.props.intl.formatMessage({ defaultMessage: 'Insert' })}
          onClick={this.handleInsertClick}
          testId="insert-btn"
          type="button"
          variant="default"
          className={styles.button}
          size="sm"
          disabled={!!this.props.insertRouteInfo || !this.props.routeInfo.selectedNode || this.props.routeInfo.selectedNode?.position === 'all'}
        />
      )
    );
  },
  renderCutButton() {
    return (
      this.props.hasAdminFeatures && (
        <Button
          text={this.props.intl.formatMessage({ defaultMessage: 'Cut' })}
          onClick={this.handleCutClick}
          testId="cut-btn"
          type="button"
          className={styles.button}
          variant="default"
          size="sm"
          disabled={!!this.props.insertRouteInfo || !this.props.routeInfo.selectedNode || this.props.routeInfo.selectedNode.id === 0}
        />
      )
    );
  },
  renderButtons() {
    if (this.state.trailPlannerActive) {
      return (
        <div className={styles.buttonContainer}>
          <div className={styles.buttons}>
            {this.renderCancelButton()}
            {this.renderOutAndBackButton()}
            {this.renderReturnButton()}
            {!this.props.hasAdminFeatures && this.renderReverseButton()}
          </div>
          <div>
            {this.props.hasAdminFeatures && this.renderReverseButton()}
            {this.renderInsertButton()}
            {this.renderCutButton()}
          </div>
        </div>
      );
    }
    return (
      <div className={styles.buttons}>
        {this.renderEditRouteButton()}
        {this.renderDrawRouteButton()}
      </div>
    );
  },
  renderInstructions() {
    if (!hasPermission({ permission: 'trails:manage' })) {
      return (
        <div id="verified-route-instructions" className={this.state.showInstructions ? '' : 'hidden'}>
          <FormattedMessage
            defaultMessage="When adding new trails, we are specifically looking for trails that start and end at a parking area and are on public property. Our strong preference is for out-and-back or loop trails that begin and end in the same location, but of course some trails are through trails and they are welcome too. Please note that any suggested trail additions must first be evaluated by our moderators. This process may take a while depending on our ability to verify the information. To read more about how to draw a route click <a>here</a>."
            values={{
              // eslint-disable-next-line react/no-unstable-nested-components
              a: linkText => (
                <a target="_blank" href="https://alltrails.zendesk.com/knowledge/articles/360019245911/en-us?brand_id=360001491372">
                  {linkText}
                </a>
              )
            }}
          />
        </div>
      );
    }
    return null;
  },
  render() {
    return (
      <div>
        {this.renderButtons()}
        {this.renderInstructions()}
        {this.state.trailPlannerActive && (
          <div className="length-and-elevation">
            <div>{this.formatDistance()}</div>
            {hasPermission({ permission: 'trails:manage' }) && (
              <div>
                {this.formatElevationGain()} (<FormattedMessage defaultMessage="approx" />
                .)
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
});

// eslint-disable-next-line import/prefer-default-export
export { VerifiedRouteContributeItem };
