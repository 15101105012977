/**
 * key `code` values
 * https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/code
 */
enum Key {
  ArrowDown = 'ArrowDown',
  ArrowLeft = 'ArrowLeft',
  ArrowRight = 'ArrowRight',
  ArrowUp = 'ArrowUp',
  Backspace = 'Backspace',
  Enter = 'Enter',
  Equal = 'Equal',
  Minus = 'Minus',
  Space = 'Space'
}

export default Key;
