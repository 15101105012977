import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '@alltrails/shared/react-intl';
import useCountryCode from 'hooks/useCountryCode';
import IntlTelInput from 'react-intl-tel-input';
import * as styles from './styles.module.scss';
import 'react-intl-tel-input/dist/main.css';

function Phone({ classes, error, onChange }) {
  const countryCode = useCountryCode();

  return (
    <>
      <IntlTelInput
        containerClassName={classNames('intl-tel-input', classes)}
        preferredCountries={[countryCode]}
        inputClassName={styles.phone}
        onPhoneNumberChange={onChange}
      />
      {error && (
        <span className={styles.error}>
          <FormattedMessage defaultMessage="There was a problem sending the text." />
          <br />
          <FormattedMessage defaultMessage="Please check the number and try again." />
        </span>
      )}
    </>
  );
}

Phone.defaultProps = {
  classes: undefined,
  error: false
};

Phone.propTypes = {
  classes: PropTypes.string,
  error: PropTypes.bool,
  onChange: PropTypes.func.isRequired
};

export default Phone;
