import { lazy, Suspense } from 'react';
import { FormattedMessage, useIntl } from '@alltrails/shared/react-intl';
import SharedSearchFilter from '@alltrails/shared/components/SearchFilter';
import useModalPortalElement from '@alltrails/shared/hooks/useModalPortalElement';
import type { User } from 'types/User';
import useFiltersContext from 'hooks/useFiltersContext';
import { FilterViewModel, FilterConfig, FilterType } from 'types/Search';
import getSaveButtonChildrenProps from '../saveButtonChildrenProps';

const FilterInputFactory = lazy(() => import('components/explore/filters/FilterInputFactory'));

export type Props = {
  filter: FilterViewModel;
  popperClassName?: string;
  user?: User;
};

const getSubText = (filterKey: string) => {
  if (filterKey === FilterType.DISTANCE_AWAY) {
    return <FormattedMessage defaultMessage="See trails based on your current location" />;
  }
  return null;
};

const SearchFilter = ({ filter, popperClassName, user }: Props) => {
  const intl = useIntl();

  const {
    appliedFilterLabelsByKey,
    clearAppliedFilters,
    clearDraftChanges,
    computedResultsCount,
    filtersByKey,
    isLoadingHypotheticalResultsCount,
    resetHypotheticalResultsCount,
    saveChanges,
    updateFilter
  } = useFiltersContext();

  const modalElement = useModalPortalElement();

  return (
    <SharedSearchFilter
      onClear={() => {
        clearAppliedFilters([filter.key]);
        resetHypotheticalResultsCount();
      }}
      selectedItems={appliedFilterLabelsByKey[filter.key] || []}
      modalElement={modalElement}
      modalTitle={filter.display}
      modalDescription={getSubText(filter.key)}
      unselectedText={filter.display}
      popperClassName={popperClassName}
      onDismiss={() => {
        clearDraftChanges();
        resetHypotheticalResultsCount();
      }}
      saveButtonChildren={getSaveButtonChildrenProps({
        intl,
        isLoading: isLoadingHypotheticalResultsCount,
        resultsCount: computedResultsCount,
        disabled:
          (user === null && filter.enabledKey === FilterType.DISTANCE_AWAY) || (user && !user.pro && filter.enabledKey === FilterType.DISTANCE_AWAY)
      })}
      saveButtonHandler={saveChanges}
      hasPlus={filter.isProFilter}
      testIdPrefix={filter.key}
    >
      <Suspense fallback={null}>
        <FilterInputFactory filter={filtersByKey[filter.key]} onChange={(changes: FilterConfig) => updateFilter(filter.key, changes)} user={user} />
      </Suspense>
    </SharedSearchFilter>
  );
};

export default SearchFilter;
