// extracted by mini-css-extract-plugin
export var button = "TrailMoreAlternateDropDownList-module__button___It91a";
export var disabled = "TrailMoreAlternateDropDownList-module__disabled___lPCOO";
export var downloadModal = "TrailMoreAlternateDropDownList-module__downloadModal___TL_Zj";
export var dropdown = "TrailMoreAlternateDropDownList-module__dropdown___YG2yS";
export var dropdownContent = "TrailMoreAlternateDropDownList-module__dropdownContent___GUUDm";
export var dropdownItem = "TrailMoreAlternateDropDownList-module__dropdown-item___PrnuX";
export var gpxLabel = "TrailMoreAlternateDropDownList-module__gpxLabel___H5OIU";
export var plusBadge = "TrailMoreAlternateDropDownList-module__plusBadge___Z7mgz";
export var suggest = "TrailMoreAlternateDropDownList-module__suggest___emOwt";
export var trailDetails = "TrailMoreAlternateDropDownList-module__trail-details___CYRoV";