import { IntlShape, defineMessages } from '@alltrails/shared/react-intl';

export enum PaperSizes {
  LETTER = 'letter',
  LEGAL = 'legal',
  TABLOID = 'tabloid',
  A2 = 'a2',
  A3 = 'a3',
  A4 = 'a4',
  CUSTOM = 'custom'
}

export const IMPERIAL_SIZES = [PaperSizes.LETTER, PaperSizes.LEGAL, PaperSizes.TABLOID, PaperSizes.CUSTOM];
export const METRIC_SIZES = [PaperSizes.A2, PaperSizes.A3, PaperSizes.A4, PaperSizes.CUSTOM];

export const PAPER_SIZE_LABELS = defineMessages({
  letter: { defaultMessage: 'Letter' },
  legal: { defaultMessage: 'Legal' },
  tabloid: { defaultMessage: 'Tabloid' },
  a2: { defaultMessage: 'A2' },
  a3: { defaultMessage: 'A3' },
  a4: { defaultMessage: 'A4' },
  custom: { defaultMessage: 'Custom' }
});

export const DIMENSIONS = {
  [PaperSizes.LETTER]: [8.5, 11],
  [PaperSizes.LEGAL]: [8.5, 14],
  [PaperSizes.TABLOID]: [11, 17],
  [PaperSizes.A2]: [16.5, 23.2],
  [PaperSizes.A3]: [11.7, 16.5],
  [PaperSizes.A4]: [8.3, 11.7],
  [PaperSizes.CUSTOM]: [16, 16]
};

export const MIN_DIMENSION = 5.5;
export const MAX_DIMENSION = 16;

export enum Orientations {
  PORTRAIT = 'portrait',
  LANDSCAPE = 'landscape'
}

export const ORIENTATION_LABELS = defineMessages({
  portrait: { defaultMessage: 'Portrait' },
  landscape: { defaultMessage: 'Landscape' }
});

export enum Grids {
  DECIMAL = 'decimal',
  DMS = 'dms',
  UTM = 'utm',
  NONE = 'none'
}
export const GRID_LABELS = defineMessages({
  decimal: { defaultMessage: 'Decimal' },
  dms: { defaultMessage: 'D°M’S”' },
  utm: { defaultMessage: 'UTM' },
  none: { defaultMessage: 'None' }
});

export const scales = (intl: IntlShape) => ({
  // # 19: '1:1k',
  18: intl.formatMessage({ defaultMessage: '1:2k' }),
  17: intl.formatMessage({ defaultMessage: '1:4k' }),
  '-5': intl.formatMessage({ defaultMessage: '1:5k Exact' }),
  16: intl.formatMessage({ defaultMessage: '1:9k' }),
  '-6': intl.formatMessage({ defaultMessage: '1:10k Exact' }),
  15: intl.formatMessage({ defaultMessage: '1:18k' }),
  '-1': intl.formatMessage({ defaultMessage: '1:24k Exact' }),
  '-2': intl.formatMessage({ defaultMessage: '1:25k Exact' }),
  14: intl.formatMessage({ defaultMessage: '1:36k' }),
  '-3': intl.formatMessage({ defaultMessage: `1:50k Exact` }),
  13: intl.formatMessage({ defaultMessage: '1:72k' }),
  '-4': intl.formatMessage({ defaultMessage: '1:100k Exact' }),
  12: intl.formatMessage({ defaultMessage: '1:144k' }),
  11: intl.formatMessage({ defaultMessage: '1:289k' }),
  10: intl.formatMessage({ defaultMessage: '1:578k' }),
  9: intl.formatMessage({ defaultMessage: '1:1m' }),
  8: intl.formatMessage({ defaultMessage: '1:2m' }),
  7: intl.formatMessage({ defaultMessage: '1:5m' }),
  6: intl.formatMessage({ defaultMessage: '1:9m' }),
  5: intl.formatMessage({ defaultMessage: '1:18m' }),
  4: intl.formatMessage({ defaultMessage: '1:37m' }),
  3: intl.formatMessage({ defaultMessage: '1:74m' }),
  2: intl.formatMessage({ defaultMessage: '1:148m' })
  // # 1:  '1:296m',
});
