import { loadAndAddImage } from '../image_helpers';
import logError from 'utils/logError';
import blue from '../../../../assets/images/icons/maps/clusters/map-cluster-blue@2x.png';
import brown from '../../../../assets/images/icons/maps/clusters/map-cluster-brown@2x.png';
import gray from '../../../../assets/images/icons/maps/clusters/map-cluster-gray@2x.png';
import green from '../../../../assets/images/icons/maps/clusters/map-cluster-green@2x.png';
import purple from '../../../../assets/images/icons/maps/clusters/map-cluster-purple@2x.png';
import red from '../../../../assets/images/icons/maps/clusters/map-cluster-red@2x.png';
import teal from '../../../../assets/images/icons/maps/clusters/map-cluster-teal@2x.png';
import debuggerClusterImage from '../../../../assets/images/icons/maps/clusters/map-cluster-debugger@2x.png';
import { BLUE, BROWN, GRAY, GREEN, PURPLE, RED, TEAL, DEBUGGER } from '../../constants/PinColor';

const clusterImageId = color => {
  return `map-cluster-${color}`;
};

function getClusterSrc(color) {
  switch (color) {
    case BLUE:
      return blue;
    case BROWN:
      return brown;
    case GRAY:
      return gray;
    case PURPLE:
      return purple;
    case GREEN:
      return green;
    case RED:
      return red;
    case TEAL:
      return teal;
    case DEBUGGER:
      return debuggerClusterImage;
    default:
      throw Error(`missing cluster src for color '${color}'`);
  }
}

const addClusterImageToMap = (map, color) => {
  const imageId = clusterImageId(color);
  const imageSrc = getClusterSrc(color);
  return loadAndAddImage(map, imageId, imageSrc, {
    pixelRatio: 2
  }).catch(logError);
};

const createClusterLayer = (id, sourceId, color, opacity) => {
  return {
    id,
    type: 'symbol',
    source: sourceId,
    filter: ['has', 'point_count'],
    layout: {
      'text-allow-overlap': true,
      'icon-allow-overlap': true,
      // The offset was found manually with the DEBUGGER cluster icon to help align things ideally on the map.
      // The debugger and cluster should both be centered over the lat/lng of the data.
      'icon-offset': [GREEN, TEAL].includes(color) ? [0, 2] : [0, 0],
      'icon-ignore-placement': true,
      'icon-image': ['image', clusterImageId(color)],
      'icon-size': 1,
      'text-field': '{point_count_abbreviated}',
      'text-font': ['Manrope Bold'],
      'text-size': 13
    },
    paint: {
      'text-color': 'white',
      'icon-opacity': opacity || 1
    }
  };
};

export { addClusterImageToMap, createClusterLayer };
