import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import LanguageRegionCode from '@alltrails/shared/types/LanguageRegionCode';
import type { Context } from 'types/Context';

const initialState: Context = {
  currentUser: null,
  proUser: false,
  mobileBrowser: false,
  tabletBrowser: false,
  displayMetric: false,
  languageRegionCode: LanguageRegionCode.English,
  primaryAlgoliaIndexId: '',
  alertsAlgoliaIndexId: ''
};

export const contextSlice = createSlice({
  name: 'context',
  initialState,
  reducers: {
    setContext: (state, action: PayloadAction<Context>) => {
      state = action.payload;

      return state;
    }
  }
});

export const { setContext } = contextSlice.actions;

export default contextSlice.reducer;
