// We only ever use uid and name, so we can use a simple type here
export type Uid = string;
export type Attribute = {
  uid: Uid;
  name: string;
};
export type AttributeMap = Record<Uid, Attribute>;

// Create a map of Uid to Attribute for a list of attributes
export const reduceAttributes = (map: AttributeMap, attribute: Attribute) => {
  map[attribute.uid] = attribute;
  return map;
};

// Remove a given set of attributes from a list
export const filterAttributes = (attrList: Attribute[], removeAttrList: Uid[]) => {
  const attrs = attrList.filter(item => removeAttrList.includes(item.uid) === false);

  return attrs.sort((a, b) => {
    if (a.uid < b.uid) {
      return -1;
    }

    if (a.uid > b.uid) {
      return 1;
    }

    return 0;
  });
};
