import { useContext, useMemo } from 'react';
import { defineMessages, useIntl } from '@alltrails/shared/react-intl';
import Select from '@alltrails/shared/denali/components/Select';
import useFormatMessage from '@alltrails/shared/hooks/useFormatMessage';

import CustomProvider from 'components/CustomProvider';
import LearnMore from '../LearnMore';
import { PrintContext } from '../../printContext';
import { scales } from '../../constants';

const SCALE_STRINGS = defineMessages({
  SCALE: { defaultMessage: 'Scale' },
  CONTENT: {
    defaultMessage:
      "The scale of a map is the ratio of a distance on the map to the corresponding distance on the ground. This simple concept is complicated by the curvature of the Earth's surface, which forces scale to vary across a map. Because of this variation, the concept of scale becomes meaningful in two distinct ways."
  }
});

type Props = {
  isMobileWidth: boolean;
};

const Scale = ({ isMobileWidth }: Props): JSX.Element => {
  const {
    formattedDefaultMessages: { SCALE, CONTENT }
  } = useFormatMessage(SCALE_STRINGS);
  const intl = useIntl();
  const { scale, setScale } = useContext(PrintContext);

  const options = useMemo(() => Object.entries(scales(intl)).map(([value, label]) => ({ value, label })), []);
  return (
    <CustomProvider>
      <div className="scaleContainer">
        <Select
          labelText=""
          placeholder=""
          options={options}
          onChange={value => setScale(parseInt(value))}
          value={`${scale}`}
          testId="printpage-scale-select"
        />
        <LearnMore title={SCALE} content={CONTENT} isMobileWidth={isMobileWidth} />
      </div>
    </CustomProvider>
  );
};

export default Scale;
