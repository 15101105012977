import { ReactNode } from 'react';
import classNames from 'classnames';
import logTrailDetailsAddPhotoClicked from '@alltrails/analytics/events/logTrailDetailsAddPhotoClicked';
import logTrailDetailsAddToCompletedClicked from '@alltrails/analytics/events/logTrailDetailsAddToCompletedClicked';
import logTrailDetailsUploadActivityClicked from '@alltrails/analytics/events/logTrailDetailsUploadActivityClicked';
import logTrailDetailsWriteReviewClicked from '@alltrails/analytics/events/logTrailDetailsWriteReviewClicked';
import type { TrailId } from '@alltrails/shared/types/trail';
import { modalRoadblock } from '@alltrails/shared/utils/modalFunnelUtils';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import useTrailCompleted from 'hooks/useTrailCompleted';
import * as styles from './styles.module.scss';
import ContributeButton from '../ContributeButton';
import TrailUgcBarInvitation from './TrailUgcBarInvitation';
import RatingsBreakdown from '../types/RatingsBreakdown';
import TrailUgcTab from '../types/TrailUgcTab';

export type TrailUgcBarProps = {
  activeTab: TrailUgcTab;
  itemCount: number;
  loggedInUserId?: boolean;
  trailId?: TrailId;
  ratingsBreakdown?: RatingsBreakdown;
  avgRating: string;
  showContributeButton?: boolean;
  showInvitation?: boolean;
  ugcSortDropDown: ReactNode;
  openTrailFormModal: () => void;
};

const TrailUgcBar = ({
  activeTab,
  itemCount,
  loggedInUserId,
  ratingsBreakdown,
  avgRating,
  showContributeButton = true,
  showInvitation = true,
  trailId,
  ugcSortDropDown,
  openTrailFormModal
}: TrailUgcBarProps): JSX.Element => {
  const languageRegionCode = useLanguageRegionCode();
  const { connectionId: completedConnectionId, toggleTrailCompleted } = useTrailCompleted(trailId);

  const onAddToCompletedClick = () => {
    logTrailDetailsAddToCompletedClicked({ trail_id: trailId });
    if (!loggedInUserId) {
      const returnToUrl = window.location.pathname + window.location.search;
      modalRoadblock('signup', 'trail-more-completed', returnToUrl, languageRegionCode);
      return;
    }
    toggleTrailCompleted();
  };

  const onContributeClicked = (tab: TrailUgcTab) => {
    const analyticsAttr = { trail_id: trailId };
    if (trailId) {
      switch (tab) {
        case 'reviews':
          logTrailDetailsWriteReviewClicked(analyticsAttr);
          break;
        case 'photos':
          logTrailDetailsAddPhotoClicked(analyticsAttr);
          break;
        case 'tracks':
          logTrailDetailsUploadActivityClicked(analyticsAttr);
          break;
        case 'completed':
          onAddToCompletedClick();
          return;
        default:
        // do nothing
      }
    }

    if (!loggedInUserId) {
      const returnToUrl = window.location.pathname + window.location.search;
      modalRoadblock('signup', `trail-contribute-${tab}`, returnToUrl, languageRegionCode);
      return;
    }

    openTrailFormModal();
  };

  return (
    <div className={classNames(styles.headerContainer, { [styles.borderBottom]: activeTab !== 'reviews' })}>
      <div className={classNames(styles.contributeInfo, { [styles.reviewsContainer]: activeTab === 'reviews' })}>
        <TrailUgcBarInvitation
          activeTab={activeTab}
          itemCount={itemCount}
          avgRating={avgRating}
          ratingsBreakdown={ratingsBreakdown}
          showInvitation={showInvitation}
          trailId={trailId}
        />
        {showInvitation && showContributeButton && (
          <ContributeButton completedConnectionId={completedConnectionId} contributeType={activeTab} clickHandler={onContributeClicked} />
        )}
      </div>
      {activeTab !== 'reviews' && (
        <div className={styles.ugcActions}>
          {ugcSortDropDown}
          {!showInvitation && showContributeButton && (
            <ContributeButton
              className={styles.contributeButton}
              completedConnectionId={completedConnectionId}
              contributeType={activeTab}
              clickHandler={onContributeClicked}
              width="auto"
            />
          )}
        </div>
      )}
    </div>
  );
};

export default TrailUgcBar;
