import Tent from '@alltrails/shared/illustrations/Tent';
import Button from '@alltrails/shared/denali/components/Button';
import Typography from '@alltrails/shared/denali/components/Typography';
import { FormattedMessage } from '@alltrails/shared/react-intl';

import * as styles from './errors.module.scss';

// https://www.figma.com/file/XrlDM2ZdWXmlnl7ZXUicGn/Rebrand%E2%80%93Press%2FAbout%2F404?node-id=1852%3A10892&t=GWwcYGHiwn1QTjiA-4
const FourOFourHeader = () => (
  <div className={styles.container}>
    <Tent />
    <div className={styles.error}>
      <Typography component="div" variant="heading500">
        <FormattedMessage defaultMessage="404" />
      </Typography>
    </div>
    <div className={styles.header}>
      <Typography component="div" variant="heading600">
        <FormattedMessage defaultMessage="We've reached the end of the trail" />
      </Typography>
    </div>
    <div className={styles.textContainer}>
      <div className={styles.text}>
        <Typography component="div" variant="paragraph300">
          <FormattedMessage defaultMessage="The page you're looking for either doesn't exist or has a new link. Let's get you back on the right path." />
        </Typography>
      </div>
    </div>
    <div className={styles.buttonContainer}>
      <Button
        text={<FormattedMessage defaultMessage="Find your next trail" />}
        linkInfo={{ href: '/explore' }}
        type="button"
        size="md"
        testId="FourOFour-explore"
        variant="primary"
      />
    </div>
  </div>
);

export default FourOFourHeader;
