import { featureCollection } from '@turf/helpers';
import { distanceMarkersGeojson } from '../sources/distance_markers';
import { addDistanceMarkerImagesToMap, createDistanceMarkerLayer } from '../layers/distance_markers';
import { addOrUpdateGeojsonSource } from '../sources/geojson_helpers';
import { addLayer, removeLayer } from '../layers/layer_helpers';
import { removeSource } from '../sources/source_helpers';

const distanceMarkersId = 'distance-markers';

const addDistanceMarkers = (map, featureCollection) => {
  const sourceId = distanceMarkersId;
  const layerId = distanceMarkersId;

  // Init source
  const geojson = featureCollection ? distanceMarkersGeojson(featureCollection, map.context.displayMetric) : featureCollection([]);
  addOrUpdateGeojsonSource(map, sourceId, geojson);

  if (map.getLayer(layerId)) {
    return;
  }

  // Add images to map
  addDistanceMarkerImagesToMap(map);

  // Add layers to map
  addLayer(map, createDistanceMarkerLayer(layerId, sourceId));
};

const removeDistanceMarkers = map => {
  removeLayer(map, distanceMarkersId);
  removeSource(map, distanceMarkersId);
};

export { addDistanceMarkers, removeDistanceMarkers };
