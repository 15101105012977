export const aggRatingDataAttributes = {
  itemProp: 'aggregateRating',
  itemScope: true,
  itemType: 'http://schema.org/AggregateRating'
};

type AggRatingStructuredDataProps = {
  rating: string | number;
  reviewCount?: string | number;
};

const AggRatingStructuredData = ({ rating, reviewCount = 1 }: AggRatingStructuredDataProps): JSX.Element => (
  <>
    <meta itemProp="ratingValue" content={String(rating)} />
    <meta itemProp="worstRating" content="0" />
    <meta itemProp="bestRating" content="5" />
    <meta itemProp="reviewCount" content={String(reviewCount)} />
  </>
);

export default AggRatingStructuredData;
