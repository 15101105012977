import LoadingSpinner from '@alltrails/shared/components/LoadingSpinner';
import * as styles from './styles/styles.module.scss';

const TrailUgcLoading = (): JSX.Element => (
  <div className={styles.loadingContainer}>
    <LoadingSpinner />
  </div>
);

export default TrailUgcLoading;
