import { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import logTrailDetailsConditionTapped from '@alltrails/analytics/events/logTrailDetailsConditionTapped';
import logTrailDetailsConditionsReportsTapped from '@alltrails/analytics/events/logTrailDetailsConditionsReportsTapped';
import ConditionCard from '@alltrails/shared/components/ConditionCard';
import Button from '@alltrails/shared/denali/components/Button';
import River from 'components/shared/River';
import useMobileWidth from 'hooks/useMobileWidth';
import { humanizeTimeSpan } from '@alltrails/shared/utils/timeHelpers';
import { TrailCondition, TrailConditionUid } from 'types/Trails/TrailCondition';
import * as styles from './styles/styles.module.scss';
import convertTrailConditionUidToAmplitude from '../TrailConditionsReviewsModal/utils/convertTrailConditionUidToAmplitude';
import TrailConditionsIcon from './TrailConditionsIcon';
import { useIntl } from '@alltrails/shared/react-intl';

type TrailConditionsInfoProps = {
  conditions: TrailCondition[];
  onSeeConditionDetailsClick: () => void;
  onConditionCardClick: (conditionUid: TrailConditionUid) => void;
  exploreFlag?: boolean;
};

const TrailConditionsInfo = ({
  conditions,
  onSeeConditionDetailsClick,
  onConditionCardClick,
  exploreFlag
}: TrailConditionsInfoProps): JSX.Element => {
  const isMobileWidth = useMobileWidth();
  const intl = useIntl();

  const onConditionClick = useCallback(
    (conditionUid: TrailConditionUid, idx: number) => {
      logTrailDetailsConditionTapped({ condition_selected: convertTrailConditionUidToAmplitude(conditionUid), position: idx });
      onConditionCardClick(conditionUid);
    },
    [onConditionCardClick]
  );

  const onSeeConditionsClicked = useCallback(() => {
    logTrailDetailsConditionsReportsTapped({
      conditions_displayed: conditions.map(condition => convertTrailConditionUidToAmplitude(condition.uid)),
      num_conditions_displayed: conditions.length
    });
    onSeeConditionDetailsClick();
  }, [conditions, onSeeConditionDetailsClick]);

  const conditionCards = useMemo(
    () =>
      conditions.map((condition: TrailCondition, idx: number) => (
        <ConditionCard
          key={condition.uid}
          onClick={() => onConditionClick(condition.uid, idx)}
          icon={<TrailConditionsIcon trailConditionUid={condition.uid} />}
          header={`${condition.name} (${condition.count})`}
          subHeader={humanizeTimeSpan(condition.lastMentionedAt)}
          className={isMobileWidth && !exploreFlag ? styles.fullCardWidth : ''}
        />
      )),
    [conditions, exploreFlag, isMobileWidth, onConditionClick]
  );

  /* NOTE: no translations needed for now, EN/US only feature */
  return (
    <>
      {isMobileWidth && !exploreFlag ? (
        <River className={styles.conditionsRiver} trackClassName={styles.conditionsRiverTrack}>
          {conditionCards}
        </River>
      ) : (
        <div className={classNames(styles.conditionsContainer, { [styles.conditionsSideView]: exploreFlag })}>{conditionCards}</div>
      )}
      <Button text="See condition reports" onClick={onSeeConditionsClicked} testId="trail-conditions-see-conditions-reports" />
    </>
  );
};

export default TrailConditionsInfo;
