enum TrailDetailsTabKey {
  Description = 'description',
  Waypoints = 'waypoints',
  Contact = 'contact_info',
  Tips = 'tips',
  Facilities = 'parking_facilities',
  Alerts = 'alerts',
  AdminNotes = 'admin notes',
  GettingThere = 'getting_there',
  EditHistory = 'edit_history',
  Accessibility = 'accessibility'
}

export default TrailDetailsTabKey;
