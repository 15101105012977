import useCurrentGeoLocation from 'hooks/useCurrentGeoLocation';
import { createContext, useMemo, useState } from 'react';
import { ReactNode } from 'react';
import type GeoLocation from '@alltrails/shared/types/Location/GeoLocation';
import geoLocationOptions from 'utils/constants/GeoLocationOptions';

type GeoLocationValue = {
  geoLocation: GeoLocation;
  getGeoLocation: () => void;
};

const GeoLocationContext = createContext<GeoLocationValue>({
  geoLocation: {} as GeoLocation,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  getGeoLocation: () => {}
});

type Props = {
  children: ReactNode;
};

function GeoLocationProvider({ children }: Props) {
  const [isEnabled, setIsEnabled] = useState(false);

  const { geoLocation } = useCurrentGeoLocation(geoLocationOptions, isEnabled);

  const value = useMemo(() => ({ geoLocation, getGeoLocation: () => setIsEnabled(true) }), [geoLocation]);

  return <GeoLocationContext.Provider value={value}>{children}</GeoLocationContext.Provider>;
}

export { GeoLocationProvider, GeoLocationContext };
