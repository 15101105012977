import { useEffect, useRef } from 'react';
import logTrailDetailsReviewsViewed from '@alltrails/analytics/events/logTrailDetailsReviewsViewed';
import ReviewsDashboard from '@alltrails/modules/TrailReviews/ReviewsDashboard';
import { FormattedMessage } from '@alltrails/shared/react-intl';
import useIsVisible from '@alltrails/shared/hooks/useIsVisible';
import type { TrailId } from '@alltrails/shared/types/trail';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import TrailUgcTab from '../types/TrailUgcTab';
import RatingsBreakdown from '../types/RatingsBreakdown';

import * as styles from './styles.module.scss';

type TrailUgcBarInvitationProps = {
  activeTab: TrailUgcTab;
  itemCount: number;
  avgRating: string;
  ratingsBreakdown?: RatingsBreakdown;
  showInvitation: boolean;
  trailId?: TrailId;
};

const TrailUgcBarInvitation = ({
  activeTab,
  itemCount,
  avgRating,
  ratingsBreakdown,
  showInvitation,
  trailId
}: TrailUgcBarInvitationProps): JSX.Element => {
  const languageRegionCode = useLanguageRegionCode();
  const ref = useRef<HTMLDivElement>();
  const isVisible = useIsVisible(ref, { threshold: 1 });

  useEffect(() => {
    if (isVisible && ref.current && trailId) {
      logTrailDetailsReviewsViewed({ trail_id: trailId });
    }
  }, [isVisible, trailId]);

  if (!showInvitation) {
    return null;
  }

  let templateString: React.ReactNode;
  switch (activeTab) {
    case 'reviews':
      if (!ratingsBreakdown) {
        return null;
      }
      return (
        <div ref={ref}>
          <ReviewsDashboard itemCount={itemCount} avgRating={avgRating} languageRegionCode={languageRegionCode} ratingsBreakdown={ratingsBreakdown} />
        </div>
      );
    case 'photos':
      return (
        <div className={styles.invitationContainer}>
          <h5 className={styles.invitationHeader}>
            <FormattedMessage defaultMessage="Add photos of this trail" />
          </h5>
          <p className={styles.invitationText}>
            <FormattedMessage defaultMessage="Photos help others preview the trail. Upload photos about this trail to inspire others." />
          </p>
        </div>
      );
    case 'tracks':
      return (
        <div className={styles.invitationContainer}>
          <h5 className={styles.invitationHeader}>
            <FormattedMessage defaultMessage="Share your route with others" />
          </h5>
          <p className={styles.invitationText}>
            <FormattedMessage defaultMessage="Help other users find their next route. Upload your activity and inspire others." />
          </p>
        </div>
      );
    case 'completed':
      templateString =
        // eslint-disable-next-line no-nested-ternary
        itemCount === 0 ? (
          <FormattedMessage defaultMessage="Use Navigator in the AllTrails app and be the first outdoor explorer who has completed this trail." />
        ) : itemCount === 1 ? (
          <FormattedMessage defaultMessage="Use Navigator in the AllTrails app and join the other outdoor explorer who has completed this trail." />
        ) : (
          <FormattedMessage
            defaultMessage="Use Navigator in the AllTrails app and join the {completedCount} other outdoor explorers who have completed this trail."
            values={{ completedCount: itemCount }}
          />
        );

      return (
        <div className={styles.invitationContainer}>
          <h5 className={styles.invitationHeader}>
            <FormattedMessage defaultMessage="Join the list" />
          </h5>
          <p className={styles.invitationText}>{templateString}</p>
        </div>
      );
    default:
      return null;
  }
};

export default TrailUgcBarInvitation;
