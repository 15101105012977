import { lazy, Suspense, useState } from 'react';
import Share from '@alltrails/shared/icons/Share';
import { COLOR_INTERACTIVE_DEFAULT } from '@alltrails/shared/denali/tokens';
import logTrailDetailsShareButtonTapped from '@alltrails/analytics/events/logTrailDetailsShareButtonTapped';
import useUser from 'hooks/useUser';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import useLanguageCode from '@alltrails/shared/hooks/useLanguageCode';
import type Trail from 'types/Trails/Trail';
import useToggle from '@alltrails/shared/hooks/useToggle';
import { useIntl } from '@alltrails/shared/react-intl';
import { NoSsr } from '@material-ui/core';
import CarouselDisplayTrigger from '@alltrails/analytics/enums/CarouselDisplayTrigger';
import ActionBarItem from '../ActionBarItem';

const SignupModal = lazy(() => import('components/SignupModal'));
const ShareModal = lazy(() => import('../../application/javascripts/react_components/main_action_bar/share_modal'));

type SharePopupButtonProps = {
  className?: string;
  shareObject: unknown;
  trail?: Trail;
  onClick?: () => void;
};

function SharePopupButton({ className, shareObject, trail, onClick }: SharePopupButtonProps): JSX.Element {
  const intl = useIntl();
  const [isOpen, toggle] = useToggle();
  const languageCode = useLanguageCode();
  const languageRegionCode = useLanguageRegionCode();
  const user = useUser();
  const [showShareModal, setShowShareModal] = useState(false);

  const hideShareModal = () => {
    setShowShareModal(false);
  };

  return (
    <>
      <NoSsr>
        <Suspense fallback={null}>
          <SignupModal
            trigger={CarouselDisplayTrigger.ShareTrail}
            isOpen={isOpen}
            onRequestClose={() => {
              toggle();
            }}
            onSuccess={() => {
              toggle();
              setShowShareModal(true);
            }}
          />
        </Suspense>
      </NoSsr>
      <ActionBarItem
        onClick={() => {
          logTrailDetailsShareButtonTapped({ trail_id: trail?.trail_id });

          if (onClick) {
            onClick();
          }

          if (!user) {
            toggle();
            return;
          }

          setShowShareModal(true);
        }}
        icon={<Share color={COLOR_INTERACTIVE_DEFAULT} />}
        title={intl.formatMessage({ defaultMessage: 'Share' })}
        className={className}
        testId="share-action"
      />
      {showShareModal && (
        <Suspense fallback={null}>
          <ShareModal handleClose={hideShareModal} shareObject={shareObject} currentUser={user} />
        </Suspense>
      )}
    </>
  );
}

export default SharePopupButton;
