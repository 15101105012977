import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import mapboxgl, { LngLatLike, MapMouseEvent } from 'mapbox-gl';
import turfBearing from '@turf/bearing';
import { lineString, Feature, LineString, Position } from '@turf/helpers';
import turfLength from '@turf/length';
import turfSimplify from '@turf/simplify';
import { COLOR_MAP_ACTIVITY, COLOR_MAP_ACTIVITY_OUTLINE } from '@alltrails/shared/denali/tokens';
import { atMapsToGeojson } from '@alltrails/maps/utils/legacyGeoJSONConversions';
import logFlyoverFinished from '@alltrails/analytics/events/logFlyoverFinished';
import FlyoverSource from '@alltrails/analytics/enums/FlyoverSource';
import fetchMapElevationProfile, { MapElevationProfileResponse } from 'api/MapElevationProfile';
import { MILLISECONDS_IN_SECOND } from 'constants/DateConstants';
import useAnimationFrame from 'hooks/useAnimationFrame';
import useStateRef from 'hooks/useStateRef';
import useFlyoverControls from 'hooks/useFlyoverControls';
import FlyoverLoadingState from 'types/FlyoverLoadingState';
import FlyoverMapEventHandlers from 'types/FlyoverMapEventHandlers';
import FlyoverCameraPosition from 'types/FlyoverCameraPosition';
import FlyoverSettings from 'types/FlyoverSettings';
import { MapboxMapShim } from 'types/Map';
import {
  FLYOVER_LINE,
  FLYOVER_ANIM_DURATION_MS,
  addFlyoverEventListeners,
  computePositionValues,
  exitFlyoverMap,
  initializeFlyoverMap,
  removeFlyoverEventListeners,
  getAnimationSpeed,
  getInitialZoom,
  getCameraPositions,
  getNextCameraPosition,
  getInterpolatedCameraPosition
} from 'utils/flyoverHelpers';
import { StyleConfigKey } from 'utils/mapbox/map';
import { hideAtMap, showAtMap } from 'utils/mapbox/overlays/at_map';
import { updatePolylines } from 'utils/mapbox/overlays/polylines';
import logError from 'utils/logError';
import useMapLoadedEvents from './useMapLoadedEvents';

const MAX_MAPBOX_ZOOM = 22;
const DEFAULT_PITCH = 65;

export default function useFlyover(
  map: MapboxMapShim,
  atMap: any,
  isNewMapsPage: boolean,
  initOnLoad?: boolean,
  isFlyoverSupported?: boolean
): FlyoverSettings {
  const [flyoverLoadingState, setFlyoverLoadingState, flyoverLoadingStateRef] = useStateRef<FlyoverLoadingState>('notStarted');
  const [disable3DOnExit, setDisable3DOnExit] = useState(true);
  const [originalLayer, setOriginalLayer] = useState<StyleConfigKey>();

  // map references
  const marker = useRef<mapboxgl.Marker>(null);

  // route info
  const atMapGeojson = useMemo(() => atMapsToGeojson([atMap]), [atMap]);
  const pinRoute = useMemo(() => atMapGeojson.features[0]?.geometry.coordinates as Position[][], [atMapGeojson]);
  const [path, setPath] = useState<Feature<LineString, { [name: string]: any }>>();
  const [cameraPath, setCameraPath] = useState<FlyoverCameraPosition[]>();
  const [pathDistance, setPathDistance] = useState<number>();
  const [segmentedElevGain, setSegmentedElevGain] = useState<number[]>([]);

  // animation state
  const [isComplete, setIsComplete] = useState(false);
  const [elevationGain, setElevationGain] = useState(0);
  const [distanceComplete, setDistanceComplete] = useState(0);
  const animationPhase = useRef(0);
  const calculatedDuration = useRef(0);
  const cameraBearing = useRef<number>(null);
  const cameraTarget = useRef<FlyoverCameraPosition>(null);
  const cameraPrevTarget = useRef<FlyoverCameraPosition>(null);
  const cameraTargetStartingProgress = useRef(0);
  const initialBearing = useRef(null);
  const initialZoom = useRef(null);
  const initialCameraPosition = useRef(null);
  const prevCoordinates = useRef(null);
  const prevCameraCoordinates = useRef(null);
  const prevElevationSeg = useRef(0);
  const startTime = useRef(0);
  const shouldResetTime = useRef(false);
  const timeElapsed = useRef(0);

  const { didMapLoadEventsFire } = useMapLoadedEvents(map, isNewMapsPage);
  const {
    isPaused,
    isPausedRef,
    shouldHideControls,
    hideControlsTimer,
    shouldResetHideControlsTimer,
    pauseFlyover,
    playFlyover,
    togglePauseFlyover,
    showControls,
    hideControls,
    isMouseDown,
    isTouching,
    interstitialEventHandlers: { keyDownHandler, mouseUpHandler },
    eventHandlers
  } = useFlyoverControls(atMap?.trailId, initOnLoad);

  useEffect(() => {
    // initialize static values based on the atMap
    if (isFlyoverSupported && pinRoute && pinRoute[0].length > 1) {
      const pathString = lineString(pinRoute[0]);
      const pathDistKm = turfLength(pathString);
      const speed = getAnimationSpeed(pathDistKm);
      const zoom = getInitialZoom(pathDistKm);

      const resolution = speed * (1 + zoom / MAX_MAPBOX_ZOOM);
      const tolerance = 1 / resolution;
      let simplifiedPath = pathString;
      try {
        simplifiedPath = turfSimplify(pathString, { tolerance });
      } catch (e) {
        logError(e);
      }
      const startingBearing = turfBearing(simplifiedPath.geometry.coordinates[0], simplifiedPath.geometry.coordinates[1]);

      setPath(pathString);
      setPathDistance(pathDistKm);
      setCameraPath(getCameraPositions(simplifiedPath, turfLength(simplifiedPath)));
      initialZoom.current = zoom;

      calculatedDuration.current = ((pathDistKm * 1000) / speed) * MILLISECONDS_IN_SECOND;
      initialBearing.current = startingBearing;
      cameraBearing.current = startingBearing;
    }
  }, [map, pinRoute, isFlyoverSupported]);

  useEffect(() => {
    if (!atMap && flyoverLoadingState === 'loading') {
      setFlyoverLoadingState('exiting');
    }
  }, [atMap, flyoverLoadingState]);

  /**
   * Responsible for updating the animation state on each call to `requestAnimationFrame`.
   * @param time current timestamp from when the first requestAnimationFrame was triggered
   */
  const flyoverFrame = (time: DOMHighResTimeStamp) => {
    if (shouldResetTime.current) {
      startTime.current = time - timeElapsed.current;
      shouldResetTime.current = false;

      hideControlsTimer.current = time;
    } else {
      timeElapsed.current = time - startTime.current;
    }

    if (shouldResetHideControlsTimer.current) {
      shouldResetHideControlsTimer.current = false;
      hideControlsTimer.current = time;
    }

    if (hideControlsTimer.current !== undefined && time - hideControlsTimer.current > MILLISECONDS_IN_SECOND * 3) {
      hideControls();
    }

    // animationPhase is a value between 0 and 1 that represents the progress of the animation
    animationPhase.current = timeElapsed.current / calculatedDuration.current;
    if (animationPhase.current >= 1) {
      // stop the animation
      pauseFlyover();

      setTimeout(() => {
        logFlyoverFinished({ source: initOnLoad ? FlyoverSource.TrailDetails : FlyoverSource.MapDetails, trail_id: atMap.trailId });
        showAtMap(map);
        setIsComplete(true);
        map.flyTo({ ...initialCameraPosition.current, speed: 0.3, curve: 3 });
        marker.current.getElement().style.visibility = 'hidden';
      }, 200);

      return;
    }

    if (isPausedRef.current) {
      return;
    }

    setDistanceComplete(atMap.summaryStats.distanceTotal * animationPhase.current);
    const elevSegIndex = Math.round(segmentedElevGain.length * animationPhase.current);
    if (elevSegIndex > 0 && elevSegIndex > prevElevationSeg.current) {
      if (segmentedElevGain[elevSegIndex] > segmentedElevGain[elevSegIndex - 1]) {
        setElevationGain(elevGain => elevGain + (segmentedElevGain[elevSegIndex] - segmentedElevGain[elevSegIndex - 1]));
      }
      prevElevationSeg.current += 1;
    }

    const { lngLat, position } = computePositionValues(path, pathDistance, animationPhase.current, prevCoordinates.current);

    // Update the marker location
    marker.current.setLngLat(lngLat);

    // Reduce the visible length of the line by using a line-gradient to cutoff the line
    updatePolylines(
      map,
      FLYOVER_LINE,
      'line-gradient',
      ['step', ['line-progress'], COLOR_MAP_ACTIVITY, animationPhase.current, 'rgba(0, 0, 0, 0)'],
      ['step', ['line-progress'], COLOR_MAP_ACTIVITY_OUTLINE, animationPhase.current, 'rgba(0, 0, 0, 0)']
    );

    /* camera logic */
    const nextTarget = getNextCameraPosition(cameraPath, animationPhase.current);
    if (!cameraTarget.current || nextTarget.location !== cameraTarget.current.location) {
      cameraPrevTarget.current = cameraTarget.current;
      cameraTarget.current = nextTarget;
      cameraTargetStartingProgress.current = animationPhase.current;
    }

    const previousTarget = cameraPrevTarget.current ?? cameraPath[0];
    const cameraPosition =
      previousTarget && nextTarget
        ? getInterpolatedCameraPosition(
            previousTarget,
            nextTarget,
            cameraTargetStartingProgress.current,
            animationPhase.current,
            calculatedDuration.current / MILLISECONDS_IN_SECOND
          )
        : { location: lngLat, bearing: initialBearing.current };

    if (!isMouseDown.current && !isTouching.current) {
      map.jumpTo({
        center: cameraPosition.location,
        bearing: cameraPosition.bearing
      });
    }

    prevCoordinates.current = position;
    prevCameraCoordinates.current = cameraPosition.location;
    cameraBearing.current = cameraPosition.bearing;
  };

  // memoize to avoid extra calls to `cancelAnimationFrame` due to re-rendering of the compass rotation
  const animationDeps = useMemo(() => [], []);
  useAnimationFrame(flyoverFrame, isPaused, isPausedRef, undefined /* onAnimationCancelled */, animationDeps);

  /**
   * Toggles between playing & pausing the animation.
   *
   * If resuming, resets the camera to the most recent position along the camera path before resuming.
   */
  const togglePlayPause = useCallback(() => {
    const { lng, lat } = map.getCenter();
    const resume = () => {
      shouldResetTime.current = true;
      playFlyover();
    };

    const resetCameraAndPlay = () => {
      if (lng !== prevCameraCoordinates.current?.[0] || lat !== prevCameraCoordinates.current?.[1] || map.getBearing() !== cameraBearing.current) {
        // unfortunately calling map.flyTo synchronously doesn't work well with default click-and-drag behavior
        setTimeout(() => {
          map.flyTo({
            center: prevCameraCoordinates.current,
            bearing: cameraBearing.current,
            speed: 0.3,
            curve: 2,
            essential: true
          });

          map.once('moveend', resume);
        }, 0);
      } else {
        resume();
      }
    };

    togglePauseFlyover(pauseFlyover, resetCameraAndPlay);
  }, [map, pauseFlyover, playFlyover, togglePauseFlyover]);

  const onKeyDown = useCallback((e: KeyboardEvent) => keyDownHandler(e, map, togglePlayPause), [keyDownHandler, map, togglePlayPause]);
  const onMouseUp = useCallback((e: MapMouseEvent) => mouseUpHandler(e, togglePlayPause), [mouseUpHandler, togglePlayPause]);
  const flyoverHandlers: FlyoverMapEventHandlers = { ...eventHandlers, onKeyDown, onMouseUp };

  useEffect(() => {
    // remove event listeners on complete to restore normal map functionality
    if (isComplete) {
      removeFlyoverEventListeners(map, pauseFlyover, flyoverHandlers);
    }
  }, [eventHandlers, isComplete, map, pauseFlyover]);

  /**
   * Initialize flyover by
   * 1) Enabling 3D
   * 2) Removing the existing polyline
   * 3) Drawing the line and marker that will be animated
   * @param enabled3D whether 3D is enabled at the time of initializing
   * @param enable3D a function to enable 3D view
   * @param setLayer a function to set a specified map layer
   * @param currentLayer the current layer at the time of initializing
   * @param onMapLoaded a callback fired when the correct layer & 3D view is loaded
   */
  const initFlyover = useCallback(
    (
      enabled3D: boolean,
      enable3D: (disablePitchAdjustment?: boolean) => void,
      setLayer: (layer: string) => void,
      currentLayer: StyleConfigKey,
      onMapLoaded?: () => void
    ) => {
      if (!map || !atMap || !didMapLoadEventsFire) {
        return;
      }

      // checks if the flyover was exiting before initialization began
      const wasExiting = flyoverLoadingStateRef.current === 'exiting';

      let elevationPromise: Promise<MapElevationProfileResponse>;
      if (!segmentedElevGain.length) {
        elevationPromise = fetchMapElevationProfile(atMap.id);
      }

      setFlyoverLoadingState('loading');
      if (!wasExiting) {
        setOriginalLayer(currentLayer);
      } else {
        map.stop();
      }

      const shouldSwitchLayers = currentLayer !== 'alltrailsSatellite';
      if (shouldSwitchLayers) {
        setLayer('alltrailsSatellite');
      }

      const initMap = () => {
        if (flyoverLoadingStateRef.current === 'exiting') return; // check if exit was pressed after initialization has started

        initialCameraPosition.current = {
          bearing: map.getBearing(),
          center: map.getCenter(),
          pitch: map.getPitch(),
          zoom: map.getZoom()
        };

        const { marker: markerPopup } = initializeFlyoverMap(map, atMapGeojson);
        marker.current = markerPopup;
        marker.current.setLngLat?.(pinRoute?.[0][0] as LngLatLike).addTo(map);
        map.flyTo({
          center: pinRoute?.[0][0] as LngLatLike,
          zoom: initialZoom.current,
          bearing: cameraBearing.current,
          pitch: DEFAULT_PITCH,
          speed: 0.3,
          curve: 3,
          essential: true
        });

        let flyoverStarted = false;
        const startFlyover = () => {
          if (flyoverLoadingStateRef.current === 'exiting') return; // check if exit was pressed after initialization has started

          if (!flyoverStarted) {
            flyoverStarted = true;
            shouldResetTime.current = true;

            addFlyoverEventListeners(map, pauseFlyover, flyoverHandlers);
            playFlyover();
            showControls();
            setFlyoverLoadingState('done');
          }
        };

        map.once('moveend', () => {
          if (flyoverLoadingStateRef.current === 'exiting') return; // check if exit was pressed after initialization has started

          // after the map pan, wait until either the map finishes loading or 1s has passed
          map.once('idle', startFlyover);
          setTimeout(startFlyover, MILLISECONDS_IN_SECOND);
        });
      };

      const set3D = () => {
        if (!enabled3D) {
          enable3D(true /* disablePitchAdjustment */);
          if (!wasExiting) {
            setDisable3DOnExit(true);
          }
          map.once('idle', initMap);
        } else {
          if (!wasExiting) {
            setDisable3DOnExit(false);
          }
          initMap();
        }
      };

      const onMapReady = () => {
        onMapLoaded();
        elevationPromise
          .then(elevations => {
            setSegmentedElevGain(elevations.smoothedElevations.map(smoothedElevPoint => smoothedElevPoint.elevation));
            setTimeout(set3D, FLYOVER_ANIM_DURATION_MS * 1.5 /* add a small buffer for animations to complete */);
          })
          .catch(e => logError(e));
      };

      if (shouldSwitchLayers) {
        map.once('idle', onMapReady);
      } else {
        onMapReady();
      }
    },
    [map, atMap, didMapLoadEventsFire, atMapGeojson, pinRoute, pathDistance, segmentedElevGain?.length, showControls, playFlyover, pauseFlyover]
  );

  /**
   * Pause and reset the animation, resetting the marker and polyline.
   */
  const resetAnimation = () => {
    pauseFlyover();
    setIsComplete(false);
    setElevationGain(0);
    animationPhase.current = 0;
    cameraBearing.current = initialBearing.current;
    cameraTarget.current = null;
    cameraPrevTarget.current = null;
    cameraTargetStartingProgress.current = 0;
    prevCoordinates.current = null;
    prevCameraCoordinates.current = null;
    prevElevationSeg.current = 0;
    timeElapsed.current = 0;
    startTime.current = 0;
    marker.current?.setLngLat?.(pinRoute[0][0] as LngLatLike);
    updatePolylines(map, FLYOVER_LINE, 'line-gradient', ['step', ['line-progress'], COLOR_MAP_ACTIVITY, 0, 'rgba(255, 0, 0, 0)']);
  };

  /**
   * Replays the animation from the beginning.
   */
  const replayAnimation = () => {
    hideAtMap(map);
    map.stop();
    marker.current.getElement().style.visibility = 'visible';
    resetAnimation();
    map.flyTo({
      center: pinRoute[0][0] as LngLatLike,
      bearing: initialBearing.current,
      zoom: initialZoom.current,
      pitch: DEFAULT_PITCH,
      speed: 0.3,
      curve: 3,
      essential: true
    });

    map.once('idle', () => {
      shouldResetTime.current = true;
      setTimeout(() => {
        playFlyover();
        addFlyoverEventListeners(map, pauseFlyover, flyoverHandlers);
      }, 500);
    });
  };

  /**
   * Exits the flyover experience by resetting internal values & removing extra event listeners.
   * Updates the loading state accordingly.
   * @param disable3D a function disable the 3D terrain
   * @param setLayer a function to change map layers
   */
  const exitFlyover = useCallback(
    (disable3D: () => void, setLayer: (layer: string) => void) => {
      map.stop();
      setFlyoverLoadingState('exiting');
      resetAnimation();
      removeFlyoverEventListeners(map, pauseFlyover, flyoverHandlers);
      setSegmentedElevGain([]);
      marker.current?.remove();
      marker.current = null;

      const returnLayer = () => {
        if (flyoverLoadingStateRef.current !== 'exiting') {
          return;
        }

        setLayer(originalLayer);
        map.once('idle', () => {
          setFlyoverLoadingState('notStarted');
        });
      };

      const returnMap = () => {
        if (flyoverLoadingStateRef.current !== 'exiting') {
          return;
        }

        exitFlyoverMap(map, atMapGeojson);
        map.once('idle', returnLayer);
      };

      if (disable3DOnExit) {
        disable3D();
        map.once('idle', returnMap);
      } else {
        returnMap();
      }
    },
    [atMapGeojson, disable3DOnExit, map, originalLayer, pauseFlyover, flyoverLoadingStateRef, setFlyoverLoadingState]
  );

  return {
    didMapLoadEventsFire,
    distanceCompleteMeters: distanceComplete,
    elevationGainMeters: elevationGain,
    exitFlyover,
    flyoverLoadingState,
    initFlyover,
    isComplete,
    isPaused,
    percentComplete: animationPhase.current,
    resetAnimation,
    replayAnimation,
    shouldHideControls,
    togglePauseFlyover: togglePlayPause
  };
}
