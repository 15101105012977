import { SyntheticEvent, useMemo, useState } from 'react';
import ReportLocation from '@alltrails/analytics/enums/ReportLocation';
import { PhotoSize } from '@alltrails/shared/types/photos';
import { getPhotoUrl } from '@alltrails/shared/utils/requests/photoRequests';
import type { Context } from 'types/Context';
import * as styles from './styles/styles.module.scss';
import Lightbox from '../../shared/Lightbox';
import { urlReformatPhotos } from '../../../utils/lightbox_helpers';
import { ServerCommunicationUtil } from '../../../utils/server_communication_util';

type PhotoGridProps = {
  mapPhotos: any[];
  mapId: number;
  context: Context;
};

const PhotoGrid = ({ mapPhotos, mapId, context }: PhotoGridProps) => {
  const [lightboxProps, updateLightboxProps] = useState({ isOpen: false, images: [], startingIndex: null });
  const sortedPhotos = useMemo(
    () => mapPhotos.sort((a, b) => +new Date(a.photo.metadata.created) - +new Date(b.photo.metadata.created)),
    [mapPhotos]
  );

  const openLightbox = (startingIndex: number) => {
    const photos = sortedPhotos.map(mp => mp.photo);
    const images = urlReformatPhotos(photos);

    updateLightboxProps(prevState => ({
      ...prevState,
      isOpen: true,
      images,
      startingIndex
    }));
  };

  const handleLightboxClose = () => {
    updateLightboxProps(prevState => ({ ...prevState, isOpen: false, images: [], startingIndex: null }));
  };

  const photos = sortedPhotos.map((mapPhoto, idx) => {
    const { id, hash } = mapPhoto.photo;
    const fallbackPhotoUrl = `/api/alltrails/photos/${id}/image?size=medium_square&key=${ServerCommunicationUtil.apiKey}`;
    const photoUrl = id && hash ? getPhotoUrl({ id, photoHash: hash }, PhotoSize.MediumSquare) : fallbackPhotoUrl;

    let shouldFallback = true;
    const addFallbackSrc = (ev: SyntheticEvent<HTMLImageElement, Event>) => {
      if (shouldFallback) {
        (ev.target as HTMLImageElement).src = fallbackPhotoUrl;
        (ev.target as HTMLImageElement).onerror = null;
      }
      shouldFallback = false;
    };

    return (
      <li key={`photo-${id}`} className={styles.photoItem}>
        <button className={styles.photoButton} onClick={() => openLightbox(idx)} type="button">
          <img src={photoUrl} className={styles.photo} alt="" onError={addFallbackSrc} />
        </button>
      </li>
    );
  });

  return (
    <>
      {photos}
      {lightboxProps.isOpen && (
        <Lightbox
          {...lightboxProps}
          mapId={mapId}
          handleCloseRequest={handleLightboxClose}
          context={context}
          analyticsReportLocation={ReportLocation.MapDetails}
        />
      )}
    </>
  );
};

export default PhotoGrid;
