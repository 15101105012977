import { PhotoSize } from '@alltrails/shared/types/photos';
import { getPhotoUrl } from '@alltrails/shared/utils/requests/photoRequests';
import { X_AT_KEY } from '@alltrails/shared/api';

// returns an array of photos that can be processed by the lightbox
const urlReformatPhotos = photos => {
  let imageHash;

  return photos.map(photo => {
    const { id, photo_hash, photoHash } = photo;
    imageHash = photo_hash || photoHash; // function is flexible whether photohash is coming from the photo class

    // TODO: Add support for fallback when direct CDN link doesn't work right
    const url =
      id && imageHash
        ? getPhotoUrl({ id, photoHash: imageHash }, PhotoSize.ExtraLarge, true)
        : `/api/alltrails/photos/${id}/image?size=extra_large&api_key=${X_AT_KEY}`;
    return { id, url };
  });
};

// returns only what the lightbox needs
const formatImagesForLightbox = photos => {
  return photos.map(photo => {
    const { id, url } = photo;
    return { id, url };
  });
};

export { urlReformatPhotos, formatImagesForLightbox };
