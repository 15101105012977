// extracted by mini-css-extract-plugin
export var addReviewBtn = "styles-module__addReviewBtn___jxbWN";
export var attributeTitle = "styles-module__attributeTitle___ITl6L";
export var attributes = "styles-module__attributes___sYmix";
export var banner = "styles-module__banner___P2WEs";
export var blockSection = "styles-module__blockSection___KErOb";
export var bookmarkButton = "styles-module__bookmarkButton___ecQ7b";
export var container = "styles-module__container___f1liH";
export var dateAndActivity = "styles-module__dateAndActivity___HyeGo";
export var description = "styles-module__description___xOSFS";
export var editReviewLink = "styles-module__editReviewLink___SBhrH";
export var garminButton = "styles-module__garminButton___QxdM6";
export var metadata = "styles-module__metadata___QHuNx";
export var name = "styles-module__name___aLM1C";
export var nameAndDescription = "styles-module__nameAndDescription___vxiRq";
export var nameAndStars = "styles-module__nameAndStars___Ae6fz";
export var plusBadge = "styles-module__plusBadge___XRWNu";
export var privacyDropdown = "styles-module__privacyDropdown___mEaGT";
export var ratingTags = "styles-module__ratingTags___pKoz8";
export var reviewComment = "styles-module__reviewComment___WNT3m";
export var reviews2TrackName = "styles-module__reviews2TrackName___J0NYI";
export var starRatingIconClass = "styles-module__starRatingIconClass___aK7Fn";
export var starsBlock = "styles-module__starsBlock___SJJrn";
export var trackName = "styles-module__trackName___I0K1Q";