import { get } from '@alltrails/shared/api';
import SortOption, { PresentationType } from 'components/trailUgc/types/SortOption';

/**
 * A function to fetch sorted UGC content from different pages.
 * @param sortingRequestUrl the request URL, changes depending on which page UGC is being fetched from
 * @param newSortOption the sort option the response should respect
 * @param perPage number of UGC returned (requests only 1 page)
 * @param locationServerData
 * @param presentationType
 */
export default function sortUgc(
  sortingRequestUrl: string,
  newSortOption: SortOption,
  perPage: number,
  locationServerData: any,
  presentationType?: PresentationType
) {
  const params: any = {
    page: 1,
    per_page: perPage,
    sort_option: newSortOption,
    ...(presentationType && { presentation_type: presentationType })
  };
  if (typeof locationServerData !== 'undefined') {
    params.country_id = locationServerData.country_id;
    if (locationServerData.attribute) params.attribute = locationServerData.attribute;
    if (locationServerData.park_type) params.park_type = locationServerData.park_type;
  }

  return get(sortingRequestUrl, {
    params
  });
}
