import Link from '@alltrails/shared/denali/components/Link';
import Repeat from '@alltrails/shared/icons/Repeat';
import { defineMessages, FormattedMessage, useIntl } from '@alltrails/shared/react-intl';
import { toggleSubscriptionStatus } from 'api/My';
import CustomProvider from 'components/CustomProvider';
import NEW_PLAN_COUNTRY_CODES from 'constants/NewPlanPromptCountryCodes';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import useUser from 'hooks/useUser';
import RecurlyAccountStatus from 'types/RecurlyAccountStatus';
import { useEffect } from 'react';
import logError from 'utils/logError';

import * as styles from './AutoRenewal.module.scss';

type Props = {
  recurlyAccountStatus: RecurlyAccountStatus;
  nextTransactionAmount: string;
};

const messages = defineMessages({
  noTrial: {
    defaultMessage:
      'Are you sure? Your plan will expire on {date} and will not auto-renew. If you wish to cancel your current plan, please email support@alltrails.com.'
  },
  trial: {
    defaultMessage: 'Are you sure? Your plan will expire on {date} and will not auto-renew.'
  }
});

async function toggle(confirmText: string) {
  if (window.confirm(confirmText)) {
    await toggleSubscriptionStatus();
    window.location.assign(window.location.href); // Refresh to top of screen so flash messages are noticed.
  }
}

function TurnOnAutoRenew({ nextTransactionAmount }: { nextTransactionAmount: string }) {
  const { ipCountry } = __AT_DATA__;
  const intl = useIntl();
  const user = useUser();

  useEffect(() => {
    // I don't anticipate that nextTransactionAmount will ever be undefined here, but I'm adding error logging to monitor it just in case.
    if (!nextTransactionAmount) {
      logError('"nextTransactionAmount" is undefined in AutoRenew prompt');
    }
  }, []);

  const prompt = intl.formatMessage(
    {
      defaultMessage:
        'We\'re so glad that you\'re enjoying AllTrails+! By clicking "Confirm" you agree to renew your subscription at the current price of {annualCost} per year.'
    },
    {
      annualCost: nextTransactionAmount
    }
  );

  return (
    <Link
      onClick={async () => {
        if (NEW_PLAN_COUNTRY_CODES.includes(ipCountry) && !user?.subscription.trial) {
          toggle(prompt);
        } else {
          await toggleSubscriptionStatus();
          window.location.assign(window.location.href); // Refresh to top of screen so flash messages are noticed.
        }
      }}
      className={styles.link}
      size="md"
      icon={{ Component: Repeat }}
    >
      <FormattedMessage defaultMessage="Turn on auto-renew" />
    </Link>
  );
}

function TurnOffAutoRenew() {
  const intl = useIntl();
  const user = useUser();
  const languageRegionCode = useLanguageRegionCode();

  const formatter = new Intl.DateTimeFormat(languageRegionCode, { day: 'numeric', month: 'long', year: 'numeric' });

  const prompt = intl.formatMessage(user.subscription?.trial ? messages.trial : messages.noTrial, {
    date: formatter.format(new Date(user.expirationDate))
  });

  return (
    <Link
      onClick={() => {
        toggle(prompt);
      }}
      className={styles.link}
      size="md"
      icon={{ Component: Repeat }}
    >
      <FormattedMessage defaultMessage="Turn off auto-renew" />
    </Link>
  );
}

function CancelSubscription() {
  const intl = useIntl();
  const languageRegionCode = useLanguageRegionCode();
  const user = useUser();

  const formatter = new Intl.DateTimeFormat(languageRegionCode, { day: 'numeric', month: 'long', year: 'numeric' });

  const prompt = intl.formatMessage(
    defineMessages({
      message: {
        defaultMessage:
          'Are you sure? Your AllTrails+ subscription expired on {date} and has not been renewed. If you wish to continue your subscription, please update your Billing Info.'
      }
    }).message,
    {
      date: formatter.format(new Date(user.expirationDate))
    }
  );

  return (
    <Link
      onClick={() => {
        toggle(prompt);
      }}
      className={styles.link}
      size="md"
      icon={{ Component: Repeat }}
    >
      <FormattedMessage defaultMessage="Cancel subscription" />
    </Link>
  );
}

function AutoRenewalContent({ recurlyAccountStatus, nextTransactionAmount }: Props) {
  switch (recurlyAccountStatus) {
    case RecurlyAccountStatus.Active:
      return <TurnOffAutoRenew />;
    case RecurlyAccountStatus.PastDue:
      return <CancelSubscription />;
    case RecurlyAccountStatus.Cancelled:
      return <TurnOnAutoRenew nextTransactionAmount={nextTransactionAmount} />;
    default:
      return null;
  }
}

function AutoRenewal({ recurlyAccountStatus, nextTransactionAmount }: Props) {
  return (
    <CustomProvider>
      <AutoRenewalContent recurlyAccountStatus={recurlyAccountStatus} nextTransactionAmount={nextTransactionAmount} />
    </CustomProvider>
  );
}

export default AutoRenewal;
