import { useState, useEffect, useCallback, useRef, Dispatch, SetStateAction } from 'react';
import ShareSource from '@alltrails/analytics/enums/ShareSource';
import { FormattedMessage, defineMessages } from '@alltrails/shared/react-intl';
import useFormatMessage from '@alltrails/shared/hooks/useFormatMessage';
import Button from '@alltrails/shared/denali/components/Button';
import ReportIssuePage from '@alltrails/modules/Reporting/ReportIssuePage';
import ReportLocation from '@alltrails/analytics/enums/ReportLocation';
import ReportContentType from '@alltrails/analytics/enums/ReportContentType';
import SuccessToast from '@alltrails/shared/types/successToast';
import { EntryPoint } from '@alltrails/modules/Reporting/types';
import '@alltrails/modules/Reporting/sharedCss.css';
import useActivityStrings from '@alltrails/shared/hooks/useActivityStrings';
import { PageStrings } from '@alltrails/shared/utils/constants/pageStringHelpers';
import { wrapUrlSafe } from '@alltrails/shared/utils/languageSupport';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import PrivacyMapUpdateModal from '@alltrails/modules/Privacy/PrivacyMapUpdateModal';
import logCustomMapCreated from '@alltrails/analytics/events/logCustomMapCreated';
import logCustomMapFeatureSubmitted from '@alltrails/analytics/events/logCustomMapFeatureSubmitted';
import CustomMapFeatureAdded from '@alltrails/analytics/enums/CustomMapFeatureAdded';
import { VISIBILITY_PRIVATE, VISIBILITY_PUBLIC } from 'utils/privacy_policy_helpers';
import { routeRequest, waypointRequest, formatWaypointForMap } from 'utils/map_creator_util';
import Expandable from '@alltrails/shared/components/Expandable';
import { modalRoadblock } from '@alltrails/shared/utils/modalFunnelUtils';
import { usersContentPrivacyPolicy, userCanEdit } from 'utils/UserUtils';
import TrailCard from 'components/cards/TrailCard';
import useUser from 'hooks/useUser';
import type { Context } from 'types/Context';
import { useRoutes, CANCEL_ROUTE_FORM, UPDATE_FOR_INCOMING_ROUTES } from 'hooks/useRoutes';
import { useWaypoints } from 'hooks/useWaypoints';
import PortalModal from 'components/shared/PortalModal';
import CreatedMapModal from '../CreatedMapModal';
import RouteSection from '../Routing/RouteSection';
import WaypointsSection from '../../Waypoints/WaypointsSection';
import DescriptionForm from '../DescriptionForm';
import ActionHeader from '../../shared/ActionHeader';
import EditHeader from '../EditHeader';
import { deleteMap } from '../../../utils/requests/at_map_requests';
import PhotoSection from '../Photos';
import DownloadRouteModal from '../../DownloadRouteModal';
import { logIndividualContentPrivacyChangedAmplitudeEvent } from '../../../utils/privacy_policy_amplitude_helpers';
import * as styles from './styles/styles.module.scss';

const PANEL_STRINGS = defineMessages({
  EDIT_MAP: { defaultMessage: 'Edit map' },
  DESCRIPTION: { defaultMessage: 'Description' },
  WAYPOINTS: { defaultMessage: 'Waypoints' },
  PHOTOS: { defaultMessage: 'Photos' },
  SAVE_CUSTOM_MAP: { defaultMessage: 'Save custom map' },
  DELETE_ITEM_QUESTION: { defaultMessage: 'Are you sure you want to delete this from AllTrails?' }
});

type Props = {
  context: Context;
  map?: any;
  openTrailFormModal?: any;
  messagingChannel?: any;
  mapboxAccessToken?: any;
  handleMapUpdate?: any;
  resultCardFunctions?: any;
  updateMapPhotos: any;
  openShareModal: any;
  linkedTrail: any;
  initialIsEditing: any;
  updateExploreMap: any;
  saveMapChanges: any;
  routeInfo: any;
  updateProgressDialog: any;
  showMapMobile: any;
  hidePanel: any;
  listMethods: any;
  handleRouteInfoChanged: any;
  saveMapAsClone: any;
  setReportingSuccessToast: Dispatch<SetStateAction<SuccessToast | null>>;
};

const MapCreatorPanel = ({
  context,
  map = {},
  messagingChannel = {},
  mapboxAccessToken = '',
  handleMapUpdate,
  openTrailFormModal,
  updateMapPhotos,
  openShareModal,
  resultCardFunctions = {},
  linkedTrail,
  initialIsEditing,
  updateExploreMap,
  saveMapChanges,
  routeInfo,
  updateProgressDialog,
  showMapMobile,
  hidePanel,
  listMethods,
  handleRouteInfoChanged,
  saveMapAsClone,
  setReportingSuccessToast
}: Props) => {
  const {
    formattedDefaultMessages: { EDIT_MAP, DESCRIPTION, WAYPOINTS, PHOTOS, SAVE_CUSTOM_MAP, DELETE_ITEM_QUESTION }
  } = useFormatMessage(PANEL_STRINGS);

  // editingMethods
  let saveMap: any;
  let handleRouteSuccess: any;
  let handleRouteSave;
  let saveAndDisplayMap: (e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLElement, MouseEvent>, fromSubForm?: boolean) => void;
  let createMapWithWaypoint: any;
  let handleSuccessfulMapSave: any;
  let handleMapError: any;

  const { findByValue } = useActivityStrings();
  const languageRegionCode = useLanguageRegionCode();
  const user = useUser();
  const [updatedMap, setUpdatedMap] = useState(map);
  const canEdit = userCanEdit(user, updatedMap.user);
  const [isEditing, toggleEditing] = useState(initialIsEditing);
  const [showDownloadRouteModal, setShowDownloadRouteModal] = useState(false);
  const [showCreatedMapModal, toggleCreatedMapModal] = useState(false);
  const [submittingState, updateSubmittingState] = useState({ fromSubForm: false, isSubmitting: false, isNew: initialIsEditing });
  const [routingState, dispatchRoutes] = useRoutes({ initialRoutes: updatedMap.routes, messagingChannel, routeInfo, canEdit });
  const [waypointsState, dispatchWaypoints] = useWaypoints({ initialWaypoints: updatedMap.waypoints, messagingChannel, canEdit });
  const prevStateRef = useRef({ isEditing, showCreatedMapModal, submittingState, routingState, waypointsState, updatedMap });
  const isCreateMapView = new URLSearchParams(window.location.search).has('save');
  const [showReportModal, setShowReportModal] = useState(false);
  const [showMapPrivacyModal, setShowMapPrivacyModal] = useState(false);

  const initialAccordionStates = {
    description: !initialIsEditing,
    waypoints: updatedMap.waypoints.length > 0,
    photos: updatedMap.mapPhotos.length > 0,
    linkedTrail: false
  };

  const description = updatedMap?.description_source || updatedMap?.description;

  const defaultContentPrivacy = usersContentPrivacyPolicy(user, 'customMaps');

  const setInitialContentPrivacy = () => {
    if (updatedMap && updatedMap.contentPrivacy) {
      return updatedMap.contentPrivacy;
    }

    return defaultContentPrivacy || VISIBILITY_PUBLIC;
  };

  const initialDescriptionState = {
    title: updatedMap ? updatedMap.name : '',
    description,
    activity: findByValue(updatedMap.activity?.uid),
    date: updatedMap.created_at ? new Date(updatedMap.created_at) : new Date(),
    isPrivate: updatedMap ? !!updatedMap.private : false,
    contentPrivacy: setInitialContentPrivacy()
  };

  const [descriptionChangeset, updateDescriptionChangeset] = useState(initialDescriptionState);
  const [previousDescriptionChangeset, updatePreviousDescriptionChangeset] = useState(initialDescriptionState); // Used for analytics
  const [accordionsOpened, updateAccordionState] = useState(initialAccordionStates);

  const beginEditing = useCallback(() => {
    updatePreviousDescriptionChangeset(descriptionChangeset);
    toggleEditing(true);
  }, [descriptionChangeset]);

  useEffect(() => {
    if (
      (prevStateRef.current.routingState.editIndex === null && routingState.editIndex !== null) ||
      (prevStateRef.current.waypointsState.editIndex === null && waypointsState.editIndex !== null)
    ) {
      beginEditing();
    }
  }, [routingState.editIndex, waypointsState.editIndex, beginEditing]);

  useEffect(() => {
    // keeps previous state tracked
    prevStateRef.current = { updatedMap: null, isEditing, showCreatedMapModal, submittingState, routingState, waypointsState };
  });

  const handleFindLocationSelect = useCallback((feature: any) => {
    const { latitude, longitude, placeName } = feature;

    // triggers showPopupAtLatLong in TrailPlannerMixin to update map lat/lng location and show popup
    messagingChannel.publish({
      topic: 'edit.show_popup_at_lat_lng',
      data: [latitude, longitude, placeName, 14]
    });
  }, []);

  const updateMap = useCallback(() => {
    if (updatedMap && updatedMap.id) {
      handleMapUpdate?.({ id: updatedMap.id });
    }
  }, [updatedMap]);

  useEffect(() => setUpdatedMap(map), [map]);

  const closeCreatedMapModal = () => {
    toggleCreatedMapModal(false);
  };

  // ROUTE PERSISTENCE
  if (isEditing || isCreateMapView) {
    handleSuccessfulMapSave = (responseMap: any) => {
      if (prevStateRef.current.submittingState.isSubmitting && !prevStateRef.current.submittingState.fromSubForm) {
        toggleEditing(false);
        const url = `/explore/map/${responseMap.slug}`;
        window.history.pushState(
          { page: PageStrings.EXPLORE_USERS_TRACKS_MAP_PAGE, selectedObjectId: responseMap.ID, filters: null },
          `Explore ${responseMap.name} | AllTrails`,
          url
        );
      }
      // update the description changeset -- needed because titles created automatically are not reflected in changeset
      if (responseMap.name !== descriptionChangeset.title) {
        updateDescriptionChangeset({ ...descriptionChangeset, title: responseMap.name });
      }
      if (prevStateRef.current.routingState.editParams) {
        dispatchRoutes({ type: CANCEL_ROUTE_FORM });
      }
      if (prevStateRef.current.waypointsState.editParams) {
        dispatchWaypoints({ type: 'CANCEL_WAYPOINT_FORM' });
      }
      if (prevStateRef.current.submittingState.isNew && !prevStateRef.current.submittingState.fromSubForm) {
        toggleCreatedMapModal(true);
        // update so modal doesn't pop up if a user saves twice
        updateSubmittingState(prevState => ({ ...prevState, isNew: false }));
      }

      dispatchRoutes({ type: UPDATE_FOR_INCOMING_ROUTES, payload: responseMap.routes });
      dispatchWaypoints({ type: 'UPDATE_FOR_INCOMING_WAYPOINTS', payload: responseMap.waypoints });
      updateSubmittingState(prevState => ({ ...prevState, fromSubForm: false, isSubmitting: false }));
    };

    saveMap = (additionalParams = {}) => {
      // This saves map changes via search_app
      saveMapChanges({ descriptionChangeset, ...additionalParams })
        .then(handleSuccessfulMapSave)
        .catch(() => {
          updateSubmittingState(prevState => ({ ...prevState, fromSubForm: false, isSubmitting: false }));
        });
    };

    handleRouteSuccess = (newMap: any) => {
      dispatchRoutes({ type: CANCEL_ROUTE_FORM });
      dispatchRoutes({ type: UPDATE_FOR_INCOMING_ROUTES, payload: newMap.routes });
      updateProgressDialog('saved');
      updateExploreMap(newMap);
      updateSubmittingState(prevState => ({ ...prevState, isSubmitting: false }));
    };

    handleMapError = () => {
      updateProgressDialog('error');
      updateSubmittingState(prevState => ({ ...prevState, fromSubForm: false, isSubmitting: false }));
    };

    handleRouteSave = (e: any = null) => {
      if (e) {
        e.preventDefault();
      }

      if (typeof updatedMap.id === 'undefined') {
        updateSubmittingState(prevState => ({ ...prevState, fromSubForm: true, isSubmitting: true }));
        saveMap();
        return;
      }

      if (!routingState.editParams) {
        return;
      }

      updateProgressDialog('saving');
      updateSubmittingState(prevState => ({ ...prevState, isSubmitting: true }));

      routeRequest(updatedMap.id, routingState.editParams).then(handleRouteSuccess).catch(handleMapError);
    };

    const saveMapContentsThenMap = () => {
      // if there is an open waypoint or route form
      updateProgressDialog('saving');
      updateSubmittingState(prevState => ({ ...prevState, isSubmitting: true }));
      // if there are not route or waypoint editparams the promises will return null
      Promise.all([routeRequest(updatedMap.id, routingState.editParams), waypointRequest(updatedMap.id, waypointsState.editParams)])
        .then(() => {
          handleRouteInfoChanged(null);
          saveMap();
        })
        .catch(err => {
          handleMapError(err);
        });
    };

    saveAndDisplayMap = (e: any, fromSubForm = false) => {
      e.preventDefault();

      logIndividualContentPrivacyChangedAmplitudeEvent(
        'customMaps',
        updatedMap.id,
        defaultContentPrivacy,
        previousDescriptionChangeset.contentPrivacy,
        descriptionChangeset.contentPrivacy
      );

      updateSubmittingState(prevState => ({ ...prevState, fromSubForm, isSubmitting: true }));
      if (updatedMap.id && (routingState.editParams || waypointsState.editParams)) {
        saveMapContentsThenMap();
        return;
      }

      if (typeof updatedMap.id === 'undefined' && waypointsState.editParams) {
        const waypoint = formatWaypointForMap(waypointsState.editParams);
        createMapWithWaypoint(waypoint, false);
        return;
      }

      saveMap();
    };

    createMapWithWaypoint = (waypoint: any, fromSubForm = true) => {
      if (updatedMap.id) {
        return;
      }

      updateSubmittingState(prevState => ({ ...prevState, fromSubForm, isSubmitting: true }));
      const waypoints = [waypoint];
      saveMap({ waypoints });
    };
  }

  const toggleAccordionState = (accordionName: 'description' | 'waypoints' | 'photos' | 'linkedTrail') => {
    updateAccordionState(prevState => ({ ...prevState, [accordionName]: !prevState[accordionName] }));
  };

  const handleShareClick = () => {
    if (!user) {
      modalRoadblock('signup', 'explore-atmap', window.location.pathname, languageRegionCode);
      return;
    }

    if (showCreatedMapModal) {
      closeCreatedMapModal();
    }

    if (updatedMap.contentPrivacy === VISIBILITY_PRIVATE) {
      setShowMapPrivacyModal(true);
      return;
    }

    openShareModal({
      type: updatedMap.presentationType,
      ugc: true,
      id: updatedMap.id,
      slug: updatedMap.slug,
      name: updatedMap.name,
      primaryKey: 'id',
      private: updatedMap.private,
      source: ShareSource.Map
    });
  };

  const handleDirectionsClick = () => {
    if (!user) {
      modalRoadblock('signup', 'explore-atmap', window.location.pathname, languageRegionCode);
      return;
    }

    const {
      location: { latitude, longitude }
    } = updatedMap;

    window.open(`https://www.google.com/maps/dir/Current+Location/${latitude},${longitude}`);
  };

  const handlePrintClick = () => {
    // fires handlePrintClick in search_map
    messagingChannel.publish({
      topic: 'print.navigate'
    });
  };

  const openDownloadModal = () => {
    if (!user) {
      modalRoadblock('signup', 'explore-atmap', window.location.pathname, languageRegionCode);
    }

    setShowDownloadRouteModal(true);
  };

  const toggleDownloadModal = () => {
    setShowDownloadRouteModal(!showDownloadRouteModal);
  };

  const handleDeleteSuccess = () => {
    window.location.href = wrapUrlSafe(`/members/${user.slug}/maps`, languageRegionCode);
  };

  const handleDeleteError = () => {
    updateProgressDialog('error-deleting-map');
  };

  const handleDeleteClick = () => {
    if (!window.confirm(DELETE_ITEM_QUESTION)) {
      return;
    }

    deleteMap(updatedMap.id)
      .then(resp => {
        if (resp.errors) {
          handleDeleteError();
          return;
        }

        handleDeleteSuccess();
      })
      .catch(() => {
        handleDeleteError();
      });
  };

  const closeDescriptionForm = () => {
    toggleAccordionState('description');
  };

  const openReportModal = () => {
    if (!user) {
      const returnToUrl = window.location.pathname + window.location.search;
      modalRoadblock('signup', 'report-issue', returnToUrl, languageRegionCode);
    }

    setShowReportModal(true);
  };

  const onMapPrivacyUpdate = () => {
    window.location.reload();
  };

  const onWaypointFormSubmit = useCallback(() => {
    logCustomMapFeatureSubmitted({ feature_added: CustomMapFeatureAdded.Waypoint });
  }, []);

  const renderHeader = () => {
    if (isEditing || isCreateMapView) {
      return <EditHeader />;
    }

    const { forcePhotoId } = resultCardFunctions;
    const { isFavorite, handleFavoriteClick } = listMethods;
    const bannerPhotoId = forcePhotoId(updatedMap.presentationType, updatedMap.ID);
    return (
      <ActionHeader
        context={context}
        data={updatedMap}
        isFavorite={isFavorite(updatedMap.presentationType, updatedMap.ID)}
        handleFavoriteClick={handleFavoriteClick}
        bannerPhotoId={bannerPhotoId}
        handleDirectionsClick={handleDirectionsClick}
        openDownloadModal={openDownloadModal}
        handleShareClick={handleShareClick}
        handleDeleteClick={handleDeleteClick}
        saveMapAsClone={() => saveMapAsClone(updatedMap)}
        type="map"
        openReportModal={openReportModal}
        setReportingSuccessToast={setReportingSuccessToast}
      />
    );
  };

  const renderLinkedTrail = () => {
    const { type, ID } = linkedTrail;

    const { allowClickingCompletedBadge, handleTrailClick, handleCompletedBadgeClick, handleFavoriteClick } = resultCardFunctions;

    const { isComplete, isFavorite, isVerified } = listMethods;

    const linkedTrailProps = {
      allowClickingCompletedBadge: allowClickingCompletedBadge(type, ID),
      handleCardClick: () => handleTrailClick(linkedTrail),
      handleCompletedBadgeClick: () => handleCompletedBadgeClick(type, ID),
      handleFavoriteClick: () => handleFavoriteClick({ type, id: ID }),
      isComplete: isComplete(type, ID),
      isFavorite: isFavorite(type, ID),
      isVerified: isVerified(type, ID),
      trail: linkedTrail,
      messagingChannel,
      key: `trail-${linkedTrail.ID}`
    };

    return <TrailCard {...linkedTrailProps} />;
  };

  return (
    <div className={`${styles.panel} ${hidePanel ? styles.hidePanel : ''}`}>
      {renderHeader()}
      <RouteSection
        canEdit={canEdit}
        isEditing={isEditing || isCreateMapView}
        routes={routingState.routes}
        sortMode={routingState.sortMode}
        dispatchRoutes={dispatchRoutes}
        dispatchWaypoints={dispatchWaypoints}
        editIndex={routingState.editIndex}
        editParams={routingState.editParams}
        messagingChannel={messagingChannel}
        summaryStats={updatedMap.summaryStats}
        handleFindLocationSelect={handleFindLocationSelect}
        mapboxAccessToken={mapboxAccessToken}
        mapId={updatedMap.id}
        updateMap={updateMap}
        openTrailFormModal={openTrailFormModal}
        handleRouteSave={handleRouteSave}
        isSubmitting={submittingState.isSubmitting}
        updateExploreMap={updateExploreMap}
        hidePanel={showMapMobile}
      />
      <div className={styles.accordionContainer}>
        {(isEditing || isCreateMapView) && (
          <Expandable
            title={DESCRIPTION}
            externalControls={{ toggleIsOpen: () => toggleAccordionState('description'), isOpen: accordionsOpened.description }}
          >
            <DescriptionForm
              initialChangeset={initialDescriptionState}
              changeset={descriptionChangeset}
              updateChangeset={updateDescriptionChangeset}
              handleDescriptionSave={saveAndDisplayMap}
              closeDescriptionForm={closeDescriptionForm}
            />
          </Expandable>
        )}
        <Expandable title={WAYPOINTS}>
          <WaypointsSection
            {...waypointsState}
            dispatchFormState
            dispatchWaypoints={dispatchWaypoints}
            messagingChannel={messagingChannel}
            mapId={updatedMap.id}
            canEdit={canEdit}
            createMapWithWaypoint={createMapWithWaypoint}
            onWaypointFormSubmit={onWaypointFormSubmit}
          />
        </Expandable>
        {updatedMap && updatedMap.id && (
          <Expandable contentClassName={styles.accordionExtended} hideBorder={!linkedTrail} title={PHOTOS}>
            <PhotoSection
              canEdit={canEdit}
              mapId={updatedMap.id}
              mapPhotos={updatedMap.mapPhotos}
              updateMapPhotos={updateMapPhotos}
              openTrailFormModal={openTrailFormModal}
              isEditing={isEditing || isCreateMapView}
              context={context}
            />
          </Expandable>
        )}
        {linkedTrail && (
          <Expandable hideBorder title={<FormattedMessage defaultMessage="Linked trail" />}>
            {renderLinkedTrail()}
          </Expandable>
        )}
      </div>
      <PortalModal isOpen={user && showDownloadRouteModal}>
        <DownloadRouteModal toggleDownloadModal={toggleDownloadModal} mapId={updatedMap.id} />
      </PortalModal>
      <div className={styles.panelSpacer} />
      {canEdit ? (
        <div className={styles.panelBottom}>
          {isEditing || isCreateMapView ? (
            <Button
              text={SAVE_CUSTOM_MAP}
              onClick={(e?: React.MouseEvent<HTMLElement, MouseEvent>) => {
                const currentDistance = updatedMap?.summaryStats?.distanceTotal || 0;
                const newRouteDistance = routingState?.editParams?.distanceTotal;
                const distance = newRouteDistance ? currentDistance + newRouteDistance : currentDistance;
                const titleLength = descriptionChangeset?.title?.length || 0;
                const descriptionLength = descriptionChangeset?.description?.length || 0;
                logCustomMapCreated({
                  distance: distance ? Math.round(distance * 100) / 100 : 0,
                  has_description: titleLength > 0 || descriptionLength > 0,
                  is_edit: !!updatedMap?.id,
                  map_source: routingState?.mapSource,
                  photos: updatedMap?.mapPhotos?.length || 0,
                  route_option: routingState?.routeOptions,
                  routes: routingState?.routes?.length || 0,
                  waypoints: waypointsState?.waypoints?.length || 0
                });
                saveAndDisplayMap(e);
              }}
              disabled={submittingState.isSubmitting}
              testId="map-creator-save-custom-map"
              variant="primary"
              width="full"
            />
          ) : (
            <Button text={EDIT_MAP} onClick={beginEditing} testId="map-creator-edit-map" variant="default" width="full" />
          )}
        </div>
      ) : (
        <div className={styles.panelJib} />
      )}
      {showCreatedMapModal && (
        <CreatedMapModal
          closeModal={closeCreatedMapModal}
          handleShareClick={handleShareClick}
          handleDirectionsClick={handleDirectionsClick}
          handleDownloadRoute={openDownloadModal}
          handlePrintClick={handlePrintClick}
        />
      )}
      {showReportModal && (
        <PortalModal isOpen={showReportModal}>
          <ReportIssuePage
            context={context}
            currentUserId={context.currentUser.id}
            entryPoint={EntryPoint.Maps}
            reportId={updatedMap.id}
            reportType="issue"
            reportUser={updatedMap.user}
            closeModal={() => setShowReportModal(false)}
            setSuccessToast={setReportingSuccessToast}
            analyticsData={{ location: ReportLocation.MapDetails, contentType: ReportContentType.Map }}
          />
        </PortalModal>
      )}
      {showMapPrivacyModal && updatedMap && (
        <PortalModal isOpen={showMapPrivacyModal}>
          <PrivacyMapUpdateModal
            data={updatedMap}
            type="customMaps"
            closeModal={() => setShowMapPrivacyModal(false)}
            user={map.user}
            onUpdate={onMapPrivacyUpdate}
          />
        </PortalModal>
      )}
    </div>
  );
};

export default MapCreatorPanel;
