import { string, number } from 'prop-types';
import * as styles from './styles/styles.module.scss';

const MapPopup = ({ name, latitude, longitude, dateString }) => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>{name}</div>
      <div className={styles.coordinates}>
        {dateString && <div>{dateString}</div>}
        {latitude && longitude && (
          <div>
            {latitude}, {longitude}
          </div>
        )}
      </div>
    </div>
  );
};

MapPopup.propTypes = {
  name: string.isRequired,
  latitude: number,
  longitude: number,
  dateString: string
};

MapPopup.defaultProps = {
  dateString: null,
  latitude: null,
  longitude: null
};

export default MapPopup;
