// https://www.figma.com/file/gZaqAej8HzoFkyrjlQ4cNs/Integrating-Brand-Changes?node-id=4771%3A93091

import { FormattedMessage } from '@alltrails/shared/react-intl';
import TrailTabType from 'types/TrailTabType';
import FrequentlyAskedQuestion, { FaqTitle } from 'components/FrequentlyAskedQuestion';
import { FaqWeatherForecast } from 'types/Trails/Trail';
import TrailWeatherFaq from 'components/TrailWeatherFaq';
import * as styles from './styles.module.scss';

type TrailFaqProps = {
  trailName: string;
  trailOverviewTabs: Array<TrailTabType>;
  faqWeatherForecast?: Array<FaqWeatherForecast>;
};

const FAQ_TABS = {
  facilities: 'parking_facilities',
  gettingThere: 'getting_there',
  tips: 'tips'
};

const TrailFaq = ({ trailName, trailOverviewTabs, faqWeatherForecast }: TrailFaqProps) => {
  const relevantTabs = Object.values(FAQ_TABS);
  const tabInfo = trailOverviewTabs.filter(t => relevantTabs.includes(t.key) && !!t.value?.trim());
  const weatherForecastAvailable = faqWeatherForecast?.length >= 5;

  const weatherFaqCount = weatherForecastAvailable ? 1 : 0;
  const faqCount = weatherFaqCount + tabInfo.length;
  if (faqCount < 2) {
    return null;
  }

  const faqTabQuestions = {
    [FAQ_TABS.facilities]: <FormattedMessage defaultMessage="What are some of the facilities available for {trailName}?" values={{ trailName }} />,
    [FAQ_TABS.tips]: <FormattedMessage defaultMessage="What are some tips to share about {trailName}?" values={{ trailName }} />,
    [FAQ_TABS.gettingThere]: <FormattedMessage defaultMessage="How to get to {trailName}?" values={{ trailName }} />
  };

  return (
    <section itemScope itemType="https://schema.org/FAQPage">
      <div className={styles.faqSection}>
        <FaqTitle>
          <FormattedMessage defaultMessage="Frequently asked questions about {trailName}" values={{ trailName }} />
        </FaqTitle>
        {tabInfo.map(tab => (
          <FrequentlyAskedQuestion question={faqTabQuestions[tab.key]} answer={tab.value} key={tab.key} />
        ))}
        {weatherForecastAvailable && <TrailWeatherFaq trailName={trailName} weatherForecast={faqWeatherForecast} />}
      </div>
    </section>
  );
};

export default TrailFaq;
