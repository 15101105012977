import { atMapsToGeojson } from '@alltrails/maps/utils/legacyGeoJSONConversions';
import { colorWithOpacityExpression } from '@alltrails/maps/utils/colors';
import { addPolylines, removePolylines } from './polylines';
import { addChevrons, removeChevrons } from './chevrons';
import { addStartEnd, removeStartEnd } from './start_end';
import { addMapWaypoints, removeWaypoints } from './waypoints';
import { addPhotos, removePhotos } from './photos';
import { addCurrLocMarker, removeCurrLocMarker } from './current_location';
import { updateLayerProperty } from '../layers/layer_helpers';

// Leave space for elevation graph at bottom and CTAs on the sides
const DEFAULT_AT_MAP_PADDING = { top: 25, bottom: 180, left: 100, right: 100 };
const atMapId = 'atMap';

const setBoundsFromAtMapGeojson = (atMapGeojson, bounds) => {
  atMapGeojson.features.map(atMapFeature => {
    const coords = atMapFeature.geometry.coordinates.flat();
    if (bounds) {
      coords.forEach(lngLat => bounds.extend(lngLat));
    }
  });
};

const addAtMap = ({
  map,
  atMap,
  renderWaypointPopup,
  handlePhotoClick,
  bounds,
  withWaypointLabels = true,
  messagingChannel,
  polylineOpts,
  isMapCreatorPage
}) => {
  if (!atMap) return;

  const atMapGeojson = atMapsToGeojson([atMap]);
  const multiCoordinates = atMapGeojson.features.map(atMapFeature => {
    const coords = atMapFeature.geometry.coordinates.flat();
    if (bounds) {
      coords.forEach(lngLat => bounds.extend(lngLat));
    }
    return coords;
  });

  const { waypoints, mapPhotos } = atMap;
  const photos = mapPhotos?.map(mapPhoto => mapPhoto.photo) ?? [];

  // Add all sources/layers
  addPolylines(map, `${atMapId}-polylines`, atMapGeojson, {} /* sourceOpts */, polylineOpts, null, isMapCreatorPage);
  addChevrons(map, `${atMapId}-chevrons`, `${atMapId}-polylines`);
  addStartEnd(map, `${atMapId}-start-end`, multiCoordinates);
  addCurrLocMarker(map);
  addMapWaypoints(map, `${atMapId}-waypoints`, waypoints, renderWaypointPopup, bounds, messagingChannel, atMap.editWaypointId, withWaypointLabels);
  addPhotos(map, `${atMapId}-photos`, photos, handlePhotoClick, bounds);
};

const removeAtMap = map => {
  removePhotos(map, `${atMapId}-photos`);
  removeWaypoints(map, `${atMapId}-waypoints`);
  removeCurrLocMarker(map);
  removeStartEnd(map, `${atMapId}-start-end`);
  removeChevrons(map, `${atMapId}-chevrons`);
  removePolylines(map, `${atMapId}-polylines`);
};

function updateLayerOpacity(map, opacity) {
  // Check if the layer exists. If not, getPaintProperty will throw an error.
  if (map.getLayer(`${atMapId}-polylines`)) {
    const polylinesColor = map.getPaintProperty(`${atMapId}-polylines`, 'line-color');
    updateLayerProperty(map, `${atMapId}-polylines`, 'paint', 'line-color', colorWithOpacityExpression(polylinesColor, opacity));
  }
}

const putAtMapIntoBackground = map => {
  updateLayerOpacity(map, 0.4);
  updateLayerProperty(map, `${atMapId}-chevrons`, 'paint', 'icon-opacity', 0.8);
  updateLayerProperty(map, `${atMapId}-start-end`, 'layout', 'visibility', 'none');
};

const bringAtMapIntoForeground = map => {
  updateLayerOpacity(map, 1);
  updateLayerProperty(map, `${atMapId}-chevrons`, 'paint', 'icon-opacity', 1);
  updateLayerProperty(map, `${atMapId}-start-end`, 'layout', 'visibility', 'visible');
};

const hideAtMap = map => {
  updateLayerOpacity(map, 0.5);
  updateLayerProperty(map, `${atMapId}-chevrons`, 'paint', 'icon-opacity', 0);
  updateLayerProperty(map, `${atMapId}-start-end`, 'paint', 'icon-opacity', 0);
  updateLayerProperty(map, `${atMapId}-waypoints`, 'layout', 'visibility', 'none');
};

const makeAtMapInvisible = map => {
  updateLayerProperty(map, `${atMapId}-polylines`, 'paint', 'line-opacity', 0);
}

const makeAtMapVisible = map => {
  updateLayerProperty(map, `${atMapId}-polylines`, 'paint', 'line-opacity', 1);
}

const showAtMap = map => {
  updateLayerOpacity(map, 1);
  updateLayerProperty(map, `${atMapId}-chevrons`, 'paint', 'icon-opacity', 1);
  updateLayerProperty(map, `${atMapId}-start-end`, 'paint', 'icon-opacity', 1);
  updateLayerProperty(map, `${atMapId}-waypoints`, 'layout', 'visibility', 'visible');
};

export {
  addAtMap,
  removeAtMap,
  putAtMapIntoBackground,
  bringAtMapIntoForeground,
  atMapId,
  hideAtMap,
  showAtMap,
  makeAtMapInvisible,
  makeAtMapVisible,
  setBoundsFromAtMapGeojson,
  DEFAULT_AT_MAP_PADDING
};
