import { Component } from 'react';
import { defineMessages, injectIntl } from '@alltrails/shared/react-intl';
import IconButton from '@alltrails/shared/denali/components/IconButton';
import Close from '@alltrails/shared/icons/Close';
import Search from '@alltrails/shared/icons/Search';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { COLOR_NEUTRAL_WHITE } from '@alltrails/shared/denali/tokens';
import * as styles from './styles/styles.module.scss';
import * as algoliaSearchBoxStyles from 'components/AlgoliaSearchBox/styles/styles.module.scss';
import { ServerCommunicationUtil } from '../../../utils/server_communication_util';
import MapboxSearchResults from '../MapboxSearchResults';
import { coordinatesGeocoder } from '../../../utils/geocoder_helpers';
import CustomProvider from 'components/CustomProvider';

const messages = defineMessages({
  placeholder: {
    defaultMessage: 'Search or click on the map to start'
  }
});

class MapboxSearchBox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      text: '',
      features: [],
      showDropdown: false
    };
  }

  apiSuccessCallback = response => {
    let { features } = response;

    features = features.map(feature => {
      return {
        latitude: feature.center[1],
        longitude: feature.center[0],
        placeName: feature.place_name
      };
    });

    this.setState({ features, showDropdown: true });
  };

  handleSelectFeature = idx => {
    const { placeName } = this.state.features[idx];
    this.props.handleFindLocationSelect(this.state.features[idx]);

    this.setState({ text: placeName, showDropdown: false });
  };

  handleApiError = err => {
    this.setState({ showDropdown: false });
  };

  handleApiComplete = () => {};

  hitMapboxApi = () => {
    if (this.state.text.length === 0) {
      this.setState({ features: [], showDropdown: false });
      return;
    }
    // check if text is a coordinate
    let text = coordinatesGeocoder(this.state.text);
    text = encodeURIComponent(text);
    let url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${text}.json?types=address,place,region,poi,district&access_token=${this.props.mapboxAccessToken}`;
    if (this.props.languageRegionCode) {
      url = `${url}&language=${this.props.languageRegionCode.substring(0, 2)}`;
    }

    ServerCommunicationUtil.getApiEndpoint(url, {}, this.apiSuccessCallback, this.handleApiError, this.handleApiComplete);
  };

  handleOnChange = e => {
    e.preventDefault();

    this.setState({ text: e.currentTarget.value }, this.hitMapboxApi);
  };

  onKeyPress = e => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  clearText = () => {
    this.setState({ text: '', features: [], showDropdown: false });
  };

  renderClearOrSearch() {
    if (this.state.text.length === 0) {
      return <Search color={COLOR_NEUTRAL_WHITE} />;
    }

    return (
      <IconButton icon={{ Component: Close, color: COLOR_NEUTRAL_WHITE }} onClick={this.clearText} testId="clear" title="Clear" variant="flat" />
    );
  }

  render() {
    return (
      <CustomProvider>
        <div className={styles.container}>
          <div className={algoliaSearchBoxStyles.form}>
            <input
              placeholder={this.props.intl.formatMessage(messages.placeholder)}
              data-testid="mapBoxSearchTextField"
              className={algoliaSearchBoxStyles.input}
              type="search"
              name="Search"
              onChange={this.handleOnChange}
              onKeyPress={this.onKeyPress}
              value={this.state.text}
            />
            <div className={classNames(algoliaSearchBoxStyles.button, styles.searchButton)}>{this.renderClearOrSearch()}</div>
          </div>
          {this.state.showDropdown && <MapboxSearchResults features={this.state.features} selectFeature={this.handleSelectFeature} />}
        </div>
      </CustomProvider>
    );
  }
}

MapboxSearchBox.defaultProps = {
  mapboxAccessToken: '',
  handleFindLocationSelect: () => {}
};
MapboxSearchBox.propTypes = {
  mapboxAccessToken: PropTypes.string,
  handleFindLocationSelect: PropTypes.func
};

export default injectIntl(MapboxSearchBox);
