import TrailRealTimeWidgetsShimmer from '../TrailRealTimeWidgetsShimmer';
import TrailWeatherOverviewShimmer from '../TrailWeatherOverviewShimmer';
import TrailUpsellRiverShimmer from '../TrailUpsellRiverShimmer';

const TrailWeatherBaseShimmer = ({ hasPlus = false }): JSX.Element => (
  <>
    <TrailWeatherOverviewShimmer />
    {hasPlus ? <TrailRealTimeWidgetsShimmer /> : <TrailUpsellRiverShimmer />}
  </>
);

export default TrailWeatherBaseShimmer;
