import { useEffect, useState } from 'react';
import { useIntl } from '@alltrails/shared/react-intl';
import Button from '@alltrails/shared/denali/components/Button';
import Phone from 'components/Phone';
import ReferralLayout from 'components/ReferralLayout';
import DownloadAppButton from 'components/DownloadAppButton';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import { sendSms } from 'api/Notifications';
import logInvitedUserDownloadPageViewed from '@alltrails/analytics/events/logInvitedUserDownloadPageViewed';
import * as styles from './styles.module.scss';
import smallAvif from './landing-page-2-w750.avif';
import smallWebp from './landing-page-2-w750.webp';
import smallJpg from './landing-page-2-w750.jpg';
import largeAvif from './landing-page-2-w2800.avif';
import largeWebp from './landing-page-2-w2800.webp';
import largeJpg from './landing-page-2-w2800.jpg';

function ReferralDownload() {
  const languageRegionCode = useLanguageRegionCode();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isWorking, setIsWorking] = useState(false);
  const [success, setSuccess] = useState();
  const [error, setError] = useState();
  const intl = useIntl();

  function handleChange(isValid, newNumber, countryData, fullNumber) {
    setPhoneNumber(fullNumber);
  }

  async function sendTextMessage() {
    try {
      setIsWorking(true);

      await sendSms(languageRegionCode, phoneNumber);

      setSuccess(true);
    } catch (e) {
      setError(true);
    } finally {
      setIsWorking(false);
    }
  }

  useEffect(() => {
    logInvitedUserDownloadPageViewed();
  }, []);

  return (
    <>
      <ReferralLayout
        activeStep={1}
        images={{
          mobile: { avif: smallAvif, webp: smallWebp, jpg: smallJpg },
          desktop: { avif: largeAvif, webp: largeWebp, jpg: largeJpg }
        }}
        heroAlt={intl.formatMessage({
          defaultMessage:
            'Two people facing away with brown backpacks on, with one person wearing a maroon hat. They are looking over a landscape of rolling green hills. '
        })}
        heading={intl.formatMessage({ defaultMessage: 'Just one more step' })}
        paragraphText={intl.formatMessage({
          defaultMessage: "Thanks for providing your email. Now, just download the app - and we'll plant one tree for you and one for your friend."
        })}
      >
        <div className={styles.link}>
          <p className={styles.sendALink}>{intl.formatMessage({ defaultMessage: 'Send a link to my phone' })}</p>
          <Phone classes={styles.phone} error={error} onChange={handleChange} />
          <Button
            text={success ? intl.formatMessage({ defaultMessage: 'Sent' }) : intl.formatMessage({ defaultMessage: 'Send link' })}
            disabled={isWorking}
            loading={isWorking}
            onClick={sendTextMessage}
            testId="referral-download-send-link"
            variant="primary"
          />
        </div>
        {/* wrapper required because of the coded display: flex in monorepo */}
        <div className={styles.download}>
          <DownloadAppButton />
        </div>
      </ReferralLayout>
    </>
  );
}

export default ReferralDownload;
