// extracted by mini-css-extract-plugin
export var alertExpiration = "styles-module__alertExpiration___we9Xc";
export var alertList = "styles-module__alertList___gv6ec";
export var displayText = "styles-module__displayText___lr7Zp";
export var editButton = "styles-module__editButton___g35Sm";
export var hidden = "styles-module__hidden___I0CFp";
export var languageContainer = "styles-module__languageContainer___HaMgI";
export var lineClamp = "styles-module__lineClamp___IawHc";
export var rowReverse = "styles-module__rowReverse___Wq_01";
export var showMoreBtn = "styles-module__showMoreBtn___woWuO";
export var textAndBtnContainer = "styles-module__textAndBtnContainer___Mc5Y_";
export var trailDetailItemContainer = "styles-module__trailDetailItemContainer___t6xpF";