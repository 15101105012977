const STORAGE_KEY_ADMIN_BASEMAP = "adminBasemapUrl";
const STORAGE_KEY_ADMIN_OVERLAY = "adminOverlayUrl";

const sanityCheckSourceUrl = url => {
  if (!url || url.length < 20) {
    return false;
  }
  let parsedUrl;
  try {
    parsedUrl = new URL(url);
  } catch (_) {
    return false;
  }
  if (parsedUrl.protocol !== "http:" && parsedUrl.protocol !== "https:") {
    return false;
  }
  // The x,y,z params are required, but can be in any order
  if (url.indexOf('{x}') === -1 || url.indexOf('{y}') === -1 || url.indexOf('{z}') === -1) {
    return false;
  }
  return true;
};

const getAdminCustomizationSettings = () => {
  if (typeof window !== 'undefined' && window.localStorage) {
    return {
      basemapSourceUrl: window.localStorage.getItem(STORAGE_KEY_ADMIN_BASEMAP) || "",
      overlaySourceUrl: window.localStorage.getItem(STORAGE_KEY_ADMIN_OVERLAY) || ""
    }
  }
  return {}
};

const setAdminCustomizationSetting = (key, value) => {
  if (typeof window !== 'undefined' && window.localStorage) {
    let storageKey;
    if (key === "basemapSourceUrl" || key === "overlaySourceUrl") {
      if (!value) {
        value = "";
      } else if (!sanityCheckSourceUrl(value)) {
        return;
      }
    }
    if (key === "basemapSourceUrl") {
      storageKey = STORAGE_KEY_ADMIN_BASEMAP
    }
    else if (key === "overlaySourceUrl") {
      storageKey = STORAGE_KEY_ADMIN_OVERLAY
    }
    else {
      return;
    }
    window.localStorage.setItem(storageKey, value);
  }
};

export { sanityCheckSourceUrl, getAdminCustomizationSettings, setAdminCustomizationSetting };
