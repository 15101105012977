import classNames from 'classnames';
import Button from '@alltrails/shared/denali/components/Button';
import { FormattedMessage } from '@alltrails/shared/react-intl';
import { ReactNode } from 'react';
import * as styles from './styles/styles.module.scss';

type Props = {
  title: ReactNode;
  config: {
    min: number;
    max: number;
    total: number;
    whiteBackground?: boolean;
  };
  handler: () => void;
};

const ShowMoreItems = ({ title, config, handler }: Props): JSX.Element => {
  if (config.max >= config.total) {
    return null;
  }

  return (
    <div className={classNames(styles.container, 'xlate-none')}>
      <Button text={title} onClick={handler} testId="" variant={config.whiteBackground ? 'default' : 'primary'} />
      <div className={styles.showing}>
        <FormattedMessage
          defaultMessage="Showing results {min} - {max} of {total}"
          values={{ min: config.min, max: config.max, total: config.total }}
        />
      </div>
    </div>
  );
};

export default ShowMoreItems;
