import Share from '@alltrails/shared/icons/Share';
import Directions from '@alltrails/shared/icons/Directions';
import Download from '@alltrails/shared/icons/Download';
import Print from '@alltrails/shared/icons/Print';
import Modal from 'components/Modal';
import { FormattedMessage } from '@alltrails/shared/react-intl';
import ShareModalDrawer from '../../shared/ShareModalDrawer';

type Props = {
  closeModal: () => void;
  handleShareClick?: () => void;
  handleDirectionsClick?: () => void;
  handleDownloadRoute?: () => void;
  handlePrintClick?: () => void;
};

const CreatedMapModal = ({ closeModal, handleShareClick, handleDirectionsClick, handleDownloadRoute, handlePrintClick }: Props) => (
  <Modal onRequestClose={closeModal} isOpen title={<FormattedMessage defaultMessage="New map created" />}>
    <ShareModalDrawer title={<FormattedMessage defaultMessage="Share map" />} icon={<Share />} onClick={handleShareClick} />
    <ShareModalDrawer title={<FormattedMessage defaultMessage="Directions" />} icon={<Directions />} onClick={handleDirectionsClick} />
    <ShareModalDrawer title={<FormattedMessage defaultMessage="Download route" />} icon={<Download />} onClick={handleDownloadRoute} />
    <ShareModalDrawer title={<FormattedMessage defaultMessage="Print map" />} icon={<Print />} onClick={handlePrintClick} />
  </Modal>
);

export default CreatedMapModal;
