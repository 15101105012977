import { useState, useMemo, useCallback, useEffect, useRef } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import CarouselDisplayTrigger from '@alltrails/analytics/enums/CarouselDisplayTrigger';
import MapProvider from '@alltrails/maps/components/MapProvider';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import { wrapUrlSafe } from '@alltrails/shared/utils/languageSupport';
import { fetchCollaborators, listSync, getListItems, getListDetails } from '@alltrails/shared/utils/requests/listRequests';
import { completedListId, favoritesListId, verifiedListId } from '@alltrails/modules/Lists/listUtils';
import { PageStrings } from '@alltrails/shared/utils/constants/pageStringHelpers';
import ListCollaborator from '@alltrails/shared/types/ListCollaborator';
import { ListItem } from '@alltrails/shared/types/lists';
import { GeoLocationProvider } from 'components/explore/GeoLocationProvider';
import SearchInsightsProvider from 'components/SearchInsightsProvider';
import ErrorFallback from 'components/shared/ErrorFallback';
import { Page, PageSource } from 'components/shared/Page/Page';
import useResizeListener from 'hooks/useResizeListener';
import type { Context } from 'types/Context';
import logError from 'utils/logError';
import CustomProvider from 'components/CustomProvider';
import SignupModal from 'components/SignupModal';
import useToggle from '@alltrails/shared/hooks/useToggle';
import { pathToRegexp } from 'path-to-regexp';
import useUser from 'hooks/useUser';
import { MapExpandProvider } from 'components/MapExpandControl/MapExpandProvider';
import { GarminProvider } from 'components/GarminSettings/GarminProvider/GarminProvider';
import compareUserIds from '@alltrails/shared/utils/compareUserIds';
import hasPermission from 'utils/hasPermission';
import getUTMAttribution from 'utils/getUTMAttribution';
import ApplicationLayout from 'components/ApplicationLayout';
import useListItems from '../../hooks/useListItems';
import NewFullscreenSearchApp from './NewFullscreenSearchApp';

type Props = {
  context: Context;
  initialCustomList: any;
  initialMyLists: any;
  initialMyListItems: any;
  initialPage: string;
  initialUserListItems: any;
  userInfo: any;
  locationData: {
    city: {
      names: { [key: string]: any[] };
    };
    country: string;
    latLng: (string | number)[];
  };
  trailPageProps?: any;
  initialSelectedObject?: any;
  initialExploreMap?: any;
};

const listAccessForAdminOrCurrentUserOrCollaborator = ({ context, userInfo }: Props, page: string, collaborators?: ListCollaborator[]) => {
  if (hasPermission({ permission: 'trails:manage' }) && page === PageStrings.EXPLORE_COMPLETED_PAGE) {
    return true;
  }

  if (page === PageStrings.EXPLORE_CUSTOM_PAGE && collaborators) {
    return collaborators.some(collaborator => String(collaborator.user.id) === String(context?.currentUser?.id));
  }

  return compareUserIds(userInfo?.id, context?.currentUser?.id);
};

const editingOtherUserListIds = ({ context, userInfo }: Props, page: string) => {
  if (
    hasPermission({ permission: 'trails:manage' }) &&
    page === PageStrings.EXPLORE_COMPLETED_PAGE &&
    !compareUserIds(userInfo?.id, context?.currentUser?.id)
  ) {
    return [verifiedListId];
  }

  return [];
};

type ListItemsData = Record<number, Record<string, Record<number, { id: number; order: number }>>>;

const areListItemsChanged = (list1: ListItemsData | null, list2: ListItemsData | null) => {
  /** 
    list data
    [listId]: {
    [type1-trail]: {
      [trailId]: { id: number, order: number }
    },
    [type2-map]: {
      [mapId]: { id: number, order: number }
    },
    [type2-activity]: {
      [trackId]: { id: number, order: number }
    }
  }
*/
  if (!list1 || !list2) {
    return true;
  }
  const list1Keys = Object.keys(list1);
  const list2Keys = Object.keys(list2);
  if (list1Keys.length !== list2Keys.length) {
    return true;
  }
  const areEqual = list1Keys.every(key => {
    const list1Value = list1[parseInt(key)];
    const list2Value = list2[parseInt(key)];
    const innerKeys1 = Object.keys(list1Value);
    const innerKeys2 = Object.keys(list2Value);

    if (innerKeys1.length !== innerKeys2.length) {
      return false;
    }

    return innerKeys1.every(innerKey => {
      const innerValue1 = list1Value[innerKey];
      const innerValue2 = list2Value[innerKey];

      const innerValueKeys1 = Object.keys(innerValue1);
      const innerValueKeys2 = Object.keys(innerValue2);

      if (innerValueKeys1.length !== innerValueKeys2.length) {
        return false;
      }

      return innerValueKeys1.every(subKey => innerValue1[parseInt(subKey)] === innerValue2[parseInt(subKey)]);
    });
  });
  return !areEqual;
};

const SearchApp = (props: Props) => {
  const user = useUser();
  const languageRegionCode = useLanguageRegionCode();
  const [isOpen, toggle] = useToggle(!user && pathToRegexp('/:locale?/explore/map/new').test(__AT_DATA__.pathname));
  const [isMemorySignupModalOpen, toggleMemorySignupModal] = useToggle(false);
  const [page, setPage] = useState(props.initialPage || PageStrings.EXPLORE_ALL_PAGE);
  const [isMobileWidth, setIsMobileWidth] = useState(props.context.mobileBrowser || false);
  const listId = useMemo(() => {
    switch (page) {
      case PageStrings.EXPLORE_COMPLETED_PAGE:
        return completedListId;
      case PageStrings.EXPLORE_CUSTOM_PAGE:
        return props.initialCustomList?.id.toString();
      case PageStrings.EXPLORE_FAVORITE_PAGE:
        return favoritesListId;
      default:
        return null;
    }
  }, [page, props.initialCustomList]);
  const exploreTrailId = props.trailPageProps?.trail?.verified_map_id;
  const activityTrailId = props?.initialSelectedObject?.ID;
  const verifiedMapId = exploreTrailId || activityTrailId;
  const isListPage = page === PageStrings.EXPLORE_CUSTOM_PAGE;

  const [collaborators, setCollaborators] = useState<ListCollaborator[]>([]);
  const [areCollaboratorsLoading, setAreCollaboratorsLoading] = useState<boolean>(false);
  const [stopCollaboratorsPolling, setStopCollaboratorsPolling] = useState<boolean>(false);
  const [collaboratorsPollingRetryCount, setCollaboratorsPollingRetryCount] = useState<number>(0);
  const [stopListSyncPolling, setStopListSyncPolling] = useState<boolean>(false);
  const [listSyncPollingRetryCount, setListSyncPollingRetryCount] = useState<number>(0);
  const [openCollaboratorsModal, setIsCollaboratorsModalOpen] = useState<boolean>(false);
  const [hasFetchedInitialData, setHasFetchedInitialData] = useState<boolean>(false);
  const collaboratorsPollingTimerRef = useRef<ReturnType<typeof setInterval> | null>(null);
  const listSyncPollingTimerRef = useRef<ReturnType<typeof setInterval> | null>(null);
  const [listItemsData, setListItemsData] = useState(null);
  const [listDetailsData, setListDetailsData] = useState(null);
  const [updatedListItems, setUpdatedListItems] = useState(null);
  const [areListItemsLoading, setAreListItemsLoading] = useState(isListPage);
  const [areListDetailsLoading, setAreListDetailsLoading] = useState(isListPage);
  const belongsToCurrentUser = listAccessForAdminOrCurrentUserOrCollaborator(props, page, collaborators);
  const prevListItems = useRef(updatedListItems);

  const fetchListItemsData = useCallback(async () => {
    try {
      const itemsData = await getListItems(listId);
      const obj = itemsData.listItems.map((items: ListItem) => ({ ...items.object, updated: items.metadata.updated }));
      setListItemsData(obj);
      const updatedListItemsData: ListItemsData = {};
      // format updated list items' data to match the format used in Rails
      // [type]: { [objectId]: { id, order } }
      itemsData.listItems.forEach(({ id, listId, objectId, type, order }) => {
        if (!updatedListItemsData[listId]) {
          updatedListItemsData[listId] = {};
        }
        if (!updatedListItemsData[listId][type]) {
          updatedListItemsData[listId][type] = {};
        }
        updatedListItemsData[listId][type][objectId] = { id, order };
      });
      if (Object.keys(updatedListItemsData).length === 0) {
        // add listId if data is empty
        updatedListItemsData[listId] = {};
      }
      // add completed and verified completed magic ID's
      if (props.initialMyListItems) {
        updatedListItemsData[completedListId] = props.initialMyListItems[completedListId];
        updatedListItemsData[verifiedListId] = props.initialMyListItems[verifiedListId];
        updatedListItemsData[favoritesListId] = props.initialMyListItems[favoritesListId];
      }
      setUpdatedListItems(updatedListItemsData);
      setAreListItemsLoading(false);
    } catch (e) {
      setAreListItemsLoading(false);
      logError(e);
    }
  }, [listId]);

  const fetchListDetailsData = useCallback(async () => {
    try {
      const detailsData = await getListDetails(listId);
      setListDetailsData(detailsData.lists[0]);
      setAreListDetailsLoading(false);
    } catch (e) {
      logError(e);
      setAreListDetailsLoading(false);
    }
  }, [listId]);

  useEffect(() => {
    if (isListPage && listId) {
      fetchListItemsData();
      fetchListDetailsData();
    }
    // initial render
  }, []);

  const {
    listMethods,
    renderModal,
    renderCollabListPrivacyModal,
    renderListToast,
    listItems,
    lists,
    latestListAction,
    userListItems,
    renderSignUpModal
  } = useListItems(
    {
      lists: props.initialMyLists,
      listItems: isListPage && belongsToCurrentUser && listId ? updatedListItems : props.initialMyListItems,
      userListItems: props.initialUserListItems,
      belongsToCurrentUser: belongsToCurrentUser && listId, // this decides whether or not to edit an individual users list
      editingOtherUserListIds: editingOtherUserListIds(props, page)
    },
    page
  );

  useEffect(() => {
    if (areListItemsChanged(prevListItems.current, updatedListItems) && isListPage && belongsToCurrentUser) {
      listMethods.updateListItems(updatedListItems);
    }
    prevListItems.current = updatedListItems;
  }, [updatedListItems, listMethods, belongsToCurrentUser, isListPage]);

  const fetchCollaboratorsData = useCallback(async () => {
    try {
      const data = await fetchCollaborators(listId);
      setCollaborators(data.collaborators);
      setHasFetchedInitialData(true);
      setAreCollaboratorsLoading(false);
    } catch (e) {
      setAreCollaboratorsLoading(false);
      logError(e);

      setCollaboratorsPollingRetryCount(prevRetryCount => prevRetryCount + 1);

      if (collaboratorsPollingRetryCount >= 2) {
        // stop retrying after 2x failed calls
        setStopCollaboratorsPolling(true);
      }
    }
  }, [listId, setCollaborators, setHasFetchedInitialData, setAreCollaboratorsLoading, collaboratorsPollingRetryCount]);

  useEffect(() => {
    // polling on the collaborative list to have "real time" updates
    // poll at a frequency of 5 seconds
    const pollInterval = 5000;
    const startPollingAndFetchInitialData = async () => {
      if (page === PageStrings.EXPLORE_CUSTOM_PAGE && listId && !openCollaboratorsModal && !stopCollaboratorsPolling && props.context?.currentUser) {
        if (!hasFetchedInitialData) {
          setAreCollaboratorsLoading(true);
          fetchCollaboratorsData();
        }

        collaboratorsPollingTimerRef.current = setInterval(() => fetchCollaboratorsData(), pollInterval);
      }
    };
    // initial collaborators fetch when the component mounts
    startPollingAndFetchInitialData();

    return () => {
      if (collaboratorsPollingTimerRef.current) {
        clearInterval(collaboratorsPollingTimerRef.current);
      }
    };
  }, [
    page,
    listId,
    stopCollaboratorsPolling,
    hasFetchedInitialData,
    collaboratorsPollingRetryCount,
    fetchCollaboratorsData,
    openCollaboratorsModal,
    props.context
  ]);

  const fetchListSync = useCallback(
    async (lastSyncTime: string) => {
      try {
        const data = await listSync(lastSyncTime);
        if (data.updated_ids.map(String).includes(listId)) {
          fetchListItemsData();
          fetchListDetailsData();
        }
      } catch (e) {
        logError(e);
        setListSyncPollingRetryCount(prevRetryCount => prevRetryCount + 1);

        if (listSyncPollingRetryCount >= 2) {
          // stop retrying after 2x failed calls
          setStopListSyncPolling(true);
        }
      }
    },
    [listSyncPollingRetryCount, listId]
  );

  useEffect(() => {
    // polling on the list sync to have "real time" updates
    // poll at a frequency of 5 seconds
    const lastSyncTime = new Date().toString();
    const pollInterval = 5000;
    const startListSyncPolling = async () => {
      if (isListPage && !openCollaboratorsModal && listId && !stopListSyncPolling && props.context?.currentUser) {
        listSyncPollingTimerRef.current = setInterval(() => fetchListSync(lastSyncTime), pollInterval);
      }
    };
    startListSyncPolling();

    return () => {
      if (listSyncPollingTimerRef.current) {
        clearInterval(listSyncPollingTimerRef.current);
      }
    };
  }, [listId, isListPage, page, openCollaboratorsModal, fetchListSync, stopListSyncPolling, props.context]);

  const updatePage = (newPage: string) => {
    setPage(newPage);
  };

  const retryErrorPage = () => {
    window.location.reload();
  };

  const onResize = useCallback(() => {
    setIsMobileWidth(window.innerWidth <= 767);
  }, []);

  useResizeListener({ onResize });

  useEffect(() => {
    if (page === PageStrings.EXPLORE_USERS_TRACKS_MAP_PAGE && !user) {
      const params = new URLSearchParams(window.location.search);
      const utmAttribution = getUTMAttribution(params);
      if (utmAttribution?.length > 0 && utmAttribution[0].value === 'memory') {
        toggleMemorySignupModal();
      }
    }
  }, [page, user, toggleMemorySignupModal]);

  return (
    <Page pageSource={PageSource.Explore} context={props.context}>
      <SignupModal
        isOpen={isMemorySignupModalOpen}
        onRequestClose={() => {
          window.location.assign(wrapUrlSafe('/', languageRegionCode));
          toggleMemorySignupModal();
        }}
        onSuccess={() => {
          toggleMemorySignupModal();
        }}
      />
      <SignupModal
        trigger={CarouselDisplayTrigger.CreateMap}
        isOpen={isOpen}
        onRequestClose={() => {
          window.location.assign(document.referrer);
        }}
        onSuccess={() => {
          toggle();
        }}
      />
      <GeoLocationProvider>
        <SearchInsightsProvider context={props.context} pageName={PageSource.Explore}>
          {renderModal()}
          {renderSignUpModal()}
          {renderCollabListPrivacyModal()}
          {renderListToast()}
          <ErrorBoundary fallback={<ErrorFallback retryErrorPage={retryErrorPage} />} onError={e => logError(e)}>
            <MapProvider>
              <MapExpandProvider>
                <GarminProvider user={user} mapId={verifiedMapId} garminCourse={props.initialExploreMap?.garminCourse}>
                  <NewFullscreenSearchApp
                    {...props}
                    isMobileWidth={isMobileWidth}
                    latestListAction={latestListAction}
                    lists={lists}
                    listItems={listItems}
                    page={page}
                    userListItems={userListItems}
                    listMethods={listMethods}
                    listId={listId}
                    belongsToCurrentUser={belongsToCurrentUser}
                    updatePage={updatePage}
                    collaborators={collaborators}
                    setCollaborators={setCollaborators}
                    areCollaboratorsLoading={areCollaboratorsLoading}
                    openCollaboratorsModal={openCollaboratorsModal}
                    setIsCollaboratorsModalOpen={setIsCollaboratorsModalOpen}
                    listItemsData={listItemsData}
                    listDetailsData={listDetailsData}
                    areListItemsLoading={areListItemsLoading}
                    areListDetailsLoading={areListDetailsLoading}
                  />
                </GarminProvider>
              </MapExpandProvider>
            </MapProvider>
          </ErrorBoundary>
        </SearchInsightsProvider>
      </GeoLocationProvider>
    </Page>
  );
};

export default function SearchAppWrapper(props: Props) {
  return (
    <ApplicationLayout context={props.context} edgeToEdge>
      <CustomProvider>
        <SearchApp {...props} />
      </CustomProvider>
    </ApplicationLayout>
  );
}
