import createRasterStyle from '@alltrails/maps/utils/raster';
import { BaseStyleId } from '@alltrails/maps/types/Styles';
import { IntlShape } from '@alltrails/shared/react-intl';
import { loadVectorStyle } from './style_helpers';
import { sanityCheckSourceUrl } from './admin_customization_helpers';

// Note: all non-raster styles have attributions embeded in sources/layers already
const baseStyleLoaders: Record<
  BaseStyleId,
  (
    locale: string,
    displayMetric: boolean,
    intl?: IntlShape,
    adminCustomizationSettings?: { overlaySourceUrl?: string; basemapSourceUrl?: string }
  ) => Promise<any>
> = {
  alltrailsOutdoorsV2: (locale: string, displayMetric: boolean, intl?: IntlShape) =>
    loadVectorStyle(`alltrails/${__AT_DATA__.mapboxStyleIds.alltrails}`, locale, displayMetric, intl),
  alltrailsSatellite: (locale: string, displayMetric: boolean, intl?: IntlShape) =>
    loadVectorStyle(`alltrails/${__AT_DATA__.mapboxStyleIds.satellite}`, locale, displayMetric, intl),
  googleSatellite: () =>
    Promise.resolve(
      createRasterStyle('googleSatellite', 'https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}', {
        attribution: 'Map data ©2023',
        tileSize: 256,
        maxzoom: 19
      })
    ),
  adminBasemap: (
    locale: string,
    displayMetric: boolean,
    intl?: IntlShape,
    adminCustomizationSettings?: { overlaySourceUrl?: string; basemapSourceUrl?: string }
  ) => {
    if (!adminCustomizationSettings || !sanityCheckSourceUrl(adminCustomizationSettings.basemapSourceUrl)) {
      return Promise.reject(new Error('invalid data'));
    }
    return Promise.resolve(
      createRasterStyle('adminBasemap', adminCustomizationSettings.basemapSourceUrl, {
        tileSize: 256,
        maxzoom: 19
      })
    );
  },
  opencycle: () =>
    Promise.resolve(
      createRasterStyle('opencycle', 'https://tiles-ocm.alltrails.com/{z}/{x}/{y}.png', {
        attribution: '© <a href="http://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a> contributors',
        tileSize: 256,
        maxzoom: 19,
        minzoom: 4
      })
    ),
  openstreet: () =>
    Promise.resolve(
      createRasterStyle('openstreet', 'https://tiles-osm.alltrails.com/{z}/{x}/{y}.png', {
        attribution: '© <a href="http://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a> contributors',
        tileSize: 256,
        maxzoom: 19,
        minzoom: 4
      })
    ),
  roadmap: (locale: string, displayMetric: boolean, intl?: IntlShape) => loadVectorStyle('mapbox/streets-v10', locale, displayMetric, intl),
  topo: () =>
    Promise.resolve(
      createRasterStyle('topo', 'https://us-topo.alltrails.com/{z}/{x}/{y}.png', {
        attribution: '© 2020 AllTrails',
        tileSize: 256,
        maxzoom: 16,
        minzoom: 4
      })
    ),
  worldparks: (locale: string, displayMetric: boolean, intl?: IntlShape) =>
    loadVectorStyle(`alltrails/${__AT_DATA__.mapboxStyleIds.terrain}`, locale, displayMetric, intl),
  worldtopo: () =>
    Promise.resolve(
      createRasterStyle('worldtopo', 'https://tiles-wtopo.alltrails.com/{z}/{y}/{x}.png', {
        attribution: 'Copyright © 2013 ESRI',
        tileSize: 256,
        maxzoom: 19
      })
    )
};

export default baseStyleLoaders;
