import SearchFilter from 'components/explore/filters/SearchFilter';
import NestedPanel from 'components/explore/filters/NestedPanel';
import DropdownGroup from '@alltrails/shared/components/DropdownGroup';
import useFeatures from 'hooks/useFeatures';
import useFiltersContext from 'hooks/useFiltersContext';
import useUser from 'hooks/useUser';
import { useIntl } from '@alltrails/shared/react-intl';
import type { Context } from 'types/Context';
import { FilterViewModel } from 'types/Search';
import hasPermission from 'utils/hasPermission';
import useMobileWidth from 'hooks/useMobileWidth';
import SavedFilters from '../filters/SavedFilters';
import SaveFilters from '../filters/SaveFilters';
import * as styles from './styles/styles.module.scss';

export type Props = {
  context: Context;
  enableSavedFilters?: boolean;
  enabledFilters: string[];
  allFiltersForMobile?: FilterViewModel[];
};

const FilterBar = ({ context, enabledFilters, enableSavedFilters, allFiltersForMobile }: Props) => {
  const intl = useIntl();
  const features = useFeatures();
  const { savedFilterSets, sortedFilters } = useFiltersContext();
  const user = useUser();
  const isMobile = useMobileWidth();

  const reducedFilters = sortedFilters.reduce(
    (memo, nextFilter) => {
      if (!enabledFilters.includes(nextFilter.enabledKey)) {
        return memo;
      }

      // The admin modal and nested modal filters are mutually exclusive.
      if (nextFilter.admin) {
        // It's an admin filter, but only render it if it has data.
        if (Object.keys(nextFilter.config).length) {
          memo.adminFilters.push(nextFilter);
        }
      } else if (nextFilter.nested) {
        memo.moreFilters.push(nextFilter);
      } else {
        // Everything else is a top-level filter.
        memo.standardFilters.push(nextFilter);
      }

      return memo;
    },
    { adminFilters: [], moreFilters: [], standardFilters: [] }
  );

  return (
    <DropdownGroup>
      {isMobile && allFiltersForMobile?.length && (
        <NestedPanel sortedFilters={allFiltersForMobile} title={intl.formatMessage({ defaultMessage: 'All' })} user={user} />
      )}
      {features.includes('saved_filters') && enableSavedFilters && savedFilterSets.length !== 0 && (
        <SavedFilters context={context} popperClassName={styles.popper} />
      )}
      {reducedFilters.standardFilters.map((filter: FilterViewModel) => (
        <SearchFilter key={filter.key} filter={filter} popperClassName={styles.popper} user={user} />
      ))}
      {reducedFilters.moreFilters.length > 1 && (
        <NestedPanel
          popperClassName={styles.popper}
          sortedFilters={reducedFilters.moreFilters}
          title={intl.formatMessage({ defaultMessage: 'More filters' })}
          user={user}
        />
      )}
      {reducedFilters.adminFilters.length > 0 && hasPermission({ permission: 'trails:manage' }) && (
        <NestedPanel sortedFilters={reducedFilters.adminFilters} popperClassName={styles.popper} title="Admin" user={user} />
      )}
      {features.includes('saved_filters') && enableSavedFilters && <SaveFilters />}
    </DropdownGroup>
  );
};

export default FilterBar;
