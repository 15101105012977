import { IntlShape } from '@alltrails/shared/react-intl';
import { CM_IN_INCH } from 'utils/constants/conversion';
import { MIN_DIMENSION, MAX_DIMENSION } from './constants';

export const toInches = (value: number, metric: boolean) => (metric && value !== undefined ? value / CM_IN_INCH : value);
export const fromInches = (value: number, metric: boolean) => (metric ? value * CM_IN_INCH : value);

export const validDimension = (dimension: number, metric: boolean) => {
  const value = toInches(dimension, metric);
  return value >= MIN_DIMENSION && value <= MAX_DIMENSION;
};

export const getUnit = (metric: boolean, intl: IntlShape) =>
  intl
    .formatNumberToParts(1, {
      style: 'unit',
      unit: metric ? 'centimeter' : 'inch'
    })
    .find(part => part.type === 'unit')?.value || '';

export const formatDimension = (value: number, metric: boolean, intl: IntlShape) =>
  intl.formatNumber(fromInches(value, metric), {
    style: 'unit',
    unitDisplay: 'narrow',
    maximumFractionDigits: 1,
    unit: metric ? 'centimeter' : 'inch'
  });
