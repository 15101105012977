const TEMP_CONVERSION_FACTOR = 1.8;
const FAHRENHEIT_FREEZING = 32;

const celsiusToFahrenheit = (temp, round) => {
  const f = temp * TEMP_CONVERSION_FACTOR + FAHRENHEIT_FREEZING;
  return round ? Math.round(f) : f;
};

const fahrenheitToCelcius = (temp, round) => {
  const c = (temp - FAHRENHEIT_FREEZING) * (1 / TEMP_CONVERSION_FACTOR);
  return round ? Math.round(c) : c;
};

export { celsiusToFahrenheit, fahrenheitToCelcius };
