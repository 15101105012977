/**
 * Returns a point object for the first point that matches the point where the source polyline was cropped at
 * returns null if no match is found
 * (start)-01-02-03-04-05-06-07-08-09-(Node 1)-01-02-03-04-05-
 * ( end )-19-18-17-16-15-14-13-12-11----------10-09-08-07-06-
 * In the above example, imagine the entire route overlaps (out and back). There are 2 polylines:
 * The first has 11 points, including (start) and (Node 1): polyline index 0
 * The second has 21 points, including (Node 1) and ( end ): polyline index 1
 * To check if (Node 1) is on an overlapping portion of the trail,
 * this method looks to find a match of point 09 (the closest crop idx used to split the polylines in 2 when (Node 1) was placed)
 * in this case, point 11 of polylines[1] is a match (same lnglat)
 *
 * @param {array} polylines - All the polylines that make up the current route. (trailPlanner.routePolylines)
 * @param {number} sourcePolylineIdx - The index for the polyline that the point falls on
 * @param {number} sourcePointIdx - index of the point within its polyline
 * @return {object|null} - Object containing the cropIdx and polylineIdx of matched point or null if no match is found
 */
const getFirstPointMatch = (polylines, sourcePolylineIdx, sourcePointIdx) => {
  let matchLocation = null;
  const lngLat = polylines[sourcePolylineIdx].points[sourcePointIdx];
  polylines.some((polyline, i) => {
    if (!Array.isArray(polyline.points)) {
      return false;
    }
    // replace search point with [0,0] to prevent matching itself, without offsetting point indexes
    const pointsToSearch = polyline.points.slice();
    if (i === sourcePolylineIdx) {
      pointsToSearch[sourcePointIdx] = [0,0];
    }

    const match = pointsToSearch?.findIndex(p => isSamePoint(p, lngLat))
    if (match && match > 0) {
      matchLocation = { cropIdx: match, polylineIdx: i }
      return true; // exit loop
    } else {
      return false;
    }
  })
  return matchLocation;
}

/**
 * Finds the position of the node that represents the beginning of the given polyline index.
 * Returns "out" or "back" for nodes that exist on overlapping segments of a route. Returns null for nodes that do not.
 *
 * @param {array} polylines - All the polylines that make up the current route. (trailPlanner.routePolylines)
 * @param {number} polylineIdx - the polylineindex related to the marker node
 * @return {string|null} - 'out' or 'back' or null
 */
const getNodePosition = (polylines, polylineIndex) => {
  // do not run for first polyline. the marker point associated with it is the trail start
  if (!polylineIndex) {
    return null
  }
  // derive the marker node lnglat from the second to last point in the polyline before the given polyline index
  // we don't use the last point because it is created when the anchor is dropped
  // and likely will not exist again on the route (see trail_planner class method: splitRouteAtNewNode)
  const beforePolylineIdx = polylineIndex - 1
  const beforePolyline = polylines[beforePolylineIdx]
  // if a polyline only has 2 points, it is a straight line between 2 marker nodes.
  // we dont want to assign a node position in this case because the second to last point of the 
  // previous polyline is also its first point (this is likely a nonexistant case anyway)
  // allowing this would result in position tooltips on straight line segments that jut out from the overlapping trail
  if (beforePolyline.points.length <= 2) {
    return null
  }
  const originalPointIdx = beforePolyline.points.length - 2;

  const originalLocation = { cropIdx: originalPointIdx, polylineIdx: beforePolylineIdx }
  const matchLocation = getFirstPointMatch(polylines, beforePolylineIdx, originalPointIdx)
  
  if (!matchLocation) {
    return null;
  }

  const matchOnBack = matchLocation && pointComesFirst(originalLocation, matchLocation)

  return matchOnBack ? 'out' : 'back';
}

/**
 * Compares 2 coordinates and returns true if they are the same, false if not
 *
 * @param {array} point1 - a coordinate array
 * @param {array} point2 - a coordinate array
 * @return {boolean} - true if the 2 coordinate arrays are the same, false if not.
 */
const isSamePoint = (point1, point2) => {
  return point1[0] === point2[0] && point1[1] === point2[1]
}

/**
 * Returns true if the first point comes before the second, false if not
 *
 * @param {object} point1 - a point object {cropIdx, polylineIdx}
 * @param {object} point2 - a point object {cropIdx, polylineIdx}
 * @return {boolean} - true if the first point comes before the second, false if not
 * 
 */
const pointComesFirst = (point1, point2) => {
  return (point2.polylineIdx > point1.polylineIdx) || (point2.polylineIdx === point1.polylineIdx && point2.cropIdx > point1.cropIdx)
}

export { getFirstPointMatch, getNodePosition, isSamePoint, pointComesFirst }
