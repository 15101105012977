import { useState, useCallback } from 'react';
import qs from 'query-string';
import { debounce } from 'debounce';

const setQueryStringWithoutPageReload = debounce(qsValue => {
  if (typeof window !== 'undefined') {
    const newurl = `${window.location.protocol}//${window.location.host}${window.location.pathname}${qsValue}`;

    window.history.pushState({ path: newurl }, '', newurl);
  }
}, 300);

const setQueryStringValue = (key, value, queryString = window.location.search) => {
  const values = qs.parse(queryString);
  const newQsValue = qs.stringify({ ...values, [key]: value });
  setQueryStringWithoutPageReload(`?${newQsValue}`);
};

export const getQueryStringValue = (key, queryString = window.location.search) => {
  const values = qs.parse(queryString);
  return values[key];
};

const useQueryString = (key, initialValue, isInt) => {
  const [value, setValue] = useState((isInt ? Number(getQueryStringValue(key)) : getQueryStringValue(key)) || initialValue);
  const onSetValue = useCallback(
    newValue => {
      setValue(isInt ? Number(newValue) : newValue);
      setQueryStringValue(key, newValue);
    },
    [key, isInt]
  );

  return [value, onSetValue];
};

export default useQueryString;
