import { useContext } from 'react';
import { FiltersContext } from 'components/explore/FiltersProvider';

export default function useFiltersContext() {
  const context = useContext(FiltersContext);

  if (!context) {
    throw new Error('filters context does not appear to be in the DOM hierarchy');
  }

  return context;
}
