// Calcs distance between two mapbox "point" objects (i.e. latLngs projected into pixels)
// Uses pythagorean distance formula
const pixelDistance = (p1, p2) => {
  return Math.abs(Math.sqrt((p2.x - p1.x) ** 2 + (p2.y - p1.y) ** 2));
};

const isCloseToEnds = (map, lngLat, lineLngLats, closePixelThresh) => {
  const pt = map.project(lngLat);
  const startPt = map.project(lineLngLats[0]);
  if (pixelDistance(pt, startPt) < closePixelThresh) {
    return true;
  }
  const endPt = map.project(lineLngLats[lineLngLats.length - 1]);
  return pixelDistance(pt, endPt) < closePixelThresh;
};

export { pixelDistance, isCloseToEnds };
