import { LngLatBounds } from 'mapbox-gl';
import { MapboxMapShim } from 'types/Map';

const fitMapToBounds = (
  map: MapboxMapShim,
  bounds: LngLatBounds,
  padding: number | { top: number; bottom: number; left: number; right: number } = 20,
  duration = 0,
  maintainZoomLevel = false,
  zoomOutByDefault = false
) => {
  const options = { duration, padding };
  // Fit map to bounds if bounds are valid
  if (!bounds.isEmpty()) {
    // use cameraForBounds to keep zoom level when adding waypoints
    if (maintainZoomLevel) {
      map.cameraForBounds(bounds, options);
    } else {
      map.fitBounds(bounds, options);
    }
  } else if (zoomOutByDefault) {
    map.setZoom(0);
  }
};

export { fitMapToBounds };
