import type GeoLocation from '@alltrails/shared/types/Location/GeoLocation';
import { IsochroneData } from 'types/Map';
import { InsidePolygon } from 'types/Search/Algolia';
import { isochroneDataToBoundingBox, isochroneDataToInsidePolygon } from 'utils/at_map_helpers';

type Action =
  | { type: 'DISTANCE_UPDATE'; meters: number }
  | { type: 'GEO_UPDATE'; geoLocation: GeoLocation }
  | { type: 'ISOCHRONE_PENDING' }
  | { type: 'ISOCHRONE_LOADED'; isochroneData: IsochroneData; lat: number; lng: number; meters: number }
  | { type: 'ISOCHRONE_REJECTED' };

type Cache = Record<
  string,
  {
    insidePolygon?: InsidePolygon;
    isochroneBoundingBox?: [number, number, number, number];
    isochroneData?: IsochroneData;
  }
>;

type State = {
  // Cache of requests. This may balloon out of size and need purging of old
  // requests at some point.
  cache?: Cache;

  // Geo search parameters.
  lat?: number;
  lng?: number;

  // Distance away search parameters.
  meters?: number;

  // Current search state parameters.
  hasValidDistanceParameters?: boolean;
  hasValidGeoParameters?: boolean;
  isLoadingIsochrone?: boolean;
  isLoadingGeoLocation?: boolean;
};

function searchKey(lat: number, lng: number, meters: number) {
  return `${lat}:${lng}:${meters}`;
}

export function getCachedData(lat: number, lng: number, meters: number, cache: Cache) {
  // Lat/lng don't matter in this scenario.
  if (meters === 0) {
    return {
      // Kind of a toss-up between setting an explicit "isZeroValue" and
      // passing that around everywhere vs using this implied zero value
      // and letting callers use it.
      insidePolygon: [[0, 0, 1, 1, 0, 1]]
    };
  }

  return cache[searchKey(lat, lng, meters)];
}

export default function reducer(state: State, action: Action) {
  switch (action.type) {
    case 'DISTANCE_UPDATE':
      if (action.meters === -1 || action.meters === undefined) {
        return { ...state, hasValidDistanceParameters: false, meters: undefined, isLoadingIsochrone: false };
      }

      // This is an odd case that we allow in filtering. A user could very well
      // choose "0" and purposefully over-filter, and we allow that.
      if (action.meters === 0) {
        return {
          ...state,
          meters: 0,
          hasValidDistanceParameters: false,
          isLoadingIsochrone: false
        };
      }

      // A distance update with the same distance we already have.  Do this
      // check to prevent render loops where we might generate new state
      // instances needlessly.
      if (action.meters === state.meters) {
        return state;
      }

      // Meters was changed and it has a meaningful value.
      return {
        ...state,
        meters: action.meters,
        hasValidDistanceParameters: true,
        // If we were already loading an isochrone, but the latest search state
        // is in the cache then disable the current loading state.
        isLoadingIsochrone: !!state.isLoadingIsochrone && !(searchKey(state.lat, state.lng, action.meters) in (state.cache || {}))
      };
    case 'GEO_UPDATE':
      if (action.geoLocation.isLocationBlocked) {
        return { ...state, hasValidGeoParameters: false, lat: undefined, lng: undefined, isLoadingGeoLocation: false };
      }

      if (action.geoLocation.pending) {
        return { ...state, hasValidGeoParameters: false, isLoadingGeoLocation: true };
      }

      if (!action.geoLocation?.lat || !action.geoLocation?.lng) {
        return { ...state, hasValidGeoParameters: false, lat: undefined, lng: undefined, isLoadingGeoLocation: false };
      }

      // A geo update where the lat/lng are identical to what we already have.
      // We may get spammed with new geoLocation objects by browsers. Do this
      // check to prevent render loops where we might generate new state
      // instances needlessly.
      if (action.geoLocation.lat === state.lat || action.geoLocation.lng === state.lng) {
        return state;
      }

      // geolocation was changed and it has a meaningful value.
      return {
        ...state,
        lat: action.geoLocation.lat,
        lng: action.geoLocation.lng,
        hasValidGeoParameters: true,
        isLoadingGeoLocation: false,
        // If we were already loading an isochrone, but the latest search state
        // is in the cache then disable the current loading state.
        isLoadingIsochrone:
          !!state.isLoadingIsochrone && !(searchKey(action.geoLocation.lat, action.geoLocation.lng, state.meters) in (state.cache || {}))
      };
    case 'ISOCHRONE_PENDING':
      return { ...state, isLoadingIsochrone: true };
    case 'ISOCHRONE_LOADED':
      return {
        ...state,
        isLoadingIsochrone:
          // The search key for the current state's value of lat, lng, meters is
          // not yet in the cache. Plus, this search that _just finished_
          // loading is not the current state's value of what we're searching.
          // So we assume that we must still be loading. Eventually this will be
          // true when another search, somewhere, as added the current state's
          // search to the cache.
          !(searchKey(state.lat, state.lng, state.meters) in (state.cache || {})) &&
          searchKey(action.lat, action.lng, action.meters) !== searchKey(state.lat, state.lng, state.meters),
        cache: {
          ...state.cache,
          [searchKey(action.lat, action.lng, action.meters)]: {
            isochroneData: action.isochroneData,
            isochroneBoundingBox: isochroneDataToBoundingBox(action.isochroneData),
            insidePolygon: isochroneDataToInsidePolygon(action.isochroneData)
          }
        }
      };
    case 'ISOCHRONE_REJECTED':
      // TODO Should we wipe the isochrone data from state, or hold on to it?
      return { ...state, isLoadingIsochrone: false };
    default:
      return state;
  }
}
