import { get } from '@alltrails/shared/api';
import type { TrailId } from '@alltrails/shared/types/trail';

export type TrailHistoryItem = {
  approvalInferred?: boolean;
  approvedDate?: string;
  approver?: unknown;
  date: string;
  id: number;
  name: string;
  user: string;
  userType: 'Admin' | 'User';
  value: unknown;
};

export default function getTrailHistory(trailId: TrailId) {
  return get<TrailHistoryItem[]>(`/api/alltrails/admin/trails/${trailId}/history`);
}
