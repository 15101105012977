import { FormattedMessage, useIntl } from '@alltrails/shared/react-intl';
import getHelpCenterUrl from '@alltrails/shared/utils/constants/helpCenterUrl';
import CustomProvider from 'components/CustomProvider';
import Flower from '@alltrails/shared/illustrations/Flower';
import Button from '@alltrails/shared/denali/components/Button';
import Link from '@alltrails/shared/denali/components/Link';
import NullState from '@alltrails/shared/denali/components/NullState';
import { getFormattedTrailUrls } from '../../../../utils/trail_helpers';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const createReactClass = require('create-react-class');

const BaseEditTrailEndBox = createReactClass({
  render() {
    const { trail, context } = this.props;
    let actionButton = null;
    if (trail.slug) {
      const { localizedUrl } = getFormattedTrailUrls(trail, context.languageRegionCode);
      actionButton = (
        <Button
          text={this.props.intl.formatMessage({ defaultMessage: 'Return to trail' })}
          linkInfo={{ href: localizedUrl }}
          testId="return-to-trail-btn"
          type="button"
          variant="primary"
          size="sm"
        />
      );
    }
    return (
      <div id="contribute-start" className="xlate-none">
        <div id="contribute-welcome">
          <div id="end-state">
            <NullState
              illustration={{ Component: Flower }}
              title={this.props.intl.formatMessage({ defaultMessage: 'Thanks for contributing!' })}
              description={this.props.intl.formatMessage({
                defaultMessage: 'Your changes will be reviewed and the trail updated as soon as they’re approved.'
              })}
            />
          </div>
        </div>
        <div id="contribute-action-bar">
          <Link size="sm" href={getHelpCenterUrl(context.languageRegionCode, 360018930672)} target="_blank" variant="primary">
            <FormattedMessage defaultMessage="Contribute help" />
          </Link>
          <span className="buttons">{actionButton}</span>
        </div>
      </div>
    );
  }
});

const EditTrailEndBox = props => {
  const intl = useIntl();
  return (
    <CustomProvider>
      <BaseEditTrailEndBox {...props} intl={intl} />
    </CustomProvider>
  );
};

export default EditTrailEndBox;
