import { serverAjaxPromise } from './request_helpers';
import { getPrimaryIndex } from 'utils/search';
import { SearchFiltersUtil } from 'utils/search_filters_util';

const loadVerifiedRoute = trailId => {
  return serverAjaxPromise({
    resReducer: data => data.trails[0].defaultMap,
    url: `/api/alltrails/trails/${trailId}?detail=offline&include_pending=true`
  });
};

const loadAtMap = (mapId, detail) => {
  return serverAjaxPromise({
    resReducer: data => data.maps[0],
    url: `/api/alltrails/maps/${mapId}?detail=${detail}`
  });
};

const loadAtMapForObj = object => {
  const { ID, type } = object;
  return type === 'trail' ? loadVerifiedRoute(ID) : loadAtMap(ID, 'deep');
};

const saveAtMap = (atMap, opts = {}) => {
  return serverAjaxPromise({
    data: {
      apply_map_rules: opts.apply_map_rules || 'true',
      combine_segments: opts.combine_segments || 'false',
      detail: opts.detail || 'deep',
      map: atMap,
      system_owned: opts.system_owned
    },
    resReducer: data => data.maps[0],
    type: 'POST',
    url: `/api/alltrails/maps`
  });
};

const updateAtMap = (atMap, opts = {}) => {
  return serverAjaxPromise({
    data: {
      apply_map_rules: opts.apply_map_rules || 'true',
      combine_segments: opts.combine_segments || 'false',
      detail: opts.detail || 'deep',
      map: atMap
    },
    resReducer: data => data.maps[0],
    type: 'PUT',
    url: `/api/alltrails/maps/${atMap.id}`
  });
};

const updateAtMapMetadata = (atMapId, updates) => {
  return serverAjaxPromise({
    data: updates,
    resReducer: data => data.maps[0].map,
    type: 'POST',
    url: `/api/alltrails/maps/${atMapId}/metadata`
  });
};

const removeAtMapFromTrail = (atMapid, trailId) => {
  return serverAjaxPromise({
    data: {
      from_trail_id: trailId
    },
    type: 'POST',
    url: `/api/alltrails/maps/${atMapid}/remove`
  });
};

const copyVerifiedRoute = (trailId, name, detail = 'deep') => {
  return serverAjaxPromise({
    data: {
      detail,
      name
    },
    resReducer: data => data.maps[0],
    type: 'POST',
    url: `/api/alltrails/trails/${trailId}/verified/copy`
  });
};

const copyRoute = (atMapId, name, detail = 'deep') => {
  return serverAjaxPromise({
    data: {
      detail,
      name
    },
    resReducer: data => data.maps[0],
    type: 'POST',
    url: `/api/alltrails/maps/${atMapId}/copy`
  });
};

const saveNewEndpoint = (atMapId, segmentId, cropIndex, newEndPoint) => {
  return serverAjaxPromise({
    data: {
      at_line_segment_id: segmentId,
      crop_idx: cropIndex,
      new_end_pt: newEndPoint
    },
    resReducer: data => data.maps[0],
    type: 'PUT',
    url: `/api/alltrails/maps/${atMapId}/endpoint/edit`
  });
};

const saveWaypointChanges = (atMapId, waypointId, waypointChanges) => {
  return serverAjaxPromise({
    data: waypointChanges,
    resReducer: data => data.waypoints[0],
    type: 'PUT',
    url: `/api/alltrails/maps/${atMapId}/waypoints/${waypointId}`
  });
};

const updateAllWaypoints = (atMapId, toUpdate) => {
  return serverAjaxPromise({
    data: toUpdate,
    type: 'PUT',
    url: `/api/alltrails/maps/${atMapId}/waypoints/all`
  });
};

const deleteWaypoint = (atMapId, waypointId) => {
  return serverAjaxPromise({
    type: 'DELETE',
    url: `/api/alltrails/maps/${atMapId}/waypoints/${waypointId}`
  });
};

const deleteAllWaypoints = atMapId => {
  return serverAjaxPromise({
    type: 'DELETE',
    url: `/api/alltrails/maps/${atMapId}/waypoints/all`
  });
};

const reorderWaypoints = (atMapId, orderedWaypointIds) => {
  return serverAjaxPromise({
    data: {
      ordered_waypoint_ids: orderedWaypointIds
    },
    resReducer: data => data.waypoints,
    type: 'POST',
    url: `/api/alltrails/maps/${atMapId}/waypoints/reorder`
  });
};

const addWaypointToTrail = (trailId, waypoint) => {
  return serverAjaxPromise({
    data: {
      description: waypoint.description_source ? waypoint.description_source : waypoint.description,
      latitude: waypoint.location.latitude,
      longitude: waypoint.location.longitude,
      name: waypoint.name_source ? waypoint.name_source : waypoint.name
    },
    resReducer: data => data.waypoints[0],
    type: 'POST',
    url: `/api/alltrails/trails/${trailId}/waypoints`
  });
};

const createRoute = (mapId, route) => {
  return serverAjaxPromise({
    data: {
      route: {
        lineDisplayProperty: route.lineDisplayProperty,
        lineSegments: route.lineSegments
      }
    },
    resReducer: data => data.maps[0],
    type: 'POST',
    url: `/api/alltrails/maps/${mapId}/routes`
  });
};

const updateRoute = (mapId, route) => {
  return serverAjaxPromise({
    data: {
      route: {
        lineDisplayProperty: route.lineDisplayProperty,
        lineSegments: route.lineSegments
      }
    },
    resReducer: data => data.maps[0],
    type: 'PUT',
    url: `/api/alltrails/maps/${mapId}/routes/${route.id}`
  });
};

const createWaypoint = (mapId, waypoint) => {
  return serverAjaxPromise({
    data: {
      description: waypoint.description,
      latitude: waypoint.location.latitude,
      longitude: waypoint.location.longitude,
      name: waypoint.name,
      showTitle: waypoint.showTitle
    },
    resReducer: data => data.waypoints[0],
    type: 'POST',
    url: `/api/alltrails/maps/${mapId}/waypoints`
  });
};

const updateWaypoint = (mapId, waypoint) => {
  return serverAjaxPromise({
    data: {
      description: waypoint.description,
      latitude: waypoint.location.latitude,
      longitude: waypoint.location.longitude,
      name: waypoint.name,
      showTitle: waypoint.showTitle
    },
    resReducer: data => data.waypoints[0],
    type: 'PUT',
    url: `/api/alltrails/maps/${mapId}/waypoints/${waypoint.id}`
  });
};

const deleteMap = mapId => {
  return serverAjaxPromise({
    resReducer: resp => resp,
    type: 'DELETE',
    url: `/api/alltrails/maps/${mapId}`
  });
};

const nearbyTrailsRequest = (map) => {
  return getPrimaryIndex()
    .search('', SearchFiltersUtil.createAlgoliaTrailsObject(map))
    .then(content => {
      return content.hits;
    });
};

export {
  addWaypointToTrail,
  copyRoute,
  copyVerifiedRoute,
  createRoute,
  createWaypoint,
  deleteAllWaypoints,
  deleteMap,
  deleteWaypoint,
  loadAtMap,
  loadAtMapForObj,
  loadVerifiedRoute,
  removeAtMapFromTrail,
  reorderWaypoints,
  saveAtMap,
  saveNewEndpoint,
  saveWaypointChanges,
  updateAllWaypoints,
  updateAtMap,
  updateAtMapMetadata,
  updateRoute,
  updateWaypoint,
  nearbyTrailsRequest
};
