import turfDistance from '@turf/distance';

// A polyline {points}
// Points are in lngLatLike format
class RoutePolyline {
  constructor(points) {
    this.points = points;
  }

  getPts() {
    return this.points;
  }

  getEndPts() {
    const points = this.getPts();
    return [points[0], points[points.length - 1]];
  }

  // Get the start (0) or finish (1) pt of the line in the direction of travel
  getEndPt(end) {
    const ends = this.getEndPts();
    return ends[end === 0 ? 0 : 1];
  }

  getLength() {
    // Find the distance between each pt and sum them
    return this.points.reduce((totalDist, currPt, i, pts) => {
      return i === 0 ? totalDist : totalDist + turfDistance(currPt, pts[i - 1]);
    }, 0);
  }

  getCopy(reverseDirection) {
    return new RoutePolyline(reverseDirection ? this.points.slice().reverse() : this.points);
  }
}

export { RoutePolyline };
