import { featureCollection } from '@turf/helpers';
import { atMapsToGeojson, pointItemsToGeojson } from '@alltrails/maps/utils/legacyGeoJSONConversions';
import { colorWithOpacityExpression } from '@alltrails/maps/utils/colors';
import { COLOR_BLUE_700 } from '@alltrails/shared/denali/tokens';
import { serverAjaxPromise } from 'utils/requests/request_helpers';
import hasPermission from 'utils/hasPermission';
import { addOrUpdateGeojsonSource } from '../sources/geojson_helpers';
import { addLayer, removeLayer, updateLayerProperty, addLayerEvent } from '../layers/layer_helpers';
import { removeSource } from '../sources/source_helpers';
import { addPreviewLines, updatePreviewLines, resetPreviewLines } from './preview_lines';
import { addHoverPopupToMap } from '../event_handling/popup_helpers';
import { addRemotePinMarker } from './remote_pin_markers';

const resultsDict = {};
const heatmapPromises = {};

const getPolylinesId = id => `${id}-heatmap-polylines`;

const getPointsId = id => `${id}-heatmap-points`;

const defaultLineColorExpression = () => ['coalesce', ['get', 'color'], 'red'];

const getTrailsRecordings = (trailId, opts) =>
  serverAjaxPromise({
    url: `/api/alltrails/trails/${trailId}/public_tracks`,
    data: opts,
    resReducer: data =>
      data.maps.map(map => {
        // ensure trailId is on each recording, as trailId isn't returned by "public_tracks" request by default
        map.trailId = trailId;
        return map;
      })
  });

const getLineOpacity = (resultsLength, isNewPolylineColor = false) => {
  if (isNewPolylineColor) {
    return 0.1;
  }

  // Lower opacity when more results are present
  return 0.5 / (1.0 + 0.5 * Math.log10(resultsLength || 0));
};

const resetHeatmapPromises = id => {
  const promises = heatmapPromises[id];
  if (promises.length > 0) {
    promises.forEach(promise => promise.abort());
    promises.length = 0;
  }
};

const setHeatmapPromises = (id, promises) => {
  heatmapPromises[id] = promises;
  Promise.allSettled(promises).finally(() => {
    promises.length = 0;
  });
};

const initHeatmapOverlay = (map, id) => {
  const heatmapOpacity = !hasPermission({ permission: 'trails:manage' }) ? 0.1 : 0.3;

  heatmapPromises[id] = {};
  const polylinesId = getPolylinesId(id);
  const pointsId = getPointsId(id);
  if (map.getLayer(polylinesId)) {
    return false;
  }

  addOrUpdateGeojsonSource(map, polylinesId, featureCollection([]));
  addOrUpdateGeojsonSource(map, pointsId, featureCollection([]));

  addLayer(map, {
    id: polylinesId,
    type: 'line',
    source: polylinesId,
    paint: {
      'line-color': colorWithOpacityExpression(defaultLineColorExpression(), 0.5),
      'line-width': 12
    }
  });
  addLayer(map, {
    id: pointsId,
    type: 'heatmap',
    source: pointsId,
    paint: {
      'heatmap-intensity': 0.8,
      'heatmap-opacity': heatmapOpacity
    }
  });
  addPreviewLines(map);

  return true;
};

const filterAtMapResults = (results, enabledOverlays) => {
  let modResults = results.map(result => {
    let dateField = result.created_at;
    if (!dateField && result.metadata) {
      dateField = result.metadata.created;
    }
    return {
      ...result,
      date: new Date(dateField)
    };
  });
  if (enabledOverlays.includes('after')) {
    const daysAgo = 60;
    const nowInMs = new Date().getTime();
    const daysAgoInMs = daysAgo * (24 * 60 * 60 * 1000);
    const afterDate = new Date(nowInMs - daysAgoInMs);
    modResults = modResults.filter(r => r.date > afterDate);
  }
  if (enabledOverlays.includes('limit')) {
    const limit = 50; // num results
    modResults = modResults.sort((a, b) => b.date - a.date).slice(0, limit);
  }
  return modResults;
};

const addTrailHeatmapOverlay = (map, id, trailId, enabledOverlays, renderHoverCard) => {
  const isNewPolylineColor = !hasPermission({ permission: 'trails:manage' });

  const init = initHeatmapOverlay(map, id);
  const polylinesId = getPolylinesId(id);
  if (init) {
    const handleClickOn = (e, object) => {
      updatePreviewLines(map, object);
    };

    const handleClickOff = () => {
      resetPreviewLines(map);
    };

    addHoverPopupToMap(map, polylinesId, renderHoverCard, handleClickOn, handleClickOff, {}, true);
    addLayerEvent(map, polylinesId, true, 'mouseenter', () => {
      map.getCanvas().style.cursor = 'pointer';
    });
    addLayerEvent(map, polylinesId, true, 'mouseleave', () => {
      map.getCanvas().style.cursor = '';
    });
  }
  resetHeatmapPromises(id);
  const recordingsPromise = getTrailsRecordings(trailId);
  setHeatmapPromises(id, [recordingsPromise]);
  recordingsPromise.then(recordings => {
    recordings = filterAtMapResults(recordings, enabledOverlays);

    // Adds necessary properties for activity hover card to function
    const geojson = atMapsToGeojson(recordings, true);
    const adjustedGeojson = {
      ...geojson,
      features: geojson.features.map(feature => {
        const recording = recordings.find(r => r.id === feature.properties.ID);
        return {
          ...feature,
          properties: {
            ...feature.properties,
            // the following properties are required for the activity hover card
            elevation_gain: recording?.elevation_gain || 0,
            length: recording?.length || 0
          }
        };
      })
    };

    addOrUpdateGeojsonSource(map, polylinesId, adjustedGeojson);
    const opacity = getLineOpacity(recordings.length, isNewPolylineColor);
    updateLayerProperty(map, polylinesId, 'paint', 'line-color', colorWithOpacityExpression(defaultLineColorExpression(), opacity));
    if (isNewPolylineColor) {
      updateLayerProperty(map, polylinesId, 'paint', 'line-color', colorWithOpacityExpression(COLOR_BLUE_700, opacity));
    }
  });
};

const addResultsHeatmapOverlay = ({ map, id, heatMapResults, renderHoverCard, lineOpts = {} }) => {
  const results = heatMapResults || []; // heatMapResults will sometimes come in as null
  resultsDict[id] = results;
  const init = initHeatmapOverlay(map, id);
  const polylinesId = getPolylinesId(id);
  if (init) {
    addLayerEvent(map, polylinesId, true, 'click', e => {
      const atMapProps = e.features[0].properties;
      const ID = atMapProps.isVerifiedMap ? atMapProps.trailId : atMapProps.ID;
      const object = resultsDict[id].find(result => result.ID === ID);
      if (object) {
        addRemotePinMarker(map, object, renderHoverCard);
      }
    });
    addLayerEvent(map, polylinesId, true, 'mouseenter', () => {
      map.getCanvas().style.cursor = 'pointer';
    });
    addLayerEvent(map, polylinesId, true, 'mouseleave', () => {
      map.getCanvas().style.cursor = '';
    });
  }
  resetHeatmapPromises(id);
  const isNewPolylineColor = !hasPermission({ permission: 'trails:manage' });

  const pointsId = getPointsId(id);
  // Accumulate ids
  const trailIds = [];
  const mapIds = [];
  results.forEach(result => {
    if (result.type === 'trail') {
      trailIds.push(result.ID);
    } else {
      mapIds.push(result.ID);
    }
  });
  // Get heatmaps
  const promises = [];
  if (trailIds.length > 0 || mapIds.length > 0) {
    const mapsPromise = serverAjaxPromise({
      type: 'POST',
      url: '/api/alltrails/maps/heatmap',
      data: {
        trail_ids: trailIds,
        map_ids: mapIds
      },
      resReducer: data => data.maps
    });
    promises.push(mapsPromise);
    mapsPromise.then(maps => {
      // Update heatmap
      addOrUpdateGeojsonSource(map, polylinesId, atMapsToGeojson(maps, true));
      const opacity = getLineOpacity(maps.length, isNewPolylineColor);
      // Parentheses here are crucial. Use the lineOpts color or else use the ternary expression.
      const color = lineOpts['line-color'] || (isNewPolylineColor ? COLOR_BLUE_700 : defaultLineColorExpression());
      updateLayerProperty(map, polylinesId, 'paint', 'line-color', colorWithOpacityExpression(color, opacity));
      if (isNewPolylineColor) {
        updateLayerProperty(map, polylinesId, 'paint', 'line-width', 5);
      }
    });
  }
  setHeatmapPromises(id, promises);

  addOrUpdateGeojsonSource(map, pointsId, pointItemsToGeojson(results));
};

const addHeatmapOverlay = (map, { trailId, results, enabledOverlays, renderHoverCard }) => {
  if (trailId) {
    addTrailHeatmapOverlay(map, 'default', trailId, enabledOverlays, renderHoverCard);
  } else {
    addResultsHeatmapOverlay({ map, id: 'default', heatMapResults: results, renderHoverCard });
  }
};

const removeHeatmapOverlay = (map, id) => {
  delete resultsDict[id];
  delete heatmapPromises[id];
  const polylinesId = getPolylinesId(id);
  const pointsId = getPointsId(id);
  removeLayer(map, polylinesId);
  removeLayer(map, pointsId);
  removeSource(map, polylinesId);
  removeSource(map, pointsId);
};

const heatmapOverlayId = layerId => `${layerId}Heatmap`; // this format should match format in overlay_configs
export { addHeatmapOverlay, addResultsHeatmapOverlay, removeHeatmapOverlay, heatmapOverlayId, filterAtMapResults };
