import { get, post } from '@alltrails/shared/api';
import { Trail } from '@alltrails/shared/types/trail';
import type { UserId } from 'types/User';

// copied from './schema', but not available in mugen when we migrate
// type GroupEditResponse = components['schemas']['CreateEditGroup'];
// type EditGroup = components['schemas']['CreateEditGroupItem'];

export type EditGroupId = number;
export type EditGroupItemId = number;

export type EditGroup = {
  id?: EditGroupId;
  /**
   * @description Type of object, e.g. trail or area
   */
  type?: string;
  user?: Record<string, never>;
  system_generated?: boolean;
  /**
   * @description ID of type object, e.g. trail ID or area ID
   */
  targetObjectID?: number;
  editItems?: Record<string, never>[];
  metadata?: {
    status?: string;
    /**
     * @description iso8601 formatted date
     */
    created?: string;
    /**
     * @description iso8601 formatted date
     */
    updated?: string;
  };
};

export type CreateEditGroupRequestPayload = {
  type: 'trail' | 'area';
  /**
   * @description ID of the type (e.g. trail id or area id)
   */
  target_object_id?: number;
  system_generated?: boolean;
  /**
   * @description AtMap ID
   */
  origin_object_id?: number;
};

export type CreateEditGroupResponse = {
  editGroups?: EditGroup[];
  meta?: {
    status?: string;
    items?: number;
    /**
     * @description iso8601 formatted date
     */
    timestamp?: string;
  };
};

export async function createEditGroup(payload: CreateEditGroupRequestPayload) {
  return post<CreateEditGroupResponse>('/api/alltrails/edits/groups', payload);
}

export type CreateEditGroupItemRequestPayload = {
  type: 'report' | 'trail_detail';
  data?: {
    name?: string;
    value?: string;
  };
  /**
   * @description Defaults to trail
   * @enum {string}
   */
  group_type?: 'trail' | 'area';
  /**
   * @description ID of group type object (e.g. trail ID or area ID)
   */
  relation_id?: number;
};

export type CreateEditGroupItemResponse = {
  editItems?: {
    id?: EditGroupItemId;
    editGroupId?: EditGroupId;
    /** @enum {string} */
    type?: 'report';
    data?: {
      comment?: string;
    };
    /**
     * @description ID of item the comment is about, e.g. trail ID or area ID
     */
    relationId?: number;
    targetObjectType?: string;
    targetObjectId?: number;
    userId?: UserId;
    voteCounts?: {
      yes?: number;
      no?: number;
    };
    relationObject?: Record<string, never>;
    metadata?: {
      status?: string;
      statusReason?: string;
      created?: string;
      updated?: string;
    };
  }[];
  meta?: {
    status?: string;
    items?: number;
    timestamp?: string;
  };
};

export async function createEditGroupItem(editGroupId: EditGroupId, payload: CreateEditGroupItemRequestPayload) {
  return post<CreateEditGroupItemResponse>(`/api/alltrails/edits/groups/${editGroupId}/items`, payload);
}

export type ApplyEditsRequestParams = {
  type: 'trail';
  target_object_id: number;
};

export type ApplyEditsResponse = Trail & { location: Trail['location'] & { zoom: number } };

export async function applyEdits(params: ApplyEditsRequestParams) {
  return get<ApplyEditsResponse>('/api/alltrails/edits/apply', { params });
}
