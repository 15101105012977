import { pointItemToLngLat } from '@alltrails/maps/utils/legacyGeoJSONConversions';

const extendBoundsByPointItem = (bounds, item) => {
  const lngLat = pointItemToLngLat(item);
  if (lngLat) {
    bounds.extend(lngLat);
  }
};

export { extendBoundsByPointItem };
