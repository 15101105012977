import useUser from 'hooks/useUser';
import useListItems from 'hooks/useListItems';
import CustomProvider from 'components/CustomProvider';
import SortableTrailsList from 'components/SortableTrailsList';

const SidebarTrailResultListWrapper = props => {
  const user = useUser();
  const listId = props.list_id;
  const belongsToCurrentUser = props.userInfo && user && user.id == props.userInfo.id;
  const { listMethods, renderModal, renderCollabListPrivacyModal, userListItems } = useListItems(
    {
      lists: props.initialMyLists,
      listItems: props.initialMyListItems,
      userListItems: props.initialUserListItems,
      belongsToCurrentUser: belongsToCurrentUser && listId // this decides whether or not to edit an individual users list
    },
    props.page
  );

  return (
    <>
      {renderModal()}
      {renderCollabListPrivacyModal()}
      <SortableTrailsList listId={listId} listMethods={listMethods} userListItems={userListItems} {...props} />
    </>
  );
};

function Wrapper(props) {
  return (
    <CustomProvider>
      <SidebarTrailResultListWrapper {...props} />
    </CustomProvider>
  );
}

export default Wrapper;
