import { atMapsToGeojson } from '@alltrails/maps/utils/legacyGeoJSONConversions';
import { getTrailContribVRs } from '../../requests/trail_requests';
import { addPolylines, removePolylines } from './polylines';

const contribsId = 'contribs';

const addContribs = (map, trailId) => {
  getTrailContribVRs(trailId).then(maps => {
    addPolylines(map, contribsId, atMapsToGeojson(maps), {} /* sourceOpts */, { 'line-color': 'rgba(0, 187, 0, 0.3)', 'line-width': 12 }, [
      'circle',
      'symbol',
      'line'
    ]);
  });
};

const removeContribs = map => {
  removePolylines(map, contribsId);
};

export { addContribs, removeContribs };
