import { useEffect } from 'react';
import { useIntl, FormattedMessage, defineMessages } from '@alltrails/shared/react-intl';
import * as clipboardPolyfill from 'clipboard-polyfill/dist/clipboard-polyfill.promise';
import logShareDisplayed from '@alltrails/analytics/events/logShareDisplayed';
import ShareModalDrawerTextMessage from 'components/ShareModalDrawerTextMessage';
import { VISIBILITY_PRIVATE } from 'utils/privacy_policy_helpers';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import useLanguageCode from '@alltrails/shared/hooks/useLanguageCode';
import LegacyModal from '../../../../components/LegacyModal';
import ShareModalDrawer from '../../../../components/shared/ShareModalDrawer';
import { ShareModalDrawerEmbed } from './share_modal_drawer_embed';
import { ShareModalDrawerQRCode } from './share_modal_drawer_qrcode';
import { wrapUrlSafe } from '@alltrails/shared/utils/languageSupport';

const createReactClass = require('create-react-class');

const embedMessages = defineMessages({
  TRAIL: { defaultMessage: 'Embed this trail' },
  MAP: { defaultMessage: 'Embed this map' },
  TRACK: { defaultMessage: 'Embed this activity' },
  LIST: { defaultMessage: 'Embed this list' },
  AREA: { defaultMessage: 'Embed this park' },
  STATE: { defaultMessage: 'Embed this state' },
  COUNTRY: { defaultMessage: 'Embed this country' },
  CITY: { defaultMessage: 'Embed this city' },
  POINT_OF_INTEREST: { defaultMessage: 'Embed this point of interest' }
});

const textMessages = defineMessages({
  TRAIL: { defaultMessage: 'Text this trail' },
  MAP: { defaultMessage: 'Text this map' },
  TRACK: { defaultMessage: 'Text this activity' },
  LIST: { defaultMessage: 'Text this list' },
  AREA: { defaultMessage: 'Text this park' },
  STATE: { defaultMessage: 'Text this state' },
  COUNTRY: { defaultMessage: 'Text this country' },
  CITY: { defaultMessage: 'Text this city' },
  POINT_OF_INTEREST: { defaultMessage: 'Text this point of interest' }
});

const BaseShareModal = createReactClass({
  getInitialState() {
    return {
      checkmarks: {},
      openDrawer: null
    };
  },
  componentDidMount() {
    this.timers = {};
  },
  openDrawer(drawer) {
    this.setState({ openDrawer: drawer });
  },
  closeDrawer() {
    this.setState({ openDrawer: null });
  },
  handleClose() {
    if (this.state.openDrawer) {
      this.closeDrawer();
    } else {
      this.props.handleClose();
    }
  },
  setCheckmark(item) {
    if (this.timers[item]) {
      clearTimeout(this.timers[item]);
    }
    const { checkmarks } = this.state;
    checkmarks[item] = true;
    this.setState({ checkmarks });
    this.timers[item] = setTimeout(() => {
      checkmarks[item] = false;
      this.setState({ checkmarks });
    }, 4000);
  },
  formatViewPath() {
    let pathStart;
    switch (this.props.shareObject.type) {
      case 'map':
        pathStart = 'explore/map/';
        break;
      case 'track':
        pathStart = 'explore/recording/';
        break;
      case 'list':
        pathStart = 'lists/';
        break;
      default:
        pathStart = '';
    }
    return `${pathStart}${this.props.shareObject.slug}`;
  },
  formatViewParams() {
    if (!this.props.currentUser) {
      return '';
    }

    const params = new URLSearchParams();
    params.set('u', this.props.currentUser.metric ? 'm' : 'i');
    params.set('sh', this.props.currentUser.referralCode);
    return `?${params.toString()}`;
  },
  formatViewUrl() {
    const baseUrl = `${location.protocol}//${location.host}`;
    const localizedPath = wrapUrlSafe(`/${this.formatViewPath()}${this.formatViewParams()}`, this.props.languageRegionCode);
    return `${baseUrl}${localizedPath}`;
  },
  handleCopyClick() {
    if (!this.props.clipboard) {
      alert(this.props.intl.formatMessage({ defaultMessage: 'Copy link is not available in your web browser' }));
      return;
    }
    this.props.clipboard.writeText(this.formatViewUrl()).then(
      () => {
        this.setCheckmark('copy');
      },
      err => {
        console.error('Could not copy link to clipboard: ', err);
      }
    );
  },
  isPrivateContent() {
    const shareObject = this.props.shareObject;
    const currentUser = this.props.currentUser;
    const isOnlyMeOption = shareObject?.contentPrivacy === VISIBILITY_PRIVATE;
    return ['map', 'track', 'list'].indexOf(shareObject.type) > -1 && (isOnlyMeOption || (currentUser && currentUser.private));
  },
  offerEmbed() {
    return !this.isPrivateContent();
  },
  handleEmailClick() {
    const subject = this.props.intl.formatMessage({ defaultMessage: 'Check this out on AllTrails' });
    const body = `${subject}:

${this.formatViewUrl()}

${this.props.intl.formatMessage({ defaultMessage: 'Download the free AllTrails App.' })}
https://alltrails.io/BrtlItRPbo`;

    window.open(`mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`);
  },
  handleTextClick() {
    this.openDrawer('sms');
  },
  handleEmbedClick() {
    this.openDrawer('embed');
  },
  handleQRCodeClick() {
    this.openDrawer('qrcode');
  },
  formatFooter() {
    if (!this.state.openDrawer && this.isPrivateContent()) {
      return (
        <p className="share-footer xlate-none">
          <FormattedMessage defaultMessage="This content is private and only you will be able to view it." />
        </p>
      );
    }
    return null;
  },
  render() {
    let title;
    let content;
    let containerClass = '';

    switch (this.state.openDrawer) {
      case 'embed':
        title = this.props.intl.formatMessage(embedMessages[this.props.shareObject.type.toUpperCase()]);
        content = (
          <ShareModalDrawerEmbed
            clipboard={this.props.clipboard}
            currentUser={this.props.currentUser}
            shareObject={this.props.shareObject}
            locale={this.props.locale}
            languageRegionCode={this.props.languageRegionCode}
            intl={this.props.intl}
          />
        );
        break;
      case 'qrcode':
        title = this.props.intl.formatMessage({ defaultMessage: 'Download QR code' });
        content = (
          <ShareModalDrawerQRCode
            shareObject={this.props.shareObject}
            formatViewPath={this.formatViewPath}
            referralCode={this.props.currentUser?.referralCode}
            intl={this.props.intl}
          />
        );
        break;
      case 'sms':
        title = this.props.intl.formatMessage(textMessages[this.props.shareObject.type.toUpperCase()]);
        content = (
          <ShareModalDrawerTextMessage
            onSuccess={() => {
              this.setCheckmark('sms');
              this.closeDrawer();
            }}
            shareObject={this.props.shareObject}
          />
        );
        break;
      default:
        title =
          this.props.shareObject.type === 'map'
            ? this.props.intl.formatMessage({ defaultMessage: 'Share map' })
            : this.props.intl.formatMessage({ defaultMessage: 'Share' });
        const copyLinkTitle = this.state.checkmarks.copy
          ? this.props.intl.formatMessage({ defaultMessage: 'Link copied' })
          : this.props.intl.formatMessage({ defaultMessage: 'Copy link' });
        const smsTitle = this.state.checkmarks.sms
          ? this.props.intl.formatMessage({ defaultMessage: 'Text sent' })
          : this.props.intl.formatMessage({ defaultMessage: 'Text' });
        let embedDiv = null;
        if (this.offerEmbed()) {
          embedDiv = (
            <div>
              <hr />
              <ShareModalDrawer
                title={this.props.intl.formatMessage({ defaultMessage: 'Embed' })}
                imgLink="https://cdn-assets.alltrails.com/assets/icons/share/embed@2x.png"
                onClick={this.handleEmbedClick}
              />
            </div>
          );
          containerClass = '5-row';
        }
        content = (
          <div>
            <ShareModalDrawer
              title={copyLinkTitle}
              imgLink="https://cdn-assets.alltrails.com/assets/icons/share/link@2x.png"
              onClick={this.handleCopyClick}
              showSuccessIcon={this.state.checkmarks.copy}
            />
            <hr />
            <ShareModalDrawer
              title={smsTitle}
              imgLink="https://cdn-assets.alltrails.com/assets/icons/share/text@2x.png"
              onClick={this.handleTextClick}
              showSuccessIcon={this.state.checkmarks.sms}
            />
            <hr />
            <ShareModalDrawer
              title={this.props.intl.formatMessage({ defaultMessage: 'Email' })}
              imgLink="https://cdn-assets.alltrails.com/assets/icons/share/email@2x.png"
              onClick={this.handleEmailClick}
            />
            {embedDiv}
            <hr />
            <ShareModalDrawer
              title={this.props.intl.formatMessage({ defaultMessage: 'QR code' })}
              imgLink="https://cdn-assets.alltrails.com/assets/icons/share/qr-code@2x.png"
              onClick={this.handleQRCodeClick}
            />
          </div>
        );
    }

    return (
      <LegacyModal handleBackgroundClick={this.handleClose}>
        <div
          id="share-modal"
          className={`share-container no-left-padding no-right-padding col-lg-4 col-md-4 col-sm-4 col-xs-4 ${containerClass} ${this.props.className} ${this.state.openDrawer}`}
          data-testid="share_modal"
        >
          <div className="exit-button-container xlate-none">
            <button
              className="close"
              onClick={this.handleClose}
              type="button"
              label={this.props.intl.formatMessage({ defaultMessage: 'Close' })}
              data-testid="share_modal_close"
            />
          </div>
          <div className="content-container" data-testid="share_modal_content_container">
            <div className="share-title xlate-none">
              <h4>{title}</h4>
            </div>
            <div className="share-body">
              {content}
              {this.formatFooter()}
            </div>
          </div>
        </div>
      </LegacyModal>
    );
  }
});

const ShareModal = ({ handleClose, shareObject, currentUser }) => {
  const intl = useIntl();
  const languageRegionCode = useLanguageRegionCode();
  const languageCode = useLanguageCode();

  useEffect(() => {
    logShareDisplayed({ id: shareObject.id, source: shareObject.source });
  }, []);

  return (
    <BaseShareModal
      intl={intl}
      clipboard={clipboardPolyfill}
      currentUser={currentUser}
      languageRegionCode={languageRegionCode}
      locale={languageCode}
      handleClose={handleClose}
      shareObject={shareObject}
    />
  );
};

export default ShareModal;
