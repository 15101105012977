import { useState } from 'react';
import { defineMessages } from '@alltrails/shared/react-intl';
import { X_AT_KEY } from '@alltrails/shared/api';
import ModalInterior from '@alltrails/shared/components/modals/ModalInterior';
import ModalBackground from '@alltrails/shared/components/modals/ModalBackground';
import CustomProvider from 'components/CustomProvider';
import useFormatMessage from '@alltrails/shared/hooks/useFormatMessage';
import Select from '@alltrails/shared/denali/components/Select';
import { execute } from '../../utils/recaptcha_helpers';
import downloadOptions from './download_util';
import * as styles from './styles/styles.module.scss';

const DOWNLOAD_ROUTE_STRINGS = defineMessages({
  DOWNLOAD_ROUTE: { defaultMessage: 'Download route' },
  CHOOSE_FILE_FORMAT: { defaultMessage: 'Select a file format' },
  OK: { defaultMessage: 'OK' },
  CANCEL: { defaultMessage: 'Cancel' }
});

type Props = {
  toggleDownloadModal: () => void;
  mapId?: number;
  trailSlug?: string;
};

const DownloadRouteModal = ({ mapId, trailSlug, toggleDownloadModal }: Props) => {
  const {
    formattedDefaultMessages: { DOWNLOAD_ROUTE, CHOOSE_FILE_FORMAT, OK, CANCEL }
  } = useFormatMessage(DOWNLOAD_ROUTE_STRINGS);
  const [selectedDownloadOption, setDownloadOption] = useState(downloadOptions[0]);

  const handleSelectChange = (value: string) => {
    const selected = downloadOptions.find(option => option.value === value);
    setDownloadOption(selected);
  };

  const handleDownload = async () => {
    const recaptcha = await execute('downloadRoute');

    const format = selectedDownloadOption?.value;

    if (mapId) {
      window.location.assign(`/maps/${mapId}/gpx?key=${X_AT_KEY}&format=${format}&recaptcha=${recaptcha}`);
      toggleDownloadModal();
    } else {
      window.location.assign(`/${trailSlug}/gpx?ref=trail-more-gpx&format=${format}&recaptcha=${recaptcha}`);
      toggleDownloadModal();
    }
  };

  return (
    <CustomProvider>
      <ModalBackground closeModal={toggleDownloadModal}>
        <ModalInterior
          title={DOWNLOAD_ROUTE}
          closeModal={toggleDownloadModal}
          isMounted
          baseCtas={{
            primaryCta: {
              onClick: handleDownload,
              text: OK,
              testId: OK
            },
            secondaryCta: {
              onClick: toggleDownloadModal,
              text: CANCEL,
              testId: CANCEL
            }
          }}
        >
          <div className={styles.downloadModalContainer}>
            <div className={styles.dropdownContainer}>
              <Select
                labelText={CHOOSE_FILE_FORMAT}
                placeholder=""
                options={downloadOptions}
                onChange={value => handleSelectChange(value)}
                value={selectedDownloadOption.value}
                testId="download-type-select"
              />
            </div>
          </div>
        </ModalInterior>
      </ModalBackground>
    </CustomProvider>
  );
};

export default DownloadRouteModal;
