import Select from '@alltrails/shared/denali/components/Select';
import CustomProvider from 'components/CustomProvider';
import { ServerCommunicationUtil } from '../../../../utils/server_communication_util';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const createReactClass = require('create-react-class');

const RegionAndCountrySelect = createReactClass({
  getInitialState() {
    return {
      allCountries: [],
      allRegions: [],
      regionId: this.props.selectedRegion,
      countryId: this.props.selectedCountry
    };
  },
  // eslint-disable-next-line react/no-unused-class-component-methods
  getSelectedValues() {
    const region = this.state.allRegions.filter(r => r.id === this.state.regionId)[0];
    const country = this.state.allCountries.filter(c => c.id === this.state.countryId)[0];
    return {
      regionId: region ? region.id : null,
      regionAbbr: region ? region.abbr : null,
      countryId: country ? country.id : null,
      countryAbbr: country ? country.isoCode : null
    };
  },
  componentDidMount() {
    ServerCommunicationUtil.getApiEndpoint('/api/alltrails/countries', {}, this.handleCountryLoad, this.handleGetError, this.handleGetComplete);
  },
  handleGetError() {},
  handleGetComplete() {},
  handleCountryLoad(data) {
    if (data.countries.length > 0) {
      this.setState({ allCountries: data.countries });
      const country = data.countries.filter(ctry => ctry.id === this.state.countryId)[0];
      if (country) {
        ServerCommunicationUtil.getApiEndpoint(
          `/api/alltrails/countries/${country.id}/regions`,
          {},
          this.handleRegionLoad,
          this.handleGetError,
          this.handleGetComplete
        );
      }
    } else {
      this.setState({ allCountries: [] });
    }
  },
  handleRegionLoad(data) {
    if (data.regions.length > 0) {
      // var regions = data.regions.reduce(function(map, reg) {map[reg.abbr] = reg; return map;}, {});
      this.setState({ allRegions: data.regions });
    } else this.setState({ allRegions: [] });
  },
  handleCountryChange(value) {
    const countryId = parseInt(value);
    const country = this.state.allCountries.filter(ctry => ctry.id === countryId)[0];
    ServerCommunicationUtil.getApiEndpoint(
      `/api/alltrails/countries/${country.id}/regions`,
      {},
      this.handleRegionLoad,
      this.handleGetError,
      this.handleGetComplete
    );
    this.props.setSelectedCountry(value);
    this.setState({ countryId });
  },
  handleRegionChange(value) {
    const regionId = parseInt(value);
    this.props.setSelectedRegion(value);
    this.setState({ regionId });
  },
  generateRegionOption(region) {
    return {
      label: region.name,
      value: region.id
    };
  },
  generateCountryOption(country) {
    return {
      label: country.name,
      value: country.id
    };
  },
  render() {
    const countries = this.state.allCountries.map(this.generateCountryOption);
    const regions = this.state.allRegions.map(this.generateRegionOption);
    return (
      <CustomProvider>
        <div>
          {this.state.allRegions.length === 0 ? null : (
            <Select
              labelText={this.props.intl.formatMessage({ defaultMessage: 'Region' })}
              onChange={selectedValue => this.handleRegionChange(selectedValue)}
              options={regions}
              testId="region-select"
              value={this.state.regionId || ''}
              size="sm"
            />
          )}
          <Select
            labelText=""
            onChange={selectedValue => this.handleCountryChange(selectedValue)}
            options={countries}
            testId="country-select"
            value={this.state.countryId || ''}
            size="sm"
          />
        </div>
      </CustomProvider>
    );
  }
});

export default RegionAndCountrySelect;
