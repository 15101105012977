import mapboxgl from 'mapbox-gl';
import ReactDOM from 'react-dom';
import MapboxglSpiderifier from './mapboxgl_spiderifier';
import { addLayerEvent, fireLayerEvent } from '../layers/layer_helpers';

const mountReactComponent = component => {
  const domContent = document.createElement('div');
  ReactDOM.render(component, domContent);
  return domContent;
};

const unmountReactComponent = domContent => {
  ReactDOM.unmountComponentAtNode(domContent);
  domContent.remove();
};

const markerMouseLeaveHelper = (e, popup, closePopup, handleMouseLeave) => {
  if (!popup.isOpen() && handleMouseLeave) {
    handleMouseLeave();
    return;
  }
  if (e.originalEvent) {
    e = e.originalEvent;
  }

  const closestPopup = $(e.relatedTarget).closest('.mapboxgl-popup')[0];
  const keepPopupOpen = closestPopup === popup.getElement();

  if (!keepPopupOpen) {
    closePopup();
  }
};

// Ensure that if the map is zoomed out such that multiple
// copies of the feature are visible, the popup appears
// over the copy being pointed to.
const getAdjustedCoordinates = (lngLat, feature) => {
  const geometry = feature.geometry;
  if (geometry.type !== 'Point') {
    return lngLat;
  }
  const coordinates = geometry.coordinates;
  while (Math.abs(lngLat.lng - coordinates[0]) > 180) {
    coordinates[0] += lngLat.lng > coordinates[0] ? 360 : -360;
  }
  return coordinates;
};

const getPopupDOMContent = popup => {
  const element = popup.getElement();
  if (!element) {
    return null;
  }
  const popupContent = element.querySelector('.mapboxgl-popup-content');
  if (!popupContent) {
    return null;
  }
  return popupContent.children[0];
};

// This is needed because properties of features in events are currently serialized as JSON
// We want this to continue working if that is ever changed
// See https://github.com/mapbox/mapbox-gl-js/issues/2434
const deserializeProperty = property => {
  try {
    property = JSON.parse(property);
  } catch (e) {
    if (!(e instanceof SyntaxError)) {
      throw e;
    }
  }

  return property;
};

const addHoverPopupToMap = (map, layerId, buildContent, handleMouseEnter = null, handleMouseLeave = null, popupOptions = {}, clickOnly = false) => {
  const popup = new mapboxgl.Popup({
    closeButton: false,
    closeOnClick: false, // This is done manually through onMouseEnter
    maxWidth: 'none',
    focusAfterOpen: false
  });

  const closePopup = () => {
    if (!popup.isOpen()) {
      return;
    }
    popup.remove();
    const domContent = getPopupDOMContent(popup);
    if (domContent) {
      unmountReactComponent(domContent);
    }
    if (handleMouseLeave) {
      handleMouseLeave();
    }
  };

  const onMouseEnter = e => {
    closePopup();

    const lngLat = e.lngLat;
    const feature = e.features[0];

    const object = feature.properties;
    if (handleMouseEnter) {
      handleMouseEnter(e, object);
    }

    if (map.shiftKeyDown) {
      return;
    }

    // If firing event remotely, it's possible that pin might not be in the viewport, in this case, jump to its location
    const coordinates = getAdjustedCoordinates(lngLat, feature);
    if (!map.getBounds().contains(coordinates)) {
      map.jumpTo({ center: coordinates });
    }

    const popupContent = mountReactComponent(buildContent(object));
    popupContent.onmouseleave = () => {
      closePopup();
    };
    popup.options.offset = deserializeProperty(object.popupOffset) || popupOptions.offset;
    popup.setLngLat(coordinates).setDOMContent(popupContent).addTo(map);
  };

  const onMouseLeave = e => {
    markerMouseLeaveHelper(e, popup, closePopup, handleMouseLeave);
  };

  if (clickOnly || (map.context && map.context.mobileBrowser)) {
    addLayerEvent(map, layerId, true, 'click', onMouseEnter);
    addLayerEvent(map, layerId, true, 'unclick', closePopup);
  } else {
    addLayerEvent(map, layerId, true, 'mouseenter', onMouseEnter);
    addLayerEvent(map, layerId, true, 'mouseleave', onMouseLeave);
    addLayerEvent(map, layerId, false, 'click', closePopup);
  }
  addLayerEvent(map, layerId, true, 'removed', closePopup);
  addLayerEvent(map, layerId, false, 'moveend', () => {
    // Remove popup when popup anchor is no longer in bounds of map
    if (popup.isOpen() && !map.getBounds().contains(popup.getLngLat())) {
      closePopup();
    }
  });

  return {
    popup,
    closePopup
  };
};

const addHoverPopupToSpiderLeg = (map, popup, spiderLeg, buildContent, closePopup, handleMouseEnter = null, handleMouseLeave = null) => {
  const onMouseEnter = e => {
    const object = spiderLeg.feature;

    if (handleMouseEnter) {
      handleMouseEnter(e, object);
    }

    if (map.shiftKeyDown) {
      return;
    }

    const popupContent = mountReactComponent(buildContent(object));
    spiderLeg.mapboxMarker.setPopup(popup); // This sets the lngLat to that of the spiderLeg marker
    popup.options.offset = MapboxglSpiderifier.popupOffsetForSpiderLeg(spiderLeg);
    popup.setDOMContent(popupContent).addTo(map);
    popup.getElement().onmouseleave = closePopup;
  };

  const onMouseLeave = e => {
    markerMouseLeaveHelper(e, popup, closePopup, handleMouseLeave);
  };

  const pin = spiderLeg.elements.pin;
  if (map.context && map.context.mobileBrowser) {
    pin.onclick = e => {
      // need timeout to allow for "map.click" event to fire (which will close any existing popups) BEFORE click on the popup
      window.setTimeout(() => onMouseEnter(e), 50);
    };
  } else {
    pin.onmouseenter = onMouseEnter;
  }
  pin.onmouseleave = onMouseLeave;
};

export { addHoverPopupToMap, addHoverPopupToSpiderLeg, mountReactComponent, unmountReactComponent, getPopupDOMContent, markerMouseLeaveHelper };
