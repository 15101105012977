import { loadAndAddImage } from '../image_helpers';
import logError from 'utils/logError';

const labelImageId = `map-marker-label`;
const labelImageSrc = `https://cdn-assets.alltrails.com/assets/icons/maps/markers/map-marker-label@2x.png`;

const addLabelImagesToMap = map => {
  return loadAndAddImage(map, labelImageId, labelImageSrc, {
    pixelRatio: 2
  }).catch(logError);
};

const createLabelLayer = (id, sourceId, field, filter = null) => {
  const layer = {
    id,
    type: 'symbol',
    source: sourceId,
    layout: {
      'icon-anchor': 'bottom',
      'icon-allow-overlap': true,
      'icon-ignore-placement': true,
      'icon-image': ['image', labelImageId],
      'icon-text-fit': 'both',
      'icon-text-fit-padding': [8, 12, 8, 12],
      'icon-offset': [0, 0],
      'text-anchor': 'bottom',
      'text-field': ['get', field],
      'text-font': ['Manrope Bold'],
      'text-offset': [0, -1.7], // Trying our best to get 8px offset https://www.figma.com/file/gZaqAej8HzoFkyrjlQ4cNs/Integrating-Brand-Changes?node-id=1176%3A61415
      'text-size': 13
    }
  };
  if (filter) {
    layer.filter = filter;
  }
  return layer;
};

export { addLabelImagesToMap, createLabelLayer };
