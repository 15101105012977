import logExploreCommunityContentClicked from '@alltrails/analytics/events/logExploreCommunityContentClicked';
import logExploreCuratedTrailsClicked from '@alltrails/analytics/events/logExploreCuratedTrailsClicked';
import { defineMessages } from '@alltrails/shared/react-intl';
import useFormatMessage from '@alltrails/shared/hooks/useFormatMessage';
import { PageStrings } from '@alltrails/shared/utils/constants/pageStringHelpers';
import classNames from 'classnames';
import CustomProvider from 'components/CustomProvider';
import * as styles from './styles/styles.module.scss';

export type Props = {
  activeItemKey: string;
  loading: unknown;
  onClick: (pageKey: string) => void;
  resultCount: number;
};

type Tab = {
  inactiveString: string;
  activeString: string;
  itemKey: string;
  analyticsHandler: () => void;
};

const messages = defineMessages({
  COMMUNITY_CONTENT: {
    defaultMessage: 'Community content'
  },
  CURATED_TRAILS: {
    defaultMessage: 'Curated trails'
  },
  MAX_NUM_RESULTS: {
    defaultMessage: '1000+'
  }
});

const ExploreTabBar = ({ activeItemKey, loading, onClick, resultCount }: Props) => {
  const {
    formattedDefaultMessages: { COMMUNITY_CONTENT, CURATED_TRAILS, MAX_NUM_RESULTS }
  } = useFormatMessage(messages);
  if (![PageStrings.EXPLORE_ALL_PAGE, PageStrings.EXPLORE_COMMUNITY_CONTENT_PAGE].includes(activeItemKey)) {
    return null;
  }

  let derivedResultCount: number | string = resultCount;
  if (resultCount >= 1000) {
    derivedResultCount = MAX_NUM_RESULTS;
  }

  const tabs: Tab[] = [
    {
      inactiveString: CURATED_TRAILS,
      activeString: `${CURATED_TRAILS} (${derivedResultCount})`,
      itemKey: PageStrings.EXPLORE_ALL_PAGE,
      analyticsHandler: logExploreCuratedTrailsClicked
    },
    {
      inactiveString: COMMUNITY_CONTENT,
      activeString: `${COMMUNITY_CONTENT} (${derivedResultCount})`,
      itemKey: PageStrings.EXPLORE_COMMUNITY_CONTENT_PAGE,
      analyticsHandler: logExploreCommunityContentClicked
    }
  ];

  const action = (item: Tab) => () => {
    item.analyticsHandler();

    onClick(item.itemKey);
  };

  return (
    <CustomProvider>
      <div className={classNames(styles.tabBar, styles.explore)} role="tablist">
        {tabs.map(item => {
          const { inactiveString, activeString, itemKey } = item;
          const isActive = itemKey === activeItemKey;

          return (
            <div
              key={item.itemKey}
              tabIndex={0}
              role="tab"
              className={classNames(styles.tab, styles.explore, { [styles.active]: isActive })}
              onClick={action(item)}
              onKeyDown={action(item)}
            >
              {isActive && !loading ? activeString : inactiveString}
            </div>
          );
        })}
      </div>
    </CustomProvider>
  );
};

export default ExploreTabBar;
