import { defineMessages } from '@alltrails/shared/react-intl';
import Button from '@alltrails/shared/denali/components/Button';
import useFormatMessage from '@alltrails/shared/hooks/useFormatMessage';
import type { TrailId } from '@alltrails/shared/types/trail';
import LoadingDots from '@alltrails/shared/denali/components/LoadingDots';
import { deleteAllWaypoints, reorderWaypoints, updateAllWaypoints } from 'api/Waypoints';
import { WaypointsAction } from 'hooks/useWaypoints';
import Waypoint from 'types/Waypoint';
import Waypoints from '../Waypoints';
import * as styles from './styles/styles.module.scss';

const WAYPOINTS_SECTION_STRINGS = defineMessages({
  ADD_WAYPOINT: { defaultMessage: 'Add new waypoint' },
  DELETE_ALL: { defaultMessage: 'Delete all' },
  DELETE_ALL_QUESTION: { defaultMessage: 'Are you sure you want to delete ALL the waypoints for this trail?' },
  HIDE_ALL: { defaultMessage: 'Hide all titles' },
  SHOW_ALL: { defaultMessage: 'Show all titles' },
  REVERSE: { defaultMessage: 'Reverse order' }
});

type WaypointsSectionProps = {
  waypoints: Waypoint[];
  dispatchWaypoints: React.Dispatch<WaypointsAction>;
  editIndex?: number;
  messagingChannel: any;
  mapId: number;
  sortMode?: boolean;
  canEdit: boolean;
  trailId?: TrailId;
  createMapWithWaypoint?: (waypoint: Waypoint) => void;
  dispatchFormState?: boolean;
  refetchTrailProps?: () => void;
  isLoading?: boolean;
  onWaypointFormSubmit?: () => void;
};

const WaypointsSection = ({
  waypoints,
  dispatchWaypoints,
  editIndex,
  messagingChannel,
  mapId,
  sortMode,
  canEdit,
  trailId,
  createMapWithWaypoint,
  dispatchFormState,
  refetchTrailProps,
  isLoading,
  onWaypointFormSubmit
}: WaypointsSectionProps) => {
  const {
    formattedDefaultMessages: { ADD_WAYPOINT, DELETE_ALL, DELETE_ALL_QUESTION, HIDE_ALL, SHOW_ALL, REVERSE }
  } = useFormatMessage(WAYPOINTS_SECTION_STRINGS);
  let editButtons;

  if (canEdit) {
    let addNewWaypointButton;
    if (editIndex === null) {
      const addWaypoint = () => {
        dispatchWaypoints({ type: 'ADD_NEW_WAYPOINT_FORM' });
      };

      addNewWaypointButton = (
        <div className={styles.newWaypointContainer}>
          <Button text={ADD_WAYPOINT} onClick={addWaypoint} testId="waypoints-add-new-waypoint" width="full" />
        </div>
      );
    }

    let allWaypointsButtons;
    if (editIndex === null && waypoints.length > 0) {
      const isAllShowTitle = () => {
        // Only allow admins to hide all titles, not show all
        if (trailId) {
          return true;
        }
        return waypoints.every(wp => wp.waypointDisplayProperty.showTitle);
      };

      const handleAllTitlesToggle = () => {
        if (!canEdit || waypoints.length < 1) {
          return;
        }

        const showTitle = !isAllShowTitle();
        updateAllWaypoints(mapId, { showTitle }).then(() => {
          dispatchWaypoints({ type: 'TOGGLE_SHOW_ALL_TITLES', payload: showTitle });
        });
      };

      const handleDeleteAll = () => {
        if (!canEdit || waypoints.length < 1 || !confirm(DELETE_ALL_QUESTION)) {
          return;
        }

        deleteAllWaypoints(mapId).then(() => {
          dispatchWaypoints({ type: 'DELETE_ALL_WAYPOINTS' });
          refetchTrailProps?.();
        });
      };

      const handleReverseClick = () => {
        if (!canEdit || waypoints.length < 1) {
          return;
        }

        const reversedWaypointIds = waypoints
          .map(wp => wp.id)
          .filter(wpId => !!wpId)
          .reverse();
        reorderWaypoints(mapId, reversedWaypointIds).then(response => {
          dispatchWaypoints({ type: 'REORDER_WAYPOINTS', payload: response.waypoints });
        });
      };

      allWaypointsButtons = (
        <div className={styles.adminButtons}>
          <Button text={DELETE_ALL} onClick={handleDeleteAll} size="sm" testId="waypoints-delete-all" variant="flat" width="full" />
          {/* don't allow waypoint titles to be shown/hidden on trail page */}
          {!trailId && (
            <Button
              text={isAllShowTitle() ? HIDE_ALL : SHOW_ALL}
              onClick={handleAllTitlesToggle}
              size="sm"
              testId="waypoints-hide-all-show-all"
              variant="flat"
              width="full"
            />
          )}
          <Button text={REVERSE} onClick={handleReverseClick} size="sm" testId="waypoints-reverse-order" variant="flat" width="full" />
        </div>
      );
    }

    editButtons = (
      <>
        {addNewWaypointButton}
        {allWaypointsButtons}
      </>
    );
  }

  if (isLoading) {
    return <LoadingDots testId="loading-dots" />;
  }

  return (
    <div className={styles.container}>
      <Waypoints
        waypoints={waypoints}
        editIndex={editIndex}
        dispatchWaypoints={dispatchWaypoints}
        messagingChannel={messagingChannel}
        mapId={mapId}
        sortMode={sortMode}
        canEdit={canEdit}
        trailId={trailId}
        createMapWithWaypoint={createMapWithWaypoint}
        dispatchFormState={dispatchFormState}
        refetchTrailProps={refetchTrailProps}
        onWaypointFormSubmit={onWaypointFormSubmit}
      />
      {editButtons}
    </div>
  );
};

export default WaypointsSection;
