// extracted by mini-css-extract-plugin
export var borderBottom = "styles-module__borderBottom___kHVpN";
export var contributeButton = "styles-module__contributeButton___InsaX";
export var contributeInfo = "styles-module__contributeInfo___NF4JS";
export var graphBar = "styles-module__graphBar___k3koS";
export var headerContainer = "styles-module__headerContainer___n3Hdo";
export var invitationContainer = "styles-module__invitationContainer___sFtqd";
export var invitationHeader = "styles-module__invitationHeader___WOhGf";
export var invitationText = "styles-module__invitationText___SOwig";
export var label = "styles-module__label___T9dDY";
export var rating = "styles-module__rating___kn8he";
export var ratingCount = "styles-module__ratingCount___OIZgF";
export var ratingDisplay = "styles-module__ratingDisplay___gZD8w";
export var ratingsTable = "styles-module__ratingsTable___yRHwD";
export var reviewsContainer = "styles-module__reviewsContainer___F054k";
export var reviewsMobile = "styles-module__reviewsMobile___EKeiv";
export var star = "styles-module__star___Z5xaT";
export var ugcActions = "styles-module__ugcActions___Zu1XC";