import { IntlShape } from '@alltrails/shared/react-intl';
import {
  FilterViewModel,
  FilterConfigStarRating,
  FilterConfigToggle,
  FilterInput,
  Filters,
  FilterConfigRange,
  FilterConfigSlider
} from 'types/Search';
import { FILTER_STRINGS } from 'utils/filters/filters.strings';

const getSearchBarFiltersConfigurations = (filters: Filters, intl: IntlShape): FilterViewModel[] => [
  {
    display: intl.formatMessage(FILTER_STRINGS.SORT_LABEL),
    key: 'sort',
    enabledKey: 'sort',
    type: FilterInput.RADIO,
    config: filters.sort as FilterConfigToggle
  },
  {
    display: intl.formatMessage(FILTER_STRINGS.DISTANCE_AWAY),
    key: 'distance_away',
    enabledKey: 'distance_away',
    type: FilterInput.SLIDER,
    config: filters.distance_away as FilterConfigSlider,
    isProFilter: true
  },
  {
    display: intl.formatMessage(FILTER_STRINGS.ACTIVITY_LABEL),
    key: 'activities',
    enabledKey: 'activity',
    type: FilterInput.RADIO,
    truncate: true,
    config: filters.activities as FilterConfigToggle
  },
  {
    display: intl.formatMessage(FILTER_STRINGS.DIFFICULTY),
    key: 'difficulty',
    enabledKey: 'difficulty',
    type: FilterInput.CHECKBOX,
    config: filters.difficulty as FilterConfigToggle
  },
  {
    display: intl.formatMessage(FILTER_STRINGS.LENGTH),
    key: 'lengths',
    enabledKey: 'length',
    type: FilterInput.RANGE,
    config: filters.lengths as FilterConfigRange
  },
  {
    display: intl.formatMessage(FILTER_STRINGS.SUITABILITY),
    key: 'access',
    enabledKey: 'access',
    type: FilterInput.CHECKBOX,
    config: filters.access as FilterConfigToggle,
    truncate: true
  },
  {
    display: intl.formatMessage(FILTER_STRINGS.FEATURES_LABEL),
    key: 'features',
    enabledKey: 'feature',
    type: FilterInput.CHECKBOX,
    nested: true,
    truncate: true,
    config: filters.features as FilterConfigToggle,
    extraProps: {
      useColumns: true
    }
  },
  {
    display: intl.formatMessage(FILTER_STRINGS.ELEV_GAIN),
    key: 'elevation_gain',
    enabledKey: 'elevation_gain',
    type: FilterInput.RANGE,
    nested: true,
    config: filters.elevation_gain as FilterConfigRange
  },
  {
    display: intl.formatMessage(FILTER_STRINGS.ROUTE_TYPE),
    key: 'route_type',
    enabledKey: 'route_type',
    type: FilterInput.CHECKBOX,
    nested: true,
    config: filters.route_type as FilterConfigToggle
  },
  {
    display: intl.formatMessage(FILTER_STRINGS.RATING),
    key: 'minRating',
    enabledKey: 'rating',
    type: FilterInput.STAR_RATING,
    nested: true,
    config: filters.minRating as FilterConfigStarRating
  },
  {
    display: intl.formatMessage(FILTER_STRINGS.TRAIL_TRAFFIC),
    key: 'visitor_usage',
    enabledKey: 'visitor_usage',
    type: FilterInput.CHECKBOX,
    nested: true,
    config: filters.visitor_usage as FilterConfigToggle,
    extraProps: {
      useColumns: true
    }
  },
  {
    display: intl.formatMessage(FILTER_STRINGS.HIGHEST_POINT),
    key: 'highest_point',
    enabledKey: 'highest_point',
    type: FilterInput.RANGE,
    nested: true,
    config: filters.highest_point as FilterConfigRange
  },
  {
    display: intl.formatMessage(FILTER_STRINGS.TRAIL_COMPLETION),
    key: 'completed',
    enabledKey: 'completed',
    type: FilterInput.CHECKBOX,
    nested: true,
    config: filters.completed as FilterConfigToggle,
    extraProps: {
      useColumns: true
    }
  },
  {
    display: 'Closed',
    key: 'closed_status',
    enabledKey: 'closed_status',
    type: FilterInput.CHECKBOX,
    admin: true,
    config: filters.closed_status as FilterConfigToggle,
    extraProps: {
      useColumns: true
    }
  },
  {
    display: 'Parks',
    key: 'areas',
    enabledKey: 'area',
    type: FilterInput.CHECKBOX,
    admin: true,
    truncate: true,
    config: filters.areas as unknown as FilterConfigToggle,
    extraProps: {
      useColumns: true
    }
  },
  {
    display: 'City',
    key: 'cities',
    enabledKey: 'city',
    type: FilterInput.CHECKBOX,
    admin: true,
    truncate: true,
    config: filters.cities as unknown as FilterConfigToggle,
    extraProps: {
      useColumns: true
    }
  },
  {
    display: 'State',
    key: 'states',
    enabledKey: 'state',
    type: FilterInput.CHECKBOX,
    admin: true,
    truncate: true,
    config: filters.states as unknown as FilterConfigToggle,
    extraProps: {
      useColumns: true
    }
  },
  {
    display: 'Country',
    key: 'countries',
    enabledKey: 'country',
    type: FilterInput.CHECKBOX,
    admin: true,
    truncate: true,
    config: filters.countries as FilterConfigToggle,
    extraProps: {
      useColumns: true
    }
  },
  {
    display: 'Linked',
    key: 'linked',
    enabledKey: 'linked',
    type: FilterInput.RADIO,
    admin: true,
    truncate: true,
    config: filters.linked as FilterConfigToggle,
    extraProps: {
      useColumns: true
    }
  }
];

export default getSearchBarFiltersConfigurations;
