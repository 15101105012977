import { useCallback, useState } from 'react';
import { IntlShape } from '@alltrails/shared/react-intl';

export type ButtonConfig = {
  label: string;
  labelInProgress?: string;
  labelSuccess?: string;
  labelFailed?: string;
  handleClick: () => Promise<any> | void;
  id: string;
};

const useAdminCTAs = (bottomButtonsConfig?: ButtonConfig[], intl?: IntlShape) => {
  const [adminCTALabels, setAdminCTALabels] = useState<string[]>(bottomButtonsConfig ? bottomButtonsConfig.map(({ label }) => label) : []);

  const updateLabelAtIndex = useCallback(
    (index: number, label: string) => {
      const newLabels = [...adminCTALabels.slice(0, index), label, ...adminCTALabels.slice(index + 1)];
      setAdminCTALabels(newLabels);
    },
    [adminCTALabels]
  );

  const onAdminCTAClick = useCallback(
    (index: number, config: ButtonConfig) => {
      const { labelInProgress, labelFailed, labelSuccess, handleClick } = config;
      // Not a promise if there is no labelInProgress
      if (!labelInProgress) {
        return handleClick();
      }

      updateLabelAtIndex(index, labelInProgress);
      return Promise.resolve(handleClick())
        .then(() => {
          updateLabelAtIndex(index, labelSuccess || intl.formatMessage({ defaultMessage: 'Success' }));
        })
        .catch(_ => {
          updateLabelAtIndex(index, labelFailed || intl.formatMessage({ defaultMessage: 'Failed' }));
        });
    },
    [updateLabelAtIndex]
  );

  if (!bottomButtonsConfig || !bottomButtonsConfig.length) {
    return undefined;
  }

  return bottomButtonsConfig.map((config, index) => ({
    label: adminCTALabels[index],
    id: config.id,
    onClick: () => onAdminCTAClick(index, config)
  }));
};

export default useAdminCTAs;
