import turfDistance from '@turf/distance';
import { PageStrings } from '@alltrails/shared/utils/constants/pageStringHelpers';
import isEmpty from 'underscore/modules/isEmpty';
import { AlgoliaResultType } from 'types/Search/Algolia';
import { legacyAlgoliaSearchAppQuery } from 'utils/legacyAlgoliaSearchQuery';
import { ServerCommunicationUtil } from '../../../../../utils/server_communication_util';
import { SearchResultsUtil } from '../../../../../utils/search_results_util';
import { SearchFiltersUtil } from '../../../../../utils/search_filters_util';
import { getPrimaryIndex, getMapsIndex } from '../../../../../utils/search';
import { logSearchResultsAnalytics } from '../../../../../utils/explore_analytics_helpers';
import { EXPLORE } from '../../../../../utils/constants/SearchAnalyticsConstants';
import logError from '../../../../../utils/logError';

// TODO: Refactor this into algolia helpers/service and separate state management from Algolia requests
const SearchAppAlgoliaMixin = {
  initAlgolia() {
    this.primaryIndex = getPrimaryIndex(this.props.context.languageRegionCode);
    this.mapsIndex = getMapsIndex();
    this.updateAlgoliaIndex();
  },
  updateAlgoliaIndex() {
    this.algoliaIndex = this.state.page === PageStrings.EXPLORE_COMMUNITY_CONTENT_PAGE ? this.mapsIndex : this.primaryIndex;
  },
  performSearch(customMaps = null) {
    // Although this could exist at the top-level, this is scoped inside
    // performSearch in an attempt to remove ambiguity and make sure no mixins
    // or react class components try to lean on this handler directly. Trying to
    // minimize the footprint of the mixins in this file.
    const handleResponse = content => {
      const center = this.refs.searchMap?.getMapCenter?.();
      const bounds = this.refs.searchMap?.getMapBounds?.();
      // Bounds 0,2 is Top Left corner
      if (!center || !bounds) {
        // the map isn't rendered yet, do not complete the search and filter setting
        return;
      }
      const searchRadius = turfDistance([center[1], center[0]], [bounds[2], bounds[0]]) / 2;

      const combinedResults = customMaps !== null ? [...content.hits, ...customMaps] : content.hits;
      const updatedResults = combinedResults.map(result => {
        // add list item created date
        if (this.props.listItems && this.props.listId && !isEmpty(this.props.listItems[this.props.listId])) {
          const currentListItem = this.props.listItems[this.props.listId]?.[result.type]?.[result.ID];
          return {
            ...result,
            list_item_created: currentListItem?.created
          };
        }
        return result;
      });

      const resultsWithLocations = updatedResults.filter(
        result => !(result._geoloc.lat == null || result._geoloc.lng == null || (result._geoloc.lat === 0 && result._geoloc.lng === 0))
      );

      // Sort results and zoom to fit
      const getListItem = (type, ID) => this.props.listMethods.getListItemInList(this.props.listId, type, ID, this.props.listItems);
      const sortedResults = SearchResultsUtil.applySortFunction(resultsWithLocations, this.state.filters, center, searchRadius, getListItem);
      logSearchResultsAnalytics(this.state.filters, sortedResults, content.hitsPerPage, center, searchRadius, EXPLORE, this.state.searchTrigger);

      this.setState({ results: sortedResults, loading: false, searchTrigger: null });
    };

    legacyAlgoliaSearchAppQuery({
      algoliaIndex: this.algoliaIndex,
      atMaps: this.state.atMaps,
      context: this.props.context,
      filters: this.state.filters,
      handlePlaceSearchResults: this.handlePlaceSearchResults,
      handleSearchResults: handleResponse,
      handleUserResults: results => {
        this.setState({
          results,
          loading: false
        });
      },
      initialUserSlug: this.props.initialUserSlug,
      insidePolygon: this.props.insidePolygon,
      isMobileWidth: this.props.isMobileWidth,
      listMethods: this.props.listMethods,
      page: this.state.page
    });
  },
  filterResults(results, objectIdPrefix) {
    return results.filter(r => r.objectID.indexOf(objectIdPrefix) > -1);
  },
  handlePlaceSearchResults(content) {
    const newFilterState = this.state.filters;

    let needToPerformSearch = false;
    [
      ['areas', 'area-'],
      ['countries', 'country-'],
      ['states', 'state-'],
      ['cities', 'cityo-']
    ].forEach(keys => {
      const results = this.filterResults(content.hits, keys[1]);
      if (this.handleLocSearchResults(newFilterState, keys[0], results)) {
        needToPerformSearch = true;
      }
    });

    this.setState({ filters: newFilterState }, needToPerformSearch ? this.replaceHistoryStateAndSearch : null);
  },
  handleLocSearchResults(filters, filtersKey, results) {
    // Use loc results to modify locs filter

    const limit = 10;
    const locs = filters[filtersKey];

    // Sort results by selected, popularity, name, then finally id
    results = results
      .sort((a, b) => {
        const aSelected = locs[a.ID] && locs[a.ID].selected;
        const bSelected = locs[b.ID] && locs[b.ID].selected;
        if (aSelected && !bSelected) {
          return -1;
        }
        if (!aSelected && bSelected) {
          return 1;
        }
        if (a.popularity !== b.popularity) {
          return b.popularity - a.popularity;
        }
        if (a.name) {
          return a.name.localeCompare(b.name);
        }
        return b.ID - a.ID;
      })
      .slice(0, limit);

    // Handle results accordingly
    const locsToAdd = [];
    results.forEach(result => {
      const currLoc = locs[result.ID];
      const { name } = result;
      if (currLoc && currLoc.selected) {
        // update currently selected
        currLoc.name = name;
        currLoc.popularity = result.popularity;
      } else {
        // new
        locsToAdd.push({
          value: result.ID,
          name,
          popularity: result.popularity,
          selected: false
        });
      }
    });

    // Creating clone so we can assign new values all at once at bottom of func (prevents weird concurrency issues)
    const newLocs = { ...locs };

    // Remove locs that aren't selected
    for (const key in newLocs) {
      if (newLocs.hasOwnProperty(key) && !newLocs[key].selected) {
        delete newLocs[key];
      }
    }

    // Add to newLocs until reaches total count of 10
    const count = Object.keys(newLocs).length;
    for (let i = 0; i < locsToAdd.length; i++) {
      const loc = locsToAdd[i];
      if (count >= limit) {
        break;
      }
      newLocs[loc.value] = loc;
    }

    // Unselect location and perform a new search if Algolia results never came back with a name
    // (likely that the location query param was set for a location that doesn't exist in the bounding box)
    let needToPerformSearch = false;
    for (const key in newLocs) {
      if (newLocs.hasOwnProperty(key) && !newLocs[key].name) {
        newLocs[key].selected = false;
        needToPerformSearch = true;
      }
    }

    filters[filtersKey] = newLocs;

    return needToPerformSearch;
  },
  getAlgoliaTrail(trailId) {
    if (!trailId) return;
    this.algoliaIndex
      .search('', {
        hitsPerPage: 1,
        distinct: 1,
        getRankingInfo: 1,
        allowTyposOnNumericTokens: 0,
        facets: ['type'],
        facetFilters: ['type:trail'],
        numericFilters: [`ID=${trailId}`]
      })
      .then(this.handleTrailSearchResult)
      .catch(logError);
  },
  handleTrailSearchResult(content) {
    if (content.hits && content.hits.length < 1) {
      return;
    }
    const trail = content.hits[0];
    if (this.state.page === PageStrings.EXPLORE_TRAIL_MAP_PAGE) {
      this.setState({ selectedObject: trail });
    } else if (
      [PageStrings.EXPLORE_USERS_MAPS_MAP_PAGE, PageStrings.EXPLORE_USERS_TRACKS_MAP_PAGE, PageStrings.EXPLORE_LIFELINE_PAGE].includes(
        this.state.page
      )
    ) {
      this.setState({ trackTrail: trail });
    }
  },
  requiresBoundsCalc(obj) {
    function isType(t) {
      return obj.objectID.indexOf(t) >= 0;
    }
    return isType('area') || isType('city') || isType('state') || isType('country');
  },
  getBoundingBoxOfTrails(obj, success, error) {
    const splt = obj.objectID.split('-');
    const [sType, id] = splt;
    // eslint-disable-next-line no-undef
    $.ajax({
      url: '/api/alltrails/locations/bounding_box',
      type: 'get',
      data: {
        s_type: sType,
        id
      },
      headers: {
        'X-AT-KEY': ServerCommunicationUtil.apiKey
      },
      success,
      error
    });
  },
  handleObjectSelected(obj) {
    // Create a new instance of search filters effectively clearing all current filters and location/area state.
    const newFilterState = SearchFiltersUtil.getSearchFilters(this.props.context, this.state.page, this.props.intl, this.props.initialUserSlug);

    // If the user selected a filter result type from Algolia enable that filter.
    if (obj.type === AlgoliaResultType.Filter) {
      // Preserve the current bounding box.
      newFilterState.boundingBox = { ...this.state.filters.boundingBox };
      // Determine if the filter is already enabled:
      if (obj.filters?.feature?.includes?.('waterfall') && newFilterState.features?.waterfall?.selected !== true) {
        SearchFiltersUtil.toggleFilter(newFilterState, 'features', 'waterfall');
      }
      if (obj.filters?.suitability?.includes?.('dogs') && newFilterState.access?.dogs?.selected !== true) {
        SearchFiltersUtil.toggleFilter(newFilterState, 'access', 'dogs');
      }
      if (obj.filters?.activity?.includes?.('hiking') && newFilterState.activities?.hiking?.selected !== true) {
        SearchFiltersUtil.toggleFilter(newFilterState, 'activities', 'hiking');
      }

      // Update app state and search.
      this.setState({ filters: newFilterState, results: null, loading: false }, this.replaceHistoryStateAndSearch);
      return;
    }

    // If the user selected a location-based result type from Algolia apply it.
    // It is unclear why we stringify then parse. Maybe to make a deep clone? Or to strip function(s) we added somewhere?
    SearchFiltersUtil.setFilter(newFilterState, 'locationObject', JSON.parse(JSON.stringify(obj)));
    SearchFiltersUtil.setFilter(newFilterState, 'initLocationObject', JSON.parse(JSON.stringify(obj)));

    // handle differently if requires bounding box of trails in location
    if (this.requiresBoundsCalc(obj)) {
      this.getBoundingBoxOfTrails(
        obj,
        // success
        data => {
          if (data) {
            // update using bounding box of all trails in location
            newFilterState.boundingBox = data;
            newFilterState.zoomLevel = null;
            const locationType = obj.objectID.split('-')[0];
            this.refs.searchMap.setMapBounds(data, { locationType });
            const titleString = this.state.page === PageStrings.EXPLORE_ALL_PAGE ? `Map of ${obj.name} Trails` : this.state.titleString;
            this.setState({ filters: newFilterState, titleString, results: null, loading: false }, this.replaceHistoryStateAndSearch);
          } else {
            this.updateCenterAndZoomWithObjGeoLoc(newFilterState, obj);
          }
        },
        // error
        () => {
          this.updateCenterAndZoomWithObjGeoLoc(newFilterState, obj);
        }
      );
    } else {
      this.updateCenterAndZoomWithObjGeoLoc(newFilterState, obj);
    }
  },
  updateCenterAndZoomWithObjGeoLoc(newFilterState, obj) {
    // Zoom
    const locationType = obj.objectID.split('-')[0];
    const zoom = this.refs.searchMap.getMaxZoom(locationType);

    const handleUpdateFilterAndState = optionalStateObject => {
      const newBounds = this.refs.searchMap.getMapBounds();
      newFilterState = newBounds ? SearchFiltersUtil.setBoundsFilter(newFilterState, newBounds[0], newBounds[2], newBounds[1], newBounds[3]) : null;

      if (newFilterState) {
        this.setState({ filters: newFilterState, ...(optionalStateObject && { optionalStateObject }) }, this.replaceHistoryStateAndSearch);
      }
    };

    // If we have a geolocation for the object use it
    // Otherwise use the calculated name to geocode the location
    if (obj._geoloc != null) {
      this.refs.searchMap.setMapCenterAndZoom(obj._geoloc.lat, obj._geoloc.lng, zoom, { suppressBoundsCallback: true });
      const titleString = this.state.page === PageStrings.EXPLORE_ALL_PAGE ? `Map of ${obj.name} Trails` : this.state.titleString;
      handleUpdateFilterAndState(titleString);
    } else {
      const objName = obj.objectID.indexOf('city') >= 0 ? obj.name[1] : obj.name;
      // eslint-disable-next-line no-undef
      $.ajax({
        url: `https://api.mapbox.com/v4/geocode/mapbox.places/${objName}.json?access_token=pk.eyJ1IjoiYWxsdHJhaWxzIiwiYSI6ImNqM293emo1YjAwZWQyd3FnaXh0eWsxeHkifQ.LeDD0X-JiWsJmDKeB0AS5w`, // gitleaks:allow
        success: data => {
          this.refs.searchMap.setMapCenterAndZoom(data.features[0].center[1], data.features[0].center[0], zoom, { suppressBoundsCallback: true });
          handleUpdateFilterAndState();
        }
      });
    }
  }
};

export default SearchAppAlgoliaMixin;
