import ShimmerWrapper from '@alltrails/shared/components/ShimmerWrapper';
import * as styles from './styles/styles.module.scss';

const TrailConditionsShimmer = (): JSX.Element => (
  <ShimmerWrapper className={styles.shimmerContainer}>
    <div className={styles.shimmerCondition} />
    <div className={styles.shimmerCondition} />
    <div className={styles.shimmerConditionHidden} />
  </ShimmerWrapper>
);

export default TrailConditionsShimmer;
