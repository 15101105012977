import { useEffect, useRef } from 'react';
import classNames from 'classnames';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import Trail from 'types/Trails/Trail';
import { buildSignupUrl } from 'utils/roadblocks';
import useUser from 'hooks/useUser';
import { useIntl, defineMessages } from '@alltrails/shared/react-intl';
import useIsVisible from '@alltrails/shared/hooks/useIsVisible';
import IconButton from '@alltrails/shared/denali/components/IconButton';
import Expand from '@alltrails/shared/icons/Expand';
import useToggle from '@alltrails/shared/hooks/useToggle';
import SignupModal from 'components/SignupModal';
import logTrailDetailsMapViewed from '@alltrails/analytics/events/logTrailDetailsMapViewed';
import logTrailDetailsStaticMapClicked from '@alltrails/analytics/events/logTrailDetailsStaticMapClicked';
import CarouselDisplayTrigger from '@alltrails/analytics/enums/CarouselDisplayTrigger';
import handleRoadblock from 'utils/handleRoadblock';
import useDeeplinkComponent from 'components/DeepLink/hooks';
import InstallInterstitial from 'components/DeepLink/InstallInterstitial';
import InstallPromptLocation from '@alltrails/analytics/enums/InstallPromptLocation';
import * as styles from './ViewFullMap.module.scss';

type Props = {
  className?: string;
  trail: Trail;
  staticMapUrl: string;
  webpStaticMapUrl: string;
  href: string;
  lat: number;
  lng: number;
};

const messages = defineMessages({ altExpand: { defaultMessage: 'Expand map' }, altStatic: { defaultMessage: '{name} map' } });

// https://www.figma.com/file/gZaqAej8HzoFkyrjlQ4cNs/Integrating-Brand-Changes?node-id=4771%3A93251&t=kOkfouLHLVYNkuUw-4
const ViewFullMap = ({ className, trail, href, staticMapUrl, webpStaticMapUrl, lat, lng }: Props) => {
  const ref = useRef<HTMLDivElement>();
  const intl = useIntl();
  const user = useUser();
  const languageRegionCode = useLanguageRegionCode();
  const isVisible = useIsVisible(ref, { threshold: 1 });
  const [isOpen, toggle] = useToggle();
  const [isInterstitialOpen, setIsInterstitialOpen] = useDeeplinkComponent('interstitial', false);

  useEffect(() => {
    if (isVisible) {
      logTrailDetailsMapViewed({ trail_id: trail.trail_id });
    }
  }, [isVisible, trail.trail_id]);

  const newHref = !user ? buildSignupUrl(href, languageRegionCode) : href;

  function handleClick(e: React.MouseEvent<HTMLAnchorElement>) {
    e.preventDefault();
    // per Ben Brott this page is going away and the new props don't apply nicely to this usage
    logTrailDetailsStaticMapClicked({ trail_id: trail.trail_id } as any);

    if (user) {
      handleRoadblock({ afterRoadblock: href, eventName: 'trail_explore', languageRegionCode, returnToUrl: href, user, setIsInterstitialOpen });
    } else {
      toggle();
    }
  }

  const altStatic = intl.formatMessage(messages.altStatic, {
    name: trail.name
  });

  const altExpand = intl.formatMessage(messages.altExpand);
  return (
    <div className={classNames(styles.container, className)} ref={ref}>
      <SignupModal
        trigger={CarouselDisplayTrigger.ViewStaticMap}
        isOpen={isOpen}
        onRequestClose={toggle}
        onSuccess={toggle}
        redirectUrl={href}
        trailId={trail.trail_id}
      />
      <InstallInterstitial
        isOpen={isInterstitialOpen}
        onRequestClose={() => setIsInterstitialOpen(false)}
        promptLocation={InstallPromptLocation.TrailPage}
      />
      <div itemProp="geo" itemScope itemType="http://schema.org/GeoCoordinates">
        <meta itemProp="latitude" content={String(lat)} className="xlate-none" />
        <meta itemProp="longitude" content={String(lng)} className="xlate-none" />
      </div>
      <a className={styles.link} href={newHref} onClick={handleClick}>
        <picture>
          {webpStaticMapUrl && <source srcSet={webpStaticMapUrl} type="image/webp" />}
          <source srcSet={staticMapUrl} type="image/png" />
          <img src={staticMapUrl} alt={altStatic} className={styles.mapImage} />
        </picture>
        <IconButton
          icon={{ Component: Expand, orientation: 'right' }}
          title={altExpand}
          size="md"
          variant="elevated"
          testId="viewFullMap"
          className={styles.viewFullMap}
        />
      </a>
    </div>
  );
};

export default ViewFullMap;
