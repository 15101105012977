import PropTypes from 'prop-types';
import * as styles from './styles/styles.module.scss';

const MapboxSearchResult = ({ idx, placeName, selectFeature }) => {
  const handleOnClick = e => {
    e.preventDefault();

    selectFeature(idx);
  };

  return (
    <a href="#" title={placeName} className={styles.searchResult} onClick={handleOnClick}>
      {placeName}
    </a>
  );
};

const MapboxSearchResults = ({ features, selectFeature }) => {
  return (
    <div className={styles.container}>
      {features.map((feature, idx) => {
        return <MapboxSearchResult key={idx} placeName={feature.placeName} idx={idx} selectFeature={selectFeature} />;
      })}
    </div>
  );
};

MapboxSearchResult.defaultProps = {
  idx: 0,
  placeName: '',
  selectFeature: () => {}
};

MapboxSearchResult.propTypes = {
  idx: PropTypes.number,
  placeName: PropTypes.string,
  selectFeature: PropTypes.func
};

MapboxSearchResults.defaultProps = {
  features: [],
  selectFeature: () => {}
};

MapboxSearchResults.propTypes = {
  features: PropTypes.arrayOf(
    PropTypes.shape({
      latitude: PropTypes.number,
      longitude: PropTypes.number,
      placeName: PropTypes.string
    })
  ),
  selectFeature: PropTypes.func
};

export default MapboxSearchResults;
