import { pointItemsToGeojson } from '@alltrails/maps/utils/legacyGeoJSONConversions';
import { addOrUpdateGeojsonSource } from '../sources/geojson_helpers';
import { addPreviewLines, resetPreviewLines, updatePreviewLines } from './preview_lines';
import { pinPopupOffset, createPinLayer, addHoverPinImageToMap } from '../layers/pins';
import { addHoverPopupToMap } from '../event_handling/popup_helpers';
import { removeSource } from '../sources/source_helpers';
import { addLayer, removeLayer, fireLayerEvent } from '../layers/layer_helpers';
import { GRAY, GREEN } from '../../constants/PinColor';

const sourceId = 'explore-remote-pin';
const layerId = sourceId;

const removeRemotePinMarker = map => {
  if (map.getSource(sourceId)) {
    resetPreviewLines(map);
    removeLayer(map, layerId);
    removeSource(map, sourceId);
  }
};

const addRemotePinMarker = (map, item, renderPopup) => {
  addPreviewLines(map);
  removeRemotePinMarker(map);

  const itemsGeojson = pointItemsToGeojson([item]);
  const features = itemsGeojson.features;
  const lngLat = features[0].geometry.coordinates;
  addOrUpdateGeojsonSource(map, sourceId, itemsGeojson, { cluster: false });

  // Use the DEBUGGER 2px red square to help figure out alignment and spacing if needed.
  const color = item.type === 'trail' ? GREEN : GRAY;
  addHoverPinImageToMap(map, color);
  const pinLayer = createPinLayer(layerId, sourceId, `${color}-hover`);
  addLayer(map, pinLayer);
  updatePreviewLines(map, item);

  const onMouseLeave = () => removeRemotePinMarker(map);
  addHoverPopupToMap(map, layerId, renderPopup, null, onMouseLeave, { offset: pinPopupOffset });
  // open the popup when a a remote item is opened
  fireLayerEvent(map, layerId, 'mouseenter', { features, lngLat });
  // close popup when clicking on map
  map.once('click', onMouseLeave);
};

export { addRemotePinMarker, removeRemotePinMarker };
