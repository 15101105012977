import mapboxgl from 'mapbox-gl';
import ReactDOM from 'react-dom';
import { addLayerEvent } from '../layers/layer_helpers';
import MarkerPositionTooltip from '../../../../javascript/components/shared/MapPopups/MarkerPositionTooltip';

const mountReactComponent = component => {
  const domContent = document.createElement('div');
  ReactDOM.render(component, domContent);
  return domContent;
};

const unmountReactComponent = domContent => {
  ReactDOM.unmountComponentAtNode(domContent);
  domContent.remove();
};

// Ensure that if the map is zoomed out such that multiple
// copies of the feature are visible, the popup appears
// over the copy being pointed to.
const getAdjustedCoordinates = (lngLat, feature) => {
  const geometry = feature.geometry;
  if (geometry.type !== 'Point') {
    return lngLat;
  }
  const coordinates = geometry.coordinates.slice();
  while (Math.abs(lngLat.lng - coordinates[0]) > 180) {
    coordinates[0] += lngLat.lng > coordinates[0] ? 360 : -360;
  }
  return coordinates;
};

const getTooltipDOMContent = tooltip => {
  const element = tooltip.getElement();
  if (!element) {
    return null;
  }
  const popupContent = element.querySelector('.mapboxgl-popup-content');
  if (!popupContent) {
    return null;
  }
  return popupContent.children[0];
};

const addMarkerTooltipsToMap = (map, layerId) => {
  const tooltip = new mapboxgl.Popup({
    closeButton: false,
    closeOnClick: false,
    maxWidth: 'none',
    focusAfterOpen: false,
    anchor: 'left',
    offset: 15,
  });

  const closeTooltip = () => {
    if (!tooltip.isOpen()) {
      return;
    }
    tooltip.remove();
    const domContent = getTooltipDOMContent(tooltip);
    if (domContent) {
      unmountReactComponent(domContent);
    }
  };

  const onMouseEnter = e => {
    const lngLat = e.lngLat;
    const feature = e.features[0];
    const message = feature.properties.positionMessage;
    
    if (!message) {
      return;
    }

    closeTooltip();

    if (map.shiftKeyDown) {
      return;
    }

    const coordinates = getAdjustedCoordinates(lngLat, feature);
    const tooltipComponent = mountReactComponent(<MarkerPositionTooltip text={message} />);
    tooltipComponent.onmouseleave = () => {
      closeTooltip();
    };
    tooltip.setLngLat(coordinates).setDOMContent(tooltipComponent).addTo(map);
  };

  const onMouseLeave = e => {
    closeTooltip()
  };

  addLayerEvent(map, layerId, true, 'mouseenter', onMouseEnter);
  addLayerEvent(map, layerId, true, 'mouseleave', onMouseLeave);

  return {
    tooltip,
    closeTooltip
  };
};



export { addMarkerTooltipsToMap, mountReactComponent, unmountReactComponent, getTooltipDOMContent };
