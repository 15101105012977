import { useState } from 'react';
import logTrailDetailsNearbyTrailClicked from '@alltrails/analytics/events/logTrailDetailsNearbyTrailClicked';
import { defineMessages } from '@alltrails/shared/react-intl';
import type { Context } from 'types/Context';
import Link from '@alltrails/shared/denali/components/Link';
import getNearbyTrails from 'api/NearbyTrails';
import TrailsList from 'components/shared/TrailsList';
import type { TrailId } from '@alltrails/shared/types/trail';
import type Trail from 'types/Trails/Trail';
import useFormatMessage from '@alltrails/shared/hooks/useFormatMessage';
import ListMethods from 'types/ListMethods';
import * as styles from './styles.module.scss';

const NEARBY_TRAILS_MESSAGES = defineMessages({ NEARBY_TRAILS: { defaultMessage: 'Nearby trails' }, SHOW_MORE: { defaultMessage: 'Show more' } });

export type NearbyTrailsListProps = {
  context: Context;
  explorePath?: string;
  listMethods: ListMethods;
  nearbyTrails: Trail[];
  trailId: TrailId;
};

const NearbyTrailsList = ({ context, explorePath, listMethods, nearbyTrails, trailId }: NearbyTrailsListProps): JSX.Element => {
  const {
    formattedDefaultMessages: { NEARBY_TRAILS, SHOW_MORE }
  } = useFormatMessage(NEARBY_TRAILS_MESSAGES);

  const [trails, setTrails] = useState(nearbyTrails);

  const onTrailClick = (trail: Trail) => {
    logTrailDetailsNearbyTrailClicked({ trail_id: trailId, destination_trail_id: trail.ID });
  };

  const loadMoreNearbyTrails = (pageNum: number, perPage: number, onSuccess: (newTrails: Trail[]) => void) => {
    getNearbyTrails(trailId, { page: pageNum, per_page: perPage }).then(response => {
      const { trails: trailsResponse } = response;
      setTrails(trails.concat(trailsResponse));
      onSuccess(trailsResponse);
    });
  };

  return (
    <div className={styles.nearbyTrailsContainer}>
      <div className={styles.nearbyTrailsHeader}>
        <h3 className={styles.headerText}>{NEARBY_TRAILS}</h3>
        <Link href={explorePath} size="sm" testId="nearby-trails-list-link">
          {SHOW_MORE}
        </Link>
      </div>
      <TrailsList
        adjustMaxCount
        context={context}
        listMethods={listMethods}
        loadMoreTrails={loadMoreNearbyTrails}
        onTrailClick={onTrailClick}
        serverTotalItems={100}
        trails={trails}
      />
    </div>
  );
};

export default NearbyTrailsList;
