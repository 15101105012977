// https://www.figma.com/file/gZaqAej8HzoFkyrjlQ4cNs/Integrating-Brand-Changes?node-id=687%3A41721

import { MouseEvent } from 'react';
import classNames from 'classnames';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import PlusBadge from '@alltrails/shared/denali/components/PlusBadge';
import CustomProvider from 'components/CustomProvider';
import * as styles from './styles/styles.module.scss';
import { toATLocalString } from '../../utils/number_util';

export type ActionBarProps = {
  className?: string;
  count?: number;
  disabled?: boolean;
  href?: string;
  icon: JSX.Element;
  onClick?: (e: MouseEvent<HTMLAnchorElement>) => void;
  showPlus?: boolean;
  target?: '_blank' | '_self' | '_parent';
  title: string;
  testId: string;
};

const ActionBarItem = ({
  title,
  count,
  onClick,
  showPlus = false,
  icon,
  href,
  target = '_self',
  disabled = false,
  className,
  testId
}: ActionBarProps): JSX.Element => {
  const languageRegionCode = useLanguageRegionCode();

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (disabled) {
      e.preventDefault();
      return;
    }

    if (onClick) {
      e.preventDefault();
      onClick(e);
    }
  };

  let localizedCountDisplay = null;

  if (count) {
    localizedCountDisplay = ` (${toATLocalString(count, languageRegionCode)})`;
  }

  return (
    <CustomProvider>
      <li className={classNames(styles.container, className)} title={title}>
        <a
          className={classNames(styles.link, className, disabled ? styles.disabled : '')}
          href={href}
          onClick={handleClick}
          target={target}
          data-testid={testId}
        >
          <div className={styles.iconContainer}>
            {icon}
            {showPlus && <PlusBadge className={styles.plusBadge} size="md" />}
          </div>
          <span className={styles.title}>
            {title}
            {localizedCountDisplay}
          </span>
        </a>
      </li>
    </CustomProvider>
  );
};

export default ActionBarItem;
