import Typography from '@alltrails/shared/denali/components/Typography';
import { FormattedMessage, FormattedNumber, useIntl } from '@alltrails/shared/react-intl';
import * as styles from './styles/styles.module.scss';

type Props = {
  pctLowTrafficCells10?: number;
  hasHeatmap: boolean;
  heatmapFlag: boolean;
  overlappingSegmentsFlag: boolean;
  sharpAnglesFlag: boolean;
};

const TrailRedrawStats = ({ pctLowTrafficCells10, heatmapFlag, hasHeatmap, overlappingSegmentsFlag, sharpAnglesFlag }: Props) => {
  const intl = useIntl();
  const heatmapScore = hasHeatmap ? 1 - (pctLowTrafficCells10 ?? 0) : null;
  const redrawIssues: string[] = [];
  if (heatmapFlag) {
    redrawIssues.push(intl.formatMessage({ defaultMessage: 'Heatmap' }));
  }
  if (overlappingSegmentsFlag) {
    redrawIssues.push(intl.formatMessage({ defaultMessage: 'Overlapping segments' }));
  }
  if (sharpAnglesFlag) {
    redrawIssues.push(intl.formatMessage({ defaultMessage: 'Sharp angles' }));
  }

  return (
    <div className={styles.topMargin}>
      <Typography variant="text100" component="div" mb="12">
        <FormattedMessage
          defaultMessage="Heatmap score: {score}"
          values={{
            score:
              typeof heatmapScore === 'number' ? (
                // eslint-disable-next-line react/style-prop-object
                <FormattedNumber value={heatmapScore} minimumFractionDigits={2} maximumFractionDigits={2} style="percent" />
              ) : (
                <FormattedMessage defaultMessage="N/A" />
              )
          }}
        />
      </Typography>
      <Typography variant="text100" component="div">
        <FormattedMessage
          defaultMessage="Redraw issue: {issues}"
          values={{ issues: redrawIssues.length ? redrawIssues.join(', ') : intl.formatMessage({ defaultMessage: 'none' }) }}
        />
      </Typography>
    </div>
  );
};

export default TrailRedrawStats;
