import classNames from 'classnames';
import ShimmerWrapper from '@alltrails/shared/components/ShimmerWrapper';
import * as styles from './styles.module.scss';

const TrailWeatherOverviewShimmer = (): JSX.Element => (
  <ShimmerWrapper className={styles.shimmerContainer} testId="trail-weather-picker-shimmer">
    <div className={styles.shimmerDayPickerContainer} />
    <div className={styles.shimmerOverviewContainer}>
      <div className={styles.shimmerPanel}>
        <div className={styles.shimmerWeather} />
        <div className={styles.shimmerWeatherDetail} />
      </div>
      <div className={classNames(styles.shimmerPanel, styles.shimmerRight)}>
        <div className={styles.shimmerSunrise} />
        <div className={styles.shimmerSunrise} />
        <div className={styles.shimmerSunrise} />
      </div>
    </div>
  </ShimmerWrapper>
);

export default TrailWeatherOverviewShimmer;
