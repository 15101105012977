import classNames from 'classnames';
import logTrailDetailsOverflowClicked from '@alltrails/analytics/events/logTrailDetailsOverflowClicked';
import Overflow from '@alltrails/shared/icons/Overflow';
import { COLOR_INTERACTIVE_DEFAULT } from '@alltrails/shared/denali/tokens';
import ActionBarItem from 'components/ActionBarItem';
import TrailMoreAlternateDropDownList from '../../../../components/TrailDetails/TrailMoreAlternateDropDownList';
import * as styles from './styles.module.scss';

const createReactClass = require('create-react-class');

const TrailMoreAlternateMenuItem = createReactClass({
  getInitialState() {
    return {
      dropDownOpen: false
    };
  },
  componentDidMount() {
    const isMobileWidth = (window && window.innerWidth <= 767) || this.props.exploreFlag;
    window.addEventListener('resize', this.handleResize);
    this.setState({ isMobileWidth });
  },
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
  handleResize() {
    const isMobileWidth = window && window.innerWidth <= 767;
    this.setState({ isMobileWidth });
  },
  handleMouseClick(e) {
    e.preventDefault();
    if (e.currentTarget.classList.contains('moreAction')) {
      if (!this.state.dropDownOpen) {
        // log when overflow menu is opened
        logTrailDetailsOverflowClicked({ trail_id: this.props.trail.trail_id });
      }
      this.setState({ dropDownOpen: !this.state.dropDownOpen });
    }
  },
  handleMouseEnter() {
    if (this.props.mobileBrowser || document.querySelector('html').classList.contains('modalOpen')) {
      return;
    }

    // log when overflow menu is opened
    logTrailDetailsOverflowClicked({ trail_id: this.props.trail.trail_id });
    this.setState({ dropDownOpen: true });
  },
  handleMouseLeave() {
    if (this.props.mobileBrowser || document.querySelector('html').classList.contains('modalOpen')) {
      return;
    }

    this.setState({ dropDownOpen: false });
  },

  render() {
    return (
      <div onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave} className={classNames('moreContainer', styles.moreContainer)}>
        <ActionBarItem
          title={this.props.intl.formatMessage({ defaultMessage: 'More' })}
          icon={<Overflow color={COLOR_INTERACTIVE_DEFAULT} />}
          onClick={this.handleMouseClick}
          className="moreAction"
          testId="more-action"
        />
        {this.state.dropDownOpen && (
          <TrailMoreAlternateDropDownList
            trail={this.props.trail}
            pending={this.props.pending}
            exploreFlag={this.props.exploreFlag}
            printUrl={this.props.printUrl}
            mobileBrowser={this.props.mobileBrowser}
            isMobileWidth={this.state.isMobileWidth}
          />
        )}
      </div>
    );
  }
});

export { TrailMoreAlternateMenuItem };
