import { ServerCommunicationUtil } from 'utils/server_communication_util';

// API under app/controllers/api/alltrails/default/notifications/

export async function sendSms(languageRegionCode, phoneNumber) {
  return fetch('/api/alltrails/notifications/sms', {
    body: JSON.stringify({
      number: phoneNumber,
      resource_type: 'app',
      lang: languageRegionCode
    }),
    headers: {
      'Content-Type': 'application/json',
      ...ServerCommunicationUtil.defaultAuthHeaders()
    },
    method: 'POST'
  }).then(response => {
    // from https://www.tjvantoll.com/2015/09/13/fetch-and-errors/
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response;
  });
}
