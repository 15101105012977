import classNames from 'classnames';
import { MapExpandContext } from 'components/MapExpandControl';
import { useCallback, useContext, useState } from 'react';
import * as styles from './styles/styles.module.scss';

type Props = {
  children: React.ReactNode;
  className?: string;
  isFlyoverViewEnabled?: boolean;
  isMobileMap?: boolean;
  isMobileWidth?: boolean;
  mapTop?: number;
  paddingBottom?: number | string;
  overflowY?: 'auto';
  width?: number;
};

const FullscreenSearchResults = ({
  children,
  className,
  isFlyoverViewEnabled,
  isMobileMap,
  isMobileWidth,
  mapTop,
  paddingBottom,
  overflowY,
  width
}: Props) => {
  const { isMapExpanded } = useContext(MapExpandContext);
  const [left, setLeft] = useState(0);

  const ref = useCallback(
    (node: HTMLDivElement) => {
      if (isMapExpanded && node !== null) {
        setLeft(-node.offsetWidth);
      } else {
        setLeft(0);
      }
    },
    [isMapExpanded]
  );

  const derivedOpacity = isFlyoverViewEnabled ? 0 : 1;

  return (
    <div
      id="fullscreen-search-results"
      data-testid="fullscreenSearchResults"
      className={classNames(
        className,
        styles.fullscreenSearchResults,
        {
          [styles.isShowingMobileMap]: isMobileWidth && isMobileMap
        },
        styles.collaborativeLists
      )}
      ref={ref}
      style={{ width, left, top: mapTop, paddingBottom, opacity: derivedOpacity, overflowY }}
    >
      {children}
    </div>
  );
};

export default FullscreenSearchResults;
