import { useState, useCallback, useMemo, ReactNode } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@alltrails/shared/denali/components/IconButton';
import Overflow from '@alltrails/shared/icons/Overflow';
import useFormatMessage from '@alltrails/shared/hooks/useFormatMessage';
import { defineMessages } from '@alltrails/shared/react-intl';

import * as styles from './styles/styles.module.scss';

type MenuItemType = { callback: () => void; label: ReactNode };

type MenuPopProps = {
  items: (MenuItemType | JSX.Element)[];
  variant?: 'default' | 'circle';
  menuAnchorElement?: HTMLButtonElement | null;
  setMenuAnchorElement?: (target: HTMLButtonElement | null) => void;
};

const MENU_POP_STRINGS = defineMessages({
  MORE_OPTIONS: {
    defaultMessage: 'More options'
  }
});

// TODO: Replace with Popover from denali
// https://alltrails.atlassian.net/browse/CMTY-5754
const MenuPop = ({ items, variant = 'default', menuAnchorElement, setMenuAnchorElement }: MenuPopProps) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const {
    formattedDefaultMessages: { MORE_OPTIONS }
  } = useFormatMessage(MENU_POP_STRINGS);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (setMenuAnchorElement) {
      setMenuAnchorElement(event.currentTarget);
    }
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    if (setMenuAnchorElement) {
      setMenuAnchorElement(null);
    }
    setAnchorEl(null);
  };

  const isMenuItemType = (item: MenuItemType | JSX.Element): item is MenuItemType => (item as MenuItemType)?.callback !== undefined;

  const handleMenuItemClick = useCallback(
    (idx: number) => () => {
      handleClose();
      const item = items[idx];
      if (isMenuItemType(item)) {
        item?.callback();
      }
    },
    []
  );

  const variants = useMemo(
    () => ({
      default: {
        button: <IconButton icon={{ Component: Overflow }} onClick={handleClick} title={MORE_OPTIONS} testId={MORE_OPTIONS} variant="flat" />,
        transformOrigin: {
          vertical: -36,
          horizontal: 156
        }
      },
      circle: {
        button: (
          <IconButton icon={{ Component: Overflow }} onClick={handleClick} title={MORE_OPTIONS} testId="menu-pop-more-options" variant="elevated" />
        ),
        transformOrigin: {
          vertical: -64,
          horizontal: 132
        }
      }
    }),
    []
  );

  const { button, transformOrigin } = variants[variant];

  return (
    <div>
      {button}
      <Menu
        anchorEl={menuAnchorElement || anchorEl}
        keepMounted
        open={setMenuAnchorElement ? Boolean(menuAnchorElement) : Boolean(anchorEl)}
        onClose={handleClose}
        transformOrigin={transformOrigin}
      >
        {items.map((item, idx) => (
          <MenuItem key={idx} onClick={handleMenuItemClick(idx)} classes={{ root: styles.menuItemRoot }}>
            {!isMenuItemType(item) ? item : item.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default MenuPop;
