import { useContext } from 'react';
import classNames from 'classnames';
import { MapExpandContext } from './MapExpandProvider';
import * as styles from './styles/styles.module.scss';

const MapExpandControl = () => {
  const { isMapExpanded, toggleMapExpanded } = useContext(MapExpandContext);

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      data-testid="mapExpandControl"
      className={classNames(styles.control, { [styles.isMapExpanded]: isMapExpanded })}
      onClick={() => toggleMapExpanded()}
    />
  );
};

export default MapExpandControl;
