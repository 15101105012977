import { serverAjaxPromise } from './request_helpers';

const retrievePdfUrlFromGeppetto = data => {
  let url = 'https://geppetto-api.alpha.mostpaths.com/pdf';

  if (typeof window !== 'undefined') {
    if (window.location.host === 'localhost:3000') {
      url = 'http://127.0.0.1:3001/pdf';
    } else if (window.location.host === 'www.alltrails.com') {
      url = 'https://geppetto-api.production.alltrails.com/pdf';
    }
  }

  return serverAjaxPromise({
    type: 'POST',
    url,
    data,
    resReducer: resp => resp
  });
};

const getLocationFromLongLat = (long, lat, mapboxAccessToken) =>
  serverAjaxPromise({
    resReducer: data => data,
    url: `https://api.mapbox.com/geocoding/v5/mapbox.places/${long},${lat}.json?types=place,region&access_token=${mapboxAccessToken}`
  });

export { retrievePdfUrlFromGeppetto, getLocationFromLongLat };
