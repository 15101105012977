import React, { ComponentProps, useCallback, useMemo } from 'react';
import { TrailAttribute } from '@alltrails/shared/types/trail';
import SelectionChips from '@alltrails/shared/denali/components/SelectionChips';
import classnames from 'classnames';
import TagSection, { ID_NONE } from './TagSection';
import * as styles from './AttributeTagSection.module.scss';

type Uid = string;
type Tag = TrailAttribute & { uid: Uid };
type Chip = ComponentProps<typeof SelectionChips>['chips'][0];

type TrailTag = {
  id: number;
  uid: string;
  mlReasoning: string;
  statusUpdateCode: string;
  overrideEnabled: boolean;
};

type Props<Key extends Uid, Value extends Tag> = {
  uniqueId: string;
  title: string;
  tags: Record<Key, Value>;
  selectedTags: Record<Key, Value>;
  setSelectedTags: (selectedTags: Record<Key, Value>) => void;
  isMultiSelect?: boolean;
  includeNone?: boolean;
  trailTags?: TrailTag[];
};

const AttributesTagSection = <Key extends Uid, Value extends Tag>({
  uniqueId,
  title,
  tags,
  selectedTags,
  setSelectedTags,
  isMultiSelect,
  includeNone,
  trailTags
}: Props<Key, Value>) => {
  const getOverrideClassName = useCallback((trailTag: TrailTag | undefined) => {
    if (!trailTag) return null;

    const { statusUpdateCode, overrideEnabled } = trailTag;
    if (overrideEnabled || statusUpdateCode === 'ml_added' || statusUpdateCode === 'ml_confirmed') {
      return styles.overrideEnabledTag;
    }
    return null;
  }, []);

  const getClassName = useCallback(
    (trailTag: TrailTag | undefined) =>
      trailTag &&
      classnames({
        [styles.automationTag]: trailTag.statusUpdateCode === 'ml_added',
        [styles.automationApprovedTag]: trailTag.statusUpdateCode === 'ml_confirmed'
      }),
    []
  );

  const chips = useMemo(
    () =>
      Object.values<Value>(tags).map(tag => {
        const isSelected = !!selectedTags[tag.uid as Key];
        const trailTag = trailTags?.find(t => t.uid === tag.uid);
        const className = isSelected ? getClassName(trailTag) : getOverrideClassName(trailTag);
        const toolTipProps = trailTag?.mlReasoning
          ? {
              testId: `test-${trailTag.uid}-${trailTag.id}`,
              text: trailTag.mlReasoning
            }
          : undefined;
        return { id: tag.uid, label: tag.name, className, toolTipProps };
      }),
    [tags, selectedTags, getClassName, getOverrideClassName, trailTags]
  );

  // The selected tags can be a superset of available tags for this section since they're sourced from trail attributes
  // so filter out any unavailable tags before rendering
  const selectedChips = useMemo(
    () =>
      Object.values<Value>(selectedTags)
        .filter(tag => !!tags[tag.uid as Key])
        .map(tag => ({ id: tag.uid, label: tag.name })),
    [tags, selectedTags]
  );

  const onChange = useCallback(
    (chip: Chip) => {
      const id = chip.id as Key;

      if (!isMultiSelect) {
        const newSelectedTags = { ...selectedTags };
        // Only clear available tags for this section since selectedTags are sourced from trail attributes and can be a superset of available tags
        Object.values<Value>(tags).forEach(tag => {
          if (id !== ID_NONE && tag.uid === id) {
            newSelectedTags[id] = tag;
          } else {
            delete newSelectedTags[tag.uid as Key];
          }
        });
        setSelectedTags(newSelectedTags);
        return;
      }

      if (selectedTags[id]) {
        const newSelectedTags = { ...selectedTags };
        delete newSelectedTags[id];
        setSelectedTags(newSelectedTags);
      } else {
        setSelectedTags({ ...selectedTags, [id]: tags[id] });
      }
    },
    [isMultiSelect, tags, selectedTags, setSelectedTags]
  );

  return (
    <TagSection
      uniqueId={uniqueId}
      title={title}
      chips={chips}
      selectedChips={selectedChips}
      onChange={onChange}
      isMultiSelect={isMultiSelect}
      includeNone={includeNone}
    />
  );
};

export default AttributesTagSection;
export type { Uid, Tag };
