import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { PageStrings } from '@alltrails/shared/utils/constants/pageStringHelpers';
import useMap from '@alltrails/maps/hooks/useMap';
import { GeoLocationContext } from 'components/explore/GeoLocationProvider';
import useIsochroneSearch from 'hooks/useIsochroneSearch';
import ListCollaborator from '@alltrails/shared/types/ListCollaborator';
// eslint-disable-next-line import/no-unresolved
import { FullscreenSearchApp } from 'react_components/search/fullscreen/search_app';
import useFeatures from 'hooks/useFeatures';
import useExperiments from 'hooks/useExperiments';
import useUser from 'hooks/useUser';
import ListMethods from 'types/ListMethods';
import { MapExpandContext } from 'components/MapExpandControl/MapExpandProvider';
import { GarminContext } from 'components/GarminSettings/GarminProvider';
import { useIntl } from '@alltrails/shared/react-intl';
import hasPermission from 'utils/hasPermission';

type Props = {
  belongsToCurrentUser: boolean;
  isMobileWidth: boolean;
  latestListAction: any;
  listId: any;
  listItems: any;
  listMethods: ListMethods;
  lists: any;
  page: string;
  updatePage: any;
  userListItems: any;
  collaborators?: ListCollaborator[];
  setCollaborators?: any;
  areCollaboratorsLoading?: boolean;
  openCollaboratorsModal?: boolean;
  setIsCollaboratorsModalOpen?: Dispatch<SetStateAction<boolean>>;
  listItemsData?: any;
  listDetailsData?: any;
  areListItemsLoading?: boolean;
  areListDetailsLoading?: boolean;
};

/**
 * NewFullscreenSearchApp wraps the legacy React class component FullscreenSearchApp
 * with a new functional component that can access hooks.
 *
 * Over time functionality from FullscreenSearchApp should be refactored. For
 * now this can (hopefully) be a shim to help provide modern functionality.
 *
 * @param {props} Props
 * @returns JSX.Element
 */
export default function NewFullscreenSearchApp({
  belongsToCurrentUser,
  isMobileWidth,
  latestListAction,
  listId,
  listItems,
  listMethods,
  lists,
  page,
  updatePage,
  userListItems,
  collaborators,
  setCollaborators,
  areCollaboratorsLoading,
  openCollaboratorsModal,
  setIsCollaboratorsModalOpen,
  listDetailsData,
  listItemsData,
  areListItemsLoading,
  areListDetailsLoading,
  ...rest
}: Props) {
  const user = useUser(); // rely on useUser hook over context.currentUser now that we support client-side-only login
  const [distanceAwayValue, setDistanceAwayValue] = useState<number>();
  const [isMapReady, setIsMapReady] = useState(false);
  const { geoLocation, getGeoLocation } = useContext(GeoLocationContext);
  const [toastProps, setToastProps] = useState(null);
  const intl = useIntl();
  const {
    isLoading: isLoadingIsochrone,
    isochroneData,
    insidePolygon,
    isochroneBoundingBox
  } = useIsochroneSearch({ distanceAwayValue, geoLocation });
  const { setCurrentMapId } = useContext(GarminContext);
  const [reportingSuccessToast, setReportingSuccessToast] = useState(null);
  const experiments = useExperiments();

  useEffect(() => {
    if (distanceAwayValue && distanceAwayValue !== -1) {
      // TODO DISCO-874 re-use functionality in SliderGroup to present a modal to
      // users about geo location if needed.
      getGeoLocation();
    }
  }, [distanceAwayValue, getGeoLocation]);

  const { isMapExpanded } = useContext(MapExpandContext);

  const features = useFeatures();
  const isActivitiesNewMapsPage = features.includes('activities_new_maps') && [PageStrings.EXPLORE_USERS_TRACKS_PAGE].includes(page);
  const isExploreNewMapsPage = [PageStrings.EXPLORE_ALL_PAGE, PageStrings.EXPLORE_FAVORITE_PAGE, PageStrings.EXPLORE_CUSTOM_PAGE].includes(page);

  // The @alltrails/maps package does not currently support admin features.
  // Admins will not be allowed to use those components.
  const isNewMapsPage = (isExploreNewMapsPage || isActivitiesNewMapsPage) && !hasPermission({ permission: 'trails:manage' });
  const shouldHideMapLayers = features.includes?.('new_hidden_map_layers');

  const newMapInstance = useMap();
  const [currentlyHoveredResult, setCurrentlyHoveredResult] = useState(null);
  const [shouldClearClickedResult, setShouldClearClickedResult] = useState(false);

  return (
    <FullscreenSearchApp
      {...rest}
      geoLocation={geoLocation}
      insidePolygon={insidePolygon}
      isLoadingIsochrone={isLoadingIsochrone}
      isMapExpanded={isMapExpanded}
      isMapReady={isMapReady}
      isMobileWidth={isMobileWidth}
      isochroneBoundingBox={isochroneBoundingBox}
      isochroneData={isochroneData}
      latestListAction={latestListAction}
      lists={lists}
      listItems={listItems}
      // Shim to help provide search behavior until we can break out legacy
      // algolia mixins.
      newMapInstance={newMapInstance}
      onMapReady={(newValue: boolean) => {
        setIsMapReady(newValue ?? true);
      }}
      page={page}
      areActivitiesNewMapsEnabled={features.includes('activities_new_maps')}
      isNewMapsPage={isNewMapsPage}
      shouldHideMapLayers={shouldHideMapLayers}
      userListItems={userListItems}
      listMethods={listMethods}
      listId={listId}
      belongsToCurrentUser={belongsToCurrentUser}
      updatePage={updatePage}
      onUpdateDistanceAway={(value: number) => {
        setDistanceAwayValue(value);
      }}
      user={user} // pass user object as a separate field to not conflict with older functionality
      intl={intl}
      currentlyHoveredResult={currentlyHoveredResult}
      updateCurrentlyHoveredResult={setCurrentlyHoveredResult}
      shouldClearClickedResult={shouldClearClickedResult}
      setShouldClearClickedResult={setShouldClearClickedResult}
      toastProps={toastProps}
      setToastProps={setToastProps}
      setMapId={setCurrentMapId}
      collaborators={collaborators}
      setCollaborators={setCollaborators}
      areCollaboratorsLoading={areCollaboratorsLoading}
      reportingSuccessToast={reportingSuccessToast}
      setReportingSuccessToast={setReportingSuccessToast}
      openCollaboratorsModal={openCollaboratorsModal}
      setIsCollaboratorsModalOpen={setIsCollaboratorsModalOpen}
      listItemsData={listItemsData}
      listDetailsData={listDetailsData}
      areListItemsLoading={areListItemsLoading}
      areListDetailsLoading={areListDetailsLoading}
      experiments={experiments}
    />
  );
}
