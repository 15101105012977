import { ReactNode } from 'react';
import classNames from 'classnames';
import IconButton, { IconButtonProps } from '@alltrails/shared/denali/components/IconButton';
import InputGroup, { InputGroupOption, InputGroupProps } from '@alltrails/shared/denali/components/InputGroup';
import Radio, { RadioProps } from '@alltrails/shared/denali/components/Radio';
import * as styles from './styles.module.scss';

type RadioWithIconOption = InputGroupOption & {
  iconButtonProps?: Pick<IconButtonProps, 'icon' | 'testId' | 'title'> & {
    alignment: 'trailing' | 'right';
    onClick: (e: React.MouseEvent<HTMLElement>, id: string) => void;
  };
  selected: RadioProps['selected'];
  testId: string;
  value: string;
};

export type RadioGroupWithIconProps = {
  className?: string;
  disabled?: boolean;
  errorText?: string;
  labelText?: string;
  onChange: (value: RadioWithIconOption['value']) => void;
  options: RadioWithIconOption[];
  size?: RadioProps['size'];
  testId: string;
  verticalAlignment?: RadioProps['verticalAlignment'];
};

/**
 * @deprecated Use Denali RadioGroup instead. Radio groups typically should not have inline trailing icons.
 */
const RadioGroupWithIcon = ({
  className,
  disabled,
  errorText,
  labelText,
  onChange,
  options,
  size = 'md',
  testId,
  verticalAlignment
}: RadioGroupWithIconProps): JSX.Element => {
  const renderInput: InputGroupProps<RadioWithIconOption>['renderInput'] = (
    { iconButtonProps, value, selected, testId: optionTestId },
    labelElement
  ) => (
    <div className={styles.radioContainer} key={value}>
      <Radio
        disabled={disabled}
        value={value}
        labelElement={labelElement}
        onChange={() => onChange(value)}
        selected={selected}
        size={size}
        testId={optionTestId}
        verticalAlignment={verticalAlignment}
      />
      {iconButtonProps && (
        <div className={classNames({ [styles.alignRight]: iconButtonProps.alignment === 'right' })}>
          <IconButton
            {...iconButtonProps}
            icon={{ ...iconButtonProps.icon }}
            onClick={(e: React.MouseEvent<HTMLElement>) => iconButtonProps?.onClick(e, value)}
            size={size}
            variant="flat"
          />
        </div>
      )}
    </div>
  );

  return (
    <InputGroup
      className={className}
      disabled={disabled}
      errorText={errorText}
      labelText={labelText}
      options={options}
      renderInput={renderInput}
      size={size}
      testId={testId}
      type="radio"
    />
  );
};

export default RadioGroupWithIcon;
