import BaseAccordion from '@material-ui/core/Accordion';
import { string, bool, func } from 'prop-types';
import Summary from '@material-ui/core/AccordionSummary';
import Details from '@material-ui/core/AccordionDetails';
import ChevronSm from '@alltrails/shared/icons/ChevronSm';
import * as styles from './styles/styles.module.scss';

const Accordion = ({ isOpen, title, onClick, children, className }) => {
  return (
    <BaseAccordion square expanded={isOpen} classes={{ root: `${styles.accordion} ${className}`, expanded: styles.accordionExpanded }}>
      <Summary
        onClick={onClick}
        classes={{ root: styles.summary, expanded: styles.summaryExpanded, content: styles.summaryTitle }}
        expandIcon={<ChevronSm />}
      >
        {title}
      </Summary>
      <Details classes={{ root: styles.details }}>{children}</Details>
    </BaseAccordion>
  );
};

export default Accordion;

Accordion.propTypes = {
  title: string.isRequired,
  isOpen: bool,
  onClick: func.isRequired,
  className: string
};

Accordion.defaultProps = {
  isOpen: false,
  className: ''
};
