const addSource = (map, sourceId, sourceSpec) => {
  if (!map.getSource(sourceId)) {
    map.addSource(sourceId, sourceSpec);
  }
  const customSources = map.customStyle.sources;
  const idx = customSources.findIndex(s => s.sourceId === sourceId);
  if (idx < 0) {
    customSources.push({
      sourceId,
      sourceSpec
    });
  }
};

const removeSource = (map, sourceId) => {
  if (map.getSource(sourceId)) {
    map.removeSource(sourceId);
  }
  const customSources = map.customStyle.sources;
  const idx = customSources.findIndex(s => s.sourceId === sourceId);
  if (idx >= 0) {
    customSources.splice(idx, 1);
  }
};

export { addSource, removeSource };
