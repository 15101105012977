import { get } from '@alltrails/shared/api';
import { IsochroneAPIResponse } from 'types/Map';

export default async function getIsochrone({ lat, lng, meters }: { lat: number; lng: number; meters: number }) {
  const response = await get<IsochroneAPIResponse>('/api/alltrails/isochrone', {
    params: {
      lng,
      lat,
      // Only integers are allowed, and that's enough precision for us. We only
      // have floats because of imperial vs metric conversions.
      meters: Math.round(meters)
    }
  });

  if (!response || !response.message) {
    throw Error('isochrone data from API is invalid');
  }

  return response;
}
