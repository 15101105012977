import { LanguageSupportUtil } from './language_support_util';
import { ServerCommunicationUtil } from './server_communication_util';
import { FILTER_ACTIVITIES_STRINGS } from './filters/filters.strings';

const getTrackActivity = (track, intl) => {
  let activityName;
  if (track.activity) {
    activityName = track.activity.name; // map presenter
  } else if (track.activities && track.activities.length > 0) {
    activityName = track.activities[0]; // algolia format
  }
  if (!activityName) {
    return null;
  }
  // replaces spaces and dashes with underscores
  const activityKey = activityName.toUpperCase().replace(/(\s)|[-]/gi, '_');
  return FILTER_ACTIVITIES_STRINGS[activityKey] ? intl.formatMessage(FILTER_ACTIVITIES_STRINGS[activityKey]) : activityName;
};

const getTrackProfilePhotoUrl = (track, size = 'large', showStaticMap = true, staticMapSize = '396x180') => {
  if (track.profile_photo_url !== undefined) {
    return track.profile_photo_url;
  }
  if (track.ID === null || track.ID === 0) {
    return '';
  }
  const staticMapOpts = showStaticMap ? `&show_static_map=yes&static_map_size=${staticMapSize}` : '';
  return `/api/alltrails/v3/maps/${track.ID}/profile_photo?size=${size}&key=${ServerCommunicationUtil.apiKey}${staticMapOpts}`;
};

const getFormattedTrackUrls = (track, context) => {
  let trackUrl;
  if (track.slug) {
    trackUrl = `/explore/${track.type === 'map' ? 'map' : 'recording'}/${track.slug}`;
  } else {
    // If we dont have a slug for some reason go to explore
    trackUrl = `/explore`;
  }
  const languageRegionCode = context ? context.languageRegionCode : null;
  return {
    url: trackUrl,
    localizedUrl: LanguageSupportUtil.wrapUrlSafe(trackUrl, languageRegionCode)
  };
};

export { getTrackActivity, getTrackProfilePhotoUrl, getFormattedTrackUrls };
