import { defineMessages, IntlShape } from '@alltrails/shared/react-intl';

const { SEE_TRAILS } = defineMessages({
  SEE_TRAILS: {
    defaultMessage: 'See {resultsCount, plural, one {{resultsCount} trail} other {{resultsCount} trails}}'
  }
});

type Args = {
  intl: IntlShape;
  isLoading: boolean;
  resultsCount: number;
  disabled?: boolean;
};

// TODO: DISCO-547 - We should use a common pattern to handle plurals (comment copied from SaveButton.tsx)
export default function getSaveButtonChildrenProps({ intl, isLoading, resultsCount, disabled }: Args) {
  return {
    text: intl.formatMessage(SEE_TRAILS, { resultsCount }),
    testId: intl.formatMessage(SEE_TRAILS, { resultsCount }),
    loading: isLoading,
    disabled
  };
}
