import logError from 'utils/logError';
import { loadAndAddImage } from '../image_helpers';
import { addLayer, removeLayer } from '../layers/layer_helpers';

const chevronImageId = 'chevron';

const addChevrons = (map, layerId, sourceId) => {
  if (map.getLayer(layerId)) {
    return;
  }

  // Add chevron images to map
  loadAndAddImage(map, chevronImageId, `https://cdn-assets.alltrails.com/assets/icons/maps/chevron@2x.png`, {
    pixelRatio: 2
  }).catch(logError);

  // Add layer to map
  addLayer(map, {
    id: layerId,
    type: 'symbol',
    source: sourceId,
    layout: {
      'icon-allow-overlap': true,
      'icon-ignore-placement': true,
      'icon-image': ['image', chevronImageId],
      'icon-rotate': 90,
      'symbol-placement': 'line',
      'symbol-spacing': 50
    },
    paint: {}
  });
};

const removeChevrons = (map, id) => {
  removeLayer(map, id);
};

export { addChevrons, removeChevrons };
