import { GeoLocationContext } from 'components/explore/GeoLocationProvider';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { FilterViewModel, FilterConfig, FilterConfigSlider } from 'types/Search';
import useIsochroneSearch from './useIsochroneSearch';

export type SearchResultsCountArgs = {
  changes: { [key: string]: FilterConfig };
  insidePolygon?: number[][];
  isochroneBoundingBox?: [number, number, number, number];
};

export type SearchResultsCountFunction = ({ changes, insidePolygon, isochroneBoundingBox }: SearchResultsCountArgs) => Promise<number>;

export type Args = {
  filtersByKey: Record<string, FilterViewModel>;
  hasAnyDraftChanges: boolean;
  // This is a function that can take the current state of draft/in-progress filters and return a new result count based on those filters.
  searchResultsCountFunction: SearchResultsCountFunction;
  resultsCount: number;
};

const useHypotheticalSearchResultsCount = ({ filtersByKey, hasAnyDraftChanges, searchResultsCountFunction, resultsCount }: Args) => {
  const isMounted = useRef(false);
  const [projectedResultsCount, setProjectedResultsCount] = useState(null);
  const { geoLocation } = useContext(GeoLocationContext);
  const {
    isLoading: isLoadingIsochrone,
    insidePolygon,
    isochroneBoundingBox
  } = useIsochroneSearch({
    distanceAwayValue: (filtersByKey?.distance_away?.config as FilterConfigSlider)?.value,
    geoLocation
  });
  const [isLoading, setIsLoading] = useState(false);

  const computedResultsCount: number = useMemo(() => {
    // null implies that we haven't tried to determine any hypothetical results yet.
    if (projectedResultsCount === null) {
      return resultsCount;
    }

    if (projectedResultsCount !== resultsCount) {
      return projectedResultsCount;
    }

    return resultsCount;
  }, [projectedResultsCount, resultsCount]);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (!hasAnyDraftChanges) {
      setProjectedResultsCount(null);
      setIsLoading(false);
      return;
    }

    if (isLoadingIsochrone) {
      return;
    }

    setIsLoading(true);

    let isSubscribed = true;

    const allDraftConfigsByKey = Object.keys(filtersByKey).reduce(
      (combined, nextKey) => ({
        ...combined,
        [nextKey]: filtersByKey[nextKey].config
      }),
      {}
    );

    searchResultsCountFunction({ changes: allDraftConfigsByKey, insidePolygon, isochroneBoundingBox })
      .then(results => {
        if (!isMounted.current || !isSubscribed) {
          return;
        }

        setProjectedResultsCount(results);
      })
      .finally(() => {
        if (!isMounted.current || !isSubscribed) {
          return;
        }

        setIsLoading(false);
      });

    // eslint-disable-next-line consistent-return
    return () => {
      isSubscribed = false;
    };
  }, [filtersByKey, hasAnyDraftChanges, insidePolygon, isLoadingIsochrone, searchResultsCountFunction]);

  return {
    resetHypotheticalResultsCount: () => {
      setProjectedResultsCount(null);
    },
    computedResultsCount,
    isLoading: isLoading || isLoadingIsochrone
  };
};

export default useHypotheticalSearchResultsCount;
