// extracted by mini-css-extract-plugin
export var shimmerCondition = "styles-module__shimmerCondition___cAuZ9";
export var shimmerContainer = "styles-module__shimmerContainer___U_0eD";
export var shimmerDay = "styles-module__shimmerDay___hKE5k";
export var shimmerDayPickerContainer = "styles-module__shimmerDayPickerContainer___OZLgo";
export var shimmerOverviewContainer = "styles-module__shimmerOverviewContainer___LRm7f";
export var shimmerPanel = "styles-module__shimmerPanel___tay3m";
export var shimmerRight = "styles-module__shimmerRight___hYikk";
export var shimmerSunrise = "styles-module__shimmerSunrise___JmuXE";
export var shimmerWeather = "styles-module__shimmerWeather___vFfFc";
export var shimmerWeatherDetail = "styles-module__shimmerWeatherDetail___o0E4I";