import TrailBuggy from '@alltrails/shared/icons/TrailBuggy';
import TrailIcy from '@alltrails/shared/icons/TrailIcy';
import TrailMuddy from '@alltrails/shared/icons/TrailMuddy';
import TrailSlippery from '@alltrails/shared/icons/TrailSlippery';
import TrailSnowy from '@alltrails/shared/icons/TrailSnowy';
import { TrailConditionUid } from 'types/Trails/TrailCondition';

type TrailConditionsIconProps = {
  trailConditionUid: TrailConditionUid;
};

const TrailConditionsIcon = ({ trailConditionUid }: TrailConditionsIconProps): JSX.Element => {
  switch (trailConditionUid) {
    case TrailConditionUid.Buggy:
      return <TrailBuggy />;
    case TrailConditionUid.Icy:
      return <TrailIcy />;
    case TrailConditionUid.Muddy:
      return <TrailMuddy />;
    case TrailConditionUid.Slippery:
      return <TrailSlippery />;
    case TrailConditionUid.Snowy:
      return <TrailSnowy />;
    default:
      return null; // leave the icon empty
  }
};

export default TrailConditionsIcon;
