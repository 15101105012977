import mapboxgl from 'mapbox-gl';
import logError from 'utils/logError';
import { addLayer, removeLayer } from './layers/layer_helpers';
import { addSource, removeSource } from './sources/source_helpers';
import { removeImage, loadAndAddImage } from './image_helpers';

const alltrailsGlpyhs = 'mapbox://fonts/alltrails/{fontstack}/{range}.pbf';

const initCustomStyle = map => {
  map.customStyle = {
    cursor: '',
    sources: [],
    layers: [],
    layerEvents: {},
    images: []
  };
};

const injectCustomStyle = map => {
  const { sources, layers, images } = map.customStyle;
  sources.forEach(s => {
    addSource(map, s.sourceId, s.sourceSpec);
  });
  images.forEach(i => {
    loadAndAddImage(map, i.imageId, i.imageSrc, i.opts).catch(logError);
  });
  layers.forEach(layer => {
    addLayer(map, layer);
  });
};

const clearCustomStyle = map => {
  const { sources, layers, images } = map.customStyle;
  // Iterate over shallow array copies to prevent issues
  // with splicing the array currently being iterated over
  layers.slice().forEach(layer => {
    removeLayer(map, layer.id, false);
  });
  images.slice().forEach(i => {
    removeImage(map, i.imageId, false);
  });
  sources.slice().forEach(s => {
    removeSource(map, s.sourceId, false);
  });
};

const setTerrain = (map, enabled) => {
  if (enabled) {
    const currentTerrain = map.getSource('mapbox-terrain');

    if (!currentTerrain) {
      map.addSource('mapbox-terrain', {
        type: 'raster-dem',
        url: 'mapbox://mapbox.mapbox-terrain-dem-v1',
        tileSize: 512,
        maxzoom: 14
      });
    }

    map.setTerrain({ source: 'mapbox-terrain', exaggeration: 1.5 });

    const currentSky = map.getLayer('sky');

    if (!currentSky) {
      map.addLayer({
        id: 'sky',
        type: 'sky',
        paint: {
          'sky-type': 'atmosphere',
          'sky-atmosphere-sun': [0.0, 0.0],
          'sky-atmosphere-sun-intensity': 15,
          'sky-atmosphere-color': 'rgba(85, 151, 210, 0.5)'
        }
      });
    }

    if (map.getLayer('contour-line')) {
      map.setFilter('contour-line', ['!', true]);
      map.setFilter('contour-label', ['!', true]);
    }
  } else {
    map.setTerrain(null).setMaxPitch(60);

    if (map.getLayer('contour-line')) {
      map.setFilter('contour-line', null);
      map.setFilter('contour-label', null);
    }
  }
};

/**
 * getStyleFingerprint returns a (potentially non-unique) identifier for a given style
 *
 * @param {object} style
 * @returns
 */
const getStyleFingerprint = style => {
  const sourceNames = Object.keys(style?.sources || {});

  return [`[name]: ${style?.name || 'no name'}`, `[sources]: ${sourceNames.length ? sourceNames.join('-') : 'no sources'}`].join(', ');
};

/**
 * swapBaseStyle replaces the current map styles and performs some other changes.
 * Note that this invalidates the map layers and any calls to map.addSource()
 * that occur while this is running will throw an exception
 * https://alltrails.atlassian.net/browse/DISCO-789
 *
 * Wait for the promise here to guarantee (as best we can) that the style swap finished.
 *
 * @param {mapboxgl.Map} map
 * @param {object} style
 * @param {boolean} terrainEnabled
 * @returns Promise
 */
const swapBaseStyle = (map, style, terrainEnabled, callback = () => {}) => {
  const oldStyleFingerprint = getStyleFingerprint(map?.getStyle());
  const newStyleFingerPrint = getStyleFingerprint(style);

  return new Promise(resolve => {
    // https://alltrails.atlassian.net/browse/DISCO-789
    //
    // The first 'styledata' event after map.setStyle() should indicate that the style is done loading.
    //
    // Generally, there will be problems if you try to add data to a map while map.setStyle() is transitioning.
    // Maybe 1/10 times or so an exception will be thrown because data is added while no loaded style is present.
    // Waiting for map.setStyle() to finish and halting or otherwise stopping any async map.addSource() calls in the mean time is crucial.
    //
    // * Note that calling map.setStyle() invalidates the map layer and any async calls to map.addSource() that happen to run before
    //   map.setStyle() is finished will throw exceptions https://github.com/mapbox/mapbox-gl-js/issues/8660
    // * Note that the 'style.load' event is a private API and should not be used https://github.com/mapbox/mapbox-gl-js/issues/2268 and https://github.com/mapbox/mapbox-gl-js/issues/3970
    // * Note we are not the only people dying for a reliable source of truth on style load completion https://github.com/mapbox/mapbox-gl-js/issues/8691
    // * Note that waiting for the 'styledata' event seems like the current reliable source of truth at time of writing https://github.com/mapbox/mapbox-gl-js/issues/4006
    // * Note that even 'styledata' may not be totally reliable https://github.com/mapbox/mapbox-gl-js/issues/9779
    //
    // Although some users report that map.isStyleLoaded() is unreliable https://github.com/mapbox/mapbox-gl-js/issues/8691 I think it
    // may work in our case. Our case seems different from that of other users because we do see a 'styledata' event fire while they do not.
    // A setInterval() continually checking map.isStyleLoaded() does seem like a workable alternative to
    // checking for the 'styledata' event if the event handler approach proves unreliable.
    map.once('styledata', () => {
      setTerrain(map, terrainEnabled);
      injectCustomStyle(map);

      console.debug(`[map] swapBaseStyle: changed from '${oldStyleFingerprint}' to ${newStyleFingerPrint}`);
      resolve();
    });

    console.debug(`[map] swapBaseStyle: changing from '${oldStyleFingerprint}' to ${newStyleFingerPrint}`);
    map.setStyle(style);
  });
};

// NOTE: Please communicate any chages here to the mobile teams, as the mobile clients use similar regex for localization
const localizeVectorStyle = (style, locale, displayMetric, intl) => {
  // Do nothing if displaying English, imperial layer
  if ((!locale || locale === 'en') && !displayMetric) {
    return style;
  }
  // Use first name found from: user-locale name (eg Moscou for a French user), English name (eg Moscow), name in place's local language (eg Москва)
  let styleStr = JSON.stringify(style)
    .replace(/\["coalesce",\["get","name_en"\],\["get","name"\]\]/g, `["coalesce",["get","name_${locale}"],["get","name_en"],["get","name"]]`)
    .replace(/n:en/g, `n:${locale}`)
    .replace(/PRIVATE/g, intl.formatMessage({ defaultMessage: "Private" }));
  if (displayMetric) {
    styleStr = styleStr
      .replace(/elevation_ft/g, 'elevation_m')
      .replace(/ ft/g, ' m')
      .replace(/\["get","l"]/g, '["/",["round",["*",["get","l"],16.1]],10]')
      .replace(/\["\*",\["get","ele"],3\.281]/g, '["get","ele"]') // used in AllTrails layer
      .replace(/\["\*",\["get","ele"],3\.28084]/g, '["get","ele"]'); // used in AllTrails Mapbox Outdoors layer
  }
  style = JSON.parse(styleStr);
  return style;
};

const addAllTrailsGlyphsToStyle = style => {
  // Without this, custom layers using the 'Manrope Bold' font would break. This
  // 'glyphs' properties is what allows us to use fonts we've defined in our
  // Mapbox-hosted font stack. This is needed for all custom fonts.
  style.glyphs = alltrailsGlpyhs;
  return style;
};

const loadVectorStyle = (styleId, locale, displayMetric, intl) => {
  if (!locale) {
    locale = 'en';
  }
  return new Promise((resolve, reject) => {
    $.ajax({
      type: 'GET',
      contentType: 'application/json',
      url: `https://api.mapbox.com/styles/v1/${styleId}`,
      data: {
        optimize: locale === 'en' && !displayMetric,
        access_token: mapboxgl.accessToken
      },
      success: style => {
        style = localizeVectorStyle(style, locale, displayMetric, intl);
        style = addAllTrailsGlyphsToStyle(style);
        resolve(style);
      },
      error: e => {
        reject(e);
      }
    });
  });
};

const createBlankStyle = () => {
  return {
    layers: [],
    sources: {},
    glyphs: alltrailsGlpyhs,
    version: 8
  };
};

export { alltrailsGlpyhs, createBlankStyle, initCustomStyle, swapBaseStyle, clearCustomStyle, loadVectorStyle, setTerrain, localizeVectorStyle };
