import { defineMessages } from '@alltrails/shared/react-intl';
import ShowMoreText from '@alltrails/shared/components/ShowMoreText';
import useFormatMessage from '@alltrails/shared/hooks/useFormatMessage';
import TextArea from '@alltrails/shared/denali/components/TextArea';
import Button from '@alltrails/shared/denali/components/Button';

import * as styles from './styles/styles.module.scss';

const DESCRIPTION_STRINGS = defineMessages({
  SAVING: { defaultMessage: 'Saving...' },
  CANNOT_SAVE_UPDATE: { defaultMessage: 'Cannot save your update. Please try again.' },
  SUBMIT: { defaultMessage: 'Submit' },
  CLEAR: { defaultMessage: 'Clear' },
  NOTES: { defaultMessage: 'Notes (optional)' }
});

type Props = {
  data: string;
  placeholderText: string;
  isEditShown: boolean;
  submitInProgress: boolean;
  submitFailed: boolean;
  handleSubmit: () => void;
  dataSource: string;
  descriptionValue: string;
  handleEditDescription: (value: string) => void;
  handleClearDescription: () => void;
};

const TextEditItem = ({
  data,
  placeholderText,
  isEditShown,
  submitInProgress,
  submitFailed,
  handleSubmit,
  dataSource,
  descriptionValue,
  handleEditDescription,
  handleClearDescription
}: Props): JSX.Element => {
  const {
    formattedDefaultMessages: { SAVING, CANNOT_SAVE_UPDATE, SUBMIT, CLEAR, NOTES }
  } = useFormatMessage(DESCRIPTION_STRINGS);
  let itemText: JSX.Element;

  if (isEditShown) {
    // Edit mode
    let statusText = null;
    let textAreaDisabled = false;
    if (submitInProgress) {
      statusText = SAVING;
      textAreaDisabled = true;
    } else if (submitFailed) {
      statusText = CANNOT_SAVE_UPDATE;
    } else {
      statusText = SUBMIT;
    }

    const buttonContainer = (
      <div className={styles.editButtons}>
        <Button text={CLEAR} onClick={handleClearDescription} disabled={submitInProgress} testId="tracks-description-cancel" variant="flat" />
        <Button text={statusText} onClick={handleSubmit} disabled={submitInProgress} testId={statusText} variant="primary" />
      </div>
    );
    const textarea = (
      <TextArea
        labelText={NOTES}
        placeholder={placeholderText}
        disabled={textAreaDisabled}
        testId="notesTextArea"
        value={descriptionValue}
        onChange={handleEditDescription}
      />
    );
    itemText = (
      <div className={styles.tabContent}>
        {textarea}
        {buttonContainer}
      </div>
    );
  } else {
    // Non-Edit Mode
    let innerText = placeholderText;
    if (dataSource) {
      innerText = dataSource;
    } else if (data) {
      innerText = data;
    }

    itemText = (
      <div className={styles.tabContent}>
        <ShowMoreText text={innerText} maxLines={4} className={styles.innerText} />
      </div>
    );
  }
  return itemText;
};

const DescriptionSection = ({
  placeholderText,
  data,
  dataSource,
  handleSubmit,
  isEditShown,
  submitInProgress,
  handleEditDescription,
  submitFailed,
  handleClearDescription,
  descriptionValue
}: Props): JSX.Element => {
  if (!data || !data.length) {
    placeholderText = '';
  }
  return (
    <div className={styles.descriptionSection}>
      <TextEditItem
        data={data}
        dataSource={dataSource}
        placeholderText={placeholderText}
        handleEditDescription={handleEditDescription}
        handleSubmit={handleSubmit}
        isEditShown={isEditShown}
        submitInProgress={submitInProgress}
        submitFailed={submitFailed}
        handleClearDescription={handleClearDescription}
        descriptionValue={descriptionValue}
      />
    </div>
  );
};

export default DescriptionSection;
