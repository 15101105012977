import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useIntl } from '@alltrails/shared/react-intl';
import AlertDialog from '@alltrails/shared/denali/components/AlertDialog';
import Error from '@alltrails/shared/icons/Error';
import * as styles from './styles/styles.module.scss';
import { AttributeMap } from '../../../../../utils/attributeUtils';

type CollectionsAlertDialogProps = {
  selectedFeatures: AttributeMap;
  lastRemovedCollection: { id: string; label: string; tagAssociation: string } | null;
  setSelectedFeatures: (features: AttributeMap) => void;
  updateCollectionMappings: () => void;
};

const CollectionsAlertDialog = ({
  selectedFeatures,
  lastRemovedCollection,
  setSelectedFeatures,
  updateCollectionMappings
}: CollectionsAlertDialogProps) => {
  const intl = useIntl();
  const [showAlert, setShowAlert] = useState(false);
  const [sharedFeature, setSharedFeature] = useState<string>('');
  const selectedFeaturesRef = useRef(selectedFeatures);

  const closeDialog = useCallback(() => {
    setShowAlert(false);
  }, []);

  const cancel = () => {
    closeDialog();
    updateCollectionMappings();
  };

  const removeFeature = () => {
    const updatedSelectedFeatures = { ...selectedFeaturesRef.current };
    delete updatedSelectedFeatures[sharedFeature];
    setSelectedFeatures(updatedSelectedFeatures);
    closeDialog();
  };

  useEffect(() => {
    // Update the ref each time selectedFeatures changes
    selectedFeaturesRef.current = selectedFeatures;
  }, [selectedFeatures]);

  useEffect(() => {
    if (!lastRemovedCollection?.tagAssociation) {
      return;
    }
    // Use selectedFeaturesRef.current instead of selectedFeatures
    const selectedFeatureKeys = Object.keys(selectedFeaturesRef.current);
    const sharedFeatureKey = selectedFeatureKeys.find((featureKey: string) => featureKey === lastRemovedCollection?.tagAssociation);
    if (sharedFeatureKey) {
      setSharedFeature(sharedFeatureKey);
      setShowAlert(true);
    }
  }, [lastRemovedCollection]);

  if (showAlert) {
    return (
      <AlertDialog
        body={intl.formatMessage(
          {
            id: 'removeTag',
            defaultMessage: 'You are removing the collection tag {collectionName}. Do you also want to remove the feature tag {sharedFeature}?'
          },
          { sharedFeature: selectedFeaturesRef.current[sharedFeature]?.name, collectionName: lastRemovedCollection?.label }
        )}
        buttons={{
          primary: {
            onClick: removeFeature,
            testId: 'remove-feature',
            text: intl.formatMessage({ defaultMessage: 'Remove both tags' })
          },
          default: {
            onClick: closeDialog,
            testId: 'remove-collection-only',
            text: intl.formatMessage({ defaultMessage: 'Remove collection tag only' })
          },
          flat: {
            onClick: cancel,
            testId: 'cancel',
            text: intl.formatMessage({ defaultMessage: 'Cancel' })
          }
        }}
        className={styles.column}
        icon={{ Component: Error }}
        onRequestClose={closeDialog}
        size="md"
        title={intl.formatMessage({ defaultMessage: 'Also remove feature tag?' })}
      />
    );
  }

  return null;
};

export default CollectionsAlertDialog;
