import * as d3 from 'd3';

import { D3Utils } from './d3Utils';

const SECONDS_PER_MINUTE = 60;
const PRINT_DPI = 96;
const WMS_SCALE_FACTOR = PRINT_DPI * 39.37 * 156543.03;
const EXACT_SCALES = {
  '-5': { trueScale: 5000 },
  '-6': { trueScale: 10000 },
  '-1': { trueScale: 24000 },
  '-2': { trueScale: 25000 },
  '-3': { trueScale: 50000 },
  '-4': { trueScale: 100000 }
};
let x;
let xUpper;
let xLower;
let y;
let yLeft;
let yRight;

const utmFormatHash = {};

const GridUtils = {
  getUtmData(minLat, maxLat, minLon, maxLon) {
    GeoUtil.datum = org.sarsoft.map.datums['WGS84'];

    const centerLat = (minLat + maxLat) / 2;
    const centerLon = (minLon + maxLon) / 2;

    const utmCenter = GeoUtil.MBLatLngToUTM({
      lat: centerLat,
      lng: centerLon
    });

    const zone = utmCenter.zone;

    const utmUpperLeft = GeoUtil.MBLatLngToUTM(
      {
        lat: maxLat,
        lng: minLon
      },
      zone
    );

    const utmUpperRight = GeoUtil.MBLatLngToUTM(
      {
        lat: maxLat,
        lng: maxLon
      },
      zone
    );

    const utmLowerLeft = GeoUtil.MBLatLngToUTM(
      {
        lat: minLat,
        lng: minLon
      },
      zone
    );

    const utmLowerRight = GeoUtil.MBLatLngToUTM(
      {
        lat: minLat,
        lng: maxLon
      },
      zone
    );

    return {
      zone,
      utmLowerLeft,
      utmUpperLeft,
      utmLowerRight,
      utmUpperRight
    };
  },
  createScale(start, end, length, multiplier = 1) {
    start *= multiplier;
    end *= multiplier;
    const domain = [start, end];
    const range = [0, length];
    return d3
      .scaleLinear()
      .domain(domain)
      .rangeRound(range);
  },
  createGroup(parent) {
    const container = document.querySelector('.printMap');
    const width = container.offsetWidth;
    const height = container.offsetHeight;

    const padding = parseInt(parent.offsetTop, 10);
    const positivePadding = Math.abs(padding);
    const paddedWidth = width + positivePadding * 2;
    const paddedHeight = height + positivePadding * 2;

    const selectedParent = parent?.children?.length > 0 ? parent?.children[0] : parent;

    return d3
      .select(selectedParent)
      .append('svg:svg')
      .attr('width', paddedWidth)
      .attr('height', paddedHeight)
      .append('svg:g')
      .attr('transform', `translate(${positivePadding}, ${positivePadding})`);
  },
  createUtmScales(geoUtilData, width, scaleWidth, height, scaleHeight) {
    const maxLeft = Math.max(geoUtilData.utmUpperLeft.e, geoUtilData.utmLowerLeft.e);
    const minRight = Math.min(geoUtilData.utmUpperRight.e, geoUtilData.utmLowerRight.e);
    const minUpper = Math.min(geoUtilData.utmUpperRight.n, geoUtilData.utmUpperLeft.n);
    const maxLower = Math.max(geoUtilData.utmLowerRight.n, geoUtilData.utmLowerLeft.n);
    if (Math.abs(minRight - maxLeft) > 300000) {
      return false;
    }
    x = this.createScale(maxLeft, minRight, width);
    xUpper = this.createScale(geoUtilData.utmUpperLeft.e, geoUtilData.utmUpperRight.e, scaleWidth);
    xLower = this.createScale(geoUtilData.utmLowerLeft.e, geoUtilData.utmLowerRight.e, scaleWidth);
    y = this.createScale(minUpper, maxLower, height);
    yLeft = this.createScale(geoUtilData.utmUpperLeft.n, geoUtilData.utmLowerLeft.n, scaleHeight);
    yRight = this.createScale(geoUtilData.utmUpperRight.n, geoUtilData.utmLowerRight.n, scaleHeight);
    return true;
  },
  clearGroups(gridRef, mapGridLinesRef) {
    const axisContainer = gridRef?.current;
    const linesContainer = mapGridLinesRef?.current;
    if (axisContainer) {
      axisContainer.innerHTML = '';
    }
    if (linesContainer) {
      linesContainer.innerHTML = '';
    }
  },
  createLngLatSvgs(bounds, gridFormat, mapZoom, gridRef, mapGridLinesRef, intl) {
    const minLat = parseFloat(bounds.sw.lat);
    const maxLat = parseFloat(bounds.ne.lat);
    const minLon = parseFloat(bounds.sw.lng);
    const maxLon = parseFloat(bounds.ne.lng);

    this.clearGroups(gridRef, mapGridLinesRef);
    if (!gridFormat || gridFormat === 'none') {
      return;
    }

    // Use the map container width / height to define where to cut off lat/lon grid lines
    const mapContainer = document.querySelector('.printMap');

    const width = mapContainer.offsetWidth;
    const height = mapContainer.offsetHeight;
    const numYTicks = height / 125;
    const numXTicks = width / 125;

    const utm = !!(gridFormat === 'utm');
    const mapLat = (minLat + maxLat) / 2;

    const scaleText = intl.formatMessage({ defaultMessage: 'Scale' });
    const datumText = intl.formatMessage({ defaultMessage: 'Datum' });

    const trueScale = (WMS_SCALE_FACTOR / 2 ** (mapZoom + 1.0)) * Math.cos((mapLat * 2 * Math.PI) / 360);
    let datumLabel = `${scaleText} 1: ${Math.round(trueScale)} ${datumText} `;
    const dms = !!(gridFormat === 'dms');
    const scaleMultiplier = dms ? SECONDS_PER_MINUTE : 1;
    datumLabel += 'WGS84';
    const mapUtmZoneContainer = document.querySelector('.mapUtmZone');

    if (utm) {
      const geoUtilData = GridUtils.getUtmData(minLat, maxLat, minLon, maxLon);
      datumLabel = `UTM Zone ${geoUtilData.zone} ${datumLabel}`;
      if (!this.createUtmScales(geoUtilData, width, width, height, height)) {
        return;
      }
    } else {
      x = this.createScale(minLon, maxLon, width, scaleMultiplier);
      y = this.createScale(maxLat, minLat, height, scaleMultiplier);
      xUpper = x;
      xLower = x;
      yLeft = y;
      yRight = y;
    }

    mapUtmZoneContainer.innerHTML = datumLabel;

    if (gridRef?.current) {
      const gridContainer = gridRef?.current;
      const axisContainer = GridUtils.createGroup(gridContainer);

      this.createAxis(xUpper, numXTicks, 'top', this.getFormatter(gridFormat), axisContainer, width, height);
      this.createAxis(xLower, numXTicks, 'bottom', this.getFormatter(gridFormat), axisContainer, width, height);
      this.createAxis(yLeft, numYTicks, 'left', this.getFormatter(gridFormat), axisContainer, width, height);
      this.createAxis(yRight, numYTicks, 'right', this.getFormatter(gridFormat), axisContainer, width, height);

      if (utm) {
        const keys = Object.keys(utmFormatHash);
        for (let i = 0; i <= keys.length - 1; i++) {
          const value = utmFormatHash[keys[i]];
          const ref = keys[i].split('.');
          const orientation = ref[0];
          const data = parseInt(ref[1], 10);
          const spanpos = parseInt(ref[2], 10);

          const axisTickLabel = axisContainer
            .selectAll(`.${orientation} text`)
            .attr('text-anchor', 'middle')
            .filter(d => d === data)
            .append('svg:tspan')
            .text(value);
          if (spanpos !== 1) {
            axisTickLabel.attr('baseline-shift', '25%').attr('font-size', '75%');
          }
        }
      }
      this.rotateAxisText('left', false, gridRef);
      this.rotateAxisText('right', true, gridRef);
    }
    if (mapGridLinesRef?.current) {
      // Add to width and height for borders
      this.createGridLines('yTicks', y, numYTicks, 0.5, 0, y, width + 1, y, mapGridLinesRef);
      this.createGridLines('xTicks', x, numXTicks, 1, x, 0, x, height + 1, mapGridLinesRef);
    }
  },
  createGridLines(name, scale, ticks, offset, x1, y1, x2, y2, mapGridLinesRef) {
    const linesContainer = mapGridLinesRef.current;
    const linesGroup = GridUtils.createGroup(linesContainer);

    const wrapFunctionArgument = argument => {
      if (typeof argument === 'number') {
        return argument;
      }
      return d => {
        return Math.round(argument(d)) - offset;
      };
    };

    linesGroup
      .selectAll(`.${name}`)
      .data(scale.ticks(ticks))
      .enter()
      .append('svg:line')
      .attr('x1', wrapFunctionArgument(x1))
      .attr('y1', wrapFunctionArgument(y1))
      .attr('x2', wrapFunctionArgument(x2))
      .attr('y2', wrapFunctionArgument(y2))
      .attr('class', name);
  },
  decimalFormatter(axis, number, value) {
    return axis.tickFormat(number).call(this, value);
  },
  utmFormatter(axis, _, value, i, orientation) {
    let chunk;
    const stringValue = value.toString();
    let ew = '';
    if (i === 0) {
      ew = axis === xUpper || axis === xLower ? 'E' : 'N';
    }
    const parts = stringValue.replace(/\b(\d+)(\d{2})(\d{3})\b/g, `$1/$2/$3${ew}`).split('/');
    for (let labelSection = 0; labelSection <= 2; labelSection++) {
      chunk = parts[labelSection];
      if (i > 0 && labelSection === 2) {
        if (chunk === '000') {
          continue;
        }
      }
      utmFormatHash[`${orientation}.${value}.${labelSection}`] = chunk;
    }
    return null;
  },
  dmsFormatter(axis, _, value) {
    const positiveValue = Math.abs(value);
    const seconds = Math.floor((positiveValue * SECONDS_PER_MINUTE) % SECONDS_PER_MINUTE);
    const minutes = Math.floor(positiveValue % SECONDS_PER_MINUTE);
    const degrees = Math.floor(positiveValue / SECONDS_PER_MINUTE);
    const secondsString = seconds && minutes ? `${seconds}\u2032\u2032` : '';
    const minutesString = minutes ? `${minutes}\u2032` : '';
    const degreesString = `${degrees}°`;
    return `${degreesString}${minutesString}${secondsString}`;
  },
  getFormatter(gridFormat) {
    switch (gridFormat) {
      case 'utm':
        return this.utmFormatter;
      case 'dms':
        return this.dmsFormatter;
      case 'decimal':
        return this.decimalFormatter;
      default:
        return '';
    }
  },
  createAxis(scale, ticks, orientation, formatter, axisContainer, width, height) {
    let axis;
    const formatFunction = (d, i) => {
      return formatter(scale, ticks, d, i, orientation);
    };

    switch (orientation) {
      case 'bottom':
        axis = D3Utils.getAxisOrientation('bottom', scale, ticks, formatFunction);
        return D3Utils.appendAxisOrientation(axisContainer, `axis ${orientation}`, `translate(0, ${height})`, axis, false, false);
      case 'top':
        axis = D3Utils.getAxisOrientation('top', scale, ticks, formatFunction);
        return D3Utils.appendAxisOrientation(axisContainer, `axis ${orientation}`, 'translate(0, 0)', axis, false, false);
      case 'right':
        axis = D3Utils.getAxisOrientation('right', scale, ticks, formatFunction);
        return D3Utils.appendAxisOrientation(axisContainer, `axis ${orientation}`, `translate(${width + 5}, -25)`, axis, false, true);
      case 'left':
        axis = D3Utils.getAxisOrientation('left', scale, ticks, formatFunction);
        return D3Utils.appendAxisOrientation(axisContainer, `axis ${orientation}`, 'translate(-5, -25)', axis, true, false);
      default:
        return '';
    }
  },
  rotateAxisText(name, clockwise, gridRef) {
    const yOffset = -7;
    const rotation = clockwise ? 90 : -90;

    const gridContainer = gridRef.current;
    const axisContainer = GridUtils.createGroup(gridContainer);
    const selectContainer = `.axis ${name} text`;
    const rotationAttr = `rotate(${rotation})`;
    axisContainer
      .selectAll(selectContainer)
      .attr('x', 0)
      .attr('y', yOffset)
      .attr('transform', rotationAttr)
      .attr('text-anchor', 'middle');
  },
  getScaledZoom(newZoom, mapCenter) {
    if (newZoom < 0) {
      const scaleInfo = EXACT_SCALES[newZoom];
      const fractionalZoom = Math.log2((WMS_SCALE_FACTOR * Math.cos((mapCenter.lat * 2 * Math.PI) / 360)) / scaleInfo.trueScale) - 1.0;
      return {
        atMapZoom: newZoom,
        fractionalZoom
      };
    }
    return {
      atMapZoom: newZoom,
      fractionalZoom: newZoom
    };
  }
};

export { GridUtils };
