import classNames from 'classnames';
import NullState from '@alltrails/shared/denali/components/NullState';
import Tent from '@alltrails/shared/illustrations/Tent';
import { defineMessages } from '@alltrails/shared/react-intl';
import * as styles from './styles/styles.module.scss';
import useFormatMessage from '@alltrails/shared/hooks/useFormatMessage';

type ErrorFallbackProps = {
  retryErrorPage: () => void;
  className?: string;
};

const ERROR_FALLBACK_MESSAGES = defineMessages({
  SOMETHING_WENT_WRONG: { defaultMessage: 'Something went wrong' },
  CLICK_RETRY: { defaultMessage: 'Click the button below to try again' },
  RETRY: { defaultMessage: 'Retry' }
});

const ErrorFallback = ({ retryErrorPage, className }: ErrorFallbackProps): JSX.Element => {
  const {
    formattedDefaultMessages: { SOMETHING_WENT_WRONG, CLICK_RETRY, RETRY }
  } = useFormatMessage(ERROR_FALLBACK_MESSAGES);

  return (
    <div className={classNames(styles.fallbackContainer, className)}>
      <NullState
        className={styles.fallbackContainer}
        illustration={{ Component: Tent }}
        title={SOMETHING_WENT_WRONG}
        description={CLICK_RETRY}
        buttons={{
          primary: {
            text: RETRY,
            testId: RETRY,
            onClick: retryErrorPage
          }
        }}
        noTopMargin
      />
    </div>
  );
};

export default ErrorFallback;
