import { memo, lazy, Suspense, useState, useMemo, useCallback, useEffect } from 'react';
import logTrailDetailsReviewTabClicked from '@alltrails/analytics/events/logTrailDetailsReviewTabClicked';
import logTrailDetailsPhotoTabClicked from '@alltrails/analytics/events/logTrailDetailsPhotoTabClicked';
import logTrailDetailsActivityTabClicked from '@alltrails/analytics/events/logTrailDetailsActivityTabClicked';
import logTrailDetailsCompletedTabClicked from '@alltrails/analytics/events/logTrailDetailsCompletedTabClicked';
import TabBar from '@alltrails/shared/denali/components/TabBar';
import { defineMessages, useIntl } from '@alltrails/shared/react-intl';
import { PhotoSize } from '@alltrails/shared/types/photos';
import useFormatMessage from '@alltrails/shared/hooks/useFormatMessage';
import type { Context } from 'types/Context';
import ReviewsUgc from 'components/trailUgc/ReviewsUgc';
import RatingsBreakdown from 'components/trailUgc/types/RatingsBreakdown';
import TrailUgcTab from 'components/trailUgc/types/TrailUgcTab';
import { useTrailUgcContext } from 'components/trailUgc/TrailUgcContext';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import Trail from 'types/Trails/Trail';
import { TrailId } from '@alltrails/shared/types/trail';
import { MapStatsUtil } from 'utils/map_stats_util';
import Ad from 'components/Ad';
import LocationServerData from 'types/LocationServerData';
import useExperiment from 'hooks/useExperiment';
import * as styles from './styles/styles.module.scss';
import TrailUgcLoading from './TrailUgcLoading';

import '@alltrails/modules/TrailReviews/sharedCss.css';

const PhotosUgc = lazy(() => import('components/trailUgc/PhotosUgc'));
const TracksUgc = lazy(() => import('components/trailUgc/TracksUgc'));
const CompletedUgc = lazy(() => import('components/trailUgc/CompletedUgc'));

const TRAIL_UGC_STRINGS = defineMessages({
  REVIEWS: { defaultMessage: 'Reviews' },
  PHOTOS: { defaultMessage: 'Photos' },
  RECORDINGS: { defaultMessage: 'Activities' },
  COMPLETED: { defaultMessage: 'Completed' }
});

type TrailUgcProps = {
  context: Context;
  adminOrPendingOwner?: boolean;
  baseUrl?: string;
  className?: string;
  completedPerPage?: number;
  hideSelectionBar?: boolean;
  includeTrailName?: boolean;
  locationServerData: LocationServerData;
  listId?: number;
  photoSize?: PhotoSize;
  photosPerPage?: number;
  popTrailReview?: boolean;
  ratingsBreakdown: RatingsBreakdown;
  reviewsPerPage?: number;
  section: string;
  showContributeButton?: boolean;
  tracksPerPage?: number;
  trailId?: TrailId;
  trailName?: string;
  userId?: number;
  avgRating: string;
  exploreFlag?: boolean;
  trail?: Trail;
  reviewSummary?: string;
};

const TrailUgc = ({
  context,
  adminOrPendingOwner,
  baseUrl,
  className,
  completedPerPage = 10,
  hideSelectionBar,
  includeTrailName = false,
  locationServerData,
  listId,
  photoSize,
  photosPerPage = 56,
  popTrailReview = false,
  ratingsBreakdown,
  reviewsPerPage = 30,
  section,
  showContributeButton = true,
  tracksPerPage = 30,
  trailId,
  trailName,
  userId,
  avgRating,
  exploreFlag,
  trail,
  reviewSummary
}: TrailUgcProps) => {
  const experiment = useExperiment('web-growth-native-ads-v2');
  const intl = useIntl();
  const {
    formattedDefaultMessages: { REVIEWS, PHOTOS, RECORDINGS, COMPLETED }
  } = useFormatMessage(TRAIL_UGC_STRINGS);

  const languageRegionCode = useLanguageRegionCode();
  const {
    ugcState: { selectedUgcTab, reviews, photos, tracks, completed },
    dispatch
  } = useTrailUgcContext();
  const [shouldPopTrailReview, setShouldPopTrailReview] = useState(popTrailReview);

  const getBaseUrl = useCallback(() => {
    if (baseUrl) {
      return baseUrl;
    }

    switch (section) {
      case 'trail-show':
        return `/api/alltrails/v2/trails/${trailId}`;
      case 'user-reviews':
        return `/api/alltrails/users/${userId}`;
      case 'LIST_PAGE':
        return `/api/alltrails/lists/${listId}`;
      default:
        return '';
    }
  }, [baseUrl, listId, section, trailId, userId]);

  const determineVisibleTabs = useCallback(() => {
    const visibleTabs: { label: string; id: TrailUgcTab; testId: string }[] = [];
    const tabOptions: TrailUgcTab[] = ['reviews', 'photos', 'tracks', 'completed'];
    const tabInfo: Record<TrailUgcTab, { count: number; label: string }> = {
      reviews: { count: reviews.total, label: REVIEWS },
      photos: { count: photos.total, label: PHOTOS },
      tracks: { count: tracks.total, label: RECORDINGS },
      completed: { count: completed.total, label: COMPLETED }
    };

    tabOptions.forEach((tab: TrailUgcTab) => {
      const { count, label } = tabInfo[tab];
      if (count !== null && count !== undefined) {
        const countFormatted = MapStatsUtil.formatData(count, 0, null, languageRegionCode);
        visibleTabs.push({ id: tab, label: `${label} (${countFormatted})`, testId: `trail-ugc-tab-${tab}` });
      }
    });

    return visibleTabs;
  }, [reviews.total, photos.total, tracks.total, completed.total, languageRegionCode]);

  const handleTabChange = useCallback(
    (activeTabKey: TrailUgcTab) => {
      if (activeTabKey === selectedUgcTab) {
        return;
      }

      if (trail) {
        switch (activeTabKey) {
          case 'reviews':
            logTrailDetailsReviewTabClicked({ trail_id: trail.trail_id });
            break;
          case 'photos':
            logTrailDetailsPhotoTabClicked({ trail_id: trail.trail_id });
            break;
          case 'tracks':
            logTrailDetailsActivityTabClicked({ trail_id: trail.trail_id });
            break;
          case 'completed':
            logTrailDetailsCompletedTabClicked({ trail_id: trail.trail_id });
            break;
          default:
          // do nothing
        }
      }

      dispatch({ type: 'CHANGE_UGC_TAB', tab: activeTabKey });
    },
    [dispatch, selectedUgcTab, trail]
  );

  const SelectionBar = useMemo(() => {
    if (hideSelectionBar) {
      return null;
    }

    const visibleTabs = determineVisibleTabs();
    return <TabBar activeTab={selectedUgcTab} className={styles.tabBar} onChange={handleTabChange} tabs={visibleTabs} testId="trail-ugc-tab-bar" />;
  }, [determineVisibleTabs, handleTabChange, hideSelectionBar, selectedUgcTab]);

  // review form should only open once in the lifecycle of this component
  useEffect(() => {
    setShouldPopTrailReview(false);
  }, []);

  const UgcContent = useMemo(() => {
    const baseUrl = getBaseUrl();
    switch (selectedUgcTab) {
      case 'reviews':
        return (
          <ReviewsUgc
            ads={
              (experiment?.value === 'unpaid_only' || experiment?.value === 'unpaid-and-paid') &&
              !exploreFlag && [
                <Ad
                  adUnitPath="/4689141/alltrails_trail3_300x250"
                  className={styles.ad}
                  experiment={experiment?.value}
                  id="gpt-trail3"
                  screenSize="mobile"
                  trail={trail}
                />,
                <Ad
                  adUnitPath="/4689141/alltrails_review2_300x250"
                  className={styles.ad}
                  experiment={experiment?.value}
                  id="gpt-review2"
                  screenSize="mobile"
                  trail={trail}
                />,
                <Ad
                  adUnitPath="/4689141/alltrails_review3_300x250"
                  className={styles.ad}
                  experiment={experiment?.value}
                  id="gpt-review3"
                  screenSize="mobile"
                  trail={trail}
                />
              ]
            }
            context={context}
            baseUrl={baseUrl}
            perPage={reviewsPerPage}
            userId={userId}
            trailId={trailId}
            trailName={trailName}
            locationServerData={locationServerData}
            includeTrailName={includeTrailName}
            ratingsBreakdown={ratingsBreakdown}
            avgRating={avgRating}
            popTrailReview={shouldPopTrailReview}
            showContributeButton={showContributeButton}
            reviewSummary={reviewSummary}
          />
        );
      case 'photos':
        return (
          <Suspense fallback={<TrailUgcLoading />}>
            <PhotosUgc
              baseUrl={baseUrl}
              perPage={photosPerPage}
              photoSize={photoSize}
              userId={userId}
              locationServerData={locationServerData}
              trailId={trailId}
              showContributeButton={showContributeButton}
              trailName={trailName}
              context={context}
            />
          </Suspense>
        );
      case 'tracks':
        return (
          <Suspense fallback={<TrailUgcLoading />}>
            <TracksUgc
              baseUrl={baseUrl}
              perPage={tracksPerPage}
              userId={userId}
              adminOrPendingOwner={adminOrPendingOwner}
              locationServerData={locationServerData}
              includeTrailName={includeTrailName}
              trailId={trailId}
              section={section}
              intl={intl}
            />
          </Suspense>
        );
      case 'completed':
        return (
          <Suspense fallback={<TrailUgcLoading />}>
            <CompletedUgc perPage={completedPerPage} userId={userId} locationServerData={locationServerData} trailId={trailId} />
          </Suspense>
        );
      default:
        return null;
    }
  }, [
    getBaseUrl,
    selectedUgcTab,
    reviewsPerPage,
    userId,
    trailId,
    trailName,
    locationServerData,
    includeTrailName,
    ratingsBreakdown,
    shouldPopTrailReview,
    showContributeButton,
    photosPerPage,
    photoSize,
    tracksPerPage,
    adminOrPendingOwner,
    section,
    completedPerPage,
    avgRating
  ]);

  return (
    <div className={className}>
      {SelectionBar}
      <div className={styles.tabContainer}>{UgcContent}</div>
    </div>
  );
};

export default memo(TrailUgc);
