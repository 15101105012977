import { PropsWithChildren } from 'react';
import { MEDIA_QUERY_MOBILE_DOWN } from 'utils/constants/breakpoints';
import * as styles from './HeroLayout.module.scss';

export type Images = {
  mobile: {
    avif: string;
    webp: string;
    jpg: string;
  };
  desktop: {
    avif: string;
    webp: string;
    jpg: string;
  };
};

type Props = {
  heroAlt: string;
  images: Images;
};

function HeroLayout({ children, heroAlt, images }: PropsWithChildren<Props>) {
  return (
    <>
      <div className={styles.hero}>
        <picture className={styles.picture}>
          <source media={MEDIA_QUERY_MOBILE_DOWN} srcSet={images.mobile.avif} type="image/avif" />
          <source media={MEDIA_QUERY_MOBILE_DOWN} srcSet={images.mobile.webp} type="image/webp" />
          <source media={MEDIA_QUERY_MOBILE_DOWN} srcSet={images.mobile.jpg} />
          <source srcSet={images.desktop.avif} type="image/avif" />
          <source srcSet={images.desktop.webp} type="image/webp" />
          <img alt={heroAlt} src={images.desktop.jpg} className={styles.img} />
        </picture>
      </div>
      <div className={styles.content}>{children}</div>
    </>
  );
}

export default HeroLayout;
