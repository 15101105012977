import TermsOfService from 'components/TermsOfService';
import { ChangeEvent, FormEvent, ReactNode, useState } from 'react';
import Button from '@alltrails/shared/denali/components/Button';
import OutlinedTextField from '@alltrails/shared/components/OutlinedTextField';
import Typography from '@alltrails/shared/denali/components/Typography';
import { FormattedMessage } from '@alltrails/shared/react-intl';
import { put } from '@alltrails/shared/api';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import { wrapUrlSafe } from '../../utils/language_support_util';
import * as styles from './ResetPasswordForm.module.scss';

type Props = {
  resetPasswordToken: string;
  resourceName: string;
  resetPasswordUrl: string;
};

// Desktop: https://www.figma.com/file/gZaqAej8HzoFkyrjlQ4cNs/Integrating-Brand-Changes?node-id=9457%3A92654
export default function ResetPasswordForm({ resetPasswordToken, resourceName, resetPasswordUrl }: Props) {
  const languageRegionCode = useLanguageRegionCode();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [serverError, setServerError] = useState<ReactNode>();
  const [confirmPasswordError, setConfirmPasswordError] = useState<ReactNode>();
  const [passwordError, setPasswordError] = useState<ReactNode>();

  async function handleSubmit(e: FormEvent) {
    e.preventDefault();

    if (password !== confirmPassword) {
      setConfirmPasswordError(<FormattedMessage defaultMessage="Passwords don't match." />);
      return;
    }
    setConfirmPasswordError(undefined);

    if (submitting) {
      return;
    }

    if (password.length < 6 || password.length > 128) {
      setPasswordError(<FormattedMessage defaultMessage="Password must be 6 to 128 characters long." />);
      return;
    }
    setPasswordError(undefined);

    setSubmitting(true);

    const params = {
      [resourceName]: {
        password,
        reset_password_token: resetPasswordToken
      }
    };

    try {
      const response = await put<{ redirectUrl: string }>(resetPasswordUrl, params);
      window.location.assign(wrapUrlSafe(response.redirectUrl, languageRegionCode));
    } catch (e) {
      setServerError(<FormattedMessage defaultMessage="There was an error resetting your password." />);
    } finally {
      setSubmitting(false);
    }
  }

  // error handling matched with Google's password form
  // 1. if blank, don't show error on tab
  // 2. if not blank, show error on blur of first field
  // 3. if there is an error on the first password, we don't show an error on the confirm password
  // 4. Clicking confirm password does show an error on the confirm

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <div className={styles.heading}>
        <Typography component="div" variant="heading400">
          <FormattedMessage defaultMessage="Reset your password" />
        </Typography>
        <Typography component="div" variant="text200">
          <FormattedMessage defaultMessage="Enter your new password below. It must be 6 to 128 characters long." />
        </Typography>
      </div>
      <OutlinedTextField
        changeHandler={(e: ChangeEvent<HTMLInputElement>) => {
          setPassword(e.target.value);
        }}
        errorMessage={passwordError}
        label={<FormattedMessage defaultMessage="Password" />}
        name="password"
        onBlur={(e: any) => {
          const { value } = e.target;

          if (value === '') {
            return;
          }

          if (e.target.value.length < 6 || e.target.value.length > 128) {
            setPasswordError(<FormattedMessage defaultMessage="Password must be 6 to 128 characters long." />);
            return;
          }

          setPasswordError(undefined);
        }}
        type="password"
        value={password}
      />
      <OutlinedTextField
        changeHandler={(e: ChangeEvent<HTMLInputElement>) => {
          const { value } = e.target;
          setConfirmPassword(value);

          // matches Google password form error handling
          if (value === '') {
            setConfirmPasswordError(undefined);
          }
        }}
        errorMessage={confirmPasswordError}
        label={<FormattedMessage defaultMessage="Confirm password" />}
        name="confirmPassword"
        type="password"
        value={confirmPassword}
      />
      {serverError && (
        <Typography color="error" component="div" variant="text200">
          {serverError}
        </Typography>
      )}
      <Button
        // disabled={submitting}
        loading={submitting}
        testId="password-reset-submit"
        text={<FormattedMessage defaultMessage="Submit" />}
        type="submit"
        variant="primary"
        width="full"
      />
      <TermsOfService />
    </form>
  );
}
