import { END, START, START_END } from 'utils/constants/MarkerIds';
import hasPermission from 'utils/hasPermission';
import MapPopup from '../../../components/shared/MapPopups/MapPopup';
import { addMarkers, removeMarkers } from './markers';

const addStartEnd = (map, id, multiCoordinates) => {
  const startEndMarkers = [];
  multiCoordinates.forEach(coordinates => {
    if (coordinates.length < 1) {
      return;
    }
    const startCoord = coordinates[0];
    const [startLng, startLat] = startCoord;
    const endCoord = coordinates[coordinates.length - 1];
    const [endLng, endLat] = endCoord;
    if (startLng === endLng && startLat === endLat) {
      startEndMarkers.push({
        name: 'Start / End',
        location: {
          latitude: startLat,
          longitude: startLng
        },
        iconImage: 'map-marker-start-end'
      });
    } else {
      startEndMarkers.push({
        name: 'Start',
        location: {
          latitude: startLat,
          longitude: startLng
        },
        iconImage: 'map-marker-start'
      });
      startEndMarkers.push({
        name: 'End',
        location: {
          latitude: endLat,
          longitude: endLng
        },
        iconImage: 'map-marker-end'
      });
    }
  });

  let startEndPopup;
  if (!hasPermission({ permission: 'trails:manage' })) {
    startEndPopup = marker => {
      const location = JSON.parse(marker.location);
      return <MapPopup name={marker.name} latitude={location.latitude} longitude={location.longitude} />;
    };
  }
  addMarkers(map, id, startEndMarkers, [START_END, START, END], startEndPopup);
};

const removeStartEnd = (map, id) => {
  removeMarkers(map, id);
};

export { addStartEnd, removeStartEnd };
