import { useContext, useState } from 'react';
import { useIntl, defineMessages, IntlShape } from '@alltrails/shared/react-intl';
import Button from '@alltrails/shared/denali/components/Button';
import OutlinedTextField from '@alltrails/shared/components/OutlinedTextField';
import useFormatMessage from '@alltrails/shared/hooks/useFormatMessage';

import { PrintContext } from '../../printContext';
import { PaperSizes, IMPERIAL_SIZES, METRIC_SIZES, DIMENSIONS, MIN_DIMENSION, MAX_DIMENSION, PAPER_SIZE_LABELS } from '../../constants';
import { toInches, fromInches, validDimension, getUnit, formatDimension } from '../../utils';
import LearnMore from '../LearnMore';

type Side = 'width' | 'length';

type Props = {
  isMobileWidth?: boolean;
};

const PAPER_SIZE_STRINGS = defineMessages({
  PAPER_SIZE: { defaultMessage: 'Paper size' },
  WIDTH: { defaultMessage: 'Width ({unit})' },
  LENGTH: { defaultMessage: 'Length ({unit})' },
  CONTENT: {
    defaultMessage:
      'Choose the size of paper you would like your map printed out on. Dimensions of the different options can be found below. You can also create a custom sized map, if for example you were wanting to print a large poster sized map.'
  },
  ERROR_MESSAGE: {
    defaultMessage: 'Min {min}, Max {max}'
  }
});

const errorMessage = (metric: boolean, intl: IntlShape) =>
  intl.formatMessage(PAPER_SIZE_STRINGS.ERROR_MESSAGE, {
    min: formatDimension(MIN_DIMENSION, metric, intl),
    max: formatDimension(MAX_DIMENSION, metric, intl)
  });

const PaperSize = ({ isMobileWidth = false }: Props) => {
  const {
    formattedDefaultMessages: { PAPER_SIZE, CONTENT },
    formatMessage
  } = useFormatMessage(PAPER_SIZE_STRINGS);
  const {
    metric,
    paperSize,
    paperDimensionWidth,
    paperDimensionHeight,
    setPaperSize,
    setPaperDimensionsWidth,
    setPaperDimensionsHeight,
    setPaperDimensionsValid
  } = useContext(PrintContext);
  const sizes = metric ? METRIC_SIZES : IMPERIAL_SIZES;
  const intl = useIntl();

  // Store these as strings so we can display the user's input if they enter an invalid value
  // If the paper size is custom on page load initialize with dimensions from the context, otherwise use the default custom dimensions
  const [width, setWidth] = useState<string>(
    fromInches(paperSize === PaperSizes.CUSTOM ? paperDimensionWidth : DIMENSIONS[PaperSizes.CUSTOM][0], metric).toString()
  );
  const [length, setLength] = useState<string>(
    fromInches(paperSize === PaperSizes.CUSTOM ? paperDimensionHeight : DIMENSIONS[PaperSizes.CUSTOM][1], metric).toString()
  );
  const [widthError, setWidthError] = useState(false);
  const [lengthError, setLengthError] = useState(false);

  const handleSizeButtonClick = (size: PaperSizes) => {
    if (size === PaperSizes.CUSTOM) {
      setPaperDimensionsWidth(toInches(Number(width), metric));
      setPaperDimensionsHeight(toInches(Number(length), metric));
      setPaperDimensionsValid(validDimension(Number(width), metric) && validDimension(Number(length), metric));
    } else {
      setPaperDimensionsValid(true);
      setPaperDimensionsWidth(DIMENSIONS[size][0]);
      setPaperDimensionsHeight(DIMENSIONS[size][1]);
    }
    setPaperSize(size);
  };

  const handleChange = (side: Side, value: string) => {
    let valueError = false;
    let numberValue: number = Number(value);

    if (value === '' || Number.isNaN(numberValue)) {
      numberValue = undefined;
    }

    if (!validDimension(numberValue, metric)) {
      valueError = true;
    }

    if (side === 'width') {
      setWidthError(valueError);
      setWidth(value);
      setPaperDimensionsWidth(toInches(numberValue, metric));
    } else {
      setLengthError(valueError);
      setLength(value);
      setPaperDimensionsHeight(toInches(numberValue, metric));
    }

    setPaperDimensionsValid(!valueError);
  };

  const renderCustomInputs = () => {
    if (paperSize !== PaperSizes.CUSTOM) {
      return null;
    }

    return (
      <div className="paperSizeInputs">
        <OutlinedTextField
          className="paperSize"
          label={formatMessage('WIDTH', { unit: getUnit(metric, intl) })}
          errorMessage={widthError ? errorMessage(metric, intl) : undefined}
          value={width || ''}
          changeHandler={event => handleChange('width', event.target.value)}
        />
        <OutlinedTextField
          className="paperSize"
          label={formatMessage('LENGTH', { unit: getUnit(metric, intl) })}
          errorMessage={lengthError ? errorMessage(metric, intl) : undefined}
          value={length || ''}
          changeHandler={event => handleChange('length', event.target.value)}
        />
      </div>
    );
  };

  return (
    <div className="buttonRow">
      {sizes.map(size => (
        <Button
          key={`paper-size-${size}`}
          text={intl.formatMessage(PAPER_SIZE_LABELS[size])}
          className="settingsButton"
          onClick={() => handleSizeButtonClick(size)}
          testId={intl.formatMessage(PAPER_SIZE_LABELS[size])}
          variant={paperSize === size ? 'primary' : 'default'}
        />
      ))}
      {renderCustomInputs()}
      <LearnMore title={PAPER_SIZE} content={CONTENT} paperSize isMobileWidth={isMobileWidth} />
    </div>
  );
};

export default PaperSize;
