import { useState } from 'react';
import { defineMessages } from '@alltrails/shared/react-intl';
import Button from '@alltrails/shared/denali/components/Button';
import type { Context } from 'types/Context';
import Track from '@alltrails/shared/types/track';
import useFormatMessage from '@alltrails/shared/hooks/useFormatMessage';
import ActivityCard from 'components/cards/ActivityCard';
import TrailCard from 'components/cards/TrailCard';
import type Trail from 'types/Trails/Trail';
import ListMethods from 'types/ListMethods';
import * as styles from './styles.module.scss';

const TRAILS_PER_PAGE = 24;

const TRAIL_LIST_MESSAGES = defineMessages({ SHOW_MORE: { defaultMessage: 'Show more trails' } });

export type TrailsListProps = {
  adjustMaxCount?: boolean;
  context: Context;
  listMethods: ListMethods;
  loadMoreTrails: (pageNum: number, perPage: number, onSuccess: (newTrails: (Trail | Track)[]) => void) => void;
  onTrailClick?: (trail: Trail | Track) => void;
  serverTotalItems: number;
  trailRenderer?: (
    trail: Trail | Track,
    idx: number,
    trailCard: (
      innerRef?: (element: HTMLElement) => any,
      draggableProps?: { [key: string]: any },
      dragHandleProps?: { [key: string]: any }
    ) => JSX.Element
  ) => JSX.Element;
  trails: (Trail | Track)[];
};

const TrailsList = ({
  adjustMaxCount,
  context,
  listMethods,
  loadMoreTrails,
  onTrailClick,
  serverTotalItems,
  trailRenderer,
  trails
}: TrailsListProps): JSX.Element => {
  const {
    formattedDefaultMessages: { SHOW_MORE }
  } = useFormatMessage(TRAIL_LIST_MESSAGES);

  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState(serverTotalItems);
  const [loading, setLoading] = useState(false);

  const onMoreTrailsLoaded = (newTrails: (Trail | Track)[]) => {
    // if this call returns less trails than we ask for, the proximity search won't return any more so we want to make the current total the max
    let maximumTrailCount = totalItems;
    if (newTrails.length < TRAILS_PER_PAGE && adjustMaxCount) {
      maximumTrailCount += newTrails.length;
    }
    setPage(page + 1);
    setTotalItems(maximumTrailCount);
    setLoading(false);
  };

  const onShowMoreTrailsClick = () => {
    setLoading(true);
    loadMoreTrails(page + 1, TRAILS_PER_PAGE, onMoreTrailsLoaded);
  };

  const renderTrail = (trail: Trail | Track, idx: number) => {
    const trailCard = (
      innerRef?: (element: HTMLElement) => any,
      draggableProps?: { [key: string]: any },
      dragHandleProps?: { [key: string]: any }
    ) => (
      <li key={`result-item-${trail.type === 'trail' ? trail.ID : (trail as Track).id}`} className={styles.trail} ref={innerRef} {...draggableProps}>
        {trail.type === 'trail' ? (
          <TrailCard
            allowItemMoving={!!dragHandleProps}
            dragHandleProps={dragHandleProps}
            handleClickAnalytics={() => onTrailClick?.(trail)}
            handleFavoriteClick={() => listMethods.handleFavoriteClick({ type: 'trail', id: trail.ID, objectId: (trail as Trail).objectID })}
            isCompleted={listMethods.isComplete('trail', trail.ID)}
            isFavorite={listMethods.isFavorite('trail', trail.ID)}
            isVerified={listMethods.isVerified('trail', trail.ID)}
            trail={trail as Trail}
          />
        ) : (
          <ActivityCard
            allowItemMoving={!!dragHandleProps}
            context={context}
            dragHandleProps={dragHandleProps}
            handleCardClick={() => onTrailClick?.(trail)}
            handleFavoriteClick={() =>
              listMethods.handleFavoriteClick({
                type: trail.type,
                id: (trail as Track).id ?? (trail as Track).ID,
                objectId: (trail as Track).objectId,
                contentPrivacy: (trail as Track).contentPrivacy
              })
            }
            isFavorite={listMethods.isFavorite(trail.type, (trail as Track).id ?? (trail as Track).ID)}
            track={trail as Track}
          />
        )}
      </li>
    );

    return trailRenderer ? trailRenderer(trail, idx, trailCard) : trailCard();
  };

  return (
    <>
      <ul>{trails.map((trail, idx) => renderTrail(trail, idx))}</ul>
      {trails.length < totalItems && (
        <div className={styles.showMoreButtonContainer}>
          <Button
            loading={loading}
            onClick={onShowMoreTrailsClick}
            testId="sidebar-trail-list-show-more"
            text={SHOW_MORE}
            variant="default"
            width="fullOnMobile"
          />
        </div>
      )}
    </>
  );
};

export default TrailsList;
