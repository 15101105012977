import AmplitudeTrailCondition from '@alltrails/analytics/enums/TrailCondition';
import { TrailConditionUid } from 'types/Trails';

/**
 * Converts the enum value of the conditionUid to an enum value that the amplitude helper takes in.
 * @param conditionUid the uid of the condition to conver
 * @returns the corresponding amplitude enum value
 */
export default function convertTrailConditionUidToAmplitude(conditionUid: TrailConditionUid): AmplitudeTrailCondition {
  const indexOfCondition = Object.values(TrailConditionUid).indexOf(conditionUid);
  return AmplitudeTrailCondition[Object.keys(TrailConditionUid)[indexOfCondition] as keyof typeof AmplitudeTrailCondition];
}
