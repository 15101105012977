import logExploreFiltersApplied from '@alltrails/analytics/events/logExploreFiltersApplied';
import logExploreMapPanEnd from '@alltrails/analytics/events/logExploreMapPanEnd';
import SearchDifficultyFilter from '@alltrails/analytics/enums/SearchDifficultyFilter';
import SearchOrigin from '@alltrails/analytics/enums/SearchOrigin';
import SearchRouteTypeFilter from '@alltrails/analytics/enums/SearchRouteTypeFilter';
import SearchTrailCompletedFilter from '@alltrails/analytics/enums/SearchTrailCompletedFilter';
import SearchTrailTrafficFilter from '@alltrails/analytics/enums/SearchTrailTrafficFilter';
import Track from '@alltrails/shared/types/track';
import { Filters } from 'types/Search';
import { sortToAmplitudeSort } from 'utils/amplitudeHelper';
import Trail from 'types/Trails/Trail';
import { MapStatsUtil } from './map_stats_util';
import { SearchFiltersUtil } from './search_filters_util';
import type GeoLocation from '@alltrails/shared/types/Location/GeoLocation';
import { FILTER, MAP_PAN } from './constants/SearchAnalyticsConstants';

const logFiltersApplied = (
  filters: Filters,
  numResults: number,
  resultsLimit: number,
  center: GeoLocation | null,
  searchRadius: number,
  source: SearchOrigin
): void => {
  const activityFilters = SearchFiltersUtil.getSelectedActivityFilterValues(filters);
  const difficultyFilters = SearchFiltersUtil.getSelectedDifficultyFilterValues(filters);
  const attractionFilters = SearchFiltersUtil.getSelectedAttractionsFilterValues(filters);
  const suitabilityFilters = SearchFiltersUtil.getSelectedSuitabilityFilterValues(filters);
  const routeTypeFilters = SearchFiltersUtil.getSelectedRouteFilterValues(filters);
  const trailTrafficFilters = SearchFiltersUtil.getSelectedTrailTrafficFilterValues(filters);
  const completionFilters = SearchFiltersUtil.getSelectedCompletedFilterValues(filters);

  logExploreFiltersApplied({
    filter_id: -1,
    is_saved_filter_set: false,
    activity_type_filters: activityFilters.length > 0 ? JSON.stringify(activityFilters) : null,
    attractions_filters: attractionFilters.length ? attractionFilters : null,
    difficulty_filters: difficultyFilters as SearchDifficultyFilter[],
    elevation_filters: JSON.stringify(
      SearchFiltersUtil.hasAlteredRangeFilter(filters.elevation_gain)
        ? {
            min: filters.elevation_gain.value[0] === 0 ? null : MapStatsUtil.metersToFeet(filters.elevation_gain.value[0]),
            max: filters.elevation_gain.value[1] === -1 ? null : MapStatsUtil.metersToFeet(filters.elevation_gain.value[1])
          }
        : null
    ),
    length_filters: JSON.stringify(
      SearchFiltersUtil.hasAlteredRangeFilter(filters.lengths)
        ? {
            min: filters.lengths.value[0] === 0 ? null : MapStatsUtil.metersToMiles(filters.lengths.value[0]),
            max: filters.lengths.value[1] === -1 ? null : MapStatsUtil.metersToMiles(filters.lengths.value[1])
          }
        : null
    ),
    distance_away_filter: SearchFiltersUtil.hasAlteredSliderFilter(filters.distance_away)
      ? Math.round(MapStatsUtil.metersToMiles(filters.distance_away.value))
      : null,
    limit_filters: resultsLimit,
    location_filters: JSON.stringify(
      center != null || searchRadius != null
        ? {
            lat: center?.lat ?? null,
            lng: center?.lng ?? null,
            radius: searchRadius
          }
        : null
    ),
    num_filters_selected: SearchFiltersUtil.getNumSelectedFilters(filters),
    num_results_returned: numResults,
    rating_filters: parseFloat(filters.minRating),
    route_type_filters: routeTypeFilters as SearchRouteTypeFilter[],
    search_origin: source,
    sort_filters: sortToAmplitudeSort(SearchFiltersUtil.getSelectedSortFilter(filters)),
    suitability_filters: suitabilityFilters.length ? suitabilityFilters : null,
    trail_completion_filters: completionFilters as SearchTrailCompletedFilter[],
    trail_traffic_filters: trailTrafficFilters as SearchTrailTrafficFilter[]
  });
};

const logSearchResultsAnalytics = (
  filters: Filters,
  results: Trail[] | Track[],
  resultsLimit: number,
  center: number[],
  searchRadius: number,
  source: SearchOrigin,
  searchTrigger: string
) => {
  const centerCoords = center ? { lat: center[0], lng: center[1] } : null;
  switch (searchTrigger) {
    case MAP_PAN:
      logExploreMapPanEnd({ lat: centerCoords.lat, lng: centerCoords.lng, num_results_returned: results.length, zoom_level: filters.zoomLevel });
      break;
    case FILTER:
      logFiltersApplied(filters, results.length, resultsLimit, centerCoords, searchRadius, source);
      break;
    default:
    // Do nothing - no other search analytics to log
  }
};

export { logSearchResultsAnalytics };
