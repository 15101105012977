import { useMemo, useState, useEffect } from 'react';
import { func } from 'prop-types';
import Button from '@alltrails/shared/denali/components/Button';
import { defineMessages } from '@alltrails/shared/react-intl';
import { useLocalizedText } from '@alltrails/shared/modules/Localization';
import { waypointPropTypes } from '../../../../proptypes/shared';
import { dateString } from '@alltrails/shared/utils/timeHelpers';
import OriginalLanguageButton from '../../OriginalLanguageButton';
import useFormatMessage from '@alltrails/shared/hooks/useFormatMessage';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import * as styles from './styles/styles.module.scss';

const WAYPOINT_POPUP_STRINGS = defineMessages({
  NEW_WAYPOINT: { defaultMessage: 'New waypoint' },
  DRAG: { defaultMessage: 'Drag to desired location' },
  HIDE: { defaultMessage: 'Hide title' },
  SHOW: { defaultMessage: 'Show title' }
});

const WaypointPopup = ({ waypoint, toggleShowTitle, addToVRoute, messagingChannel }) => {
  const [showTitle, updateShowTitle] = useState(waypoint.waypointDisplayProperty ? waypoint.waypointDisplayProperty.showTitle : false);
  const [vRouteLabel, updateVRouteLabel] = useState('Add To VRoute');
  const [location, updateLocation] = useState(waypoint.location);
  const {
    formattedDefaultMessages: { NEW_WAYPOINT, DRAG, HIDE, SHOW }
  } = useFormatMessage(WAYPOINT_POPUP_STRINGS);

  const languageRegionCode = useLanguageRegionCode();

  const descriptionSource = waypoint?.description_original || waypoint?.description;
  const {
    canBeLocalized: isDescTranslatable,
    isLocalized: toggledDescText,
    toggleText: toggleDescText,
    text: description
  } = useLocalizedText(descriptionSource, waypoint?.description_lang, languageRegionCode, {
    table: 'waypoints',
    column: 'description',
    row: waypoint?.id
  });

  const nameSource = waypoint?.name_original || waypoint?.name;
  const {
    canBeLocalized: isNameTranslatable,
    isLocalized: toggledNameText,
    toggleText: toggleNameText,
    text: name
  } = useLocalizedText(nameSource, waypoint?.name_lang, languageRegionCode, { table: 'waypoints', column: 'name', row: waypoint?.id });

  const handleWaypointDragged = data => {
    const {
      location: { latitude, longitude }
    } = data;

    const newLocation = {
      longitude: longitude.toFixed(5),
      latitude: latitude.toFixed(5)
    };

    updateLocation(newLocation);
  };

  useEffect(() => {
    if (!messagingChannel) {
      return;
    }

    messagingChannel.subscribe('waypoint.dragged.with_location', handleWaypointDragged);
  }, []);
  const coordinates = useMemo(() => {
    const { latitude, longitude } = location;

    return `${parseFloat(latitude).toFixed(5)}, ${parseFloat(longitude).toFixed(5)}`;
  }, [location]);

  const handleToggleShowTitle = () => {
    updateShowTitle(prevState => !prevState);
    toggleShowTitle();
  };

  const vRouteAddedOrFailed = () => {
    return vRouteLabel === 'Added' || vRouteLabel === 'Failed';
  };

  const handleAddtoVRoute = () => {
    if (vRouteAddedOrFailed() || !addToVRoute) {
      return;
    }

    addToVRoute()
      .then(text => {
        updateVRouteLabel(text);
      })
      .catch(text => {
        updateVRouteLabel(text);
      });
  };

  useMemo(() => {
    if (waypoint.id === null) {
      return [NEW_WAYPOINT, DRAG];
    }
  }, [waypoint]);

  return (
    <div className={styles.container}>
      <div className={styles.nameAndDescription}>
        <div className={styles.title}>{name}</div>
        <div className={styles.description}>{description}</div>
      </div>
      <div className={styles.coordinates}>
        {waypoint.date && <div>{dateString(waypoint.date, waypoint.timezone)}</div>}
        <div>{coordinates}</div>
      </div>
      {waypoint.name && (
        <div className={styles.controlBtns}>
          <div className={styles.linkBtnContainer}>
            {toggleShowTitle && (
              <Button text={showTitle ? HIDE : SHOW} onClick={handleToggleShowTitle} size="sm" testId="waypoint-popup-show-hide-title" width="full" />
            )}
            {(addToVRoute || vRouteAddedOrFailed()) && (
              <Button text={vRouteLabel} onClick={handleAddtoVRoute} disabled={!addToVRoute && vRouteAddedOrFailed()} size="sm" testId={vRouteLabel} width="full" />
            )}
          </div>
          {(isDescTranslatable || isNameTranslatable) && (
            <OriginalLanguageButton
              active={toggledDescText || toggledNameText}
              handleClick={() => {
                toggleDescText();
                toggleNameText();
              }}
              eventSource="Waypoint Popup"
            />
          )}
        </div>
      )}
    </div>
  );
};

WaypointPopup.propTypes = {
  toggleShowTitle: func,
  waypoint: waypointPropTypes.isRequired
};

WaypointPopup.defaultProps = {
  toggleShowTitle: null,
  messagingChannel: null
};

export default WaypointPopup;
