import { useIntl, defineMessages } from '@alltrails/shared/react-intl';
import Select from '@alltrails/shared/denali/components/Select';
import Switch from '@alltrails/shared/denali/components/Switch';
import CustomProvider from 'components/CustomProvider';
import { useMemo } from 'react';
import RoutingTip from '../RoutingTip';
import { routingControls, controlContainer, routingToggle } from './styles/styles.module.scss';

type Props = {
  nodeSelected?: object;
  closeRoutingTip: () => void;
  onSelectRoutingMode: (value: string) => void;
  toggleRouting: () => void;
  routingMode: string;
  canRouteAlongSlidRoute?: boolean;
  routingActive: boolean;
  showRoutingTips: object;
};

const routeModes: { [key: string]: object } = defineMessages({
  HIKING: { defaultMessage: 'Hiking' },
  MOUNTAIN_BIKING: { defaultMessage: 'Mountain biking' },
  BIKE_TOURING: { defaultMessage: 'Bike touring' },
  ROAD_BIKING: { defaultMessage: 'Road biking' },
  OFF_ROAD_DRIVING: { defaultMessage: 'Off-road driving' },
  SCENIC_DRIVING: { defaultMessage: 'Scenic driving' },
  SLID_ROUTE: { defaultMessage: 'Suggested route (beta)' }
});

const RouteControls = ({
  routingMode,
  canRouteAlongSlidRoute = false,
  onSelectRoutingMode,
  routingActive,
  toggleRouting,
  closeRoutingTip,
  showRoutingTips,
  nodeSelected
}: Props): JSX.Element => {
  const intl = useIntl();

  let tipType;

  if (nodeSelected) {
    tipType = 'NODE_TIP';
  } else if (routingActive) {
    tipType = 'SMART_TIP';
  } else {
    tipType = 'DRAW_TIP';
  }

  const routingModes = useMemo(() => {
    const modes = ['hiking', 'mountain-biking', 'bike-touring', 'road-biking', 'off-road-driving', 'scenic-driving'];
    if (canRouteAlongSlidRoute) {
      modes.push('slid-route');
    }
    return modes;
  }, [canRouteAlongSlidRoute]);

  const routingOptions = useMemo(
    () =>
      routingModes.map(mode => ({
        value: mode,
        label: intl.formatMessage(routeModes[mode.replace(/-/g, '_').toUpperCase()])
      })),
    [routingModes, intl]
  );

  const currentOption = useMemo(() => routingOptions.find(option => option.value === routingMode), [routingOptions, routingMode]);

  return (
    <CustomProvider>
      <div className={controlContainer}>
        <div className={routingControls}>
          <Switch
            className={routingToggle}
            labelText={intl.formatMessage({ defaultMessage: 'Smart routing' })}
            selected={routingActive}
            testId="routeControlToggle"
            onChange={toggleRouting}
            width="full"
          />
          <Select
            labelText={intl.formatMessage({ defaultMessage: 'Routing type' })}
            placeholder=""
            options={routingOptions}
            onChange={value => onSelectRoutingMode(value)}
            value={currentOption.value}
            testId="routing-type-select"
          />
        </div>
        {/* @ts-expect-error showRoutingTips throws any error */}
        {showRoutingTips && showRoutingTips[tipType] && <RoutingTip tipType={tipType} onClose={closeRoutingTip} />}
      </div>
    </CustomProvider>
  );
};

export default RouteControls;
