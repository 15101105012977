const alertTypes = intl => [
  { value: 'W', label: intl.formatMessage({ defaultMessage: 'Caution' }) },
  { value: 'I', label: intl.formatMessage({ defaultMessage: 'Informational' }) },
  { value: 'C', label: intl.formatMessage({ defaultMessage: 'Closure' }) },
  { value: 'D', label: intl.formatMessage({ defaultMessage: 'Danger' }) }
];

const defaultAlertType = intl => alertTypes(intl)[0];

const alertTypeLabel = (alertType, intl) => {
  const type = alertTypes(intl).find(type => type.value === alertType);
  return type ? type.label : '';
};

export { alertTypes, defaultAlertType, alertTypeLabel };
