import { defineMessages } from '@alltrails/shared/react-intl';
import useFormatMessage from '@alltrails/shared/hooks/useFormatMessage';
import { func, bool } from 'prop-types';
import Button from '@alltrails/shared/denali/components/Button';
import * as styles from './styles/styles.module.scss';

const FORM_BUTTONS_STRINGS = defineMessages({
  CANCEL: { defaultMessage: 'Cancel' },
  SAVE: { defaultMessage: 'Save' }
});

const FormButtons = ({ onCancel, disabled }) => {
  const {
    formattedDefaultMessages: { CANCEL, SAVE }
  } = useFormatMessage(FORM_BUTTONS_STRINGS);
  return (
    <div className={styles.formButtonsContainer}>
      <Button text={CANCEL} onClick={onCancel} testId="map-creator-cancel" variant="flat" />
      <Button disabled={disabled} testId="map-creator-save" text={SAVE} type="submit" variant="primary" width="auto" />
    </div>
  );
};

export default FormButtons;

FormButtons.propTypes = {
  onCancel: func.isRequired,
  disabled: bool
};

FormButtons.defaultProps = {
  disabled: false
};
