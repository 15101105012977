import { useEffect } from 'react';
import { useParams } from '@alltrails/shared/components/ReactRouter';
import { defineMessages, FormattedMessage, useIntl } from '@alltrails/shared/react-intl';
import Button from '@alltrails/shared/denali/components/Button';
import ReferralLayout from 'components/ReferralLayout';
import Link from '@alltrails/shared/denali/components/Link';
import Typography from '@alltrails/shared/denali/components/Typography';
import logInvitedUserCreateAccountClicked from '@alltrails/analytics/events/logInvitedUserCreateAccountClicked';
import logInvitedUserLandingPageViewed from '@alltrails/analytics/events/logInvitedUserLandingPageViewed';
import useFormatMessage from '@alltrails/shared/hooks/useFormatMessage';
import smallAvif from './landing-page-1-w750.avif';
import smallWebp from './landing-page-1-w750.webp';
import smallJpg from './landing-page-1-w750.jpg';
import largeAvif from './landing-page-1-w2800.avif';
import largeWebp from './landing-page-1-w2800.webp';
import largeJpg from './landing-page-1-w2800.jpg';
import * as styles from './styles.module.scss';

const messages = defineMessages({
  createFreeAccount: {
    defaultMessage: 'Create a free account'
  }
});

function ReferralWelcome({ firstName }) {
  const intl = useIntl();
  const {
    formattedDefaultMessages: { createFreeAccount }
  } = useFormatMessage(messages);

  const { referralLink } = useParams();

  useEffect(() => {
    logInvitedUserLandingPageViewed();
  }, []);

  return (
    <>
      <ReferralLayout
        activeStep={0}
        images={{
          mobile: { avif: smallAvif, webp: smallWebp, jpg: smallJpg },
          desktop: { avif: largeAvif, webp: largeWebp, jpg: largeJpg }
        }}
        heroAlt={intl.formatMessage({
          defaultMessage:
            "A close-up of two people's hands planting a redwood tree together. One is holding a tree in a black pot, while the other makes room for the tree to be planted in the ground among green redwood needles."
        })}
        heading={<FormattedMessage defaultMessage="{firstName} invited you to hit the trails!" values={{ firstName }} />}
        paragraphText={
          <FormattedMessage defaultMessage="Once you sign up and download the app, we'll plant a tree for each of you. That means you'll be supporting One Tree Planted to restore damage from wildfires and deforestation worldwide." />
        }
      >
        <div className={styles.welcomeContent}>
          <Button
            linkInfo={{ href: `${referralLink}/signup` }}
            text={createFreeAccount}
            testId="referral-create-free-account"
            variant="primary"
            onClick={logInvitedUserCreateAccountClicked}
          />
          <Typography className={styles.disclaimer} variant="paragraph100">
            <FormattedMessage defaultMessage="Heads up: This offer is for new members only." />
          </Typography>
        </div>
      </ReferralLayout>
      <footer className={styles.terms}>
        <Link size="sm" testId="terms-link" href="/terms" variant="secondary">
          <FormattedMessage defaultMessage="Terms and conditions" />
        </Link>
      </footer>
    </>
  );
}

export default ReferralWelcome;
