import { useEffect, useState } from 'react';
import { MapboxMapShim } from 'types/Map';

export default function useMapLoadedEvents(mapInstance: MapboxMapShim, isNewMapsPage: boolean) {
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const [isMapStyleLoaded, setIsMapStyleLoaded] = useState(false);

  function onLoad() {
    setIsMapLoaded(true);
  }

  function onStyleLoad() {
    setIsMapStyleLoaded(true);
  }

  useEffect(() => {
    setIsMapLoaded(false);
    setIsMapStyleLoaded(false);
  }, [isNewMapsPage]);

  useEffect(() => {
    // This is a very small number so we can continually check for map readiness
    // and not delay UX functionality.
    const intervalToCheckMapReadiness = 50;

    // Until we eventually create the map instance ourselves in MapProvider and
    // work away from mixins there is an odd edge-case/race-condition where the
    // map is not loaded, but event handlers can't be added in time. So, use our
    // own timers to track map readiness as a fallback if needed. Normally the
    // event handlers work as needed, but this is a backup for that edge case.
    let timer: any;
    // eslint-disable-next-line prefer-const
    timer = setInterval(() => {
      if (mapInstance?.loaded() && !isNewMapsPage) {
        setIsMapLoaded(true);
      }
      if (mapInstance?.isStyleLoaded() && !isNewMapsPage) {
        setIsMapStyleLoaded(true);
      }
      if (mapInstance?.loaded() && mapInstance?.isStyleLoaded() && !isNewMapsPage) {
        clearInterval(timer);
      }
    }, intervalToCheckMapReadiness);

    if (!isNewMapsPage) {
      mapInstance?.once('load', onLoad);
      mapInstance?.once('style.load', onStyleLoad);
    }

    return () => {
      mapInstance?.off('load', onLoad);
      mapInstance?.off('style.load', onStyleLoad);

      clearInterval(timer);
    };
  }, [mapInstance, isNewMapsPage]);

  return {
    didMapLoadEventsFire: isMapLoaded && isMapStyleLoaded
  };
}
