type Props = {
  action: () => void;
  containerClass: string;
  altName?: string;
  innerClass?: string;
  srcIcon?: string;
};

export default function PhotosTab({ srcIcon, action, altName, containerClass, innerClass }: Props) {
  return (
    <li className={containerClass} onClick={action}>
      <img alt={altName} className={innerClass} src={srcIcon} />
    </li>
  );
}
