// extracted by mini-css-extract-plugin
export var backgroundImage = "TrailDetailsCard-module__backgroundImage___Q2fE1";
export var badgeContainer = "TrailDetailsCard-module__badgeContainer___w1KjY";
export var card = "TrailDetailsCard-module__card___JBbif";
export var clickable = "TrailDetailsCard-module__clickable___zOWO5";
export var content = "TrailDetailsCard-module__content___rO6ek";
export var difficultyAndRatingContainer = "TrailDetailsCard-module__difficultyAndRatingContainer___Q3O5A";
export var favorite = "TrailDetailsCard-module__favorite___uOAwW";
export var infoLocationAndBookmark = "TrailDetailsCard-module__infoLocationAndBookmark___Hbfwa";
export var location = "TrailDetailsCard-module__location___xmmiJ";
export var name = "TrailDetailsCard-module__name___fVfrR";