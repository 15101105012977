import Sign from '@alltrails/shared/illustrations/Sign';
import Button from '@alltrails/shared/denali/components/Button';
import Typography from '@alltrails/shared/denali/components/Typography';
import { FormattedMessage } from '@alltrails/shared/react-intl';

import * as styles from './errors.module.scss';

// https://www.figma.com/file/XrlDM2ZdWXmlnl7ZXUicGn/Rebrand%E2%80%93Press%2FAbout%2F404?node-id=1852%3A12086&t=GWwcYGHiwn1QTjiA-4
const FiveHundredHeader = () => (
  <div className={styles.container}>
    <Sign />
    <div className={styles.error}>
      <Typography component="div" variant="heading500">
        <FormattedMessage defaultMessage="500" />
      </Typography>
    </div>
    <div className={styles.header}>
      <Typography component="div" variant="heading600">
        <FormattedMessage defaultMessage="Looks like we went off the trail" />
      </Typography>
    </div>
    <div className={styles.textContainer}>
      <div className={styles.text}>
        <Typography component="div" variant="paragraph300">
          <FormattedMessage defaultMessage="There seems to be a problem with our server. We're working hard to resolve the issue and should be back to normal soon." />
        </Typography>
      </div>
    </div>
    <div className={styles.buttonContainer}>
      <Button
        text={<FormattedMessage defaultMessage="Find your next trail" />}
        linkInfo={{ href: '/explore' }}
        type="button"
        size="md"
        testId="FiveHundred-explore"
        variant="primary"
      />
    </div>
  </div>
);

export default FiveHundredHeader;
