import { useEffect, useRef } from 'react';
import { object, string, number, bool } from 'prop-types';

import { GridUtils } from './GridUtils';

const GridAxes = ({ bounds, grid, mapZoom, paperSize, orientation, paperDimensionWidth, paperDimensionHeight, isMobileWidth, intl }) => {
  const gridRef = useRef();

  useEffect(() => {
    GridUtils.createLngLatSvgs(bounds, grid, mapZoom, gridRef, null, intl);
  }, [bounds, grid, mapZoom, paperSize, orientation, paperDimensionWidth, paperDimensionHeight, isMobileWidth]);

  return <div data-testid="grid-axes" id="pdf-grid-axes" ref={gridRef} className="pdfGridAxes" />;
};

GridAxes.propTypes = {
  bounds: object,
  grid: string,
  mapZoom: number,
  paperSize: string,
  orientation: string,
  paperDimensionWidth: number,
  paperDimensionHeight: number,
  isMobileWidth: bool
};

GridAxes.defaultProps = {
  bounds: {},
  grid: '',
  mapZoom: 0,
  paperSize: '',
  orientation: '',
  paperDimensionWidth: 0,
  paperDimensionHeight: 0,
  isMobileWidth: false
};

export { GridAxes };
