import { useEffect } from 'react';
import CarouselDisplayTrigger from '@alltrails/analytics/enums/CarouselDisplayTrigger';
import { useFlyoverSettings } from 'components/MapProvider';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import { wrapUrlSafe } from 'utils/language_support_util';
import { FormattedMessage } from '@alltrails/shared/react-intl';
import SignupModal from 'components/SignupModal';
import useUser from 'hooks/useUser';
import * as styles from './FlyoverPreviewUpsellModal.module.scss';
import PreviewUpsellModal from './PreviewUpsellModal';

type Props = {
  disable3D: () => void;
  exactExplorePath: string;
  initFlyoverOnLoad?: boolean;
  onFlyoverDisabled: () => void;
  onSuccess: () => void;
  setLayer: (layer: string) => void;
};

// https://www.figma.com/design/PtYSSpohQ9rL7ZWCcdsYJK/2025-Upsell-System-%E2%80%A2-Handoff?node-id=1881-96486&m=dev
export default function FlyoverPreviewUpsellModal({ disable3D, exactExplorePath, initFlyoverOnLoad, onFlyoverDisabled, onSuccess, setLayer }: Props) {
  const languageRegionCode = useLanguageRegionCode();
  const { exitFlyover, isPaused, togglePauseFlyover } = useFlyoverSettings();
  const user = useUser();

  useEffect(() => {
    if (!isPaused) {
      togglePauseFlyover();
    }
  }, [isPaused, togglePauseFlyover]);

  const flyoverParam = 'initFlyover=true';
  const flyoverHref = exactExplorePath.indexOf('?') === -1 ? `${exactExplorePath}?${flyoverParam}` : `${exactExplorePath}&${flyoverParam}`;

  return (
    <div className={styles.upsell}>
      {user && !user.pro ? (
        <PreviewUpsellModal
          headingNonPromo={<FormattedMessage defaultMessage="Immerse yourself in a 3D preview of any trail" />}
          headingPromo={<FormattedMessage defaultMessage="Try Trail Preview and more for free" />}
          onCloseRequest={() => {
            if (initFlyoverOnLoad) {
              global.history.back();
            } else {
              onFlyoverDisabled();
              exitFlyover(disable3D, setLayer);
            }
          }}
          returnTo={wrapUrlSafe(flyoverHref, languageRegionCode)}
          trigger={CarouselDisplayTrigger.Flyover}
        />
      ) : (
        <SignupModal
          isOpen
          onRequestClose={() => {
            if (initFlyoverOnLoad) {
              global.history.back();
            } else {
              onFlyoverDisabled();
              exitFlyover(disable3D, setLayer);
            }
          }}
          onSuccess={user => {
            if (user.pro) {
              onSuccess();
            }
          }}
          trigger={CarouselDisplayTrigger.Flyover}
        />
      )}
    </div>
  );
}
