import React, { useCallback, useEffect, useState, useRef } from 'react';
import AlertDialog from '@alltrails/shared/denali/components/AlertDialog';
import Error from '@alltrails/shared/icons/Error';
import { AttributeMap } from '../../../../../utils/attributeUtils';
import { Collection } from '../useCollections';
import * as styles from './styles/styles.module.scss';

type FeaturesAlertDialogProps = {
  selectedCollectionMappings: Collection[];
  lastRemovedFeature: AttributeMap | null;
  setSelectedFeatures: () => void;
  updateCollectionMappings: ({ id }: { id: string }) => void;
};

const FeaturesAlertDialog = ({
  selectedCollectionMappings,
  lastRemovedFeature,
  setSelectedFeatures,
  updateCollectionMappings
}: FeaturesAlertDialogProps) => {
  const [showAlert, setShowAlert] = useState(false);
  const [sharedCollection, setSharedCollection] = useState<Collection>();
  const selectedCollectionMappingsRef = useRef(selectedCollectionMappings);
  const isRemovingFeature = useRef(false);

  const cancel = useCallback(() => {
    setShowAlert(false);
  }, []);

  const closeDialog = useCallback(() => {
    if (showAlert && !isRemovingFeature.current) {
      setSelectedFeatures();
    }
    cancel();
  }, [showAlert, setSelectedFeatures, cancel]);

  const removeCollection = () => {
    isRemovingFeature.current = true;
    updateCollectionMappings({ id: sharedCollection.uid });
    closeDialog();
  };

  useEffect(() => {
    isRemovingFeature.current = false;
  }, [showAlert]);

  useEffect(() => {
    selectedCollectionMappingsRef.current = selectedCollectionMappings;
  }, [selectedCollectionMappings]);

  useEffect(() => {
    if (!lastRemovedFeature) {
      return;
    }
    const shared = selectedCollectionMappingsRef?.current.find((mapping: Collection) =>
      Object.keys(lastRemovedFeature).includes(mapping.tagAssociation)
    );
    if (!shared) {
      return;
    }
    setSharedCollection(shared);
    setShowAlert(true);
  }, [lastRemovedFeature]);

  const sharedFeature = lastRemovedFeature?.[sharedCollection?.tagAssociation]?.name;
  const dialogBody = `You are removing the feature tag ${sharedFeature}.
  This will also remove the collection tag ${sharedCollection?.name}?
  Note: It may take a few days to see collection tag changes reflected in contribute.`;

  if (showAlert) {
    return (
      <AlertDialog
        body={dialogBody}
        buttons={{
          primary: {
            onClick: removeCollection,
            testId: 'remove-collection',
            text: 'Remove both tags'
          },
          flat: {
            onClick: cancel,
            testId: 'cancel',
            text: 'Cancel'
          }
        }}
        className={styles.column}
        icon={{ Component: Error }}
        onRequestClose={closeDialog}
        size="md"
        title="Trail within a collection"
      />
    );
  }

  return null;
};

export default FeaturesAlertDialog;
