import { useState } from 'react';
import { defineMessages } from '@alltrails/shared/react-intl';
import Button from '@alltrails/shared/denali/components/Button';
import useFiltersContext from 'hooks/useFiltersContext';
import useFormatMessage from '@alltrails/shared/hooks/useFormatMessage';
import SaveFiltersModal from '../SaveFiltersModal';

const messages = defineMessages({
  saveFilters: {
    defaultMessage: 'Save filters'
  }
});

export default function SaveFilters() {
  const {
    formattedDefaultMessages: { saveFilters }
  } = useFormatMessage(messages);

  const [showSaveFiltersModal, setShowSaveFiltersModal] = useState(false);

  const { hasSaveableFilters } = useFiltersContext();

  return (
    <>
      <Button
        text={saveFilters}
        disabled={!hasSaveableFilters}
        onClick={() => {
          setShowSaveFiltersModal(true);
        }}
        size="sm"
        testId="save-filters"
        variant="primary"
      />
      <SaveFiltersModal
        isOpen={showSaveFiltersModal}
        onRequestClose={() => setShowSaveFiltersModal(false)}
        onSave={() => setShowSaveFiltersModal(false)}
      />
    </>
  );
}
