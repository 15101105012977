import { FormattedMessage } from '@alltrails/shared/react-intl';
import Button from '@alltrails/shared/denali/components/Button';
import LoadingSpinner from '../../../../components/shared/LoadingSpinner';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const createReactClass = require('create-react-class');

const ShareModalDrawerQRCode = createReactClass({
  getInitialState() {
    return {
      loading: true
    };
  },
  downloadQRCode() {
    const qrcodeSrc = this.formatQRCodeSrc(true);
    // To set the name of the downloaded file we need to use this fetch-blob-a-click method
    fetch(qrcodeSrc).then(response => {
      response.blob().then(blob => {
        const a = document.createElement('a');
        a.href = window.URL.createObjectURL(blob);
        a.download = `${this.props.shareObject.name}.png`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      });
    });
  },
  formatQRCodeSrc(isDownloadRequest = false) {
    const urlBase = `${location.protocol}//${location.host}`;
    const params = new URLSearchParams();

    if (isDownloadRequest) {
      params.set('disposition', 'attachment');
    }

    if (this.props.referralCode) {
      params.set('referral_code', this.props.referralCode);
    }

    return `${urlBase}/qrcode/${this.props.formatViewPath()}?${params.toString()}`;
  },
  onQRCodeImageLoad() {
    this.setState({ loading: false });
  },
  render() {
    const qrcodeSrc = this.formatQRCodeSrc();
    return (
      <div className="form-holder">
        <div style={{ display: this.state.loading ? 'block' : 'none' }} className="qrcode-placeholder">
          <LoadingSpinner />
        </div>
        <img
          alt={this.props.intl.formatMessage({ defaultMessage: '{value} QR code' }, { value: this.props.shareObject.name })}
          style={{ display: this.state.loading ? 'none' : 'block' }}
          className="qrcode"
          src={qrcodeSrc}
          onLoad={this.onQRCodeImageLoad}
          data-testid="share-qr-code-image"
        />
        <p>
          <FormattedMessage defaultMessage="Download this QR code to quickly link others to the important information on this page. QR codes can be read by any device with the appropriate software. Use the smart phone or tablet to read the QR code." />
        </p>
        <div className="share-buttons">
          <Button
            text={<FormattedMessage defaultMessage="Download QR code" />}
            type="button"
            disabled={this.state.loading}
            size="md"
            onClick={this.downloadQRCode}
            variant="primary"
            testId="qr-download-share"
          />
        </div>
      </div>
    );
  }
});

// eslint-disable-next-line import/prefer-default-export
export { ShareModalDrawerQRCode };
