import { number, object } from 'prop-types';
import { useEffect, useRef } from 'react';

import { D3Utils } from './d3Utils';

const clearDeclinationScale = declinationScaleRef => {
  const declinationScaleContainer = declinationScaleRef?.current;
  if (declinationScaleContainer) {
    declinationScaleContainer.innerHTML = '';
  }
};

const renderDeclinationScale = (declination, context, declinationScaleRef) => {
  clearDeclinationScale(declinationScaleRef);
  const padding = 20;
  const width = 40;
  const height = 60;
  const xOffset = 15;
  const posOrNeg = declination < 0 ? -1 : 1;

  const today = new Date();
  const date = new Intl.DateTimeFormat(context.languageRegionCode).format(today);

  const declinationScaleContainer = declinationScaleRef.current;

  const chart = D3Utils.appendSvg(declinationScaleContainer, width + 2 * padding, height + 2 * padding);
  const axisGroup = chart.append('svg:g').attr('transform', `translate(${padding - xOffset * posOrNeg},${padding})`);
  const halfWidth = width / 2;

  const center = Math.round(halfWidth) + 0.5;

  // appending north line on the distance scale
  D3Utils.appendSvgLine(axisGroup, center, height, 'black', 'true-north');
  // appending magnetic north line on the distance scale
  D3Utils.appendSvgLine(axisGroup, center, height, 'black', 'magnetic-north', 'transform', `rotate(${declination},${width},${height})`);

  // appending arrow to the magnetic north line
  axisGroup
    .append('svg:path')
    .attr('d', `M ${halfWidth + 0.5} 0 L ${halfWidth + 0.5} 6 L ${halfWidth + 3 * posOrNeg + 0.5} 6 L ${halfWidth + 0.5}  0`)
    .attr('fill', 'black')
    .attr('class', 'arrow')
    .attr('transform', `rotate(${declination},${center},${height})`);

  // appending polygon star to the north line
  axisGroup
    .append('svg:polygon')
    .attr('points', '100,10 40,180 190,60 10,60 160,180')
    .attr('fill', 'black')
    .attr('class', 'star')
    .attr('transform', `translate(${halfWidth - 4.5},-10)scale(0.05)`);

  if (Math.abs(declination) > 10) {
    axisGroup
      .append('svg:text')
      .text('MN')
      .attr('text-anchor', 'middle')
      .attr('font-size', '6pt')
      .attr('x', halfWidth)
      .attr('y', 0)
      .attr('dy', -3)
      .attr('dx', 3 * posOrNeg)
      .attr('transform', `rotate(${declination},${center},${height})`);
  }

  const startOrEnd = declination < 0 ? 'end' : 'start';
  const westEast = declination < 0 ? 'W' : 'E';

  const declinationScaleFontSize = '8pt';
  axisGroup
    .append('svg:text')
    .text(`${Math.abs(Math.round(declination * 10) / 10)}°${westEast}`)
    .attr('text-anchor', startOrEnd)
    .attr('font-size', declinationScaleFontSize)
    .attr('x', halfWidth + (height / 2) * Math.tan((declination * Math.PI) / 180.0))
    .attr('y', height - (height / 2) * Math.cos((declination * Math.PI) / 180.0))
    .attr('dx', 5 * posOrNeg)
    .attr('dy', 5)
    .attr('id', 'pdf-declination-text');

  axisGroup
    .append('svg:text')
    .text(`${date}`)
    .attr('text-anchor', 'start')
    .attr('font-size', declinationScaleFontSize)
    .attr('x', -8)
    .attr('y', height + 5)
    .attr('dy', 10)
    .attr('id', 'pdf-declination-date');
};

const DeclinationScale = ({ declination, context }) => {
  const declinationScaleRef = useRef();

  useEffect(() => {
    renderDeclinationScale(declination, context, declinationScaleRef);
  }, [declination, context]);

  return <div data-testid="declination-scale" className="declinationScale" ref={declinationScaleRef} />;
};

DeclinationScale.propTypes = {
  declination: number,
  context: object
};

DeclinationScale.defaultProps = {
  declination: 0,
  context: {}
};

export { DeclinationScale };
