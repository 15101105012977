import { MutableRefObject, useCallback, useMemo, useRef, useState } from 'react';
import { MapMouseEvent, MapTouchEvent, MapWheelEvent } from 'mapbox-gl';
import { debounce } from 'debounce';
import logFlyoverMapInteractionStarted from '@alltrails/analytics/events/logFlyoverMapInteractionStarted';
import logFlyoverMapInteractionEnded from '@alltrails/analytics/events/logFlyoverMapInteractionEnded';
import FlyoverSource from '@alltrails/analytics/enums/FlyoverSource';
import useStateRef from 'hooks/useStateRef';
import FlyoverMapEventHandlers from 'types/FlyoverMapEventHandlers';
import Key from 'types/Key';
import type { TrailId } from '@alltrails/shared/types/trail';
import { MapboxMapShim } from 'types/Map';

export const INTERACTION_DEBOUNCE_TIMEOUT = 300;

type FlyoverControls = {
  isPaused: boolean;
  isPausedRef: MutableRefObject<boolean>;
  shouldHideControls: boolean;
  hideControlsTimer: MutableRefObject<number>;
  shouldResetHideControlsTimer: MutableRefObject<boolean>;
  pauseFlyover: () => void;
  playFlyover: () => void;
  togglePauseFlyover: (onPause: () => void, onPlay: () => void) => void;
  showControls: () => void;
  hideControls: () => void;
  isMouseDown: MutableRefObject<boolean>;
  isTouching: MutableRefObject<boolean>;
  /**
   * an intermediate set of functions that optionally require extra functionality via parameters.
   */
  interstitialEventHandlers: {
    keyDownHandler: (e: KeyboardEvent, map: MapboxMapShim, togglePause?: () => void) => void;
    mouseUpHandler: (e: MapMouseEvent, togglePause?: () => void) => void;
  };
  eventHandlers: FlyoverMapEventHandlers;
};

function getInteractionEventParameters(
  map: MapboxMapShim,
  initOnLoad: boolean,
  trailId: TrailId
): Parameters<typeof logFlyoverMapInteractionStarted>[0] {
  return {
    bearing: map.getBearing(),
    center_lat: map.getCenter().lat,
    center_lng: map.getCenter().lng,
    pitch: map.getPitch(),
    source: initOnLoad ? FlyoverSource.TrailDetails : FlyoverSource.MapDetails,
    trail_id: trailId,
    zoom: map.getZoom()
  };
}

export default function useFlyoverControls(trailId: TrailId, initOnLoad?: boolean): FlyoverControls {
  // event handler info
  const isMouseDown = useRef(false);
  const clickPoint = useRef(null);
  const isTouching = useRef(false);
  const touchPoint = useRef(null);
  const touchPoints = useRef(null);

  const [isPaused, setIsPaused, isPausedRef] = useStateRef(true);
  const [shouldHideControls, setHideControls] = useState(true);
  const hideControlsTimer = useRef<number>(undefined);
  const shouldResetHideControlsTimer = useRef<boolean>(false);

  const debouncedLogInteractionStarted = useMemo(
    () => debounce(logFlyoverMapInteractionStarted, INTERACTION_DEBOUNCE_TIMEOUT, true /* immediate */),
    []
  );
  const debouncedLogInteractionEnded = useMemo(
    () => debounce(logFlyoverMapInteractionEnded, INTERACTION_DEBOUNCE_TIMEOUT, false /* immediate */),
    []
  );

  // --- controls ---

  const togglePauseFlyover = useCallback((onPause: () => void, onPlay: () => void) => (isPausedRef.current ? onPlay() : onPause()), []);

  const pauseFlyover = useCallback(() => {
    setHideControls(false);
    setIsPaused(true);
  }, []);

  const playFlyover = useCallback(() => {
    setIsPaused(false);
  }, []);

  const showControls = () => {
    setHideControls(false);
  };

  const hideControls = () => {
    setHideControls(true);
  };

  // --- event handlers ---

  const mouseDownHandler = useCallback(
    (e: MapMouseEvent) => {
      logFlyoverMapInteractionStarted(getInteractionEventParameters(e.target, initOnLoad, trailId));
      isMouseDown.current = true;
      clickPoint.current = e.point;
    },
    [initOnLoad, trailId]
  );

  const mouseMoveHandler = useCallback(() => {
    setHideControls(false);
    shouldResetHideControlsTimer.current = true;
  }, []);

  const mouseUpHandler = useCallback(
    (e: MapMouseEvent, togglePause?: () => void) => {
      logFlyoverMapInteractionEnded(getInteractionEventParameters(e.target, initOnLoad, trailId));
      if (clickPoint.current && isMouseDown.current) {
        if (clickPoint.current.x === e.point.x && clickPoint.current.y === e.point.y) {
          togglePause();
        } else {
          pauseFlyover();
        }
      }
      clickPoint.current = null;
      isMouseDown.current = false;
    },
    [initOnLoad, pauseFlyover, trailId]
  );

  const wheelHandler = useCallback(
    (e: MapWheelEvent) => {
      debouncedLogInteractionStarted(getInteractionEventParameters(e.target, initOnLoad, trailId));
      pauseFlyover();
      setHideControls(false);
      debouncedLogInteractionEnded(getInteractionEventParameters(e.target, initOnLoad, trailId));
    },
    [debouncedLogInteractionEnded, debouncedLogInteractionStarted, initOnLoad, pauseFlyover, trailId]
  );

  const touchStartHandler = useCallback(
    (e: MapTouchEvent) => {
      logFlyoverMapInteractionStarted(getInteractionEventParameters(e.target, initOnLoad, trailId));

      if (e.points.length > 1) {
        pauseFlyover();
      }

      isTouching.current = true;
      touchPoint.current = e.point;
      touchPoints.current = e.points;
    },
    [initOnLoad, pauseFlyover, trailId]
  );

  const touchEndHandler = useCallback(
    (e: MapTouchEvent) => {
      logFlyoverMapInteractionEnded(getInteractionEventParameters(e.target, initOnLoad, trailId));

      if (touchPoint.current && isTouching.current) {
        if ((e.points.length === 1 && touchPoint.current.x !== e.point.x && touchPoint.current.y !== e.point.y) || e.points.length > 1) {
          pauseFlyover();
        }
      }
      isTouching.current = false;
      touchPoint.current = null;
      touchPoints.current = null;
    },
    [initOnLoad, pauseFlyover, trailId]
  );

  const keyDownHandler = useCallback(
    (e: KeyboardEvent, map: MapboxMapShim, togglePause?: () => void) => {
      switch (e.code) {
        case Key.ArrowDown:
        case Key.ArrowLeft:
        case Key.ArrowRight:
        case Key.ArrowUp:
        case Key.Equal:
        case Key.Minus:
          debouncedLogInteractionStarted(getInteractionEventParameters(map, initOnLoad, trailId));
          pauseFlyover();
          debouncedLogInteractionEnded(getInteractionEventParameters(map, initOnLoad, trailId));
          break;
        case Key.Space:
          e.preventDefault();
          togglePause();
          break;
        default:
        // do nothing
      }
    },
    [debouncedLogInteractionEnded, debouncedLogInteractionStarted, initOnLoad, pauseFlyover, trailId]
  );

  return {
    // controls state
    isPaused,
    isPausedRef,
    shouldHideControls,
    hideControlsTimer,
    shouldResetHideControlsTimer,
    pauseFlyover,
    playFlyover,
    togglePauseFlyover,
    showControls,
    hideControls,
    // events & event handlers
    isMouseDown,
    isTouching,
    interstitialEventHandlers: {
      keyDownHandler,
      mouseUpHandler
    },
    eventHandlers: {
      onMouseDown: mouseDownHandler,
      onMouseMove: mouseMoveHandler,
      onTouchStart: touchStartHandler,
      onTouchEnd: touchEndHandler,
      onWheel: wheelHandler
    }
  };
}
