import { addSource } from './source_helpers';

const addOrUpdateGeojsonSource = (map, sourceId, geojsonData, sourceOptions = {}) => {
  const source = map.getSource(sourceId);
  if (!source) {
    const sourceSpec = {
      type: 'geojson',
      data: geojsonData,
      ...sourceOptions
    };
    addSource(map, sourceId, sourceSpec);
  } else {
    source.setData(geojsonData);
    map.customStyle.sources.some(s => {
      if (s.sourceId === sourceId) {
        s.sourceSpec.data = geojsonData;
        return true;
      }
      return false;
    });
  }
};

export { addOrUpdateGeojsonSource };
