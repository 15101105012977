import CustomProvider from 'components/CustomProvider';

import FourOFourHeader from 'components/errors/FourOFourHeader';

const FourOFour = () => (
  <CustomProvider>
    <FourOFourHeader />
  </CustomProvider>
);

export default FourOFour;
