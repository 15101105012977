import { useState, lazy, Suspense, useContext } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from '@alltrails/shared/react-intl';
import logTrailDetailsGpxDownloadButtonTapped from '@alltrails/analytics/events/logTrailDetailsGpxDownloadButtonTapped';
import logTrailDetailsOverflowItemSelected from '@alltrails/analytics/events/logTrailDetailsOverflowItemSelected';
import logTrailDetailsPrintClicked from '@alltrails/analytics/events/logTrailDetailsPrintClicked';
import logTrailDownloadButtonTapped from '@alltrails/analytics/events/logTrailDownloadButtonTapped';
import logTrailDetailsAddToCompletedClicked from '@alltrails/analytics/events/logTrailDetailsAddToCompletedClicked';
import ShareSource from '@alltrails/analytics/enums/ShareSource';
import TrailDetailsOverflowMenuOption from '@alltrails/analytics/enums/TrailDetailsOverflowMenuOption';
import useUser from 'hooks/useUser';
import Trail from 'types/Trails/Trail';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import SignupModal from 'components/SignupModal';
import useToggle from '@alltrails/shared/hooks/useToggle';
import useTrailCompleted from 'hooks/useTrailCompleted';
import PlusBadge from '@alltrails/shared/denali/components/PlusBadge';
import ShareObject from 'types/ShareObject';
import { wrapUrlSafe } from 'utils/language_support_util';
import { wrapEventHandler } from 'utils/handlers';
import { copyVerifiedRoute } from 'utils/requests/at_map_requests';
import { modalRoadblock } from '@alltrails/shared/utils/modalFunnelUtils';
import { ServerCommunicationUtil } from 'utils/server_communication_util';
import logError from 'utils/logError';
import PortalModal from 'components/shared/PortalModal';
import CarouselDisplayTrigger from '@alltrails/analytics/enums/CarouselDisplayTrigger';
import { GarminContext } from 'components/GarminSettings/GarminProvider';
import hasPermission from 'utils/hasPermission';
import TrailPageCTAText from '@alltrails/analytics/enums/TrailPageCTAText';
import ProgressDialog from '../../shared/ProgressDialog';
import DownloadRouteModal from '../../DownloadRouteModal';
import * as styles from './TrailMoreAlternateDropDownList.module.scss';

// eslint-disable-next-line import/no-unresolved
const ShareModal = lazy(() => import('react_components/main_action_bar/share_modal'));

type Props = {
  exploreFlag: boolean;
  isMobileWidth: boolean;
  mobileBrowser: boolean;
  pending: boolean;
  trail: Trail;
  printUrl: string;
};

const TrailMoreAlternateDropDownList = ({ exploreFlag, isMobileWidth, mobileBrowser, pending, printUrl, trail }: Props) => {
  const [isOpen, toggle] = useToggle();
  const user = useUser();
  const { connectionId: completedConnectionId, toggleTrailCompleted } = useTrailCompleted(trail.trail_id);
  const languageRegionCode = useLanguageRegionCode();
  const [showShareModal, setShowShareModal] = useState(false);
  const [progressDialogState, setProgressDialogState] = useState();
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [downloadObject, setDownloadObject] = useState<ShareObject>();
  const { canUpdateCourse, handleSendRoute, handleUpdateRoute } = useContext(GarminContext);

  const closeShareModal = () => {
    setShowShareModal(false);
    (document.querySelector('.hide-for-fullscreen-modal') as HTMLElement).style.display = '';
  };

  const handleShareClick = () => {
    setShowShareModal(true);

    const hideForFullScreenModal = document.querySelector('.hide-for-fullscreen-modal') as HTMLElement;

    if (hideForFullScreenModal) {
      hideForFullScreenModal.style.display = 'none';
    }

    return true;
  };

  const handleDeleteTrailClick = () => {
    if (!window.confirm('Are you sure you want to delete this trail?')) {
      return;
    }

    const url = `/api/alltrails/trails/${trail.trail_id}`;

    ServerCommunicationUtil.deleteApiEndpoint(
      url,
      {},
      () => {
        window.location.href = wrapUrlSafe('/explore', languageRegionCode);
      },
      (xhr: any, status: any, error: any) => {
        logError(error);
      }
    );
  };

  const handleEditClick = () => {
    const returnToUrl = window.location.pathname + window.location.search;

    logTrailDetailsOverflowItemSelected({ trail_id: trail.trail_id, overflow_selection: TrailDetailsOverflowMenuOption.SuggestTrailEdit });

    if (!user) {
      modalRoadblock('signup', 'trail-more-edit', returnToUrl, languageRegionCode);
      return false;
    }

    return true;
  };

  const updateProgressDialog = (state: any) => {
    setProgressDialogState(state);
  };

  const renderProgressDialog = () => {
    if (!progressDialogState) {
      return null;
    }

    return (
      <PortalModal isOpen={progressDialogState}>
        <ProgressDialog isMobileWidth={isMobileWidth} variant={progressDialogState} updateProgressDialog={updateProgressDialog} />
      </PortalModal>
    );
  };

  const handleMapSaveError = (err: any) => {
    updateProgressDialog('error');
    logError(err);
    return err;
  };

  const redirectToMapPage = (map: any) => {
    window.top.location.href = wrapUrlSafe(`/explore/map/${map.slug}?save`, languageRegionCode);
  };

  const blockUserNewMapCreation = () => {
    const returnToUrl = window.location.pathname + window.location.search;
    modalRoadblock('signup', 'explore-map-new', returnToUrl, languageRegionCode);
  };

  const saveMapAsClone = () => {
    logTrailDetailsOverflowItemSelected({ trail_id: trail.trail_id, overflow_selection: TrailDetailsOverflowMenuOption.CustomizeMap });

    // Block user with signup if not signed-in
    if (!user) {
      blockUserNewMapCreation();
      return;
    }

    const { trail_id, name } = trail;
    updateProgressDialog('saving');
    copyVerifiedRoute(trail_id, name).then(redirectToMapPage).catch(handleMapSaveError);
  };

  const toggleDownloadModal = () => {
    setShowDownloadModal(!showDownloadModal);
  };

  const openDownloadModal = () => {
    logTrailDownloadButtonTapped({ button_highlighted: false, trail_id: trail.trail_id, cta_text: TrailPageCTAText.Download });

    const publishObject = {
      id: trail.trail_id,
      name: trail.name,
      slug: trail.slug,
      type: 'trail' as const,
      primaryKey: 'trail_id' as const
    };

    if (!user) {
      logTrailDetailsGpxDownloadButtonTapped({ trail_id: trail.trail_id });
      const returnToUrl = window.location.pathname + window.location.search;
      modalRoadblock('signup', 'trail-more-gpx', returnToUrl, languageRegionCode);
    }

    setShowDownloadModal(true);
    setDownloadObject(publishObject);
  };

  // Explore or Mobile
  let printUrlItem = null;
  if (exploreFlag || isMobileWidth) {
    if (!mobileBrowser) {
      printUrlItem = (
        <div className={styles.dropdownContent}>
          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
          <a
            data-testid="trail_more_alternate_print_pdf"
            className={styles.gpxLabel}
            href={printUrl}
            onClick={() => logTrailDetailsPrintClicked({ trail_id: trail.trail_id })}
          >
            <PlusBadge size="md" className={styles.plusBadge} />
            <FormattedMessage defaultMessage="Print/PDF map" />
          </a>
        </div>
      );
    }
  }

  // Requires Signed-in User
  let suggestEditItem = null;
  let addToCompletedItem = null;
  const onAddToCompletedClick = () => {
    toggleTrailCompleted();
    logTrailDetailsOverflowItemSelected({ trail_id: trail.trail_id, overflow_selection: TrailDetailsOverflowMenuOption.AddToCompleted });
    logTrailDetailsAddToCompletedClicked({ trail_id: trail.trail_id });
  };
  if (user) {
    const suggestUrl = wrapUrlSafe(`/${trail.slug}/edit?ref=trail-more-edit`, languageRegionCode);
    // suggest edit display: none for screen sizes under 767 (unless being displayed in trail-explore left panel)
    suggestEditItem = (
      <div className={classNames(styles.dropdownContent, 'suggest', !exploreFlag && 'trail-details')}>
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        <a href={suggestUrl} target="_blank" onClick={handleEditClick} data-testid="trail_more_alternate_suggest_edit">
          <FormattedMessage defaultMessage="Suggest edit" />
        </a>
      </div>
    );
    const label = completedConnectionId ? (
      <FormattedMessage defaultMessage="Remove from completed" />
    ) : (
      <FormattedMessage defaultMessage="Add to completed" />
    );
    addToCompletedItem = (
      <div className={styles.dropdownContent}>
        <button className={styles.button} data-testid="trail_more_alternate_toggle_completed" onClick={onAddToCompletedClick} type="button">
          {label}
        </button>
      </div>
    );
  }

  return (
    <>
      <SignupModal
        trigger={CarouselDisplayTrigger.ShareTrail}
        isOpen={isOpen}
        onRequestClose={() => {
          toggle();
        }}
        onSuccess={() => {
          toggle();
          handleShareClick();
        }}
      />
      <div className={styles.dropdown}>
        {!pending && (
          <div className={styles.dropdownContent}>
            <button
              className={styles.button}
              data-testid="trail_more_alternate_send_to_garmin"
              onClick={canUpdateCourse ? handleUpdateRoute : handleSendRoute}
              type="button"
            >
              <PlusBadge size="md" className={styles.plusBadge} />
              {canUpdateCourse ? <FormattedMessage defaultMessage="Update on Garmin" /> : <FormattedMessage defaultMessage="Send to Garmin" />}
            </button>
          </div>
        )}
        {suggestEditItem}
        {hasPermission({ permission: 'trails:manage' }) && (
          <div className={styles.dropdownContent}>
            <button className={styles.button} data-testid="trail_more_alternate_delete_trail" onClick={handleDeleteTrailClick} type="button">
              Delete Trail
            </button>
          </div>
        )}
        {(exploreFlag || isMobileWidth) && (
          <div className={styles.dropdownContent}>
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <button
              className={styles.button}
              data-testid="trail_more_alternate_share"
              onClick={() => {
                if (!user) {
                  toggle();
                } else {
                  handleShareClick();
                }
              }}
              type="button"
            >
              <FormattedMessage defaultMessage="Share" />
            </button>
          </div>
        )}
        {!pending && (
          <div className={styles.dropdownContent}>
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <button className={styles.button} data-testid="trail_more_alternate_download_route" onClick={openDownloadModal} type="button">
              <FormattedMessage defaultMessage="Download route" />
            </button>
          </div>
        )}
        <div className={styles.dropdownContent}>
          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
          <button
            className={styles.button}
            data-testid="trail_more_alternate_customize_map"
            key="customizeMap"
            onClick={wrapEventHandler(saveMapAsClone)}
            type="button"
          >
            <FormattedMessage defaultMessage="Customize map" />
          </button>
        </div>
        {addToCompletedItem}
        {printUrlItem}
      </div>
      {showShareModal && (
        <Suspense fallback={null}>
          <ShareModal
            handleClose={closeShareModal}
            shareObject={{
              id: trail.trail_id,
              name: trail.name,
              slug: trail.slug,
              type: 'trail' as const,
              primaryKey: 'trail_id' as const,
              source: ShareSource.Trail
            }}
            currentUser={user}
          />
        </Suspense>
      )}
      {renderProgressDialog()}
      {user && showDownloadModal && <DownloadRouteModal toggleDownloadModal={toggleDownloadModal} trailSlug={downloadObject?.slug} />}
    </>
  );
};

export default TrailMoreAlternateDropDownList;
