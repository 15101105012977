import { featureCollection, multiLineString } from '@turf/helpers';
import { addPolylines } from './polylines';
import { addChevrons } from './chevrons';
import { addLifelineStartEnd } from './lifeline_start_end';
import { addMarkers } from './markers';
import { decodeNDim } from '../../at_map_helpers';
import { putAtMapIntoBackground } from './at_map';
import { MSG_GREEN, MSG_RED, MSG_YELLOW } from 'utils/constants/MarkerIds';

const lifelineMapId = 'lifeline';
const MSG_LEVEL_POSITIVE = 1;
const MSG_LEVEL_DISTRESS = 3;

const chooseColor = level => {
  switch (level) {
    case MSG_LEVEL_POSITIVE:
      return MSG_GREEN;
    case MSG_LEVEL_DISTRESS:
      return MSG_RED;
    default:
      return MSG_YELLOW;
  }
};

const formatLifelineMessages = (messages, timezone) => {
  const colors = new Set();
  const waypoints = messages.map(({ id, latitude, longitude, content, level, queuedAt, sentAt }) => {
    const color = chooseColor(level);
    colors.add(color);
    const date = queuedAt || sentAt;

    return {
      date,
      id,
      location: { latitude, longitude },
      timezone,
      name: content,
      description: '',
      iconImage: `map-marker-${color}`
    };
  });

  return [Array.from(colors), waypoints];
};

const addLifeline = (map, atMap, renderWaypointPopup, bounds = null, lifeline) => {
  const {
    currentPolylines,
    id,
    messages,
    metadata: { timezoneIdentifier }
  } = lifeline;

  const multiCoordinates = currentPolylines.map(pl => {
    return decodeNDim(pl).map(coord => [coord[1], coord[0]]);
  });
  const features = [multiLineString(multiCoordinates, { color: 'red' })];
  const geoJson = featureCollection(features);
  const [colors, waypoints] = formatLifelineMessages(messages, timezoneIdentifier);
  addPolylines(map, `${lifelineMapId}-polylines`, geoJson);
  addChevrons(map, `${lifelineMapId}-chevrons`, `${lifelineMapId}-polylines`);
  addLifelineStartEnd(map, `${lifelineMapId}-start-end`, multiCoordinates, lifeline);
  addMarkers(map, `${lifelineMapId}-waypoints`, waypoints, colors, renderWaypointPopup, bounds);
  putAtMapIntoBackground(map);
};

export { addLifeline };
