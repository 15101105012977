import { lazy, ReactNode, Suspense, useCallback, useEffect, useState } from 'react';
import logActivityCardClicked from '@alltrails/analytics/events/logActivityCardClicked';
import logTrailDetailsSeeAllClicked from '@alltrails/analytics/events/logTrailDetailsSeeAllClicked';
import logTrailDetailsSortChanged from '@alltrails/analytics/events/logTrailDetailsSortChanged';
import CardLocation from '@alltrails/analytics/enums/CardLocation';
import TrailDetailsSeeAllLocation from '@alltrails/analytics/enums/TrailDetailsSeeAllLocation';
import TrailDetailsSortLocation from '@alltrails/analytics/enums/TrailDetailsSortLocation';
import { SortOption, TrailReviews } from '@alltrails/modules/TrailReviews/types';
import TrailReviewsPage from '@alltrails/modules/TrailReviews/TrailReviewsPage';
import type Review from '@alltrails/shared/types/review';
import type { User } from 'types/User';
import type { Context } from 'types/Context';
import useToggle from '@alltrails/shared/hooks/useToggle';
import type { ReviewFormModalProps } from '@alltrails/modules/TrailReviews/ReviewFormModal';
import CustomProvider from 'components/CustomProvider';
import { useTrailUgcContext } from 'components/trailUgc/TrailUgcContext';
import CarouselDisplayTrigger from '@alltrails/analytics/enums/CarouselDisplayTrigger';
import ReportLocation from '@alltrails/analytics/enums/ReportLocation';
import useFeatures from 'hooks/useFeatures';
import useUgcContentState, { UgcContentProps } from '../hooks/useUgcContentState';
import RatingsBreakdown from '../types/RatingsBreakdown';
import { getAnalyticsSort } from '../utils/trailUgcAnalyticsHelpers';

const ReviewFormModal = lazy(() => import('@alltrails/modules/TrailReviews/ReviewFormModal'));
const NewReviewFormModal = lazy(() => import('@alltrails/modules/TrailReviews/NewReviewFormModal'));
const SignupModal = lazy(() => import('components/SignupModal'));

type ReviewsUgcProps = UgcContentProps & {
  ads?: ReactNode[];
  baseUrl: string;
  includeTrailName: boolean;
  locationServerData: any;
  ratingsBreakdown: RatingsBreakdown;
  avgRating: string;
  showContributeButton: boolean;
  trailName: string;
  popTrailReview?: boolean;
  context?: Context;
  reviewSummary?: string;
};

const ReviewsUgc = ({
  ads,
  context,
  baseUrl,
  includeTrailName,
  locationServerData,
  perPage = 30,
  popTrailReview,
  ratingsBreakdown,
  avgRating,
  showContributeButton,
  trailId,
  trailName,
  userId,
  reviewSummary
}: ReviewsUgcProps): JSX.Element => {
  const {
    ugcState: {
      reviews: { items, total, pageCount }
    },
    dispatch
  } = useTrailUgcContext();
  const [reviewFormProps, setReviewFormProps] = useState<ReviewFormModalProps>(null);
  const [signupModalIsOpen, toggleSignupModal] = useToggle(false);
  const features = useFeatures();
  const areReviews2Enabled = features.includes('reviews_v2');

  const addReview = (data: { detail: Review }) => {
    dispatch({ type: 'ADD_REVIEW', review: data.detail });
  };

  const updateReview = (data: { detail: Review }) => {
    dispatch({ type: 'UPDATE_REVIEW', review: data.detail });
  };

  const deleteReview = (data: Review) => {
    dispatch({ type: 'DELETE_REVIEW', review: data });
  };

  const handleMoreReviewsLoaded = useCallback(
    (data: any) => {
      dispatch({ type: 'LOAD_MORE_REVIEWS', reviews: data.trail_reviews });
    },
    [dispatch]
  );

  const { formOpen, openForm, closeForm, renderUgcBar } = useUgcContentState<Review>({
    items,
    activeTab: 'reviews',
    pageCount,
    maxItems: total,
    locationServerData,
    userId,
    perPage,
    handleMoreLoaded: handleMoreReviewsLoaded,
    trailId,
    sortConfig: {
      availableSorts: ['atSort', 'desc', 'asc', 'highestRated', 'lowestRated'],
      onSortSuccess: (data: any, newSort: SortOption) => dispatch({ type: 'SORT_REVIEWS', payload: { reviews: data.trail_reviews, newSort } }),
      sortUrl: `${baseUrl}/reviews/`
    } /* sortConfig */,
    ratingsBreakdown,
    showContributeButton,
    avgRating
  });

  const onOpenTrailReviewFormModal = useCallback(
    (props?: ReviewFormModalProps) => {
      setReviewFormProps(props);
      openForm();
    },
    [openForm]
  );

  // only open trail review once in lifecycle
  useEffect(() => {
    if (popTrailReview) {
      openForm();
    }
  }, []);

  const onSortChange = (oldSort: SortOption, newSort: SortOption) => {
    logTrailDetailsSortChanged({
      trail_id: trailId,
      previous_sort_method: getAnalyticsSort(oldSort),
      sort_method_selected: getAnalyticsSort(newSort),
      sort_location: TrailDetailsSortLocation.ReviewsTab
    });
  };

  const onSortSuccess = (data: TrailReviews, newSort: SortOption) => {
    dispatch({ type: 'SORT_REVIEWS', payload: { reviews: data.trail_reviews, newSort } });
  };

  const onShowMore = (data: TrailReviews) => {
    logTrailDetailsSeeAllClicked({ trail_id: trailId, location: TrailDetailsSeeAllLocation.Reviews });

    if (!userId) {
      toggleSignupModal();
    } else {
      handleMoreReviewsLoaded(data);
      dispatch({ type: 'SET_ALL_UGC_SHOWN', allShown: items?.length > total });
    }
  };

  const onActivityLinkClick = (review: Review) => {
    logActivityCardClicked({
      detailed_card_location: CardLocation.TrailDetails,
      map_id: String(review.associatedRecording?.id),
      owner_id: String(review.user.id)
    });
  };

  const renderReviewSummary = (summary: string) => (
    <div style={{ backgroundColor: '#FAFAFA', padding: '8px', lineHeight: '1.3', whiteSpace: 'pre-line' }}>
      <strong>AllTrails GPT Summary:</strong> {summary}
    </div>
  );

  return (
    <CustomProvider>
      {signupModalIsOpen && (
        <Suspense fallback={null}>
          <SignupModal
            trigger={CarouselDisplayTrigger.SeeMoreReviews}
            isOpen={signupModalIsOpen}
            onRequestClose={toggleSignupModal}
            onSuccess={toggleSignupModal}
          />
        </Suspense>
      )}
      {renderUgcBar({ openTrailFormModal: onOpenTrailReviewFormModal })}
      {reviewSummary && renderReviewSummary(reviewSummary)}
      <TrailReviewsPage
        ads={ads}
        context={context}
        reviews={items}
        baseUrl={baseUrl}
        total={total}
        perPage={perPage}
        pageCount={pageCount}
        includeTrailName={includeTrailName}
        onDeleteReview={deleteReview}
        openReviewFormModal={onOpenTrailReviewFormModal}
        onUpdateReview={updateReview}
        onSortChange={onSortChange}
        onSortSuccess={onSortSuccess}
        onShowMore={onShowMore}
        onActivityLinkClick={onActivityLinkClick}
        localizeOnLoad
        analyticsReportLocation={ReportLocation.TrailDetails}
        features={features}
      />
      {formOpen && (
        <Suspense fallback={null}>
          {areReviews2Enabled ? (
            <NewReviewFormModal
              closeReviewModal={closeForm}
              trailId={trailId}
              onReviewAdd={addReview}
              updateReview={updateReview}
              user={{ id: userId } as User}
              {...reviewFormProps}
            />
          ) : (
            <ReviewFormModal
              closeReviewModal={closeForm}
              multistep
              trailId={trailId}
              trailName={trailName}
              onReviewAdd={addReview}
              updateReview={updateReview}
              user={{ id: userId } as User}
              {...reviewFormProps}
            />
          )}
        </Suspense>
      )}
    </CustomProvider>
  );
};

export default ReviewsUgc;
