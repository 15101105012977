import { Marker } from 'mapbox-gl';
import logError from 'utils/logError';
import { loadAndAddImage } from '../image_helpers';
import currentImageSrc from '../../../../assets/images/icons/maps/markers/map-marker-current@2x.png';
import startImageSrc from '../../../../assets/images/icons/maps/markers/map-marker-start@2x.png';
import endImageSrc from '../../../../assets/images/icons/maps/markers/map-marker-end@2x.png';
import startEndImageSrc from '../../../../assets/images/icons/maps/markers/map-marker-start-end@2x.png';
import editStartEndImageSrc from '../../../../assets/images/icons/maps/markers/map-marker-edit-start-end@2x.png';
import editStartImageSrc from '../../../../assets/images/icons/maps/markers/map-marker-edit-start@2x.png';
import editEndImageSrc from '../../../../assets/images/icons/maps/markers/map-marker-edit-end@2x.png';
import nodeImageSrc from '../../../../assets/images/icons/maps/markers/map-marker-node@2x.png';
import plusImageSrc from '../../../../assets/images/icons/maps/markers/map-marker-plus@2x.png';
import yellowImageSrc from '../../../../assets/images/icons/maps/markers/map-marker-yellow@2x.png';
import redImageSrc from '../../../../assets/images/icons/maps/markers/map-marker-red@2x.png';
import purpleImageSrc from '../../../../assets/images/icons/maps/markers/map-marker-purple@2x.png';
import msgGreenImageSrc from '../../../../assets/images/icons/maps/markers/map-marker-msg-green@2x.png';
import msgRedImageSrc from '../../../../assets/images/icons/maps/markers/map-marker-msg-red@2x.png';
import msgYellowImageSrc from '../../../../assets/images/icons/maps/markers/map-marker-msg-yellow@2x.png';
import { CURRENT, END, MSG_GREEN, MSG_RED, MSG_YELLOW, NODE, PLUS, PURPLE, RED, START, START_END, EDIT_START, EDIT_END, EDIT_START_END, YELLOW } from 'utils/constants/MarkerIds';

const mapMarkerImageId = color => {
  return `map-marker-${color}`;
};

function getMarkerImageSrc(id) {
  switch (id) {
    case CURRENT:
      return currentImageSrc;
    case START:
      return startImageSrc;
    case EDIT_START:
      return editStartImageSrc;
    case END:
      return endImageSrc;
    case EDIT_END:
      return editEndImageSrc;
    case START_END:
      return startEndImageSrc;
    case EDIT_START_END:
      return editStartEndImageSrc;
    case NODE:
      return nodeImageSrc;
    case PLUS:
      return plusImageSrc;
    case YELLOW:
      return yellowImageSrc;
    case RED:
      return redImageSrc;
    case PURPLE:
      return purpleImageSrc;
    case MSG_GREEN:
      return msgGreenImageSrc;
    case MSG_RED:
      return msgRedImageSrc;
    case MSG_YELLOW:
      return msgYellowImageSrc;
    default:
      throw Error(`missing marker src for id '${id}'`);
  }
}

const mapMarkerDiv = id => {
  const div = document.createElement('div');
  div.style.backgroundImage = `url("${getMarkerImageSrc(id)}")`;
  div.style.backgroundSize = 'contain';
  if ([START, END].includes(id)) {
    div.style.height = '40px';
    div.style.width = '40px';
  } else {
    div.style.height = '30px';
    div.style.width = '30px';
  }
  return div;
};

const createDraggableMarker = id => {
  const element = mapMarkerDiv(id);
  return new Marker({
    element,
    draggable: true
  });
};

const addMarkerImagesToMap = (map, id) => {
  const imageId = mapMarkerImageId(id);
  const imageSrc = getMarkerImageSrc(id);
  return loadAndAddImage(map, imageId, imageSrc, {
    pixelRatio: 2
  }).catch(logError);
};

const createMarkerLayer = (id, sourceId, markerId) => {
  return {
    id,
    type: 'symbol',
    source: sourceId,
    layout: {
      'icon-allow-overlap': true,
      'icon-ignore-placement': true,
      'icon-anchor': ['coalesce', ['get', 'iconAnchor'], 'center'],
      'icon-image': ['image', ['coalesce', ['get', 'iconImage'], mapMarkerImageId(markerId)]],
      'symbol-z-order': 'source'
    },
    paint: {
      'icon-opacity': 1
    }
  };
};

export { addMarkerImagesToMap, createMarkerLayer, mapMarkerImageId, getMarkerImageSrc, mapMarkerDiv, createDraggableMarker };
