import { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ChevronSm from '@alltrails/shared/icons/ChevronSm';
import * as styles from './styles/styles.module.scss';

const SelectDropdown = ({ name, handleChange, options, defaultItem, isMobile, slim, materialRootClass, className, nullable, ariaLabel }) => {
  const [isOpen, toggleOpen] = useState(false);
  const root = isOpen ? styles.selectFocused : styles.selectRoot;
  const icon = classNames(styles.icon, { [styles.activeIcon]: isOpen });

  const chevron = props => (
    <div className={icon} {...props}>
      <ChevronSm orientation="down" />
    </div>
  );

  return (
    <Select
      name={name}
      aria-label={ariaLabel}
      className={className}
      native={isMobile}
      disableUnderline
      classes={{ root: classNames(root, materialRootClass, { [styles.slim]: slim }), icon }}
      value={defaultItem.value}
      IconComponent={chevron}
      onChange={handleChange}
      onOpen={() => toggleOpen(true)}
      onClose={() => toggleOpen(false)}
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left'
        },
        transformOrigin: {
          vertical: -8,
          horizontal: 'left'
        },
        MenuListProps: {
          classes: { root: styles.menuRoot }
        },
        getContentAnchorEl: null
      }}
    >
      {nullable && <MenuItem value="">&nbsp;</MenuItem>}
      {options.map(option => {
        const Item = isMobile ? 'option' : MenuItem;
        return (
          <Item classes={{ root: styles.menuItemRoot }} key={option.value} value={option.value}>
            {option.label}
          </Item>
        );
      })}
    </Select>
  );
};

SelectDropdown.propTypes = {
  handleChange: PropTypes.func,
  name: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  className: PropTypes.string,
  nullable: PropTypes.bool,
  slim: PropTypes.bool,
  materialRootClass: PropTypes.string,
  defaultItem: PropTypes.object,
  isMobile: PropTypes.bool,
  ariaLabel: PropTypes.string
};

SelectDropdown.defaultProps = {
  handleChange: () => {},
  options: [],
  name: '',
  defaultItem: {
    value: '',
    label: ''
  },
  isMobile: false,
  slim: false,
  materialRootClass: '',
  className: null,
  nullable: false,
  ariaLabel: ''
};

export default SelectDropdown;
