import Track from '@alltrails/shared/types/track';
import type Review from '@alltrails/shared/types/review';
import type { User } from 'types/User';
import SortOption from 'components/trailUgc/types/SortOption';
import TrailUgcTab from 'components/trailUgc/types/TrailUgcTab';
import { Photo } from 'types/Photo';

export type TrailUgcAction =
  | { type: 'ADD_REVIEW'; review: Review }
  | { type: 'UPDATE_REVIEW'; review: Review }
  | { type: 'DELETE_REVIEW'; review: Review }
  | { type: 'ADD_PHOTOS'; photos: Photo[] }
  | { type: 'ADD_TRACK'; track: Track }
  | { type: 'REMOVE_TRACK'; track: Track }
  | { type: 'TOGGLE_COMPLETED'; payload: { currentUser: User; connectionId?: number } }
  | { type: 'LOAD_MORE_REVIEWS'; reviews: Review[] }
  | { type: 'LOAD_MORE_PHOTOS'; photos: Photo[] }
  | { type: 'LOAD_MORE_TRACKS'; tracks: Track[] }
  | { type: 'LOAD_MORE_COMPLETED'; users: User[] }
  | { type: 'SORT_REVIEWS'; payload: { reviews: Review[]; newSort: SortOption } }
  | { type: 'SORT_PHOTOS'; payload: { photos: Photo[]; newSort: SortOption } }
  | { type: 'SORT_TRACKS'; payload: { tracks: Track[]; newSort: SortOption } }
  | { type: 'CHANGE_UGC_TAB'; tab: TrailUgcTab }
  | { type: 'SET_ALL_UGC_SHOWN'; allShown: boolean };

type UgcInfo<T> = {
  items: T[] | null;
  sortOption: SortOption;
  pageCount: number;
  total: number;
};

export type TrailUgcState = {
  reviews: UgcInfo<Review>;
  photos: UgcInfo<Photo>;
  tracks: UgcInfo<Track>;
  completed: Exclude<UgcInfo<User>, 'sortOption'> & { connectionId: number | null };
  selectedUgcTab: TrailUgcTab;
  allUgcShown: boolean;
};

/**
 * Updates a single review in a given array of reviews. If the review is not found, returns the set of original reviews.
 * Always returns a copy of the given review array.
 * @param oldReviews array of reviews to update.
 * @param review the review object with updated properites.
 * @returns a new array of reviews.
 */
function updateReview(oldReviews: Review[], review: Review): Review[] {
  const reviewsCopy = [...oldReviews];
  const position = oldReviews.map(review => review.id).indexOf(review.id);
  if (position >= 0) {
    reviewsCopy[position] = review;
  }
  return reviewsCopy;
}

/**
 * Adds or removes the current user from the completed state depending on the existing state.
 * @param oldCompletedState old completed state
 * @param payload current user object & connection id
 * @returns new completed state
 */
function toggleCompleted(
  oldCompletedState: UgcInfo<User> & { connectionId: number | null },
  payload: { currentUser: User; connectionId?: number }
): UgcInfo<User> & { connectionId: number | null } {
  const newCompletedState = { ...oldCompletedState };
  if (oldCompletedState.connectionId === null && payload.connectionId !== null) {
    // add user to completed
    newCompletedState.items = [...newCompletedState.items, payload.currentUser];
    newCompletedState.total += 1;
  } else if (oldCompletedState !== null && payload.connectionId === null) {
    // remove user from completed
    newCompletedState.items = newCompletedState.items.filter(user => user.id !== payload.currentUser.id);
    newCompletedState.total -= 1;
  }

  newCompletedState.connectionId = payload.connectionId;

  return newCompletedState;
}

/**
 * Joins two arrays [...arr1, ...arr2] and removes any duplicates based on their ID.
 * For now, used specifically for Reviews or Users.
 * @param arr1 first section of array to join
 * @param arr2 second section of array to join
 * @returns a new, joined, unique array
 */
function deDupe<T extends Review | User>(arr1: T[], arr2: T[]): T[] {
  const newData = arr1.concat(arr2);
  const uniqueIds = new Set<number>();
  const newDataUnique: T[] = [];
  for (let i = 0; i < newData.length; i++) {
    if (!uniqueIds.has(newData[i].id)) {
      newDataUnique.push(newData[i]);
      uniqueIds.add(newData[i].id);
    }
  }

  return newDataUnique;
}

export default function reducer(state: TrailUgcState, action: TrailUgcAction): TrailUgcState {
  switch (action.type) {
    case 'ADD_REVIEW':
      return { ...state, reviews: { ...state.reviews, items: [action.review, ...state.reviews.items], total: state.reviews.total + 1 } };
    case 'UPDATE_REVIEW':
      return { ...state, reviews: { ...state.reviews, items: updateReview(state.reviews.items, action.review) } };
    case 'DELETE_REVIEW':
      return {
        ...state,
        reviews: { ...state.reviews, items: state.reviews.items.filter(review => review.id !== action.review.id), total: state.reviews.total - 1 }
      };
    case 'ADD_PHOTOS': {
      const [firstPhoto, ...remainingPhotos] = state.photos.items;
      return {
        ...state,
        photos: { ...state.photos, items: [firstPhoto, ...action.photos, ...remainingPhotos], total: state.photos.total + action.photos.length }
      };
    }
    case 'ADD_TRACK':
      return { ...state, tracks: { ...state.tracks, items: [...state.tracks.items, action.track], total: state.tracks.total + 1 } };
    case 'REMOVE_TRACK':
      return {
        ...state,
        tracks: { ...state.tracks, items: state.tracks.items.filter(track => track.id !== action.track.id), total: state.tracks.total - 1 }
      };
    case 'TOGGLE_COMPLETED':
      return { ...state, completed: { ...state.completed, ...toggleCompleted(state.completed, action.payload) } };
    case 'LOAD_MORE_REVIEWS':
      return { ...state, reviews: { ...state.reviews, items: deDupe(state.reviews.items, action.reviews), pageCount: state.reviews.pageCount + 1 } };
    case 'LOAD_MORE_PHOTOS':
      return { ...state, photos: { ...state.photos, items: [...state.photos.items, ...action.photos], pageCount: state.photos.pageCount + 1 } };
    case 'LOAD_MORE_TRACKS':
      return { ...state, tracks: { ...state.tracks, items: [...state.tracks.items, ...action.tracks], pageCount: state.tracks.pageCount + 1 } };
    case 'LOAD_MORE_COMPLETED':
      return {
        ...state,
        completed: { ...state.completed, items: deDupe(state.completed.items, action.users), pageCount: state.completed.pageCount + 1 }
      };
    case 'SORT_REVIEWS':
      return {
        ...state,
        reviews: { ...state.reviews, items: action.payload.reviews, sortOption: action.payload.newSort }
      };
    case 'SORT_PHOTOS':
      return {
        ...state,
        photos: { ...state.photos, items: action.payload.photos, sortOption: action.payload.newSort }
      };
    case 'SORT_TRACKS':
      return {
        ...state,
        tracks: { ...state.tracks, items: action.payload.tracks, sortOption: action.payload.newSort }
      };
    case 'CHANGE_UGC_TAB':
      return { ...state, selectedUgcTab: action.tab };
    case 'SET_ALL_UGC_SHOWN':
      return { ...state, allUgcShown: action.allShown };
    default:
      return state;
  }
}
