import { useState } from 'react';
import CarouselDisplayTrigger from '@alltrails/analytics/enums/CarouselDisplayTrigger';
import ReportLocation from '@alltrails/analytics/enums/ReportLocation';
import TrailDetailsPhotoSource from '@alltrails/analytics/enums/TrailDetailsPhotoSource';
import logTrailDetailsAddToListButtonTapped from '@alltrails/analytics/events/logTrailDetailsAddToListButtonTapped';
import logTrailDetailsHeroImageClicked from '@alltrails/analytics/events/logTrailDetailsHeroImageClicked';
import BookmarkButton from '@alltrails/shared/components/BookmarkButton';
import CompletionBadge from '@alltrails/shared/components/cards/CompletionBadge';
import DifficultyAndRating from '@alltrails/shared/components/cards/DifficultyAndRating';
import { LightboxPhoto } from '@alltrails/shared/types/lightbox';
import useTrailAggregatePhotoViewed from 'hooks/useTrailAggregatePhotoViewed';
import { TrailId } from '@alltrails/shared/types/trail';
import type { Trail } from '@alltrails/shared/types/Trails/Trail';
import { urlReformatPhotos } from 'utils/lightbox_helpers';
import AggRatingStructuredData, { aggRatingDataAttributes } from 'components/shared/AggRatingStructuredData';
import { getTrailProfilePhotoUrl } from 'utils/trail_helpers';
import Lightbox from 'components/shared/Lightbox';
import { defineMessages, useIntl } from '@alltrails/shared/react-intl';
import type { Context } from 'types/Context';
import SignupModal from 'components/SignupModal';
import useToggle from '@alltrails/shared/hooks/useToggle';
import useUser from 'hooks/useUser';
import getTrailProps from 'api/Trail';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import useFeatures from 'hooks/useFeatures';
import CardLocation from '../CardLocation';
import * as styles from './TrailDetailsCard.module.scss';
import * as cardStyles from '../CardContent/styles/styles.module.scss';

const messages = defineMessages({
  altTitle: { defaultMessage: '{list} | AllTrails.com' }
});

type Props = {
  exploreFlag?: boolean;
  isPending?: boolean;
  listMethods: any;
  numPhotos?: number;
  photos?: any;
  photoUrl?: string;
  trail: Trail;
  webpPhotoUrl?: string;
  seoH1TestContent?: string;
  context: Context;
};

export default function TrailDetailsCard({ isPending, photoUrl, webpPhotoUrl, trail, photos = [], listMethods, seoH1TestContent, context }: Props) {
  const [isSignupModalOpen, toggleSignupModal] = useToggle();
  const intl = useIntl();
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [updateAggregate, logAggregateViewed] = useTrailAggregatePhotoViewed({ source: TrailDetailsPhotoSource.PhotosGallery, trailId: trail.ID });
  const user = useUser();
  const languageRegionCode = useLanguageRegionCode();
  const includeStrenuous = useFeatures().includes('show_strenuous_difficulty_level');

  function handleCloseRequest() {
    logAggregateViewed();
    setLightboxOpen(false);
  }

  function onPhotoViewed(photo: LightboxPhoto['photo']) {
    updateAggregate(photo.id);
  }

  function getLightboxProps() {
    return {
      images: urlReformatPhotos(photos),
      handleCloseRequest,
      onPhotoViewed,
      isOpen: true,
      startingIndex: 0,
      trailId: trail.trail_id,
      context,
      analyticsReportLocation: ReportLocation.TrailDetails
    };
  }

  function openLightbox() {
    setLightboxOpen(true);
  }

  function renderLightbox() {
    if (!lightboxOpen) {
      return null;
    }

    return <Lightbox {...getLightboxProps()} />;
  }

  function onClick() {
    if (!lightboxOpen) {
      logTrailDetailsHeroImageClicked({ trail_id: trail.trail_id });
      openLightbox();
    }
  }

  const trailPhotoUrl = photoUrl || getTrailProfilePhotoUrl(trail);
  let displayName = trail.name;

  if (isPending) {
    displayName += ' [PENDING]';
  }

  displayName = seoH1TestContent || displayName;

  const altTitle = intl.formatMessage(messages.altTitle, {
    // these fields are already translated from the server
    list: [trail.name, trail.area_name, trail.city_name, trail.state_name, trail.country_name].filter(Boolean).join(', ')
  });

  const trailHasReviews = trail.num_reviews > 0;

  return (
    <>
      <SignupModal
        trigger={CarouselDisplayTrigger.Favorite}
        isOpen={isSignupModalOpen}
        onRequestClose={() => {
          toggleSignupModal();
        }}
        onSuccess={async () => {
          const trailDetails = await getTrailProps(trail.ID as TrailId, false);
          listMethods.setListsAndListItems({ lists: trailDetails.initialMyLists, listItems: trailDetails.initialMyListItems });
          listMethods.setActiveItem({ type: trail.type, id: trail.ID, objectID: trail.objectID });
          listMethods.toggleShowModal(true);
          toggleSignupModal();
        }}
        trailId={trail.trail_id}
      />
      <div
        className={[styles.card, photos.length > 0 ? styles.clickable : ''].join(' ')}
        itemProp="image"
        title={trail.name}
        {...(photos.length > 0 ? { onClick, role: 'button' } : {})}
      >
        {(webpPhotoUrl || trailPhotoUrl) && (
          <picture>
            <source srcSet={webpPhotoUrl} type="image/webp" />
            <img src={trailPhotoUrl} alt={altTitle} className={styles.backgroundImage} title={altTitle} />
          </picture>
        )}
        <div className={styles.content}>
          <div className={styles.badgeContainer}>
            <CompletionBadge isCompleted={listMethods.isComplete(trail.type, trail.ID)} isVerified={listMethods.isVerified(trail.type, trail.ID)} />
          </div>
          <h1 className={styles.name} itemProp="name" title={seoH1TestContent || trail.name}>
            {seoH1TestContent || displayName}
          </h1>
          <div className={styles.infoLocationAndBookmark}>
            <div
              className={`${cardStyles.info} ${cardStyles.thin}`}
              {...(trailHasReviews ? aggRatingDataAttributes : {})}
              data-testid="difficulty-and-rating"
            >
              <DifficultyAndRating
                className={styles.difficultyAndRatingContainer}
                difficulty={parseInt(trail.difficulty_rating)}
                languageRegionCode={languageRegionCode}
                rating={trail.avg_rating}
                reviewCount={trail.num_reviews}
                includeStrenuous={includeStrenuous}
              />
              {trailHasReviews && <AggRatingStructuredData rating={trail.avg_rating} reviewCount={trail.num_reviews} />}
            </div>
            <CardLocation className={styles.location} trail={trail} />
            <BookmarkButton
              className={styles.favorite}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.stopPropagation(); // make sure we don't display the lightbox

                logTrailDetailsAddToListButtonTapped({ trail_id: trail.trail_id });

                if (!user) {
                  toggleSignupModal();
                  return;
                }

                listMethods.setActiveItem({ type: trail.type, id: trail.ID, objectID: trail.objectID });
                listMethods.toggleShowModal(true);
              }}
              isSelected={listMethods.isFavorite(trail.type, trail.ID)}
            />
          </div>
        </div>
        {renderLightbox()}
      </div>
    </>
  );
}
