import classNames from 'classnames';
import * as styles from './styles/styles.module.scss';

type Props = {
  titleRow?: boolean;
  columnTitle?: string;
  secondColumnTitle?: string;
  propTitle?: string;
  propsDesc?: string;
  skip?: boolean;
};

const GridRow = ({ titleRow = false, columnTitle = '', secondColumnTitle = '', propTitle = '', propsDesc = '', skip = false }: Props) => {
  if (titleRow) {
    return (
      <div className={styles.titleRow}>
        <div className={styles.titleCell}>{columnTitle}</div>
        <div className={styles.titleCell}>{secondColumnTitle}</div>
      </div>
    );
  }

  return (
    <div className={classNames(styles.detailsRow, { [styles.skipRow]: skip })}>
      <div className={styles.detailsTitle}>{propTitle}</div>
      <div className={styles.detailsTitle}>{propsDesc}</div>
    </div>
  );
};

export default GridRow;
