import NullState from '@alltrails/shared/denali/components/NullState';
import { defineMessages, useIntl } from '@alltrails/shared/react-intl';
import * as styles from './styles/styles.module.scss';

type TrailWeatherErrorProps = {
  onRetry: () => void;
  offline?: boolean;
};

const messages = defineMessages({
  offline: {
    defaultMessage: "You're offline"
  },
  tryAgain: {
    defaultMessage: "Let's try that again"
  },
  retry: {
    defaultMessage: 'Retry'
  },
  checkConnection: {
    defaultMessage: 'Please check your internet connection and try again.'
  },
  troubleLoading: {
    defaultMessage: 'We’re having trouble loading conditions details for this trail.'
  }
});

const TrailWeatherErrorProps = ({ onRetry, offline = false }: TrailWeatherErrorProps): JSX.Element => {
  const intl = useIntl();
  return (
    <NullState
      title={offline ? intl.formatMessage(messages.offline) : intl.formatMessage(messages.tryAgain)}
      description={offline ? intl.formatMessage(messages.checkConnection) : intl.formatMessage(messages.troubleLoading)}
      buttons={{ primary: { text: intl.formatMessage(messages.retry), onClick: onRetry, testId: 'trail-conditions-error-retry' } }}
      className={styles.errorContainer}
    />
  );
};

export default TrailWeatherErrorProps;
