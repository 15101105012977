import { OnMapMoveArgs } from 'components/MapProvider/MapProvider';
import { debounce } from 'debounce';
import { useCallback, useEffect } from 'react';
import { MapboxMapShim } from 'types/Map';
import { getMapBounds, getMapCenter, getMapZoom } from './map';

type Args = {
  mapInstance: MapboxMapShim;
  eventHandler: (args: OnMapMoveArgs) => void;
};

function onMoveEnd(mapInstance: MapboxMapShim, eventHandler: (args: OnMapMoveArgs) => void) {
  try {
    eventHandler?.({
      center: getMapCenter(mapInstance),
      bounds: getMapBounds(mapInstance),
      zoom: getMapZoom(mapInstance)
    });
  } catch (error) {
    // Every time we make the initial switch from 2D to 3D getBounds() throws
    // an unexplained 'Invalid LngLat object: (NaN, NaN)' error deep within mapbox-gl
    console.warn(error);
  }
}

export default function useRegisterMapMoveEndHandler({ mapInstance, eventHandler }: Args) {
  const fn = debounce(
    useCallback(() => onMoveEnd(mapInstance, eventHandler), [mapInstance, eventHandler]),
    300
  );

  useEffect(() => {
    // Despite what the docs seem to imply, this is invoked not only on user
    // interaction but also if the viewport changes, which is problematic for
    // a few reasons:
    // 1. It must be debounced to avoid excessive invocations
    // 2. On iOS the Smart Banner animating in to view will impact the map
    //    bounds and trigger events even if the map is not visible, and then
    //    the search state changes which may or may not re-trigger the
    //    SmartBanner to show.
    mapInstance?.on('moveend', fn);

    // eslint-disable-next-line consistent-return
    return () => {
      mapInstance?.off('moveend', fn);
    };
  }, [fn, mapInstance]);
}
