import { defineMessages } from '@alltrails/shared/react-intl';
import useFormatMessage from '@alltrails/shared/hooks/useFormatMessage';
import IconButton from '@alltrails/shared/denali/components/IconButton';
import Close from '@alltrails/shared/icons/Close';
import * as styles from './styles/styles.module.scss';

// https://www.figma.com/file/gZaqAej8HzoFkyrjlQ4cNs/Integrating-Brand-Changes?node-id=1204%3A61999

enum RoutingTypes {
  SMART_TIP = 'SMART_TIP',
  DRAW_TIP = 'DRAW_TIP',
  NODE_TIP = 'NODE_TIP'
}

const ROUTING_TIP_STRINGS = defineMessages({
  SMART_TIP: { defaultMessage: 'Smart routing:' },
  DRAW_TIP: { defaultMessage: 'Drawing mode:' },
  NODE_TIP: { defaultMessage: 'Tip:' },
  TIP_BUTTON_LABEL: { defaultMessage: 'Close routing tips' },
  SMART_TIP_TEXT: { defaultMessage: 'We will route you on the best path for your selected activity' },
  DRAW_TIP_TEXT: { defaultMessage: "Hold 'shift' to free draw" },
  NODE_TIP_TEXT: { defaultMessage: "Hit the 'delete' / \u232B key to remove a point from your route." }
});

type Props = {
  onClose: (tipType: string, event: any) => void;
  tipType: string;
};

const RoutingTip = ({ tipType, onClose }: Props): JSX.Element => {
  const {
    formattedDefaultMessages: { SMART_TIP, DRAW_TIP, NODE_TIP, TIP_BUTTON_LABEL, SMART_TIP_TEXT, DRAW_TIP_TEXT, NODE_TIP_TEXT }
  } = useFormatMessage(ROUTING_TIP_STRINGS);

  const getRoutingTipTitle = (): string => {
    switch (tipType) {
      case RoutingTypes.SMART_TIP:
        return SMART_TIP;
      case RoutingTypes.DRAW_TIP:
        return DRAW_TIP;
      case RoutingTypes.NODE_TIP:
        return NODE_TIP;
      default:
        return SMART_TIP;
    }
  };

  const getRoutingTipText = (): string => {
    switch (tipType) {
      case RoutingTypes.SMART_TIP:
        return SMART_TIP_TEXT;
      case RoutingTypes.DRAW_TIP:
        return DRAW_TIP_TEXT;
      case RoutingTypes.NODE_TIP:
        return NODE_TIP_TEXT;
      default:
        return SMART_TIP_TEXT;
    }
  };

  const title = getRoutingTipTitle();
  const text = getRoutingTipText();
  return (
    <div className={styles.routingTip}>
      <span className={styles.textContent}>
        <span className={styles.routingTipTitle}>{title}</span>
        <span className={styles.routingTipText}>{text}</span>
      </span>
      <IconButton
        title={TIP_BUTTON_LABEL}
        testId={TIP_BUTTON_LABEL}
        icon={{ Component: Close }}
        onClick={e => onClose(tipType, e)}
        variant="flat"
        size="sm"
      />
    </div>
  );
};

export default RoutingTip;
