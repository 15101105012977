import { Fragment, lazy, Suspense } from 'react';
import { useIntl } from '@alltrails/shared/react-intl';
import Divider from '@material-ui/core/Divider';
import SearchFilter from '@alltrails/shared/components/SearchFilter';
import useModalPortalElement from '@alltrails/shared/hooks/useModalPortalElement';
import type { User } from 'types/User';
import useFiltersContext from 'hooks/useFiltersContext';
import { FilterViewModel, FilterConfig, FilterInput } from 'types/Search';
import getSaveButtonChildrenProps from '../saveButtonChildrenProps';
import * as styles from './styles/styles.module.scss';

const ExpandableFilterPanel = lazy(() => import('components/explore/ExpandableFilterPanel'));

export type Props = {
  popperClassName?: string;
  sortedFilters: FilterViewModel[];
  title: string;
  triggerElement?: JSX.Element;
  user?: User;
};

// getSelectedItems flattens all the selected display text from the filters into a single array.
function getSelectedItems(filterKeys: string[], filterLabels: Record<string, string[]>): string[] {
  return filterKeys.reduce((acc: string[], nextKey: string): string[] => [...acc, ...filterLabels[nextKey]], []);
}

/**
 * NestedPanel is like a special case of SearchFilter and could be collapsed into that.
 */
const NestedPanel = ({ popperClassName, sortedFilters = [], triggerElement, title, user }: Props) => {
  const intl = useIntl();

  const {
    appliedFilterLabelsByKey,
    clearAppliedFilters,
    clearDraftChanges,
    filtersByKey,
    computedResultsCount,
    isLoadingHypotheticalResultsCount,
    resetHypotheticalResultsCount,
    saveChanges,
    updateFilter
  } = useFiltersContext();

  const allFilterKeys: string[] = sortedFilters.map(filter => filter.key);
  const nestedSelectedItems = getSelectedItems(allFilterKeys, appliedFilterLabelsByKey);
  // NestedPanel doesn't care about the actual items when it comes to displaying a title. All we care about is forcing
  // our own title (by making it the first item) and having a different count than a normal filter chip. The count here
  // reflects the total number of selected items rather than n - 1. We never display any of the selected items in the chip.
  const selectedItems = nestedSelectedItems.length > 0 ? [title, ...nestedSelectedItems] : [];

  const modalElement = useModalPortalElement();

  return (
    <SearchFilter
      onClear={() => {
        clearAppliedFilters(allFilterKeys);
        resetHypotheticalResultsCount();
      }}
      forceModalState
      selectedItems={selectedItems}
      modalElement={modalElement}
      modalTitle={title}
      unselectedText={title}
      popperClassName={popperClassName}
      onDismiss={() => {
        clearDraftChanges();
        resetHypotheticalResultsCount();
      }}
      saveButtonChildren={getSaveButtonChildrenProps({ intl, isLoading: isLoadingHypotheticalResultsCount, resultsCount: computedResultsCount })}
      saveButtonHandler={saveChanges}
      triggerElement={triggerElement}
      testIdPrefix="nested-panel"
    >
      {sortedFilters.map((filter, index) => {
        // The UI treatment for star ratings is slightly different than other inputs.
        const panelTitle = filter.type === FilterInput.STAR_RATING ? null : filter.display;
        return (
          <Fragment key={filter.key}>
            <Suspense fallback={null}>
              <ExpandableFilterPanel
                className={styles.filterPanel}
                title={panelTitle}
                shouldTruncate={filter.truncate}
                hasPlus={filter.isProFilter}
                filter={filtersByKey[filter.key]}
                onChange={(changes: FilterConfig) => updateFilter(filter.key, changes)}
                user={user}
              />
            </Suspense>
            {index !== sortedFilters.length - 1 && <Divider />}
          </Fragment>
        );
      })}
    </SearchFilter>
  );
};

export default NestedPanel;
