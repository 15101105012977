import { useCallback, useEffect, useMemo, useReducer } from 'react';
import {
  FilterCheckboxViewModel,
  FilterConfig,
  FilterInput,
  FilterRangeUpdates,
  FilterViewModel,
  FilterConfigToggleKey,
  FilterConfigRange,
  FilterConfigSlider,
  FilterSliderUpdates
} from 'types/Search';
import { useIntl } from '@alltrails/shared/react-intl';
import getAllAppliedFilterLabels from 'utils/filters/getAllAppliedFilterLabels';
import searchFilterReducer from '../ducks/searchFilters';

export type Args = {
  clearRadioFilter?: (filterKey: string, pageName: string) => void;
  filters: FilterViewModel[];
  handleFilterRadioToggle?: (filterKey: string, filterConfigKey: string) => void;
  handleFilterSet?: (filterKey: string, value: string) => void;
  handleFilterRange?: (filterKey: string, value: FilterRangeUpdates | FilterSliderUpdates) => void;
  handleFilterToggle?: (filterKey: string, filterConfigKey: string) => void;
  page?: string;
};

const getRangeStateUpdates = (config: FilterConfigRange): FilterRangeUpdates => ({
  convertedRange: config.value,
  displayRange: config.displayValue,
  formattedRange: config.formattedRange
});

const getSliderStateUpdates = (config: FilterConfigSlider): FilterSliderUpdates => ({
  convertedRange: config.value,
  displayRange: config.displayValue,
  formattedRange: config.formattedRange
});

const saveFilterChanges = (
  filter: FilterViewModel,
  savedFilter: FilterViewModel,
  updateMethods: { [key: string]: (key: string, updates: any) => void }
) => {
  switch (filter.type) {
    case FilterInput.CHECKBOX:
      Object.keys(filter.config).forEach((configKey: FilterConfigToggleKey) => {
        if (filter.config[configKey].selected !== (savedFilter as FilterCheckboxViewModel).config[configKey].selected) {
          updateMethods[FilterInput.CHECKBOX](filter.key, configKey);
        }
      });
      break;
    case FilterInput.RADIO:
      updateMethods[FilterInput.RADIO](
        filter.key,
        Object.keys(filter.config).find((configKey: FilterConfigToggleKey) => filter.config[configKey].selected === true)
      );
      break;
    case FilterInput.SLIDER:
      updateMethods[FilterInput.SLIDER](filter.key, getSliderStateUpdates(filter.config));
      break;
    case FilterInput.RANGE:
      updateMethods[FilterInput.RANGE](filter.key, getRangeStateUpdates(filter.config));
      break;
    case FilterInput.STAR_RATING:
      updateMethods[FilterInput.STAR_RATING](filter.key, filter.config);
      break;
    default:
      // eslint-disable-next-line no-console
      console.warn(`saveChanges unexpected filter type: ${JSON.stringify(filter)}`);
      break;
  }
};

const useSearchFilters = ({
  clearRadioFilter,
  filters,
  handleFilterRadioToggle,
  handleFilterSet,
  handleFilterRange,
  handleFilterToggle,
  page
}: Args) => {
  const intl = useIntl();

  const [state, dispatch] = useReducer(searchFilterReducer, {
    saved: filters,
    savedByKey: filters.reduce(
      (memo, filter) => ({
        ...memo,
        [filter.key]: filter
      }),
      {}
    ),
    changes: {}
  });

  useEffect(() => {
    dispatch({ type: 'UPDATE_ALL', payload: filters });
  }, [filters, filters?.length]);

  const clearDraftChanges = () => {
    dispatch({ type: 'CLEAR_CHANGES' });
  };

  const clearAppliedFilters = (keys: string[]) => {
    keys.forEach((key: string) => {
      const filter = state.savedByKey[key];

      switch (filter.type) {
        case FilterInput.CHECKBOX:
          Object.keys(filter.config).forEach((configKey: FilterConfigToggleKey) => {
            // Toggle any checkbox value to off for this component.
            if (filter.config[configKey].selected === true) {
              handleFilterToggle(filter.key, configKey);
            }
          });
          break;
        case FilterInput.RADIO:
          clearRadioFilter(filter.key, page);
          break;
        case FilterInput.RANGE:
          filter.config.displayValue = [0, filter.config.upperBound];
          filter.config.value = [0, -1];
          handleFilterRange(filter.key, getRangeStateUpdates(filter.config));
          break;
        case FilterInput.SLIDER:
          filter.config.displayValue = filter.config.upperBound;
          filter.config.value = -1;
          handleFilterRange(filter.key, getSliderStateUpdates(filter.config));
          break;
        case FilterInput.STAR_RATING:
          handleFilterSet(filter.key, null);
          break;
        default:
          // eslint-disable-next-line no-console
          console.warn(`clearAppliedFilters unexpected filter type: ${JSON.stringify(filter)}`);
          break;
      }
    });

    dispatch({ type: 'CLEAR_CHANGES' });
  };

  const saveChanges = () => {
    Object.values(state.changes).forEach((filter: FilterViewModel) =>
      saveFilterChanges(filter, state.savedByKey[filter.key], {
        [FilterInput.CHECKBOX]: handleFilterToggle,
        [FilterInput.RADIO]: handleFilterRadioToggle,
        [FilterInput.RANGE]: handleFilterRange,
        [FilterInput.SLIDER]: handleFilterRange,
        [FilterInput.STAR_RATING]: handleFilterSet
      })
    );
  };

  // useCallback otherwise updateFilter changes every time, and other hooks
  // relying on it might re-render needlessly.
  const updateFilter = useCallback(
    (key: string, changes: FilterConfig) => {
      dispatch({ type: 'CHANGE_ONE', payload: { key, changes } });
    },
    [dispatch]
  );
  const updateFilters = useCallback(
    (payload: FilterViewModel[]) => {
      dispatch({ type: 'CHANGE_MANY', payload });
    },
    [dispatch]
  );

  const filtersByKey = useMemo(() => ({ ...state.savedByKey, ...state.changes }), [state.savedByKey, state.changes]);

  return {
    appliedFilterLabelsByKey: getAllAppliedFilterLabels(intl, state.savedByKey),
    clearAppliedFilters,
    clearDraftChanges,
    filtersByKey,
    hasAnyDraftChanges: Object.keys(state.changes).length > 0,
    saveChanges,
    updateFilter,
    updateFilters
  };
};

export default useSearchFilters;
