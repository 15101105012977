type UTMAttribution = {
  paramKey: string;
  value: string;
};

const getUTMAttribution = (params: URLSearchParams): UTMAttribution[] | undefined => {
  const utmAttribution: UTMAttribution[] = [];

  params.forEach((value, key) => {
    if (key.startsWith('utm')) {
      utmAttribution.push({ paramKey: key, value });
    }
  });

  return utmAttribution.length > 0 ? utmAttribution : undefined;
};

export default getUTMAttribution;
