import { Fragment, ReactNode, useEffect } from 'react';
import { FormattedMessage } from '@alltrails/shared/react-intl';
import Typography from '@alltrails/shared/denali/components/Typography';
import invite from 'components/icons/invite.svg';
import download from 'components/icons/download.svg';
import tree from 'components/icons/tree.svg';
import Link from '@alltrails/shared/denali/components/Link';
import logReferralHowItWorksLearnMoreClicked from '@alltrails/analytics/events/logReferralHowItWorksLearnMoreClicked';
import logReferralHowItWorksViewed from '@alltrails/analytics/events/logReferralHowItWorksViewed';
import * as styles from './HowItWorksModal.module.scss';

const bullets = [
  {
    body: <FormattedMessage defaultMessage="Invite your friends to sign up for AllTrails and download the app via text or email." />,
    src: invite,
    title: <FormattedMessage defaultMessage="Invite your friends" />
  },
  {
    body: <FormattedMessage defaultMessage="The people you invite sign up for a free account and download the app." />,
    src: download,
    title: <FormattedMessage defaultMessage="Your friends sign up" />
  },
  {
    body: (
      <FormattedMessage
        defaultMessage="In partnership with One Tree Planted, we plant one tree for you - and one for each friend once they log in. <a>Learn more</a>"
        values={{
          a: (chunks: ReactNode) => (
            <>
              <br />
              <Link
                href="https://onetreeplanted.org/"
                target="_blank"
                rel="noreferrer"
                onClick={() => logReferralHowItWorksLearnMoreClicked()}
                size="md"
                testId="one-tree-planted-link"
              >
                {chunks}
              </Link>
            </>
          )
        }}
      />
    ),
    src: tree,
    title: <FormattedMessage defaultMessage="We plant trees" />
  }
];

// https://www.figma.com/file/gZaqAej8HzoFkyrjlQ4cNs/Integrating-Brand-Changes?node-id=7560%3A88923
export default function HowItWorksModal() {
  useEffect(() => {
    logReferralHowItWorksViewed();
  }, []);

  return (
    <Typography className={styles.grid} component="div">
      {bullets.map(bullet => (
        <Fragment key={bullet.src}>
          <div>
            <img alt="" src={bullet.src} />
          </div>
          <div>
            <Typography component="div" mb="8" variant="heading300">
              {bullet.title}
            </Typography>
            <Typography component="div" variant="paragraph200">
              {bullet.body}
            </Typography>
          </div>
        </Fragment>
      ))}
    </Typography>
  );
}
