import NullState from '@alltrails/shared/denali/components/NullState';
import Tent from '@alltrails/shared/illustrations/Tent';

type TrailConditionsErrorProps = {
  onRetry: () => void;
};

/* NOTE: no translations needed for now, EN/US only feature */
const TrailConditionsError = ({ onRetry }: TrailConditionsErrorProps): JSX.Element => (
  <NullState
    illustration={{ Component: Tent }}
    title="Let's try that again"
    description="We’re having trouble loading conditions for this trail."
    buttons={{ primary: { text: 'Retry', onClick: onRetry, testId: 'trail-conditions-error-retry' } }}
  />
);

export default TrailConditionsError;
