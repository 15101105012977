import { Map } from 'mapbox-gl';
import { Feature, LineString } from '@turf/helpers';
import { COLOR_NEUTRAL_900 } from '@alltrails/shared/denali/tokens';
import { addLayer, removeLayer } from '../layers/layer_helpers';
import { removeSource } from '../sources/source_helpers';

const addSlidRoute = async (map: Map, slidRoute: Feature<LineString>) => {
  addLayer(
    map,
    {
      id: 'slid-route',
      type: 'line',
      source: { type: 'geojson', data: slidRoute },
      layout: {
        'line-join': 'round',
        'line-cap': 'round'
      },
      paint: {
        'line-color': COLOR_NEUTRAL_900,
        'line-width': 8,
        'line-opacity': 0.6
      }
    },
    null,
    'atMap-polylines'
  );
};

const removeSlidRoute = (map: Map) => {
  removeLayer(map, 'slid-route');
  removeSource(map, 'slid-route');
};

export { addSlidRoute, removeSlidRoute };
