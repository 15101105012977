import { MouseEvent, useState } from 'react';
import logTrailDetailsPhotosIconClicked from '@alltrails/analytics/events/logTrailDetailsPhotosIconClicked';
import ReportLocation from '@alltrails/analytics/enums/ReportLocation';
import Photo from '@alltrails/shared/icons/Photo';
import { COLOR_INTERACTIVE_DEFAULT } from '@alltrails/shared/denali/tokens';
import { defineMessages } from '@alltrails/shared/react-intl';
import useFormatMessage from '@alltrails/shared/hooks/useFormatMessage';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import type { Context } from 'types/Context';
import useUser from 'hooks/useUser';
import type { TrailId } from '@alltrails/shared/types/trail';
import { LanguageSupportUtil } from 'utils/language_support_util';
import { urlReformatPhotos } from 'utils/lightbox_helpers';
import hasPermission from 'utils/hasPermission';
import ActionBarItem from '../../ActionBarItem';
import Lightbox from '../../shared/Lightbox';

const messages = defineMessages({
  PHOTOS: { defaultMessage: 'Photos' }
});

type PhotoMenuItemProps = {
  count: number;
  photos: unknown;
  slug: string;
  trailId?: TrailId;
  context: Context;
};

const PhotosMenuItem = ({ count, photos, slug, trailId, context }: PhotoMenuItemProps): JSX.Element => {
  const {
    formattedDefaultMessages: { PHOTOS }
  } = useFormatMessage(messages);

  const languageRegionCode = useLanguageRegionCode();
  const user = useUser();
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [startingPhotosIndex, setStartingPhotosIndex] = useState(0);

  const openAdminLink = () => {
    const contributePath = `/contribute/admin/photos?trail_id=${trailId}`;
    window.open(contributePath, '_blank');
  };

  const openLightbox = (startingPhotosIndex: number) => {
    setStartingPhotosIndex(startingPhotosIndex);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
    setStartingPhotosIndex(null);
  };

  const handleClick = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    logTrailDetailsPhotosIconClicked({ trail_id: trailId });

    if (hasPermission({ permission: 'trails:manage' }) && trailId) {
      openAdminLink();
      return;
    }

    if (slug) {
      const path = LanguageSupportUtil.wrapUrlSafe(`/${slug}/photos`, languageRegionCode);
      window.location.href = path;
      return;
    }

    openLightbox(0);
  };

  return (
    <>
      <ActionBarItem
        href={LanguageSupportUtil.wrapUrlSafe(`/${slug}/photos`, languageRegionCode)}
        onClick={handleClick}
        icon={<Photo color={COLOR_INTERACTIVE_DEFAULT} />}
        title={PHOTOS}
        count={count}
        testId="photos-action"
      />
      {lightboxOpen && (
        <Lightbox
          handleCloseRequest={closeLightbox}
          images={urlReformatPhotos(photos)}
          startingIndex={startingPhotosIndex}
          trailId={trailId}
          context={context}
          analyticsReportLocation={ReportLocation.TrailDetails}
        />
      )}
    </>
  );
};

export default PhotosMenuItem;
