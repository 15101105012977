import reorder from '@alltrails/shared/utils/reorder';
import { string, arrayOf, number, func, shape, instanceOf, bool, object } from 'prop-types';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import RouteCard from '../RouteCard';
import RouteForm from '../RouteForm';
import { UPDATE_EDIT_INDEX, UPDATE_SORT_MODE, REORDER_ROUTES, REMOVE_ROUTE } from 'hooks/useRoutes';
import { MapStatsUtil } from 'utils/map_stats_util';
import { ServerCommunicationUtil } from 'utils/server_communication_util';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import useDisplayMetric from 'hooks/useDisplayMetric';

const Routes = ({
  routes,
  editIndex,
  dispatchRoutes,
  editParams,
  messagingChannel,
  handleFindLocationSelect,
  mapboxAccessToken,
  canEdit,
  sortMode,
  mapId,
  updateMap,
  handleRouteSave,
  isSubmitting
}) => {
  const displayMetric = useDisplayMetric();
  const languageRegionCode = useLanguageRegionCode();

  let deleteSuccess;
  let handleEdit;
  let handleDelete;
  let handleSortMode;
  let editRouteProps;
  let handleReorderSuccess;
  let persistRoutesOrder;
  let onDragEnd;

  if (canEdit) {
    // uploaded routes being deleted don't return an id in the response data
    // so pass in the id just incase
    deleteSuccess = (data, id) => {
      dispatchRoutes({ type: REMOVE_ROUTE, payload: data.id || id });
      updateMap();
    };

    handleEdit = idx => {
      if (!canEdit) {
        return;
      }

      const {
        id,
        lineDisplayProperty: { color },
        lineGeoStats: { distanceTotal, elevationGain }
      } = routes[idx];

      const params = { id, distanceTotal, elevationGain, initialColor: color };
      dispatchRoutes({ type: UPDATE_EDIT_INDEX, payload: { editParams: params, editIndex: idx } });
    };

    handleDelete = idx => {
      if (!canEdit) {
        return;
      }

      const { id } = routes[idx];
      const url = `/api/alltrails/maps/${mapId}/routes/${id}?calculate_synch=true`;
      ServerCommunicationUtil.deleteApiEndpoint(
        url,
        {},
        data => deleteSuccess(data, id),
        () => {
          console.log('TODO: ERROR HANDLING / MESSAGING');
        },
        () => {}
      );
    };

    handleSortMode = () => {
      if (!canEdit) {
        return;
      }

      dispatchRoutes({ type: UPDATE_SORT_MODE, payload: true });
    };

    editRouteProps = (route, idx) => {
      const {
        lineDisplayProperty: { color }
      } = route;
      const { distance, elevation } = MapStatsUtil.distanceAndElevation(editParams, displayMetric, languageRegionCode);

      return {
        color,
        distance,
        elevation,
        idx,
        messagingChannel,
        dispatchRoutes,
        handleFindLocationSelect,
        mapboxAccessToken,
        handleRouteSave,
        isSubmitting
      };
    };

    handleReorderSuccess = data => {
      dispatchRoutes({ type: UPDATE_SORT_MODE, payload: false });
    };

    onDragEnd = result => {
      const reorderedRoutes = reorder(routes, result.source.index, result.destination.index).map((route, idx) => {
        return { ...route, sequence_num: idx + 1 };
      });

      dispatchRoutes({ type: REORDER_ROUTES, payload: reorderedRoutes });
    };

    persistRoutesOrder = () => {
      const routeIds = routes.map(r => r.id);
      ServerCommunicationUtil.postApiEndpoint(
        `/api/alltrails/maps/${mapId}/routes/reorder`,
        { ordered_route_ids: routeIds },
        handleReorderSuccess,
        err => {
          console.log('err', err);
        },
        () => {}
      );
    };
  }

  const routeCardProps = (route, idx) => {
    const {
      lineDisplayProperty: { color },
      lineGeoStats,
      id
    } = route;
    const { distance, elevation } = MapStatsUtil.distanceAndElevation(lineGeoStats, displayMetric, languageRegionCode);

    return {
      id,
      color,
      distance,
      elevation,
      handleDelete,
      handleEdit,
      handleSortMode,
      idx,
      totalRoutesLength: routes.length,
      canEdit,
      messagingChannel
    };
  };

  if (sortMode) {
    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {provided => (
            <ul {...provided.droppableProps} ref={provided.innerRef}>
              {routes.map((route, idx) => {
                return (
                  <Draggable key={`${route.id}`} draggableId={`${route.id}`} index={idx}>
                    {provided => (
                      <li ref={provided.innerRef} {...provided.draggableProps}>
                        <RouteCard {...routeCardProps(route, idx)} provided={provided} sortMode={sortMode} persistRoutesOrder={persistRoutesOrder} />
                      </li>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
    );
  }

  return routes.map((route, idx) => {
    return idx === editIndex && canEdit ? (
      <RouteForm key="routeForm" {...editRouteProps(route, idx)} />
    ) : (
      <RouteCard key={route.id} {...routeCardProps(route, idx)} />
    );
  });
};

Routes.propTypes = {
  routes: arrayOf(
    shape({
      id: number,
      description: string,
      lineDisplayProperty: shape({
        color: string
      }),
      lineGeoStats: shape({
        distanceTotal: string,
        elevationEnd: number,
        elevationGain: number,
        elevationLoss: number,
        elevationMax: number,
        elevationMin: number,
        elevationStart: number
      }),
      lineSegments: arrayOf(
        shape({
          dateTimeStart: instanceOf(Date),
          dateTime: instanceOf(Date),
          id: number,
          polyline: shape({
            elevationData: string,
            indexedElevationData: string,
            pointsData: string
          }),
          sequence_num: number
        })
      ),
      location: shape({
        city: string,
        country: string,
        country_id: number,
        latitude: string,
        longitude: string,
        postalCode: string,
        region: string
      }),
      name: string,
      rating: number,
      sequence_num: number,
      status: string,
      trailId: number
    })
  ),
  dispatchRoutes: func,
  editIndex: number,
  editParams: shape({
    lineGeoStats: shape({
      distanceTotal: number,
      elevationGain: number
    }),
    color: string
  }),
  messagingChannel: object,
  mapboxAccessToken: string,
  handleFindLocationSelect: func,
  canEdit: bool,
  sortMode: bool,
  mapId: number,
  updateMap: func
};

Routes.defaultProps = {
  editIndex: null,
  editParams: {},
  handleFindLocationSelect: () => {},
  mapboxAccessToken: '',
  messagingChannel: {},
  routes: [],
  dispatchRoutes: () => {},
  canEdit: false,
  mapId: null,
  updateMap: () => {}
};

export default Routes;
