import classNames from 'classnames';
import LoadingSpinner from '@alltrails/shared/components/LoadingSpinner';
import { TrailUgcProvider } from 'components/trailUgc/TrailUgcContext';
import Trail from 'pages/Trail';
import ListMethods from 'types/ListMethods';
import * as styles from './styles.module.scss';

type TrailExploreProps = {
  trailPageProps: any;
  listMethods: ListMethods;
  isLoading: boolean;
};

const TrailExplore = ({ trailPageProps, listMethods, isLoading }: TrailExploreProps): JSX.Element => (
  <div
    id="explore_trail"
    /* ID used for styling a mobile sized Trail in explore */ className={classNames(styles.trailContainer, { [styles.center]: isLoading })}
  >
    {isLoading ? (
      <LoadingSpinner className={styles.trailLoading} />
    ) : (
      <TrailUgcProvider
        ugcInfo={{
          reviews: trailPageProps.reviews?.trail_reviews,
          photos: trailPageProps.photos,
          tracks: trailPageProps.tracks?.maps,
          completed: { users: trailPageProps.completedJson?.users, connectionId: trailPageProps.completedJson?.connectionId },
          itemTotals: trailPageProps.itemTotals
        }}
      >
        <Trail {...trailPageProps} listMethods={listMethods} />
      </TrailUgcProvider>
    )}
  </div>
);

export default TrailExplore;
