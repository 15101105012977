import { useEffect, useMemo, useRef, useState } from 'react';
import logTrailDetailsDetailTabClicked from '@alltrails/analytics/events/logTrailDetailsDetailTabClicked';
import logTrailDetailsDetailTabsViewed from '@alltrails/analytics/events/logTrailDetailsDetailTabsViewed';
import TrailDetailsDetailTab from '@alltrails/analytics/enums/TrailDetailsDetailTab';
import TabBar from '@alltrails/shared/denali/components/TabBar';
import useIsVisible from '@alltrails/shared/hooks/useIsVisible';
import TrailDetailsTabKey from 'types/TrailDetailsTabKey';
import TrailTabType from 'types/TrailTabType';
import type { TrailId } from '@alltrails/shared/types/trail';
import hasPermission from 'utils/hasPermission';
import * as styles from './styles/styles.module.scss';
import TrailDetailItem from '../TrailDetailItem';
import TrailEditHistory from '../TrailEditHistory';
import WaypointsWrapper from '../../Waypoints/WaypointsWrapper';

function getAnalyticsDetailsTab(tabKey: TrailDetailsTabKey | null): TrailDetailsDetailTab | null {
  switch (tabKey) {
    case 'contact_info':
      return TrailDetailsDetailTab.Contact;
    case 'description':
      return TrailDetailsDetailTab.TrailDetailsDescription;
    case 'parking_facilities':
      return TrailDetailsDetailTab.Facilities;
    case 'getting_there':
      return TrailDetailsDetailTab.GettingThere;
    case 'tips':
      return TrailDetailsDetailTab.Tips;
    case 'waypoints':
      return TrailDetailsDetailTab.Waypoints;
    default:
      return null;
  }
}

type TrailOverviewProps = {
  adminOrPendingOwner?: boolean;
  descriptionInitiallyExpanded?: boolean;
  tabs: TrailTabType[];
  trailId: TrailId;
  refetchTrailProps?: () => void;
  isLoadingTabs?: boolean;
};

const TrailOverview = ({
  adminOrPendingOwner,
  descriptionInitiallyExpanded,
  tabs,
  trailId,
  refetchTrailProps,
  isLoadingTabs
}: TrailOverviewProps): JSX.Element => {
  const ref = useRef<HTMLDivElement>(null);
  const isVisible = useIsVisible(ref, { threshold: 1 });
  const [activeTabKey, setActiveTabKey] = useState<TrailDetailsTabKey>(tabs.length ? tabs[0].key : null);
  const [messagingChannel, setMessagingChannel] = useState<any>(null);
  const visibleTabs = useMemo(
    () =>
      tabs
        .filter(tab => !(!hasPermission({ permission: 'trails:manage' }) && tab.key === 'waypoints' && tab.value.waypoints?.length === 0))
        .map(tab => ({
          id: tab.key,
          label: tab.label,
          testId: `trail-overview-tab-${tab.key}`
        })),
    [tabs, hasPermission]
  );
  const editItem = useMemo(() => tabs.find(tab => tab.key === activeTabKey), [activeTabKey, tabs]);

  const handleTabChange = (tabKey: TrailDetailsTabKey) => {
    if (tabKey === activeTabKey) {
      return;
    }

    logTrailDetailsDetailTabClicked({
      trail_id: trailId,
      trail_details_tab: getAnalyticsDetailsTab(tabKey),
      default_tab: getAnalyticsDetailsTab(tabs[0].key)
    });

    setActiveTabKey(tabKey);
  };

  useEffect(() => {
    // legacy method for communicating between trail details & search_app
    // TODO: replace messaging channel in explore
    // @ts-expect-error custom messaging tunnel
    if (window.parent && window.parent.messagingTunnel) {
      // @ts-expect-error custom messaging tunnel
      setMessagingChannel(window.parent.messagingTunnel('explore_messagingChannel'));
    }
  }, []);

  useEffect(() => {
    if (isVisible) {
      logTrailDetailsDetailTabsViewed({ trail_id: trailId });
    }
  }, [isVisible, trailId]);

  return (
    <div ref={ref}>
      {visibleTabs.length ? (
        <>
          <TabBar activeTab={activeTabKey} className={styles.tabBar} onChange={handleTabChange} tabs={visibleTabs} testId="trail-overview-tab-bar" />
          {activeTabKey === TrailDetailsTabKey.Waypoints && (
            <WaypointsWrapper
              canEdit={adminOrPendingOwner}
              className={styles.waypointsWrapper}
              waypoints={editItem.value.waypoints}
              mapId={editItem.value.id}
              trailId={trailId}
              messagingChannel={messagingChannel}
              refetchTrailProps={refetchTrailProps}
              isLoading={isLoadingTabs}
            />
          )}
          {activeTabKey === TrailDetailsTabKey.EditHistory && <TrailEditHistory trailId={trailId} visible />}
          {activeTabKey !== TrailDetailsTabKey.Waypoints && activeTabKey !== TrailDetailsTabKey.EditHistory && (
            <TrailDetailItem
              activeTab={activeTabKey}
              adminOrPendingOwner={adminOrPendingOwner}
              descriptionInitiallyExpanded={descriptionInitiallyExpanded}
              editItem={editItem}
              trailId={trailId}
              visible
            />
          )}
        </>
      ) : null}
    </div>
  );
};

export default TrailOverview;
