import { useCallback } from 'react';
import { FormattedMessage, defineMessages } from '@alltrails/shared/react-intl';
import useFormatMessage from '@alltrails/shared/hooks/useFormatMessage';
import { number, string, func, bool } from 'prop-types';
import Menu from '@alltrails/shared/icons/Menu';
import { providedPropTypes } from '../../../../proptypes/shared';
import * as styles from './styles/styles.module.scss';
import RouteDetails from '../RouteDetails';
import MenuPop from '../../../shared/MenuPop';

const ROUTE_CARD_STRINGS = defineMessages({
  SORT: { defaultMessage: 'Sort' },
  EDIT: { defaultMessage: 'Edit' },
  DELETE: { defaultMessage: 'Delete' },
  DONE: { defaultMessage: 'Done' }
});

const RouteCard = ({
  id,
  idx,
  color,
  distance,
  elevation,
  handleEdit,
  handleDelete,
  totalRoutesLength,
  canEdit,
  handleSortMode,
  provided,
  sortMode,
  persistRoutesOrder,
  messagingChannel
}) => {
  const {
    formattedDefaultMessages: { SORT, EDIT, DELETE, DONE }
  } = useFormatMessage(ROUTE_CARD_STRINGS);
  let renderMenuPop;

  if (canEdit) {
    const sortOption = {
      label: SORT,
      callback: handleSortMode
    };

    const menuOptions = [
      {
        label: EDIT,
        callback: useCallback(() => {
          handleEdit(idx);
        }, [idx])
      },
      {
        label: DELETE,
        callback: useCallback(() => {
          handleDelete(idx);
        }, [idx])
      }
    ];

    if (totalRoutesLength > 1 && canEdit) {
      menuOptions.push(sortOption);
    }

    renderMenuPop = <MenuPop items={menuOptions} />;
  }

  const handleMouseEnter = () => {
    messagingChannel.publish({ topic: 'route.mouseEnter', data: id });
  };

  const handleMouseLeave = () => {
    messagingChannel.publish({ topic: 'route.mouseLeave' });
  };

  const routeNumber = totalRoutesLength === 1 && idx == 0 ? '' : idx + 1;

  return (
    <div className={styles.container} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <div className={styles.titleAndMenu}>
        <div className={styles.toggleAndTitle}>
          {provided && (
            <span {...provided.dragHandleProps} title="Reorder" data-testid="reorder">
              <Menu />
            </span>
          )}
          <h3 className={styles.title}>
            <FormattedMessage defaultMessage="Route {number}" values={{ number: routeNumber }} />
          </h3>
        </div>
        {sortMode ? (
          <button type="button" className={styles.doneButton} onClick={persistRoutesOrder}>
            {DONE}
          </button>
        ) : (
          <div className={styles.menuContainer}>{renderMenuPop}</div>
        )}
      </div>
      <RouteDetails distance={distance} elevation={elevation} color={color} />
    </div>
  );
};

RouteCard.propTypes = {
  idx: number.isRequired,
  color: string.isRequired,
  distance: string.isRequired,
  elevation: string.isRequired,
  handleEdit: func,
  handleDelete: func,
  canEdit: bool,
  handleSortMode: func,
  provided: providedPropTypes,
  sortMode: bool,
  persistRoutesOrder: func
};

RouteCard.defaultProps = {
  handleEdit: () => {},
  handleDelete: () => {},
  canEdit: false,
  provided: null,
  handleSortMode: () => {},
  sortMode: false,
  persistRoutesOrder: () => {}
};

export default RouteCard;
