import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import logTrailDetailsShowMoreClicked from '@alltrails/analytics/events/logTrailDetailsShowMoreClicked';
import ShowMoreLocation from '@alltrails/analytics/enums/ShowMoreLocation';
import { FormattedMessage } from '@alltrails/shared/react-intl';
import type { TrailId } from '@alltrails/shared/types/trail';
import * as styles from './styles.module.scss';

export type ProgrammaticOverviewProps = {
  trailId: TrailId;
  overview?: string;
};

const ProgrammaticOverview = ({ trailId, overview }: ProgrammaticOverviewProps): JSX.Element => {
  const [displayShowMoreButton, setDisplayShowMoreButton] = useState(false);
  const [showMoreOverviewExpanded, setShowMoreOverviewExpanded] = useState(false);

  const toggleShowMore = useCallback(() => {
    logTrailDetailsShowMoreClicked({ trail_id: trailId, show_more_location: ShowMoreLocation.Overview });
    setShowMoreOverviewExpanded(!showMoreOverviewExpanded);
  }, [showMoreOverviewExpanded, trailId]);

  const showMoreButton = useMemo(() => {
    if (!displayShowMoreButton || showMoreOverviewExpanded) {
      return null;
    }

    return (
      <div className={styles.showMoreContainer}>
        <button type="button" className={styles.showMore} onClick={toggleShowMore}>
          <FormattedMessage defaultMessage="Show more" />
        </button>
      </div>
    );
  }, [displayShowMoreButton, showMoreOverviewExpanded, toggleShowMore]);

  useEffect(() => {
    if (showMoreButton) {
      return;
    }

    const timer = window.setTimeout(() => {
      // the text element's width and height evaluate to 0 unless we give the DOM a very small waiting period here
      // @ts-ignore
      if (window.elementHasOverflow('auto-overview')) {
        setDisplayShowMoreButton(true);
      }
    }, 10 /* small waiting period to allow DOM to evaluate rect */);

    // eslint-disable-next-line consistent-return
    return () => window.clearTimeout(timer);
  }, [showMoreButton]);

  if (!overview) {
    return null;
  }

  return (
    <section itemProp="description" className={styles.trailOverviewContainer}>
      <p className={classNames('xlate-google', styles.trailOverview, { [styles.clamp]: !showMoreOverviewExpanded })} id="auto-overview">
        {overview}
      </p>
      {showMoreButton}
    </section>
  );
};

export default memo(ProgrammaticOverview);
