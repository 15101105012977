import { featureCollection, multiLineString } from '@turf/helpers';
import { decodeSegmentLngLat, getSegments } from '@alltrails/maps/utils/legacyGeoJSONConversions';
import { COLOR_NEUTRAL_200 } from '@alltrails/shared/denali/tokens';
import { bringAtMapIntoForeground, putAtMapIntoBackground } from './at_map';
import { addPolylines, removePolylines } from './polylines';
import { addChevrons, removeChevrons } from './chevrons';
import { createDraggableMarker } from '../layers/markers';
import { closestInterpPtSegments } from '../../trail_planner/closest_on_line';
import { END } from 'utils/constants/MarkerIds';

const editPolylinesId = 'editEndpoint-polylines';
let draggableEndMarker;

const addEditEndpointOverlay = (map, endAtLine, handleEndpointEdited) => {
  if (!endAtLine) {
    return;
  }

  const segmentIds = [];
  const multiCoords = [];
  getSegments(endAtLine).forEach(segment => {
    segmentIds.push(segment.id);
    multiCoords.push(decodeSegmentLngLat(segment));
  });
  if (multiCoords.length === 0) {
    return;
  }
  const lastCoords = multiCoords[multiCoords.length - 1];
  if (lastCoords.length === 0) {
    return;
  }
  const lastCoord = lastCoords[lastCoords.length - 1];

  putAtMapIntoBackground(map);

  // The gray polyline showing what the user is removing starts at the original end of the line and runs
  // (backwards) along the original line to the current drag-point.  Hence it is initially empty.
  const geojson = featureCollection([multiLineString([[]])]);
  addPolylines(map, editPolylinesId, geojson, {}, { 'line-color': COLOR_NEUTRAL_200 });

  draggableEndMarker = createDraggableMarker(END).setLngLat(lastCoord).addTo(map);

  let endSegmentData;
  const onDrag = () => {
    const lngLat = draggableEndMarker.getLngLat().toArray();
    const closest = closestInterpPtSegments(lngLat, multiCoords);
    if (closest.cropIndex === null) {
      return;
    }

    // Find new end point on line.  Since we need to build the grey polyline from the original end of
    // the line to the drag-point, do the search from the end of the original line, building as we go.
    const croppedMultiCoords = [];
    let newPoint; // latLng
    for (let i = multiCoords.length - 1; i >= 0 ; i--) {
      const coords = multiCoords[i];
      if (closest.cropSegmentsIdx === i) {
        // Crop segment (from end since we are working backwards)
        const croppedCoords = coords.slice(closest.cropIndex + 1).toReversed();
        if (closest.interpolatedPoint) {
          croppedCoords.push(closest.interpolatedPoint);
          newPoint = closest.interpolatedPoint.slice().reverse(); // latLng;
        }
        croppedMultiCoords.push(croppedCoords);
        // Move marker so it says on the polyline
        const endPt = croppedCoords[croppedCoords.length - 1];
        draggableEndMarker.setLngLat(endPt);
        // Break early
        break;
      }
      // Otherwise, collect coordinates from segments before cropIndex (reversed since we are working backwards)
      croppedMultiCoords.push(coords.toReversed());
    }

    // Update display of polyline
    geojson.features[0].geometry.coordinates = croppedMultiCoords;
    addPolylines(map, editPolylinesId, geojson);

    endSegmentData = {
      endSegmentId: segmentIds[closest.cropSegmentsIdx],
      endSegmentCropIndex: closest.cropIndex,
      endSegmentNewPoint: newPoint
    };
  };

  draggableEndMarker.on('drag', onDrag);
  draggableEndMarker.on('dragend', () => {
    if (!endSegmentData) {
      return;
    }
    handleEndpointEdited(endSegmentData);
    endSegmentData = null;
  });
};

const removeEditEndpointOverlay = map => {
  if (draggableEndMarker) {
    draggableEndMarker.remove();
  }

  removePolylines(map, editPolylinesId);

  bringAtMapIntoForeground(map);
};

export { addEditEndpointOverlay, removeEditEndpointOverlay };
