// extracted by mini-css-extract-plugin
export var ad = "styles-module__ad___th_QW";
export var completed = "styles-module__completed___oaAsR";
export var emptyHeader = "styles-module__emptyHeader___LPDY8";
export var favorite = "styles-module__favorite___sX9f8";
export var icon = "styles-module__icon___OykY6";
export var iconText = "styles-module__iconText___YSeeT";
export var loadingContainer = "styles-module__loadingContainer___c47Jx";
export var loadingSortingMessage = "styles-module__loadingSortingMessage___VMXFS";
export var photos = "styles-module__photos___Svjre";
export var recordings = "styles-module__recordings___fc2p2";
export var reviews = "styles-module__reviews___JoFyX";
export var tabBar = "styles-module__tabBar___sYOoq";
export var tabContainer = "styles-module__tabContainer___Nl7zs";