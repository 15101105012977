import { FormattedMessage } from '@alltrails/shared/react-intl';
import { Modal } from '@alltrails/modules/Modal';
import Button from '@alltrails/shared/denali/components/Button';
import AvatarStack from '@alltrails/shared/denali/components/AvatarStack';
import Typography from '@alltrails/shared/denali/components/Typography';
import User from '@alltrails/shared/types/User';
import { wrapUrlSafe } from 'utils/language_support_util';
import LanguageRegionCode from '@alltrails/shared/types/LanguageRegionCode';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import * as styles from './styles/styles.module.scss';

type Props = {
  isOpen: boolean;
  closeModal: () => void;
  currentUser: User;
  activityOwner: User;
};

const getAvatars = (users: User[], languageRegionCode: LanguageRegionCode) =>
  users.map(user => ({
    userId: user.id,
    userName: `${user.firstName} ${user.lastName}`,
    linkInfo: {
      href: wrapUrlSafe(`/members/${user.slug}`, languageRegionCode)
    },
    hasPlus: user.pro
  }));

function TagalongConfirmationModal({ isOpen, closeModal, currentUser, activityOwner }: Props) {
  const languageRegionCode = useLanguageRegionCode();

  // Capitalize first name via javascript to avoid adding html to translations
  const capitalizedFirstName = activityOwner.firstName.charAt(0).toUpperCase() + activityOwner.firstName.slice(1);

  return (
    <Modal isOpen={isOpen} onCloseRequest={closeModal} shouldHideHeader>
      <div className={styles.container}>
        <AvatarStack totalAvatars={2} avatars={getAvatars([activityOwner, currentUser], languageRegionCode)} testId="" />
        <div className={styles.textContainer}>
          <Typography variant="heading500">
            <FormattedMessage defaultMessage={"You joined {capitalizedFirstName}'s activity"} values={{ capitalizedFirstName }} />
          </Typography>
          <Typography variant="text200" className={styles.description}>
            <FormattedMessage defaultMessage="Relive and share this memory anytime from your profile." />
          </Typography>
        </div>
        <Button size="md" onClick={closeModal} variant="primary" text={<FormattedMessage defaultMessage="Check it out" />} testId="tagalong-cta" />
      </div>
    </Modal>
  );
}

export default TagalongConfirmationModal;
