import { defineMessages, useIntl } from '@alltrails/shared/react-intl';
import { APIErrors } from 'types/Errors';

const errorMessages = defineMessages({
  unexpectedError: {
    defaultMessage: 'An unexpected error occurred'
  },
  saved_filter_name_not_unique: {
    defaultMessage: 'Name already in use'
  },
  saved_filter_name_too_long: {
    defaultMessage: 'Name is too long'
  }
});

type ErrorCode = 'saved_filter_name_not_unique' | 'saved_filter_name_too_long' | 'unexpectedError';

function errorCodeToErrorMessage(errorCode: ErrorCode) {
  return errorMessages[errorCode] || errorMessages.unexpectedError;
}

export default function useErrorParsing(): { errorsToMessage: (errors: APIErrors) => string } {
  const intl = useIntl();

  return {
    errorsToMessage: (errors: APIErrors) => {
      const errorCode = errors?.errors?.[0]?.code as ErrorCode;

      return intl.formatMessage(errorCodeToErrorMessage(errorCode));
    }
  };
}
