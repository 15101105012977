const addImage = (map, imageId, image, imageSrc, opts) => {
  opts = opts || {};

  if (!map.hasImage(imageId)) {
    map.addImage(imageId, image, opts);
  }

  const customImages = map.customStyle.images;
  const idx = customImages.findIndex(i => i.imageId === imageId);
  if (idx < 0) {
    customImages.push({
      imageId,
      image,
      imageSrc,
      opts
    });
  }
};

const removeImage = (map, imageId) => {
  if (map.hasImage(imageId)) {
    map.removeImage(imageId);
  }
  const customImages = map.customStyle.images;
  const idx = customImages.findIndex(i => i.imageId === imageId);
  if (idx >= 0) {
    customImages.splice(idx, 1);
  }
};

const loadAndAddImage = (map, imageId, imageSrc, opts) => {
  return new Promise((resolve, reject) => {
    if (map.hasImage(imageId)) {
      resolve();
      return;
    }
    map.loadImage(imageSrc, (error, image) => {
      if (error) {
        reject(error);
        return;
      }
      addImage(map, imageId, image, imageSrc, opts);
      map.triggerRepaint();
      resolve();
    });
  });
};

export { addImage, removeImage, loadAndAddImage };
