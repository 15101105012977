import { useEffect } from 'react';
import { FormattedMessage } from '@alltrails/shared/react-intl';
import Typography from '@alltrails/shared/denali/components/Typography';
import useUser from 'hooks/useUser';
import connect from 'components/icons/connect.svg';
import heartSrc from 'components/icons/heart.svg';
import logReferralDashboardViewed from '@alltrails/analytics/events/logReferralDashboardViewed';
import * as styles from './YourInvitesModal.module.scss';

// https://www.figma.com/file/gZaqAej8HzoFkyrjlQ4cNs/Integrating-Brand-Changes?node-id=7560%3A88962
export default function YourInvitesModal() {
  const { trees_planted } = useUser();

  useEffect(() => {
    logReferralDashboardViewed();
  }, []);

  return (
    <Typography className={styles.content} component="div">
      {trees_planted === 0 ? <img alt="" className={styles.container} src={connect} /> : <img alt="" className={styles.heart} src={heartSrc} />}
      <Typography component="div" mb="12" variant="heading700">
        {trees_planted}
      </Typography>
      <Typography component="div" mb="24" variant="heading400">
        <FormattedMessage
          defaultMessage="{count, plural, one {Tree} other {Trees}} planted"
          values={{
            count: trees_planted
          }}
        />
      </Typography>
      {trees_planted > 0 ? (
        <Typography component="div" color="secondary" mb="64" variant="paragraph200">
          <FormattedMessage defaultMessage="Your trees support the One Tree Planted mission to restore damage from wildfires and deforestation worldwide." />
        </Typography>
      ) : (
        <div className={styles.spacer} />
      )}
    </Typography>
  );
}
