import { ReactNode, createContext, useCallback, useContext, useEffect, useMemo } from 'react';
import useResizeListener from 'hooks/useResizeListener';
import { refreshOverlaysOnPan } from 'utils/mapbox/additionalStyleLoaders';
import FlyoverSettings from 'types/FlyoverSettings';
import { IsochroneData } from 'types/Map';
import useMapLoadedEvents from './useMapLoadedEvents';
import useRegisterMapMoveHandler from './useRegisterMapMoveEndHandler';
import { getMapCenter, getMapZoom } from './map';
import useIsochroneMapLayer from './useIsochroneMapLayer';
import useFlyover from './useFlyover';

export type OnMapMoveArgs = {
  center: [number, number];
  bounds: [number, number, number, number];
  zoom: number;
};

export type OnMapLoadedArgs = {
  center: [number, number];
  zoom: number;
};

type Props = {
  children?: ReactNode;
  // deprecated we should eventually move this to MapProvider.
  getOverlayConfigParams: () => unknown;
  isNewMapsPage?: boolean;
  isochroneData: IsochroneData;
  // deprecated we should eventually create this in MapProvider.
  mapInstance: mapboxgl.Map;
  atMap: any; // TODO: atMap type
  initFlyoverOnLoad?: boolean;
  isFlyoverSupported?: boolean;
};

type MapValue = {
  isMapLoaded: boolean;
  mapInstance: mapboxgl.Map;
  flyoverSettings: FlyoverSettings;
};

const MapContext = createContext<MapValue>({
  isMapLoaded: false,
  mapInstance: null,
  flyoverSettings: {} as FlyoverSettings
});

function MapProvider({
  children,
  getOverlayConfigParams,
  isochroneData,
  mapInstance,
  atMap,
  initFlyoverOnLoad,
  isNewMapsPage,
  isFlyoverSupported
}: Props) {
  useRegisterMapMoveHandler({
    mapInstance,
    eventHandler: useCallback(() => {
      // Generic behavior we always want for a map.
      refreshOverlaysOnPan(mapInstance, getOverlayConfigParams());
    }, [mapInstance, getOverlayConfigParams])
  });

  const { didMapLoadEventsFire } = useMapLoadedEvents(mapInstance, isNewMapsPage);

  useIsochroneMapLayer({ mapInstance, isochroneData, isNewMapsPage });

  const flyoverSettings = useFlyover(mapInstance, atMap, isNewMapsPage, initFlyoverOnLoad, isFlyoverSupported);

  useResizeListener({
    debounceTiming: 300,
    onResize: useCallback(() => {
      mapInstance?.resize();
    }, [mapInstance])
  });

  const value = useMemo(
    () => ({
      isMapLoaded: didMapLoadEventsFire,
      mapInstance,
      flyoverSettings
    }),
    [didMapLoadEventsFire, flyoverSettings, mapInstance]
  );

  return <MapContext.Provider value={value}>{children}</MapContext.Provider>;
}

function useFlyoverSettings() {
  const { flyoverSettings } = useContext(MapContext);

  if (!Object.keys(flyoverSettings).length) {
    throw new Error("MapProvider's flyover settings are not defined. Are you using useFlyoverSettings within MapProvider?");
  }

  return flyoverSettings;
}

type MapConsumerShimProps = {
  onMapMove?: (args: OnMapMoveArgs) => void;
  onMapFirstLoaded?: (args: OnMapLoadedArgs) => void;
};

/**
 * MapConsumerShim only exists to support class components that cannot use hooks.
 *
 * @deprecated
 * @param {MapConsumerShimProps}
 * @returns
 */
function MapConsumerShim({ onMapMove, onMapFirstLoaded }: MapConsumerShimProps): JSX.Element {
  const { mapInstance, isMapLoaded } = useContext(MapContext);

  useRegisterMapMoveHandler({ mapInstance, eventHandler: onMapMove });

  useEffect(() => {
    if (isMapLoaded) {
      onMapFirstLoaded?.({
        center: getMapCenter(mapInstance),
        zoom: getMapZoom(mapInstance)
      });
    }
  }, [isMapLoaded, mapInstance, onMapFirstLoaded]);

  // This is a shim and renders nothing. It only exists to bridge the hook call
  // for legacy components that cannot use hooks directly.
  return null;
}

export { MapConsumerShim, MapContext, MapProvider, useFlyoverSettings };
