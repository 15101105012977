import { useState } from 'react';
import { useIntl, FormattedMessage } from '@alltrails/shared/react-intl';
import Button from '@alltrails/shared/denali/components/Button';
import LanguageRegionCode from '@alltrails/shared/types/LanguageRegionCode';
import { adjustToSameNominalTimeForUser, monthDayYear } from '@alltrails/shared/utils/timeHelpers';
import Alert from '@alltrails/shared/types/alert';
import * as formStyles from '../TrailDetailItemForm/styles/styles.module.scss';
import * as styles from './styles/styles.module.scss';
import TrailAlertModal from '../../TrailAlertModal';
import { wrapEventHandler } from '../../../utils/handlers';
import { alertTypeLabel, defaultAlertType } from '../../../utils/alerts_helpers';

type TrailDetailAlertsEditorProps = {
  errorMessage: string | React.ReactNode;
  handleCancelPress: () => void;
  handleSubmit: (newItems: Alert[]) => void;
  items: Alert[];
  submitting: boolean;
};

const TrailDetailAlertsEditor = ({ errorMessage, handleCancelPress, handleSubmit, items, submitting }: TrailDetailAlertsEditorProps): JSX.Element => {
  const [alerts, setAlerts] = useState<Alert[]>(items);
  const [alertModalIndex, setAlertModalIndex] = useState<number | null>(null);
  const [showAlertModal, setShowAlertModal] = useState<boolean>(false);
  const intl = useIntl();

  const openAlertModal = (index: number | null) => {
    setAlertModalIndex(index);
    setShowAlertModal(true);
  };

  const closeAlertModal = () => {
    setShowAlertModal(false);
  };

  const handleAlertModalSubmit = (formParams: Alert) => {
    const newAlerts = [...alerts];
    if (alertModalIndex !== null) {
      newAlerts[alertModalIndex] = formParams;
    } else {
      newAlerts.push(formParams);
    }
    setAlerts(newAlerts);
    setShowAlertModal(false);
  };

  const addItem = () => {
    if (submitting) {
      return;
    }
    openAlertModal(null);
  };

  const editItem = (index: number) => {
    if (submitting) {
      return;
    }
    openAlertModal(index);
  };

  const deleteItem = (index: number) => {
    if (submitting) {
      return;
    }
    const newAlerts = [...alerts];
    alerts[index].deleted = true;
    setAlerts(newAlerts);
  };

  const handleSubmitInternal = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (submitting) {
      return;
    }

    handleSubmit(alerts);
  };

  return (
    <form onSubmit={handleSubmitInternal}>
      <table className={styles.itemTable}>
        <tbody>
          {alerts.map((item, i) => {
            if (item.deleted) {
              return null;
            }
            let scheduledStart = '';
            let scheduledEnd = '';
            if (item.startDate) {
              const date = adjustToSameNominalTimeForUser(item.startDate);
              scheduledStart = `, starts ${monthDayYear(date, LanguageRegionCode.English, false)}`;
            }
            if (item.endDate) {
              const recurs = item.startDate && item.recursAnnually ? ', recurs' : '';
              const date = adjustToSameNominalTimeForUser(item.endDate);
              scheduledEnd = `, ends ${monthDayYear(date, LanguageRegionCode.English, false)}${recurs}`;
            }
            return (
              !item.deleted && (
                <tr key={item.id}>
                  <td>
                    <div>
                      <b>{item.shortName}</b>
                    </div>
                    <div>{item.content}</div>
                    <div>
                      <i>
                        {alertTypeLabel(item.alertType, intl) || defaultAlertType(intl).value}
                        {scheduledStart}
                        {scheduledEnd}
                      </i>
                    </div>
                  </td>
                  <td>
                    <div className={styles.buttonGroup}>
                      <Button text="Edit" onClick={() => editItem(i)} testId="trail-details-alerts-editor-edit" size="sm" />
                      <Button text="Delete" onClick={() => deleteItem(i)} testId="trail-details-alerts-editor-delete" size="sm" />
                    </div>
                  </td>
                </tr>
              )
            );
          })}
          <tr>
            <td>
              <Button onClick={addItem} testId="admin-alerts-add" text="Add" />
            </td>
          </tr>
        </tbody>
      </table>
      <div className={formStyles.buttonContainer}>
        <Button
          loading={submitting}
          testId="trail-alerts-edit-submit"
          text={<FormattedMessage defaultMessage="Submit" />}
          type="submit"
          variant="primary"
          width="full"
        />
        <Button onClick={handleCancelPress} text={<FormattedMessage defaultMessage="Cancel" />} testId="trail-alerts-cancel" variant="flat" />
      </div>
      {errorMessage && <p className={styles.errorMsg}>{errorMessage}</p>}
      {showAlertModal && (
        <TrailAlertModal
          closeModal={closeAlertModal}
          handleSubmit={params => handleAlertModalSubmit(params)}
          showAreaFields={false}
          alert={alertModalIndex !== null ? alerts[alertModalIndex] : null}
        />
      )}
    </form>
  );
};

export default TrailDetailAlertsEditor;
