import { useContext } from 'react';
import { defineMessages, useIntl } from '@alltrails/shared/react-intl';
import Button from '@alltrails/shared/denali/components/Button';

import LearnMore from '../LearnMore';
import { PrintContext } from '../../printContext';
import { Orientations, ORIENTATION_LABELS } from '../../constants';
import useFormatMessage from '@alltrails/shared/hooks/useFormatMessage';

type Props = {
  isMobileWidth?: boolean;
};

const ORIENTATION_STRINGS = defineMessages({
  ORIENTATION_: { defaultMessage: 'Orientation' },
  CONTENT: {
    defaultMessage:
      'The main difference between landscape and portrait image orientation is that a landscape image is wider than it is taller while a portrait image is taller than it is wider.'
  }
});

const Orientation = ({ isMobileWidth = false }: Props) => {
  const {
    formattedDefaultMessages: { ORIENTATION_, CONTENT }
  } = useFormatMessage(ORIENTATION_STRINGS);
  const { orientation, setOrientation } = useContext(PrintContext);
  const intl = useIntl()

  return (
    <div className="buttonRow">
      {Object.values(Orientations).map(orient => (
        <Button
          text={intl.formatMessage(ORIENTATION_LABELS[orient])}
          className="settingsButton"
          key={`orientation-${orient}`}
          onClick={() => setOrientation(orient)}
          testId={intl.formatMessage(ORIENTATION_LABELS[orient])}
          variant={orientation === orient ? 'primary' : 'default'}
        />
      ))}
      <LearnMore title={ORIENTATION_} content={CONTENT} orientation isMobileWidth={isMobileWidth} />
    </div>
  );
};

export default Orientation;
