import { bool, object } from 'prop-types';
import { defineMessages, FormattedMessage } from '@alltrails/shared/react-intl';
import UserPropType from '../../../../proptypes/UserPropType';
import { MapStatsUtil } from '../../../../utils/map_stats_util';
import useFormatMessage from '@alltrails/shared/hooks/useFormatMessage';
import SplitSection from '../SplitSection';
import * as styles from './styles/styles.module.scss';

const SPLITS_STRINGS = defineMessages({
  AVG_SPEED: { defaultMessage: 'Avg speed' },
  PACE: { defaultMessage: 'Pace' },
  KILOMETERS: { defaultMessage: 'Kilometers' },
  MILES: { defaultMessage: 'Miles' }
});

// https://www.figma.com/file/gZaqAej8HzoFkyrjlQ4cNs/Integrating-Brand-Changes?node-id=3341%3A91772
const SplitsTab = ({ splits, displayMetric, currentUser }) => {
  const {
    formattedDefaultMessages: { AVG_SPEED, PACE, KILOMETERS, MILES, ELEV_GAIN }
  } = useFormatMessage(SPLITS_STRINGS);
  const displaySpeed = currentUser && currentUser.displaySpeed;
  const paceSpeedTitle = displaySpeed ? AVG_SPEED : PACE;
  const splitsData = displayMetric ? splits.metric : splits.imperial;

  return (
    <div className={styles.splitsContainer}>
      <div className={styles.splitLabels}>
        <span className={styles.splitLabel}>{displayMetric ? KILOMETERS : MILES}</span>
        <span className={styles.splitLabel}>{paceSpeedTitle}</span>
        <span className={styles.splitLabel}>
          {' '}
          <FormattedMessage defaultMessage="Elev. gain" />{' '}
        </span>
      </div>
      {splitsData.map((split, i) => {
        return (
          <div className={styles.splits} key={i}>
            <SplitSection data={split.distance.toFixed(1)} />
            <SplitSection
              data={`${displaySpeed ? split.avg_speed.toFixed(2).toString() : MapStatsUtil.secondsToTimeNo24HourLimit(split.pace)}`}
              pace
              splitBar={split.bar}
            />
            <SplitSection data={MapStatsUtil.formatData(split.elevation, 0, displayMetric ? ' m' : ' ft')} />
          </div>
        );
      })}
    </div>
  );
};

SplitsTab.propTypes = {
  splits: object,
  displayMetric: bool,
  currentUser: UserPropType
};

SplitsTab.defaultProps = {
  splits: {},
  displayMetric: false,
  currentUser: {}
};

export default SplitsTab;
