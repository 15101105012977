import { useState, Suspense, useCallback, lazy } from 'react';
import { useIntl, defineMessages } from '@alltrails/shared/react-intl';
import useFormatMessage from '@alltrails/shared/hooks/useFormatMessage';
import { createPortal } from 'react-dom';
import TextInput from '@alltrails/shared/denali/components/TextInput';
import TextArea from '@alltrails/shared/denali/components/TextArea';
import Select from '@alltrails/shared/denali/components/Select';
import PrivacyTrigger from '@alltrails/shared/components/PrivacyTrigger';
import useActivityStrings from '@alltrails/shared/hooks/useActivityStrings';
import DatePicker from '@alltrails/shared/components/DatePicker';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import logCustomMapFeatureSubmitted from '@alltrails/analytics/events/logCustomMapFeatureSubmitted';
import CustomMapFeatureAdded from '@alltrails/analytics/enums/CustomMapFeatureAdded';
import { policyToRadioForm, CUSTOM_MAP, visibilityToTranslatedOption } from '../../../utils/privacy_policy_helpers';
import FormButtons from '../FormButtons';
import { form, formField, dateSelect, formDateActivity, privacyTrigger, dateBlock, dateLabel } from './styles/styles.module.scss';

const IndividualPrivacyModal = lazy(() => import('../../privacyPolicy/modals/IndividualPrivacyModal'));

const DESCRIPTION_FORM_STRINGS = defineMessages({
  TITLE_LABEL: { defaultMessage: 'Name your map' },
  DESCRIPTION_LABEL: { defaultMessage: 'Add notes about your route' },
  PRIVACY: { defaultMessage: 'Privacy level' },
  ACTIVITY: { defaultMessage: 'Activity' },
  DATE: { defaultMessage: 'Date' }
});

type Props = {
  updateChangeset: (prevState: object) => void;
  changeset: {
    title: string;
    description: string;
    activity: {
      value: string;
    };
    date: Date;
    contentPrivacy: string;
  };
  initialChangeset: object;
  isSubmitting?: boolean;
  handleDescriptionSave?: (e: React.FormEvent<HTMLFormElement>, fromSubForm?: boolean) => void;
  closeDescriptionForm?: () => void;
};

const DescriptionForm = ({
  initialChangeset,
  changeset,
  updateChangeset,
  handleDescriptionSave,
  isSubmitting,
  closeDescriptionForm
}: Props): JSX.Element => {
  const {
    formattedDefaultMessages: { TITLE_LABEL, DESCRIPTION_LABEL, PRIVACY, ACTIVITY, DATE }
  } = useFormatMessage(DESCRIPTION_FORM_STRINGS);
  const intl = useIntl();
  const [showModal, setShowModal] = useState(false);
  const { activitySelectOptions, findByValue } = useActivityStrings();
  const languageRegionCode = useLanguageRegionCode();

  const onTitleChange = useCallback(
    (title: string) => {
      updateChangeset((prevState: object) => ({ ...prevState, title }));
    },
    [updateChangeset]
  );

  const onDescriptionChange = useCallback(
    (description: string) => {
      updateChangeset((prevState: object) => ({ ...prevState, description }));
    },
    [updateChangeset]
  );

  const onDateChange = (date: Date) => {
    updateChangeset((prevState: object) => ({ ...prevState, date }));
  };

  const onContentPrivacyChange = (contentPrivacy: string) => {
    updateChangeset((prevState: object) => ({ ...prevState, contentPrivacy }));
  };

  const onSelect = (value: string) => {
    updateChangeset((prevState: object) => ({ ...prevState, activity: findByValue(value) }));
  };

  const onClear = () => {
    updateChangeset(initialChangeset);
    closeDescriptionForm();
  };

  const saveDescriptionForm = (e: React.FormEvent<HTMLFormElement>) => {
    closeDescriptionForm();
    logCustomMapFeatureSubmitted({ feature_added: CustomMapFeatureAdded.MapDescription });
    handleDescriptionSave(e, true);
  };

  return (
    <>
      <form className={form} data-testid="description-data-form" onSubmit={saveDescriptionForm}>
        <div>
          <TextInput
            labelText={TITLE_LABEL}
            value={changeset.title}
            onChange={onTitleChange}
            testId="description-form-text-field"
            className={formField}
          />
        </div>
        <div>
          <TextArea
            labelText={DESCRIPTION_LABEL}
            onChange={onDescriptionChange}
            testId="description-form-description"
            rows={3}
            value={changeset.description || ''}
            className={formField}
          />
        </div>
        <div className={formDateActivity}>
          <Select
            labelText={ACTIVITY}
            placeholder=""
            options={activitySelectOptions}
            onChange={value => onSelect(value)}
            value={changeset?.activity?.value}
            testId="activity-type-select"
          />
          <div className={dateBlock}>
            <div className={dateLabel}>{DATE}</div>
            <DatePicker
              abbreviateMonth
              materialRootClass={dateSelect}
              date={changeset.date}
              disableFuture={false}
              onChange={onDateChange}
              languageRegionCode={languageRegionCode}
            />
          </div>
        </div>
        <PrivacyTrigger
          className={privacyTrigger}
          onClick={() => setShowModal(true)}
          label={PRIVACY}
          currentOption={visibilityToTranslatedOption(changeset.contentPrivacy, intl)}
          testId="description-privacy-trigger"
        />
        <FormButtons onCancel={onClear} disabled={isSubmitting} />
      </form>
      {showModal &&
        createPortal(
          <Suspense fallback={null}>
            <IndividualPrivacyModal
              includeModalBackground
              closeModal={() => setShowModal(false)}
              isMounted={showModal}
              acceptCallback={onContentPrivacyChange}
              {...policyToRadioForm(CUSTOM_MAP, changeset.contentPrivacy, intl)}
            />
          </Suspense>,
          document.getElementById('modalPortal')
        )}
    </>
  );
};

export default DescriptionForm;
