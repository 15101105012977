import { useEffect } from 'react';

/**
 * useDebounce debounces a given function with useEffect. If the timeout or the
 * given function changes then the hook will run, cancel existing timers, and
 * re-render. A caller should implement useCallback or similar to control the
 * function changing or not.
 *
 * @param {() => void} fn
 * @param {number} timeout
 */
export default function useDebounce(fn: () => void, timeout: number) {
  useEffect(() => {
    const timer = setTimeout(() => fn(), timeout);

    return () => {
      clearTimeout(timer);
    };
  }, [fn, timeout]);
}
