export const localBusinessDataAttributes = (itemSlug: string) => ({
  itemType: 'http://schema.org/LocalBusiness',
  itemScope: true,
  itemID: `/${itemSlug}`
});

type LocalBusinessAddressProps = {
  city?: string;
  state?: string;
  country?: string;
};

// Address is a required property for the LocalBusiness schema
// Include this block wherever you're using the localBusinessDataAttributes
export const LocalBusinessAddress = ({ city, state, country }: LocalBusinessAddressProps) => {
  const addressLocality = [city, state, country].filter(n => !!n).join(', ');

  return (
    <span itemProp="address" itemScope itemType="http://schema.org/PostalAddress">
      <meta itemProp="addressLocality" content={addressLocality} />
    </span>
  );
};
