import getPageTitle from '@alltrails/shared/utils/getPageTitle';
import PageTitle from '@alltrails/shared/types/PageTitle';

type Props = {
  className?: string;
  title?: string;
};

const GoBack = ({ className, title }: Props) => {
  const checkExploreTrailPage = () => {
    const pageTitle = getPageTitle(window.location.pathname);
    return pageTitle === PageTitle.ExploreMapDetails || pageTitle === PageTitle.ExploreRecordingDetails || pageTitle === PageTitle.TrailDetails;
  };

  const navigateBack = () => {
    const isExploreTrailPage = checkExploreTrailPage();
    if (isExploreTrailPage) {
      const previousPath = window.location.pathname.split('/').slice(0, -1).join('/');
      window.location.replace(previousPath);
    } else {
      window.history.back();
    }
  };

  return (
    <div className={`backContainer ${className}`}>
      <span className="backArrow" />
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <span className="backSpan" onClick={navigateBack}>
        {title}
      </span>
    </div>
  );
};

GoBack.defaultProps = {
  className: '',
  title: ''
};

export default GoBack;
