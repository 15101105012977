import TrailDetailsSeeAllLocation from '@alltrails/analytics/enums/TrailDetailsSeeAllLocation';
import TrailDetailsSortLocation from '@alltrails/analytics/enums/TrailDetailsSortLocation';
import TrailDetailsSortMethodType from '@alltrails/analytics/enums/TrailDetailsSortMethodType';
import SortOption from '../types/SortOption';
import TrailUgcTab from '../types/TrailUgcTab';

export function getAnalyticsSort(sortOption: SortOption): TrailDetailsSortMethodType | null {
  switch (sortOption) {
    case 'atSort':
      return TrailDetailsSortMethodType.AlltrailsSort;
    case 'desc':
      return TrailDetailsSortMethodType.NewestFirst;
    case 'asc':
      return TrailDetailsSortMethodType.OldestFirst;
    case 'highestRated':
      return TrailDetailsSortMethodType.HighestRated;
    case 'lowestRated':
      return TrailDetailsSortMethodType.LowestRated;
    default:
      return null;
  }
}

export function getAnalyticsSortLocation(tab: TrailUgcTab): TrailDetailsSortLocation | null {
  switch (tab) {
    case 'reviews':
      return TrailDetailsSortLocation.ReviewsTab;
    case 'photos':
      return TrailDetailsSortLocation.PhotosTab;
    case 'tracks':
      return TrailDetailsSortLocation.ActivitiesTab;
    default:
      return null;
  }
}

export function getAnalyticsSeeAllLocation(tab: TrailUgcTab): TrailDetailsSeeAllLocation | null {
  switch (tab) {
    case 'reviews':
      return TrailDetailsSeeAllLocation.Reviews;
    case 'photos':
      return TrailDetailsSeeAllLocation.Photos;
    case 'completed':
      return TrailDetailsSeeAllLocation.Completed;
    case 'tracks':
      return TrailDetailsSeeAllLocation.Activities;
    default:
      return null;
  }
}
