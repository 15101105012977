import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { defineMessages } from '@alltrails/shared/react-intl';
import type { Context } from 'types/Context';
import compareUserIds from '@alltrails/shared/utils/compareUserIds';
import { Activity } from 'types/Activity';
import Expandable from '@alltrails/shared/components/Expandable';
import useFormatMessage from '@alltrails/shared/hooks/useFormatMessage';
import { updateTrack } from '@alltrails/modules/ActivityUpload/requests';
import SuccessToast from '@alltrails/shared/types/successToast';
import { userCanEdit } from 'utils/UserUtils';
import hasPermission from 'utils/hasPermission';
import * as styles from './styles/styles.module.scss';
import { ServerCommunicationUtil } from '../../../utils/server_communication_util';
import { LanguageSupportUtil } from '../../../utils/language_support_util';
import TrackDetailsCard from '../../cards/TrackDetailsCard';
import LinkedCard from '../LinkedCard';
import PhotosSection from '../Photos/PhotosSection';
import SplitsTab from '../Splits/SplitsTab';
import StatsTab from '../Stats/StatsTab';
import DescriptionSection from '../DescriptionSection';
import WaypointsWrapper from '../../Waypoints/WaypointsWrapper';
import { AnonymousMapUsername } from '../../../Constants';

const TRACK_PANEL_STRINGS = defineMessages({
  UNHIDE: { defaultMessage: 'Unhide' },
  HIDE: { defaultMessage: 'Hide' },
  UNHIDING: { defaultMessage: 'Unhiding' },
  HIDING: { defaultMessage: 'Hiding' },
  FAILED: { defaultMessage: 'Failed' },
  NOTES: { defaultMessage: 'Notes' },
  ADD_NOTES: { defaultMessage: 'Add notes about your activity' },
  WAYPOINTS: { defaultMessage: 'Waypoints' },
  PHOTOS: { defaultMessage: 'Photos' },
  SPLITS: { defaultMessage: 'Splits' }
});

type Props = {
  data: Activity;
  context: Context;
  isMobileWidth?: boolean;
  handlePhotosUpload?: (photoDetails: string) => void;
  handleMapInfoChanged?: (data: any) => void;
  messagingChannel?: {
    publish: () => void;
    subscribe: () => void;
  };
  result?: {
    ID: number;
    type: string;
    slug: string;
    contentPrivacy: string;
  };
  openTrailFormModal?: () => void;
  resultCardFunctions?: any;
  listMethods?: any;
  handleTrackUpdate?: (detail: any) => void;
  handleShareClick?: () => void;
  saveMapAsClone?: () => void;
  blockNonUserCreation?: () => void;
  currentPage?: string;
  showMapMobile?: () => void;
  trackTrail?: any;
  setReportingSuccessToast: Dispatch<SetStateAction<SuccessToast | null>>;
};

const TrackPanel = ({
  context,
  data,
  isMobileWidth,
  handlePhotosUpload,
  handleMapInfoChanged,
  messagingChannel,
  result,
  openTrailFormModal,
  resultCardFunctions,
  listMethods,
  handleTrackUpdate,
  handleShareClick,
  saveMapAsClone,
  blockNonUserCreation,
  currentPage,
  showMapMobile,
  trackTrail,
  setReportingSuccessToast
}: Props): JSX.Element => {
  const initialAccordionStates = {
    description: data?.description !== '',
    waypoints: data?.waypoints?.length > 0,
    photos: data?.mapPhotos?.length > 0,
    splits: data?.splits?.length > 0
  };

  const {
    formattedDefaultMessages: { UNHIDE, HIDE, UNHIDING, HIDING, FAILED, NOTES, ADD_NOTES, WAYPOINTS, PHOTOS, SPLITS }
  } = useFormatMessage(TRACK_PANEL_STRINGS);
  const [hidden, setIsHidden] = useState(null);
  const [hideStatusText, setHideStatusText] = useState(null);
  const [accordionsOpened, updateAccordionState] = useState(initialAccordionStates);
  const [descriptionValue, setDescriptionValue] = useState('');
  const [isEditShown, setIsEditShown] = useState(false);
  const [submitInProgress, setSubmitInProgress] = useState(false);
  const [submitFailed, setSubmitFailed] = useState(false);
  const [activityRating, updateActivityRating] = useState<number>(data?.rating);

  const updateSuccessCallback = (mapData: Activity[]) => {
    const payload = { detail: mapData[0] };
    handleTrackUpdate(payload);
  };

  const handleRatingChange = (e: React.ChangeEvent<HTMLInputElement>, updateRating: number) => {
    e.preventDefault();
    updateActivityRating(updateRating);
    updateTrack(data.id, { rating: updateRating }, data.trailId)
      .then(updateSuccessCallback)
      .catch(err => console.error('Updating activity rating error:', err));
  };

  useEffect(() => {
    // set the description to editing state when the description does not yet exist and if
    // the user is viewing their own activity or an admin user is viewing any activity
    if (
      ((context?.currentUser && (data?.user?.id === context?.currentUser?.id || hasPermission({ permission: 'trails:manage' }))) ||
        data?.user == null ||
        data?.user?.username === AnonymousMapUsername) &&
      !data?.description
    ) {
      setIsEditShown(true);
    }
    // set hide/unhide linked trail option for admin users
    if (hasPermission({ permission: 'trails:manage' })) {
      setIsHidden(data.hidden);
    }
  }, [isMobileWidth, context, data]);

  useEffect(() => {
    setHideStatusText(hidden ? UNHIDE : HIDE);
  }, [hidden, HIDE, UNHIDE]);

  useEffect(() => {
    setDescriptionValue(data?.description || '');
  }, [data?.description]);

  const photosUpload = (photoDetails: string) => {
    if (handlePhotosUpload) {
      handlePhotosUpload(photoDetails);
    }
  };

  const handleEditDescription = (value: string) => {
    setDescriptionValue(value);
  };

  const handleClearDescription = () => {
    setDescriptionValue('');
  };

  const submitError = () => {
    setSubmitFailed(true);
    setSubmitInProgress(false);
  };

  const submitSuccess = (info: any) => {
    setIsEditShown(false);
    setSubmitInProgress(false);
    handleMapInfoChanged(info);
  };

  const handleDescriptionSubmit = (newDescription: string) => {
    const updateMapUrl = `/api/alltrails/maps/${data.id}?detail=deep&key=${ServerCommunicationUtil.apiKey}`;
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    ServerCommunicationUtil.putApiEndpoint(updateMapUrl, { map: { description: newDescription } }, submitSuccess, submitError, () => {});
  };

  const handleAddAsTrailClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    window.open(LanguageSupportUtil.wrapUrlSafe(`/trail/new?recording=${data?.id}`, context?.languageRegionCode), '_blank');
  };

  const handleHideShowTrackClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const url = `/api/alltrails/maps/${data?.id}/metadata`;
    setHideStatusText(hidden ? UNHIDING : HIDING);

    ServerCommunicationUtil.postApiEndpoint(
      url,
      { hidden: !hidden },
      () => setIsHidden(!hidden),
      () => setHideStatusText(FAILED),
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      () => {}
    );
  };

  const getSource = () => {
    if (!data || !data?.metadata || !data?.metadata.source) {
      return '?';
    }
    switch (data?.metadata.source) {
      case 1000:
        return 'iOS';
      case 1001:
        return 'Android';
      case 1043:
        return 'Garmin';
      case 1033:
        return 'Web';
      default:
        return 'Other';
    }
  };

  const toggleAccordionState = (accordionName: 'description' | 'waypoints' | 'photos' | 'splits') => {
    updateAccordionState(prevState => ({ ...prevState, [accordionName]: !prevState[accordionName] }));
  };

  const saveEdit = () => {
    handleDescriptionSubmit(descriptionValue);
    setSubmitInProgress(true);
    setSubmitFailed(false);
  };

  const is3PViewerOrAnonymousUser = !compareUserIds(context?.currentUser?.id, data?.user?.id) || !context.currentUser;

  const renderNotes = () => {
    const isEmptyMemberSection = is3PViewerOrAnonymousUser && !data?.description_source && !data?.description;

    if (!isEmptyMemberSection) {
      return (
        <Expandable
          title={NOTES}
          externalControls={{ toggleIsOpen: () => toggleAccordionState('description'), isOpen: accordionsOpened.description }}
        >
          <DescriptionSection
            data={data?.description}
            dataSource={data?.description_source}
            handleEditDescription={handleEditDescription}
            handleClearDescription={handleClearDescription}
            placeholderText={ADD_NOTES}
            isEditShown={isEditShown}
            submitInProgress={submitInProgress}
            submitFailed={submitFailed}
            handleSubmit={saveEdit}
            descriptionValue={descriptionValue}
          />
        </Expandable>
      );
    }
    return null;
  };

  const renderWaypointsSection = () => {
    const isEmptyMemberSection = is3PViewerOrAnonymousUser && data?.waypoints?.length === 0;
    const canEdit = userCanEdit(context?.currentUser, data?.user, true);
    if (!isEmptyMemberSection || hasPermission({ permission: 'trails:manage' })) {
      return (
        <Expandable
          title={WAYPOINTS}
          externalControls={{ toggleIsOpen: () => toggleAccordionState('waypoints'), isOpen: accordionsOpened.waypoints }}
        >
          <WaypointsWrapper
            canEdit={canEdit}
            waypoints={data?.waypoints}
            mapId={result?.ID}
            messagingChannel={messagingChannel}
            className={styles.trackWaypointsContainer}
          />
        </Expandable>
      );
    }
    return null;
  };

  const renderPhotosTab = () => {
    const isEmptyMemberSection = is3PViewerOrAnonymousUser && data?.mapPhotos?.length === 0;
    if (!isEmptyMemberSection || hasPermission({ permission: 'trails:manage' })) {
      return (
        <Expandable title={PHOTOS} externalControls={{ toggleIsOpen: () => toggleAccordionState('photos'), isOpen: accordionsOpened.photos }}>
          <PhotosSection
            openTrailFormModal={openTrailFormModal}
            track={data}
            data={data?.mapPhotos}
            handlePhotosUpload={photosUpload}
            messagingChannel={messagingChannel}
            currentUser={context?.currentUser}
            linkedTrail={trackTrail ? { ...trackTrail, id: trackTrail.ID } : null}
            context={context}
          />
        </Expandable>
      );
    }
    return null;
  };

  const style = { height: '100%', bottom: 0, left: 0 };
  const showSource = context?.currentUser && hasPermission({ permission: 'trails:manage' }) && getSource();
  return (
    <div id="fullscreen-track-details" style={style}>
      <div className={styles.trackContainer}>
        <TrackDetailsCard
          context={context}
          forcePhotoId={resultCardFunctions.forcePhotoId(result?.type, result?.ID)}
          handleFavoriteClick={() => listMethods.handleFavoriteClick({ type: result?.type, id: result?.ID, contentPrivacy: result?.contentPrivacy })}
          height={180}
          isFavorite={listMethods.isFavorite(result?.type, result?.ID)}
          isMobileWidth={isMobileWidth}
          key={`track-${result?.ID}`}
          linkable={false}
          messagingChannel={messagingChannel}
          showStaticMap={false}
          tabletBrowser={context?.tabletBrowser}
          track={result}
          handleTrackUpdate={handleTrackUpdate}
          width={396}
          data={data}
          handleShareClick={handleShareClick}
          saveMapAsClone={saveMapAsClone}
          blockNonUserCreation={blockNonUserCreation}
          handleRatingChange={handleRatingChange}
          rating={activityRating}
          trackTrail={trackTrail}
          canEdit={userCanEdit(context?.currentUser, data?.user, true)}
          setReportingSuccessToast={setReportingSuccessToast}
        />
        <div className={styles.innerTrackDetails}>
          <StatsTab
            data={data}
            currentPage={currentPage}
            displayMetric={context?.displayMetric}
            currentUser={context?.currentUser as any}
            languageRegionCode={context?.languageRegionCode}
            isMobileWidth={isMobileWidth}
            showMapMobile={showMapMobile}
          />
          {data?.splits && (
            <Expandable
              title={`${SPLITS} ${showSource ? ` - ${getSource()}` : ''}`}
              externalControls={{ toggleIsOpen: () => toggleAccordionState('splits'), isOpen: accordionsOpened.splits }}
            >
              <SplitsTab splits={data?.splits} displayMetric={context?.displayMetric} currentUser={context?.currentUser as any} />
            </Expandable>
          )}
          {renderPhotosTab()}
          {renderWaypointsSection()}
          {renderNotes()}
          <LinkedCard
            trackTrail={trackTrail}
            context={context}
            data={data}
            hideText={hideStatusText}
            handleAddAsTrailClick={handleAddAsTrailClick}
            handleHideShowTrackClick={handleHideShowTrackClick}
            resultCardFunctions={resultCardFunctions}
            listMethods={listMethods}
            messagingChannel={messagingChannel}
          />
        </div>
      </div>
    </div>
  );
};

export default TrackPanel;
