import { FormattedMessage } from '@alltrails/shared/react-intl';
import Checkbox from '@alltrails/shared/denali/components/Checkbox';
import Button from '@alltrails/shared/denali/components/Button';
import Chevron from '@alltrails/shared/icons/Chevron';
import { wrapUrlSafe } from 'utils/language_support_util';

const createReactClass = require('create-react-class');

const ShareModalDrawerEmbed = createReactClass({
  getInitialState() {
    return {
      successfulCopy: false,
      noScrollZoom: false,
      hideElevation: false,
      hideName: false,
      showCustomization: false
    };
  },
  formatHtmlToCopy() {
    const iframeSrc = this.formatIframeSrc();
    return `<iframe class="alltrails" src="${iframeSrc}" width="100%" height="400" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" title="AllTrails: Trail Guides and Maps for Hiking, Camping, and Running"></iframe>`;
  },
  formatIframeSrc() {
    let type;
    switch (this.props.shareObject.type) {
      case 'track':
        type = 'recording/';
        break;
      case 'area':
      case 'point_of_interest':
      case 'country':
      case 'state':
      case 'city':
      case 'trail':
        type = '';
        break;
      default:
        type = `${this.props.shareObject.type}/`;
    }

    let urlBase = `${location.protocol}//${location.host}`;
    const queryStrings = [];
    if (this.state.noScrollZoom) {
      queryStrings.push('scrollZoom=false');
    }
    if (this.state.hideElevation) {
      queryStrings.push('elevationDiagram=false');
    }
    if (this.state.hideName) {
      queryStrings.push('hideName=true');
    }
    if (this.props.currentUser) {
      queryStrings.push(`u=${this.props.currentUser.metric ? 'm' : 'i'}`);
      queryStrings.push(`sh=${this.props.currentUser.referralCode}`);
    }

    const queryString = queryStrings.length > 0 ? `?${queryStrings.join('&')}` : '';
    const path = `/widget/${type}${this.props.shareObject.slug}${queryString}`;
    return `${urlBase}${wrapUrlSafe(path, this.props.languageRegionCode)}`;
  },
  clickNoScrollZoom() {
    this.setState({
      successfulCopy: false,
      noScrollZoom: !this.state.noScrollZoom
    });
  },
  clickHideElevation() {
    this.setState({
      successfulCopy: false,
      hideElevation: !this.state.hideElevation
    });
  },
  clickHideName() {
    this.setState({
      successfulCopy: false,
      hideName: !this.state.hideName
    });
  },
  copyHtml() {
    const htmlToCopy = this.formatHtmlToCopy();
    this.props.clipboard.writeText(htmlToCopy).then(
      function () {
        this.setState({ successfulCopy: true });
      }.bind(this),
      function (err) {
        console.error('Could not copy text: ', err);
      }
    );
  },
  toggleCustomization() {
    const showCustomization = !this.state.showCustomization;
    this.setState({
      showCustomization
    });
  },
  render() {
    const htmlToCopy = this.formatHtmlToCopy();
    const iframeSrc = this.formatIframeSrc();
    const copyButtonLabel = this.state.successfulCopy
      ? this.props.intl.formatMessage({ defaultMessage: 'HTML copied' })
      : this.props.intl.formatMessage({ defaultMessage: 'Copy HTML' });
    const copyHtmlButton = this.props.clipboard ? (
      <div className="copy-html">
        <Button text={copyButtonLabel} type="button" size="md" onClick={this.copyHtml} variant="primary" />
      </div>
    ) : null;
    const indicator = <Chevron orientation={this.state.showCustomization ? 'up' : 'down'} size="sm" />;
    let hideNameGroup = null;
    if (this.props.shareObject.ugc) {
      hideNameGroup = (
        <div className="checkbox-group">
          <div className="checkbox-holder">
            <Checkbox
              name="hideName"
              checked={this.state.hideName}
              handleChange={this.clickHideName}
              label={this.props.intl.formatMessage({ defaultMessage: 'Hide your name' })}
            />
          </div>
        </div>
      );
    }
    return (
      <div className="xlate-none">
        <div className="form-holder">
          <p className="intro-text">
            <FormattedMessage defaultMessage="To add this trail to your website, copy and paste the following HTML into your website code:" />
          </p>
          <textarea aria-label={<FormattedMessage defaultMessage="HTML" />} readOnly value={htmlToCopy} />
          <div className="customize-form">
            {copyHtmlButton}
            <p className="form-header" onClick={this.toggleCustomization}>
              <FormattedMessage defaultMessage="Customize your code:" /> {indicator}
            </p>
            <div className={`customization-holder ${this.state.showCustomization ? '' : 'hidden'}`}>
              <div className="checkbox-group">
                <div className="checkbox-holder">
                  <Checkbox
                    id="noScrollZoom"
                    testId="checkbox-noScrollZoom"
                    size="sm"
                    onChange={this.clickNoScrollZoom}
                    selected={this.state.noScrollZoom}
                    labelElement={this.props.intl.formatMessage({ defaultMessage: 'Deactivate zoom with mouse' })}
                  />
                </div>
                <div className="checkbox-holder">
                  <Checkbox
                    id="hideElevation"
                    testId="checkbox-hideElevation"
                    size="sm"
                    selected={this.state.hideElevation}
                    onChange={this.clickHideElevation}
                    labelElement={this.props.intl.formatMessage({ defaultMessage: 'Hide elevation graph' })}
                  />
                </div>
              </div>
              {hideNameGroup}
            </div>
          </div>
        </div>
        <div className="preview">
          <p className="preview-title" id="preview">
            <FormattedMessage defaultMessage="Preview" />
          </p>
          <iframe aria-labelledby="preview" src={iframeSrc} scrolling="no" />
        </div>
      </div>
    );
  }
});

export { ShareModalDrawerEmbed };
