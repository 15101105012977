import mapboxgl from 'mapbox-gl';
import { atMapsToGeojson } from '@alltrails/maps/utils/legacyGeoJSONConversions';
import { defineMessages } from '@alltrails/shared/react-intl';
import CustomProvider from 'components/CustomProvider';
import isEqual from 'underscore/modules/isEqual';
import DoubleBack from '@alltrails/shared/icons/DoubleBack';
import CloseLoop from '@alltrails/shared/icons/CloseLoop';
import Reverse from '@alltrails/shared/icons/Reverse';
import Cut from '@alltrails/shared/icons/Cut';
import Split from '@alltrails/shared/icons/Split';
import Remove from '@alltrails/shared/icons/Remove';
import SearchPointPopup from '../../../../../components/MapCreator/SearchPointPopup';
import { mountReactComponent, getPopupDOMContent, unmountReactComponent } from '../../../../../utils/mapbox/event_handling/popup_helpers';
import { addDistanceMarkers } from '../../../../../utils/mapbox/overlays/distance_markers';
import { putAtMapIntoBackground, bringAtMapIntoForeground, makeAtMapVisible, makeAtMapInvisible } from '../../../../../utils/mapbox/overlays/at_map';
import { TrailPlanner } from '../../../../../utils/trail_planner/trail_planner';
import { removeMarkers } from '../../../../../utils/mapbox/overlays/markers';

import { updateLayerProperty, removeLayer } from '../../../../../utils/mapbox/layers/layer_helpers';
import {
  addTrailPlannerOverlay,
  removeTrailPlannerMap,
  removeInsertModeMap,
  routeNodesId,
  routeInsertModeNodesId,
  routePolylinesId,
  routeChevronsId,
  routeNodesDragId,
  routeInsertModeNodesDragId,
  newRouteNodeId
} from '../../../../../utils/mapbox/overlays/trail_planner_overlay';
import { setWindowExitGuard } from '../../../../../utils/window_helpers';
import { addSlidRoute, removeSlidRoute } from '../../../../../utils/mapbox/overlays/slid_route';

const MESSAGES = defineMessages({
  dragToChange: { defaultMessage: "Drag to change route, 'delete' to remove" },
  clickToAddPoint: { defaultMessage: 'Click to add point' },
  outSelected: { defaultMessage: 'Out Selected' },
  backSelected: { defaultMessage: 'Back Selected' },
  allSelected: { defaultMessage: 'All Selected' }
});

const CUT_TYPE = 'cut';
const INSERT_TYPE = 'insert';

const TrailPlannerMixin = {
  trailPlannerMessagingSubscriptions() {
    if (this.listeners == null) this.listeners = [];
    this.listeners.push(this.props.messagingChannel.subscribe('button.click.undo', this.undoClick));
    this.listeners.push(this.props.messagingChannel.subscribe('button.click.redo', this.redoClick));
    this.listeners.push(this.props.messagingChannel.subscribe('button.click.out_and_back', this.outAndBackClick));
    this.listeners.push(this.props.messagingChannel.subscribe('button.click.return', this.returnClick));
    this.listeners.push(this.props.messagingChannel.subscribe('button.click.reverse', this.reverseClick));
    this.listeners.push(this.props.messagingChannel.subscribe('map.click', this.mapClick));
    this.listeners.push(this.props.messagingChannel.subscribe('map.map_right_click', this.mapRightClick));
    this.listeners.push(this.props.messagingChannel.subscribe('map.shift_drag.start', this.mapShiftDragStart));
    this.listeners.push(this.props.messagingChannel.subscribe('map.shift_drag.end', this.mapShiftDragEnd));
    this.listeners.push(this.props.messagingChannel.subscribe('map.shift_drag.move', this.mapShiftDragMove));
    this.listeners.push(this.props.messagingChannel.subscribe('map.ctrl_key', this.mapCtrlKey));
    this.listeners.push(this.props.messagingChannel.subscribe('map.del_key', this.mapDelKey));
    this.listeners.push(this.props.messagingChannel.subscribe('map.esc_key', this.mapEscKey));
    this.listeners.push(this.props.messagingChannel.subscribe('route.edit.changeColor', this.handleRouteColorChange));
    this.listeners.push(this.props.messagingChannel.subscribe('edit.enable_trail_planner', this.enablePlanning));
    this.listeners.push(this.props.messagingChannel.subscribe('edit.enable_insert_mode', this.enableInsertMode));
    this.listeners.push(this.props.messagingChannel.subscribe('edit.disable_insert_mode', this.disableInsertMode));
    this.listeners.push(this.props.messagingChannel.subscribe('map.enter_return_key', this.applyInsertRouteSection));
    this.listeners.push(this.props.messagingChannel.subscribe('edit.disable_trail_planner', this.disablePlanning));
    this.listeners.push(this.props.messagingChannel.subscribe('edit.show_popup_at_lat_lng', this.showPopupAtLatLng));
    this.listeners.push(this.props.messagingChannel.subscribe('map.opt_z_key', this.enableSuggestedRoute));
  },
  showPopupAtLatLng(data) {
    const [lat, lng, placeName, zoom = 15] = data;
    const partialPlaceName = placeName.split(',')[0];
    const isStartingPoint = !this.state.trailPlanner.routeStart;
    const popupOptions = { closeButton: false, closeOnClick: false, offset: 15 };

    // different zoom behavior based on whether or not route is starting
    if (isStartingPoint) {
      this.setMapCenterAndZoom(lat, lng, zoom);
    } else {
      const bounds = this.mbMap.getBounds();
      this.mbMap.fitBounds(bounds.extend([lng, lat]), { padding: 200 });
    }

    const markerDot = document.createElement('div');
    markerDot.classList.add(isStartingPoint ? 'mapboxgl-green-dot-marker' : 'mapboxgl-gray-dot-marker');

    // create new marker
    const searchPointMarker = new mapboxgl.Marker({
      draggable: true,
      element: markerDot
    });

    const removeMarkerAndUnmountPopup = (marker, domContent) => {
      unmountReactComponent(domContent);
      marker.remove();
    };

    const addPointAndRemoveMarkerAndPopup = (coordinates, marker, domContent) => {
      const [longitude, latitude] = coordinates;
      this.state.trailPlanner.routeToPoint([longitude, latitude]);

      removeMarkerAndUnmountPopup(marker, domContent);
    };

    const DOMContent = mountReactComponent(
      <CustomProvider>
        <SearchPointPopup
          id="search-popup"
          onClose={() => removeMarkerAndUnmountPopup(searchPointMarker, DOMContent)}
          isStartingPoint={isStartingPoint}
          locationName={partialPlaceName}
          addToRoute={() => addPointAndRemoveMarkerAndPopup([lng, lat], searchPointMarker, DOMContent)}
        />
      </CustomProvider>
    );

    // create popup
    const initialPopup = new mapboxgl.Popup(popupOptions).setDOMContent(DOMContent);
    // add popup to marker
    searchPointMarker.setLngLat([lng, lat]).setPopup(initialPopup).addTo(this.mbMap).togglePopup();

    searchPointMarker.on('dragend', () => {
      // unmount current popup component
      const currentPopupContent = getPopupDOMContent(searchPointMarker.getPopup());
      unmountReactComponent(currentPopupContent);

      const coordinates = searchPointMarker.getLngLat();

      // update the domContent
      const updatedDOMContent = mountReactComponent(
        <CustomProvider>
          <SearchPointPopup
            onClose={() => removeMarkerAndUnmountPopup(searchPointMarker, updatedDOMContent)}
            isStartingPoint={isStartingPoint}
            addToRoute={() => addPointAndRemoveMarkerAndPopup([coordinates.lng, coordinates.lat], searchPointMarker, updatedDOMContent)}
          />
        </CustomProvider>
      );
      // update popup with new domContent
      initialPopup.setLngLat([coordinates.lng, coordinates.lat]).setDOMContent(updatedDOMContent);
    });
  },
  getActiveTrailPlanner() {
    return this.state.insertTrailPlanner || this.state.trailPlanner;
  },
  enablePlanning(route) {
    if (this.state.trailPlanner) {
      return;
    }

    setWindowExitGuard(true);

    // Update map's default cursor to 'crosshair'
    this.mbMap.customStyle.cursor = 'crosshair';
    this.mbMap.getCanvas().style.cursor = this.mbMap.customStyle.cursor;

    putAtMapIntoBackground(this.mbMap);

    const { updateProgressDialog } = this.props;
    const { routingActive, routingMode } = this.state;
    const { onRouteInfoChange } = this;
    // Attach to state immediately to prevent any race condition issues
    this.state.trailPlanner = new TrailPlanner({
      onRouteInfoChange,
      route,
      routingActive,
      routingMode,
      updateProgressDialog,
      slidRoute: this.state.slidRoute
    });
    this.state.trailPlanner.initRoutePolylines();
    this.setState({
      trailPlanner: this.state.trailPlanner,
      currentlyEditingRoute: route,
      selectedRouteNode: null
    });
  },
  enableSuggestedRoute() {
    if (!this.state.trailPlanner || !this.state.slidRoute) return;
    this.setRoutingMode('slid-route');
  },
  disablePlanning() {
    if (!this.state.trailPlanner) {
      return;
    }

    // Restore map's default cursor to ''
    this.mbMap.customStyle.cursor = '';
    this.mbMap.getCanvas().style.cursor = this.mbMap.customStyle.cursor;

    removeTrailPlannerMap(this.mbMap);
    bringAtMapIntoForeground(this.mbMap);
    this.updateDistanceMarkers(this.props.exploreMap);

    setWindowExitGuard(false);
    // eslint-disable-next-line no-undef
    $('#flash-msg').slideUp();

    this.props.handleRouteInfoChanged(null);
    this.setState({
      trailPlanner: null,
      currentlyEditingRoute: null,
      selectedRouteNode: null
    });
  },
  enableInsertMode(type = INSERT_TYPE) {
    removeMarkers(this.mbMap, routeNodesId, true);

    if (!this.canEnableInsertMode(type)) {
      return;
    }

    const { routeNodes, endsCoincide, routePolylines } = this.state.trailPlanner;
    const startNode = type === INSERT_TYPE ? this.state.selectedRouteNode : routeNodes[this.state.selectedRouteNode.id - 1];
    const insertEndPoint = type === CUT_TYPE ? this.state.selectedRouteNode.lngLat : null;
    // if the cut end nodes are both multipoint nodes (or the start node when ends coincide)
    const isMultiLineCut = this.state.selectedRouteNode.position === 'all' && (startNode.position === 'all' || (startNode.id === 0 && endsCoincide));
    const { updateProgressDialog } = this.props;
    const { routingActive, routingMode } = this.state;
    const { onInsertRouteInfoChange } = this;

    // Create trail planner instance for insert mode
    // Attach to state immediately to prevent any race condition issues
    this.state.insertTrailPlanner = new TrailPlanner({
      onRouteInfoChange: onInsertRouteInfoChange,
      route: undefined,
      routingActive,
      routingMode,
      updateProgressDialog,
      isInsertTrailPlanner: true,
      insertEndPoint,
      routeStart: startNode.lngLat,
      isMultiLineCut
    });

    this.state.insertTrailPlanner.initRoutePolylines();

    this.setState({
      insertTrailPlanner: this.state.insertTrailPlanner,
      selectedRouteNode: null
    });

    // update paint features based on type of insert
    makeAtMapInvisible(this.mbMap);
    if (type === INSERT_TYPE) {
      // reduces opacity on entire existing route, not the route to be inserted
      updateLayerProperty(this.mbMap, routePolylinesId, 'paint', 'line-opacity', 0.4);
      updateLayerProperty(this.mbMap, routeChevronsId, 'paint', 'icon-opacity', 0.4);
    } else if (isMultiLineCut) {
      // reduces opacity on polyline(s) to be cut
      // if it's a multi-line cut and the start node does not have a second idx, the start node must be the first/last (ends coincide)
      const endIdx = startNode.idxs[1] ? startNode.idxs[1] - 1 : routePolylines.length - 1;
      const fadedLines = [startNode.idxs[0], endIdx];
      const lineOpacity = ['match', ['get', 'idx'], fadedLines, 0.2, 1];
      updateLayerProperty(this.mbMap, routePolylinesId, 'paint', 'line-opacity', lineOpacity);
    } else {
      const lineOpacity = ['match', ['get', 'idx'], startNode.id, 0.4, 1];
      updateLayerProperty(this.mbMap, routePolylinesId, 'paint', 'line-opacity', lineOpacity);
    }
  },
  applyInsertRouteSection() {
    const { insertTrailPlanner } = this.state;

    if (!insertTrailPlanner) {
      return;
    }
    if (insertTrailPlanner.insertEndPoint) {
      this.applyCut();
    } else {
      this.applyInsert();
    }
  },
  applyInsert() {
    const { insertTrailPlanner } = this.state;

    if (insertTrailPlanner.endsCoincide) {
      this.state.trailPlanner.mergeRoutes(insertTrailPlanner);
      this.disableInsertMode();
    } else {
      alert('Start and end point must coincide before you can insert the new route segment');
    }
  },
  applyCut() {
    const { insertTrailPlanner } = this.state;
    if (isEqual(insertTrailPlanner.insertEndPoint, insertTrailPlanner.getEndPt(1))) {
      this.state.trailPlanner.mergeRoutes(insertTrailPlanner, true);
      this.disableInsertMode();
    } else {
      alert('You must draw to the cut point before you can insert the new route segment');
    }
  },
  canEnableInsertMode(type) {
    // cannot use start node for cut mode, as it cuts out the line from the selected node to the node before it.
    if (type === CUT_TYPE && this.state.selectedRouteNode?.id === 0) {
      return false;
    }
    if (this.state.insertTrailPlanner || !this.state.currentlyEditingRoute || !this.state.selectedRouteNode || !this.state.trailPlanner) {
      return false;
    }
    return true;
  },
  disableInsertMode() {
    if (!this.state.insertTrailPlanner) {
      return;
    }

    makeAtMapVisible(this.mbMap);
    updateLayerProperty(this.mbMap, routePolylinesId, 'paint', 'line-opacity', 1);
    updateLayerProperty(this.mbMap, routeChevronsId, 'paint', 'icon-opacity', 1);
    updateLayerProperty(this.mbMap, routeNodesId, 'layout', 'icon-size', 1);

    removeInsertModeMap(this.mbMap);
    this.props.handleInsertRouteInfoChanged(null);
    this.props.handleRouteInfoChanged({ selectedNode: null });

    this.setState({
      insertTrailPlanner: null,
      selectedRouteNode: null
    });

    this.state.trailPlanner.checkRoute();
  },
  handleRouteColorChange(newColor) {
    this.state.trailPlanner.color = newColor;
    // Update drawn route
    this.drawTrailPlannerRoute();
    // Update plannerMap at parent level
    const plannerMap = this.state.trailPlanner.toAtMap();
    this.props.handleRouteInfoChanged({ plannerMap });
  },
  onRouteInfoChange(routeInfo) {
    this.drawTrailPlannerRoute();
    this.updateDistanceMarkers(routeInfo.plannerMap);
    if (routeInfo.fullChange) {
      this.props.handleRouteInfoChanged(routeInfo);
      this.setState({
        plannerMap: routeInfo.plannerMap,
        selectedRouteNode: null
      });
    }
    if (this.props.hasAdminFeatures) {
      // sets last placed marker node to selected node
      const selectedNode = this.state.trailPlanner?.lastAddedRouteNode;
      if (selectedNode) {
        this.toggleSelectRouteNode(selectedNode);
        this.state.trailPlanner.lastAddedRouteNode = null;
      }
    }
  },
  onInsertRouteInfoChange(routeInfo) {
    this.drawTrailPlannerRoute(this.state.insertTrailPlanner);
    this.props.handleInsertRouteInfoChanged(routeInfo);
    // sets last placed marker node to selected node
    const selectedNode = this.state.insertTrailPlanner?.lastAddedRouteNode;
    if (selectedNode) {
      this.toggleSelectRouteNode(selectedNode);
      this.state.insertTrailPlanner.lastAddedRouteNode = null;
    }
  },
  drawTrailPlannerRoute(trailPlanner = this.state.trailPlanner) {
    const messages = {
      dragToChange: this.props.intl.formatMessage(MESSAGES.dragToChange),
      clickToAddPoint: this.props.intl.formatMessage(MESSAGES.clickToAddPoint),
      backSelected: this.props.intl.formatMessage(MESSAGES.backSelected),
      outSelected: this.props.intl.formatMessage(MESSAGES.outSelected),
      allSelected: this.props.intl.formatMessage(MESSAGES.allSelected)
    };

    const startNode = this.state.trailPlanner.routeNodes[0];

    // admin features are new route drawing features like marker tooltips, marker toolbar, cut/split, etc
    addTrailPlannerOverlay(this.mbMap, trailPlanner, this.state.trailPlannerColor, messages, this.props.hasAdminFeatures, startNode, () =>
      this.getToolbarConfig()
    );
  },
  getToolbarConfig() {
    return [
      {
        icon: { Component: DoubleBack },
        onClick: () => this.outAndBackClick(),
        title: 'Double back',
        disabled: this.state.insertTrailPlanner?.insertEndPoint
      },
      {
        icon: { Component: CloseLoop },
        onClick: () => this.returnClick(),
        title: 'Close loop',
        disabled: !!this.state.insertTrailPlanner?.insertEndPoint || (!this.state.insertTrailPlanner && this.state.trailPlanner.endsCoincide)
      },
      {
        icon: { Component: Reverse },
        onClick: () => this.reverseClick(),
        title: 'Reverse',
        disabled: this.state.insertTrailPlanner && !this.state.insertTrailPlanner.endsCoincide
      },
      {
        icon: { Component: Cut },
        onClick: () => this.enableInsertMode(CUT_TYPE),
        title: 'Cut',
        disabled: this.state.insertTrailPlanner || this.state.selectedRouteNode.id === 0
      },
      {
        icon: { Component: Split },
        onClick: () => this.enableInsertMode(INSERT_TYPE),
        title: 'Insert',
        disabled: this.state.insertTrailPlanner
      },
      {
        icon: { Component: Remove },
        onClick: () => this.mapDelKey(true),
        title: 'Delete'
      }
    ];
  },
  updateDistanceMarkers(atMap) {
    if (this.state.enabledOverlays.includes('distanceMarkers')) {
      addDistanceMarkers(this.mbMap, atMapsToGeojson([atMap]));
    }
  },
  routeNodeClick(feature) {
    const routeNode = feature.properties;
    const lngLat = feature.geometry.coordinates;
    removeLayer(this.mbMap, `${routeNodesDragId}-labels`);
    const tp = this.getActiveTrailPlanner();

    // if clicked when hovering over route
    if (feature.id === newRouteNodeId) {
      // Split route at new route node
      // these options are for marker nodes placed on route segments that overlap
      const opts = {
        forceSplitOnTopLine: this.props.hasAdminFeatures && !this.state.activeKeyModifier,
        forceSplitOnBottomLine: this.props.hasAdminFeatures && this.state.activeKeyModifier === 'v',
        forceSplitOnBothLines: this.props.hasAdminFeatures && this.state.activeKeyModifier === 'a'
      };
      tp.addNewRouteNode({ ...routeNode, lngLat }, opts);

      // if end node for cut mode is clicked
    } else if (this.isInsertEndNode(routeNode)) {
      tp.routeToPoint(lngLat);
      // if start node for insert mode (insert type, not cut) is clicked
    } else if (this.isInsertStartNode(routeNode)) {
      tp.returnToStart();
    } else {
      // Toggle selection of route node
      this.toggleSelectRouteNode({ ...routeNode, lngLat });
    }
  },
  isInsertEndNode(routeNode) {
    if (this.state.insertTrailPlanner && this.state.insertTrailPlanner.insertEndPoint) {
      const { routeNodes } = this.state.insertTrailPlanner;
      const lastNode = routeNodes[routeNodes.length - 1];
      return routeNode.id === lastNode.id;
    }

    return false;
  },
  isInsertStartNode(routeNode) {
    if (this.state.insertTrailPlanner && !this.state.insertTrailPlanner.insertEndPoint) {
      const { routeNodes } = this.state.insertTrailPlanner;
      const startNode = routeNodes[0];
      return routeNode.id === startNode.id;
    }

    return false;
  },
  mapClick(e) {
    if (this.state.trailPlanner) {
      if (!this.mbMap.shiftKeyDown) {
        // Handle clicking on specific trail planner layers (like a routeNode)
        const features = this.mbMap.queryRenderedFeatures(e.point);
        const feature = features[0];
        if (feature && [routeNodesId, routeNodesDragId, routeInsertModeNodesId, routeInsertModeNodesDragId].includes(feature.layer.id)) {
          this.routeNodeClick(feature);
          return;
        }
        // Drawing functionality
        const tp = this.getActiveTrailPlanner();
        tp.routeToPoint(e.lngLat.toArray(), true);
      }

      // deselect currently selected node if exists
      if (this.state.selectedRouteNode) {
        this.toggleSelectRouteNode(this.state.selectedRouteNode);
      }
    }
  },
  // need to handle right click only when it's on a route node (to set selected node)
  mapRightClick(e) {
    if (this.state.trailPlanner) {
      if (!this.mbMap.shiftKeyDown) {
        const features = this.mbMap.queryRenderedFeatures(e.point);
        const feature = features[0];
        const lngLat = feature.geometry.coordinates;
        const routeNode = feature.properties;
        if (feature && [routeNodesId, routeInsertModeNodesId].includes(feature.layer.id)) {
          // eslint-disable-next-line eqeqeq
          if (this.state.selectedRouteNode?.id != routeNode.id) {
            this.toggleSelectRouteNode({ ...routeNode, lngLat });
          }
        }
      }
    }
  },
  mapShiftDragStart(e) {
    if (this.state.insertTrailPlanner && !this.state.routingActive) {
      this.state.insertTrailPlanner.startDynamicPolyline(e.lngLat.toArray());
    } else if (this.state.trailPlanner && !this.state.routingActive) {
      this.state.trailPlanner.startDynamicPolyline(e.lngLat.toArray());
    }
  },
  mapShiftDragMove(e) {
    if (this.state.insertTrailPlanner && !this.state.routingActive) {
      this.state.insertTrailPlanner.updateDynamicPolyline(e.lngLat.toArray());
    } else if (this.state.trailPlanner && !this.state.routingActive) {
      this.state.trailPlanner.updateDynamicPolyline(e.lngLat.toArray());
    }
  },
  mapShiftDragEnd(e) {
    if (this.state.insertTrailPlanner && !this.state.routingActive) {
      this.state.insertTrailPlanner.endDynamicPolyline(e.lngLat.toArray());
    } else if (this.state.trailPlanner && !this.state.routingActive) {
      this.state.trailPlanner.endDynamicPolyline(e.lngLat.toArray());
    }
  },
  mapCtrlKey(pressed) {
    if (pressed) {
      this.toggleRouting();
    }
  },
  mapDelKey(pressed) {
    const tp = this.getActiveTrailPlanner();
    if (tp && this.state.selectedRouteNode && pressed) {
      tp.routeNodeRemoved(this.state.selectedRouteNode);
      this.toggleSelectRouteNode(this.state.selectedRouteNode);
    }
  },
  mapEscKey(pressed) {
    if (pressed) {
      this.disableInsertMode();
    }
  },
  toggleSelectRouteNode(routeNode) {
    if (this.state.selectedRouteNode && this.state.selectedRouteNode.id === routeNode.id) {
      // Deselect
      updateLayerProperty(this.mbMap, routeNodesId, 'layout', 'icon-size', 1);
      this.setState({ selectedRouteNode: null });
    } else {
      // Select
      const iconSize = ['match', ['get', 'id'], routeNode.id, 1.25, 1];
      updateLayerProperty(this.mbMap, routeNodesId, 'layout', 'icon-size', iconSize);
      this.setState({ selectedRouteNode: routeNode });
    }
    // need to know if there is a selected route node in parent components in order to enable/disable insert button
    this.props.handleRouteInfoChanged({ selectedNode: this.state.selectedRouteNode });
  },
  toggleRouting() {
    const routingActive = !this.state.routingActive;
    this.setState({ routingActive });
    if (this.state.insertTrailPlanner) {
      this.state.insertTrailPlanner.routingActive = routingActive;
    }
    this.state.trailPlanner.routingActive = routingActive;
  },
  toggleRoutingTip(tipType, e) {
    e.stopPropagation();
    const toolTipState = { ...this.state.showRoutingTips };
    toolTipState[tipType] = false;
    this.setState({ showRoutingTips: toolTipState });
  },
  setRoutingMode(value) {
    const routingMode = value;
    this.setState({ routingMode });
    if (this.state.insertTrailPlanner) {
      this.state.insertTrailPlanner.routingMode = routingMode;
    }
    this.state.trailPlanner.routingMode = routingMode;

    if (routingMode === 'slid-route') {
      addSlidRoute(this.mbMap, this.state.slidRoute);
    } else {
      removeSlidRoute(this.mbMap);
    }
  },
  outAndBackClick() {
    const tp = this.getActiveTrailPlanner();
    if (tp) {
      tp.outAndBack();
    }
  },
  returnClick() {
    const tp = this.getActiveTrailPlanner();
    if (tp) {
      tp.returnToStart();
    }
  },
  reverseClick() {
    const tp = this.getActiveTrailPlanner();

    if (tp) {
      tp.reverseRoute();
    }
  },
  undoClick() {
    const tp = this.getActiveTrailPlanner();
    if (!tp) {
      return;
    }
    if (tp.isInsertTrailPlanner && this.state.insertTrailPlanner.routePolylines.length < 1) {
      this.disableInsertMode();
    } else {
      tp.undo();
    }
  },
  redoClick() {
    const tp = this.getActiveTrailPlanner();
    if (tp) {
      tp.redo();
    }
  }
};

export { TrailPlannerMixin, CUT_TYPE, INSERT_TYPE };
