import classNames from 'classnames';
import { usePopper } from 'react-popper';
import useIsMinimumMapHeight from '@alltrails/shared/hooks/useIsMinimumMapHeight';
import MapSelectorModal from '@alltrails/maps/components/MapSelectorModal';
import useStyleKeyboardShortcut from '@alltrails/maps/hooks/useStyleKeyboardShortcut';
import { getMapLocation } from '@alltrails/maps/utils/amplitude';
import { additionalStyleCardConfigs, baseStyleCardConfigs, hideMapsBaseStyleCardConfigs } from '@alltrails/maps/utils/cardConfigs';
import getStyleCardConfigs from '@alltrails/maps/utils/getStyleCardConfigs';
import IconButton from '@alltrails/shared/denali/components/IconButton';
import IconButtonGroup from '@alltrails/shared/denali/components/IconButtonGroup';
import LayerButton from '@alltrails/maps/components/LayerButton';
import LayerMenu from '@alltrails/maps/components/LayerMenu';
import Maps2d from '@alltrails/shared/icons/2d';
import Maps3d from '@alltrails/shared/icons/3d';
import Compass from '@alltrails/shared/icons/Compass';
import Layers from '@alltrails/shared/icons/Layers';
import { useIntl } from '@alltrails/shared/react-intl';
import { wrapUrlSafe } from 'utils/language_support_util';
import useFeatures from 'hooks/useFeatures';
import logThreeDMapButtonClicked from '@alltrails/analytics/events/logThreeDMapButtonClicked';
import logMapLayerButtonTapped from '@alltrails/analytics/events/logMapLayerButtonTapped';
import { AdditionalStyleId, StyleId } from '@alltrails/maps/types/Styles';
import { ComponentProps, MouseEvent, useCallback, useMemo, useState } from 'react';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import useUser from 'hooks/useUser';
import useExperiments from 'hooks/useExperiments';
import * as styles from './styles/styles.module.scss';
import * as printStyles from './styles/print.module.scss';

type Props = {
  adminCustomizationSettings?: ComponentProps<typeof MapSelectorModal>['adminCustomizationSettings'];
  adminLayout?: boolean;
  compassRotation?: number;
  currentLayerTerrainEnabled?: boolean;
  currentMapLayer: StyleId;
  enabledOverlays: AdditionalStyleId[];
  handleAdminCustomizationSettingsChange?: (settingsKey: string, sourceUrl?: string) => void;
  handleCompassClick?: (e: MouseEvent<HTMLElement>) => void;
  handleDisable3D?: () => void;
  handleEnable3D?: () => void;
  handleResetClick: ComponentProps<typeof MapSelectorModal>['handleResetClick'];
  isAuthenticated?: boolean;
  isNotExploreMap?: boolean;
  mobileBrowser: boolean;
  onActivityFilterChange: ComponentProps<typeof MapSelectorModal>['handleActivityFilterChange'];
  onCardClick: ComponentProps<typeof MapSelectorModal>['handleCardClick'];
  onHeatmapClick: ComponentProps<typeof MapSelectorModal>['handleHeatmapClick'];
  permissions: string[];
  selectedFilterActivity?: ComponentProps<typeof MapSelectorModal>['selectedFilterActivity'];
  shouldConditionallyRender?: boolean;
  terrainActive?: boolean;
  terrainDisabled?: boolean;
  trailId?: number;
  selectedObject?: ComponentProps<typeof LayerMenu>['selectedObject'];
};

const MapSelection = ({
  adminCustomizationSettings = {},
  adminLayout = false,
  compassRotation = 0,
  currentLayerTerrainEnabled = false,
  currentMapLayer,
  enabledOverlays,
  handleAdminCustomizationSettingsChange,
  handleCompassClick,
  handleDisable3D,
  handleEnable3D,
  handleResetClick,
  isAuthenticated = false,
  isNotExploreMap = true,
  mobileBrowser,
  onActivityFilterChange,
  onCardClick,
  onHeatmapClick,
  permissions,
  selectedFilterActivity = null,
  shouldConditionallyRender = false,
  terrainActive = false,
  terrainDisabled = false,
  trailId = -1,
  selectedObject
}: Props) => {
  const user = useUser();
  const intl = useIntl();
  const isMinimumMapHeight = useIsMinimumMapHeight();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const languageRegionCode = useLanguageRegionCode();
  const shouldHideMaps = useFeatures()?.includes?.('new_hidden_map_layers');
  const hasNewLayerMenu = useExperiments()?.['web-monolith-new-layer-menu']?.value === 'on';

  let body: HTMLElement;
  let hideForFullscreen: HTMLElement;
  if (typeof window !== 'undefined') {
    body = document.querySelector('body');
    hideForFullscreen = document.querySelector('.hide-for-fullscreen-modal');
  }

  const handleClickModalButton = useCallback(() => {
    if (!isMenuOpen) {
      logMapLayerButtonTapped({ map_location: getMapLocation(window.location.pathname) });
      setIsMenuOpen(true);
      if (body && hideForFullscreen) {
        body.style.overflow = 'hidden';
        hideForFullscreen.style.display = 'none';
      }
    } else {
      setIsMenuOpen(false);
      if (body && hideForFullscreen) {
        body.style.overflow = 'unset';
        hideForFullscreen.style.display = '';
      }
    }
  }, [body, hideForFullscreen, isMenuOpen]);

  const getTerrainButton = () => {
    if (terrainDisabled || !currentLayerTerrainEnabled || !handleDisable3D || !handleEnable3D) {
      return null;
    }
    const onClick = () => {
      logThreeDMapButtonClicked({ map_location: getMapLocation(window.location.pathname), trail_id: trailId });

      if (terrainActive) {
        handleDisable3D();
      } else if (user?.pro) {
        handleEnable3D();
      } else {
        const params = new URLSearchParams();
        params.set('returnTo', window.location.toString());
        const path = `${isAuthenticated ? '/plus' : `/signup?${params.toString()}`}`;
        window.location.assign(wrapUrlSafe(path, languageRegionCode));
      }
    };
    const title = terrainActive ? intl.formatMessage({ defaultMessage: 'Disable 3D map' }) : intl.formatMessage({ defaultMessage: 'Enable 3D map' });
    return { icon: { Component: terrainActive ? Maps2d : Maps3d }, onClick, title, testId: title };
  };

  const { mapTypesCards, mapDetailsCards, additionalOptionsCards, extraCards, heatmapCard } = getStyleCardConfigs({
    currentMapLayer,
    enabledOverlays,
    isAdmin: adminLayout,
    additionalStyleCardConfigs,
    permissions,
    baseStyleCardConfigs: shouldHideMaps ? hideMapsBaseStyleCardConfigs : baseStyleCardConfigs,
    terrainActive,
    hasNewLayerMenu
  });

  // Note that this is defined outside the scope of the cards modal. These
  // shortcuts are meant to be used without having to open that modal
  useStyleKeyboardShortcut(styleId => {
    const config = [...mapDetailsCards, ...mapTypesCards, ...(extraCards ?? []), ...(heatmapCard ?? [])].find(({ key }) => key === styleId);
    if (!config) {
      return;
    }

    onCardClick(config);
  });

  const shouldRenderButtons = !shouldConditionallyRender || isMinimumMapHeight;

  const [anchorElement, setAnchorElement] = useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);

  const { styles: popperStyles, attributes: popperAttributes } = usePopper(anchorElement, popperElement, {
    placement: 'left-start',
    modifiers: [
      // This can be slightly more expensive on re-computing positions but may
      // also avoid edge-case layout issues.
      { name: 'computeStyles', options: { adaptive: false } },
      { name: 'offset', options: { offset: [0, 16] } }
    ]
  });

  const layerCount = useMemo(() => {
    if (adminLayout) {
      return enabledOverlays?.filter(overlay => !overlay?.includes('Heatmap'))?.length;
    }
    return enabledOverlays?.length;
  }, [enabledOverlays, adminLayout]);

  return (
    <div
      className={classNames(styles.controls, printStyles.controls, {
        [styles.isNotExploreMap]: isNotExploreMap,
        [styles.newLayerMenu]: hasNewLayerMenu
      })}
    >
      {shouldRenderButtons && (
        <>
          {hasNewLayerMenu ? (
            <>
              <LayerButton ref={setAnchorElement} isOpen={isMenuOpen} onClick={handleClickModalButton} layerCount={layerCount} />
              {terrainDisabled || !currentLayerTerrainEnabled || !handleDisable3D || !handleEnable3D ? null : (
                <IconButton {...getTerrainButton()} variant="elevated" />
              )}
            </>
          ) : (
            <IconButtonGroup
              className={terrainActive ? '' : styles.brandColor3DButton}
              buttons={[
                {
                  icon: { Component: Layers },
                  onClick: handleClickModalButton,
                  title: intl.formatMessage({ defaultMessage: 'Map options' }),
                  testId: 'map-options'
                },
                getTerrainButton()
              ].filter(button => !!button)}
              orientation="vertical"
              testId="map-selection-controls"
            />
          )}
          {terrainDisabled ? null : (
            <IconButton
              className={styles.compass}
              icon={{ Component: Compass, orientation: compassRotation }}
              onClick={handleCompassClick}
              testId="map-compass"
              title={intl.formatMessage({ defaultMessage: 'Reset north and pitch' })}
              variant="elevated"
            />
          )}
        </>
      )}
      {isMenuOpen && (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
          {hasNewLayerMenu ? (
            <LayerMenu
              ref={setPopperElement}
              isOpen
              onCloseRequest={handleClickModalButton}
              enabledOverlays={enabledOverlays}
              adminLayout={adminLayout}
              desktopModalProps={{ ...popperAttributes.popper, style: popperStyles.popper }}
              baseMapCards={mapTypesCards}
              overlayCards={mapDetailsCards}
              extraCards={extraCards}
              handleCardClick={onCardClick}
              handleHeatmapClick={onHeatmapClick}
              adminCustomizationSettings={adminCustomizationSettings}
              heatmapCard={heatmapCard}
              handleAdminCustomizationSettingsChange={handleAdminCustomizationSettingsChange}
              selectedObject={selectedObject}
            />
          ) : (
            <MapSelectorModal
              mapTypesCards={mapTypesCards}
              mapDetailsCards={mapDetailsCards}
              additionalOptionsCards={additionalOptionsCards}
              handleClose={handleClickModalButton}
              handleResetClick={handleResetClick}
              handleCardClick={onCardClick}
              handleHeatmapClick={onHeatmapClick}
              handleActivityFilterChange={onActivityFilterChange}
              selectedFilterActivity={selectedFilterActivity}
              adminCustomizationSettings={adminCustomizationSettings}
              handleAdminCustomizationSettingsChange={handleAdminCustomizationSettingsChange}
              adminLayout={adminLayout}
              mobileBrowser={mobileBrowser}
              enabledOverlays={enabledOverlays}
              selectedObject={selectedObject}
            />
          )}
        </>
      )}
    </div>
  );
};

export default MapSelection;
