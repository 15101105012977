import { string } from 'prop-types';

import * as styles from './styles/styles.module.scss';

const StatsSection = ({ labelName, data }) => {
  return (
    <span className={styles.section}>
      <div className={styles.label}>{labelName}</div>
      <div className={styles.dataSection}>{data}</div>
    </span>
  );
};

StatsSection.propTypes = {
  labelName: string.isRequired,
  data: string.isRequired
};

export default StatsSection;
