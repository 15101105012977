import { useEffect, useRef, useState } from 'react';
import { defineMessages } from '@alltrails/shared/react-intl';
import useFormatMessage from '@alltrails/shared/hooks/useFormatMessage';
import { FONT_SIZE_400, SPACE_16 } from '@alltrails/shared/denali/tokens';
import useDisplayMetric from 'hooks/useDisplayMetric';
import { MapStatsUtil } from 'utils/map_stats_util';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import FlyoverLoadingState from 'types/FlyoverLoadingState';
import type Trail from 'types/Trails/Trail';
import * as styles from './FlyoverMapStats.module.scss';
import FlyoverTransition from '../FlyoverTransition';
import getTextWidth from './utils/getTextWidth';

const messages = defineMessages({
  ELEV_GAIN: { defaultMessage: 'Elevation gain' },
  DISTANCE: { defaultMessage: 'Distance' }
});

type FlyoverMapStatsProps = {
  elevationGainMeters: number;
  flyoverLoadingState: FlyoverLoadingState;
  percentComplete: number;
  trail: Trail;
};

const FlyoverMapStats = ({ elevationGainMeters, flyoverLoadingState, percentComplete, trail }: FlyoverMapStatsProps): JSX.Element => {
  const {
    formattedDefaultMessages: { ELEV_GAIN, DISTANCE }
  } = useFormatMessage(messages);
  const displayMetric = useDisplayMetric();
  const languageRegionCode = useLanguageRegionCode();

  const statRef = useRef<HTMLSpanElement>(null);
  const [distanceStatWidth, setDistanceStatWidth] = useState(0);
  const [elevStatWidth, setElevStatWidth] = useState(0);

  useEffect(() => {
    if (flyoverLoadingState === 'done' && statRef.current) {
      const distWidth = getTextWidth(
        MapStatsUtil.metersToFormattedUserUnits(trail.length, 2 /* precision, add extra decimal for more space */, displayMetric, languageRegionCode),
        getComputedStyle(statRef.current).font
      );
      const elevWidth = getTextWidth(
        MapStatsUtil.elevationFormatted(trail.elevation_gain, displayMetric, languageRegionCode),
        getComputedStyle(statRef.current).font
      );
      setDistanceStatWidth(distWidth);
      setElevStatWidth(elevWidth + FONT_SIZE_400 /* extra character for more space */);
    }
  }, [displayMetric, flyoverLoadingState, languageRegionCode, trail.length, trail.elevation_gain]);

  return (
    <FlyoverTransition showOn={flyoverLoadingState === 'done'} unmountOnExit>
      {flyoverLoadingState === 'done' && (
        <div className={styles.animStatsContainer} style={{ minWidth: distanceStatWidth + elevStatWidth + SPACE_16 /* gap */ + 40 /* padding */ }}>
          <div className={styles.animStat} style={{ minWidth: distanceStatWidth }}>
            <span className={styles.statTitle}>{DISTANCE}</span>
            <span className={styles.stat} ref={statRef}>
              {MapStatsUtil.metersToFormattedUserUnits(percentComplete * trail.length, 1 /* precision */, displayMetric, languageRegionCode)}
            </span>
          </div>
          <div className={styles.animStat} style={{ minWidth: elevStatWidth }}>
            <span className={styles.statTitle}>{ELEV_GAIN}</span>
            <span className={styles.stat}>{MapStatsUtil.elevationFormatted(elevationGainMeters, displayMetric, languageRegionCode)}</span>
          </div>
        </div>
      )}
    </FlyoverTransition>
  );
};

export default FlyoverMapStats;
