import { loadAndAddImage } from '../image_helpers';
import logError from 'utils/logError';

const mapMarkerImageId = `map-marker-distance`;

const addDistanceMarkerImagesToMap = map => {
  const imageId = mapMarkerImageId;
  const imageSrc = `https://cdn-assets.alltrails.com/assets/icons/maps/markers/map-marker-distance@2x.png`;
  return loadAndAddImage(map, imageId, imageSrc, {
    stretchX: [[36, 68]],
    pixelRatio: 2
  }).catch(logError);
};

const createDistanceMarkerLayer = (id, sourceId) => {
  return {
    id,
    type: 'symbol',
    source: sourceId,
    layout: {
      'icon-allow-overlap': true,
      'icon-ignore-placement': true,
      'icon-image': ['image', mapMarkerImageId],
      'icon-text-fit': 'both',
      'icon-text-fit-padding': [10, 14, 14, 14],
      'text-field': ['get', 'label'],
      'text-font': ['Manrope Bold'],
      'text-size': 11
    }
  };
};

export { addDistanceMarkerImagesToMap, createDistanceMarkerLayer };
