// extracted by mini-css-extract-plugin
export var ad1 = "Trail-module__ad1___h_nCO";
export var ad1Mobile = "Trail-module__ad1Mobile___OQ06s";
export var ad2 = "Trail-module__ad2___FQWdN";
export var container = "Trail-module__container___joe8I";
export var divider = "Trail-module__divider___Cmea4";
export var exploreTrail = "Trail-module__explore_trail___ONEGG";
export var hideOnMobile = "Trail-module__hideOnMobile___SHlUl";
export var mobileStaticMap = "Trail-module__mobileStaticMap___r3_4z";
export var suggestEditAndDropdown = "Trail-module__suggestEditAndDropdown___yE0g3";
export var tagGroupContainer = "Trail-module__tagGroupContainer___JFhKU";
export var trailInfo = "Trail-module__trailInfo___Ejufa";
export var trailInfoContainer = "Trail-module__trailInfoContainer___tIkam";
export var trailSidebar = "Trail-module__trailSidebar___Bsu11";