import * as d3 from 'd3';

const D3Utils = {
  appendSvg(container, width, height) {
    return d3
      .select(container)
      .append('svg:svg')
      .attr('width', width)
      .attr('height', height);
  },
  appendSvgLine(axisGroup, width, height, strokeAttr, classAttr, additionalAttrClass, additionalAttr) {
    if (additionalAttrClass) {
      axisGroup
        .append('svg:line')
        .attr('x1', width)
        .attr('y1', 0)
        .attr('x2', width)
        .attr('y2', height)
        .attr('stroke', strokeAttr)
        .attr('class', classAttr)
        .attr(additionalAttrClass, additionalAttr);
    }
    axisGroup
      .append('svg:line')
      .attr('x1', width)
      .attr('y1', 0)
      .attr('x2', width)
      .attr('y2', height)
      .attr('stroke', strokeAttr)
      .attr('class', classAttr);
  },
  getAxisOrientation(orientation, scale, ticks, formatFunction) {
    switch (orientation) {
      case 'bottom':
        return d3
          .axisBottom()
          .scale(scale)
          .ticks(ticks)
          .tickFormat(formatFunction)
          .tickSize(0, 0, 0);
      case 'top':
        return d3
          .axisTop()
          .scale(scale)
          .ticks(ticks)
          .tickFormat(formatFunction)
          .tickSize(0, 0, 0);
      case 'right':
        return d3
          .axisRight()
          .scale(scale)
          .ticks(ticks)
          .tickFormat(formatFunction)
          .tickSize(0, 0, 0);
      case 'left':
        return d3
          .axisLeft()
          .scale(scale)
          .ticks(ticks)
          .tickFormat(formatFunction)
          .tickSize(0, 0, 0);
      default:
        return null;
    }
  },
  appendAxisOrientation(container, axisClassAttr, transformAttr, axis, left, right) {
    if (left) {
      return container
        .append('svg:g')
        .attr('class', axisClassAttr)
        .call(axis)
        .attr('transform', transformAttr)
        .selectAll('text')
        .attr('transform', 'rotate(-90)');
    }
    if (right) {
      return container
        .append('svg:g')
        .attr('class', axisClassAttr)
        .call(axis)
        .attr('transform', transformAttr)
        .selectAll('text')
        .attr('transform', 'rotate(90)');
    }
    return container
      .append('svg:g')
      .attr('class', axisClassAttr)
      .attr('transform', transformAttr)
      .call(axis);
  },
  scaleLinear(domain, rangeRound) {
    return d3
      .scaleLinear()
      .domain(domain)
      .rangeRound(rangeRound);
  }
};

export { D3Utils };
