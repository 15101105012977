import { createContext, ReactNode, useMemo, useState } from 'react';

type Props = {
  children?: ReactNode;
};

type MapExpandValue = {
  isMapExpanded: boolean;
  toggleMapExpanded: () => void;
};

const MapExpandContext = createContext<MapExpandValue>({
  isMapExpanded: false,
  toggleMapExpanded: () => undefined
});

function MapExpandProvider({ children }: Props) {
  const [isMapExpanded, setIsMapExpanded] = useState(false);

  const value = useMemo(
    () => ({
      isMapExpanded,
      toggleMapExpanded: () => {
        if (!isMapExpanded && window) {
          window.setTimeout(() => {
            window.dispatchEvent(new Event('resize')); // force change to be recognized by mapbox
          }, 100);
        }

        setIsMapExpanded(!isMapExpanded);
      }
    }),
    [isMapExpanded]
  );

  return <MapExpandContext.Provider value={value}>{children}</MapExpandContext.Provider>;
}

export { MapExpandContext, MapExpandProvider };
