// Units in milliseconds
export const MILLISECONDS_IN_SECOND = 1000;
export const MILLISECONDS_IN_MINUTE = MILLISECONDS_IN_SECOND * 60;
export const MILLISECONDS_IN_HOUR = MILLISECONDS_IN_MINUTE * 60;
export const MILLISECONDS_IN_DAY = MILLISECONDS_IN_HOUR * 24;
export const MILLISECONDS_IN_YEAR = MILLISECONDS_IN_DAY * 365;

// Units in seconds
export const SECONDS_IN_MINUTE = 60;

// Units in minutes
export const MINUTES_IN_HOUR = 60;
