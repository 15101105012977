import { useState } from 'react';
import classNames from 'classnames';
import Button from '@alltrails/shared/denali/components/Button';
import { useIntl, FormattedMessage } from '@alltrails/shared/react-intl';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import ShareObject from 'types/ShareObject';
import { post } from '@alltrails/shared/api';
import logError from 'utils/logError';
import Phone from '../Phone';
import * as styles from './ShareModalDrawerTextMessage.module.scss';

function getSharePromptByType(type: ShareObject['type']) {
  switch (type) {
    case 'list':
      return (
        <FormattedMessage defaultMessage="Text this list to your friends, family or yourself so you can get driving directions and see detailed trail maps on the go." />
      );
    case 'state':
      return (
        <FormattedMessage defaultMessage="Text this state to your friends, family or yourself so you can get driving directions and see detailed trail maps on the go." />
      );
    case 'trail':
      return (
        <FormattedMessage defaultMessage="Text this trail to your friends, family or yourself so you can get driving directions and see detailed trail maps on the go." />
      );
    case 'point_of_interest':
      return (
        <FormattedMessage defaultMessage="Text this point of interest to your friends, family or yourself so you can get driving directions and see detailed trail maps on the go." />
      );
    case 'city':
      return (
        <FormattedMessage defaultMessage="Text this city to your friends, family or yourself so you can get driving directions and see detailed trail maps on the go." />
      );
    case 'country':
      return (
        <FormattedMessage defaultMessage="Text this country to your friends, family or yourself so you can get driving directions and see detailed trail maps on the go." />
      );
    case 'park':
    case 'area':
      return (
        <FormattedMessage defaultMessage="Text this park to your friends, family or yourself so you can get driving directions and see detailed trail maps on the go." />
      );
    case 'map':
    case 'track':
      return (
        <FormattedMessage defaultMessage="Text this map to your friends, family or yourself so you can get driving directions and see detailed trail maps on the go." />
      );
    default:
      logError(new Error(`Missing share text message prompt for '${type}'`));
      return (
        <FormattedMessage defaultMessage="Text this to your friends, family or yourself so you can get driving directions and see detailed trail maps on the go." />
      );
  }
}

type Props = {
  onSuccess: () => void;
  shareObject: ShareObject;
};

function ShareModalDrawerTextMessage({ onSuccess, shareObject }: Props) {
  const intl = useIntl();
  const languageRegionCode = useLanguageRegionCode();
  const [showSpinner, setShowSpinner] = useState(false);
  const [buttonLabel, setButtonLabel] = useState(intl.formatMessage({ defaultMessage: 'Text link' }));
  const [isValid, setIsValid] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');

  function handleChange(isValid: boolean, newNumber: string, countryData: unknown, fullNumber: string) {
    setIsValid(isValid);
    setPhoneNumber(fullNumber);
  }

  async function sendTextMessage() {
    setShowSpinner(true);

    try {
      await post('/api/alltrails/notifications/sms', {
        number: phoneNumber,
        resource_type: shareObject.type,
        resource_id: shareObject.id,
        resource_primary_key: shareObject.primaryKey,
        lang: languageRegionCode
      });

      onSuccess();
    } catch (e) {
      setButtonLabel(intl.formatMessage({ defaultMessage: 'Failed' }));
    } finally {
      setShowSpinner(false);
    }
  }

  return (
    <div>
      <p>{getSharePromptByType(shareObject.type)}</p>
      <div className={styles.form}>
        <Phone onChange={handleChange} classes={styles.phone} />
        <div className={classNames(styles.button, { [styles.disabled]: !isValid, [styles.loading]: showSpinner })}>
          <Button
            text={buttonLabel}
            type="button"
            disabled={!isValid}
            loading={showSpinner}
            size="md"
            onClick={sendTextMessage}
            testId="text-link-button"
            variant="primary"
          />
        </div>
      </div>
    </div>
  );
}

export default ShareModalDrawerTextMessage;
