import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';

// Standard country-language codes are comprised of two arguments separated by a
// dash, for example fr-CA is French Canadian.

// The first argument is a valid ISO Language Code. These codes are the
// lower-case two-letter codes as defined by ISO-639. You can find a full list
// of these codes at a number of sites like here.

// The second argument to both constructors is a valid ISO Country Code. These
// codes are the upper-case two-letter codes as defined by ISO-3166. You can
// find a full list of these codes at a number of sites like here. Also three
// letter codes for countries are available.
export default function useCountryCode() {
  const languageRegionCode = useLanguageRegionCode();
  return languageRegionCode.split('-')[1];
}
