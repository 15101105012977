import { addClusteredPins, removeClusteredPins } from './clustered_pins';
import { addPreviewLines } from './preview_lines';
import { getPrimaryIndex } from '../../search';
import { addResultsHeatmapOverlay, heatmapOverlayId } from './heatmap';
import { GREEN, GRAY } from '../../constants/PinColor';
import { SearchFiltersUtil } from '../../search_filters_util';

const nearbyLayerId = 'nearbyTrails';
const nearbyLayerClosedId = 'nearbyTrailsClosed';

const addNearbyTrails = (map, trailId, renderHoverCard, enabledOverlays, isAdmin) =>
  getPrimaryIndex()
    .search('', SearchFiltersUtil.createAlgoliaTrailsObject(map))
    .then(content => {
      let results = content.hits;
      if (trailId) {
        results = results.filter(trail => trail.ID !== trailId);
      }
      const nearby = [];
      const closed = [];

      results.forEach(result => {
        if (result.is_closed && isAdmin) {
          closed.push(result);
        } else {
          nearby.push(result);
        }
      });
      addPreviewLines(map);
      addClusteredPins(map, nearbyLayerId, nearby, GREEN, renderHoverCard);
      addClusteredPins(map, nearbyLayerClosedId, closed, GRAY, renderHoverCard);
      if (enabledOverlays.includes(heatmapOverlayId(nearbyLayerId))) {
        addResultsHeatmapOverlay({ map, id: nearbyLayerId, heatMapResults: nearby, renderHoverCard });
      }
    });

const removeNearbyTrails = map => {
  removeClusteredPins(map, nearbyLayerId);
  removeClusteredPins(map, nearbyLayerClosedId);
};

export { addNearbyTrails, removeNearbyTrails };
