import { post } from '@alltrails/shared/api';

// API under app/controllers/my

type ToggleSubscriptionStatusResponse = Record<string, unknown>;

/* eslint-disable import/prefer-default-export */
export async function toggleSubscriptionStatus() {
  return post<ToggleSubscriptionStatusResponse>('/my/profile/toggle_subscription_status', null);
}
