import { ComponentProps, useCallback } from 'react';
import classNames from 'classnames';
import { defineMessages, IntlShape, useIntl } from '@alltrails/shared/react-intl';
import Button from '@alltrails/shared/denali/components/Button';
import * as styles from './styles.module.scss';
import TrailUgcTab from '../types/TrailUgcTab';

export type ContributeButtonProps = {
  className?: string;
  completedConnectionId: number;
  contributeType: TrailUgcTab;
  clickHandler: (tabName: TrailUgcTab) => void;
  width?: ComponentProps<typeof Button>['width'];
};

const messages = defineMessages({
  WRITE_REVIEW: { defaultMessage: 'Write review' },
  UPLOAD_PHOTOS: {
    defaultMessage: 'Upload photos'
  },
  UPLOAD_RECORDINGS: { defaultMessage: 'Upload activities' },
  REMOVE_FROM_COMPLETED: { defaultMessage: 'Remove from completed' },
  ADD_TO_COMPLETED: { defaultMessage: 'Add to completed' }
});

function getButtonText(tab: TrailUgcTab, hasCompleted: boolean, intl: IntlShape): string {
  switch (tab) {
    case 'reviews':
      return intl.formatMessage(messages.WRITE_REVIEW);
    case 'photos':
      return intl.formatMessage(messages.UPLOAD_PHOTOS);
    case 'tracks':
      return intl.formatMessage(messages.UPLOAD_RECORDINGS);
    case 'completed':
      return hasCompleted ? intl.formatMessage(messages.REMOVE_FROM_COMPLETED) : intl.formatMessage(messages.ADD_TO_COMPLETED);
    default:
      return '';
  }
}

const ContributeButton = ({
  className,
  completedConnectionId,
  contributeType,
  clickHandler,
  width = 'fullOnMobile'
}: ContributeButtonProps): JSX.Element => {
  const intl = useIntl();
  const text = getButtonText(contributeType, !!completedConnectionId, intl);

  const onClick = useCallback(() => {
    clickHandler(contributeType);
  }, [clickHandler, contributeType]);

  return (
    <Button text={text} className={classNames(styles.contributeButton, className)} onClick={onClick} testId={text} variant="primary" width={width} />
  );
};

export default ContributeButton;
