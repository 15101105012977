import classNames from 'classnames';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { createPortal } from 'react-dom';
import { debounce } from 'debounce';
import isEqual from 'underscore/modules/isEqual';
import isEmpty from 'underscore/modules/isEmpty';
import ReportLocation from '@alltrails/analytics/enums/ReportLocation';
import ReportContentType from '@alltrails/analytics/enums/ReportContentType';
import { FormattedMessage } from '@alltrails/shared/react-intl';
import Button from '@alltrails/shared/denali/components/Button';
import Place from '@alltrails/shared/icons/Place';
import { PageStrings } from '@alltrails/shared/utils/constants/pageStringHelpers';
import reorder from '@alltrails/shared/utils/reorder';
import NoSsr from '@material-ui/core/NoSsr';
import LoadingSpinner from '@alltrails/shared/components/LoadingSpinner';
import logListViewed from '@alltrails/analytics/events/logListViewed';
import logActivitiesViewed from '@alltrails/analytics/events/logActivitiesViewed';
import ActivityCard from 'components/cards/ActivityCard';
import { TrailCardV2 } from 'components/cards/TrailCard';
import Switch from '@alltrails/shared/denali/components/Switch';
import { shallowCompare } from 'utils/compare';
import { SearchResultsUtil } from 'utils/search_results_util';
import * as styles from './styles/styles.module.scss';
import CardLocation from '@alltrails/analytics/enums/CardLocation';
import logTrailCardClicked from '@alltrails/analytics/events/logTrailCardClicked';
import ReportIssuePage from '@alltrails/modules/Reporting/ReportIssuePage';
import { EntryPoint } from '@alltrails/modules/Reporting/types';
import '@alltrails/modules/Reporting/sharedCss.css';
import LanguageSupportUtil from 'utils/language_support_util';

const createReactClass = require('create-react-class');

const itemHeight = 260; // height of item to be used in calculating # of visible items (including margins)
const renderableItemOffset = 1; // render more items using this offset than the current limit for better scrolling experience

const LegacyFullscreenSearchResultList = createReactClass({
  getInitialState() {
    return {
      visibleItemIndex: 0,
      // estimate before we know the scroll height
      renderableItems: 6,
      visibleItems: 6,
      loadItems: 6,
      animatedString: '...',
      uploadFormVisible: this.props.uploadFormVisible,
      refreshAds: false,
      results: this.props.results,
      h1Obj: this.props.h1Obj,
      showPrivacyModal: false,
      showReportingModal: false,
      setReportingSuccessToast: this.props.setReportingSuccessToast,
      isCollaborator: this.props.isCollaborator
    };
  },
  shouldComponentUpdate(nextProps, nextState) {
    return shallowCompare(this, nextProps, nextState, propKey => propKey !== 'resultCardFunctions');
  },
  analyticsLogEvent(type, listId) {
    if (this.props.context.currentUser && this.props.userInfo) {
      const isOwnedByUser = this.props.userInfo.id === this.props.context.currentUser.id;
      if (type !== 'activities') {
        logListViewed({
          owned_by_user: isOwnedByUser,
          owner_id: this.props.userInfo?.id,
          list_type: type,
          list_id: listId,
          item_count: this.props.results?.length
        });
      } else {
        logActivitiesViewed({ owned_by_user: isOwnedByUser, owner_id: this.props.userInfo?.id, activities_count: this.props.results?.length });
      }
    }
  },
  componentDidUpdate(prevProps) {
    // log listViewed analytics
    if (this.props.loading !== prevProps.loading) {
      if (this.props.context.currentUser) {
        switch (this.props.currentPage) {
          case PageStrings.EXPLORE_FAVORITE_PAGE:
            return this.analyticsLogEvent('favorites', '1000');
          case PageStrings.EXPLORE_CUSTOM_PAGE:
            return this.analyticsLogEvent('custom', this.props.customList?.id);
          case PageStrings.EXPLORE_USERS_MAPS_PAGE:
            return this.analyticsLogEvent('maps', '');
          case PageStrings.EXPLORE_COMPLETED_PAGE:
            return this.analyticsLogEvent('completed', '');
          case PageStrings.EXPLORE_USERS_TRACKS_PAGE:
            return this.analyticsLogEvent('activities', '');
          default:
            return '';
        }
      }
    }
  },
  componentDidMount() {
    this.handleResize();
    window.addEventListener('resize', debounce(this.handleResize, 200));
  },
  handleResize() {
    if (this.refs.scrollList) {
      const h = this.refs.scrollList.offsetHeight;
      const visibleItems = Math.ceil(h / itemHeight);
      this.setState({ renderableItems: visibleItems, visibleItems });
    }
  },
  // TODO: eventually migrate to static getDerivedStateFromProps()
  UNSAFE_componentWillReceiveProps(nextProps) {
    const nextState = {
      results: nextProps.results
    };
    // only update h1Obj if new h1Obj is valid
    if (nextProps.h1Obj) {
      nextState.h1Obj = nextProps.h1Obj;
    }
    // adjust scroll/renderableItems if new items in list
    if (nextProps.results && this.props.results) {
      const newIds = nextProps.results.map(result => result.ID);
      const oldIds = this.props.results.map(result => result.ID);
      if (!isEqual(newIds, oldIds)) {
        nextState.renderableItems = this.state.visibleItems;
        if (this.refs.scrollList) {
          this.refs.scrollList.scrollTop = 0;
        }
      }
    }
    this.setState(nextState);
  },
  componentWillUnmount() {
    clearInterval(this.timer);
  },
  onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    const results = reorder(this.state.results, result.source.index, result.destination.index);
    const ids = results.map(r => r.ID);

    this.props.changeParentResultOrder(results, ids);
  },
  handleScroll() {
    const { renderableItems, visibleItems, results } = this.state;
    // Do nothing if all items have been rendered
    if (renderableItems >= results.length) {
      return;
    }
    // If space to scroll is less than the renderable offset, render another batch equal to the size of visible items
    const { scrollHeight, scrollTop, clientHeight } = this.refs.scrollList;
    const scrollBottom = scrollHeight - (scrollTop + clientHeight);
    if (scrollBottom < renderableItemOffset * itemHeight) {
      this.setState({ renderableItems: renderableItems + visibleItems });
    }
  },
  getResultLength() {
    return this.state.results ? this.state.results.length : 0;
  },
  getListFunctionalityClasses() {
    let listFunctionalityClasses = ' can-report';
    if (this.props.showBottomButton) {
      listFunctionalityClasses += ' bottom-button';
    }
    if (this.props.handleResultsEditClick) {
      listFunctionalityClasses += ' float-delete-button';
    }
    return listFunctionalityClasses;
  },
  handleFavoriteClick({ result, additionalOptions }) {
    this.props.listMethods.handleFavoriteClick({
      type: result.type,
      id: result.ID,
      belongsToCurrentUser: this.props.belongsToCurrentUser,
      listId: SearchResultsUtil.getListData(this.props.currentPage, this.props.customList, this.props.intl)?.id,
      listTitle: SearchResultsUtil.getListData(this.props.currentPage, this.props.customList, this.props.intl)?.title,
      ...additionalOptions
    });
  },
  renderTrackResultCard(result, idx, dragHandleProps) {
    // On a users list, show the 1st party variant since the users name is already visible
    const forceFirstParty =
      this.props.currentPage === PageStrings.EXPLORE_USERS_MAPS_PAGE ||
      this.props.currentPage === PageStrings.EXPLORE_USERS_MAPS_MAP_PAGE ||
      this.props.currentPage === PageStrings.EXPLORE_USERS_TRACKS_PAGE ||
      this.props.currentPage === PageStrings.EXPLORE_USERS_TRACKS_MAP_PAGE;

    return (
      <ActivityCard
        allowItemDelete={
          this.props.isEditing &&
          this.props.resultCardFunctions.allowAddRemove() &&
          this.props.resultCardFunctions.isItemInPageList(result.type, result.ID)
        }
        allowItemMoving={this.props.allowReordering}
        context={this.props.context}
        forceFirstParty={forceFirstParty}
        handleCardClick={() =>
          (result.type == 'track' ? this.props.resultCardFunctions.handleTrackClick : this.props.resultCardFunctions.handleMapClick)(result, idx)
        }
        handleFavoriteClick={() =>
          this.handleFavoriteClick({
            result,
            contentPrivacy: result.contentPrivacy,
            detailedCardLocation: CardLocation.ExploreTabListView
          })
        }
        handleItemDeleteClick={() => this.props.resultCardFunctions.handleAddRemoveClick(result.type, result.ID)}
        isFavorite={this.props.listMethods.isFavorite(result.type, result.ID)}
        itemDeleteLabel={this.props.resultCardFunctions.getAddRemoveLabels().remove}
        key={`track-${result.ID}`}
        messagingChannel={this.props.messagingChannel}
        track={result}
        dragHandleProps={dragHandleProps}
        updateCurrentlyHoveredResult={this.props.updateCurrentlyHoveredResult}
      />
    );
  },
  renderTrailResultCard(result, dragHandleProps, isCompletionBadgeAutoExpanded) {
    const { isEditing, resultCardFunctions, allowReordering, listMethods, messagingChannel, context, updateCurrentlyHoveredResult } = this.props;
    // TODO: https://alltrails.atlassian.net/browse/XPLOR-2378
    const detailedCardLocation = this.props.isNewMapsPage ? CardLocation.ExploreTabListView : undefined;
    return (
      <TrailCardV2
        isCompletionBadgeAutoExpanded={isCompletionBadgeAutoExpanded}
        allowItemDelete={isEditing && resultCardFunctions.allowAddRemove() && resultCardFunctions.isItemInPageList(result.type, result.ID)}
        allowItemMoving={allowReordering}
        allowClickingCompletedBadge={resultCardFunctions.allowClickingCompletedBadge(result.type, result.ID)}
        handleItemDeleteClick={() => resultCardFunctions.handleAddRemoveClick(result.type, result.ID)}
        handleCardClick={() => {
          if (detailedCardLocation) {
            logTrailCardClicked({ trail_id: result.ID, detailed_card_location: detailedCardLocation });
          }
          resultCardFunctions.handleTrailClick(result);
        }}
        handleCompletedBadgeClick={() => resultCardFunctions.handleCompletedBadgeClick(result.type, result.ID)}
        handleFavoriteClick={() =>
          this.handleFavoriteClick({
            result,
            objectId: result.objectID,
            detailedCardLocation
          })
        }
        isCompleted={listMethods.isComplete(result.type, result.ID)}
        isFavorite={listMethods.isFavorite(result.type, result.ID)}
        isVerified={listMethods.isVerified(result.type, result.ID)}
        itemDeleteLabel={resultCardFunctions.getAddRemoveLabels().remove}
        key={`trail-${result.ID}`}
        messagingChannel={messagingChannel}
        trail={result}
        trailUrl={resultCardFunctions.formatTrailUrl(result)}
        dragHandleProps={dragHandleProps}
        context={context}
        updateCurrentlyHoveredResult={updateCurrentlyHoveredResult}
        isNewMapsPage={this.props.isNewMapsPage}
      />
    );
  },
  renderSortableList() {
    const listFunctionalityClasses = this.getListFunctionalityClasses();

    const cardIndexForBadgeAnimation = this.state.results.findIndex(result =>
      result.type === 'trail' ? this.props.listMethods.isComplete('trail', result.ID) : false
    );

    return (
      <NoSsr>
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId="droppable">
            {provided => (
              <ul className={listFunctionalityClasses} {...provided.droppableProps} ref={provided.innerRef}>
                {this.state.results.map((result, idx) => {
                  const id = result.ID.toString();
                  return (
                    <Draggable key={id} draggableId={id} index={idx}>
                      {provided => (
                        <li ref={provided.innerRef} {...provided.draggableProps}>
                          {this.renderTrailOrTrackResultCard(result, idx, provided.dragHandleProps, cardIndexForBadgeAnimation === idx)}
                        </li>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
        {this.getResultLength() < 3 &&
          [PageStrings.EXPLORE_ALL_PAGE, PageStrings.EXPLORE_COMMUNITY_CONTENT_PAGE].includes(this.props.currentPage) &&
          this.props.noResultsObj}
      </NoSsr>
    );
  },
  renderTrailOrTrackResultCard(result, idx, dragHandleProps = null, isCompletionBadgeAutoExpanded = false) {
    if (result.type === 'trail') {
      return this.renderTrailResultCard(result, dragHandleProps, isCompletionBadgeAutoExpanded);
    }
    if (result.type === 'track' || result.type === 'map') {
      return this.renderTrackResultCard(result, idx, dragHandleProps);
    }
  },
  renderEditButton() {
    if (
      isEmpty(this.state.results) ||
      this.props.context?.currentUser?.id !== this.props.userInfo?.id ||
      this.props.currentPage == PageStrings.EXPLORE_COMPLETED_PAGE
    )
      return null;

    return (
      <span className={this.props.isEditing ? 'edit-results edit-results-done' : 'edit-results'} onClick={this.props.handleResultsEditClick}>
        {this.props.isEditing
          ? this.props.intl.formatMessage({ defaultMessage: 'Done' })
          : this.props.intl.formatMessage({ defaultMessage: 'Edit list' })}
      </span>
    );
  },
  renderResultList() {
    let resultItems = [];
    if (this.props.loading) {
      return (
        <div className="loading-placeholder">
          <LoadingSpinner />
        </div>
      );
    }
    if (this.state.results && this.state.results.length > 0) {
      if (this.props.allowReordering) {
        // if its a sortable list return a different list
        return this.renderSortableList();
      }
      this.state.results.forEach((result, idx) => {
        // Dont render unless allowed to
        if (idx >= this.state.renderableItems + renderableItemOffset) {
          return;
        }
        if (result.type == 'trail') {
          resultItems.push(this.renderTrailResultCard(result));
        } else if (result.type == 'track' || result.type == 'map') {
          resultItems.push(this.renderTrackResultCard(result, idx));
        }
      });
      if (this.getResultLength() < 3 && [PageStrings.EXPLORE_ALL_PAGE, PageStrings.EXPLORE_COMMUNITY_CONTENT_PAGE].includes(this.props.currentPage)) {
        resultItems.push(this.props.noResultsObj);
      }
    } else {
      resultItems = this.props.noResultsObj;
    }

    if (Array.isArray(resultItems)) {
      resultItems = resultItems.filter(item => item !== undefined && item !== null).map((item, pos) => <li key={`result-item${pos}`}>{item}</li>);
    }

    const listFunctionalityClasses = this.getListFunctionalityClasses();
    return (
      <ul ref="scrollList" onScroll={this.handleScroll} className={listFunctionalityClasses}>
        {resultItems}
        {this.renderStaticCTA()}
        {this.renderReportButton()}
      </ul>
    );
  },
  renderReportButton() {
    if (this.props.currentPage !== PageStrings.EXPLORE_CUSTOM_PAGE || !this.props.context?.currentUser) return;
    const listUserId = this.props.customList?.userId || this.props.customList?.user?.id;
    if (this.props.currentPage === PageStrings.EXPLORE_CUSTOM_PAGE && listUserId !== this.props.context?.currentUser?.id) {
      return (
        <div className={classNames(styles.reportContainer, this.props.results?.length === 0 && styles.bottomPositioning)}>
          <Button
            className={styles.reportButton}
            text={this.props.intl.formatMessage({ defaultMessage: 'Report an issue' })}
            variant="flat"
            testId="report-an-issue-btn"
            onClick={() => this.setState({ showReportingModal: true })}
            size="sm"
            icon={{ Component: Place }}
          />
        </div>
      );
    }
  },
  renderReportingModal() {
    if (!this.state.showReportingModal || !this.props.customList || this.props.currentPage !== PageStrings.EXPLORE_CUSTOM_PAGE) return;

    return createPortal(
      <ReportIssuePage
        context={this.props.context}
        currentUserId={this.props.context.currentUser.id}
        entryPoint={EntryPoint.Lists}
        reportId={this.props.customList.id}
        reportType="issue"
        reportUser={this.props.userInfo}
        closeModal={() => this.setState({ showReportingModal: false })}
        setSuccessToast={this.props.setReportingSuccessToast}
        analyticsData={{ location: ReportLocation.List, contentType: ReportContentType.List }}
      />,
      document.getElementById('modalPortal')
    );
  },
  renderStaticCTA() {
    const listUserId = this.props.customList?.userId || this.props.customList?.user?.id;
    if (
      this.state.results &&
      this.state.results.length > 0 &&
      this.props.currentPage === PageStrings.EXPLORE_CUSTOM_PAGE &&
      (this.props.isCollaborator || listUserId === this.props.context?.currentUser?.id)
    ) {
      return (
        <div className={styles.staticCta}>
          <div className={styles.title}>
            <FormattedMessage defaultMessage="Keep it going" />
          </div>
          <div className={styles.subtext}>
            <FormattedMessage defaultMessage="Find more trails to add to your list" />
          </div>
          <Button
            text={this.props.intl.formatMessage({ defaultMessage: 'Explore trails' })}
            variant="default"
            testId="explore-trails-static-cta-btn"
            onClick={() => {
              window.location = LanguageSupportUtil.wrapUrlSafe('/explore', this.props.context.languageRegionCode);
            }}
          />
        </div>
      );
    }
  },
  render() {
    if (this.props.areListDetailsLoading) {
      return (
        <div className="loading-placeholder">
          <LoadingSpinner />
        </div>
      );
    }

    const resultList = this.renderResultList();
    let clearDiv = null;

    if (this.props.filtersSet && this.state.results) {
      clearDiv = (
        <span className={styles.button} onClick={this.props.clearAllFilters}>
          {this.props.intl.formatMessage({ defaultMessage: 'Clear filters' })}
        </span>
      );
    } else if (this.props.handleResultsEditClick) {
      clearDiv = this.renderEditButton();
    }

    let countContainer;
    if (
      this.props.resultCountObj &&
      !(this.props.currentPage === PageStrings.EXPLORE_FAVORITE_PAGE || this.props.currentPage === PageStrings.EXPLORE_CUSTOM_PAGE) &&
      (clearDiv ||
        this.props.currentPage === PageStrings.EXPLORE_CUSTOM_PAGE ||
        this.props.currentPage === PageStrings.EXPLORE_ALL_PAGE ||
        this.props.currentPage === PageStrings.EXPLORE_FAVORITE_PAGE ||
        this.props.currentPage === PageStrings.EXPLORE_COMPLETED_PAGE ||
        this.props.currentPage === PageStrings.EXPLORE_USERS_TRACKS_PAGE ||
        this.props.currentPage === PageStrings.EXPLORE_USERS_MAPS_PAGE)
    ) {
      countContainer = (
        <div className="count-container">
          <div className="count-container-item">{this.props.resultCountObj}</div>
          <div className="count-container-item clear-button-container">{clearDiv}</div>
        </div>
      );
    }

    let resultInfo;
    if (
      (this.state.h1Obj || countContainer) &&
      this.props.currentPage !== PageStrings.EXPLORE_ALL_PAGE &&
      this.props.currentPage !== PageStrings.EXPLORE_COMMUNITY_CONTENT_PAGE
    ) {
      resultInfo = (
        <div
          className={classNames('result-info', {
            collaborativeLists:
              this.props.currentPage === PageStrings.EXPLORE_FAVORITE_PAGE || this.props.currentPage === PageStrings.EXPLORE_CUSTOM_PAGE
          })}
        >
          {this.state.h1Obj}
          {countContainer}
        </div>
      );
    }

    let bottomButtonContent;
    if (this.props.showToggleButton) {
      bottomButtonContent = (
        <div className="bottom-button-container">
          <Switch
            labelText={this.props.bottomButtonText}
            selected={this.props.nearbyTrailsShown}
            testId="nearbyTrailsSwitch"
            onChange={this.props.bottomButtonAction}
          />
        </div>
      );
    } else if (this.props.showBottomButton) {
      bottomButtonContent = (
        <div className="bottom-button-container">
          <Button
            text={this.props.bottomButtonText}
            className="clear-filters-btn"
            onClick={this.props.bottomButtonAction}
            testId="search-result-list-clear-filters-button"
            width="full"
            variant="primary"
          />
        </div>
      );
    }

    return (
      <>
        {!this.props.isMobileWidth && this.props.tabBar}
        {resultInfo}
        {resultList}
        {bottomButtonContent}
        {this.renderReportingModal()}
      </>
    );
  }
});

export default LegacyFullscreenSearchResultList;
