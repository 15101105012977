import { useCallback, useMemo, useState } from 'react';
import Review, { ReviewId } from '@alltrails/shared/types/review';
import { TrailCondition, TrailConditionUid } from 'types/Trails';

type ConditionsMap = { [key in TrailConditionUid]?: TrailCondition };
type ReviewMap = { [key: ReviewId]: Review };
type ConditionsReviews = { [key in TrailConditionUid]?: ReviewId[] };

const useTrailConditions = (trailConditions: TrailCondition[], filters?: TrailConditionUid[]) => {
  const [conditions] = useState<ConditionsMap>(
    trailConditions.reduce((acc, condition) => {
      acc[condition.uid] = condition;
      return acc;
    }, {} as ConditionsMap)
  ); // condition uid => condition
  const [reviews, setReviews] = useState<ReviewMap>({}); // review id => review
  const [conditionsReviews, setConditionsReviews] = useState<ConditionsReviews>({} as ConditionsReviews); // condition uid => review id[]
  const [filteredConditionsSet, setFilteredConditionsSet] = useState<Set<TrailConditionUid>>(new Set(filters));

  /**
   * Conditions sorted by most recently mentioned first. If two conditions are mentioned at the same time, sort by descending count.
   */
  const sortedConditions = useMemo(
    () =>
      Object.values(conditions).sort((a, b) => {
        if (a.lastMentionedAt === b.lastMentionedAt) {
          return b.count - a.count;
        }
        return a.lastMentionedAt > b.lastMentionedAt ? -1 : 1;
      }),
    [conditions]
  );

  const filteredConditions = useMemo(() => Array.from(filteredConditionsSet), [filteredConditionsSet]);

  /**
   * Toggles a condition to filter reviews by.
   * @param condition the condition to filter by.
   */
  const toggleConditionFilter = useCallback(
    (conditionUid: TrailConditionUid) => {
      if (!conditions[conditionUid]) {
        return;
      }

      if (filteredConditionsSet.has(conditionUid)) {
        // remove condition
        setFilteredConditionsSet(filters => {
          const newFilters = new Set(filters);
          newFilters.delete(conditionUid);
          return newFilters;
        });
      } else {
        // add condition
        setFilteredConditionsSet(filters => new Set(filters.add(conditionUid)));
      }
    },
    [conditions, filteredConditionsSet]
  );

  /**
   * Clears all applied filters
   */
  const resetConditionFilters = useCallback(() => {
    setFilteredConditionsSet(new Set());
  }, []);

  /**
   * Adds trail reviews to the state & maps conditions to review ids. If there are no associated conditions, don't consider the review.
   * @param reviewsToAdd array of trail reviews to add.
   */
  const addReviews = useCallback((reviewsToAdd: Review[]) => {
    const reviewMap: ReviewMap = {};
    const conditionsReviewsMap: ConditionsReviews = {};
    reviewsToAdd.forEach(review => {
      if (!review.trailConditions.length) {
        return;
      }

      reviewMap[review.id] = review;
      review.trailConditions.forEach(({ uid }) => {
        if (conditionsReviewsMap[uid]) {
          conditionsReviewsMap[uid].push(review.id);
        } else {
          conditionsReviewsMap[uid] = [review.id];
        }
      });
    });

    setReviews(reviewMap);
    setConditionsReviews(conditionsReviewsMap);
  }, []);

  /**
   * Gets a set of unique reviews to show accounting for potential condition filters being applied. Sorted by date from most recent to least recent.
   * Note: having multiple condition filters will return a union of relevant reviews (ie. an AND is applied to review results)
   * @returns an array of reviews to show filtered by condition(s).
   */
  const getReviews = useCallback((): Review[] => {
    const reviewSorter = (a: Review, b: Review) => {
      if (a.date === b.date) {
        return 0;
      }
      return a.date > b.date ? -1 : 1;
    };

    if (!filteredConditionsSet.size) {
      return Object.values(reviews).sort(reviewSorter);
    }

    const addedReviews = new Set<ReviewId>();
    const relevantReviews: Review[] = [];
    filteredConditionsSet.forEach(condition => {
      const relevantReviewIds = conditionsReviews[condition] ?? [];
      relevantReviewIds.forEach(reviewId => {
        if (!addedReviews.has(reviewId)) {
          relevantReviews.push(reviews[reviewId]);
          addedReviews.add(reviewId);
        }
      });
    });

    return relevantReviews.sort(reviewSorter);
  }, [reviews, conditionsReviews, filteredConditionsSet]);

  return {
    sortedConditions,
    filteredConditions,
    toggleConditionFilter,
    resetConditionFilters,
    addReviews,
    getReviews
  };
};

export default useTrailConditions;
