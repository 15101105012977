import { useCallback, useEffect, useState } from 'react';
import ReportLocation from '@alltrails/analytics/enums/ReportLocation';
import { CreditLinkResponse, LightboxPhoto } from '@alltrails/shared/types/lightbox';
import { get } from '@alltrails/shared/api';
import Experiments from '@alltrails/shared/types/Experiments';
import SharedLightbox from '@alltrails/modules/Lightbox';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import { Trail } from '@alltrails/shared/types/trail';
import type { Context } from 'types/Context';
import AdInterstitial from 'components/AdInterstitial';
import useExperiment from 'hooks/useExperiment';
import '@alltrails/modules/Lightbox/sharedCss.css';
import './styles/overrides.scss';
import hasPermission from 'utils/hasPermission';

type Props = {
  handleCloseRequest: () => void;
  onPhotoViewed?: (photo: LightboxPhoto['photo']) => void;
  context?: Context;
  images: { id?: number; url: string }[];
  listId?: number;
  mapId?: number;
  showCreditActions?: boolean;
  startingIndex: number;
  trailId?: number;
  linkedTrail?: Pick<Trail, 'id' | 'name' | 'slug'>;
  analyticsReportLocation?: ReportLocation;
  isStaticPhoto?: boolean;
};

function LightboxAd({ count, experiment }: { count: number; experiment: Experiments['web-growth-native-ads-v2']['value'] }) {
  if (count === 5) {
    return <AdInterstitial adId="gpt-photos1" adUnitPath="/4689141/alltrails_photos1_300x250" experiment={experiment} />;
  }

  if (count === 15) {
    return <AdInterstitial adId="gpt-photos2" adUnitPath="/4689141/alltrails_photos2_300x250" experiment={experiment} />;
  }

  if (count === 25) {
    return <AdInterstitial adId="gpt-photos3" adUnitPath="/4689141/alltrails_photos3_300x250" experiment={experiment} />;
  }

  return null;
}

function Lightbox({
  context,
  handleCloseRequest,
  onPhotoViewed,
  images,
  listId,
  mapId,
  showCreditActions,
  startingIndex,
  trailId,
  linkedTrail,
  analyticsReportLocation,
  isStaticPhoto
}: Props) {
  const [count, setCount] = useState(0);
  const experiment = useExperiment('web-growth-native-ads-v2');
  const languageRegionCode = useLanguageRegionCode();

  useEffect(() => {
    document.querySelector('html').classList.add('modalOpen');

    return () => {
      document.querySelector('html').classList.remove('modalOpen');
    };
  }, []);

  const getPhotoUrl = useCallback(
    (index: number) => {
      const url = `/photos/${images[index].id}/credit-link`;

      const params = new URLSearchParams();
      const query = showCreditActions ? '&' : '?';
      if (showCreditActions) {
        params.set('showActions', showCreditActions.toString());
      }

      if (trailId) {
        params.set('provide_set_as_default', 'true');
      } else if (mapId) {
        params.set('provide_map_id', String(mapId));
      } else if (listId) {
        params.set('provide_list_id', String(listId));
      } else {
        params.set('provide_trail_links', 'true');
      }

      return `${url}${query}${params.toString()}`;
    },
    [images, showCreditActions, trailId, mapId, listId]
  );

  const fetchPhotoDetails = useCallback(
    async (newPhotoIndex: number) => {
      const url = getPhotoUrl(newPhotoIndex);
      try {
        let response;
        if (!isStaticPhoto) {
          response = await get<CreditLinkResponse>(url);
        }
        if (isStaticPhoto || !response) {
          return images[newPhotoIndex];
        }
        return response;
      } catch (error) {
        throw Error(`Error getting a photo details: ${error}`);
      }
    },
    [getPhotoUrl, isStaticPhoto, images]
  );

  return (
    <>
      <SharedLightbox
        context={context}
        images={images}
        onNavigate={fetchPhotoDetails}
        onPhotoViewed={onPhotoViewed}
        onClose={handleCloseRequest}
        startingIndex={startingIndex}
        languageRegionCode={languageRegionCode}
        linkedTrail={linkedTrail}
        count={count}
        setCount={setCount}
        analyticsReportLocation={analyticsReportLocation}
        shouldHideActions={isStaticPhoto}
      />
      {(experiment?.value === 'unpaid_only' || experiment?.value === 'unpaid-and-paid') && (
        <LightboxAd count={count} experiment={experiment?.value} />
      )}
    </>
  );
}

export default Lightbox;
