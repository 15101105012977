// extracted by mini-css-extract-plugin
export var container = "styles-module__container___Fg8B6";
export var coordinatesAndLanguageBtn = "styles-module__coordinatesAndLanguageBtn___p67gC";
export var descriptionText = "styles-module__descriptionText___W304D";
export var doneButton = "styles-module__doneButton___dL7P6";
export var dragHandle = "styles-module__dragHandle___QwiVB";
export var latLng = "styles-module__latLng___xUZx7";
export var nameText = "styles-module__nameText___pyoLo";
export var numberAndName = "styles-module__numberAndName___FzTM0";
export var orderNumber = "styles-module__orderNumber___DEczn";
export var topSection = "styles-module__topSection___C3UF9";