import mapboxgl from 'mapbox-gl';
import type { Context } from 'types/Context';
import { IntlShape } from '@alltrails/shared/react-intl';
import getHelpCenterUrl from '@alltrails/shared/utils/constants/helpCenterUrl';
import { atBoundsToLngLatBounds } from 'utils/at_map_helpers';
import { createBlankStyle, initCustomStyle, swapBaseStyle } from 'utils/mapbox/style_helpers';
import { initLayerEvents } from 'utils/mapbox/layers/layer_helpers';
import baseStyleLoaders from './baseStyleLoaders';

type StyleConfigKey =
  | 'alltrailsOutdoorsV2'
  | 'alltrailsSatellite'
  | 'googleSatellite'
  | 'adminBasemap'
  | 'opencycle'
  | 'openstreet'
  | 'roadmap'
  | 'topo'
  | 'worldparks'
  | 'worldtopo';

type AllTrailsMap = mapboxgl.Map & {
  context: Context;
  geolocateControl: mapboxgl.IControl;
};

type GetArgs = {
  initialBounds: {
    latitudeTopLeft: number;
    longitudeTopLeft: number;
    latitudeBottomRight: number;
    longitudeBottomRight: number;
  };
  initialCenter: [string, string, string]; // zoom is the third value
  isPrint: boolean;
  mapboxAccessToken: string;
  mapDivId: string;
  mapZoom: string;
  setPrintFlags: unknown;
};

/**
 * getMapboxInstance returns a mapbox map that is not yet full loaded.
 * @param {GetArgs} args
 * @returns mapboxgl.Map
 */
function getMapboxInstance({ initialBounds, initialCenter, isPrint, mapboxAccessToken, mapDivId, mapZoom, setPrintFlags }: GetArgs): mapboxgl.Map {
  let initBounds: [[number, number], [number, number]];
  let initCenter: [number, number];
  let initZoom = 14;

  if (isPrint) {
    // LatLng -> LngLat
    initCenter = [parseFloat(initialCenter[1]), parseFloat(initialCenter[0])];
    if (mapZoom) {
      initZoom = parseFloat(mapZoom);
    } else {
      initZoom = parseFloat(initialCenter[2]);
    }
  } else if (initialBounds) {
    initBounds = atBoundsToLngLatBounds(initialBounds) as [[number, number], [number, number]];
  } else if (initialCenter) {
    // LatLng -> LngLat
    initCenter = [parseFloat(initialCenter[1]), parseFloat(initialCenter[0])];
    initZoom = parseFloat(initialCenter[2]);
  }

  const preserveDrawingBuffer = !!setPrintFlags;

  // Init mapbox map
  mapboxgl.accessToken = mapboxAccessToken;

  return new mapboxgl.Map({
    bounds: initBounds,
    center: initCenter,
    container: mapDivId,
    fadeDuration: 0,
    preserveDrawingBuffer,
    style: createBlankStyle(),
    zoom: initZoom,
    maxPitch: 60,
    attributionControl: false
  }) as mapboxgl.Map;
}

type LoadArgs = {
  context: Context;
  currentMapLayer: StyleConfigKey;
  locale: string;
  map: mapboxgl.Map;
  intl: IntlShape;
};

/**
 * loadMapExtras returns a Promise that resolves when a map is fully loaded and ready for interaction.
 * @param {LoadArgs} args
 * @returns Promise
 */
async function loadMapExtras({ context, currentMapLayer, locale, map, intl }: LoadArgs): Promise<void> {
  return new Promise(resolve => {
    // Registering this independently of the map instantiation is risky. loadMapExtras should
    // be called immediately after the map is created or else this event may never fire.
    map.once('load', async () => {
      initCustomStyle(map);
      initLayerEvents(map);

      const { displayMetric } = context;

      const styleLoader = baseStyleLoaders[currentMapLayer];
      const style = await styleLoader(locale, context.displayMetric, intl);
      // We do some extra work in swapBaseStyle which means we can't simply use the style in the map's constructor.
      await swapBaseStyle(map, style, false);

      // Configure map controls
      map.addControl(
        new mapboxgl.ScaleControl({
          maxWidth: 80,
          unit: displayMetric ? 'metric' : 'imperial'
        }),
        'bottom-right'
      );

      // Add custom AllTrails properties onto map.
      (map as AllTrailsMap).context = context;
      (map as AllTrailsMap).geolocateControl = new mapboxgl.GeolocateControl({});
      map.addControl((map as AllTrailsMap).geolocateControl, 'bottom-right');
      const mapLegendString = intl.formatMessage({ defaultMessage: 'Map legend' });
      map.addControl(
        new mapboxgl.AttributionControl({
          customAttribution: `<strong><a href="${getHelpCenterUrl(
            context.languageRegionCode,
            11555324555924
          )}" target="_blank"> ${mapLegendString} </a></strong>`
        }),
        'bottom-right'
      );
      map.boxZoom.disable();

      resolve(null);
    });
  });
}

export { getMapboxInstance, loadMapExtras, StyleConfigKey };
