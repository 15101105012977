import { useEffect, useState } from 'react';
import { defineMessages } from '@alltrails/shared/react-intl';
import classNames from 'classnames';
import Tooltip from '@alltrails/maps/components/Tooltip';
import IconButton from '@alltrails/shared/denali/components/IconButton';
import Close from '@alltrails/shared/icons/Close';
import Pause from '@alltrails/shared/icons/Pause';
import Play from '@alltrails/shared/icons/Play';
import useFormatMessage from '@alltrails/shared/hooks/useFormatMessage';
import logFlyoverCloseButtonTapped from '@alltrails/analytics/events/logFlyoverCloseButtonTapped';
import logFlyoverPauseButtonTapped from '@alltrails/analytics/events/logFlyoverPauseButtonTapped';
import logFlyoverReplayButtonTapped from '@alltrails/analytics/events/logFlyoverReplayButtonTapped';
import logFlyoverResumeButtonTapped from '@alltrails/analytics/events/logFlyoverResumeButtonTapped';
import FlyoverSource from '@alltrails/analytics/enums/FlyoverSource';
import { useFlyoverSettings } from 'components/MapProvider';
import useLanguageRegionCode from '@alltrails/shared/hooks/useLanguageRegionCode';
import useMobileWidth from 'hooks/useMobileWidth';
import { getControlsPadding } from 'utils/flyoverHelpers';
import { getLocationName } from 'utils/location_parsing';
import { StyleConfigKey } from 'utils/mapbox/map';
import { MapboxMapShim } from 'types/Map';
import type Trail from 'types/Trails/Trail';
import Key from 'types/Key';
import { wrapUrlSafe } from '@alltrails/shared/utils/languageSupport';
import * as styles from './styles.module.scss';
import FlyoverMapStats from '../FlyoverMapStats';
import FlyoverTransition from '../FlyoverTransition';

const messages = defineMessages({
  EXIT_FLYOVER: { defaultMessage: 'Exit' },
  PAUSE: { defaultMessage: 'Pause' },
  PLAY: { defaultMessage: 'Play' }
});

export type FlyoverMapControlsProps = {
  currentLayer: StyleConfigKey;
  disable3D: () => void;
  enable3D: () => void;
  initFlyoverOnLoad?: boolean;
  map: MapboxMapShim;
  onFlyoverEnabled: () => void;
  onFlyoverDisabled: () => void;
  setLayer: (layer: string) => void;
  shouldInitializeFlyover?: boolean;
  terrainActive: boolean;
  trail: Trail;
};

const FlyoverMapControls = ({
  currentLayer,
  disable3D,
  enable3D,
  initFlyoverOnLoad = false,
  map,
  onFlyoverEnabled,
  onFlyoverDisabled,
  setLayer,
  shouldInitializeFlyover = false,
  terrainActive,
  trail
}: FlyoverMapControlsProps): JSX.Element => {
  const {
    formattedDefaultMessages: { EXIT_FLYOVER, PAUSE, PLAY }
  } = useFormatMessage(messages);
  const isMobile = useMobileWidth();
  const languageRegionCode = useLanguageRegionCode();

  const [canExit, setCanExit] = useState(false);
  const [controlsPadding, setControlsPadding] = useState(getControlsPadding(map));
  const {
    didMapLoadEventsFire,
    elevationGainMeters,
    exitFlyover,
    flyoverLoadingState,
    initFlyover,
    isComplete,
    isPaused,
    percentComplete,
    replayAnimation,
    shouldHideControls,
    togglePauseFlyover
  } = useFlyoverSettings();

  const onEnabled = () => {
    onFlyoverEnabled();
    setCanExit(true);
  };

  const onPlayPauseClick = () => {
    const flyoverSource = initFlyoverOnLoad ? FlyoverSource.TrailDetails : FlyoverSource.MapDetails;
    if (isComplete) {
      logFlyoverReplayButtonTapped({ source: flyoverSource, trail_id: trail.trail_id });
      replayAnimation();
    } else {
      const pausePlayMetricInfo = {
        percent_complete: percentComplete,
        source: flyoverSource,
        trail_id: trail.trail_id
      };
      // eslint-disable-next-line no-unused-expressions
      isPaused ? logFlyoverResumeButtonTapped(pausePlayMetricInfo) : logFlyoverPauseButtonTapped(pausePlayMetricInfo);
      togglePauseFlyover();
    }
  };

  const onCloseClick = () => {
    logFlyoverCloseButtonTapped({
      is_paused: isPaused,
      percent_complete: percentComplete,
      source: initFlyoverOnLoad ? FlyoverSource.TrailDetails : FlyoverSource.MapDetails,
      trail_id: trail.trail_id
    });

    const params = new URLSearchParams(window.location.search);

    if (params.has('flyoverReturnToTrail')) {
      window.location.assign(wrapUrlSafe(`/${trail.slug}`, languageRegionCode));
    } else {
      onFlyoverDisabled();
      exitFlyover(disable3D, setLayer);
      setCanExit(false);
    }
  };

  useEffect(() => {
    // PLAN-986: preventDefault on spacebar to prevent page scroll
    const prevDefault = (ev: KeyboardEvent) => ev.code === Key.Space && ev.preventDefault();
    if (initFlyoverOnLoad) {
      document.addEventListener('keypress', prevDefault);
    }
    return () => document.removeEventListener('keypress', prevDefault);
  }, []);

  useEffect(() => {
    const shouldInit = didMapLoadEventsFire && shouldInitializeFlyover;
    if (shouldInit || (shouldInit && initFlyoverOnLoad)) {
      initFlyover(terrainActive, enable3D, setLayer, currentLayer, onEnabled);
    }
  }, [didMapLoadEventsFire, shouldInitializeFlyover]);

  useEffect(() => {
    // PLAN-1171: make sure the controls on mobile web aren't hidden by the dynamic URL bar
    const onResize = () => setControlsPadding(getControlsPadding(map));
    window.addEventListener('resize', onResize);
    window.addEventListener('scroll', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
      window.removeEventListener('scroll', onResize);
    };
  }, [map]);

  useEffect(() => {
    function handlePopState() {
      onFlyoverDisabled();
      exitFlyover(disable3D, setLayer);
      setCanExit(false);
    }

    if (canExit) {
      window.addEventListener('popstate', handlePopState);
    }

    return () => window.removeEventListener('popstate', handlePopState);
  }, [canExit, disable3D, exitFlyover, onFlyoverDisabled, setLayer]);

  if (!didMapLoadEventsFire) {
    return null;
  }

  return (
    <>
      <FlyoverTransition
        className={styles.flyoverExit}
        showOn={flyoverLoadingState === 'done' ? !shouldHideControls : flyoverLoadingState === 'loading'}
        unmountOnExit
        useShortEntry
      >
        <IconButton
          icon={{ Component: Close }}
          onClick={onCloseClick}
          size={isMobile ? 'sm' : 'md'}
          title={EXIT_FLYOVER}
          testId="flyover-exit"
          variant="elevated"
        />
      </FlyoverTransition>
      <FlyoverTransition className={styles.flyoverTooltip} showOn={flyoverLoadingState === 'done'} unmountOnExit useShortEntry>
        <Tooltip is3DActive isFlyoverEnabled languageRegionCode={languageRegionCode} />
      </FlyoverTransition>
      <FlyoverTransition showOn={!shouldHideControls && flyoverLoadingState === 'done'} unmountOnExit>
        <div className={styles.controlsContainer} style={{ bottom: controlsPadding }}>
          <div className={styles.trailInfoContainer}>
            <span className={classNames('xlate-none', styles.trailName)}>{trail.name}</span>
            <span className={styles.trailLocation}>{getLocationName(trail)}</span>
          </div>
          <IconButton
            className={styles.playPause}
            icon={{ Component: isPaused ? Play : Pause }}
            onClick={onPlayPauseClick}
            size="md"
            testId={`flyover-${isPaused ? 'play' : 'pause'}`}
            title={isPaused ? PLAY : PAUSE}
            variant="flat"
          />
        </div>
      </FlyoverTransition>
      <FlyoverMapStats
        elevationGainMeters={elevationGainMeters}
        flyoverLoadingState={flyoverLoadingState}
        percentComplete={percentComplete}
        trail={trail}
      />
    </>
  );
};

export default FlyoverMapControls;
