import classNames from 'classnames';
import * as styles from './styles.module.scss';

type FaqTitleProps = {
  children: React.ReactNode;
};
export const FaqTitle = ({ children }: FaqTitleProps) => <h2 className={styles.title}>{children}</h2>;

type FrequentlyAskedQuestionProps = {
  question: React.ReactNode;
  answer: React.ReactNode;
  excludeMarginBottom?: boolean;
};

const FrequentlyAskedQuestion = ({ question, answer, excludeMarginBottom }: FrequentlyAskedQuestionProps) => (
  <div
    itemScope
    itemProp="mainEntity"
    itemType="https://schema.org/Question"
    className={classNames(styles.questionAndAnswer, { [styles.noMarginBottom]: excludeMarginBottom })}
  >
    <h3 itemProp="name" className={styles.question}>
      {question}
    </h3>
    <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
      <p itemProp="text" className={styles.answer}>
        {answer}
      </p>
    </div>
  </div>
);

export default FrequentlyAskedQuestion;
