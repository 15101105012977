import mapboxgl from 'mapbox-gl';
import { pointItemsToGeojson } from '@alltrails/maps/utils/legacyGeoJSONConversions';
import logError from 'utils/logError';
import { YELLOW } from 'utils/constants/MarkerIds';
import { addOrUpdateGeojsonSource } from '../sources/geojson_helpers';
import { addMarkers, removeMarkers } from './markers';
import { getTrailsWaypoints } from '../../requests/trail_requests';
import { addLayer, addLayerEvent, removeLayer } from '../layers/layer_helpers';
import { addLabelImagesToMap, createLabelLayer } from '../layers/labels';
import { mountReactComponent, addHoverPopupToMap } from '../event_handling/popup_helpers';
import { createDraggableMarker } from '../layers/markers';
import { createWaypointMarkerLayer, waypointImageId, waypointImageSrc } from '../layers/waypoint_markers';
import { loadAndAddImage } from '../image_helpers';

const createDraggableWaypoint = (id, color, map, waypoint, popupContent, messagingChannel) => {
  const popup = new mapboxgl.Popup({
    closeButton: false,
    closeOnMove: false
  }).setDOMContent(mountReactComponent(popupContent(waypoint)));

  const { longitude, latitude } = waypoint.location;

  const marker = createDraggableMarker(color).setLngLat([longitude, latitude]).addTo(map).setPopup(popup).togglePopup();

  marker.on('dragend', () => {
    const { lng, lat } = marker.getLngLat();

    if (messagingChannel) {
      messagingChannel.publish({
        topic: 'waypoint.dragged.with_location',
        data: {
          waypointId: waypoint.id,
          location: {
            latitude: lat,
            longitude: lng
          }
        }
      });
    }
  });

  const removeMarker = () => {
    if (marker) {
      marker.remove();
    }
  };

  messagingChannel.subscribe('removeDraggableMarker', removeMarker);
  return marker;
};

const addWaypointMarkers = (map, id, waypoints, renderPopup, bounds) => {
  const sourceId = id;
  const layerId = id;

  // Init/update source
  const geojson = pointItemsToGeojson(waypoints);
  addOrUpdateGeojsonSource(map, sourceId, geojson);

  if (bounds) {
    geojson.features.forEach(geojsonPoint => {
      bounds.extend(geojsonPoint.geometry.coordinates);
    });
  }

  if (!map.getLayer(layerId)) {
    // Add layer to map
    loadAndAddImage(map, waypointImageId, waypointImageSrc, {
      pixelRatio: 2
    }).catch(logError);

    // Uncomment this code to see a debugging aid to help visually align our icons
    // for waypoints, which may have shadows/spacing/padding in the images. Making
    // sure our icon images perfectly align with the actual lat/lng of data is
    // important. Imagine if users tried to place a waypoint on an exact lat/lng
    // and thought it was correct because of our waypoint image, then later we
    // swap the image and it does not align with the data. All user data would
    // be misaligned because of that UX bug.
    // loadAndAddImage(map, 'debug-waypoint-image', debuggerImageSrc, {
    //   pixelRatio: 2
    // });
    // addLayer(map, {
    //   id: 'debug-waypoint-layer',
    //   type: 'symbol',
    //   source: sourceId,
    //   layout: {
    //     'icon-allow-overlap': true,
    //     'icon-ignore-placement': true,
    //     'icon-image': ['image', 'debug-waypoint-image'],
    //     'icon-size': 1
    //   }
    // });

    const layer = createWaypointMarkerLayer(layerId, sourceId);
    addLayer(map, layer);

    // Init cursor changes
    addLayerEvent(map, layerId, true, 'mouseenter', () => {
      map.getCanvas().style.cursor = 'pointer';
    });
    addLayerEvent(map, layerId, true, 'mouseleave', () => {
      map.getCanvas().style.cursor = map.customStyle.cursor;
    });

    // Init popups
    if (renderPopup) {
      addHoverPopupToMap(map, layerId, renderPopup);
    }
  }
};

const addMapWaypoints = (
  map,
  id,
  waypoints,
  renderPopup = null,
  bounds = null,
  messagingChannel = null,
  editWaypointId = null,
  withLabels = true
) => {
  if (!waypoints) return;

  const orderedWaypoints = waypoints.sort((a, b) => a.order - b.order).map((wp, idx) => ({ ...wp, order: idx + 1 }));

  const editableWaypoints = [];
  const regularWaypoints = [];
  orderedWaypoints.forEach(wp => {
    if (wp.id === editWaypointId) {
      editableWaypoints.push(wp);
    } else {
      regularWaypoints.push(wp);
    }
  });
  addWaypointMarkers(map, id, regularWaypoints, renderPopup, bounds);

  if (editableWaypoints.length > 0) {
    const waypoint = editableWaypoints[0];
    createDraggableWaypoint(id, YELLOW, map, waypoint, renderPopup, messagingChannel);
  }

  if (withLabels) {
    // Add label images
    addLabelImagesToMap(map);

    // Add label layer
    const showTitleFilter = ['==', ['get', 'showTitle', ['object', ['get', 'waypointDisplayProperty']]], true];
    addLayer(map, createLabelLayer(`${id}-labels`, id, 'name', showTitleFilter));
  }
};

const addCommunityWaypoints = (map, id, exploreMap, renderPopup = null, bounds = null) => {
  getTrailsWaypoints(exploreMap.trailId).then(waypoints => {
    // If viewing a trail map, do not show UGC that is from this map.
    const excludeWaypointIds = exploreMap.waypoints.map(waypoint => waypoint.id);
    waypoints = waypoints.filter(waypoint => !excludeWaypointIds.includes(waypoint.id));
    // Wrap renderPopup function in order to supply flag that this is a "community" waypoint and does not live on exploreMap
    const renderWaypointPopup = waypoint => renderPopup(waypoint, true);
    // Add waypoints overlay
    // Note: community waypoints aren't draggable and don't have labels
    // TODO: Move this logic into its own overlay file since it's very different? - Will these be numbered?
    addMarkers(map, id, waypoints, YELLOW, renderWaypointPopup, bounds);
  });
};

const removeWaypoints = (map, id, messagingChannel = null) => {
  removeLayer(map, `${id}-labels`);
  removeMarkers(map, id);

  if (messagingChannel) {
    messagingChannel.publish({ topic: 'removeDraggableMarker' });
  }
};

export { addMapWaypoints, addCommunityWaypoints, removeWaypoints };
